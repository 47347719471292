import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import classnames from "classnames";
import { getAbout } from "./../../actions/aboutAction";
import { editAbout } from "./../../actions/aboutActionUD";
import queryString from "query-string";
import { Growl } from "primereact/growl";
import NotFound from "./../../pages/NotFound";
class EditABOUT extends Component {
  constructor() {
    super();
    this.state = {
      reference_id: "",
      directory: "",
      requestSuccessMessage: "",
      requestMessage: "",
      requestFailedMessage: {},
      about: {},
      resType: "",
      content: "",
      init2: false,
      error1: false,
      error2: false,
      error3: false,
      pageTitle: "Ikar.sy |ABOUT",
      pageDescription:
        "سوق العقارات الإلكتروني في سورية Ikar.sy مخصص لجمع عروض بيع، شراء و تأجير العقارات من جميع مكاتب العقارات في سورية. الإعلان على Ikar.sy مجاني و متاح فقط للمكاتب العقارية والمتعهدين المسجلين لدى الموقع في جميع المحافظات السورية. | Ikar.sy",
    };
  }

  componentWillReceiveProps = async (nextProps, nextState) => {
    if (
      nextProps.requestSuccessMessage !== this.state.requestSuccessMessage &&
      this.state.requestSuccessMessage === "ready" &&
      this.state.requestFailedMessage.error === undefined
    ) {
      this.setState({
        // requestSuccessMessage: nextProps.requestSuccessMessage,
        requestSuccessMessage: nextProps.requestFailedMessage,
      });

      switch (nextProps.requestSuccessMessage) {
        case "تعديل الاقتراح المحدد":
          this.growl.show({
            severity: "success",
            summary: "تم تعديل الاقتراح بنجاح",
          });

          const searchUrlParams = await queryString.parse(
            this.props.locationSearch
          );
          if (searchUrlParams.about_id !== undefined) {
            // agency_ref_id = searchUrlParams.agency_id;
            const id = searchUrlParams.about_id;
            if (id !== undefined && id !== null) {
              this.props.getAbout(id);
            }
          }
          break;

        case "error in edit question":
          this.growl.show({
            severity: "error",
            summary: "حدثت مشكلة في تعديل البند الرجاء المحاولة لاحقا ",
          });
          break;

        default:
          break;
      }
    } else {
      if (nextProps.about !== this.state.about) {
        await this.setState({
          about: nextProps.about,
          content: nextProps.about.content,

          reference_id: nextProps.about.reference_id,
        });
      }

      if (
        nextProps.about !== undefined &&
        nextProps.about !== this.state.about &&
        nextProps.requestMessage === "edit is succeeded"
      ) {
        const { content } = nextProps.about;

        await this.setState({
          content,
        });
      }
    }

    if (
      nextProps.resType !== undefined &&
      nextProps.resType !== this.state.resType
    ) {
      const { resType } = nextProps;
      this.setState({ resType });
    }
  };

  async componentDidMount() {
    const searchUrlParams = await queryString.parse(this.props.locationSearch);

    if (searchUrlParams.about_id !== undefined) {
      // agency_ref_id = searchUrlParams.agency_id;
      const id = searchUrlParams.about_id;
      if (id !== undefined && id !== null) {
        this.props.getAbout(id);
      }
    }
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
      error1: false,
      error2: false,
      error3: false,
    });

  onEditSubmit = async (str, e) => {
    e.preventDefault();
    const { content, reference_id } = this.state;
    if (content === "" || content === null) {
      this.setState({ error2: true });
    }

    const aboutObject = {
      content,
      reference_id,
    };
    this.setState({ requestSuccessMessage: "ready" });
    this.props.editAbout(aboutObject, "تعديل الاقتراح المحدد");
  };

  onCheckBoxChange = async (e) => {
    if (this.state.init2) {
      await this.setState({ init2: false, [e.target.name]: true });
    } else {
      await this.setState({ [e.target.name]: e.target.checked });
    }
  };

  render() {
    const {
      resType,
    } = this.state;
    return (
      <div>
        <Helmet>
          <title>{this.state.pageTitle}</title>
          <meta name="description" content={this.state.pageDescription} />
        </Helmet>
        {/* //////////// */}
        {resType === "complete" ? (
          <div>
            <Growl ref={(el) => (this.growl = el)} />
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="text-center pb-4 pt-3 mb-4">
                  <h1 className="main_title">تعديل اقتراح</h1>
                </div>
              </div>
            </div>
            <form className="search_div  round-div p-1 mb-4">
              <div className="row justify-content-around mb-4">
                <div className="form-group text-right col-lg-6 col-md-6 col-sm-12 col-xs-12  mb-4">
                  <label htmlFor="content">
                    التفاصيل: <span className="display_mandatory"></span>
                  </label>
                  <div>
                    <textarea
                      rows="4"
                      name="content"
                      className={classnames("form-control", {
                        "is-invalid": this.state.error2,
                      })}
                      placeholder=""
                      value={this.state.content}
                      onChange={this.onChange}
                    />
                  </div>
                  {this.state.error2 ? (
                    <div className="invalid-feedback-custom">
                      الرجاء ادخال التفاصيل{" "}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </form>
            <div className="text-right">
              <span>
                لادخال رابط فيسبوك - يوتيوب - انستغرام - تويتر أو رقم هاتف ضمن
                الجواب يجب اتباع المثال التالي{" "}
              </span>
              <div className="form-group text-right col-12  mb-4">
                <div>
                  <textarea
                    readOnly="true"
                    rows="6"
                    className={classnames("form-control", "text-right", {
                      "is-invalid": this.state.error2,
                    })}
                    placeholder=""
                  >{`فيديو يوتيوب
            <<=https://www.youtube.com/channel/UCHc5tI-ELOt7q6ryCoDB_yw/videos;موقع عقار على اليوتيوب;youtube=>>
             صفحة فيسبوك 
            <<=https://www.facebook.com/Ikarsy-109054930712834;افضل موقع عربي للعقارات;facebook=>>
            رقم اتصال 
            <<=00963923456789;اتصل بنا;mobile=>>`}</textarea>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 md-12 m-auto">
                <button
                  className="btn  submit-button m-1 text-center btn-block"
                  //  style={{ position: "right" }}
                  onClick={this.onEditSubmit.bind(this, "submit")}
                >
                  تعديل الاقتراح
                </button>
              </div>
            </div>
          </div>
        ) : resType === "not complete" ? (
          <NotFound></NotFound>
        ) : null}
      </div>
    );
  }
}

EditABOUT.propTypes = {
  editAbout: PropTypes.func.isRequired,
  getAbout: PropTypes.func.isRequired,
  about: PropTypes.object.isRequired,
  requestFailed: PropTypes.bool.isRequired,
  requestFailedMessage: PropTypes.object.isRequired,
  isTakingAgencyRole: PropTypes.bool.isRequired,
  ikarAdmin: PropTypes.bool.isRequired,
  requestSuccessMessage: PropTypes.string.isRequired,
  short_id: PropTypes.string.isRequired,
  resType: PropTypes.string.isRequired,
};
const mapStateToProps = (state) => ({
  ////reducers
  about: state.about.about,
  requestFailed: state.about.requestFailed,
  requestFailedMessage: state.about.requestFailedMessage,
  isTakingAgencyRole: state.auth.isTakingAgencyRole,
  takenAgencyRoleToken: state.auth.takenAgencyRoleToken,
  ikarAdmin: state.auth.ikarAdmin,
  requestSuccessMessage: state.aboutUD.requestSuccessMessage,
  requestMessage: state.about.requestMessage,
  short_id: state.aboutUD.short_id,
  resType: state.about.resType,
  directory: state.about.directory,
});
export default connect(mapStateToProps, {
  editAbout,
  getAbout,
})(EditABOUT);
