import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Accordion, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';
import jQuery from 'jquery';
import { PropTypes } from 'prop-types';
import { loginProcess } from '../actions/authActions';
import { getJoins} from '../actions/joinUsAction'; 
//import { editFaq } from '../actions/faqActionsUD';
// import { Button } from 'primereact/button';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { takeAgencyRole } from '../actions/authActions';
class JoinUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: 'Ikar.sy | للانضمام إلى فريق',
      pageDescription:
        'سوق العقارات الإلكتروني في سورية Ikar.sy مخصص لجمع عروض بيع، شراء و تأجير العقارات من جميع مكاتب العقارات في سورية. الإعلان على Ikar.sy مجاني و متاح فقط للمكاتب العقارية والمتعهدين المسجلين لدى الموقع في جميع المحافظات السورية. | Ikar.sy',
        pageTitle: '',
        pageDescription: '',
        selectedFaq: {},
        requestSuccessMessage: '',
        deleteSuccessMessage: '',
        joinus: [],
        updatedReferenceId: '',
        updatedRank: '',
        total_joinus: 0,
        pageNumber: 1,
        bgURL: '',
        title: [],
        content: [],
        showMainPhoto: false,
    };
  }

  componentWillReceiveProps(nextProps, nextState) {
    let result2 = [];
    let result3 = [];
    if (
      nextProps.joinus !== this.state.joinus &&
      nextProps.joinus[0] !== undefined &&
      nextProps.joinus[0] !== null
    ) {
      // result2=nextProps.joinus.title;
      // result3=nextProps.joinus.content;
      nextProps.joinus.forEach((obj, index) => {
        // if (obj.visible === true) {
        result2.push(obj.title);
      });
      nextProps.joinus.forEach((obj, index) => {
        // if (obj.visible === true) {
        result3.push(obj.content); 
      });
      this.setState({
        joinus: nextProps.joinus,
        title: result2,
        content: result3,
         total_joinus: nextProps.joinus[0].total_joinus,
      });
    } else {
      this.setState({
        joinus: [],
        total_joinus: 0,
      });
    }    
  }

  changeButton = () => {
    jQuery(function ($) {
      var $button = $('.btn-link');
      if ($button.length > 0) {
        $button.css({ width: '100%', height: '100%', textAlign: 'right' });
      }
    });
  };

  componentDidMount() {
   
    this.props.getJoins();
    
  }

 
  render() {
    this.changeButton();
    let res = {};
    const {
      joinus,
      total_joinus,
      pageNumber,
      bgURL,
      showMainPhoto,
      title,
    } = this.state;
    
    return (
      <div>
        <Helmet>
          <title>{this.state.pageTitle}</title>
          <meta name="description" content={this.state.pageDescription} />
        </Helmet>
        <div className="lead text-justify " style={{ direction: 'rtl' }}>
        
          <span className="display_faq">انضم إلينا:</span>
        </div>
        
        {this.state.joinus.length === 0 ? (
          <div
            style={{ fontSize: 20, direction: 'rtl' }}
            className=" lead text-justify black_with_shadow faq-margin my-2"
          >
            لا يمكنك الانضمام حالياً
          </div>
        ) : (
          <Accordion defaultActiveKey="0">
            {(this.state.joinus || []).map((join, index) => (
              <Card style={{ direction: 'rtl', textAlign: 'start' }}>
                <Card.Header>
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey={index}
                    style={{ textDecoration: 'none' }}
                  >
                    <span className="display_faq_question">{
                      join.title
                    }</span>
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey={index}>
                  <Card.Body>
                    {join.content.split(/[<<>>]/).map((element) =>
                      element[0] !== '=' ? (
                        <spen>{element}</spen>
                      ) : element
                          .split(';')[2]
                          .substring(0, element.split(';')[2].length - 1) ===
                        'facebook' ? (
                        <a href={element.split(';')[0].substring(1)}>
                          {element.split(';')[1]}
                          <span>
                            <FontAwesomeIcon
                              icon={['fab', 'facebook']}
                              size="x"
                              style={{ color: '#3b5998' }}
                            />
                          </span>
                        </a>
                      ) : element
                          .split(';')[2]
                          .substring(0, element.split(';')[2].length - 1) ===
                        'youtube' ? (
                        <a href={element.split(';')[0].substring(1)}>
                          {element.split(';')[1]}
                          <span>
                            <FontAwesomeIcon
                              icon={['fab', 'youtube']}
                              size="x"
                              style={{ color: '#c4302b' }}
                            />
                          </span>
                        </a>
                      ) : element
                          .split(';')[2]
                          .substring(0, element.split(';')[2].length - 1) ===
                        'twitter' ? (
                        <a href={element.split(';')[0].substring(1)}>
                          {element.split(';')[1]}
                          <span>
                            <FontAwesomeIcon
                              icon={['fab', 'twitter']}
                              size="x"
                              style={{ color: '#c4302b' }}
                            />
                          </span>
                        </a>
                      ) : element
                          .split(';')[2]
                          .substring(0, element.split(';')[2].length - 1) ===
                        'instagram' ? (
                        <a href={element.split(';')[0].substring(1)}>
                          {element.split(';')[1]}
                          <span>
                            <FontAwesomeIcon
                              icon={['fab', 'instagram']}
                              size="x"
                              style={{ color: '#c4302b' }}
                            />
                          </span>
                        </a>
                      ) : element
                          .split(';')[2]
                          .substring(0, element.split(';')[2].length - 1) ===
                        'mobile' ? (
                        <a
                          href={`tel:${element.split(';')[0].substring(1)}`}
                          // onClick="ga('send', 'event', { eventCategory: 'Contact', eventAction: 'Call', eventLabel: 'Mobile Button'});"
                          style={{ color: '#f18805' }}
                        >
                          <span className="show-property-link">
                            {element.split(';')[1]}
                          </span>
                        </a>
                      ) : null
                    )}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
          </Accordion>
        )}

      
      </div>
         );
  }
}
JoinUs.propTypes = {
  joinus: PropTypes.array.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
//   ikarAdmin: PropTypes.bool.isRequired,
// //   editFaq: PropTypes.func.isRequired,
  getFaqs: PropTypes.func.isRequired,
// //   deleteFaq: PropTypes.func.isRequired,
  requestSuccessMessage: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  joinus: state.join.joinus,
  isLoggedIn: state.auth.isLoggedIn,
  ikarAdmin: state.auth.ikarAdmin,
 // requestSuccessMessage: state.faqUD.requestSuccessMessage,
  deleteSuccessMessage: state.join.deleteSuccessMessage,
//   short_id: state.agencyUD.short_id,
//   short_id2: state.agency.short_id2,
});
export default compose(
    withRouter,
    connect(mapStateToProps, {
      loginProcess,
      getJoins,
      takeAgencyRole,
    })
)(JoinUs);
