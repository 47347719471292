import React, { Component } from "react";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import shortid from "shortid";
import { recordsPerPage } from "./../../../mainConf/index";
import { removeParam } from "./../../../mainConf/functions.jsx";
import CurrencyFormat from "react-currency-format";
import queryString from "query-string";
import { linkDomain } from "./../../../mainConf";
class PaginationForProperties extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      recordsNumber: 0,
      recordsPerPage: 3,
      pagesNumber: 0,
      visiblePagesNumber: 8,
      resultLabel: "",
      showMessage: false,
      pRange: [],
      locationSearch: "",
      initlocationSearch: "",
      isMobile: false,
      //,extraPages: false
    };
  }

  resize() {
    this.setState({
      isMobile: window.innerWidth < 432,
    });
    if (window.innerWidth < 432) {
      localStorage.setItem("isMobile", "true");
    } else {
      localStorage.setItem("isMobile", "false");
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("resize", this.resize.bind(this));
  }
  componentDidMount() {
    this._isMounted = true;

    if (this._isMounted) {
      window.addEventListener("resize", this.resize.bind(this));
      // window.addEventListener(
      //   'resize',
      //   () => {
      //     this.setState({
      //       isMobile: window.innerWidth < 432,
      //     });
      //     if (window.innerWidth < 432) {
      //       localStorage.setItem('isMobile', 'true');
      //     } else {
      //       localStorage.setItem('isMobile', 'false');
      //     }
      //   },
      //   false
      // );
    }
  }

  test = () => {
    if (window.innerWidth < 432) {
      localStorage.setItem("isMobile", "true");
      return true;
    } else {
      localStorage.setItem("isMobile", "false");
      return false;
    }
  };
  isNumeric = (num) => {
    return /^\d+$/.test(num);
  };
  async componentWillReceiveProps(nextProps, nextState) {
    var pageNumber = 1;
    if (nextProps.locationSearch !== this.state.initlocationSearch) {
      if (this._isMounted) {
        await this.setState({ initlocationSearch: nextProps.locationSearch });
      }
      let searchUrlParams = queryString.parse(nextProps.locationSearch);
      let lowerSearchURLParams = {};
      await Object.keys(searchUrlParams).forEach(function (key, value) {
        lowerSearchURLParams[key.toLowerCase()] = searchUrlParams[key];
      });
      searchUrlParams = lowerSearchURLParams;
      if (
        searchUrlParams.page !== undefined &&
        this.isNumeric(searchUrlParams.page)
      ) {
        pageNumber = searchUrlParams.page;
        // if (pageNumber > this.state.pagesNumber) {
        //   pageNumber = this.state.pagesNumber;
        // }
      }

      if (nextProps.locationSearch !== undefined) {
        var locationSearch = await removeParam(
          "page",
          nextProps.locationSearch
        );
        if (this._isMounted) {
          await this.setState({ locationSearch });
        }
      }
      if (this._isMounted) {
        await this.onPageClick(parseInt(pageNumber), null);
      }
    }

    if (
      nextProps.recordsNumber !== this.state.recordsNumber &&
      nextProps.recordsNumber !== -1
    ) {
      const pagesNumber = Math.ceil(nextProps.recordsNumber / recordsPerPage);
      //let extraPages = pagesNumber <= this.state.visiblePagesNumber ? false : true;
      //here
      if (parseInt(this.state.currentPage) <= parseInt(pagesNumber)) {
        pageNumber = this.state.currentPage;
      } else {
        pageNumber = pagesNumber;
      }
      this.setPaginationRange(pagesNumber, this.state.currentPage);
      if (nextProps.showMessage !== undefined) {
        this.setState({
          recordsNumber: parseInt(nextProps.recordsNumber),
          recordsPerPage: recordsPerPage,
          pagesNumber,
          resultLabel: nextProps.resultLabel,
          showMessage: nextProps.showMessage,
          // currentPage: pageNumber
        });
      } else {
        this.setState({
          recordsNumber: nextProps.recordsNumber,
          recordsPerPage: recordsPerPage,
          pagesNumber,
          resultLabel: nextProps.resultLabel,
          // currentPage: pageNumber
          //,extraPages
        });
      }
    }
    // if (
    //   nextProps.pageNumber !== this.state.currentPage &&
    //   nextProps.pageNumber === 1
    // ) {
    //   this.setState({ currentPage: 1 });
    //   this.onPageClick(1, null);
    // }
  }
  setPaginationRange = (pagesNumber, currentPage, e) => {
    var counter = 0;
    var min = 1;
    var max = pagesNumber;
    for (var i = currentPage - 4; i <= currentPage + 4; i++) {
      for (var j = 1; j <= 4; j++) {
        if (counter < 4) {
          if (currentPage - j > 0) {
            min = currentPage - j;
            counter += 1;
          }
          if (currentPage + j <= pagesNumber) {
            max = currentPage + j;
            counter += 1;
          }
        }
      }
    }
    var pRange = [];
    for (var c = min; c <= max; c++) {
      pRange.push(c);
    }

    this.setState({ pRange });
  };
  onPageClick = async (pageNumber, e) => {
    if (e !== null) {
      e.preventDefault();
    }
    this.setState({ currentPage: pageNumber });
    // var locationSearch = `${this.state.locationSearch}&page=${pageNumber}`;
    // await this.props.onPageChange(locationSearch);
    await this.setPaginationRange(this.state.pagesNumber, pageNumber);
  };
  onPreviousClick = async (e) => {
    e.preventDefault();
    var currentPage = this.state.currentPage - 1;
    this.setState({ currentPage });
    // var locationSearch = `${this.state.locationSearch}&page=${currentPage}`;
    // await this.props.onPageChange(locationSearch);
    await this.setPaginationRange(this.state.pagesNumber, currentPage);
  };
  onNextClick = async (e) => {
    e.preventDefault();
    var currentPage = this.state.currentPage + 1;
    this.setState({ currentPage });
    // var locationSearch = `${this.state.locationSearch}&page=${currentPage}`;
    // await this.props.onPageChange(locationSearch);
    await this.setPaginationRange(this.state.pagesNumber, currentPage);
  };
  render() {
    const {
      recordsNumber,
      // recordsPerPage,
      currentPage,
      pagesNumber,
      //,extraPages
      resultLabel,
      showMessage,
      pRange,
    } = this.state;
    const className = this.test() === true ? "pagination-sm" : "";
    const className2 = this.test() === true ? "page_link_sm2" : "page_link_sm";
    const className3 = this.test() === true ? "marginr" : "";
    console.log("pRange=", pRange);
    return (
      <nav
        style={{ direction: "rtl" }}
        className={classnames("mt-2", className3)}
      >
        <ul
          className={classnames(
            "pagination",
            "justify-content-center",
            className
          )}
        >
          {recordsNumber > 0 ? (
            <li
              className={classnames("page-item", "ml-2", "noselect", {
                disabled: currentPage === 1,
              })}
            >
              <a
                href={
                  currentPage > 1
                    ? `${linkDomain}${
                        this.props.destination
                      }?${this.state.locationSearch.replace(
                        "?",
                        ""
                      )}&page=${parseInt(this.state.currentPage) - 1}`
                    : "#!"
                }
                className={classnames(
                  "page-link",
                  "page_link_pervious",
                  "noselect",
                  {
                    display_gold: currentPage > 1,
                  }
                )}
              >
                {/* <Link
                to={{
                  pathname: currentPage > 1 ? this.props.destination : '#!',
                  // query: { search_mode: 'min' }
                  search:
                    currentPage > 1
                      ? `${this.state.locationSearch}&page=${
                          parseInt(this.state.currentPage) - 1
                        }`
                      : '',
                }}
                className={classnames(
                  'page-link',
                  'page_link_pervious',
                  'noselect',
                  {
                    display_gold: currentPage > 1,
                  }
                )}
                //tabIndex="-1"
                // onClick={this.onPreviousClick}
              > */}
                <FontAwesomeIcon
                  icon={["fas", "angle-right"]}
                ></FontAwesomeIcon>
                {currentPage > 1 ? (
                  <span>
                    {` `}السابق{` `}
                  </span>
                ) : null}
                {/* </Link> */}
              </a>
            </li>
          ) : null}

          {!pRange.includes(1) && this.state.pagesNumber > 0 && (
            <li className={classnames("page-item", "ml-2")}>
              <a
                href={`${linkDomain}${
                  this.props.destination
                }?${this.state.locationSearch.replace("?", "")}`}
                className={classnames("page-link", "noselect", className2)}
              >
                1
              </a>
            </li>
          )}
          {!pRange.includes(1) && this.state.pagesNumber > 0 && (
            <li
              key={shortid.generate()}
              className={classnames("page-item", "display_gold", "ml-2")}
              style={{ fontWeight: "bold" }}
            >
              ...
            </li>
          )}

          {pRange.map((element, index) => {
            return (
              <li
                key={shortid.generate()}
                className={classnames("page-item", "ml-2", {
                  active: currentPage === element,
                })}
              >
                <a
                  href={`${linkDomain}${
                    this.props.destination
                  }?${this.state.locationSearch.replace(
                    "?",
                    ""
                  )}&page=${element}`}
                  className={classnames("page-link", "noselect", className2)}
                >
                  {/* <Link
                  to={{
                    pathname: this.props.destination,
                    // query: { search_mode: 'min' }
                    search: `${this.state.locationSearch}&page=${element}`,
                  }}
                  className={classnames('page-link', 'noselect', className2)}
                  // onClick={this.onPageClick.bind(this, element)}
                > */}
                  {element}
                  {/* </Link> */}
                </a>
              </li>
            );
          })}
          {/* {Array.apply(1, Array(pagesNumber)).map((element, index) => { */}
          {!pRange.includes(this.state.pagesNumber) &&
            this.state.pagesNumber > 0 && (
              <li
                key={shortid.generate()}
                className={classnames("page-item", "display_gold", "ml-2")}
                style={{ fontWeight: "bold" }}
              >
                ...
              </li>
            )}

          {!pRange.includes(this.state.pagesNumber) &&
            this.state.pagesNumber > 0 && (
              <li className={classnames("page-item", "ml-2")}>
                <a
                  href={`${linkDomain}${
                    this.props.destination
                  }?${this.state.locationSearch.replace("?", "")}&page=${
                    this.state.pagesNumber
                  }`}
                  className={classnames("page-link", "noselect", className2)}
                >
                  {this.state.pagesNumber}
                </a>
              </li>
            )}

          {recordsNumber > 0 ? (
            <li
              className={classnames("page-item", "noselect", {
                disabled: currentPage === pagesNumber,
              })}
            >
              <a
                href={
                  currentPage < pagesNumber
                    ? `${linkDomain}${
                        this.props.destination
                      }?${this.state.locationSearch.replace(
                        "?",
                        ""
                      )}&page=${parseInt(this.state.currentPage) + 1}`
                    : "#!"
                }
                className={classnames(
                  "page-link",
                  "page_link_next",
                  "noselect",
                  {
                    display_gold: currentPage < pagesNumber,
                  }
                )}
              >
                {/* <Link
                to={{
                  pathname:
                    currentPage < pagesNumber ? this.props.destination : '#!',
                  // query: { search_mode: 'min' }
                  search:
                    currentPage < pagesNumber
                      ? `${this.state.locationSearch}&page=${
                          parseInt(this.state.currentPage) + 1
                        }`
                      : '',
                }}
                className={classnames(
                  'page-link',
                  'page_link_next',
                  'noselect',
                  {
                    display_gold: currentPage < pagesNumber,
                  }
                )}
                // onClick={this.onNextClick}
              > */}
                {currentPage < pagesNumber ? (
                  <span>
                    {` `}التالي{` `}
                  </span>
                ) : null}
                <FontAwesomeIcon icon={["fas", "angle-left"]}></FontAwesomeIcon>
                {/* </Link> */}
              </a>
            </li>
          ) : null}
        </ul>
        <div className="noselect disabled text-center">
          {showMessage ? (
            recordsNumber > 0 ? (
              <span className="display_7_bold">
                {`(`}
                <CurrencyFormat
                  value={recordsNumber}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={" "}
                />
                {`  نتيجة  )`}
                {resultLabel !== "عقار" ? <span>{resultLabel}</span> : null}
              </span>
            ) : (
              <span className="display_gold display_7">
                {/* لا يوجد نتائج للبحث المدخل */}
              </span>
            )
          ) : null}
        </div>
      </nav>
    );
  }
}

export default PaginationForProperties;
