import React, { Component } from 'react';
import PropTypes from 'prop-types';
import waterMark from './../assets/images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
//import { loginProcess } from '../actions/authActions';
import { getPrivacys, deletePrivacy } from '../actions/privacyAction';
import { linkDomain } from '../mainConf/index';
import { Growl } from 'primereact/growl';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { ResReady, ResNotComplete } from './../components/layout/resTypes';
class Privacy extends Component {
  constructor(props) {
   
    super();
    this.state = {
      pageTitle: 'Ikar.sy |سياسة الخصوصية',
      pageDescription:'  صفحة سياسة الخصوصية الخاصة بسوق العقارات الأول في سوريا |Ikar.sy',
      privacys: {},
      privacy: {},
      total_privacys: 0,
      deleteSuccessMessage: '',
      updatedReferenceId: '',
      selectedPrivacy: {},
      resType: '',
    };
  }

  componentDidMount() {
    if (this.props.ikarAdmin) this.props.getPrivacys(true);
    else {
      this.props.getPrivacys(false);
    

    }
  }

  // export = () => {
  //   this.dt.exportCSV();
  // };

  onEditPrivacy = async (e) => {
    if (!window.confirm('تعديل سياسة الخصوصية المحددة ؟')) {
      e.preventDefault();
      return;
    }
  };

  showDetailsTemplate = (rowData, column) => {
    return (
      <React.Fragment>
  
        <Link
          to={`/adminDashBoard/edit_privacy/${rowData.reference_id}`}
        >
          <Button
            // icon="fa fa-edit"
            //iconPos="right"
            className="p-button-warning btn_process"
            tooltip="تعديل سياسة الخصوصية"
            tooltipOptions={{
              position: 'bottom',
              event: 'hover',
            }}
            onClick={this.onEditPrivacy.bind(this)}
           // disabled={rowData.business_status === 'مؤرشف'}
          >
            <FontAwesomeIcon icon={['fas', 'edit']}></FontAwesomeIcon>
          </Button>
        </Link>
      </React.Fragment>
    );
  };

  onDeletePrivacy = async (reference_id, e) => {
    let processType = 'حذف سياسة الخصوصية المحددة';
    if (!window.confirm('حذف سياسة الخصوصية المحددة ؟')) {
      return;
    }
    await this.setState({
      deleteSuccessMessage: 'ready',
      updatedReferenceId: reference_id,
    });
    await this.props.deletePrivacy(reference_id, processType);
  };

  componentWillReceiveProps(nextProps, nextState) {
    let result2 = [];

    if (
      nextProps.resType !== this.state.resType
    ){
      this.setState({
        resType: nextProps.resType,
      });
    }
    if (
      nextProps.privacys !== this.state.privacys 
      // &&nextProps.privacys[0] !== undefined &&
      // nextProps.privacys[0] !== null
    ) {
      
      this.setState({
        resType: nextProps.resType,
        privacys: nextProps.privacys,
        total_privacys: 1,
      });
    } else {
      this.setState({
        privacys: {},
        total_privacys: 0,
      });
    }

    if (
      nextProps.privacy !== this.state.privacy
      // nextProps.privacy !== undefined &&
      // nextProps.privacy !== null
    ) {
      
      this.setState({
        resType: nextProps.resType,
        privacy: nextProps.privacy,
      });
    } else {
      this.setState({
        privacy: {},
      });
    }

    if (
      nextProps.deleteSuccessMessage !== this.state.deleteSuccessMessage &&
      this.state.deleteSuccessMessage !== '' &&
      nextProps.deleteSuccessMessage !== undefined &&
      nextProps.deleteSuccessMessage !== ''
    ) {
      this.setState({
        deleteSuccessMessage: nextProps.deleteSuccessMessage,
      });
      switch (nextProps.deleteSuccessMessage) {
        case 'حذف سياسة الخصوصية المحددة':
          this.growl.show({
            severity: 'success',
            summary: 'تم حذف سياسة الخصوصية المحددة',
          });
          
            if (this.state.privacys.reference_id !== this.state.updatedReferenceId) {
              result2.push(this.state.privacys);
            }
;
          this.setState({ privacys: result2 });
          break;

        default:
          break;
      }
    }
  }

  render() {
    
    const { privacy, privacys } = this.state;
   
    return (
      <div>
        <Helmet>
          <title>{this.state.pageTitle}</title>
          <meta name="description" content={this.state.pageDescription} />
        </Helmet>
        <br />
    
        {/* <h1 className="display-4 logo_color text-center text-uppercase">
          Ikar.sy
        </h1> */}

        <br />
        {this.props.ikarAdmin ? (
          <div className="property-container">
            <div className="container-fluid">
              <Growl ref={(el) => (this.growl = el)} />
              {/* row justify-content-around align-items-center  */}
              <div className="table_container mt-1">
                <DataTable
                  ref={(el) => {
                    this.dt = el;
                  }}
                  value={privacys}
                  responsive={true}
                  //header="المكاتب العقارية"
                  selectionMode="single"
                  selection={this.state.selectedPrivacy}
                  onSelectionChange={(e) => {
                    this.setState({ selectedPrivacy: e.value });
                  }}
                  // header={joinHeader}
                >
                  <Column
                    field="content"
                    header="التفاصيل"
                    sortable={true}
                    className="table_td"
                    // style={{ width: "150px" }}
                  />

                  <Column
                    body={this.showDetailsTemplate}
                    // header="الاجراءات"
                    style={{ width: '150px' }}
                    className="table_td table-functions"
                  />
                </DataTable>
                <br />
              </div>
            </div>
          </div>
        ) : this.state.resType === 'complete' ? (
          <div className="lead text-justify" style={{ direction: 'rtl' , whiteSpace: "pre-wrap"  }}>
             {privacy.content.replaceAll(
                                                              " 2-", "\n2-")
                                                          .replaceAll(
                                                              " 3-", "\n3-")
                                                          .replaceAll(
                                                              " 4-", "\n4-")
                                                          .replaceAll(
                                                              "5-", "\n5-")
                                                          .replaceAll(
                                                              "6-", "\n6-")
                                                          .replaceAll(
                                                              "7-", "\n7-")
                                                          .replaceAll(
                                                              "8-", "\n8-")
                                                          .replaceAll(
                                                              "9-", "\n9-")
                                                          .replaceAll(
                                                              "10-", "\n10-")
                                                          .replaceAll(
                                                              "11-", "\n11-")
                                                          .replaceAll(
                                                              "12-", "\n12-")
                                                              .replaceAll(
                                                                "12 -", "\n12-")
                                                          .replaceAll(
                                                              "13-", "\n13-")
                                                          .replaceAll(
                                                              "14-", "\n14-")}
      

            <br />
            <br />
            <br />
          </div>
        ) : this.state.resType === 'not complete' ? (
          <ResNotComplete></ResNotComplete>
        ) : this.state.resType === 'ready' ? (
          <ResReady></ResReady>
        ) : (
          <div>
            <br />
            <br />
            <br />
            <br />
          </div>
        )}
        <div className="text-center">
          <img src={waterMark} alt="" />
        </div>
        {/* {this.props.ikarAdmin ? (
          <div>
            <button
              className={classnames(
                "btn",
                "px-0",
                { "submit-search-button": this.state.largeScreen },
                {
                  "submit-search-button-min": !this.state.largeScreen,
                }
              )}
                onClick={this.onDeletePrivacy.bind(
                this,
                privacy.reference_id,
                'remove_faq'
              )}
            >
              حذف
            </button>
          </div>
        ) : null}  */}
      </div>
    );
  }
}
Privacy.propTypes = {
  privacys: PropTypes.object.isRequired,
  privacy: PropTypes.object.isRequired,
  ikarAdmin: PropTypes.bool.isRequired,
  // ikarEmail: PropTypes.string.isRequired,
  // agencyAdmin: PropTypes.bool.isRequired,
  //takenAgencyRoleEmail: PropTypes.string.isRequired,
  // isTakingAgencyRole: PropTypes.bool.isRequired,
  getPrivacys: PropTypes.func.isRequired,
  deletePrivacy: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  ikarAdmin: state.auth.ikarAdmin,
  privacys: state.privacy.privacys,
  privacy: state.privacy.privacy,
  deleteSuccessMessage: state.privacy.deleteSuccessMessage,
  resType: state.privacy.resType,
});
//export default Privacy;
export default connect(mapStateToProps, { getPrivacys,  deletePrivacy })(Privacy);
