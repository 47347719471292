import {
  DO_SEARCH,
  GET_PROPERTIES_FOR_RENT,
  GET_PROPERTIES_FOR_SALE,
  GET_PROPERTIES,
  GET_PROPERTIES_BY_AGENCY,
  GET_PROPERTIES_BY_USER,
  GET_PROPERTIES_LATEST,
  GET_PROPERTIES_MOST_VIEWED,
  GET_PROPERTIES_PER_PROVINCE,
  GET_PROPERTIES_SPECIAL_OFFER,
  // GET_AGENCIES,
  // GET_USERS,
  DO_AGENCY_SEARCH,
  DO_AGENCY_PUBLIC_SEARCH,
  DO_USER_SEARCH
} from '../actions/types';
import shortid from 'shortid';
const initialState = {
  properties: [],
  propertiesLatest: [],
  propertiesMostViewed: [],
  propertiesPerProvince: [],
  propertiesSpecialOffer: [],
  property: {},
  agencies: [],
  agency: {},
  users: [],
  user: {},
  requestFailed: false,
  requestFailedMessage: {},
  requestSuccessMessage: '',
  short_id2: '',
  short_id6: shortid.generate(),
  short_id: '',
  searchParams: {},
  currentProvince: '',
  resType: 'ready',
  pageNumber: '1'
};

export default function(state = initialState, action) {
  switch (action.type) {
    // case GET_PROPERTIES:
    //   return {
    //     ...state
    //     ///  , properties: action.payload
    //   };
    case DO_SEARCH:
      if (
        Object.keys(action.payload).length > 0 &&
        (action.payload.errorMessage === '' ||
          action.payload.errorMessage === undefined)
      ) {
        return {
          ...state,
          properties: action.payload,
          //add state data
          property: {},
          requestFailed: false,
          requestFailedMessage: {},
          requestSuccessMessage: '',
          searchParams: action.payload.searchParameters,
          pageNumber: action.payload.pageNumber,
          resType: action.payload.resType
          //
        };
      } else {
        return {
          ...state,
          properties: [],
          requestFailed: true,
          requestFailedMessage: action.payload,
          //add state data
          property: {},
          requestSuccessMessage: '',
          searchParams: {},
          pageNumber: 1,
          resType: action.payload.resType
          //
        };
      }

    case GET_PROPERTIES_LATEST:
      if (
        Object.keys(action.payload).length > 0 &&
        (action.payload.errorMessage === '' ||
          action.payload.errorMessage === undefined)
      ) {
        return {
          ...state,
          propertiesLatest: action.payload,
          //add state data
          property: {},
          requestFailed: false,
          requestFailedMessage: {},
          requestSuccessMessage: '',
          searchParams: action.payload.searchParameters,
          pageNumber: action.payload.pageNumber
          //
        };
      } else {
        return {
          ...state,
          propertiesLatest: [],
          requestFailed: true,
          requestFailedMessage: action.payload,
          //add state data
          property: {},
          requestSuccessMessage: '',
          searchParams: {},
          pageNumber: 1
          //
        };
      }

    case GET_PROPERTIES_MOST_VIEWED:
      if (
        Object.keys(action.payload).length > 0 &&
        (action.payload.errorMessage === '' ||
          action.payload.errorMessage === undefined)
      ) {
        return {
          ...state,
          propertiesMostViewed: action.payload,
          //add state data
          property: {},
          requestFailed: false,
          requestFailedMessage: {},
          requestSuccessMessage: '',
          searchParams: action.payload.searchParameters,
          pageNumber: action.payload.pageNumber
          //
        };
      } else {
        return {
          ...state,
          propertiesMostViewed: [],
          requestFailed: true,
          requestFailedMessage: action.payload,
          //add state data
          property: {},
          requestSuccessMessage: '',
          searchParams: {},
          pageNumber: 1
          //
        };
      }

    case GET_PROPERTIES_PER_PROVINCE:
      if (
        Object.keys(action.payload).length > 0 &&
        (action.payload.errorMessage === '' ||
          action.payload.errorMessage === undefined)
      ) {
        return {
          ...state,
          propertiesPerProvince: action.payload,
          //add state data
          property: {},
          requestFailed: false,
          requestFailedMessage: {},
          requestSuccessMessage: '',
          searchParams: action.payload.searchParameters,
          currentProvince: action.payload.currentProvince,
          pageNumber: action.payload.pageNumber
          //
        };
      } else {
        return {
          ...state,
          propertiesPerProvince: [],
          requestFailed: true,
          requestFailedMessage: action.payload,
          //add state data
          property: {},
          requestSuccessMessage: '',
          searchParams: {},
          currentProvince: '',
          pageNumber: 1
          //
        };
      }

    case GET_PROPERTIES_SPECIAL_OFFER:
      if (
        Object.keys(action.payload).length > 0 &&
        (action.payload.errorMessage === '' ||
          action.payload.errorMessage === undefined)
      ) {
        return {
          ...state,
          propertiesSpecialOffer: action.payload,
          //add state data
          property: {},
          requestFailed: false,
          requestFailedMessage: {},
          requestSuccessMessage: '',
          searchParams: action.payload.searchParameters,
          pageNumber: action.payload.pageNumber
          //
        };
      } else {
        return {
          ...state,
          propertiesSpecialOffer: [],
          requestFailed: true,
          requestFailedMessage: action.payload,
          //add state data
          property: {},
          requestSuccessMessage: '',
          searchParams: {},
          pageNumber: 1
          //
        };
      }

    case GET_PROPERTIES:
      if (
        Object.keys(action.payload).length > 0 &&
        (action.payload.errorMessage === '' ||
          action.payload.errorMessage === undefined)
      ) {
        return {
          ...state,
          properties: action.payload,
          //add state data
          property: {},
          requestFailed: false,
          requestFailedMessage: {},
          requestSuccessMessage: '',
          pageNumber: action.payload.pageNumber
          //
        };
      } else {
        return {
          ...state,
          properties: [],
          requestFailed: true,
          requestFailedMessage: action.payload,
          //add state data
          property: {},
          requestSuccessMessage: '',
          pageNumber: 1
          //
        };
      }
    // case GET_AGENCIES:
    //   if (
    //     Object.keys(action.payload).length > 0 &&
    //     (action.payload.errorMessage === '' ||
    //       action.payload.errorMessage === undefined)
    //   ) {
    //     return {
    //       ...state,
    //       agencies: action.payload,
    //       //add state
    //       agency: {},
    //       requestFailed: false,
    //       requestFailedMessage: {},
    //       short_id2: shortid.generate(),
    //       pageNumber: action.payload.pageNumber
    //     };
    //   } else {
    //     return {
    //       ...state,
    //       agencies: [],
    //       requestFailed: true,
    //       requestFailedMessage: action.payload,
    //       //add state
    //       agency: {},
    //       short_id2: '',
    //       pageNumber: 1
    //     };
    //   }
    case DO_AGENCY_SEARCH:
      if (
        Object.keys(action.payload).length > 0 &&
        (action.payload.errorMessage === '' ||
          action.payload.errorMessage === undefined)
      ) {
        return {
          ...state,
          agencies: action.payload,
          //add state
          agency: {},
          requestFailed: false,
          requestFailedMessage: {},
          short_id2: shortid.generate(),
          resType: action.payload.resType,
          pageNumber: action.payload.pageNumber
        };
      } else {
        return {
          ...state,
          agencies: [],
          requestFailed: true,
          requestFailedMessage: action.payload,
          //add state
          agency: {},
          short_id2: '',
          resType: action.payload.resType,
          pageNumber: 1
        };
      }

    case DO_AGENCY_PUBLIC_SEARCH:
      if (
        Object.keys(action.payload).length > 0 &&
        (action.payload.errorMessage === '' ||
          action.payload.errorMessage === undefined)
      ) {
        return {
          ...state,
          agencies: action.payload,
          //add state
          agency: {},
          requestFailed: false,
          requestFailedMessage: {},
          short_id2: shortid.generate(),
          resType: action.payload.resType,
          pageNumber: action.payload.pageNumber
        };
      } else {
        return {
          ...state,
          agencies: [],
          requestFailed: true,
          requestFailedMessage: action.payload,
          //add state
          agency: {},
          short_id2: '',
          resType: action.payload.resType,
          pageNumber: 1
        };
      }

    // case GET_USERS:
    //   if (
    //     Object.keys(action.payload).length > 0 &&
    //     (action.payload.errorMessage === '' ||
    //       action.payload.errorMessage === undefined)
    //   ) {
    //     return {
    //       ...state,
    //       users: action.payload.users,
    //       ///add state
    //       user: {},
    //       requestFailed: false,
    //       requestFailedMessage: {},
    //       short_id2: shortid.generate(),
    //       pageNumber: action.payload.pageNumber
    //     };
    //   } else {
    //     return {
    //       ...state,
    //       users: [],
    //       requestFailed: true,
    //       requestFailedMessage: action.payload,
    //       ///add state
    //       user: {},
    //       short_id2: '',
    //       pageNumber: 1
    //     };
    //   }

    case DO_USER_SEARCH:
      if (
        Object.keys(action.payload).length > 0 &&
        (action.payload.errorMessage === '' ||
          action.payload.errorMessage === undefined)
      ) {
        return {
          ...state,
          users: action.payload,
          ///add state
          user: {},
          requestFailed: false,
          requestFailedMessage: {},
          short_id2: shortid.generate(),
          pageNumber: action.payload.pageNumber
        };
      } else {
        return {
          ...state,
          users: [],
          requestFailed: true,
          requestFailedMessage: action.payload,
          ///add state
          user: {},
          short_id2: '',
          pageNumber: 1
        };
      }
    case GET_PROPERTIES_BY_AGENCY:
      if (
        Object.keys(action.payload).length > 0 &&
        (action.payload.errorMessage === '' ||
          action.payload.errorMessage === undefined)
      ) {
        return {
          ...state,
          properties: action.payload,
          property: {},
          requestFailed: false,
          requestFailedMessage: {},
          requestSuccessMessage: '',
          pageNumber: action.payload.pageNumber
        };
      } else {
        return {
          ...state,
          properties: [],
          requestFailed: true,
          requestFailedMessage: action.payload,
          property: {},
          requestSuccessMessage: '',
          pageNumber: 1
        };
      }

    case GET_PROPERTIES_BY_USER:
      if (
        Object.keys(action.payload).length > 0 &&
        (action.payload.errorMessage === '' ||
          action.payload.errorMessage === undefined)
      ) {
        return {
          ...state,
          properties: action.payload,
          property: {},
          requestFailed: false,
          requestFailedMessage: {},
          requestSuccessMessage: '',
          pageNumber: action.payload.pageNumber
        };
      } else {
        return {
          ...state,
          properties: [],
          requestFailed: true,
          requestFailedMessage: action.payload,
          property: {},
          requestSuccessMessage: '',
          pageNumber: 1
        };
      }

    case GET_PROPERTIES_FOR_RENT:
      if (
        Object.keys(action.payload).length > 0 &&
        (action.payload.errorMessage === '' ||
          action.payload.errorMessage === undefined)
      ) {
        return {
          ...state,
          properties: action.payload,
          //add state data
          property: {},
          requestFailed: false,
          requestFailedMessage: {},
          requestSuccessMessage: '',
          pageNumber: action.payload.pageNumber
          //
        };
      } else {
        return {
          ...state,
          properties: [],
          requestFailed: true,
          requestFailedMessage: action.payload,
          //add state data
          property: {},
          requestSuccessMessage: '',
          pageNumber: 1
          //
        };
      }

    case GET_PROPERTIES_FOR_SALE:
      if (
        Object.keys(action.payload).length > 0 &&
        (action.payload.errorMessage === '' ||
          action.payload.errorMessage === undefined)
      ) {
        return {
          ...state,
          properties: action.payload,
          //add state data
          property: {},
          requestFailed: false,
          requestFailedMessage: {},
          requestSuccessMessage: '',
          pageNumber: action.payload.pageNumber
          //
        };
      } else {
        return {
          ...state,
          properties: [],
          requestFailed: true,
          requestFailedMessage: action.payload,
          //add state data
          property: {},
          requestSuccessMessage: '',
          pageNumber: 1
          //
        };
      }
    default:
      return state;
  }
}
