import {
  ADD_CONTACT_MESSAGE,
  GET_CONTACT_MESSAGES,
  DELETE_CONTACT_MESSAGES,
  GET_READ_MESSAGES_AGENCY,
} from './types';
import axios from 'axios';
import { mainDomain } from './../mainConf/index';


export const getreadMessagesAgency = (referenceId, token) => async (
  dispatch
) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };

  await axios
    .get(
      `${mainDomain}/v1/contact?reference_id=${referenceId}`,
     
      // ,
      // {
      //   withCredentials: true,
      // }
      {
        headers: {
          Accept: 'application/json',
          Authorization: token,
        },
      }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
      } else {
        res = {};
      }
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
    },
    );
  dispatch({
    type: GET_READ_MESSAGES_AGENCY,
    payload: res,
  });
};


export const addContactMessage = (contactMessage) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  await axios
    .post(
      `${mainDomain}/v1/contact`,
      contactMessage,
      // ,
      // {
      //   withCredentials: true,
      // }
      {
        header: {
          Accept: 'application/json',
          Authorization: localStorage.getItem('ikar_token'),
        },
      }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
      } else res = {};
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
    });
  dispatch({
    type: ADD_CONTACT_MESSAGE,
    payload: res,
  });
};

export const getContactMessages = (contactMessageData, token) => async (
  dispatch
) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };

  await axios
    .post(
      `${mainDomain}/v1/contacts/search`,
      contactMessageData,
      // ,
      // {
      //   withCredentials: true,
      // }
      {
        headers: {
          Accept: 'application/json',
          Authorization: token,
        },
      }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
      } else {
        res = {};
      }
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
    });
  dispatch({
    type: GET_CONTACT_MESSAGES,
    payload: res,
  });
};

export const deleteContactMessages = (
  referenceId,
  token,
  processType
) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  await axios
    .delete(
      `${mainDomain}/v1/contact?reference_id=${referenceId}`,
      // ,
      // {
      //   withCredentials: true,
      // }
      {
        headers: {
          Accept: 'application/json',
          //'Content-Type': 'application/json',
          Authorization: token,
        },
      }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
        res.message = processType;
      } else {
        res = {};
      }
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
    });
  dispatch({
    type: DELETE_CONTACT_MESSAGES,
    payload: res,
  });
};

export const agencyDeleteContactMessages = (
  referenceId,
  token,
  processType
) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  await axios
    .post(
      `${mainDomain}/v1/contact/hide`,
      { contact_message: { reference_id: referenceId } },
      // ,
      // {
      //   withCredentials: true,
      // }
      {
        headers: {
          Accept: 'application/json',
          //'Content-Type': 'application/json',
          Authorization: token,
        },
      }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
        res.message = processType;
      } else {
        res = {};
      }
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
    });
  dispatch({
    type: DELETE_CONTACT_MESSAGES,
    payload: res,
  });
};
