import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Accordion, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';
import jQuery from 'jquery';
import { PropTypes } from 'prop-types';
import { loginProcess } from '../actions/authActions';
import { getFaqs, deleteFaq } from '../actions/faqActions';
import { editFaq } from '../actions/faqActionsUD';
// import { Button } from 'primereact/button';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { takeAgencyRole } from '../actions/authActions';
import { ResReady, ResNotComplete } from './../components/layout/resTypes';
class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: 'Ikar.sy | الأسئلة الشائعة',
      pageDescription:
        'سوق العقارات الإلكتروني في سورية Ikar.sy مخصص لجمع عروض بيع، شراء و تأجير العقارات من جميع مكاتب العقارات في سورية. الإعلان على Ikar.sy مجاني و متاح فقط للمكاتب العقارية والمتعهدين المسجلين لدى الموقع في جميع المحافظات السورية. | Ikar.sy',
      pageTitle: '',
      pageDescription: '',
      selectedFaq: {},
      requestSuccessMessage: '',
      deleteSuccessMessage: '',
      faqs: [],
      ready: 'start',
      updatedReferenceId: '',
      updatedRank: '',
      total_faqs: 0,
      pageNumber: 1,
      bgURL: '',
      title: [],
      details: [],
      showMainPhoto: false,
      resType: '',
    };
  }
  componentWillReceiveProps(nextProps, nextState) {
    let result2 = [];
    let result3 = [];
    if (
      nextProps.resType !== this.state.resType
    ){
      this.setState({
        resType: nextProps.resType,
      });
    }
    if (
      nextProps.faqs !== this.state.faqs &&
      nextProps.faqs[0] !== undefined &&
      nextProps.faqs[0] !== null
    ) {
      nextProps.faqs.forEach((obj, index) => {
        result2.push(obj.title);
      });
      nextProps.faqs.forEach((obj, index) => {
        result3.push(obj.details);
      });
      this.setState({
        resType: nextProps.resType,
        faqs: nextProps.faqs,
        title: result2,
        details: result3,
        total_faqs: nextProps.faqs[0].total_faqs,
      });
    } else {
      this.setState({
        faqs: [],
        total_faqs: 0,
      });
    }
  }
  changeButton = () => {
    jQuery(function ($) {
      var $button = $('.btn-link');
      if ($button.length > 0) {
        $button.css({ width: '100%', height: '100%', textAlign: 'right' });
      }
    });
  };
  componentDidMount() {
    this.props.getFaqs();
  }

  changeButton = () => {
    jQuery(function ($) {
      var $button = $('.btn-link');
      if ($button.length > 0) {
        $button.css({ width: '100%', height: '100%', textAlign: 'right' });
      }
    });
  };
  render() {
    this.changeButton();

    return (
      <div>
        <Helmet>
          <title>{this.state.pageTitle}</title>
          <meta name="description" content={this.state.pageDescription} />
        </Helmet>
        <div className="lead text-justify " style={{ direction: 'rtl' }}>
          <span className="display_faq">الأسئلة الشائعة:</span>
        </div>

        {this.state.resType === 'complete' ? (
          this.state.faqs.length === 0 ? (
            <div
              style={{ fontSize: 20, direction: 'rtl' }}
              className=" lead text-justify black_with_shadow faq-margin my-2"
            >
              لا يوجد أية أسئلة حالياً
            </div>
          ) : (
            <Accordion defaultActiveKey="0">
              {(this.state.faqs || []).map((faq, index) => (
                <Card style={{ direction: 'rtl', textAlign: 'start' }}>
                  <Card.Header>
                    <Accordion.Toggle
                      as={Button}
                      variant="link"
                      eventKey={index}
                      style={{ textDecoration: 'none' }}
                    >
                      <span className="display_faq_question">{`${index + 1} . ${
                        faq.title
                      }`}</span>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey={index}>
                    <Card.Body>
                      {faq.details.split(/[<<>>]/).map((element) =>
                        element[0] !== '=' ? (
                          <spen>{element}</spen>
                        ) : element
                            .split(';')[2]
                            .substring(0, element.split(';')[2].length - 1) ===
                          'facebook' ? (
                          <a href={element.split(';')[0].substring(1)}>
                            {element.split(';')[1]}
                            <span>
                              <FontAwesomeIcon
                                icon={['fab', 'facebook']}
                                size="x"
                                style={{ color: '#3b5998' }}
                              />
                            </span>
                          </a>
                        ) : element
                            .split(';')[2]
                            .substring(0, element.split(';')[2].length - 1) ===
                          'youtube' ? (
                          <a href={element.split(';')[0].substring(1)}>
                            {element.split(';')[1]}
                            <span>
                              <FontAwesomeIcon
                                icon={['fab', 'youtube']}
                                size="x"
                                style={{ color: '#c4302b' }}
                              />
                            </span>
                          </a>
                        ) : element
                            .split(';')[2]
                            .substring(0, element.split(';')[2].length - 1) ===
                          'twitter' ? (
                          <a href={element.split(';')[0].substring(1)}>
                            {element.split(';')[1]}
                            <span>
                              <FontAwesomeIcon
                                icon={['fab', 'twitter']}
                                size="x"
                                style={{ color: '#c4302b' }}
                              />
                            </span>
                          </a>
                        ) : element
                            .split(';')[2]
                            .substring(0, element.split(';')[2].length - 1) ===
                          'instagram' ? (
                          <a href={element.split(';')[0].substring(1)}>
                            {element.split(';')[1]}
                            <span>
                              <FontAwesomeIcon
                                icon={['fab', 'instagram']}
                                size="x"
                                style={{ color: '#c4302b' }}
                              />
                            </span>
                          </a>
                        ) : element
                            .split(';')[2]
                            .substring(0, element.split(';')[2].length - 1) ===
                          'mobile' ? (
                          <a
                            href={`tel:${element.split(';')[0].substring(1)}`}
                            // onClick="ga('send', 'event', { eventCategory: 'Contact', eventAction: 'Call', eventLabel: 'Mobile Button'});"
                            style={{ color: '#f18805' }}
                          >
                            <span className="show-property-link">
                              {element.split(';')[1]}
                            </span>
                          </a>
                        ) : null
                      )}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ))}
            </Accordion>
          )
        ) : this.state.resType === 'not complete' ? (
          <ResNotComplete></ResNotComplete>
        ) : this.state.resType === 'ready' ? (
          <ResReady></ResReady>
        ) : (
          <div>
            <br />
            <br />
            <br />
            <br />
          </div>
        )}
      </div>
    );
  }
}
FAQ.propTypes = {
  faqs: PropTypes.array.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  ikarAdmin: PropTypes.bool.isRequired,
  editFaq: PropTypes.func.isRequired,
  getFaqs: PropTypes.func.isRequired,
  deleteFaq: PropTypes.func.isRequired,
  requestSuccessMessage: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  faqs: state.faq.faqs,
  isLoggedIn: state.auth.isLoggedIn,
  ikarAdmin: state.auth.ikarAdmin,
  requestSuccessMessage: state.faqUD.requestSuccessMessage,
  deleteSuccessMessage: state.faq.deleteSuccessMessage,
  resType: state.faq.resType,
  // short_id: state.agencyUD.short_id,
  // short_id2: state.agency.short_id2,
});
export default compose(
  withRouter,
  connect(mapStateToProps, {
    loginProcess,
    editFaq,
    getFaqs,
    deleteFaq,
    takeAgencyRole,
  })
)(FAQ);
