import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setPath } from './../../actions/settingActions';
import { linkDomain } from './../../mainConf';
import defaultBackground from './../../assets/images/defaultBackground.png';
import aleppoBackground from './../../assets/images/provinces/aleppo.jpg';
import asuwaydaBackground from './../../assets/images/provinces/asuwayda.jpg';
import damascusBackground from './../../assets/images/provinces/damascus.jpg';
import daraaBackground from './../../assets/images/provinces/daraa.jpg';
import deirezorBackground from './../../assets/images/provinces/deirezor.jpg';
import hamaBackground from './../../assets/images/provinces/hama.jpg';
import hasakaBackground from './../../assets/images/provinces/hasaka.jpg';
import homsBackground from './../../assets/images/provinces/homs.jpg';
import idlibBackground from './../../assets/images/provinces/idlib.jpg';
import latakiaBackground from './../../assets/images/provinces/latakia.jpg';
import quneitraBackground from './../../assets/images/provinces/quneitra.jpg';
import raqqaBackground from './../../assets/images/provinces/raqqa.jpg';
import rifDamascusBackground from './../../assets/images/provinces/rifDamascus.jpg';
import tartousBackground from './../../assets/images/provinces/tartous.jpg';
import { Link } from 'react-router-dom';
// import { google } from 'http://maps.google.com/maps/api/js';
//import { Tooltip } from 'primereact/tooltip';
class Province extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentProvince: '',
      propertiesNumber: 0,
      locationSearch: '',
    };
  }

  onHandleEdit = (id, e) => {
    const path = { page: 'edit-property', id: id };
    this.props.setPath(path);
  };
  componentDidMount() {
    if (this.props.main_type !== '' || this.props.business_offer !== '') {
      var search = [];
      if (this.props.main_type !== '') {
        search.push(`main_type=${this.props.main_type}`);
      }

      if (this.props.business_offer !== '') {
        search.push(`business_offer=${this.props.business_offer}`);
      }
      this.setState({ locationSearch: search.join('&') });
    }

    if (this.props.currentProvince !== this.state.currentProvince) {
      this.setState({
        currentProvince: this.props.currentProvince,
        propertiesNumber: this.props.propertiesNumber,
      });
    }
  }
  async componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.main_type !== '' || nextProps.business_offer !== '') {
      var search = [];
      if (nextProps.main_type !== '') {
        search.push(`main_type=${nextProps.main_type}`);
      }

      if (nextProps.business_offer !== '') {
        search.push(`business_offer=${nextProps.business_offer}`);
      }
      await this.setState({ locationSearch: search.join('&') });
    }
  }
  onMapShowClick = (e) => {};
  getNextImage = (e) => {
    e.preventDefault();
    if (this.state.photoIndex !== this.state.url.length - 1) {
      this.setState({ photoIndex: this.state.photoIndex + 1 });
    } else {
      this.setState({ photoIndex: 0 });
    }
  };
  getPreviousImage = (e) => {
    e.preventDefault();

    if (this.state.photoIndex !== 0) {
      this.setState({ photoIndex: this.state.photoIndex - 1 });
    } else {
      this.setState({ photoIndex: this.state.url.length - 1 });
    }
  };

  render() {
    const { currentProvince, propertiesNumber } = this.state;
    var bgURL = defaultBackground;
    if (currentProvince === 'دمشق') {
      bgURL = damascusBackground;
    }
    if (currentProvince === 'حلب') {
      bgURL = aleppoBackground;
    }
    if (currentProvince === 'السويداء') {
      bgURL = asuwaydaBackground;
    }
    if (currentProvince === 'درعا') {
      bgURL = daraaBackground;
    }
    if (currentProvince === 'دير الزور') {
      bgURL = deirezorBackground;
    }
    if (currentProvince === 'حماة') {
      bgURL = hamaBackground;
    }
    if (currentProvince === 'الحسكة') {
      bgURL = hasakaBackground;
    }
    if (currentProvince === 'حمص') {
      bgURL = homsBackground;
    }
    if (currentProvince === 'ادلب') {
      bgURL = idlibBackground;
    }
    if (currentProvince === 'اللاذقية') {
      bgURL = latakiaBackground;
    }
    if (currentProvince === 'القنيطرة') {
      bgURL = quneitraBackground;
    }
    if (currentProvince === 'الرقة') {
      bgURL = raqqaBackground;
    }
    if (currentProvince === 'ريف دمشق') {
      bgURL = rifDamascusBackground;
    }
    if (currentProvince === 'طرطوس') {
      bgURL = tartousBackground;
    }

    return (
      <React.Fragment>
        <div
          // className="col-xl-3 col-lg-4 col-sm-8 mb-3"
          style={{ direction: 'rtl' }}
        >
          <div className="card text-center mb-3 property_card mx-auto">
            <div
              className="card-body m-0 p-0"
              // style={{
              //   background: `url(${bgURL})center no-repeat /cover`
              // }}
            >
              <div className="image-container">
                {/* <Link
                  to={`/properties_per_province/${currentProvince}`}
                  className="mx-auto"
                  onClick={this.props.onProvinceClick}
                > */}
                <a
                  href={`${linkDomain}/properties_per_province/${currentProvince}${
                    this.props.lSearch !== '' ? `?${this.props.lSearch}` : ``
                  }`}
                  className="mx-auto"
                >
                  {/* <Link
                  to={{
                    pathname: `${linkDomain}/properties_per_province/${currentProvince}`,
                    // query: { search_mode: 'min' }
                    search: this.state.locationSearch,
                  }}
                  className="mx-auto"
                > */}
                  <img
                    src={bgURL}
                    alt="profile"
                    className="property-main-image"
                    style={{ height: '296px' }}
                  />
                </a>
                {/* </Link> */}
              </div>
              <div className="property-header-top-right text-left no_select">
                <span className="property-position">{propertiesNumber}</span>
              </div>
              <div className="property-header text-right no_select">
                <span className="property-position">{currentProvince}</span>
              </div>
            </div>
            <div className="card-footer p-0 m-0"></div>
            <div className="card-footer p-0 m-0"></div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Province.propTypes = {
  //property: PropTypes.object.isRequired
  //requestSuccessMessage: PropTypes.string.isRequired
};
const mapStateToProps = (state) => ({
  // isTakingAgencyRole: state.auth.isTakingAgencyRole,
  // takenAgencyRoleToken: state.auth.takenAgencyRoleToken
});
export default connect(mapStateToProps, { setPath })(Province);
