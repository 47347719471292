import {GET_ABOUTS , DELETE_ABOUT , ADD_ABOUT , GET_ABOUT } from './types';
import axios from 'axios';
import { mainDomain } from './../mainConf';


export const getAbouts = (ikarAdmin) => async (dispatch) => {
      let res = {};
      let errorRes = {
        errorStatus: '',
        errorMessage: '',
       
      };
      var token = '';
      if (ikarAdmin) {
        token = localStorage.getItem('ikar_token');
        await axios
          .get(
            `${mainDomain}/v1/about_ikar`,
            // ,
            // {
            //   withCredentials: true,
            // }
            {
              headers: {
                //'Content-Type': 'application/json',
                Accept: 'application/json',
                //Accept: 'application/json',
                Authorization: token,
              },
            }
          )
          .then(function (response) {
            if (
              response !== null &&
              response !== undefined &&
              Object.keys(response).length > 0
            ) {
              res = response.data;
              res.resType = 'complete';
              
            } else res = {};
          })
          .catch(function (error) {
            if (error.response) {
              errorRes = {
                errorStatus: error.response.status,
                errorMessage: error.response,
              };
            } else if (error.request) {
              errorRes = {
                errorMessage: error.request,
              };
            } else {
              errorRes = { errorMessage: error.message };
            }
            res = errorRes;
            res.resType = 'not complete';
          }
          ) ;
      } else {
      await axios
        .get(
          `${mainDomain}/v1/about_ikar`,
           
          {
            headers: {
              Accept: 'application/json'
             // Authorization: localStorage.getItem('ikar_token'),
            },
          }
        )
        .then(function (response) {
          if (
            response !== null &&
            response !== undefined &&
            Object.keys(response).length > 0
          ) {
            res = response.data;
            res.resType = 'complete';
          } else res = {};
           
        })
        .catch(function (error) {
          if (error.response) {
            errorRes = {
              errorStatus: error.response.status,
              errorMessage: error.response,
            };
          } else if (error.request) {
            errorRes = {
              errorMessage: error.request,
            };
          } else {
            errorRes = { errorMessage: error.message };
          }
         
          res = errorRes;
          res.resType = 'not complete';
         
        }
        ) ; 
      
    }
   
      dispatch({
        type: GET_ABOUTS,
        payload: res,
      });
  };

  //////////////////////////////////////////////////////////////////////////////////////

  export const deleteAbout = (reference_id, processType) => async (dispatch) => {
    let res = {};
    let errorRes = {
      errorStatus: '',
      errorMessage: '',
    };
    await axios
      .delete(`${mainDomain}/v1/about_ikar?reference_id=${reference_id}`, {
        headers: {
          Accept: 'application/json',
          Authorization: localStorage.getItem('ikar_token'),
        },
      })
      .then(function (response) {
        if (
          response !== null &&
          response !== undefined &&
          Object.keys(response).length > 0
        ) {
          res = response.data;
          res.message = processType;
        } else {
          res = {};
        }
      })
      .catch(function (error) {
        if (error.response) {
          errorRes = {
            errorStatus: error.response.status,
            errorMessage: error.response,
          };
        } else if (error.request) {
          errorRes = {
            errorMessage: error.request,
          };
        } else {
          errorRes = { errorMessage: error.message };
        }
        res = errorRes;
      });
    dispatch({
      type: DELETE_ABOUT,
      payload: res,
    });
  };
  
  ////////////////////////////////////////////////////////////////////////////////

  export const addAbout = (about) => async (dispatch) => {
    let res = {};
    let errorRes = {
      errorStatus: '',
      errorMessage: '',
    };
    await axios
      .post(
        `${mainDomain}/v1/about_ikar`,
        {about_ikar:about},
        // ,
        // {
        //   withCredentials: true,
        // }
        {
          headers: {
            Accept: 'application/json',
            Authorization: localStorage.getItem('ikar_token'),
          },
        }
      )
      .then(function (response) {
        if (
          response !== null &&
          response !== undefined &&
          Object.keys(response).length > 0
        ) {
          res = response.data;
          res.resType = 'complete';
        } else res = {};
      })
      .catch(function (error) {
        if (error.response) {
          errorRes = {
            errorStatus: error.response.status,
            errorMessage: error.response,
          };
        } else if (error.request) {
          errorRes = {
            errorMessage: error.request,
          };
        } else {
          errorRes = { errorMessage: error.message };
        }
        res = errorRes;
        res.resType = 'not complete';
      });
    dispatch({
      type: ADD_ABOUT,
      payload: res,
    });
  };

 ///////////////////////////////////////////////////////////////////
 export const getAbout = ( aboutReferenceId) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  var token = '';
  var aboutRefId = aboutReferenceId;
    token = localStorage.getItem('ikar_token');
    await axios
      .get(`${mainDomain}/v1/about_ikar/show?reference_id=${aboutRefId}`, {
        headers: {
          //'Content-Type': 'application/json',
          Accept: 'application/json',
          //Accept: 'application/json',
          Authorization: token,
        },
      })
      .then(function (response) {
        if (
          response !== null &&
          response !== undefined &&
          Object.keys(response).length > 0
        ) {
          res = response.data;
          res.resType = 'complete';
        } else res = {};
      })
      .catch(function (error) {
        if (error.response) {
          errorRes = {
            errorStatus: error.response.status,
            errorMessage: error.response,
          };
        } else if (error.request) {
          errorRes = {
            errorMessage: error.request,
          };
        } else {
          errorRes = { errorMessage: error.message };
        }
        res = errorRes;
        res.resType = 'not complete';
      });
  
  dispatch({
    type: GET_ABOUT,
    payload: res,
  });
};