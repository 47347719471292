import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from './../../../assets/images/logo.png';

class Footer extends React.Component {
  handleClick = (str, e) => {
    this.props.onPathChange(str);
  };
  render() {
    return (
      <footer className="main-footer px-5">
        <div className="container-fluid">
          <div className="row text-light py-1"></div>
          <div className="row" style={{ direction: 'rtl' }}>
            <div className="col-md-4  text-center">
              <Link to="/" className="navbar-brand  pt-1">
                <img src={logo} className="logo" alt="" />
              </Link>
            </div>
            <div className="col-md-4  text-center">
              <div className="col text-center text-light">
                <p style={{ direction: 'ltr' }}>
                  Copyright &copy; {new Date().getFullYear()}{' '}
                  <span className="text-danger font-weight-bold">Ikar</span>.
                  All rights reserved.<br></br>
                  <a className="text-light"
                                        href="/privacy"
                                  
                                      >
                                      
                                         Privacy Policy 
                                         {` `}
                                      </a>
                </p>
           
              </div>
           
            </div>
            <div className="col-md-4 text-lg-right text-sm-center text-center">
              <ul className="list-inline  pt-1">
                <li className="list-inline-item mx-1">
                  <a href="https://facebook.com/ikarsy-%D8%B3%D9%88%D9%82-%D8%A7%D9%84%D8%B9%D9%82%D8%A7%D8%B1%D8%A7%D8%AA-%D8%A7%D9%84%D8%A7%D9%84%D9%83%D8%AA%D8%B1%D9%88%D9%86%D9%8A-%D9%81%D9%8A-%D8%B3%D9%88%D8%B1%D9%8A%D8%A9-1534620726816588/">
                    <FontAwesomeIcon
                      icon={['fab', 'facebook']}
                      size="2x"
                      style={{ color: '#3b5998' }}
                    />
                  </a>
                </li>
                <li className="list-inline-item mx-1">
                  <a href="https://twitter.com/ikar_sy">
                    <FontAwesomeIcon
                      icon={['fab', 'twitter']}
                      size="2x"
                      style={{ color: '#00acee' }}
                    />
                  </a>
                </li>
                <li className="list-inline-item mx-1">
                  <a href="https://www.instagram.com/ikar.sy/">
                    <FontAwesomeIcon
                      icon={['fab', 'instagram']}
                      size="2x"
                      style={{ color: '#3f729b' }}
                    />
                  </a>
                </li>
                <li className="list-inline-item mx-1">
                  <a href="https://www.youtube.com/channel/UCHc5tI-ELOt7q6ryCoDB_yw">
                    <FontAwesomeIcon
                      icon={['fab', 'youtube']}
                      size="2x"
                      style={{ color: '#c4302b' }}
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
