import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addUser } from './../../actions/userActions';
import { Growl } from 'primereact/growl';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';

class UserRegistration extends Component {
  constructor(props) {
    super(props);
    document.title = 'إضافة مستخدم | Ikar.sy';
    this.state = {
      pageTitle: '',
      pageDescription: '',
      noMessages: true,
      user: {},
      name: '',
      contact_email: '',
      password: '',
      password_configuration: '',
      isAgencyAdmin: true,
      isIkarAdmin: false,
      surname: '',
      title: '',
      description: '',
      roles: [],
      mobile: '',
      facebook: '',
      whatsapp: '',
      agency_ref_id: '',
      agency_rank: 100,
      errors: {},
    };
  }

  onSubmit = (e) => {
    e.preventDefault();
    const {
      name,
      contact_email,
      password,
      password_configuration,
      isAgencyAdmin,
      isIkarAdmin,
      surname,
      title,
      description,
      // roles,
      mobile,
      facebook,
      whatsapp,
      agency_ref_id,
      agency_rank,
    } = this.state;

    if (contact_email.trim() === '') {
      this.setState({ errors: { contact_email: 'يجب ادخال الايميل' } });
      return;
    }
    // if (!/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/.test(contact_email)) {
    //   errors.contact_email = 'يجب التأكد من الايميل المدخل';
    // }
    if (password.trim() === '') {
      this.setState({ errors: { password: 'يجب ادخال كلمة السر' } });
      return;
    }
    if (password.length < 8) {
      this.setState({
        errors: { password: 'كلمة السر على الاقل ثمانية رموز' },
      });
      return;
    }
    if (password_configuration.trim() === '') {
      this.setState({
        errors: { password_configuration: 'يجب ادخال اعادة كلمة السر' },
      });
      return;
    }
    if (password !== password_configuration) {
      this.setState({
        errors: { password_configuration: 'كلمتا السر غير متطابقتين' },
      });
      return;
    }
    if (name.trim() === '') {
      this.setState({
        errors: { name: 'يجب ادخال الاسم' },
      });
      return;
    }
    if (surname.trim() === '') {
      this.setState({
        errors: { surname: 'يجب ادخال اللقب' },
      });
      return;
    }

    if (title.trim() === '') {
      this.setState({
        errors: { title: 'يجب ادخال العنوان ' },
      });
      return;
    }
    // if (mobile.trim() === '') {
    //   this.setState({
    //     errors: { mobile: 'يجب ادخال رقم الموبايل' }
    //   });
    //   return;
    // }
    // if (facebook.trim() === '') {
    //   this.setState({
    //     errors: { facebook: 'يجب ادخال عنوان صفحة ال فيسبوك ' }
    //   });
    //   return;
    // }
    // if (whatsapp.trim() === '') {
    //   this.setState({
    //     errors: { whatsapp: 'يجب ادخال رقم ال واتس اب ' }
    //   });
    //   return;
    // }
    this.setState({ errors: {} });
    const admin = {
      name,
      contact_email,
      password,
      is_agency_admin: isAgencyAdmin,
      is_ikar_admin: isIkarAdmin,
      surname,
      title,
      description,
      roles: [],
      mobile,
      facebook,
      whatsapp,
      agency_ref_id,
      agency_rank,
    };
    const UserData = { admin };
    this.props.addUser(UserData);
    this.setState({ noMessages: false });
    this.setState({
      noMessages: true,
      name: '',
      contact_email: '',
      password: '',
      password_configuration: '',
      isAgencyAdmin: true,
      isIkarAdmin: false,
      surname: '',
      title: '',
      description: '',
      roles: [],
      mobile: '',
      facebook: '',
      whatsapp: '',
      agency_ref_id: '',
      agency_rank: 100,
      errors: {},
    });
  };
  handleClear = (e) => {
    e.preventDefault();
    this.setState({
      noMessages: true,
      name: '',
      contact_email: '',
      password: '',
      password_configuration: '',
      isAgencyAdmin: true,
      isIkarAdmin: false,
      surname: '',
      title: '',
      description: '',
      roles: [],
      mobile: '',
      facebook: '',
      whatsapp: '',
      agency_ref_id: '',
      agency_rank: 100,
      errors: {},
    });
  };
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  render() {
    const {
      name,
      contact_email,
      password,
      password_configuration,
      // isAgencyAdmin,
      // isIkarAdmin,
      surname,
      title,
      description,
      // roles,
      mobile,
      facebook,
      whatsapp,
      // agency_ref_id,
      agency_rank,
      errors,
    } = this.state;

    return (
      <div>
        <Helmet>
          <title>{this.state.pageTitle}</title>
          <meta name="description" content={this.state.pageDescription} />
          <meta name="robots" content="noindex" />
        </Helmet>
        <div className="row">
          <div className="col-md-10 mx-auto">
            {/* {message ? (
            <Alert message={message} messageType={messageType} />
          ) : null} */}
            <h1 className="text-center pb-4 pt-3">
              <h1 className="text-primary display_4">
                <FontAwesomeIcon
                  icon={['fas', 'user-plus']}
                  size="1x"
                  className="text-primary mx-2"
                />
                اضافة مستخدم
              </h1>
              <Growl ref={(el) => (this.growl = el)} />
            </h1>
            <form onSubmit={this.onSubmit} style={{ direction: 'rtl' }}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group text-right mb-4">
                    <label htmlFor="contact_email">البريد الالكتروني:</label>
                    <input
                      type="email"
                      className={classnames('form-control', {
                        'is-invalid': errors.contact_email,
                      })}
                      name="contact_email"
                      value={contact_email}
                      onChange={this.onChange}
                    />
                    {errors.contact_email && (
                      <div className="invalid-feedback">
                        {errors.contact_email}
                      </div>
                    )}
                  </div>
                  <div className="form-group text-right mb-4">
                    <label htmlFor="password">كلمة السر:</label>
                    <input
                      type="password"
                      className={classnames('form-control', {
                        'is-invalid': errors.password,
                      })}
                      name="password"
                      value={password}
                      onChange={this.onChange}
                    />
                    {errors.password && (
                      <div className="invalid-feedback">{errors.password}</div>
                    )}
                  </div>
                  <div className="form-group text-right mb-4">
                    <label htmlFor="password_configuration">
                      اعادة كلمة السر:
                    </label>
                    <input
                      type="password"
                      className={classnames('form-control', {
                        'is-invalid': errors.password_configuration,
                      })}
                      name="password_configuration"
                      value={password_configuration}
                      onChange={this.onChange}
                    />
                    {errors.password_configuration && (
                      <div className="invalid-feedback">
                        {errors.password_configuration}
                      </div>
                    )}
                  </div>

                  <div className="form-group text-right mb-4">
                    <label htmlFor="name">اسم المستخدم:</label>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors.name,
                      })}
                      name="name"
                      value={name}
                      onChange={this.onChange}
                    />
                    {errors.name && (
                      <div className="invalid-feedback">{errors.name}</div>
                    )}
                  </div>
                  <div className="form-group text-right mb-4">
                    <label htmlFor="surname">اللقب:</label>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors.surname,
                      })}
                      name="surname"
                      value={surname}
                      onChange={this.onChange}
                    />
                    {errors.surname && (
                      <div className="invalid-feedback">{errors.surname}</div>
                    )}
                  </div>
                  <div className="form-group text-right mb-4">
                    <label htmlFor="title">العنوان:</label>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors.title,
                      })}
                      name="title"
                      value={title}
                      onChange={this.onChange}
                    />
                    {errors.title && (
                      <div className="invalid-feedback">{errors.title}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group text-right mb-4">
                    <label htmlFor="description">الوصف:</label>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors.description,
                      })}
                      name="description"
                      value={description}
                      onChange={this.onChange}
                    />
                    {errors.description && (
                      <div className="invalid-feedback">
                        {errors.description}
                      </div>
                    )}
                  </div>

                  <div className="form-group text-right mb-4">
                    <label htmlFor="mobile">رقم الموبايل:</label>
                    <input
                      type="number"
                      className={classnames('form-control', {
                        'is-invalid': errors.mobile,
                      })}
                      name="mobile"
                      value={mobile}
                      onChange={this.onChange}
                    />
                    {errors.mobile && (
                      <div className="invalid-feedback">{errors.mobile}</div>
                    )}
                  </div>
                  <div className="form-group text-right mb-4">
                    <label htmlFor="facebook">عنوان صفحةالفيسبوك:</label>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors.facebook,
                      })}
                      name="facebook"
                      value={facebook}
                      onChange={this.onChange}
                    />
                    {errors.facebook && (
                      <div className="invalid-feedback">{errors.facebook}</div>
                    )}
                  </div>
                  <div className="form-group text-right mb-4">
                    <label htmlFor="whatsapp">رقم الواتس اب:</label>
                    <input
                      type="number"
                      className={classnames('form-control', {
                        'is-invalid': errors.whatsapp,
                      })}
                      name="whatsapp"
                      value={whatsapp}
                      onChange={this.onChange}
                    />
                    {errors.whatsapp && (
                      <div className="invalid-feedback">{errors.whatsapp}</div>
                    )}
                  </div>
                  <div className="form-group text-right mb-4">
                    <label htmlFor="agency_rank">مرتبة المكتب:</label>
                    <input
                      type="number"
                      className={classnames('form-control', {
                        'is-invalid': errors.agency_rank,
                      })}
                      name="agency_rank"
                      value={agency_rank}
                      onChange={this.onChange}
                    />
                    {errors.agency_rank && (
                      <div className="invalid-feedback">
                        {errors.agency_rank}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-8 mx-auto">
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="submit"
                        value="اضافة مستخدم"
                        className="btn btn-success btn-block submit-button m-1"
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="submit"
                        value="مسح الحقول"
                        className="btn btn-danger btn-block submit-button m-1"
                        onClick={this.handleClear}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
            {this.props.requestFailed &&
            !this.state.noMessages &&
            this.props.requestFailedMessage.errorMessage.data.errors[0]
              .detail ===
              `User contact_email must be unique. User with contact email: ${contact_email} already exists.` ? (
              <div className="alert alert-danger mt-2 text-right">
                الايميل المدخل موجود مسبقا
              </div>
            ) : null}
            {!this.props.requestFailed &&
            !this.state.noMessages &&
            this.props.requestFailedMessage.error === 'no' ? (
              //  this.growl.show({
              //     severity: 'info',
              //     summary: 'Success',
              //     detail: 'تم اضافة مستخدم بنجاح'
              //   })
              <div className="alert alert-success mt-2 text-right">
                تم اضافة مستخدم بنجاح
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

UserRegistration.propTypes = {
  addUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  requestFailed: PropTypes.bool.isRequired,
  requestFailedMessage: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  user: state.user.user,
  requestFailed: state.user.requestFailed,
  requestFailedMessage: state.user.requestFailedMessage,
});

export default connect(mapStateToProps, { addUser })(UserRegistration);
