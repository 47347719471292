import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getUser } from '../../actions/userActions';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: '',
      pageDescription: '',
      noMessages: true,
      user: {},
      id: '',
      name: '',
      email: '',
      password: '',
      password_configuration: '',
      isAgencyAdmin: true,
      isIkarAdmin: false,
      surname: '',
      title: '',
      description: '',
      roles: [],
      mobile: '',
      facebook: '',
      whatsapp: '',
      agency_ref_id: '',
      agency_rank: 1,
      errors: {},
    };
  }
  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.user.email !== this.state.email) {
      var {
        id,
        name,
        email,
        //password,
        isAgencyAdmin,
        isIkarAdmin,
        surname,
        title,
        description,
        roles,
        mobile,
        facebook,
        whatsapp,
        agency_ref_id,
        agency_rank,
      } = nextProps.user;
      agency_ref_id = agency_ref_id === null ? '' : agency_ref_id; //
      surname = surname === null ? '' : surname; //
      mobile = mobile === null ? '' : mobile; //
      title = title === null ? '' : title; //
      description = description === null ? '' : description; //

      facebook = facebook === null ? '' : facebook; //
      whatsapp = whatsapp === null ? '' : whatsapp; //
      this.setState({
        id,
        name,
        email,
        //password,
        isAgencyAdmin,
        isIkarAdmin,
        surname,
        title,
        description,
        roles,
        mobile,
        facebook,
        whatsapp,
        agency_ref_id,
        agency_rank,
      });
    }
  }
  componentDidMount() {
    if (
      this.props.match !== undefined &&
      this.props.match.params !== undefined &&
      this.props.match.params.id !== undefined
    ) {
      const { id } = this.props.match.params;
      const ikarAdmin = this.props.ikarAdmin;
      const isTakingAgencyRole = this.props.isTakingAgencyRole;
      // const takenAgencyRoleEmail = this.props.takenAgencyRoleEmail;
      const takenAgencyRoleToken = this.props.takenAgencyRoleToken;
      this.props.getUser(
        ikarAdmin,
        isTakingAgencyRole,
        id,
        takenAgencyRoleToken
      );
    }
    if (this.props.location.query !== undefined) {
      if (
        this.props.location.query.user_email !== undefined &&
        this.props.location.query.user_email !== null
      ) {
        var userEmail = this.props.location.query.user_email;
        const ikarAdmin = this.props.ikarAdmin;
        const isTakingAgencyRole = this.props.isTakingAgencyRole;

        // const takenAgencyRoleEmail = this.props.takenAgencyRoleEmail;

        const takenAgencyRoleToken = this.props.takenAgencyRoleToken;
        this.props.getUser(
          ikarAdmin,
          isTakingAgencyRole,
          userEmail,
          takenAgencyRoleToken
        );
      }
    }
  }
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  render() {
    const {
      name,
      email,
      // password,
      // password_configuration,
      // isAgencyAdmin,
      // isIkarAdmin,
      // surname,
      // title,
      // description,
      // roles,
      mobile,
      facebook,
      whatsapp,
      // agency_ref_id,
      // agency_rank,
      // errors
    } = this.state;

    return (
      <div className="simple-div">
        <Helmet>
          <title>{this.state.pageTitle}</title>
          <meta name="description" content={this.state.pageDescription} />
          <meta name="robots" content="noindex"></meta>
        </Helmet>
        <div className="row">
          <div className="col-md-10 mx-auto">
            {/* {message ? (
            <Alert message={message} messageType={messageType} />
          ) : null} */}
            <h1 className="text-center pb-4 pt-3">
              <h1 className="text-primary">
                <FontAwesomeIcon
                  icon={['fas', 'user-tie']}
                  size="1x"
                  className="text-primary mx-2"
                />
                معلومات المستخدم
              </h1>
            </h1>

            <div className="card text-center">
              <div className="card-header text-primary lead text-uppercase">
                {email}
              </div>
              <div className="card-body" style={{ direction: 'rtl' }}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group text-right mb-4">
                      <label htmlFor="email">البريد الالكتروني:</label>
                      <input
                        type="email"
                        readOnly
                        className={classnames('form-control')}
                        name="email"
                        value={email}
                        onChange={this.onChange}
                      />
                    </div>

                    <div className="form-group text-right mb-4">
                      <label htmlFor="name">اسم المستخدم:</label>
                      <input
                        type="text"
                        className={classnames('form-control')}
                        name="name"
                        value={name}
                        onChange={this.onChange}
                      />
                    </div>
                    {/* <div className="form-group text-right mb-4">
                      <label htmlFor="surname">اللقب:</label>
                      <input
                        type="text"
                        className={classnames('form-control')}
                        name="surname"
                        value={surname}
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="form-group text-right mb-4">
                      <label htmlFor="title">العنوان:</label>
                      <input
                        type="text"
                        className={classnames('form-control')}
                        name="title"
                        value={title}
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="form-group text-right mb-4">
                      <label htmlFor="description">الوصف:</label>
                      <input
                        type="text"
                        className={classnames('form-control')}
                        name="description"
                        value={description}
                        onChange={this.onChange}
                      />
                    </div> */}
                    <div className="form-group text-right mb-4">
                      <label htmlFor="mobile">رقم الموبايل:</label>
                      <input
                        type="number"
                        className={classnames('form-control')}
                        name="mobile"
                        value={mobile}
                        onChange={this.onChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group text-right mb-4">
                      <label htmlFor="facebook">عنوان صفحةالفيسبوك:</label>
                      <input
                        type="text"
                        className={classnames('form-control')}
                        name="facebook"
                        value={facebook}
                        onChange={this.onChange}
                      />
                    </div>
                    <div className="form-group text-right mb-4">
                      <label htmlFor="whatsapp">رقم الواتس اب:</label>
                      <input
                        type="number"
                        className={classnames('form-control')}
                        name="whatsapp"
                        value={whatsapp}
                        onChange={this.onChange}
                      />
                    </div>
                    {/* <div className="form-group text-right mb-4">
                      <label htmlFor="agency_rank">مرتبة المكتب:</label>
                      <input
                        type="number"
                        className={classnames('form-control')}
                        name="agency_rank"
                        value={agency_rank}
                        onChange={this.onChange}
                      />
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="card-footer text-muted">
                <Link to="/" className="btn btn-primary">
                  الصفحة الرئيسية
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

UserProfile.propTypes = {
  getUser: PropTypes.func.isRequired,
  //user: PropTypes.object.isRequired,
  requestFailed: PropTypes.bool.isRequired,
  requestFailedMessage: PropTypes.object.isRequired,
  isTakingAgencyRole: PropTypes.bool.isRequired,
  ikarAdmin: PropTypes.bool.isRequired,
  // takenAgencyRoleEmail: PropTypes.string.isRequired,
  // takenAgencyRoleToken: PropTypes.string.isRequired
};
const mapStateToProps = (state) => ({
  user: state.user.user,
  requestFailed: state.user.requestFailed,
  requestFailedMessage: state.user.requestFailedMessage,
  isTakingAgencyRole: state.auth.isTakingAgencyRole,
  ikarAdmin: state.auth.ikarAdmin,
  takenAgencyRoleEmail: state.auth.takenAgencyRoleEmail,
  takenAgencyRoleToken: state.auth.takenAgencyRoleToken,
});

export default connect(mapStateToProps, { getUser })(UserProfile);
