import {
  GET_PROPERTY,
  ADD_PROPERTY,
  ADD_PROPERTY_PHOTO,
  CALL_FOR_PROPERTY,
} from './types';
import axios from 'axios';
import { mainDomain } from './../mainConf';

// export const getProperties = () => async dispatch => {
//   ///const res=await axios.get('');
//   const res = {};
//   dispatch({
//     type: GET_PROPERTIES,
//     payload: res.data
//   });
// };

export const getProperty = (
  ikarAdmin,
  isTakingAgencyRole,
  takenAgencyRoleToken,
  propertyReferenceId
) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };

  var token = '';
  var propertyRefId = propertyReferenceId;
  if (isTakingAgencyRole) {
    token = takenAgencyRoleToken;
  } else {
    token = localStorage.getItem('ikar_token');
  }
  if (token === '' || token === undefined || token === null) {
    await axios
      .get(`${mainDomain}/v1/property?reference_id=${propertyRefId}`)

      .then(function (response) {
        if (
          response !== null &&
          response !== undefined &&
          Object.keys(response).length > 0
        ) {
          res = response.data;
          res.resType = 'complete';
        } else res = {};
      })
      .catch(function (error) {
        if (error.response) {
          errorRes = {
            errorStatus: error.response.status,
            errorMessage: error.response,
          };
        } else if (error.request) {
          errorRes = {
            errorMessage: error.request,
          };
        } else {
          errorRes = { errorMessage: error.message };
        }
        res = errorRes;
        res.resType = 'not complete';
      });
  } else {
    await axios
      .get(
        `${mainDomain}/v1/property?reference_id=${propertyRefId}`,
        // ,
        // {
        //   withCredentials: true
        // }
        {
          headers: {
            Accept: 'application/json',
            Authorization: token,
          },
        }
      )

      .then(function (response) {
        if (
          response !== null &&
          response !== undefined &&
          Object.keys(response).length > 0
        ) {
          res = response.data;
          res.resType = 'complete';
        } else res = {};
      })
      .catch(function (error) {
        if (error.response) {
          errorRes = {
            errorStatus: error.response.status,
            errorMessage: error.response,
          };
        } else if (error.request) {
          errorRes = {
            errorMessage: error.request,
          };
        } else {
          errorRes = { errorMessage: error.message };
        }
        res = errorRes;
        res.resType = 'not complete';
      });
  }
  dispatch({
    type: GET_PROPERTY,
    payload: res,
  });
};

export const callForProperty = (id) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };

  await axios
    .post(
      `${mainDomain}/v1/property/calls`,
      { reference_id: id }
      // ,
      // {
      //   withCredentials: true
      // }
    )

    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
        res.resType = 'complete';
      } else res = {};
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
      res.resType = 'not complete';
    });
  dispatch({
    type: CALL_FOR_PROPERTY,
    payload: res,
  });
};

export const addProperty = (
  property1,
  isTakingAgencyRole,
  takenAgencyRoleToken
) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  var token = '';
  if (isTakingAgencyRole) {
    //////todo
    token = takenAgencyRoleToken;
  } else {
    token = localStorage.getItem('ikar_token');
  }
  await axios
    .post(
      `${mainDomain}/v1/property`,
      property1,
      // ,
      // {
      //   withCredentials: true
      // }
      {
        headers: {
          Accept: 'application/json',
          Authorization: token,
        },
      }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
      } else res = {};
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
    });
  dispatch({
    type: ADD_PROPERTY,
    payload: res,
  });
};

export const addPropertyPhoto = (
  photoObject,
  processType,
  photoName,
  isTakingAgencyRole,
  takenAgencyRoleToken
) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  var token = '';
  if (isTakingAgencyRole) {
    //////todo
    token = takenAgencyRoleToken;
  } else {
    token = localStorage.getItem('ikar_token');
  }
  await axios
    .post(
      `${mainDomain}/v1/property/image`,
      photoObject,
      // ,
      // {
      //   withCredentials: true
      // }
      {
        headers: {
          Accept: 'application/json',
          Authorization: token,
        },
      }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
        res.message = processType;
        res.uploadedPhotoName = photoName;
      } else res = {};
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
      res.uploadedPhotoName = photoName;
    });
  dispatch({
    type: ADD_PROPERTY_PHOTO,
    payload: res,
  });
};
