import React, { Component } from 'react';
import ReactGA from 'react-ga4';
import { getProperty, callForProperty } from './../actions/propertyActions';
//import waterMark from "./../assets/images/logo.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { linkDomain } from './../mainConf';
import { getreadMessagesAgency } from './../actions/contactActions';
import {
  getContactMessages,
  agencyDeleteContactMessages,
} from './../actions/contactActions';
import { Growl } from 'primereact/growl';
import { doSearch } from './../actions/searchActions';
import { updateProperty, deleteProperty } from './../actions/propertyActionsUD';
//import PaginationForProperties from "./../components/layout/Pagination/PaginationForProperties";
import { loginProcess } from './../actions/authActions';
import { PropTypes } from 'prop-types';
import queryString from 'query-string';
import { Accordion, Card, Button } from 'react-bootstrap';
class newMessage extends Component {
  constructor(props) {
    super();
    this.state = {
      pageTitle: '',
      pageDescription: '',
      largeScreen: '',

      locationSearch: '',
      resType: '',
      requestSuccessMessage: '',
      properties: [],
      total_properties: -1,
      updatedReferenceId: '',
      deleteSuccessMessage: '',
      isReadNewMessage: false,
      contactMessages: [],
      updatedMessageReferenceId: '',
      name: '',
      reference_id: '',
      id: '',
      province: '',
      area: '',
      title: '',
    };
  }

  async componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.locationSearch !== this.state.locationSearch) {
      await this.setState({ locationSearch: nextProps.locationSearch });
    }
    if (nextProps.reference_id !== this.state.reference_id) {
      await this.setState({ reference_id: nextProps.reference_id });
    }
    if (
      nextProps.resType !== undefined &&
      nextProps.resType !== this.state.resType
    ) {
      const { resType } = nextProps;
      this.setState({ resType });
    }
    // if (
    //   nextProps.contactMessages !== undefined &&
    //   nextProps.contactMessages !== this.state.contactMessages
    // ) {
    //   const { contactMessages } = nextProps;
    //   this.setState({ contactMessages });
    // }
    if (nextProps.name !== undefined && nextProps.name !== this.state.name) {
      const { name } = nextProps;
      this.setState({ name });
    }
    if (
      nextProps.isReadNewMessage !== this.state.isReadNewMessage &&
      this.state.isReadNewMessage !== ''
    ) {
      this.setState({
        isReadNewMessage: nextProps.isReadNewMessage,
      });
    }

    if (
      nextProps.requestSuccessMessage !== this.state.requestSuccessMessage &&
      this.state.requestSuccessMessage !== ''
    ) {
      var result2 = [];
      this.setState({
        requestSuccessMessage: nextProps.requestSuccessMessage,
      });

      switch (nextProps.requestSuccessMessage) {
        case 'اظهار العقار المحدد':
          this.growl.show({
            severity: 'success',
            summary: 'تم اظهار العقار المحدد',
            // detail: 'Order submitted'
          });
          this.state.properties.forEach((obj, index) => {
            result2.push(obj);
            if (obj.reference_id === this.state.updatedReferenceId) {
              result2[index].visible = !obj.visible;
            }
          });
          this.setState({ properties: result2 });
          break;
        case 'اخفاء العقار المحدد':
          this.growl.show({
            severity: 'success',
            summary: 'تم اخفار العقار المحدد',
          });
          this.state.properties.forEach((obj, index) => {
            result2.push(obj);
            if (obj.reference_id === this.state.updatedReferenceId) {
              result2[index].visible = !obj.visible;
            }
          });
          this.setState({ properties: result2 });
          break;

        case 'حذف العقار المحدد':
          this.growl.show({
            severity: 'success',
            summary: 'تم حذف العقار المحدد',
          });
          this.state.properties.forEach((obj, index) => {
            result2.push(obj);
            if (obj.reference_id === this.state.updatedReferenceId) {
              result2[index].business_status = 'مؤرشف';
            }
          });
          // this.state.properties.forEach((obj, index) => {
          //   if (obj.reference_id !== this.state.updatedReferenceId) {
          //     result2.push(obj);
          //   }
          // });
          this.setState({ properties: result2 });
          break;
        case 'استرجاع العقار المحدد':
          this.growl.show({
            severity: 'success',
            summary: 'تم استرجاع العقار المحدد',
          });
          this.state.properties.forEach((obj, index) => {
            result2.push(obj);
            if (obj.reference_id === this.state.updatedReferenceId) {
              result2[index].business_status = 'جديد';
            }
          });
          // this.state.properties.forEach((obj, index) => {
          //   if (obj.reference_id !== this.state.updatedReferenceId) {
          //     result2.push(obj);
          //   }
          // });
          this.setState({ properties: result2 });
          break;
        case 'اظهار العقار المحدد في أول نتائج البحث':
          this.growl.show({
            severity: 'success',
            summary: 'تم اظهار العقار المحدد في أول نتائج البحث',
            detail: 'عند تحديث الصفحة يظهر الترتيب الجديد للعقارات',
          });
          this.state.properties.forEach((obj, index) => {
            result2.push(obj);
            if (obj.reference_id === this.state.updatedReferenceId) {
              result2[index].rank = 1;
            }
          });
          this.setState({ properties: result2 });
          break;
        default:
          break;
      }
    } else if (
      nextProps.deleteSuccessMessage !== this.state.deleteSuccessMessage &&
      this.state.deleteSuccessMessage !== ''
    ) {
      var result22 = [];
      var messages = [];
      this.setState({
        deleteSuccessMessage: nextProps.deleteSuccessMessage,
      });

      switch (nextProps.deleteSuccessMessage) {
        case 'حذف الرسالة المحددة':
          this.growl.show({
            severity: 'success',
            summary: 'تم حذف الرسالة المحددة',
          });
          this.state.contactMessages.forEach((obj, index) => {
            if (obj.reference_id !== this.state.updatedMessageReferenceId) {
              messages.push(obj);
            }
          });
          this.state.properties.forEach((obj, index) => {
            result22.push(obj);
            if (obj.reference_id === this.state.updatedReferenceId) {
              result22[index].num_contacts = result22[index].num_contacts - 1;
            }
          });
          this.setState({ contactMessages: messages, properties: result22 });
          break;

        default:
          break;
      }
    } else if (
      nextProps.properties !== this.state.properties &&
      nextProps.properties[0] !== undefined
    ) {
      const { properties } = nextProps;
      this.setState({
        properties,
        total_properties: properties[0].total_properties,
      });
      // if (nextProps.pageNumber === 1) {
      //   this.setState({
      //     pageNumber: nextProps.pageNumber
      //   });
      // }
    } else if (nextProps.contactMessages !== this.state.contactMessages) {
      const { contactMessages } = nextProps;
      this.setState({
        contactMessages,
      });
    }
    // if (
    //   nextProps.properties[0] === undefined ||
    //   nextProps.properties.length === 0
    // )
    else {
      this.setState({
        properties: [],
        total_properties: 0,
      });
    }
  }
  resize() {
    let currentWidth = window.innerWidth;
    if (currentWidth >= 768) {
      this.setState({ largeScreen: 't' });
    } else {
      this.setState({ largeScreen: 'f' });
    }
  }
  onTelClick = async (id, e) => {
    // e.preventDefault();
    await ReactGA.event({
      category: 'Tel click',
      action: `Property ID: ${id}`,
    });
    this.props.callForProperty(id);
  };
  onReadMessage = async (reference_id, property_ref_id, read, e) => {
    e.preventDefault();
    if (read === false) {
      await this.setState({
        isReadNewMessage: read,
        requestSuccessMessage: 'get is succeeded',
        updatedReferenceId: property_ref_id,
        updatedMessageReferenceId: reference_id,
      });

      const isTakingAgencyRole = this.props.isTakingAgencyRole;
      const takenAgencyRoleToken = this.props.takenAgencyRoleToken;
      let token = '';

      if (isTakingAgencyRole) {
        token = takenAgencyRoleToken;
      } else {
        token = localStorage.getItem('ikar_token');
      }
      this.props.getreadMessagesAgency(reference_id, token);
    }
  };

  onDeleteContactMessage = async (reference_id, property_ref_id, e) => {
    e.preventDefault();
    if (!window.confirm('حذف الرسالة المحددة ؟')) {
      return;
    }

    var processType = 'حذف الرسالة المحددة';

    await this.setState({
      deleteSuccessMessage: 'ready',
      updatedReferenceId: property_ref_id,
      updatedMessageReferenceId: reference_id,
    });

    const isTakingAgencyRole = this.props.isTakingAgencyRole;
    const takenAgencyRoleToken = this.props.takenAgencyRoleToken;
    let token = '';
    if (isTakingAgencyRole) {
      token = takenAgencyRoleToken;
    } else {
      token = localStorage.getItem('ikar_token');
    }
    this.props.agencyDeleteContactMessages(reference_id, token, processType);
  };

  componentDidMount() {
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
    if (
      this.props.match !== undefined &&
      this.props.match.params !== undefined
    ) {
      const { id } = this.props.match.params;
      let searchURLParams = queryString.parse(this.props.location.search);
      const { title } = searchURLParams;
      const { area } = searchURLParams;
      const { province } = searchURLParams;

      if (id !== undefined && id !== null) {
        const contact_message = {};
        contact_message.property_ref_id = id;

        const contactMessageData = { contact_message };

        const isTakingAgencyRole = this.props.isTakingAgencyRole;
        const takenAgencyRoleToken = this.props.takenAgencyRoleToken;
        let token = '';
        if (isTakingAgencyRole) {
          token = takenAgencyRoleToken;
        } else {
          token = localStorage.getItem('ikar_token');
        }
        this.setState({ id });
        this.setState({ province });
        this.setState({ area });
        this.setState({ title });
        this.props.getContactMessages(contactMessageData, token);
      }
    }
  }

  render() {
    // const {
    //   properties,
    //   showCards,
    //   showMainPhoto,
    //   bgURL,
    //   total_properties,
    //   contactMessages,
    //   selectedContactMessage,
    //   largeScreen,
    //   // resType
    // } = this.state;
    // {(this.state.properties || []).map((prop) =>
    //   )}

    return (
      <div>
        <Helmet>
          <title>{this.state.pageTitle}</title>
          <meta name="description" content={this.state.pageDescription} />
        </Helmet>
        <Growl ref={(el) => (this.growl = el)} />

        <div className="lead text-justify " style={{ direction: 'rtl' }}>
          <span className="display_faq">
            الرسائل الواردة للعقار ({this.state.title}) في {this.state.province}{' '}
            {(this.state.area===null || this.state.area===undefined || this.state.area==='غير محدد')?
            ` - ${this.state.area}`:''} :
          </span>
          <br />
        </div>

        {this.state.contactMessages.length === 0 ? (
          <div
            style={{ fontSize: 20, direction: 'rtl' }}
            className=" lead text-justify black_with_shadow faq-margin my-2"
          >
            لا يوجد رسائل حالياً
          </div>
        ) : (
          <Accordion defaultActiveKey="0">
            {(this.state.contactMessages || []).map((contactMessage, index) => (
              <Card
                key={index}
                style={{ direction: 'rtl', textAlign: 'justify' }}
              >
                <Card.Header
                  style={
                    !contactMessage.read
                      ? { backgroundColor: 'lightgray' }
                      : null
                  }
                >
                  <Accordion.Toggle
                    as={Button}
                    variant="link"
                    eventKey={index}
                    style={{ textDecoration: 'none', width: '100%' }}
                    onClick={this.onReadMessage.bind(
                      this,
                      contactMessage.reference_id,
                      contactMessage.property_ref_id,
                      contactMessage.read
                    )}
                  >
                    <div className="container">
                      <div className="row">
                        <div className="col-3" style={{ textAlign: 'right' }}>
                          <span
                            className="display_faq_question"
                            style={
                              contactMessage.read
                                ? { fontWeight: 'normal' }
                                : { fontWeight: 'bold' }
                            }
                          >
                            {contactMessage.name}
                          </span>
                        </div>

                        <div className="col-6" style={{ textAlign: 'right' }}>
                          <span
                            className="display_faq_question"
                            style={
                              contactMessage.read
                                ? { fontWeight: 'normal' }
                                : { fontWeight: 'bold' }
                            }
                          >
                            {contactMessage.contact_email}
                          </span>
                        </div>

                        <div
                          className="col-3"
                          style={
                            (contactMessage.read
                              ? { fontWeight: 'normal' }
                              : { fontWeight: 'bold' },
                            { fontSize: 20, direction: 'ltr' })
                          }
                        >
                          {contactMessage.created_at}
                        </div>
                      </div>
                    </div>
                  </Accordion.Toggle>
                </Card.Header>

                <Accordion.Collapse eventKey={index}>
                  <Card.Body
                    style={
                      contactMessage.read
                        ? { fontWeight: 'normal' }
                        : { fontWeight: 'bold' }
                    }
                  >
                    <span>{contactMessage.message}</span>
                    <br />
                    <br />
                    <table className="show-property-table-contact">
                      <thead></thead>
                      <colgroup>
                        <col style={{ width: '25%' }} />
                        <col style={{ width: 'auto' }} />
                      </colgroup>
                      <tbody>
                        <tr className="show-property-tr pl-sm-2 pl-md-5 pl-lg-2">
                          <td style={{ textAlign: 'center' }}>
                            <span>الاتصال مع المرسل على الرقم :</span>
                          </td>
                          <td style={{ textAlign: 'right' }}>
                            <FontAwesomeIcon
                              icon={['fas', 'mobile-alt']}
                              color="gray"
                            />{' '}
                            <a
                              href={`tel:${contactMessage.mobile}`}
                              style={{ color: '#007ad9' }}
                            >
                              +{contactMessage.mobile.replace('+', '')}
                            </a>{' '}
                          </td>
                          <br />
                          <td>
                            <div style={{ marginTop: '10px' }}>
                              <span className="tooltipicon_message">
                                <Button
                                  className="p-button-danger btn_process"
                                  style={{ backgroundColor: 'red' }}
                                  onClick={this.onDeleteContactMessage.bind(
                                    this,
                                    contactMessage.reference_id,
                                    contactMessage.property_ref_id
                                  )}
                                >
                                  <FontAwesomeIcon
                                    icon={['fas', 'times']}
                                  ></FontAwesomeIcon>

                                  <span className="tooltiptext_message">
                                    <h1 style={{ fontSize: 15 }}>
                                      حذف الرسالة
                                    </h1>
                                  </span>
                                </Button>
                              </span>

                              <span className="tooltipicon_message1">
                                <a
                                  href={`${linkDomain}/properties/${contactMessage.property_ref_id}`}
                                >
                                  <Button
                                    className="p-button-secondary btn_process"
                                    style={{ border: '1px solid #9F9F9F' }}
                                  >
                                    <FontAwesomeIcon
                                      icon={['fas', 'building']}
                                    ></FontAwesomeIcon>
                                  </Button>
                                  {/* </Link> */}
                                </a>
                                <span className="tooltiptext_message1">
                                  <h1 style={{ fontSize: 15 }}>عرض العقار</h1>
                                </span>
                              </span>
                            </div>
                          </td>
                        </tr>
                        {/* condition in javascript */}

                        {contactMessage.whatsapp !== null ? (
                          <tr className="show-property-tr pl-sm-2 pl-md-5 pl-lg-2">
                            <td style={{ textAlign: 'center' }}>
                              <span> التواصل مع المرسل واتساب :</span>
                            </td>

                            <td style={{ textAlign: 'right' }}>
                              <FontAwesomeIcon
                                icon={['fab', 'whatsapp']}
                                color="#4ac959"
                              />{' '}
                              <a
                                id="whatsappButton"
                                href={`https://wa.me/${contactMessage.whatsapp.replace(
                                  '00',
                                  ''
                                )}?text=${encodeURI(
                                  `مرحباً, أرجو التواصل معي بخصوص العقار: ${contactMessage.title} - ${contactMessage.business_offer}- Ikar.sy:<${linkDomain}/properties/${contactMessage.reference_id}>`
                                )}`}
                                // onClick="ga('send', 'event', { eventCategory: 'Contact', eventAction: 'Call', eventLabel: 'Mobile Button'});"
                                style={{ color: '#f18805' }}
                                onClick={this.onTelClick.bind(
                                  this,
                                  contactMessage.reference_id
                                )}
                              >
                                <span className="show-property-link">
                                  {contactMessage.whatsapp.replace(
                                    '00963',
                                    '0'
                                  )}
                                </span>
                              </a>
                            </td>
                          </tr>
                        ) : null}
                        <br />
                      </tbody>
                    </table>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
          </Accordion>
        )}
      </div>
    );
  }
}

newMessage.propTypes = {
  properties: PropTypes.array.isRequired,
  contactMessages: PropTypes.array.isRequired,
  doSearch: PropTypes.func.isRequired,
  getContactMessages: PropTypes.func.isRequired,
  getreadMessagesAgency: PropTypes.func.isRequired,
  agencyDeleteContactMessages: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  ikarAdmin: PropTypes.bool.isRequired,
  isTakingAgencyRole: PropTypes.bool.isRequired,
  // takenAgencyRoleToken: PropTypes.string.isRequired,
  updateProperty: PropTypes.func.isRequired,
  requestSuccessMessage: PropTypes.string.isRequired,
  deleteSuccessMessage: PropTypes.string.isRequired,
  // pageNumber: PropTypes.number.isRequired
};

const mapStateToProps = (state) => ({
  properties: state.search.properties,
  isLoggedIn: state.auth.isLoggedIn,
  ikarAdmin: state.auth.ikarAdmin,
  isTakingAgencyRole: state.auth.isTakingAgencyRole,
  takenAgencyRoleToken: state.auth.takenAgencyRoleToken,
  requestSuccessMessage: state.propertyUD.requestSuccessMessage,
  deleteSuccessMessage: state.contact.deleteSuccessMessage,
  isReadNewMessage: state.contact.isReadNewMessage,
  short_id: state.propertyUD.short_id,
  short_id2: state.propertyUD.short_id2,
  contactMessages: state.contact.contact_messages,
  // pageNumber: state.search.pageNumber,
  resType: state.search.resType,
});

export default connect(mapStateToProps, {
  doSearch,
  loginProcess,
  getContactMessages,
  getreadMessagesAgency,
  agencyDeleteContactMessages,
  updateProperty,
  deleteProperty,
  callForProperty,
})(newMessage);
