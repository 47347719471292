import { GET_MAIN_RESPONSE, SET_MAIN_RESPONSE } from '../actions/types';

const initialState = {
  mainResponse: {},
  requestFailed: false,
  requestFailedMessage: {},
  requestSuccessMessage: '',
  resType: 'ready',
  main_type_param: '',
  business_offer_param: '',
  requestData: ''
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_MAIN_RESPONSE:
      if (Object.keys(action.payload).length > 0) {
        return {
          ...state,
          main_type_param: action.payload.main_type,
          business_offer_param: action.payload.business_offer
        };
      } else {
        return {
          ...state,
          main_type_param: '',
          business_offer_param: ''
        };
      }

    case GET_MAIN_RESPONSE:
      if (
        Object.keys(action.payload).length > 0 &&
        (action.payload.errorMessage === '' ||
          action.payload.errorMessage === undefined)
      ) {
        return {
          ...state,
          mainResponse: action.payload,
          requestFailed: false,
          requestFailedMessage: {},
          requestSuccessMessage: '',
          resType: action.payload.resType,
          requestData: action.payload.requestData
          //
        };
      } else {
        return {
          ...state,
          mainResponse: {},
          requestFailed: true,
          requestFailedMessage: action.payload,
          requestSuccessMessage: '',
          resType: action.payload.resType,
          requestData: action.payload.requestData
          //
        };
      }
    default:
      return state;
  }
}
