// import locationHelperBuilder from 'redux-auth-wrapper/history4/locationHelper';
import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect';
// import { routerActions } from 'react-router-redux';
// import createHistory from 'history/createBrowserHistory';
// import LoadingSpinner from './../components/layout/spinner'; // change it to your custom component
// import authReducer from './../reducers/authReducer';

// import { UserAuthWrapper } from 'redux-auth-wrapper';
// import connectedAuthWrapper from 'redux-auth-wrapper/connectedAuthWrapper';

// import { connect } from 'react-redux';

// const locationHelper = locationHelperBuilder({});
// const history = createHistory();

export const IsAuthenticated = connectedRouterRedirect({
  redirectPath: '/login',
  authenticatedSelector: state =>
    Object.keys(state.auth.loginRes).length > 0 && state.auth.isLoggedIn,
  //authenticatingSelector: state => state.auth.isLoggedIn,
  wrapperDisplayName: 'IsAuthenticated'
  //redirectAction: routerActions.replace
  //authenticatingSelector: state => state.auth.isLoggedIn,
  //AuthenticatingComponent: LoadingSpinner
});

export const userIsAuthenticated = connectedRouterRedirect({
  redirectPath: '/login',
  authenticatedSelector: state =>
    (Object.keys(state.auth.loginRes).length > 0 &&
      state.auth.isLoggedIn &&
      state.auth.agencyAdmin) ||
    (Object.keys(state.auth.loginRes).length > 0 &&
      state.auth.ikarAdmin &&
      state.auth.isTakingAgencyRole),
  //authenticatingSelector: state => state.auth.isLoggedIn,
  wrapperDisplayName: 'UserIsAuthenticated'
  //redirectAction: routerActions.replace
  //authenticatingSelector: state => state.auth.isLoggedIn,
  //AuthenticatingComponent: LoadingSpinner
});

export const adminIsAuthenticated = connectedRouterRedirect({
  // redirectPath: '/login',
  authenticatedSelector: state =>
    Object.keys(state.auth.loginRes).length > 0 &&
    state.auth.ikarAdmin &&
    !state.auth.isTakingAgencyRole,
  wrapperDisplayName: 'adminIsAuthenticated',
  redirectPath: state =>
    state.auth.ikarAdmin && state.auth.isTakingAgencyRole
      ? '/dashBoard/properties_by_agency'
      : state.auth.ikarAdmin && !state.auth.isTakingAgencyRole
      ? '/adminDashBoard/agencies'
      : '/login'
  //authenticatingSelector: state => state.auth.isLoggedIn,
  //AuthenticatingComponent: LoadingSpinner
  //redirectAction: routerActions.replace
});

export const userIsNotAuthenticated = connectedRouterRedirect({
  // This sends the user either to the query param route if we have one, or to the landing page if none is specified and the user is already logged in
  //
  //
  //
  // redirectPath: (state, ownProps) =>
  //   locationHelper.getRedirectQueryParam(ownProps) || '/',
  redirectPath: state =>
    state.auth.ikarAdmin && !state.auth.isTakingAgencyRole
      ? '/adminDashBoard/agencies'
      : (state.auth.ikarAdmin && state.auth.isTakingAgencyRole) ||
        !state.auth.ikarAdmin
      ? '/dashBoard/properties_by_agency'
      : '/',
  // state.auth.ikarAdmin && state.auth.isTakingAgencyRole
  // ? '/dashBoard/properties_by_agency'
  // : '/'
  //
  //
  //
  // This prevents us from adding the query parameter when we send the user away from the login page
  allowRedirectBack: false,
  // If selector is true, wrapper will not redirect
  // So if there is no user data, then we show the page
  authenticatedSelector: state =>
    !state.auth.isLoggedIn && !state.auth.ikarAdmin,
  //authenticatingSelector: state => !state.auth.isLoggedIn,
  // A nice display name for this check
  wrapperDisplayName: 'UserIsNotAuthenticated'
});

// export const userIsAuthenticated = UserAuthWrapper({
//   //authSelector: state => state.auth, // how to get the user state
//   authSelector: state => state.auth,
//   authenticatingSelector: state => {
//     return state.auth.isLoggedIn;
//   },
//   authenticatedSelector: state => {
//     return state.auth.loginRes !== null;
//   },
//   redirectAction: routerActions.replace, // the redux action to dispatch for redirect
//   wrapperDisplayName: 'userIsAuthenticated', // a nice name for this auth check
//   AuthenticatingComponent: LoadingSpinner,
//   allowRedirectBack: true,
//   redirectPath: '/login'
// });

// const userIsAuthenticatedDefaults = {
//   authenticatedSelector: state => {
//     return state.auth.loginRes !== null;
//   },
//   authenticatingSelector: state => state.auth.isLoggedIn,
//   wrapperDisplayName: 'UserIsAuthenticated'
// };
// export const userIsAuthenticated = connectedAuthWrapper(
//   userIsAuthenticatedDefaults
// );

//export connect()(userIsAuthenticated);
// export const UserIsAuthenticated = connectedAuthWrapper({
//   //authSelector: state => state.auth.isLoggedIn, // how to get the user state
//   authenticatedSelector: state => state.auth.loginRes !== null,
//   authenticatingSelector: state => state.auth.isLoggedIn,
//   redirectAction: routerActions.replace, // the redux action to dispatch for redirect
//   wrapperDisplayName: 'UserIsAuthenticated', // a nice name for this auth check
//   AuthenticatingComponent: LoadingSpinner
// });

// export const UserIsAuthenticated = connectedRouterRedirect({
//   wrapperDisplayName: 'UserIsAuthenticated',
//   AuthenticatingComponent: LoadingScreen,
//   allowRedirectBack: true,
//   redirectPath: (state, ownProps) =>
//     locationHelper.getRedirectQueryParam(ownProps) || '/login',
//   authenticatingSelector: ({ firebase: { auth, profile, isInitializing } }) =>
//     !auth.isLoaded || isInitializing === true,
//   authenticatedSelector: ({ firebase: { auth } }) =>
//     auth.isLoaded && !auth.isEmpty
// });

// export const UserIsNotAuthenticated = connectedRouterRedirect({
//   wrapperDisplayName: 'UserIsNotAuthenticated',
//   AuthenticatingComponent: LoadingScreen,
//   allowRedirectBack: false,
//   redirectPath: (state, ownProps) =>
//     locationHelper.getRedirectQueryParam(ownProps) || '/',
//   authenticatingSelector: ({ firebase: { auth, isInitializing } }) =>
//     !auth.isLoaded || isInitializing === true,
//   authenticatedSelector: ({ firebase: { auth } }) =>
//     auth.isLoaded && auth.isEmpty
// });
