import React, { Component } from "react";
import Property from "../components/properties/Property";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import shortid from "shortid";
import { doSearch } from "../actions/searchActions";
import { isNumeric } from "../mainConf/functions";
import PaginationForProperties from "../components/layout/Pagination/PaginationForProperties";
// import { ResReady, ResNotComplete } from './../layout/resTypes';
import { recordsPerPage } from "../mainConf/index";
import { removeParam } from "../mainConf/functions.jsx";
import queryString from "query-string";
import { Link } from "react-router-dom";
import classnames from "classnames";
import SearchBar from "../components/search/SearchBar.jsx";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { getCategories, getCategories2 } from "../actions/categoryActions";
import NotFound from "../pages/NotFound";
import { linkDomain } from "./../mainConf";
import {
  generalProvinces,
  generalBusinessOffer,
  generalTypesItems,
} from "../mainConf/index";
class ShowPropertiesPerTwoCategories extends Component {
  constructor(props) {
    super();
  }
  state = {
    is_page_param: false,
    pageTitle: "عقارات | Ikar.sy",
    pageDescription:
      "أكثر عروض العقارات مشاهدة ضمن موقعنا في السوق السورية مع تفاصيل شاملة لكل عرض | Ikar.sy",
    properties: [],
    categories: {},
    categories2: {},
    categoriesPerProvince: {},
    categoriesPerBusinessOffer: {},
    categoriesPerPropertyType: {},
    requestDataType: "",
    requestDataType2: "",
    total_properties: -1,
    searchBarFullShow: false,
    requestSuccessMessage: {},
    errors: {},
    pageNumber: 1,
    searchParameters: {},
    property_type_from_category: "",
    business_offer_from_category: "",
    resType: "ready",
    locationSearch: "",
    normalUrl: "",
    largeScreen: "",
    BOId: "",
    PTId: "",
    categoryNotFound: false,
    showSecondCategories: true,
  };
  resize() {
    let currentWidth = window.innerWidth;
    if (currentWidth >= 768) {
      this.setState({ largeScreen: "t" });
    } else {
      this.setState({ largeScreen: "f" });
    }
  }
  async componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    let searchURLParams = await queryString.parse(this.props.location.search);
    let lowerSearchURLParams = {};
    await Object.keys(searchURLParams).forEach(function (key, value) {
      lowerSearchURLParams[key.toLowerCase()] = searchURLParams[key];
    });
    searchURLParams = lowerSearchURLParams;
    let property_type_from_category = "";
    let business_offer_from_category = "";
    if (
      this.props.match !== undefined &&
      this.props.match.params !== undefined
    ) {
      const { BOId, PTId } = this.props.match.params;
      this.setState({ BOId, PTId });
      if (BOId !== undefined && BOId !== null) {
        business_offer_from_category = BOId;
      }
      if (PTId !== undefined && PTId !== null) {
        property_type_from_category = PTId;
      }
      if (
        !(
          (BOId === "عروض" || generalBusinessOffer.includes(BOId)) &&
          (PTId === "عقارات" || generalTypesItems.includes(PTId))
        )
      ) {
        this.setState({ categoryNotFound: true });
        return false;
      }
    }
    const is_page_param =
      searchURLParams.page === undefined ||
      searchURLParams.page === null ||
      !isNumeric(searchURLParams.page)
        ? false
        : true;
    this.setState({ is_page_param });
    const page =
      searchURLParams.page !== undefined &&
      searchURLParams.page !== null &&
      isNumeric(searchURLParams.page)
        ? searchURLParams.page
        : "1";
    if (property_type_from_category !== null) {
      await this.setState({ property_type_from_category });
    }

    if (business_offer_from_category !== null) {
      await this.setState({ business_offer_from_category });
    }
    await this.setState({ locationSearch: this.props.location.search });

    this.onSearchSubmit(
      recordsPerPage,
      page,
      property_type_from_category,
      business_offer_from_category
    );
  }

  onSearchSubmit = (
    recordsPerPage,
    pageNumber,
    propertyTypeFromCategory,
    businessOfferFromCategory
  ) => {
    const property = {};
    let aggregationData = {};
    let aggregationData2 = {};
    aggregationData2.aggs_on = "business_offer";

    if (
      propertyTypeFromCategory !== "" &&
      propertyTypeFromCategory !== "غير محدد" &&
      propertyTypeFromCategory !== "عقارات"
    ) {
      aggregationData.type = propertyTypeFromCategory;
      aggregationData2.type = propertyTypeFromCategory;
      property.type = propertyTypeFromCategory;
      aggregationData.aggs_on = "province";
    } else if (propertyTypeFromCategory === "عقارات") {
      aggregationData.aggs_on = "type";
    }

    if (
      businessOfferFromCategory !== "" &&
      (businessOfferFromCategory === "بيع" ||
        businessOfferFromCategory === "تأجير" ||
        businessOfferFromCategory === "مزادات" ||
        businessOfferFromCategory === "رهن" ||
        businessOfferFromCategory === "استثمار")
    ) {
      aggregationData.business_offer = businessOfferFromCategory;
      aggregationData2.business_offer = businessOfferFromCategory;
      property.business_offer = businessOfferFromCategory;
    } else if (
      propertyTypeFromCategory === "عقارات" &&
      businessOfferFromCategory === "عروض"
    ) {
      aggregationData.aggs_on = "business_offer";
      this.setState({ showSecondCategories: false });
    }

    property.visible = true;
    property.order = {};
    property.order.created_at = "desc";
    property.temp_status = "عادي";
    // property.ranges = {};
    // property.ranges.days = {};
    // property.ranges.days.min = -999999;
    // property.ranges.days.max = -1;

    this.setState({ pageNumber });
    this.props.getCategories(aggregationData);
    if (businessOfferFromCategory === "عروض") {
      this.props.getCategories2(aggregationData2);
    }
    this.props.doSearch(property, recordsPerPage, pageNumber);
  };
  removeSearchQuery = async () => {
    localStorage.removeItem("ikar_main_type_param");
    localStorage.removeItem("ikar_business_offer_param");
    await this.setState({ main_type_param: "", business_offer_param: "" });
    this.onSearchSubmit(recordsPerPage, 1, null, null);
  };
  async componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.location.search !== this.state.locationSearch) {
      var normalUrl = await removeParam("main_type", nextProps.location.search);
      normalUrl = await removeParam("business_offer", normalUrl);
      await this.setState({
        normalUrl,
        locationSearch: nextProps.location.search,
      });
      const searchURLParams = await queryString.parse(
        nextProps.location.search
      );
      const main_type_param =
        searchURLParams.main_type !== undefined &&
        searchURLParams.main_type !== null
          ? searchURLParams.main_type
          : "";

      const business_offer_param =
        searchURLParams.business_offer !== undefined &&
        searchURLParams.business_offer !== null
          ? searchURLParams.business_offer
          : "";
      const page =
        searchURLParams.page !== undefined &&
        searchURLParams.page !== null &&
        isNumeric(searchURLParams.page)
          ? searchURLParams.page
          : "1";
      if (main_type_param !== null) {
        await this.setState({ main_type_param });
      }
      if (business_offer_param !== null) {
        await this.setState({ business_offer_param });
      }
      this.onSearchSubmit(
        recordsPerPage,
        page,
        business_offer_param,
        main_type_param
      );
    }
    if (
      nextProps.resType !== undefined &&
      nextProps.resType !== this.state.resType
    ) {
      const { resType } = nextProps;
      this.setState({ resType });
    }

    if (
      nextProps.requestData !== undefined &&
      nextProps.requestData.aggs_on !== undefined &&
      nextProps.requestData.aggs_on !== this.state.requestDataType
    ) {
      const { requestData } = nextProps;
      this.setState({
        requestDataType: nextProps.requestData.aggs_on,
      });
    }

    if (
      nextProps.requestData2 !== undefined &&
      nextProps.requestData2.aggs_on !== undefined &&
      nextProps.requestData2.aggs_on !== this.state.requestDataType2
    ) {
      const { requestData2 } = nextProps;
      this.setState({
        requestDataType2: nextProps.requestData2.aggs_on,
      });
    }

    if (
      nextProps.categories !== this.state.categories &&
      nextProps.categories.requestData !== undefined &&
      nextProps.categories.requestData.aggs_on !== undefined
    ) {
      const { categories } = nextProps;
      let categoriesPerProvince = {};
      let categoriesPerPropertyType = {};
      if (
        nextProps.categories.requestData.aggs_on !== undefined &&
        nextProps.categories.requestData.aggs_on === "province"
      ) {
        categoriesPerProvince = nextProps.categories.per_province;
      }
      if (
        nextProps.categories.requestData.aggs_on !== undefined &&
        nextProps.categories.requestData.aggs_on === "type"
      ) {
        categoriesPerPropertyType = nextProps.categories.per_type;
      }
      this.setState({
        categories,
        categoriesPerProvince,
        categoriesPerPropertyType,
      });
    }

    if (
      nextProps.categories2 !== this.state.categories2 &&
      nextProps.categories2.requestData !== undefined &&
      nextProps.categories2.requestData.aggs_on !== undefined
    ) {
      const { categories2 } = nextProps;
      let categoriesPerBusinessOffer = {};
      if (
        nextProps.categories2.requestData.aggs_on !== undefined &&
        nextProps.categories2.requestData.aggs_on === "business_offer"
      ) {
        categoriesPerBusinessOffer = nextProps.categories2.per_business_offer;
      }
      this.setState({
        categories2,
        categoriesPerBusinessOffer,
      });
    }
    if (
      nextProps.properties !== this.state.properties &&
      nextProps.properties[0] !== undefined
    ) {
      const { properties } = nextProps;
      this.setState({
        properties,
        total_properties: properties[0].total_properties,
      });
      if (nextProps.pageNumber === 1) {
        this.setState({
          pageNumber: nextProps.pageNumber,
        });
      }
      if (nextProps.location.search !== this.state.locationSearch) {
        normalUrl = await removeParam("main_type", nextProps.location.search);
        normalUrl = await removeParam("business_offer", normalUrl);
        await this.setState({
          normalUrl,
          locationSearch: nextProps.location.search,
        });
        const searchURLParams = await queryString.parse(
          nextProps.location.search
        );
        const main_type_param =
          searchURLParams.main_type !== undefined &&
          searchURLParams.main_type !== null
            ? searchURLParams.main_type
            : "";

        const business_offer_param =
          searchURLParams.business_offer !== undefined &&
          searchURLParams.business_offer !== null
            ? searchURLParams.business_offer
            : "";
        if (main_type_param !== null) {
          await this.setState({ main_type_param });
        }
        if (business_offer_param !== null) {
          await this.setState({ business_offer_param });
        }
      }
    } else if (
      nextProps.properties[0] === undefined ||
      nextProps.properties.length === 0
    ) {
      this.setState({
        properties: [],
        total_properties: 0,
      });
    }
  }

  render() {
    const {
      properties,
      total_properties,
      pageNumber,
      main_type_param,
      business_offer_param,
      largeScreen,
      categoryNotFound,
      property_type_from_category,
      business_offer_from_category,
    } = this.state;

    if (properties !== undefined && properties.length > 0) {
    }

    return (
      <React.Fragment>
        {categoryNotFound ? (
          <NotFound></NotFound>
        ) : (
          <React.Fragment>
            <Helmet>
              <title>{` ${
                business_offer_from_category !== ""
                  ? business_offer_from_category
                  : ""
              } ${
                property_type_from_category !== ""
                  ? property_type_from_category
                  : ""
              } | Ikar.sy `}</title>
              <meta name="description" content={
                `  ${
                  business_offer_from_category !== ""
                    ? business_offer_from_category
                    : "عروض"
                } ${
                  property_type_from_category === ""
                    ?""
                    : property_type_from_category === 'شقة'
                    ? 'شقق'
                    : property_type_from_category === 'عيادة'
                    ? 'عيادات'
                    : property_type_from_category === 'بيت'
                    ? 'بيوت'
                    : property_type_from_category === 'مطعم'
                    ? 'مطاعم'
                    : property_type_from_category === 'مكتب'
                    ? 'مكاتب'
                    : property_type_from_category === 'صالة'
                    ? 'صالات'
                    : property_type_from_category === 'فندق'
                    ? 'فنادق'
                    : property_type_from_category === 'مقهى'
                    ? 'مقاهي'
                    : property_type_from_category === 'أراضي زراعية' ||
                    property_type_from_category === 'أرض للبناء' ||
                    property_type_from_category === 'أراضي ذات رخصة سياحية' ||
                    property_type_from_category === 'أراضي ذات رخصة صناعية'
                    ? 'أراضي'
                    : `${property_type_from_category} في سوريا`
                  } ${
                    pageNumber <=0  ? '' : `- الصفحة ${pageNumber}`} | Ikar.sy `
                } />
              <link
                rel="canonical"
                href={`${linkDomain}/${this.state.PTId}/${this.state.BOId}${
                  this.state.pageNumber > 0 && this.state.is_page_param
                    ? `?page=${this.state.pageNumber}`
                    : ""
                }`}
              />
            </Helmet>
            {largeScreen === "t" ? (
              <SearchBar
                searchBarFullShow={this.state.searchBarFullShow}
                searchType="main_search"
                destination="/search"
                urlFilters="false"
                searchMode="off"
              ></SearchBar>
            ) : null}
            <ol
              itemScope
              itemType="http://schema.org/BreadcrumbList"
              className="ikar-breadcrumbs text-right categories-title-div mt-2"
            >
              <li
                itemProp="itemListElement"
                itemScope
                itemType="http://schema.org/ListItem"
                className="mr-1"
              >
                <a
                  itemProp="item"
                  href={`${linkDomain}/عقارات/${business_offer_from_category}`}
                >
                  <span itemProp="name">{business_offer_from_category}</span>
                </a>
                <meta itemProp="position" content="1" />
              </li>
              {` > `}
              <li
                itemProp="itemListElement"
                itemScope
                itemType="http://schema.org/ListItem"
              >
                <a
                  itemProp="item"
                  href={`${linkDomain}/${property_type_from_category}/${business_offer_from_category}`}
                >
                  <span itemProp="name">{property_type_from_category}</span>
                </a>
                <meta itemProp="position" content="2" />
              </li>
            </ol>
            {/* <div className="text-right categories-title-div mt-2">
              <span className="mr-1">
                <a
                  href={`${linkDomain}/عقارات/${business_offer_from_category}`}
                >
                  <span>{business_offer_from_category}</span>
                </a>
                {` > `}
                <a
                  href={`${linkDomain}/${property_type_from_category}/${business_offer_from_category}`}
                >
                  <span>{property_type_from_category}</span>
                </a>
              </span>
            </div> */}
            {Object.keys(this.state.categoriesPerPropertyType).length > 0 ? (
              <div className="mb-3 text-center categories-div">
                <div className="row">
                  {Object.keys(this.state.categoriesPerPropertyType).map(
                    (item, index) => {
                      const propertyTypeDisplayName = item;
                      const businessOfferDisplayName =
                        this.state.business_offer_from_category;
                      return (
                        <div className="col-lg-2 col-md-3 col-sm-6" key={index}>
                          <a
                            href={`${linkDomain}/${propertyTypeDisplayName}/${businessOfferDisplayName}`}
                          >
                            <span>
                              {`${
                                item.length > 15
                                  ? `${item.substring(0, 15)} ...`
                                  : item
                              }`}
                            </span>
                            <span className="category-item">{` (${this.state.categoriesPerPropertyType[item]})`}</span>
                          </a>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            ) : null}
            {Object.keys(this.state.categoriesPerProvince).length > 0 ? (
              <div className="mb-3 text-center categories-div">
                <div className="row">
                  {Object.keys(this.state.categoriesPerProvince).map(
                    (item, index) => {
                      const provinceDisplayName = item;
                      const propertyTypeDisplayName =
                        this.state.property_type_from_category;
                      const businessOfferDisplayName =
                        this.state.business_offer_from_category;
                      return (
                        <div className="col-lg-2 col-md-3 col-sm-6" key={index}>
                          <a
                            href={`${linkDomain}/${provinceDisplayName}/${propertyTypeDisplayName}/${businessOfferDisplayName}`}
                          >
                            <span>
                              {`${
                                item.length > 15
                                  ? `${item.substring(0, 15)} ...`
                                  : item
                              }`}
                            </span>
                            <span className="category-item">{` (${this.state.categoriesPerProvince[item]})`}</span>
                          </a>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            ) : null}
            {Object.keys(this.state.categoriesPerBusinessOffer).length > 0 ? (
              <div className="mb-3 text-center categories-div">
                <div className="row">
                  {Object.keys(this.state.categoriesPerBusinessOffer).map(
                    (item, index) => {
                      const propertyTypeDisplayName =
                        this.state.property_type_from_category;
                      const businessOfferDisplayName = item;
                      return (
                        <div className="col-lg-2 col-md-3 col-sm-6" key={index}>
                          <a
                            href={`${linkDomain}/${propertyTypeDisplayName}/${businessOfferDisplayName}`}
                          >
                            <span>
                              {`${
                                item.length > 15
                                  ? `${item.substring(0, 15)} ...`
                                  : item
                              }`}
                            </span>
                            <span className="category-item">{` (${this.state.categoriesPerBusinessOffer[item]})`}</span>
                          </a>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            ) : null}

            <div className="mb-3 text-center mt-2">
              <h1 className="main_title">{`العقارات تبعا للتصنيف (${this.state.BOId} - ${this.state.PTId}) `}</h1>
            </div>
            <div className="property-container">
              <div className="container-fluid">
                {/* {main_type_param !== '' || business_offer_param !== '' ? (
              <div className="text-right">
                <div className="mb-1">
                  <span className="display_7">
                    {`يتم فلترة النتائج تبعا للقيم(`}
                  </span>
                  <span className="display_7">
                    {business_offer_param !== '' ? (
                      <React.Fragment>
                        <span className="display_7">نوع العرض: </span>
                        <span className="display_gold">
                          {business_offer_param}
                        </span>{' '}
                      </React.Fragment>
                    ) : null}
                    {business_offer_param !== '' && main_type_param !== ''
                      ? ' - '
                      : ''}
                    {main_type_param !== '' ? (
                      <React.Fragment>
                        <span className="display_7">نوع العقار: </span>
                        <span className="display_gold">
                          {main_type_param.split(' OR ').join(',')}
                        </span>
                      </React.Fragment>
                    ) : null}

                    <span className="display_7">{')'}</span>
                  </span>
                  <Link
                    to={{
                      pathname: this.props.location.pathname,
                      // query: { search_mode: 'min' }
                      search: this.state.normalUrl,
                    }}
                    className={classnames(
                      'btn',
                      'px-0',
                      'mb-2',
                      'mx-2',
                      'submit-button'
                    )}
                    style={{ width: '200px' }}
                    // onClick={this.onSearchButtonSubmit}
                  >
                    حذف الفلترة الحالية للبحث
                  </Link>
                </div>
              </div>
            ) : null} */}
                <div className="row justify-content-around align-items-center">
                  {properties !== [] &&
                    properties !== undefined &&
                    (properties || []).map((property) => (
                      <Property
                        key={shortid.generate()}
                        property={property}
                        isLoggedIn={false}
                        ikarAdmin={false}
                        requestSuccessMessage={this.state.requestSuccessMessage}
                      />
                    ))}
                </div>
                <PaginationForProperties
                  resultLabel="عقار"
                  recordsNumber={total_properties}
                  // pageNumber={pageNumber}
                  // onPageChange={this.onSearchButtonSubmit}
                  showMessage={true}
                  destination={`/${this.state.PTId}/${this.state.BOId}`}
                  locationSearch={this.state.locationSearch}
                ></PaginationForProperties>
              </div>
            </div>
            {largeScreen === "f" ? (
              <SearchBar
                searchBarFullShow={this.state.searchBarFullShow}
                searchType="main_search"
                destination="/search"
                urlFilters="false"
                searchMode="off"
              ></SearchBar>
            ) : null}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

ShowPropertiesPerTwoCategories.propTypes = {
  properties: PropTypes.array.isRequired,
  categories: PropTypes.object.isRequired,
  categories2: PropTypes.object.isRequired,
  doSearch: PropTypes.func.isRequired,
  getCategories: PropTypes.func.isRequired,
  getCategories2: PropTypes.func.isRequired,
  // pageNumber: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  properties: state.search.properties,
  pageNumber: state.search.pageNumber,
  resType: state.search.resType,
  categories: state.category.categories,
  requestData: state.category.requestData,
  categories2: state.category.categories2,
  requestData2: state.category.requestData2,
});

export default connect(mapStateToProps, {
  doSearch,
  getCategories,
  getCategories2,
})(ShowPropertiesPerTwoCategories);
