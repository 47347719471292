import { GET_CATEGORIES, GET_CATEGORIES2 } from '../actions/types';

const initialState = {
  categories: {},
  requestFailed: false,
  requestFailedMessage: {},
  requestSuccessMessage: '',
  resType: 'ready',
  requestData: {},

  categories2: {},
  requestFailed2: false,
  requestFailedMessage2: {},
  requestSuccessMessage2: '',
  resType2: 'ready',
  requestData2: {},
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_CATEGORIES:
      if (
        Object.keys(action.payload).length > 0 &&
        (action.payload.errorMessage === '' ||
          action.payload.errorMessage === undefined)
      ) {
        return {
          ...state,
          categories: action.payload,
          requestFailed: false,
          requestFailedMessage: {},
          requestSuccessMessage: '',
          resType: action.payload.resType,
          requestData: action.payload.requestData,
          //
        };
      } else {
        return {
          ...state,
          mainResponse: {},
          requestFailed: true,
          requestFailedMessage: action.payload,
          requestSuccessMessage: '',
          resType: action.payload.resType,
          requestData: action.payload.requestData,
          //
        };
      }
    case GET_CATEGORIES2:
      if (
        Object.keys(action.payload).length > 0 &&
        (action.payload.errorMessage === '' ||
          action.payload.errorMessage === undefined)
      ) {
        return {
          ...state,
          categories2: action.payload,
          requestFailed2: false,
          requestFailedMessage2: {},
          requestSuccessMessage2: '',
          resType2: action.payload.resType,
          requestData2: action.payload.requestData,
          //
        };
      } else {
        return {
          ...state,
          mainResponse2: {},
          requestFailed2: true,
          requestFailedMessage2: action.payload,
          requestSuccessMessage2: '',
          resType2: action.payload.resType,
          requestData2: action.payload.requestData,
          //
        };
      }
    default:
      return state;
  }
}
