import { GET_JOINS ,DELETE_JOIN,ADD_JOIN ,GET_JOIN } from '../actions/types';
import shortid from 'shortid';
const initialState = {
  joinus: [],
  join: {},
  requestFailed: false,
  requestFailedMessage: {},
  requestSuccessMessage: '',
  deleteSuccessMessage: '',
  requestMessage: '',
  resType: 'ready',
};
  export default function (state = initialState, action) {
    switch (action.type) {
        
    case GET_JOINS:
      
        if (
          Object.keys(action.payload).length > 0 &&
          (action.payload.errorMessage === '' ||
            action.payload.errorMessage === undefined)
        ) {
         // action.payload.processStatus = 'get joinus';
          return {   
          //...state,
          join: action.payload[0],
          //add state data
          joinus: action.payload,
          requestFailed: false,
          requestFailedMessage: {},
          requestSuccessMessage: '',
          resType: action.payload.resType,
          };
        } else {
          return {  
          join: {},
          requestFailed: true,
          requestFailedMessage: action.payload,
          //add state data
          joinus: [],
          requestSuccessMessage: '',
          requestMessage: '',
          resType: action.payload.resType,
          //
          };
         
        }
        case ADD_JOIN:
          if (
            Object.keys(action.payload).length > 0 &&
            (action.payload.errorMessage === '' ||
              action.payload.errorMessage === undefined)
          ) {
            if (action.payload.error === undefined) {
              return {
                //...state,
                join: action.payload,
                requestFailed: false,
                requestFailedMessage: { error: 'no' },
                //add state
                joinus: [],
                requestSuccessMessage: 'add is succeeded',
                resType: action.payload.resType,
                short_id: shortid.generate(),
              };
            } else {
              return {
                //...state,
                join: {},
                requestFailed: true,
                requestFailedMessage: action.payload,
                //add state
                joinus: [],
                requestSuccessMessage: 'error in add question',
                resType: action.payload.resType,
                short_id: shortid.generate(),
              };
            }
          } else {
            return {
              //...state,
              join: {},
              requestFailed: true,
              requestFailedMessage: action.payload,
              //add state
              joinus: [],
              requestSuccessMessage: 'error in add question',
              resType: action.payload.resType,
            };
          }

          case GET_JOIN:
            if (
              action.payload.length > 0 &&
              (action.payload.errorMessage === '' ||
                action.payload.errorMessage === undefined)
            ) {
              action.payload.processStatus = 'get joins us';
              return {
                //...state,
                join: action.payload[0],
                //add state data
                joinus: [],
                requestFailed: false,
                requestFailedMessage: {},
                requestMessage: 'get join ready',
                resType: action.payload.resType,
                //
              };
            } else {
              return {
                //...state,
                join: {},
                requestFailed: true,
                requestFailedMessage: action.payload,
                //add state data
                joinus: [],
                requestSuccessMessage: '',
                requestMessage: '',
                resType: action.payload.resType,
                //
              };
            }


       case DELETE_JOIN:
          if (
            Object.keys(action.payload).length > 0 &&
            (action.payload.errorMessage === '' ||
              action.payload.errorMessage === undefined)
          ) {
            return {
              joinus: [],
              join: {},
              requestFailed: false,
              requestFailedMessage: {},
              deleteSuccessMessage: action.payload.message,
              short_id: shortid.generate(),
            };
          } else {
            return {
              joinus: [],
              join: {},
              requestFailed: true,
              requestFailedMessage: action.payload,
              deleteSuccessMessage: '',
            };
          }
          
        
            default:
              return state;

            }
          
        }