import { UPDATE_PRIVACY} from './types';
import axios from 'axios';
import { mainDomain } from './../mainConf';

export const editPrivacy = (privacy , processType ) => async (dispatch) => {
    let res = {};
    let errorRes = {
      errorStatus: '',
      errorMessage: '',
    };
    let token = localStorage.getItem('ikar_token');

    await axios
      .put(
        `${mainDomain}/v1/privacy/update`,
        {privacy:privacy},
       //{reference_id:reference_id , content:privacy}
        {
          headers: {
            Accept: 'application/json',
            //'Content-Type': 'application/json',
            //'Access-Control-Allow-Origin': '*',
            //'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
            Authorization: token,
          },
        }
      )
      .then(function (response) {
        console.log(response);
        if (
          response !== null &&
          response !== undefined &&
          Object.keys(response).length > 0
        ) {
          res = response.data;
          res.message = processType;
        } else res = {};
      })
      .catch(function (error) {
        if (error.response) {
          errorRes = {
            errorStatus: error.response.status,
            errorMessage: error.response,
          };
        } else if (error.request) {
          errorRes = {
            errorMessage: error.request,
          };
        } else {
          errorRes = { errorMessage: error.message };
        }
        res = errorRes;
      })
      ;
    dispatch({
      type: UPDATE_PRIVACY,
      payload: res,
    });
  };