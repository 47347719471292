export type CategoryItem = {
  business_offer: 'تأجير' | 'بيع' | 'غير محدد'|'مزادات'|'رهن'|'استثمار';
  property_type:
    | 'غير محدد'
    | 'استوديو'
    | 'شقة'
    | 'بيت'
    | 'بيت عربي'
    | 'سويت'
    | 'شاليه'
    | 'فيلا'
    | 'سطح معد للبناء'
    | 'بناء كامل'
    | 'قبو'
    | 'مقهى'
    | 'مكتب'
    | 'عيادة'
    | 'مطعم'
    | 'فندق'
    | 'مشفى'
    | 'صالة'
    | 'منشأة تصنيع'
    | 'مستودع'
    | 'محطة وقود'
    | 'مرآب'
    | 'محلات تجارية'
    | 'محلات اصلاح'
    | 'محلات لتربية الحيوانات'
    | 'مزرعة'
    | 'أراضي زراعية'
    | 'أرض للبناء'
    | 'أراضي ذات رخصة سياحية'
    | 'أراضي ذات رخصة صناعية';
  province:
    | 'غير محدد'
    | 'دمشق'
    | 'ريف دمشق'
    | 'حلب'
    | 'طرطوس'
    | 'اللاذقية'
    | 'حمص'
    | 'حماة'
    | 'السويداء'
    | 'الحسكة'
    | 'درعا'
    | 'ادلب'
    | 'دير الزور'
    | 'القنيطرة'
    | 'الرقة';
};

export type Category = {
  title: string;
  items: Array<CategoryItem>;
};

export type Categories = Array<Category>;
export const categories: Categories = [
  {
    title: 'البحث الشعبي',
    items: [
      { business_offer: 'غير محدد', property_type: 'غير محدد', province: 'حلب' },
      { business_offer: 'تأجير', property_type: 'غير محدد', province: 'دمشق' },
      { business_offer: 'غير محدد', property_type: 'غير محدد', province: 'حمص' },
      { business_offer: 'تأجير', property_type: 'مكتب', province: 'غير محدد' },
      { business_offer: 'تأجير', property_type: 'عيادة', province: 'غير محدد' },
      { business_offer: 'بيع', property_type: 'غير محدد', province: 'حمص' },
    ],
  },
  {
    title: 'العقارات التجارية',
    items: [
      { business_offer: 'غير محدد', property_type: 'مكتب', province: 'غير محدد' },
      { business_offer: 'غير محدد', property_type: 'محلات تجارية', province: 'غير محدد' },
      { business_offer: 'غير محدد', property_type: 'عيادة', province: 'غير محدد' },
      { business_offer: 'غير محدد', property_type: 'أراضي زراعية', province: 'غير محدد' },
      { business_offer: 'تأجير', property_type: 'محلات تجارية', province: 'غير محدد', },
      { business_offer: 'غير محدد', property_type: 'أرض للبناء', province: 'غير محدد' },
    ],
  },
  {
    title: 'العقارات الأكثر بحثاً',
    items: [
      { business_offer: 'غير محدد', property_type: 'شاليه', province: 'طرطوس' },
      { business_offer: 'غير محدد', property_type: 'غير محدد', province: 'اللاذقية' },
      { business_offer: 'تأجير', property_type: 'شقة', province: 'دمشق' },
      { business_offer: 'تأجير', property_type: 'شقة', province: 'اللاذقية' },
      { business_offer: 'بيع', property_type: 'شقة', province: 'دمشق' },
      { business_offer: 'غير محدد', property_type: 'غير محدد', province: 'دمشق' },
    ],
  },
];
