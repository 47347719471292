import {GET_JOINS , DELETE_JOIN , ADD_JOIN ,GET_JOIN} from './types';
import axios from 'axios';
import { mainDomain } from './../mainConf';


export const getJoins = (ikarAdmin) => async (dispatch) => {
    let res = {};
    let errorRes = {
      errorStatus: '',
      errorMessage: '',
     
    };
    var token = '';
    if (ikarAdmin) {
      token = localStorage.getItem('ikar_token');
      await axios
        .get(
          `${mainDomain}/v1/joins_us`,
          // ,
          // {
          //   withCredentials: true,
          // }
          {
            headers: {
              //'Content-Type': 'application/json',
              Accept: 'application/json',
              //Accept: 'application/json',
              Authorization: token,
            },
          }
        )
        .then(function (response) {
          if (
            response !== null &&
            response !== undefined &&
            Object.keys(response).length > 0
          ) {
            res = response.data;
            res.resType = 'complete';
            
          } else res = {};
        })
        .catch(function (error) {
          if (error.response) {
            errorRes = {
              errorStatus: error.response.status,
              errorMessage: error.response,
            };
          } else if (error.request) {
            errorRes = {
              errorMessage: error.request,
            };
          } else {
            errorRes = { errorMessage: error.message };
          }
          res = errorRes;
          res.resType = 'not complete';
        }
        ) ;
    } else {
    await axios
      .get(
        `${mainDomain}/v1/joins_us`,
         
        {
          headers: {
            Accept: 'application/json'
           // Authorization: localStorage.getItem('ikar_token'),
          },
        }
      )
      .then(function (response) {
        if (
          response !== null &&
          response !== undefined &&
          Object.keys(response).length > 0
        ) {
          res = response.data;
          res.resType = 'complete';
        } else res = {};
         
      })
      .catch(function (error) {
        if (error.response) {
          errorRes = {
            errorStatus: error.response.status,
            errorMessage: error.response,
          };
        } else if (error.request) {
          errorRes = {
            errorMessage: error.request,
          };
        } else {
          errorRes = { errorMessage: error.message };
        }
       
        res = errorRes;
        res.resType = 'not complete';
       
      }
      ) ;     
  }
    dispatch({
      type: GET_JOINS,
      payload: res,
    });
};

  export const deleteJoin = (reference_id, processType) => async (dispatch) => {
    let res = {};
    let errorRes = {
      errorStatus: '',
      errorMessage: '',
    };
    await axios
      .delete(`${mainDomain}/v1/join_us?reference_id=${reference_id}`, {
        headers: {
          Accept: 'application/json',
          Authorization: localStorage.getItem('ikar_token'),
        },
      })
      .then(function (response) {
        if (
          response !== null &&
          response !== undefined &&
          Object.keys(response).length > 0
        ) {
          res = response.data;
          res.message = processType;
        } else {
          res = {};
        }
      })
      .catch(function (error) {
        if (error.response) {
          errorRes = {
            errorStatus: error.response.status,
            errorMessage: error.response,
          };
        } else if (error.request) {
          errorRes = {
            errorMessage: error.request,
          };
        } else {
          errorRes = { errorMessage: error.message };
        }
        res = errorRes;
      });
    dispatch({
      type: DELETE_JOIN,
      payload: res,
    });
  };
  
export const addJoin = (join) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  await axios
    .post(
      `${mainDomain}/v1/join_us`,
      {join_us:join},
      // ,
      // {
      //   withCredentials: true,
      // }
      {
        headers: {
          Accept: 'application/json',
          Authorization: localStorage.getItem('ikar_token'),
        },
      }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
        res.resType = 'complete';
      } else res = {};
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
      res.resType = 'not complete';
    });
  dispatch({
    type: ADD_JOIN,
    payload: res,
  });
};
export const getJoin = (ikarAdmin, joinReferenceId) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  var token = '';
  var joinRefId = joinReferenceId;
  if (ikarAdmin) {
    token = localStorage.getItem('ikar_token');
    await axios
      .get(`${mainDomain}/v1/join_us?reference_id=${joinRefId}`, {
        headers: {
          //'Content-Type': 'application/json',
          Accept: 'application/json',
          //Accept: 'application/json',
          Authorization: token,
        },
      })
      .then(function (response) {
        if (
          response !== null &&
          response !== undefined &&
          Object.keys(response).length > 0
        ) {
          res = response.data;
          res.resType = 'complete';
        } else res = {};
      })
      .catch(function (error) {
        if (error.response) {
          errorRes = {
            errorStatus: error.response.status,
            errorMessage: error.response,
          };
        } else if (error.request) {
          errorRes = {
            errorMessage: error.request,
          };
        } else {
          errorRes = { errorMessage: error.message };
        }
        res = errorRes;
        res.resType = 'not complete';
      });
  } else {
    await axios
      .get(
        `${mainDomain}/v1/join_us?reference_id=${joinRefId}`,
        // ,
        // {
        //   withCredentials: true,
        // }
        {
          headers: {
            //'Content-Type': 'application/json',
            Accept: 'application/json',
            //Accept: 'application/json',
            // Authorization: token,
          },
        }
      )
      .then(function (response) {
        if (
          response !== null &&
          response !== undefined &&
          Object.keys(response).length > 0
        ) {
          res = response.data;
          res.resType = 'complete';
        } else res = {};
      })
      .catch(function (error) {
        if (error.response) {
          errorRes = {
            errorStatus: error.response.status,
            errorMessage: error.response,
          };
        } else if (error.request) {
          errorRes = {
            errorMessage: error.request,
          };
        } else {
          errorRes = { errorMessage: error.message };
        }
        res = errorRes;
        res.resType = 'not complete';
      });
  }
  dispatch({
    type: GET_JOIN,
    payload: res,
  });
};
