import React, { useState, useEffect } from 'react';
import CategoryCard from './CategoryCard';
import {categories,Category} from '../mainConf/categoryTemplate';

export const Categories = () => {
  return (
    <div className="container">
      <div className="row">
        {categories.map((category: Category, index: number) => {
          return (
            <div className="col-md-4" key={index}>
              <CategoryCard
                title={category.title}
                items={category.items}
              ></CategoryCard>
            </div>
          );
        })}
      </div>
    </div>
  );
};
