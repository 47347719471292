import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addAgency } from './../../actions/agencyActions';
import { Growl } from 'primereact/growl';
import classnames from 'classnames';
import Lightbox from 'react-image-lightbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog } from 'primereact/dialog';
import { syriaAreas } from './../../mainConf';
import { Map, TileLayer } from 'react-leaflet';
import { ToggleButton } from 'primereact/togglebutton';
import base64toblob from 'base64toblob';
import imageFileToBase64 from 'image-file-to-base64-exif';
// import InputMask from 'react-input-mask';
import { noInternetMessage, mapsEnabled } from './../../mainConf';
import { Helmet } from 'react-helmet';
class agencyRegistration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: '',
      pageDescription: '',
      requestFailedMessage: '',
      requestSuccessMessage: '',
      showMessages: true,
      isOpen: false,
      hasLocation: false,
      lat: 34.890596,
      lng: 35.881085,
      zoom: 17,
      scale: 100,
      quality: 75,
      selectedUsers: [],
      noMessages: true,
      areas: [],
      user: {},
      name: '',
      description: '',
      contact_email: '',
      password: '',
      password_confirmation: '',
      mobile: '009639',
      phone: '00963',
      reference_id: '',
      whatsapp: '009639',
      telegram: '009639',
      roles: [],
      gps_location: '',
      showMap: false,
      processScreen: false,
      IsUsingGPS: false,
      propertyPosition: null,
      business_email: '',
      charging_plan_id: 1,
      city: '',
      province: 'غير محدد',
      area: 'غير محدد',
      instagram: '',
      is_blocked: false,
      is_charged: true,
      other_phones: '',
      street: '',
      twitter: '',
      facebook: '',
      legal_address: '',
      business_address: '',
      admins: [],
      agents: [],
      photoIndex: 0,
      fullSelectedFilesArray: [],
      photo_name: '',
      photo_data: '',
      errors: {},
      resMessages: {},
      resType: '',
    };
  }
  getCurrentLocation = async () => {
    const currentLocation = window.navigator && window.navigator.geolocation;
    if (currentLocation) {
      await currentLocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          this.setState({
            lat,
            lng,
          });
        },
        (error) => {
          this.setState({
            lat: 34.890596,
            lng: 35.881085,
          });
        }
      );
    }
  };

  componentWillReceiveProps(nextProps, nextState) {
    var errors = {};
    var noErrors = true;
    if (nextProps.resType !== this.state.resType) {
      this.setState({
        resType: nextProps.resType,
        processScreen: false,
      });

      if (
        nextProps.requestFailed &&
        nextProps.requestFailedMessage.errorMessage !== undefined &&
        nextProps.requestFailedMessage.errorMessage !== null &&
        // !this.state.noMessages &&
        nextProps.requestFailedMessage.errorMessage.data !== undefined &&
        nextProps.requestFailedMessage.errorMessage.data.errors !== undefined &&
        nextProps.requestFailedMessage.errorMessage.data.errors[0].detail ===
          `Agency's email must be unique. Agency with email: ${this.state.contact_email} already exists.`
      ) {
        noErrors = false;
        errors = {};
        errors.contact_email = 'الايميل المدخل موجود مسبقا';
        this.setState({
          resMessages: {
            message: 'الايميل المدخل موجود مسبقا',
          },
          errors,
        });
      } else if (
        nextProps.requestFailed &&
        nextProps.requestFailedMessage.errorMessage !== undefined &&
        nextProps.requestFailedMessage.errorMessage !== null &&
        // !this.state.noMessages &&
        nextProps.requestFailedMessage.errorMessage.data.errors[0].detail ===
          `Agency's mobile must be unique. Agency with mobile: ${this.state.mobile} already exists.`
      ) {
        noErrors = false;
        errors = {};
        errors.mobile = 'رقم الموبايل المدخل موجود مسبقا';
        this.setState({
          resMessages: {
            message: 'رقم الموبايل المدخل موجود مسبقا',
          },
          errors,
        });
      } else if (
        nextProps.requestFailed &&
        nextProps.requestFailedMessage.errorMessage !== undefined &&
        nextProps.requestFailedMessage.errorMessage !== null &&
        // !this.state.noMessages &&
        nextProps.requestFailedMessage.errorMessage.data.errors[0].detail ===
          `Create agency request body is not valid  => contact_email : is in invalid format`
      ) {
        noErrors = false;
        errors = {};
        errors.contact_email = 'الرجاء التأكد من الايميل المدخل';
        this.setState({
          resMessages: {
            message: 'الرجاء التأكد من الايميل المدخل',
          },
          errors,
        });
      }
    }
    if (
      nextProps.requestSuccessMessage !== this.state.requestSuccessMessage &&
      this.state.requestSuccessMessage !== '' &&
      nextProps.requestSuccessMessage !== undefined &&
      nextProps.requestSuccessMessage !== null
    ) {
      this.setState({
        requestSuccessMessage: nextProps.requestSuccessMessage,
      });
      if (nextProps.requestFailedMessage !== undefined) {
        this.setState({
          requestFailedMessage: nextProps.requestFailedMessage,
        });
      }
      switch (nextProps.requestSuccessMessage) {
        case 'add is succeeded':
          this.growl.show({
            severity: 'success',
            summary:
              'تم اضافة المكتب بنجاح ... الرجاء التحقق من بريدك الاكتروني لتفعيل الحساب',
          });
          this.handleClear(null);
          break;

        case 'error in add agency':
          if (noErrors === true) {
            this.growl.show({
              severity: 'error',
              summary: 'حدثت مشكلة في اضافة المكتب الرجاء المحاولة بعد قليل',
            });
          }
          break;
        default:
          break;
      }
    }
  }
  componentDidMount() {
    this.getCurrentLocation();
    // jQuery(function($) {
    //   $(document).ready(function() {
    //     $('#phoneId').Inputmask('99-9999999');

    //   });
    // });
    // jQuery(function($) {
    //   $('#userTable').on('click', '.clickable-row', function(event) {
    //     $(this)
    //       .addClass('table-success')
    //       .siblings()
    //       .removeClass('table-success');
    //   });
    // });
  }
  // name,contact_email,password,is_agency_admin,is_ikar_admin,surname,title,description,roles,mobile,facebook,whatsapp,agency_ref_id,agency_rank,
  isNumeric = (num) => {
    // return !isNaN(num);
    // return /^-{0,1}\d+$/.test(num);
    return /^\d+$/.test(num);
  };
  onSubmit = async (e) => {
    e.preventDefault();
    await this.setState({
      requestSuccessMessage: 'ready',
    });

    const {
      // selectedUsers,
      // noMessages,
      // areas,
      // user,
      name,
      description,
      contact_email,
      password,
      password_confirmation,
      mobile,
      phone,
      // reference_id,
      whatsapp,
      telegram,
      roles,
      gps_location,
      // showMap,
      // processScreen,
      // IsUsingGPS,
      // propertyPosition,
      business_email,
      charging_plan_id,
      // city,
      area,
      instagram,
      is_blocked,
      is_charged,
      other_phones,
      province,
      street,
      twitter,
      facebook,
      legal_address,
      business_address,
      // admins,
      agents,
      // fullSelectedFilesArray,
      photo_name,
      photo_data,
    } = this.state;
    // if (selectedUsers.length === 0) {
    //   this.setState({
    //     errors: {
    //       selectedUsers: 'يجب ادخال مشرف واحد على الاقل لكل مكتب عقاري'
    //     }
    //   });
    //   return;
    // }
    // if (selectedUsers.length > 3) {
    //   this.setState({
    //     errors: {
    //       selectedUsers:
    //         'يجب ألا يزيد عدد المشرفين على المكتب العقاري الواحد عن 3 مشرفين'
    //     }
    //   });
    //   return;
    // }
    // let selectedEmails = [];
    // selectedUsers.map(
    //   user => (selectedEmails = [...selectedEmails, user.contact_email])
    // );
    var errors = {};
    if (contact_email.trim() === '') {
      errors.contact_email = 'يجب ادخال الايميل';
    } else if (!/[\w+\-.]+@[a-zA-Z.-]+\.[a-z]{2,3}/.test(contact_email)) {
      errors.contact_email = 'يجب التأكد من الايميل المدخل';
    }
    if (business_email !== '') {
      if (!/[\w+\-.]+@[a-zA-Z.-]+\.[a-z]{2,3}/.test(business_email)) {
        errors.business_email = 'يجب التأكد من الايميل المدخل';
      }
    }
    if (password.trim() === '') {
      errors.password = 'يجب ادخال كلمة السر';
    } else if (password.length < 9) {
      errors.password = 'كلمة السر على الاقل تسعة رموز';
    }
    if (password_confirmation.trim() === '') {
      errors.password_confirmation = 'يجب ادخال اعادة كلمة السر';
    } else if (password !== password_confirmation) {
      errors.password_confirmation = 'كلمتا السر غير متطابقتين';
    }
    if (name.trim() === '') {
      errors.name = 'يجب ادخال الاسم';
    }
    if (this.isNumeric(name.trim())) {
      errors.name = 'يجب التأكد من الاسم المدخل';
    }

    if (name.length < 3) {
      errors.name = 'يجب ان يكون اسم المكتب على الاقل 3 حروف';
    }

    if (name.length > 30) {
      errors.name = 'يجب الا يتجاوز اسم المكتب المدخل 30 حرف';
    }
    if (province === 'غير محدد' || province === '') {
      errors.province = 'يجب ادخال المحافظة';
    }

    // if (area === 'غير محدد' || area === '') {
    //   errors.area = 'يجب ادخال المنطقة';
    // }
    var area1=area;
    if(area === 'غير محدد' || area === '')
       area1=null;
    // if (city.trim() === '') {
    //   this.setState({
    //     errors: { city: 'يجب ادخال العنوان التفصيلي' }
    //   });
    //   return;
    // }
    // if (phone.trim() === '') {
    //   this.setState({ errors: { phone: 'يجب ادخال رقم الهاتف' } });
    //   return;
    // }
    if (mobile.trim() === '' || mobile.trim() === '009639') {
      errors.mobile = 'يجب ادخال رقم الموبايل';
    } else if (mobile.trim() !== '' && mobile.trim() !== '009639') {
      if (String(mobile.trim()).charAt(0) !== '0') {
        errors.mobile = 'يجب التأكد من رقم الموبايل';
      }
      if (mobile.trim().length < 14 || mobile.trim().length > 14) {
        errors.mobile = 'يجب التأكد من رقم الموبايل';
      }
      if (mobile.trim().length > 1 && !this.isNumeric(mobile.trim())) {
        errors.mobile = 'يجب التأكد من رقم الموبايل';
      }
    }

    if (other_phones.trim().length > 10) {
      errors.other_phones = 'يجب التأكد من رقم الاتصال';
    }

    if (whatsapp.trim() !== '' && whatsapp.trim() !== '009639') {
      if (String(whatsapp.trim()).charAt(0) !== '0') {
        errors.whatsapp = 'يجب التأكد من رقم الواتس أب';
      }
      if (whatsapp.trim().length < 14 || whatsapp.trim().length > 14) {
        errors.whatsapp = 'يجب التأكد من رقم الواتس أب';
      }
      if (whatsapp.trim().length > 1 && !this.isNumeric(whatsapp.trim())) {
        errors.whatsapp = 'يجب التأكد من رقم الواتس أب';
      }
    }

    if (telegram.trim() !== '' && telegram.trim() !== '009639') {
      if (String(telegram.trim()).charAt(0) !== '0') {
        errors.telegram = 'يجب التأكد من رقم التيلغرام';
      }
      if (telegram.trim().length < 14 || telegram.trim().length > 14) {
        errors.telegram = 'يجب التأكد من رقم التيلغرام';
      }
      if (telegram.trim().length > 1 && !this.isNumeric(telegram.trim())) {
        errors.telegram = 'يجب التأكد من رقم التيلغرام';
      }
    }

    if (phone.trim() !== '' && phone.trim() !== '00963') {
      if (String(phone.trim()).charAt(0) !== '0') {
        errors.phone = 'يجب التأكد من رقم الهاتف الأرضي';
      }
      if (phone.trim().length > 7 && phone.trim().length < 11) {
        errors.phone = 'يجب التأكد من رقم الهاتف الأرضي';
      }
      if (phone.trim().length > 1 && !this.isNumeric(phone.trim())) {
        errors.phone = 'يجب التأكد من رقم الهاتف الأرضي';
      }
    }
    // if (telegram.trim() !== "" && telegram.trim() !== "09") {
    //   if (String(telegram.trim()).charAt(0) !== "0") {
    //     errors.telegram = "يجب التأكد من رقم ال تيلغرام";
    //   }
    //   if (telegram.trim().length <= 10) {
    //     errors.telegram = "يجب التأكد من رقم ال تيلغرام";
    //   }
    // }

    // if (whatsapp.trim() !== "" && whatsapp.trim() !== "09") {
    //   if (String(whatsapp.trim()).charAt(0) !== "0") {
    //     errors.whatsapp = "يجب التأكد من رقم ال واتساب";
    //   }
    //   if (whatsapp.trim().length <= 10) {
    //     errors.whatsapp = "يجب التأكد من رقم ال واتساب";
    //   }
    // }

    // if (telegram.trim() === '' || telegram.trim() === '009639') {
    //   errors.telegram = 'يجب ادخال رقم ال تيلغرام';
    // }
    // if (facebook.trim() === '') {
    //   this.setState({ errors: { facebook: 'يجب ادخال عنوان صفحة ال فيسبوك' } });
    //   return;
    // }
    // if (legal_address.trim() === '') {
    //   this.setState({
    //     errors: { legal_address: 'يجب ادخال العنوان ' }
    //   });
    //   return;
    // }
    // if (business_address.trim() === '') {
    //   this.setState({
    //     errors: { business_address: 'يجب ادخال العنوان ' }
    //   });
    //   return;
    // }
    // if (contact_email.trim() === '') {
    //   this.setState({
    //     errors: { contact_email: 'يجب ادخال الايميل' }
    //   });
    //   return;
    // }
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }
    await this.setState({
      errors: {},
      resMessages: {},
      processScreen: true,
      resType: '',
    });
    // const agency = {
    //   name,
    //   description,
    //   contact_email,
    //   mobile,
    //   phone: phone.replace(/\s/g, ''),
    //   whatsapp,
    //   telegram,
    //   roles,
    //   gps_location,
    //   business_email,
    //   charging_plan_id,
    //   city: area,
    //   area,
    //   facebook,
    //   instagram,
    //   is_blocked,
    //   is_charged,
    //   other_phones,
    //   province,
    //   street,
    //   twitter,
    //   legal_address,
    //   business_address,
    //   password,
    //   agents,
    //   photo_name,
    //   photo_data
    // };
    // var Phone = '';
    //return;
    // if (phone.length === 3 && parseInt(phone[0]) === 0) {
    //   Phone = '';
    // } else {
    //   Phone = phone;
    // }
    const agency = {
      name: name === '' ? null : name,
      description: description === '' ? null : description,
      contact_email: contact_email === '' ? null : contact_email,
      mobile:
        mobile.replace(/\s/g, '') === '' ||
        mobile.replace(/\s/g, '') === '009639'
          ? null
          : mobile.replace(/\s/g, ''),

      // phone: Phone.replace(/\s/g, '') === '' ? null : Phone.replace(/\s/g, ''),
      phone:
        phone.replace(/\s/g, '') === '' ||
        phone.replace(/\s/g, '') === '00963' ||
        phone.replace(/\s/g, '').length === 7
          ? null
          : phone.replace(/\s/g, ''),

      whatsapp:
        whatsapp.replace(/\s/g, '') === '' ||
        whatsapp.replace(/\s/g, '') === '009639'
          ? null
          : whatsapp.replace(/\s/g, ''),

      telegram:
        telegram.replace(/\s/g, '') === '' ||
        telegram.replace(/\s/g, '') === '009639'
          ? null
          : telegram.replace(/\s/g, ''),

      roles: roles === '' ? null : roles,
      gps_location: gps_location === '' ? null : gps_location,
      business_email: business_email === '' ? null : business_email,
      charging_plan_id: charging_plan_id === '' ? null : charging_plan_id,
      city: area1 === '' ? null : area1,
      area: area1 === '' ? null : area1,
      facebook: facebook === '' ? null : facebook,
      instagram: instagram === '' ? null : instagram,
      is_blocked: is_blocked === '' ? null : is_blocked,
      is_charged: is_charged === '' ? null : is_charged,
      other_phones: other_phones === '' ? null : other_phones,
      province: province === '' ? null : province,
      street: street === '' ? null : street,
      twitter: twitter === '' ? null : twitter,
      legal_address: legal_address === '' ? null : legal_address,
      business_address: business_address === '' ? null : business_address,
      password: password === '' ? null : password,
      agents: agents === '' ? null : agents,
      photo_name: photo_name === '' ? null : photo_name,
      photo_data: photo_data === '' ? null : photo_data,
    };
    // {
    //   name,
    //   description,
    //   contact_email,
    //   password,
    //   mobile,
    //   phone,
    //   reference_id,
    //   whatsapp,
    //   roles,
    //   gps_location,
    //   showMap,
    //   business_email,
    //   charging_plan_id,
    //   city: province,
    //   area,
    //   instagram,
    //   is_blocked,
    //   is_charged,
    //   other_phones,
    //   province,
    //   street,
    //   twitter,
    //   facebook,
    //   legal_address,
    //   business_address,
    //   admins: selectedEmails,
    //   agents
    // };
    const agencyDate = { agency };

    this.props.addAgency(agencyDate);
    this.setState({ noMessages: false });
    // this.setState({
    //   selectedUsers: [],
    //   showMap: false,
    //   areas: [],
    //   user: {},
    //   name: '',
    //   description: '',
    //   //contact_email: '',
    //   password: '',
    //   password_confirmation: '',
    //   mobile: '009639',
    //   phone: '00963',
    //   reference_id: '',
    //   whatsapp: '009639',
    //   telegram: '009639',
    //   roles: [],
    //   gps_location: '',
    //   processScreen: false,
    //   IsUsingGPS: false,
    //   propertyPosition: null,
    //   business_email: '',
    //   charging_plan_id: 1,
    //   city: '',
    //   area: '',
    //   instagram: '',
    //   is_blocked: false,
    //   is_charged: true,
    //   other_phones: '',
    //   province: '',
    //   street: '',
    //   twitter: '',
    //   facebook: '',
    //   legal_address: '',
    //   business_address: '',
    //   admins: [],
    //   agents: [],
    //   errors: {},
    //   fullSelectedFilesArray: [],
    //   photo_name: '',
    //   photo_data: '',
    //   isOpen: false
    // });
  };
  handleClear = (e) => {
    if (e !== null) {
      e.preventDefault();
    }
    this.setState({
      selectedUsers: [],
      noMessages: true,
      isOpen: false,
      areas: [],
      user: {},
      name: '',
      description: '',
      contact_email: '',
      password: '',
      password_confirmation: '',
      mobile: '009639',
      phone: '00963',
      reference_id: '',
      whatsapp: '009639',
      telegram: '009639',
      roles: [],
      gps_location: '',
      showMap: false,
      processScreen: false,
      IsUsingGPS: false,
      propertyPosition: null,
      business_email: '',
      charging_plan_id: 1,
      city: '',
      area: 'غير محدد',
      instagram: '',
      is_blocked: false,
      is_charged: true,
      other_phones: '',
      province: 'غير محدد',
      street: '',
      twitter: '',
      facebook: '',
      legal_address: '',
      business_address: '',
      admins: [],
      agents: [],
      errors: {},
      photoIndex: 0,
      fullSelectedFilesArray: [],
      photo_name: '',
      photo_data: '',
      requestFailedMessage: '',
      requestSuccessMessage: '',
      lat: 34.890596,
      lng: 35.881085,
      zoom: 17,
      scale: 100,
      quality: 75,
    });
  };
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  onMobileChange = (e) => {
    // if (e.target.value.length > 10 || e.target.value.length === 1) {
    //   return;
    // }
    // if (/^\d+$/.test(e.target.value)) {
    //   this.setState({ [e.target.name]: e.target.value });
    // }

    if (e.target.value.length > 14 || e.target.value.length === 4) {
      return;
    }
    if (/^(009639)\d{0,8}?$/.test(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  onPhoneChange = (e) => {
    if (e.target.value.length > 16 || e.target.value.length === 3) {
      return;
    }
    if (/^(00963)\d{0,9}?$/.test(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  onNameChange = (e) => {
    if (e.target.value.length > 30) {
      return;
    }
    this.setState({ [e.target.name]: e.target.value });
  };
  onCheckBoxChange = (e) =>
    this.setState({ [e.target.name]: e.target.checked });
  onProvinceChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.value === 'دمشق') {
      //دمشق
      this.setState({ areas: syriaAreas.damascusAreas, phone: '0096311' });
    } else if (e.target.value === 'ريف دمشق') {
      //ريف دمشق
      this.setState({
        areas: syriaAreas.rifDamascusAreas,
        phone: '0096311',
      });
    } else if (e.target.value === 'حلب') {
      //حلب
      this.setState({
        areas: syriaAreas.aleppoAreas,
        phone: '0096321',
      });
    } else if (e.target.value === 'طرطوس') {
      //طرطوس
      this.setState({
        areas: syriaAreas.tartousAreas,
        phone: '0096343',
      });
    } else if (e.target.value === 'اللاذقية') {
      //اللاذقية
      this.setState({ areas: syriaAreas.latakiaAreas, phone: '0096341' });
    } else if (e.target.value === 'حمص') {
      //حمص
      this.setState({
        areas: syriaAreas.homsAreas,
        phone: '0096331',
      });
    } else if (e.target.value === 'حماة') {
      //حماة
      this.setState({ areas: syriaAreas.hamaAreas, phone: '0096333' });
    } else if (e.target.value === 'السويداء') {
      //السويداء
      this.setState({ areas: syriaAreas.asuwaydaAreas, phone: '0096316' });
    } else if (e.target.value === 'الحسكة') {
      //الحسكة
      this.setState({
        areas: syriaAreas.hasakaAreas,
        phone: '0096352',
      });
    } else if (e.target.value === 'درعا') {
      //درعا
      this.setState({ areas: syriaAreas.daraaAreas, phone: '0096315' });
    } else if (e.target.value === 'ادلب') {
      //ادلب
      this.setState({
        areas: syriaAreas.idlibAreas,
        phone: '0096323',
      });
    } else if (e.target.value === 'دير الزور') {
      //دير الزور
      this.setState({ areas: syriaAreas.deirezorAreas, phone: '0096351' });
    } else if (e.target.value === 'القنيطرة') {
      //القنيطرة
      this.setState({ areas: syriaAreas.quneitraAreas, phone: '0096314' });
    } else if (e.target.value === 'الرقة') {
      //الرقة
      this.setState({ areas: syriaAreas.raqqaAreas, phone: '0096322' });
    } else {
      this.setState({ areas: [], phone: '00963' });
    }
  };
  onMapClick = async (e) => {
    const lat = e.latlng.lat;
    const lng = e.latlng.lng;
    const gps_location = `${lat},${lng}`;

    await this.setState({
      propertyPosition: e.latlng,
      gps_location,
      IsUsingGPS: true,
    });
  };
  onUploadImage = async (e) => {
    this.setState({ errors: {} });
    var propertyImages = e.target.files;
    var selectedFileArray = [];
    var fullSelectedFilesArray = [];
    var url = '';
    var obj = {};
    for (var i = 0; i < propertyImages.length; i++) {
      var filesize = (propertyImages[i].size / 1024 / 1024).toFixed(4);
      if (filesize <= 5) {
        selectedFileArray = [];
        obj = {};
        url = URL.createObjectURL(propertyImages[i]);
        obj = propertyImages[i];
        obj.objectURL = url;
        var Base64ArrayStr = await this.getBase64(obj);
        var compressedImage = await this.onImageCompressor(obj, Base64ArrayStr);

        selectedFileArray.push(
          compressedImage.compressed.name,
          compressedImage.compressed.size,
          compressedImage.compressed,
          i
        );

        fullSelectedFilesArray.push(selectedFileArray);
      } else {
        this.setState({
          errors: {
            fileSize: `الملف ${propertyImages[i].name} حجمه اكبر من 5 ميغا بايت`,
          },
        });
        return;
      }
    }
    var Base64Array = [];
    for (var ii = 0; ii < fullSelectedFilesArray.length; ii++) {
      Base64ArrayStr = fullSelectedFilesArray[ii][2].base64;
      Base64Array.push(Base64ArrayStr);
    }

    this.setState({
      fullSelectedFilesArray,
      photo_data: Base64Array[0],
      photo_name: compressedImage.compressed.name,
    });
  };
  onDeleteImage = () => {
    this.setState({
      fullSelectedFilesArray: [],
      errors: {},
      photo_data: '',
      photo_name: '',
    });
    document.getElementById('upload_images').value = '';
    // Array.from(
    //   document.getElementsByClassName('p-button-text-icon-left')
    // ).forEach(function(element, index, array) {
    //   if (element.textContent === 'حذف كافة صور العقار') {s
    //     element.click();
    //   }
    // });
  };
  getBase64 = async (file) => {
    var resultValue = imageFileToBase64(file);
    return resultValue;
  };
  addImgObj = (imageFile) => {
    return new Promise((resolve) => {
      let img = new Image();
      img.src = imageFile;

      img.addEventListener('load', function () {
        var obj = {};
        obj.width1 = this.width;
        obj.height1 = this.height;
        resolve(obj);
      });
    });
  };
  onImageCompressor = async (originImage, imageFile) => {
    var resultObjToPass = {};
    let canvas = document.createElement('canvas');
    let ctx = canvas.getContext('2d');

    let img = new Image();
    img.src = imageFile;
    await img.addEventListener('load', function () {});
    if (img.width === 0) {
      const obj = await this.addImgObj(imageFile);
    }
    let scale = this.state.scale / 100;
    if (img.width > 1200) {
      scale = 1200 / img.width;
      scale = scale.toFixed(4);
    }
    let width = img.width * scale;
    let height = img.height * scale;
    canvas.setAttribute('width', width);
    canvas.setAttribute('height', height);
    ctx.drawImage(img, 0, 0, width, height);
    let quality = this.state.quality ? this.state.quality / 100 : 1;
    let base64 = canvas.toDataURL('image/jpeg', quality);
    let fileName = originImage.name;

    let lastDot = fileName.lastIndexOf('.');
    fileName = fileName.substr(0, lastDot); // + '.jpeg'

    let objToPass = {
      canvas: canvas,
      original: originImage,
      compressed: {
        blob: this.toBlob(base64),
        base64: base64,
        name: fileName,
        file: this.buildFile(base64, fileName),
      },
    };

    objToPass.compressed.size = Math.round(
      objToPass.compressed.file.size / 1000
    ); //+ ' kB'
    objToPass.compressed.type = 'image/jpeg';
    resultObjToPass = await objToPass;
    return resultObjToPass;
  };
  toBlob = (imgUrl) => {
    let blob = base64toblob(imgUrl.split(',')[1], 'image/jpeg');
    let url = window.URL.createObjectURL(blob);
    return url;
  };
  buildFile = (blob, name) => {
    return new File([blob], name);
  };
  handleToggleButton = (e) => {
    this.setState({ showMap: e.value });
    if (!e.value) {
      this.setState({
        propertyPosition: null,
        gps_location: '',
        IsUsingGPS: false,
      });
    }
  };
  // mapRef = createRef<Map>()
  render() {
    const position = [this.state.lat, this.state.lng];
    const {
      resMessages,
      // selectedUsers,
      // noMessages,
      isOpen,
      areas,
      // user,
      name,
      description,
      contact_email,
      password,
      password_confirmation,
      mobile,
      phone,
      // reference_id,
      whatsapp,
      telegram,
      // roles,
      // gps_location,
      showMap,
      // processScreen,
      IsUsingGPS,
      propertyPosition,
      business_email,
      // charging_plan_id,
      // city,
      area,
      instagram,
      is_blocked,
      // is_charged,
      other_phones,
      province,
      street,
      twitter,
      facebook,
      legal_address,
      business_address,
      // admins,
      // agents,
      fullSelectedFilesArray,
      photoIndex,
      photo_name,
      // photo_data,
      errors,
      processScreen,
    } = this.state;
    return (
      <div>
        <Helmet>
          <title>{this.state.pageTitle}</title>
          <meta name="description" content={this.state.pageDescription} />
          <meta name="robots" content="noindex" />
        </Helmet>
        <div className="row">
          <div className="col-md-10 mx-auto">
            <div className="mt-2 text-center">
              <h1 className="main_title">
                {/* <FontAwesomeIcon
                  icon={['fas', 'address-card']}
                  size="1x"
                  className="text-primary mx-2"
                /> */}
                اضافة مكتب عقاري
              </h1>
            </div>

            <div className="text-center pb-4 pt-3 display_5">
              <Growl ref={(el) => (this.growl = el)} />
            </div>
          </div>
        </div>
        {processScreen ? (
          // <div className="process-loader">Ikar.sy</div>
          <div className="process-loader">
            <div className="span text-center">
              {/* <div className="location_indicator" /> */}
              <div
                className="spinner-border text-warning"
                style={{ width: '3rem', height: '3rem' }}
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
              <p className="text-danger lead text-center">
                <span>www.</span>
                <span className="font-weight-bold">Ikar</span>
                <span className="font-weight-bold">.sy</span>
              </p>
            </div>
          </div>
        ) : null}
        {/* <div className="row">
          <div
            className="col-md-10 mx-auto"
            style={{ height: '300px', overflow: 'scroll' }}
          >
            <h1 className="display-5 mb-2 text-center">
              <span className="text-info">قائمة المستخدمين</span>
            </h1>
            <div className="row">
              <div className="col-12 mx-1">
                <div class="container-fluid">
                  <div className="row justify-content-around align-items-center">
                    <DataTable
                      value={users}
                      header="Single Selection"
                      selection={selectedUsers}
                      onSelectionChange={e =>
                        this.setState({ selectedUsers: e.value })
                      }
                    >
                      <Column
                        header="*"
                        selectionMode="multiple"
                        style={{ width: '20px' }}
                      />

                      <Column field="name" header="الاسم" />
                      <Column field="contact_email" header="الايميل" />
                    </DataTable>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {errors.selectedUsers && (
          <div className="alert alert-danger mt-2 text-right">
            {errors.selectedUsers}
          </div>
        )}
        <p className="text-primary my-2 text-center">
          حدد المستخدم صاحب المكتب العقاري من الجدول السابق
        </p> */}

        <div className="row">
          <div className="col-md-10 mx-auto">
            <form
              // onSubmit={this.onSubmit}
              style={{ direction: 'rtl' }}
            >
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group text-right mb-4">
                    <label htmlFor="contact_email">
                      البريد الالكتروني:{' '}
                      <span className="display_mandatory">(مطلوب)</span>
                    </label>
                    <input
                      type="email"
                      className={classnames('form-control', {
                        'is-invalid': errors.contact_email,
                      })}
                      name="contact_email"
                      value={contact_email}
                      onChange={this.onChange}
                    />
                    {errors.contact_email && (
                      <div className="invalid-feedback">
                        {errors.contact_email}
                      </div>
                    )}
                  </div>
                  <div className="form-group text-right mb-4">
                    <label htmlFor="password">
                      كلمة السر:{' '}
                      <span className="display_mandatory">(مطلوب)</span>
                    </label>
                    <input
                      type="password"
                      className={classnames('form-control', {
                        'is-invalid': errors.password,
                      })}
                      name="password"
                      value={password}
                      onChange={this.onChange}
                    />
                    {errors.password && (
                      <div className="invalid-feedback">{errors.password}</div>
                    )}
                  </div>
                  <div className="form-group text-right mb-4">
                    <label htmlFor="password_confirmation">
                      اعادة كلمة السر:{' '}
                      <span className="display_mandatory">(مطلوب)</span>
                    </label>
                    <input
                      type="password"
                      className={classnames('form-control', {
                        'is-invalid': errors.password_confirmation,
                      })}
                      name="password_confirmation"
                      value={password_confirmation}
                      onChange={this.onChange}
                    />
                    {errors.password_confirmation && (
                      <div className="invalid-feedback">
                        {errors.password_confirmation}
                      </div>
                    )}
                  </div>
                  <div className="form-group text-right mb-4">
                    <label htmlFor="name">
                      اسم المكتب:{' '}
                      <span className="display_mandatory">(مطلوب)</span>
                    </label>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors.name,
                      })}
                      name="name"
                      value={name}
                      onChange={this.onNameChange}
                    />
                    {errors.name && (
                      <div className="invalid-feedback">{errors.name}</div>
                    )}
                  </div>
                  <div className="form-group text-right  mb-4">
                    <label htmlFor="province">
                      المحافظة:{' '}
                      <span className="display_mandatory">(مطلوب)</span>
                    </label>
                    <select
                      name="province"
                      className={classnames('form-control', {
                        'is-invalid': errors.province,
                      })}
                      onChange={this.onProvinceChange}
                      value={province}
                    >
                      <option value="غير محدد">غير محدد</option>
                      <option value="دمشق"> دمشق</option>
                      <option value="ريف دمشق"> ريف دمشق</option>
                      <option value="حلب"> حلب</option>
                      <option value="طرطوس"> طرطوس</option>
                      <option value="اللاذقية"> اللاذقية</option>
                      <option value="حمص"> حمص</option>
                      <option value="حماة"> حماة</option>
                      <option value="السويداء"> السويداء</option>
                      <option value="الحسكة"> الحسكة</option>
                      <option value="درعا"> درعا</option>
                      <option value="ادلب"> ادلب</option>
                      <option value="دير الزور"> دير الزور</option>
                      <option value="القنيطرة"> القنيطرة</option>
                      <option value="الرقة"> الرقة</option>
                    </select>
                    {errors.province && (
                      <div className="invalid-feedback">{errors.province}</div>
                    )}
                  </div>
                  <div className="form-group text-right  mb-4">
                    <label htmlFor="area">
                      المنطقة:{' '}
                      {/* <span className="display_mandatory">(مطلوب)</span> */}
                    </label>
                    <select
                      name="area"
                      className={classnames('form-control', {
                        'is-invalid': errors.area,
                      })}
                      onChange={this.onChange}
                      value={area}
                    >
                      <option value="غير محدد">غير محدد</option>
                      {areas.map((area, index) => (
                        <option value={area} key={index}>
                          {area}
                        </option>
                      ))}
                    </select>
                    {errors.area && (
                      <div className="invalid-feedback">{errors.area}</div>
                    )}
                  </div>
                  {mapsEnabled ? (
                    <div className="form-group round-div py-1">
                      <div className="col-12 text-center">
                        <ToggleButton
                          style={{ width: '175px', fontSize: '16px' }}
                          checked={IsUsingGPS}
                          onChange={this.handleToggleButton}
                          onLabel="عدم تحديد احداثيات"
                          offLabel="تحديد احداثيات"
                          onIcon="pi pi-map-marker"
                          offIcon="pi pi-globe"
                        />
                      </div>
                      <Dialog
                        header="حدد موقع مكتبك على الخريطة"
                        //footer={mapFooter}
                        // iconsTemplate={mapIcon}
                        visible={showMap}
                        style={{ width: '90vw', minHeight: '70vh' }}
                        modal={true}
                        onHide={() => this.setState({ showMap: false })}
                      >
                        <div>
                          {showMap ? (
                            mapsEnabled ? (
                              <Map
                                center={position}
                                length={4}
                                onClick={this.onMapClick}
                                //onLocationfound={this.handleLocationFound}
                                ref={this.mapRef}
                                zoom={this.state.zoom}
                                style={{ width: '85vw', minHeight: '65vh' }}
                              >
                                <TileLayer
                                  attribution='<a href="http://ikar.sy">Ikar.sy</a> &amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                />
                              </Map>
                            ) : (
                              noInternetMessage
                            )
                          ) : null}
                        </div>
                      </Dialog>
                      <div className="col-12 text-right">
                        <div className="row">
                          <div className="col-12 text-right my-1">
                            {!propertyPosition ? (
                              <span>خط العرض : </span>
                            ) : null}
                            {IsUsingGPS && mapsEnabled ? (
                              propertyPosition ? (
                                <React.Fragment>
                                  خط العرض :{' '}
                                  <span className="text-primary mx-2">
                                    {propertyPosition.lat}
                                  </span>
                                  <FontAwesomeIcon
                                    icon={['fas', 'map-marked-alt']}
                                    size="1x"
                                    color="blue"
                                  />
                                </React.Fragment>
                              ) : null
                            ) : null}
                          </div>
                          <div className="col-12 text-right my-1">
                            {!propertyPosition ? (
                              <span>خط الطول : </span>
                            ) : null}
                            {IsUsingGPS && mapsEnabled ? (
                              propertyPosition ? (
                                <React.Fragment>
                                  خط الطول :{' '}
                                  <span className="text-primary mx-2">
                                    {propertyPosition.lng}
                                  </span>
                                  <FontAwesomeIcon
                                    icon={['fas', 'map-marked-alt']}
                                    size="1x"
                                    color="blue"
                                  />
                                </React.Fragment>
                              ) : null
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {/* <div className="form-group text-right mb-4">
                    <label htmlFor="city">المدينة:</label>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors.city
                      })}
                      name="city"
                      value={city}
                      onChange={this.onChange}
                    />
                    {errors.city && (
                      <div className="invalid-feedback">{errors.city}</div>
                    )}
                  </div> */}
                  {/* <div className="form-group text-right mb-4">
                    <label htmlFor="area">عنوان تفصيلي:</label>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors.area
                      })}
                      name="area"
                      value={area}
                      onChange={this.onChange}
                    />
                    {errors.area && (
                      <div className="invalid-feedback">{errors.area}</div>
                    )}
                  </div> */}
                  <div className="form-group text-right mb-4">
                    <label htmlFor="street">الشارع:</label>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors.street,
                      })}
                      name="street"
                      value={street}
                      onChange={this.onChange}
                    />
                    {errors.street && (
                      <div className="invalid-feedback">{errors.street}</div>
                    )}
                  </div>
                  <div className="form-group text-right mb-4">
                    <label htmlFor="legal_address">العنوان الدائم:</label>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors.legal_address,
                      })}
                      name="legal_address"
                      value={legal_address}
                      onChange={this.onChange}
                    />
                    {errors.legal_address && (
                      <div className="invalid-feedback">
                        {errors.legal_address}
                      </div>
                    )}
                  </div>
                  <div className="form-group text-right mb-4">
                    <label htmlFor="business_address">عنوان العمل:</label>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors.business_address,
                      })}
                      name="business_address"
                      value={business_address}
                      onChange={this.onChange}
                    />
                    {errors.business_address && (
                      <div className="invalid-feedback">
                        {errors.business_address}
                      </div>
                    )}
                  </div>
                  <div className="form-group text-right mb-4">
                    <label htmlFor="description">الوصف:</label>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors.description,
                      })}
                      name="description"
                      value={description}
                      onChange={this.onChange}
                    />
                    {errors.description && (
                      <div className="invalid-feedback">
                        {errors.description}
                      </div>
                    )}
                  </div>
                  <div className="form-group text-right mb-4">
                    <label htmlFor="phone">رقم الهاتف (______11-00963):</label>
                    <input
                      type="tel"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      name="phone"
                      // id="phoneId"
                      className={classnames('form-control', {
                        'is-invalid': errors.phone,
                      })}
                      style={{ direction: 'ltr' }}
                      placeholder=""
                      value={phone}
                      onChange={this.onPhoneChange}
                    />

                    {errors.phone && (
                      <div className="invalid-feedback">{errors.phone}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group text-right mb-4">
                    <label htmlFor="mobile">
                      رقم الموبايل (________9-00963):{' '}
                      <span className="display_mandatory">(مطلوب)</span>
                    </label>
                    <input
                      type="tel"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      name="mobile"
                      // id="mobileId"
                      className={classnames('form-control', {
                        'is-invalid': errors.mobile,
                      })}
                      style={{ direction: 'ltr' }}
                      placeholder=""
                      value={mobile}
                      onChange={this.onMobileChange}
                    />

                    {errors.mobile && (
                      <div className="invalid-feedback">{errors.mobile}</div>
                    )}
                  </div>
                  <div className="form-group text-right mb-4">
                    <label htmlFor="whatsapp">رقم ال واتس اب:</label>
                    <input
                      type="tel"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      name="whatsapp"
                      // id="whatsappId"
                      className={classnames('form-control', {
                        'is-invalid': errors.whatsapp,
                      })}
                      style={{ direction: 'ltr' }}
                      placeholder=""
                      value={whatsapp}
                      onChange={this.onMobileChange}
                    />

                    {errors.whatsapp && (
                      <div className="invalid-feedback">{errors.whatsapp}</div>
                    )}
                  </div>
                  <div className="form-group text-right mb-4">
                    <label htmlFor="telegram">رقم ال تيلغرام:</label>
                    <input
                      type="tel"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      name="telegram"
                      // id="telegramId"
                      className={classnames('form-control', {
                        'is-invalid': errors.telegram,
                      })}
                      style={{ direction: 'ltr' }}
                      placeholder=""
                      value={telegram}
                      onChange={this.onMobileChange}
                    />

                    {errors.telegram && (
                      <div className="invalid-feedback">{errors.telegram}</div>
                    )}
                  </div>
                  <div className="form-group text-right mb-4">
                    <label htmlFor="other_phones">ارقام اخرى:</label>
                    <input
                      type="tel"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      name="other_phones"
                      // id="other_phonesId"
                      className={classnames('form-control', {
                        'is-invalid': errors.other_phones,
                      })}
                      style={{ direction: 'ltr' }}
                      placeholder=""
                      value={other_phones}
                      onChange={this.onChange}
                    />
                    {/* <InputMask
                      mask="9999 999999"
                      maskChar=" "
                      style={{ direction: 'ltr' }}
                      // type="number"
                      className={classnames('form-control', {
                        'is-invalid': errors.other_phones
                      })}
                      name="other_phones"
                      value={other_phones}
                      onChange={this.onChange}
                      id="other_phonesId"
                    /> */}
                    {errors.other_phones && (
                      <div className="invalid-feedback">
                        {errors.other_phones}
                      </div>
                    )}
                  </div>

                  <div className="form-group text-right mb-4">
                    <label htmlFor="twitter">عنوان صفحة ال تويتر:</label>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors.twitter,
                      })}
                      name="twitter"
                      value={twitter}
                      onChange={this.onChange}
                    />
                    {errors.twitter && (
                      <div className="invalid-feedback">{errors.twitter}</div>
                    )}
                  </div>
                  <div className="form-group text-right mb-4">
                    <label htmlFor="facebook">عنوان صفحةال فيسبوك:</label>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors.facebook,
                      })}
                      name="facebook"
                      value={facebook}
                      onChange={this.onChange}
                    />
                    {errors.facebook && (
                      <div className="invalid-feedback">{errors.facebook}</div>
                    )}
                  </div>

                  <div className="form-group text-right mb-4">
                    <label htmlFor="instagram">عنوان صفحة ال انستغرام:</label>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors.instagram,
                      })}
                      name="instagram"
                      value={instagram}
                      onChange={this.onChange}
                    />
                    {errors.instagram && (
                      <div className="invalid-feedback">{errors.instagram}</div>
                    )}
                  </div>

                  <div className="form-group text-right mb-4">
                    <label htmlFor="business_email">ايميل العمل:</label>
                    <input
                      type="email"
                      className={classnames('form-control', {
                        'is-invalid': errors.business_email,
                      })}
                      name="business_email"
                      value={business_email}
                      onChange={this.onChange}
                    />
                    {errors.business_email && (
                      <div className="invalid-feedback">
                        {errors.business_email}
                      </div>
                    )}
                  </div>
                  <div className="form-group text-right">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="is_blocked"
                        className="form-check-input"
                        checked={is_blocked}
                        onChange={this.onCheckBoxChange}
                      />
                      <label className="form-check-label mx-3" htmlFor="duplex">
                        المكتب محجوب
                      </label>
                    </div>
                  </div>
                  {/* <div className="form-group text-right">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="is_charged"
                        className="form-check-input"
                        checked={is_charged}
                        onChange={this.onCheckBoxChange}
                      />
                      <label className="form-check-label mx-3" htmlFor="duplex">
                        المكتب متعامل بالدفعات
                      </label>
                    </div>
                  </div> */}
                  <div className="form-group text-right  mb-3">
                    <label htmlFor="propertyImages">تحميل شعار المكتب:</label>
                    <div className="fileupload-images">
                      <div className="row">
                        <div className="col-md-10  mx-auto">
                          <label
                            htmlFor="upload_images"
                            className="btn btn-primary"
                            style={{ width: '175px' }}
                          >
                            <input
                              type="file"
                              id="upload_images"
                              name="upload_images[]"
                              accept=".jpg, .jpeg"
                              //multiple
                              className="d-none"
                              onChange={this.onUploadImage}
                            />
                            تحميل شعار المكتب{' '}
                            <FontAwesomeIcon
                              icon={['fas', 'plus']}
                              color="white"
                            />
                          </label>
                        </div>

                        {/* <div className="col-md-5 mx-1">
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={this.onDeleteImage}
                            style={{ width: '175px' }}
                          >
                            حذف شعار المكتب{' '}
                            <FontAwesomeIcon
                              icon={['fas', 'times']}
                              color="white"
                            />
                          </button>
                        </div> */}
                        <div className="col-md-2"></div>
                        {errors.fileSize && (
                          <div className="bg-danger">{errors.fileSize}</div>
                        )}
                      </div>
                    </div>

                    {(fullSelectedFilesArray || []).map(
                      (fullSelectedFilesArray, index) => (
                        <div
                          key={index}
                          className="fileupload_image_inside_container"
                        >
                          <div className="text-center fileupload_div_delete_image">
                            <button
                              type="button"
                              className="btn btn-primary fileupload_btn_deleted"
                              onClick={this.onDeleteImage.bind(
                                this,
                                fullSelectedFilesArray[0]
                              )}
                            >
                              <FontAwesomeIcon
                                icon={['fas', 'times']}
                                color="white"
                              />
                            </button>
                          </div>
                          <div className="mt-1 mb-1 text-center">
                            <img
                              className="fileupload_img"
                              src={fullSelectedFilesArray[2].blob}
                              alt={fullSelectedFilesArray[2].name}
                              onClick={() =>
                                this.setState({
                                  photoIndex: index,
                                  isOpen: true,
                                })
                              }
                            />
                          </div>
                          <div
                            className="mx-auto my-0 px-2 text-center text-warning small"
                            style={{ wordWrap: 'break-word' }}
                          >
                            {/* {fullSelectedFilesArray[0]} */}
                            {/* <label htmlFor="photo_name">تغيير اسم الشعار:</label> */}
                            <div className="text-center">
                              <input
                                type="text"
                                className={classnames({
                                  'is-invalid': errors.photo_name,
                                })}
                                name="photo_name"
                                value={photo_name}
                                onChange={this.onChange}
                              />
                              {errors.photo_name && (
                                <div className="invalid-feedback">
                                  {errors.photo_name}
                                </div>
                              )}
                            </div>
                            {/* <p className="text-dark">{` ${
                              fullSelectedFilesArray[1]
                            }KB`}</p> */}
                          </div>
                          {/* <div className="col-12 px-1 my-1 text-center h6"></div> */}
                        </div>
                      )
                    )}

                    <div className="row">
                      {isOpen && (
                        <Lightbox
                          mainSrc={`${fullSelectedFilesArray[photoIndex][2].blob}`}
                          nextSrc={`${
                            fullSelectedFilesArray[
                              (photoIndex + 1) % fullSelectedFilesArray.length
                            ][2].blob
                          }`}
                          prevSrc={`${
                            fullSelectedFilesArray[
                              (photoIndex + fullSelectedFilesArray.length - 1) %
                                fullSelectedFilesArray.length
                            ][2].blob
                          }`}
                          onCloseRequest={() =>
                            this.setState({ isOpen: false })
                          }
                          onMovePrevRequest={() =>
                            this.setState({
                              photoIndex:
                                (photoIndex +
                                  fullSelectedFilesArray.length -
                                  1) %
                                fullSelectedFilesArray.length,
                            })
                          }
                          onMoveNextRequest={() =>
                            this.setState({
                              photoIndex:
                                (photoIndex + 1) %
                                fullSelectedFilesArray.length,
                            })
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-8 mx-auto">
                  <div className="row">
                    <div className="col-md-6">
                      <button
                        className="btn btn-block submit-button m-1"
                        onClick={this.onSubmit}
                      >
                        اضافة مكتب
                      </button>
                    </div>
                    <div className="col-md-6">
                      <button
                        className="btn btn-block submit-button  m-1"
                        onClick={this.handleClear}
                      >
                        مسح الحقول
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            {Object.keys(errors).length > 0 ? (
              <div className="alert alert-warning mt-2 text-right">
                الرجاء تعبئة الحقول المطلوبة
              </div>
            ) : null}
            {Object.keys(resMessages).length > 0 ? (
              <div className="alert alert-danger mt-2 text-right">
                {resMessages.message}
              </div>
            ) : null}
            {/* {!this.props.requestFailed &&
            !this.state.noMessages &&
            this.props.requestFailedMessage.error === 'no' ? (
              <div className="alert alert-success mt-2 text-right">
                تم اضافة مكتب بنجاح
              </div>
            ) : null} */}
          </div>
        </div>
      </div>
    );
  }
}

agencyRegistration.propTypes = {
  addAgency: PropTypes.func.isRequired,
  agency: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  requestFailed: PropTypes.bool.isRequired,
  requestFailedMessage: PropTypes.object.isRequired,
  requestSuccessMessage: PropTypes.string.isRequired,
};
const mapStateToProps = (state) => ({
  agency: state.agency.agency,
  users: state.search.users,
  requestFailed: state.agency.requestFailed,
  requestFailedMessage: state.agency.requestFailedMessage,
  requestSuccessMessage: state.agency.requestSuccessMessage,
  resType: state.agency.resType,
});

export default connect(mapStateToProps, { addAgency })(agencyRegistration);
