import React, { Component } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { doSearch } from "./../../actions/searchActions";
import { Link } from "react-router-dom";
import classnames from "classnames";
import shortid from "shortid";
import { compose } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MultiSelect } from "primereact/multiselect";
import { withRouter } from "react-router-dom";
import { recordsPerPage } from "./../../mainConf/index";
import { isNumeric } from "./../../mainConf/functions";
import FacebookButton from "./../../facebookButton/FacebookButton";
import { getCategories, getCategories2 } from "../../actions/categoryActions";
import {
  formatMoney,
  buildSearchTitle,
  buildSearchDescription,
} from "./../../mainConf/functions";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import {
  syriaAreas,
  generalBusinessOffer,
  generalTypes,
  generalSellPrices,
  generalRentPrices,
  generalAreas,
  generalProvinces,
  generalTypesItems,
  generalViewDirections,
} from "./../../mainConf";
import queryString from "query-string";
import { Checkbox } from "primereact/checkbox";
import { removeParam } from "./../../mainConf/functions.jsx";
//import MultiSelect from "react-multi-select-component";
// import Province from './../provinces/Province';

class SearchBar extends Component {
  _isMounted = false;
  state = {
    hideContact: false,
    pageTitle: "",
    pageDescription: "",
    // searchURLParams: {},
    forRent: false,
    forSell: true,
    agency_id: "",
    agency_by_name: "",
    searchUrl: "",
    searchParams: {},
    properties: [],
    largeScreen: false,
    searchBarFullShow: false,
    searchTypeMainProperties: false,
    searchTypeAllProperties: false,
    searchTypeAgencyProperties: false,
    searchTypeUserProperties: false,
    searchTypeForRentProperties: false,
    searchTypeForSaleProperties: false,
    searchTypeForWithContacts: false,
    searchTypeForWithAgencyContacts: false,
    searchId: "main",
    destination: "",
    requestSuccessMessage: {},
    areas: [],
    propertyPosition: null,
    showLakta: false,
    //meeeeeee
    show_deleted_properties: false,
    /////meeee
    title: "",
    order_by: "",
    random: "",
    agency_name: "",
    type: "نوع العقار", //flat
    rank: 1,
    description: "",
    reference_id: "",
    city: "",
    province: "المحافظة",
    area: "المنطقة",
    street: "",
    gps_location: "", //"105 , 104"
    bedrooms: "الغرف",
    selectedFilters: [],
    bathrooms: "غرف الاستحمام",
    toilets: 0,
    garage: false,
    parking: false,
    balcony: 0,
    view_direction: "الاتجاه",
    floor: "الطابق",
    duplex: false,
    total_area: 0, //150
    living_area: 0, //125
    ground_area: 0, //25
    lift: false,
    furnitured: false,
    type_of_ownership: "غير محدد", //"private"
    price: 0,
    business_status: "جديد", //new
    rent_plan_id: 1,
    sell_plan_id: 1,
    visible: true,
    built_at: null, //dateTime "thu jul 07 12:20:15 +0300 2017"
    reconstructed_at: null, //dateTime "thu jul 07 12:20:15 +0300 2018"
    status: "غير محدد", //"ready"
    price_per_day: 0,
    price_per_mm: 0,
    business_offer: "نوع العرض", //"rent"
    main_type_param: "",
    agency_name_param: "",
    views: 0,
    created_at: null, //dateTime "thu jul 07 12:20:15 +0300 2018"
    photo_data: [],
    min_price: "أدنى سعر",
    max_price: "أعلى سعر",
    min_area: "أدنى مساحة",
    max_area: "أعلى مساحة",
    errors: {},
    pageNumber: 0,
    locationSearch: "",
    normalUrl: "",
    // short_id6: ''
  };

  async componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.location.search !== this.state.locationSearch) {
      var normalUrl = await removeParam("main_type", nextProps.location.search);
      // var locationSearchParam = this.state.locationSearch;
      if (this._isMounted) {
        await this.setState({
          normalUrl,
          locationSearch: nextProps.location.search,
        });

        await this.getSearch();
      }
    }
  }
  isNumeric = (num) => {
    return /^\d+$/.test(num);
  };
  isInt = (num) => {
    return /^-{0,1}\d+$/.test(num);
  };
  getSearch = async () => {
    await this.setState({ locationSearch: this.props.location.search });
    let searchURLParams = await queryString.parse(this.props.location.search);
    let lowerSearchURLParams = {};
    await Object.keys(searchURLParams).forEach(function (key, value) {
      lowerSearchURLParams[key.toLowerCase()] = searchURLParams[key];
    });
    searchURLParams = lowerSearchURLParams;
    var agency_id =
      searchURLParams.agency_id !== undefined &&
      searchURLParams.agency_id !== null
        ? searchURLParams.agency_id
        : "";
    var agency_by_name =
      searchURLParams.name !== undefined && searchURLParams.name !== null
        ? searchURLParams.name
        : "";

    var main_type_param =
      searchURLParams.main_type !== undefined &&
      searchURLParams.main_type !== null
        ? searchURLParams.main_type
        : "";
    var min_price =
      searchURLParams.min_price !== undefined &&
      searchURLParams.min_price !== null &&
      isNumeric(searchURLParams.min_price)
        ? searchURLParams.min_price
        : "";
    var max_price =
      searchURLParams.max_price !== undefined &&
      searchURLParams.max_price !== null &&
      isNumeric(searchURLParams.max_price)
        ? searchURLParams.max_price
        : "";
    var min_area =
      searchURLParams.min_area !== undefined &&
      searchURLParams.min_area !== null &&
      isNumeric(searchURLParams.min_area)
        ? searchURLParams.min_area
        : "";
    var max_area =
      searchURLParams.max_area !== undefined &&
      searchURLParams.max_area !== null &&
      isNumeric(searchURLParams.max_area)
        ? searchURLParams.max_area
        : "";
    var area = "المنطقة";
    var city =
      searchURLParams.city !== undefined && searchURLParams.city !== null
        ? searchURLParams.city
        : "";
    var province =
      searchURLParams.province_id !== undefined &&
      searchURLParams.province_id !== null &&
      generalProvinces[searchURLParams.province_id] !== undefined
        ? generalProvinces[searchURLParams.province_id]
        : "المحافظة";
    if (province !== "المحافظة") {
      this.onProvinceChange2();
    }
    if (
      searchURLParams.district_id !== undefined &&
      searchURLParams.province_id !== undefined &&
      searchURLParams.district_id !== null &&
      searchURLParams.province_id !== null
    ) {
      if (generalProvinces[searchURLParams.province_id] === "دمشق") {
        if (
          syriaAreas.damascusAreas[searchURLParams.district_id] !== undefined
        ) {
          area = syriaAreas.damascusAreas[searchURLParams.district_id];
        }
      } else if (generalProvinces[searchURLParams.province_id] === "ريف دمشق") {
        if (
          syriaAreas.rifDamascusAreas[searchURLParams.district_id] !== undefined
        ) {
          area = syriaAreas.rifDamascusAreas[searchURLParams.district_id];
        }
      } else if (generalProvinces[searchURLParams.province_id] === "حلب") {
        if (syriaAreas.aleppoAreas[searchURLParams.district_id] !== undefined) {
          area = syriaAreas.aleppoAreas[searchURLParams.district_id];
        }
      } else if (generalProvinces[searchURLParams.province_id] === "طرطوس") {
        if (
          syriaAreas.tartousAreas[searchURLParams.district_id] !== undefined
        ) {
          area = syriaAreas.tartousAreas[searchURLParams.district_id];
        }
      } else if (generalProvinces[searchURLParams.province_id] === "اللاذقية") {
        if (
          syriaAreas.latakiaAreas[searchURLParams.district_id] !== undefined
        ) {
          area = syriaAreas.latakiaAreas[searchURLParams.district_id];
        }
      } else if (generalProvinces[searchURLParams.province_id] === "حمص") {
        if (syriaAreas.homsAreas[searchURLParams.district_id] !== undefined) {
          area = syriaAreas.homsAreas[searchURLParams.district_id];
        }
      } else if (generalProvinces[searchURLParams.province_id] === "حماة") {
        if (syriaAreas.hamaAreas[searchURLParams.district_id] !== undefined) {
          area = syriaAreas.hamaAreas[searchURLParams.district_id];
        }
      } else if (generalProvinces[searchURLParams.province_id] === "السويداء") {
        if (
          syriaAreas.asuwaydaAreas[searchURLParams.district_id] !== undefined
        ) {
          area = syriaAreas.asuwaydaAreas[searchURLParams.district_id];
        }
      } else if (generalProvinces[searchURLParams.province_id] === "الحسكة") {
        if (syriaAreas.hasakaAreas[searchURLParams.district_id] !== undefined) {
          area = syriaAreas.hasakaAreas[searchURLParams.district_id];
        }
      } else if (generalProvinces[searchURLParams.province_id] === "درعا") {
        if (syriaAreas.daraaAreas[searchURLParams.district_id] !== undefined) {
          area = syriaAreas.daraaAreas[searchURLParams.district_id];
        }
      } else if (generalProvinces[searchURLParams.province_id] === "ادلب") {
        if (syriaAreas.idlibAreas[searchURLParams.district_id] !== undefined) {
          area = syriaAreas.idlibAreas[searchURLParams.district_id];
        }
      } else if (
        generalProvinces[searchURLParams.province_id] === "دير الزور"
      ) {
        if (
          syriaAreas.deirezorAreas[searchURLParams.district_id] !== undefined
        ) {
          area = syriaAreas.deirezorAreas[searchURLParams.district_id];
        }
      } else if (generalProvinces[searchURLParams.province_id] === "القنيطرة") {
        if (
          syriaAreas.quneitraAreas[searchURLParams.district_id] !== undefined
        ) {
          area = syriaAreas.quneitraAreas[searchURLParams.district_id];
        }
      } else if (generalProvinces[searchURLParams.province_id] === "الرقة") {
        if (syriaAreas.raqqaAreas[searchURLParams.district_id] !== undefined) {
          area = syriaAreas.raqqaAreas[searchURLParams.district_id];
        }
      }
    }
    var bedrooms =
      searchURLParams.bedrooms !== undefined &&
      searchURLParams.bedrooms !== null &&
      isNumeric(searchURLParams.bedrooms)
        ? searchURLParams.bedrooms
        : "الغرف";
    var bathrooms =
      searchURLParams.bathrooms !== undefined &&
      searchURLParams.bathrooms !== null &&
      isNumeric(searchURLParams.bathrooms)
        ? searchURLParams.bathrooms
        : "غرف الاستحمام";
    var business_offer =
      searchURLParams.business_offer_id !== undefined &&
      searchURLParams.business_offer_id !== null &&
      generalBusinessOffer[searchURLParams.business_offer_id] !== undefined
        ? generalBusinessOffer[searchURLParams.business_offer_id]
        : "نوع العرض";

    if (business_offer === "أبحث للإيجار") {
      await this.setState({
        forRent: true,
        forSell: false,
      });
    } else if (business_offer === "تأجير") {
      await this.setState({
        forRent: true,
        forSell: false,
      });
    } else if (business_offer === "أبحث للشراء") {
      await this.setState({
        forRent: false,
        forSell: true,
      });
    } else if (business_offer === "رهن") {
      await this.setState({
        forRent: false,
        forSell: false,
      });
    } else {
      await this.setState({
        forRent: false,
        forSell: true,
      });
    }

    var random =
      searchURLParams.random !== undefined && searchURLParams.random !== null
        ? searchURLParams.random
        : "";
    var type =
      searchURLParams.type_id !== undefined &&
      searchURLParams.type_id !== null &&
      generalTypesItems[parseInt(searchURLParams.type_id)] !== undefined
        ? generalTypesItems[parseInt(searchURLParams.type_id)]
        : "نوع العقار";
    var view_direction =
      searchURLParams.view_direction_id &&
      searchURLParams.view_direction_id !== null &&
      generalViewDirections[searchURLParams.view_direction_id] !== undefined
        ? generalViewDirections[searchURLParams.view_direction_id]
        : "الاتجاه";
    var floor =
      searchURLParams.floor !== undefined &&
      searchURLParams.floor !== null &&
      this.isInt(searchURLParams.floor)
        ? searchURLParams.floor
        : "الطابق";
    var selectedFilters = [];
    var basement =
      searchURLParams.basement !== undefined &&
      searchURLParams.basement !== null &&
      searchURLParams.basement === "true"
        ? true
        : searchURLParams.basement === "false"
        ? false
        : false;
    if (basement === true) {
      selectedFilters.push("ملجأ");
    }
    var duplex =
      searchURLParams.duplex !== undefined &&
      searchURLParams.duplex !== null &&
      searchURLParams.duplex === "true"
        ? true
        : searchURLParams.duplex === "false"
        ? false
        : false;
    if (duplex === true) {
      selectedFilters.push("دوبلكس");
    }
    var furnitured =
      searchURLParams.furnitured !== undefined &&
      searchURLParams.furnitured !== null &&
      searchURLParams.furnitured === "true"
        ? true
        : searchURLParams.furnitured === "false"
        ? false
        : false;
    if (furnitured === true) {
      selectedFilters.push("مفروش");
    }
    var garage =
      searchURLParams.garage !== undefined &&
      searchURLParams.garage !== null &&
      searchURLParams.garage === "true"
        ? true
        : searchURLParams.garage === "false"
        ? false
        : false;
    if (garage === true) {
      selectedFilters.push("كراج");
    }
    var lift =
      searchURLParams.lift !== undefined &&
      searchURLParams.lift !== null &&
      searchURLParams.lift === "true"
        ? true
        : searchURLParams.lift === "false"
        ? false
        : false;
    if (lift === true) {
      selectedFilters.push("مصعد");
    }
    var parking =
      searchURLParams.parking !== undefined &&
      searchURLParams.parking !== null &&
      searchURLParams.parking === "true"
        ? true
        : searchURLParams.parking === "false"
        ? false
        : false;
    if (parking === true) {
      selectedFilters.push("موقف");
    }
    var showLakta =
      searchURLParams.show_lakta !== undefined &&
      searchURLParams.show_lakta !== null &&
      searchURLParams.show_lakta === "true"
        ? true
        : searchURLParams.show_lakta === "false"
        ? false
        : false;
    /////////meeeeeee

    var show_deleted_properties =
      searchURLParams.show_deleted_properties !== undefined &&
      searchURLParams.show_deleted_properties !== null &&
      searchURLParams.show_deleted_properties === "true"
        ? true
        : searchURLParams.show_deleted_properties === "false"
        ? false
        : false;

    // من الأكثر اتصالات
    var order_by =
      searchURLParams.order_created_at !== undefined &&
      searchURLParams.order_created_at !== null
        ? searchURLParams.order_created_at === "asc"
          ? "من الأقدم إلى الأحدث"
          : searchURLParams.order_created_at === "desc"
          ? "من الأحدث إلى الأقدم"
          : "ترتيب تلقائي"
        : searchURLParams.order_calls !== undefined &&
          searchURLParams.order_calls !== null
        ? searchURLParams.order_calls === "asc"
          ? "من الأقل اتصالات"
          : searchURLParams.order_calls === "desc"
          ? "من الأكثر اتصالات"
          : "ترتيب تلقائي"
        : searchURLParams.order_views !== undefined &&
          searchURLParams.order_views !== null
        ? searchURLParams.order_views === "asc"
          ? "من الأقل مشاهدات"
          : searchURLParams.order_views === "desc"
          ? "من الأكثر مشاهدات"
          : "ترتيب تلقائي"
        : searchURLParams.order_price !== undefined &&
          searchURLParams.order_price !== null
        ? searchURLParams.order_price === "asc"
          ? "من الأرخص إلى الأغلى"
          : searchURLParams.order_price === "desc"
          ? "من الأغلى إلى الأرخص"
          : "ترتيب تلقائي"
        : "ترتيب تلقائي";

    var pageNumber =
      searchURLParams.page !== undefined &&
      searchURLParams.page !== null
        ? searchURLParams.page
        : "1";
    var agency_name =
      searchURLParams.agency_name !== undefined &&
      searchURLParams.agency_name !== null
        ? searchURLParams.agency_name
        : "";
    var agency_name_param =
      searchURLParams.agency_name !== undefined &&
      searchURLParams.agency_name !== null
        ? searchURLParams.agency_name
        : "";
    let pageTitle = buildSearchTitle(
      main_type_param,
      type,
      business_offer,
      furnitured,
      province,
      area
    );
    let pageDescription = buildSearchDescription(
      main_type_param,
      type,
      business_offer,
      furnitured,
      province,
      area,
      pageNumber,
      bedrooms,
      bathrooms,
      duplex,
      basement,
      lift,
   
      parking,
      garage,
      view_direction,
      min_area,
      max_area,
      min_price,
      max_price,
      floor
    );
    await this.setState({
      province,
      pageTitle: pageTitle.replace(/\s\s+/g, " "),
      pageDescription: pageDescription.replace(/\s\s+/g, " "),
    });
    await this.onProvinceChange2(province);
    var searchBarFullShow = false;
    if (
      selectedFilters.length > 0 ||
      random !== "" ||
      view_direction !== "الاتجاه" ||
      floor !== "الطابق" ||
      bathrooms !== "غرف الاستحمام" ||
      bedrooms !== "الغرف" ||
      showLakta ||
      ///me
      show_deleted_properties
    ) {
      searchBarFullShow = true;
    }
    await this.setState({
      searchBarFullShow,
      selectedFilters,
      agency_id,
      agency_by_name,
      min_price,
      max_price,
      min_area,
      max_area,
      area,
      bedrooms,
      bathrooms,
      business_offer:
        business_offer !== "غير محدد" ? business_offer : "نوع العرض",
      city,
      random,
      type: type !== "غير محدد" ? type : "نوع العقار",
      main_type_param,
      agency_name_param,
      view_direction,
      floor,
      basement,
      duplex,
      furnitured,
      garage,
      lift,
      parking,
      showLakta,
      ///meee
      show_deleted_properties,
      ///meeee
      order_by,
      agency_name,
    });

    if (this.props.searchMode !== "off") {
      await this.onSearchSubmit(recordsPerPage, pageNumber, null);
    }
    this.buildSearchUrl();
  };
  onProvinceChange2 = (province) => {
    if (province === "دمشق") {
      //دمشق
      this.setState({ areas: syriaAreas.damascusAreas });
    } else if (province === "ريف دمشق") {
      //ريف دمشق
      this.setState({
        areas: syriaAreas.rifDamascusAreas,
      });
    } else if (province === "حلب") {
      //حلب
      this.setState({
        areas: syriaAreas.aleppoAreas,
      });
    } else if (province === "طرطوس") {
      //طرطوس
      this.setState({
        areas: syriaAreas.tartousAreas,
      });
    } else if (province === "اللاذقية") {
      //اللاذقية
      this.setState({ areas: syriaAreas.latakiaAreas });
    } else if (province === "حمص") {
      //حمص
      this.setState({
        areas: syriaAreas.homsAreas,
      });
    } else if (province === "حماة") {
      //حماة
      this.setState({ areas: syriaAreas.hamaAreas });
    } else if (province === "السويداء") {
      //السويداء
      this.setState({ areas: syriaAreas.asuwaydaAreas });
    } else if (province === "الحسكة") {
      //الحسكة
      this.setState({
        areas: syriaAreas.hasakaAreas,
      });
    } else if (province === "درعا") {
      //درعا
      this.setState({ areas: syriaAreas.daraaAreas });
    } else if (province === "ادلب") {
      //ادلب
      this.setState({
        areas: syriaAreas.idlibAreas,
      });
    } else if (province === "دير الزور") {
      //دير الزور
      this.setState({ areas: syriaAreas.deirezorAreas });
    } else if (province === "القنيطرة") {
      //القنيطرة
      this.setState({ areas: syriaAreas.quneitraAreas });
    } else if (province === "الرقة") {
      //الرقة
      this.setState({ areas: syriaAreas.raqqaAreas });
    } else {
      this.setState({ areas: [] });
    }
  };
  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("resize", this.resize.bind(this));
  }
  async componentDidMount() {
    this._isMounted = true;
    if (this._isMounted) {
      this.resize();
      window.addEventListener("resize", this.resize.bind(this));
      const { searchBarFullShow, searchType, destination } = this.props;

      this.setState({ searchBarFullShow });

      var searchIdParam = "";

      switch (searchType) {
        case "all_search":
          this.setState({
            searchTypeMainProperties: false,
            searchTypeAllProperties: true,
            searchTypeAgencyProperties: false,
            searchTypeUserProperties: false,
            searchTypeForRentProperties: false,
            searchTypeForSaleProperties: false,
            searchTypeForWithContacts: false,
            searchTypeForWithAgencyContacts: false,
            destination,
          });
          break;
        case "main_search":
          this.setState({
            searchTypeMainProperties: true,
            searchTypeAllProperties: false,
            searchTypeAgencyProperties: false,
            searchTypeUserProperties: false,
            searchTypeForRentProperties: false,
            searchTypeForSaleProperties: false,
            searchTypeForWithContacts: false,
            searchTypeForWithAgencyContacts: false,
            destination,
          });
          break;
        case "agency_properties_search":
          const searchUrlParams = await queryString.parse(
            this.props.location.search
          );
          // var searchType = 'agency_properties_search';
          var destinationParam = "/dashBoard/properties_by_agency";
          if (searchUrlParams.agency_id !== undefined) {
            searchIdParam = searchUrlParams.agency_id;
            // searchType = `agency_properties_search/${agency_ref_id}`;
            // destinationParam = `/adminDashBoard/properties_by_agency?agency_id=${searchIdParam}`;
            destinationParam = `/adminDashBoard/properties_by_agency`;
          } else {
            const isTakingAgencyRole = this.props.isTakingAgencyRole;
            const takenAgencyRoleAgencyRefId =
              this.props.takenAgencyRoleAgencyRefId;

            if (isTakingAgencyRole) {
              searchIdParam = takenAgencyRoleAgencyRefId;
            } else {
              searchIdParam = localStorage.getItem("ikar_agency_ref_id");
            }
          }

          this.setState({
            searchTypeMainProperties: false,
            searchTypeAllProperties: false,
            searchTypeAgencyProperties: true,
            searchTypeUserProperties: false,
            searchTypeForRentProperties: false,
            searchTypeForSaleProperties: false,
            searchTypeForWithContacts: false,
            searchTypeForWithAgencyContacts: false,
            searchId: searchIdParam,
            destination: destinationParam,
          });
          break;
        case "user_properties_search":
          this.setState({
            searchTypeMainProperties: false,
            searchTypeAllProperties: false,
            searchTypeAgencyProperties: false,
            searchTypeUserProperties: true,
            searchTypeForRentProperties: false,
            searchTypeForSaleProperties: false,
            searchTypeForWithContacts: false,
            searchTypeForWithAgencyContacts: false,
            destination,
          });
          break;
        case "for_rent_properties_search":
          this.setState({
            searchTypeMainProperties: false,
            searchTypeAllProperties: false,
            searchTypeAgencyProperties: false,
            searchTypeUserProperties: false,
            searchTypeForRentProperties: true,
            searchTypeForSaleProperties: false,
            searchTypeForWithContacts: false,
            searchTypeForWithAgencyContacts: false,
            destination,
          });
          break;

        case "for_sale_properties_search":
          this.setState({
            searchTypeMainProperties: false,
            searchTypeAllProperties: false,
            searchTypeAgencyProperties: false,
            searchTypeUserProperties: false,
            searchTypeForRentProperties: false,
            searchTypeForSaleProperties: true,
            searchTypeForWithContacts: false,
            searchTypeForWithAgencyContacts: false,
            destination,
          });
          break;
        case "contact_messages":
          this.setState({
            searchTypeForWithContacts: true,
            searchTypeMainProperties: false,
            searchTypeAllProperties: false,
            searchTypeAgencyProperties: false,
            searchTypeUserProperties: false,
            searchTypeForRentProperties: false,
            searchTypeForSaleProperties: false,
            searchTypeForWithAgencyContacts: false,
            destination,
          });
          break;
        case "agency_contact_messages":
          this.setState({
            searchTypeForWithAgencyContacts: true,
            searchTypeMainProperties: false,
            searchTypeAllProperties: false,
            searchTypeAgencyProperties: false,
            searchTypeUserProperties: false,
            searchTypeForRentProperties: false,
            searchTypeForSaleProperties: false,
            searchTypeForWithContacts: false,
            destination,
          });
          break;
        default:
          break;
      }

      this.getSearch();
    }
  }
  resize() {
    let currentWidth = window.innerWidth;
    if (currentWidth >= 768) {
      this.setState({ largeScreen: true });
    } else {
      this.setState({ largeScreen: false });
    }
  }

  buildSearchUrl = async () => {
    const {
      // searchTypeAllProperties,
      // searchTypeAgencyProperties,
      agency_id,
      agency_by_name,
      showLakta,
      ////me
      show_deleted_properties,
      /////meeee
      order_by,
      type,
      main_type_param,
      agency_name_param,
      agency_name,
      province,
      area,
      bedrooms,
      selectedFilters,
      random,
      bathrooms,
      view_direction,
      floor,
      business_offer,
      min_price,
      max_price,
      min_area,
      max_area,
    } = this.state;

    var Province = "";
    var Area = "";
    var Type = "";
    var BusinessOffer = "";
    var Bedrooms = "";
    var Bathrooms = "";
    var Floor = "";
    var ViewDirection = "";
    if (province !== "المحافظة") {
      Province = province;
    }
    if (area !== "المنطقة") {
      Area = area;
    }
    if (type !== "نوع العقار") {
      Type = type;
    }
    if (business_offer !== "نوع العرض") {
      BusinessOffer = business_offer;
    }

    if (bedrooms !== "الغرف") {
      Bedrooms = bedrooms;
    }

    if (floor !== "الطابق") {
      Floor = floor;
    }
    if (bathrooms !== "غرف الاستحمام") {
      Bathrooms = bathrooms;
    }
    if (view_direction !== "الاتجاه") {
      ViewDirection = view_direction;
    }
    if (
      min_price !== "" &&
      min_price !== "أدنى سعر" &&
      parseInt(min_price) !== 0 &&
      max_price !== "" &&
      max_price !== "أعلى سعر" &&
      //parseInt(max_price) !== 0 &&
      parseInt(min_price) > parseInt(max_price)
    ) {
      this.setState({ errors: { max_price: "اعلى سعر!" } });
      return;
    }
    if (
      min_area !== "" &&
      min_area !== "أدنى مساحة" &&
      parseInt(min_area) !== 0 &&
      max_area !== "" &&
      max_area !== "أعلى مساحة" &&
      //parseInt(max_area) !== 0 &&
      parseInt(min_area) > parseInt(max_area)
    ) {
      this.setState({ errors: { max_area: "اعلى مساحة!" } });
      return;
    }
    const property = {};
    if (Type !== "") {
      property.type = Type;
    }
    if (main_type_param !== "" && this.props.urlFilters !== "false") {
      property.main_type = main_type_param;
    }
    if (BusinessOffer !== "") {
      property.business_offer = BusinessOffer;
    }

    if (Province !== "") {
      property.province = Province;
    }
    if (Area !== "") {
      property.city = Area;
    }
    if (Area !== "") {
      property.area = Area;
    }
    if (Bedrooms !== "") {
      property.bedrooms = Bedrooms;
    }
    if (Bathrooms !== "") {
      property.bathrooms = Bathrooms;
    }
    if (ViewDirection !== "") {
      property.view_direction = ViewDirection;
    }
    if (Floor !== "") {
      property.floor = Floor;
    }
    property.ranges = {};
    if (
      parseInt(min_price) !== 0 &&
      min_price !== "" &&
      min_price !== "أدنى سعر" &&
      parseInt(max_price) !== 0 &&
      max_price !== "" &&
      max_price !== "أعلى سعر" &&
      parseInt(min_price) <= parseInt(max_price)
    ) {
      property.ranges.price = {};
      property.ranges.price.min = min_price === "" ? 0 : min_price;
      property.ranges.price.max = max_price === "" ? 0 : max_price;
    }

    //////add
    else if (
      parseInt(min_price) !== 0 &&
      min_price !== "" &&
      min_price !== "أدنى سعر" &&
      (parseInt(max_price) === 0 || max_price === "") &&
      parseInt(min_price) > 0
    ) {
      property.ranges.price = {};
      property.ranges.price.min = min_price;
      property.ranges.price.max = null;
    } else if (
      parseInt(max_price) !== 0 &&
      max_price !== "" &&
      max_price !== "أعلى سعر" &&
      (parseInt(min_price) === 0 || min_price === "") &&
      parseInt(max_price) > 0
    ) {
      property.ranges.price = {};
      property.ranges.price.min = null;
      property.ranges.price.max = max_price;
    }
    //////
    if (
      parseInt(min_area) !== 0 &&
      min_area !== "" &&
      min_area !== "أدنى مساحة" &&
      parseInt(max_area) !== 0 &&
      max_area !== "" &&
      max_area !== "أعلى مساحة" &&
      parseInt(min_area) <= parseInt(max_area)
    ) {
      property.ranges.total_area = {};
      property.ranges.total_area.min = min_area === "" ? 0 : min_area;
      property.ranges.total_area.max = max_area === "" ? 0 : max_area;
    }

    //////add
    else if (
      parseInt(min_area) !== 0 &&
      min_area !== "" &&
      min_area !== "أدنى مساحة" &&
      (parseInt(max_area) === 0 || max_area === "") &&
      parseInt(min_area) > 0
    ) {
      property.ranges.total_area = {};
      property.ranges.total_area.min = min_area;
      property.ranges.total_area.max = null;
    } else if (
      parseInt(max_area) !== 0 &&
      max_area !== "" &&
      max_area !== "أعلى مساحة" &&
      (parseInt(min_area) === 0 || min_area === "") &&
      parseInt(max_area) > 0
    ) {
      property.ranges.total_area = {};
      property.ranges.total_area.min = null;
      property.ranges.total_area.max = max_area;
    }
    //////
    if (random !== "") {
      property.random = random;
    }
    if (agency_name.trim() !== "") {
      property.agency_name = agency_name;
    }

    selectedFilters.forEach((item) => {
      switch (item) {
        case "كراج":
          property.garage = true;
          break;

        case "دوبلكس":
          property.duplex = true;
          break;

        case "موقف":
          property.parking = true;
          break;

        case "مصعد":
          property.lift = true;
          break;

        case "ملجأ":
          property.basement = true;
          break;

        case "مفروش":
          property.furnitured = true;
          break;

        default:
          break;
      }
    });

    if (showLakta) {
      // property.ranges.days = {};
      // property.ranges.days.min = 0;
      property.temp_status = "سعر لقطة";
    } else {
      // property.ranges.days = {};
      // property.ranges.days.min = -999999;
      // property.ranges.days.max = -1;
    }
    ///////////meee
    if (show_deleted_properties) {
      // property.ranges.days = {};
      // property.ranges.days.min = 0;
      property.business_status = "مؤرشف";
    } else {
      // property.ranges.days = {};
      // property.ranges.days.min = -999999;
      // property.ranges.days.max = -1;
    }
    ////////meeeeeeeee
    property.order = {};
    if (order_by !== "ترتيب تلقائي") {
      if (order_by === "من الأقدم إلى الأحدث") {
        property.order.created_at = "asc";
      } else if (order_by === "من الأحدث إلى الأقدم") {
        property.order.created_at = "desc";
      } else if (order_by === "من الأرخص إلى الأغلى") {
        property.order.price = "asc";
      } else if (order_by === "من الأغلى إلى الأرخص") {
        property.order.price = "desc";
      } else if (order_by === "من الأقل اتصالات") {
        property.order.calls = "asc";
      } else if (order_by === "من الأكثر اتصالات") {
        property.order.calls = "desc";
      } else if (order_by === "من الأقل مشاهدات") {
        property.order.views = "asc";
      } else if (order_by === "من الأكثر مشاهدات") {
        property.order.views = "desc";
      }
    }

    var searchUrl = [];
    if (agency_id !== undefined && agency_id !== "") {
      searchUrl.push(`agency_id=${agency_id}`);
    }
    if (agency_by_name !== undefined && agency_by_name !== "") {
      searchUrl.push(`name=${agency_by_name}`);
    }
    if (property.business_offer !== undefined) {
      searchUrl.push(
        `business_offer_id=${generalBusinessOffer.findIndex(
          (x) => x === property.business_offer
        )}`
      );
    }
    if (property.random !== undefined) {
      searchUrl.push(`random=${property.random}`);
    }
    if (property.province !== undefined) {
      searchUrl.push(
        `province_id=${generalProvinces.findIndex(
          (x) => x === property.province
        )}`
      );
    }

    if (property.agency_name !== undefined) {
      searchUrl.push(`agency_name=${property.agency_name}`);
    }

    if (property.area !== undefined) {
      if (property.province === "دمشق") {
        searchUrl.push(
          `district_id=${syriaAreas.damascusAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === "ريف دمشق") {
        searchUrl.push(
          `district_id=${syriaAreas.rifDamascusAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === "حلب") {
        searchUrl.push(
          `district_id=${syriaAreas.aleppoAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === "طرطوس") {
        searchUrl.push(
          `district_id=${syriaAreas.tartousAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === "اللاذقية") {
        searchUrl.push(
          `district_id=${syriaAreas.latakiaAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === "حمص") {
        searchUrl.push(
          `district_id=${syriaAreas.homsAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === "حماة") {
        searchUrl.push(
          `district_id=${syriaAreas.hamaAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === "السويداء") {
        searchUrl.push(
          `district_id=${syriaAreas.asuwaydaAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === "الحسكة") {
        searchUrl.push(
          `district_id=${syriaAreas.hasakaAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === "درعا") {
        searchUrl.push(
          `district_id=${syriaAreas.daraaAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === "ادلب") {
        searchUrl.push(
          `district_id=${syriaAreas.idlibAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === "دير الزور") {
        searchUrl.push(
          `district_id=${syriaAreas.deirezorAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === "القنيطرة") {
        searchUrl.push(
          `district_id=${syriaAreas.quneitraAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === "الرقة") {
        searchUrl.push(
          `district_id=${syriaAreas.raqqaAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      }
    }

    if (property.type !== undefined) {
      searchUrl.push(
        `type_id=${generalTypesItems.findIndex((x) => x === property.type)}`
      );
    }
    if (property.ranges !== undefined) {
      if (
        property.ranges.price !== undefined &&
        property.ranges.price.min !== undefined
      ) {
        searchUrl.push(`min_price=${property.ranges.price.min}`);
      }
      if (
        property.ranges.price !== undefined &&
        property.ranges.price.max !== undefined
      ) {
        searchUrl.push(`max_price=${property.ranges.price.max}`);
      }

      if (
        property.ranges.total_area !== undefined &&
        property.ranges.total_area.min !== undefined
      ) {
        searchUrl.push(`min_area=${property.ranges.total_area.min}`);
      }
      if (
        property.ranges.total_area !== undefined &&
        property.ranges.total_area.max !== undefined
      ) {
        searchUrl.push(`max_area=${property.ranges.total_area.max}`);
      }
    }

    // if (property.main_type !== undefined) {
    //   searchUrl.push(
    //     `main_type=${generalMainTypes.findIndex(x => x === property.main_type)}`
    //   );
    // }

    if (property.main_type !== undefined) {
      searchUrl.push(`main_type=${property.main_type}`);
    }

    if (property.bedrooms !== undefined) {
      searchUrl.push(`bedrooms=${property.bedrooms}`);
    }

    if (property.floor !== undefined) {
      searchUrl.push(`floor=${property.floor}`);
    }

    if (property.bathrooms !== undefined) {
      searchUrl.push(`bathrooms=${property.bathrooms}`);
    }

    if (property.garage !== undefined) {
      searchUrl.push(`garage=${property.garage}`);
    }
    if (property.duplex !== undefined) {
      searchUrl.push(`duplex=${property.duplex}`);
    }
    if (property.parking !== undefined) {
      searchUrl.push(`parking=${property.parking}`);
    }
    if (property.lift !== undefined) {
      searchUrl.push(`lift=${property.lift}`);
    }
    if (property.basement !== undefined) {
      searchUrl.push(`basement=${property.basement}`);
    }
    if (property.furnitured !== undefined) {
      searchUrl.push(`furnitured=${property.furnitured}`);
    }

    if (property.view_direction !== undefined) {
      searchUrl.push(
        `view_direction_id=${generalViewDirections.findIndex(
          (x) => x === property.view_direction
        )}`
      );
    }

    if (showLakta) {
      searchUrl.push(`show_lakta=true`);
    }
    /////meeeeeeee
    if (show_deleted_properties) {
      searchUrl.push(`show_deleted_properties=true`);
    }
    ///////meeee
    if (property.order !== undefined) {
      if (property.order.created_at !== undefined) {
        searchUrl.push(`order_created_at=${property.order.created_at}`);
      }

      if (property.order.price !== undefined) {
        searchUrl.push(`order_price=${property.order.price}`);
      }

      if (property.order.calls !== undefined) {
        searchUrl.push(`order_calls=${property.order.calls}`);
      }

      if (property.order.views !== undefined) {
        searchUrl.push(`order_views=${property.order.views}`);
      }
    }

    var normalUrl = await removeParam("main_type", `?${searchUrl.join("&")}`);
    await this.setState({
      normalUrl,
    });
    await this.setState({ searchUrl: searchUrl.join("&") });
  };
  onSearchButtonSubmit = async (e) => {
    e.preventDefault();
    await this.buildSearchUrl();

    var str = this.state.searchUrl !== "" ? "?" : "";
    const { min_price, max_price, min_area, max_area } = this.state;
    var errors = {};
    if (
      min_price !== "" &&
      min_price !== "أدنى سعر" &&
      parseInt(min_price) !== 0 &&
      max_price !== "" &&
      max_price !== "أعلى سعر" &&
      //parseInt(max_price) !== 0 &&
      parseInt(min_price) > parseInt(max_price)
    ) {
      errors.max_price = "أدنى سعر أكبر من أعلى سعر";
    }
    if (
      min_area !== "" &&
      min_area !== "أدنى مساحة" &&
      parseInt(min_area) !== 0 &&
      max_area !== "" &&
      max_area !== "أعلى مساحة" &&
      //parseInt(max_area) !== 0 &&
      parseInt(min_area) > parseInt(max_area)
    ) {
      errors.max_area = "المساحة الدنيا أكبر من المساحة العليا";
    }
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }
    if (this.props.destination === "/search") {
      var searchStr = "";
      if (this.state.type !== "نوع العقار") {
        // searchStr = searchStr + ` T=${this.state.type}, `;
        searchStr = searchStr + ` Type, `;
      }

      if (this.state.business_offer !== "نوع العرض") {
        // searchStr = searchStr + ` BO=${this.state.business_offer}, `;
        searchStr = searchStr + ` BusinessOffer, `;
      }
      if (this.state.province !== "المحافظة") {
        // searchStr = searchStr + ` P=${this.state.province}, `;
        searchStr = searchStr + ` Province, `;
      }
      if (this.state.area !== "المنطقة") {
        // searchStr = searchStr + ` A=${this.state.area}, `;
        searchStr = searchStr + ` Area, `;
      }
      if (this.state.min_price !== "") {
        // searchStr = searchStr + ` min_p=${this.state.min_price}, `;
        searchStr = searchStr + ` MinPrice, `;
      }
      if (this.state.max_price !== "") {
        // searchStr = searchStr + ` max_p=${this.state.max_price}, `;
        searchStr = searchStr + ` MaxPrice, `;
      }

      if (this.state.min_area !== "") {
        // searchStr = searchStr + ` min_a=${this.state.min_area}, `;
        searchStr = searchStr + ` MinArea, `;
      }
      if (this.state.max_area !== "") {
        // searchStr = searchStr + ` max_a=${this.state.max_area}, `;
        searchStr = searchStr + ` MaxArea, `;
      }

      if (this.state.bedrooms !== "الغرف") {
        // searchStr = searchStr + ` BR=${this.state.bedrooms}, `;
        searchStr = searchStr + ` BedRooms, `;
      }
      if (this.state.bathrooms !== "غرف الاستحمام") {
        // searchStr = searchStr + ` BathR=${this.state.bathrooms}, `;
        searchStr = searchStr + ` BathRooms, `;
      }
      if (this.state.floor !== "الطابق") {
        // searchStr = searchStr + ` F=${this.state.floor}, `;
        searchStr = searchStr + ` Floor, `;
      }
      if (this.state.view_direction !== "الاتجاه") {
        // searchStr = searchStr + ` VD=${this.state.view_direction}, `;
        searchStr = searchStr + ` ViewDirection, `;
      }

      if (this.state.random !== "") {
        // searchStr = searchStr + ` R=${this.state.random}, `;
        searchStr = searchStr + ` Random, `;
      }

      if (this.state.garage !== false) {
        // searchStr = searchStr + ` كراج `;
        searchStr = searchStr + ` Garage `;
      }

      if (this.state.furnitured !== false) {
        // searchStr = searchStr + ` مفروش `;
        searchStr = searchStr + ` Furnitured `;
      }

      if (this.state.basement !== false) {
        // searchStr = searchStr + ` ملجأ `;
        searchStr = searchStr + ` Basement `;
      }

      if (this.state.parking !== false) {
        // searchStr = searchStr + ` موقف `;
        searchStr = searchStr + ` Parking `;
      }

      if (this.state.duplex !== false) {
        // searchStr = searchStr + ` دوبلكس `;
        searchStr = searchStr + ` Duplex `;
      }

      if (this.state.lift !== false) {
        // searchStr = searchStr + ` مصعد `;
        searchStr = searchStr + ` Lift `;
      }

      await ReactGA.event({
        category: "Search page",
        action: `Search parameters: ${searchStr}`,
      });
    }
    window.location = `${this.state.destination}${str}${this.state.searchUrl}`;

    // this.setState({ errors: {}, pageNumber: 1 });
    // return (
    //   <Redirect
    //     to={{
    //       pathname: this.props.destination,
    //       // query: { search_mode: 'min' }
    //       search: this.state.searchUrl
    //     }}
    //   ></Redirect>
    // );
  };

  onSearchSubmit = (recordsPerPage, pageNumber, e) => {
    if (e !== null) {
      e.preventDefault();
    }
    const {
      // largeScreen,
      // searchBarFullShow,
      // searchTypeMainProperties,
      searchTypeAllProperties,
      searchTypeAgencyProperties,
      // searchTypeUserProperties,
      // searchTypeForRentProperties,
      // searchTypeForSaleProperties,
      // searchTypeForWithContacts,
      // searchId,
      // areas,
      // IsGarden,
      // IsMM,
      // IsUsingGPS,
      // forRent,
      // forSell,
      // IsBuildDate,
      // IsReconstructed,
      // showMap,
      // maplays,
      // propertyPosition,
      showLakta,
      ///meee
      show_deleted_properties,
      ////me
      // title,
      order_by,
      type,
      main_type_param,
      agency_name_param,
      agency_name,
      // rank,
      // description,
      // reference_id,
      // city,
      province,
      area,
      // street,
      // gps_location,
      bedrooms,
      selectedFilters,
      random,
      bathrooms,
      // toilets,
      garage,
      parking,
      // balcony,
      view_direction,
      floor,
      duplex,
      // total_area,
      // living_area,
      // ground_area,
      lift,
      furnitured,
      basement,
      // type_of_ownership,
      // price,
      // business_status,
      // rent_plan_id,
      // sell_plan_id,
      // visible,
      // built_at,
      // reconstructed_at,
      // status,
      // price_per_day,
      // price_per_mm,
      business_offer,
      // views,
      // created_at,
      // photo_data,
      min_price,
      max_price,
      min_area,
      max_area,
    } = this.state;

    var Province = "";
    var Area = "";
    var Type = "";
    var BusinessOffer = "";
    var Bedrooms = "";
    var Bathrooms = "";
    var Floor = "";
    var ViewDirection = "";
    var errors = {};
    if (province !== "المحافظة") {
      Province = province;
    }
    if (area !== "المنطقة") {
      Area = area;
    }
    if (type !== "نوع العقار") {
      Type = type;
    }
    if (business_offer !== "نوع العرض") {
      BusinessOffer = business_offer;
    }

    if (bedrooms !== "الغرف") {
      Bedrooms = bedrooms;
    }

    if (floor !== "الطابق") {
      Floor = floor;
    }
    if (bathrooms !== "غرف الاستحمام") {
      Bathrooms = bathrooms;
    }
    if (view_direction !== "الاتجاه") {
      ViewDirection = view_direction;
    }

    if (
      min_price !== "" &&
      min_price !== "أدنى سعر" &&
      parseInt(min_price) !== 0 &&
      max_price !== "" &&
      max_price !== "أعلى سعر" &&
      //parseInt(max_price) !== 0 &&
      parseInt(min_price) > parseInt(max_price)
    ) {
      errors.max_price = "أدنى سعر أكبر من أعلى سعر";
    }
    if (
      min_area !== "" &&
      min_area !== "أدنى مساحة" &&
      parseInt(min_area) !== 0 &&
      max_area !== "" &&
      max_area !== "أعلى مساحة" &&
      //parseInt(max_area) !== 0 &&
      parseInt(min_area) > parseInt(max_area)
    ) {
      errors.max_area = "المساحة الدنيا أكبر من المساحة العليا";
    }
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }
    const property = {};
    if (Type !== "") {
      property.type = Type;
    }
    if (main_type_param !== "" && this.props.urlFilters !== "false") {
      property.main_type = main_type_param;
    }
    if (BusinessOffer !== "") {
      property.business_offer = BusinessOffer;
    }

    if (Province !== "") {
      property.province = Province;
    }
    if (Area !== "") {
      property.city = Area;
    }
    if (Area !== "") {
      property.area = Area;
    }
    if (Bedrooms !== "") {
      property.bedrooms = Bedrooms;
    }
    if (Bathrooms !== "") {
      property.bathrooms = Bathrooms;
    }
    if (ViewDirection !== "") {
      property.view_direction = ViewDirection;
    }
    if (Floor !== "") {
      property.floor = Floor;
    }
    property.ranges = {};
    if (
      parseInt(min_price) !== 0 &&
      min_price !== "" &&
      min_price !== "أدنى سعر" &&
      parseInt(max_price) !== 0 &&
      max_price !== "" &&
      max_price !== "أعلى سعر" &&
      parseInt(min_price) <= parseInt(max_price)
    ) {
      property.ranges.price = {};
      property.ranges.price.min = min_price === "" ? 0 : min_price;
      property.ranges.price.max = max_price === "" ? 0 : max_price;
    }

    //////add
    else if (
      parseInt(min_price) !== 0 &&
      min_price !== "" &&
      min_price !== "أدنى سعر" &&
      (parseInt(max_price) === 0 || max_price === "") &&
      parseInt(min_price) > 0
    ) {
      property.ranges.price = {};
      property.ranges.price.min = min_price;
      property.ranges.price.max = null;
    } else if (
      parseInt(max_price) !== 0 &&
      max_price !== "" &&
      max_price !== "أعلى سعر" &&
      (parseInt(min_price) === 0 || min_price === "") &&
      parseInt(max_price) > 0
    ) {
      property.ranges.price = {};
      property.ranges.price.min = null;
      property.ranges.price.max = max_price;
    }
    //////
    if (
      parseInt(min_area) !== 0 &&
      min_area !== "" &&
      min_area !== "أدنى مساحة" &&
      parseInt(max_area) !== 0 &&
      max_area !== "" &&
      max_area !== "أعلى مساحة" &&
      parseInt(min_area) <= parseInt(max_area)
    ) {
      property.ranges.total_area = {};
      property.ranges.total_area.min = min_area === "" ? 0 : min_area;
      property.ranges.total_area.max = max_area === "" ? 0 : max_area;
    }

    //////add
    else if (
      parseInt(min_area) !== 0 &&
      min_area !== "" &&
      min_area !== "أدنى مساحة" &&
      (parseInt(max_area) === 0 || max_area === "") &&
      parseInt(min_area) > 0
    ) {
      property.ranges.total_area = {};
      property.ranges.total_area.min = min_area;
      property.ranges.total_area.max = null;
    } else if (
      parseInt(max_area) !== 0 &&
      max_area !== "" &&
      max_area !== "أعلى مساحة" &&
      (parseInt(min_area) === 0 || min_area === "") &&
      parseInt(max_area) > 0
    ) {
      property.ranges.total_area = {};
      property.ranges.total_area.min = null;
      property.ranges.total_area.max = max_area;
    }
    //////
    if (garage === true) {
      property.garage = true;
    }
    if (furnitured === true) {
      property.furnitured = true;
    }
    if (parking === true) {
      property.parking = true;
    }
    if (lift === true) {
      property.lift = true;
    }
    if (duplex === true) {
      property.duplex = true;
    }
    if (basement === true) {
      property.basement = true;
    }
    if (random !== "") {
      property.random = random;
    }
    if (agency_name.trim() !== "") {
      property.agency_name = agency_name;
    }
    if (this.state.searchTypeMainProperties) {
      // property.visible = true;
    } else if (this.state.searchTypeForWithContacts) {
      if (property.ranges === undefined) {
        property.ranges = {};
      }
      property.ranges.num_contacts = {};
      property.ranges.num_contacts.min = 1;
      property.ranges.num_contacts.max = 50000;
    } else if (this.state.searchTypeForWithAgencyContacts) {
      if (property.ranges === undefined) {
        property.ranges = {};
      }

      property.ranges.num_contacts = {};
      property.ranges.num_contacts.min = 1;
      property.ranges.num_contacts.max = 50000;
      property.for = "contact";
      // property.agency_ref_id = this.state.searchId;

      const isTakingAgencyRole = this.props.isTakingAgencyRole;
      const takenAgencyRoleAgencyRefId = this.props.takenAgencyRoleAgencyRefId;
      var agency_ref_id = "";
      if (isTakingAgencyRole) {
        agency_ref_id = takenAgencyRoleAgencyRefId;
      } else {
        agency_ref_id = localStorage.getItem("ikar_agency_ref_id");
      }

      property.agency_ref_id = agency_ref_id;
      // property.agency_ref_id = localStorage.getItem('ikar_agency_ref_id');
    } else if (this.state.searchTypeUserProperties) {
      property.agent_ref_id = this.state.searchId;
    } else if (this.state.searchTypeAgencyProperties) {
      property.agency_ref_id = this.state.searchId;
    } else if (this.state.searchTypeForRentProperties) {
      property.business_offer = "تأجير";
      // property.visible = true;
    } else if (this.state.searchTypeForSaleProperties) {
      property.business_offer = "بيع";
      // property.visible = true;
    }

    selectedFilters.forEach((item) => {
      switch (item) {
        case "كراج":
          property.garage = true;
          break;

        case "دوبلكس":
          property.duplex = true;
          break;

        case "موقف":
          property.parking = true;
          break;

        case "مصعد":
          property.lift = true;
          break;

        case "ملجأ":
          property.basement = true;
          break;

        case "مفروش":
          property.furnitured = true;
          break;

        default:
          break;
      }
    });

    if (showLakta) {
      // property.ranges.days = {};
      // property.ranges.days.min = 0;
      property.temp_status = "سعر لقطة";
    } else {
      // property.ranges.days = {};
      // property.ranges.days.min = -999999;
      // property.ranges.days.max = -1;
    }
    //////meeeeeeeee
    if (show_deleted_properties) {
      // property.ranges.days = {};
      // property.ranges.days.min = 0;
      property.business_status = "مؤرشف";
    } else {
      // property.ranges.days = {};
      // property.ranges.days.min = -999999;
      // property.ranges.days.max = -1;
    }
    ///////////////meee
    property.order = {};
    if (order_by !== "ترتيب تلقائي") {
      if (order_by === "من الأقدم إلى الأحدث") {
        property.order.created_at = "asc";
      } else if (order_by === "من الأحدث إلى الأقدم") {
        property.order.created_at = "desc";
      } else if (order_by === "من الأرخص إلى الأغلى") {
        property.order.price = "asc";
      } else if (order_by === "من الأغلى إلى الأرخص") {
        property.order.price = "desc";
      } else if (order_by === "من الأقل اتصالات") {
        property.order.calls = "asc";
      } else if (order_by === "من الأكثر اتصالات") {
        property.order.calls = "desc";
      } else if (order_by === "من الأقل مشاهدات") {
        property.order.views = "asc";
      } else if (order_by === "من الأكثر مشاهدات") {
        property.order.views = "desc";
      }
    }
    ///////new line
    else if (order_by == "ترتيب تلقائي") {
      property.order.created_at = "desc";
    }
    var searchType = "search";
    if (
      searchTypeAllProperties === true ||
      this.state.searchTypeForWithContacts === true
    ) {
      searchType = "admin_search";
    } else if (
      searchTypeAgencyProperties === true ||
      this.state.searchTypeForWithAgencyContacts === true
    ) {
      searchType = "agency_search";
    }

    const isTakingAgencyRole = this.props.isTakingAgencyRole;
    const takenAgencyRoleToken = this.props.takenAgencyRoleToken;
    let aggregationData = {};
    let aggregationData2 = {};
    if (!property.province) {
      aggregationData.aggs_on = "province";
    } else if (!property.type) {
      aggregationData.aggs_on = "type";
    } else {
      aggregationData.aggs_on = "none";
    }
    if (!property.business_offer) {
      aggregationData2.aggs_on = "business_offer";
    } else {
      aggregationData2.aggs_on = "none";
    }

    if (property.province) {
      aggregationData.province = property.province;
      aggregationData2.province = property.province;
    }
    if (property.business_offer) {
      aggregationData.business_offer = property.business_offer;
      aggregationData2.business_offer = property.business_offer;
    }
    if (property.type) {
      aggregationData.type = property.type;
      aggregationData2.type = property.type;
    }
    if (property.ranges) {
      aggregationData.ranges = property.ranges;
      aggregationData2.ranges = property.ranges;
    }
    if (property.bedrooms) {
      aggregationData.bedrooms = property.bedrooms;
      aggregationData2.bedrooms = property.bedrooms;
    }
    if (property.bathrooms) {
      aggregationData.bathrooms = property.bathrooms;
      aggregationData2.bathrooms = property.bathrooms;
    }
    if (property.floor) {
      aggregationData.floor = property.floor;
      aggregationData2.floor = property.floor;
    }
    if (property.view_direction) {
      aggregationData.view_direction = property.view_direction;
      aggregationData2.view_direction = property.view_direction;
    }
    if (property.random) {
      aggregationData.random = property.random;
      aggregationData2.random = property.random;
    }
    if (property.parking) {
      aggregationData.parking = property.parking;
      aggregationData2.parking = property.parking;
    }
    if (property.lift) {
      aggregationData.lift = property.lift;
      aggregationData2.lift = property.lift;
    }
    if (property.garage) {
      aggregationData.garage = property.garage;
      aggregationData2.garage = property.garage;
    }
    if (property.furnitured) {
      aggregationData.furnitured = property.furnitured;
      aggregationData2.furnitured = property.furnitured;
    }
    if (property.basement) {
      aggregationData.basement = property.basement;
      aggregationData2.basement = property.basement;
    }
    if (property.duplex) {
      aggregationData.duplex = property.duplex;
      aggregationData2.duplex = property.duplex;
    }
    this.props.getCategories(aggregationData);
    this.props.getCategories2(aggregationData2);
    if (
      property.agent_ref_id === undefined ||
      (property.agent_ref_id !== undefined && property.agent_ref_id !== "")
    )
      this.props.doSearch(
        property,
        recordsPerPage,
        pageNumber,
        searchType,
        isTakingAgencyRole,
        takenAgencyRoleToken,
        "search bar"
      );
    this.setState({ errors: {} });
  };
  handleSizeClick = (e) => {
    e.preventDefault();
    this.setState({ searchBarFullShow: !this.state.searchBarFullShow });
  };
  onBusinessOfferChange = async (e) => {
    // await this.buildSearchUrl();

    if (e.target.value === "أبحث للإيجار") {
      await this.setState({
        [e.target.name]: e.target.value,
        forRent: true,
        forSell: false,
      });
    } else if (e.target.value === "تأجير") {
      await this.setState({
        [e.target.name]: e.target.value,
        forRent: true,
        forSell: false,
      });
    } else if (e.target.value === "أبحث للشراء") {
      await this.setState({
        [e.target.name]: e.target.value,
        forRent: false,
        forSell: true,
      });
    } else if (e.target.value === "رهن") {
      await this.setState({
        [e.target.name]: e.target.value,
        forRent: false,
        forSell: false,
      });
    } else {
      await this.setState({
        [e.target.name]: e.target.value,
        forRent: false,
        forSell: true,
      });
    }
  };

  onChange = async (e) => {
    await this.setState({ [e.target.name]: e.target.value });
    // await this.buildSearchUrl();
  };
  onOrderChange = async (e) => {
    await this.setState({ [e.target.name]: e.target.value });
    // await this.onSearchSubmit(recordsPerPage, 1, null);
    await this.onSearchButtonSubmit(e);
  };
  onCheckBoxChange = async (e) => {
    await this.setState({ [e.target.name]: e.target.checked });
    // await this.buildSearchUrl();
  };
  onProvinceChange = async (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.value === "دمشق") {
      //دمشق
      await this.setState({
        areas: syriaAreas.damascusAreas,
        area: "المنطقة",
        city: "",
      });
    } else if (e.target.value === "ريف دمشق") {
      //ريف دمشق
      await this.setState({
        areas: syriaAreas.rifDamascusAreas,
        area: "المنطقة",
        city: "",
      });
    } else if (e.target.value === "حلب") {
      //حلب
      await this.setState({
        areas: syriaAreas.aleppoAreas,
        area: "المنطقة",
        city: "",
      });
    } else if (e.target.value === "طرطوس") {
      //طرطوس
      await this.setState({
        areas: syriaAreas.tartousAreas,
        area: "المنطقة",
        city: "",
      });
    } else if (e.target.value === "اللاذقية") {
      //اللاذقية
      await this.setState({
        areas: syriaAreas.latakiaAreas,
        area: "المنطقة",
        city: "",
      });
    } else if (e.target.value === "حمص") {
      //حمص
      await this.setState({
        areas: syriaAreas.homsAreas,
        area: "المنطقة",
        city: "",
      });
    } else if (e.target.value === "حماة") {
      //حماة
      await this.setState({
        areas: syriaAreas.hamaAreas,
        area: "المنطقة",
        city: "",
      });
    } else if (e.target.value === "السويداء") {
      //السويداء
      await this.setState({
        areas: syriaAreas.asuwaydaAreas,
        area: "المنطقة",
        city: "",
      });
    } else if (e.target.value === "الحسكة") {
      //الحسكة
      await this.setState({
        areas: syriaAreas.hasakaAreas,
        area: "المنطقة",
        city: "",
      });
    } else if (e.target.value === "درعا") {
      //درعا
      await this.setState({
        areas: syriaAreas.daraaAreas,
        area: "المنطقة",
        city: "",
      });
    } else if (e.target.value === "ادلب") {
      //ادلب
      await this.setState({
        areas: syriaAreas.idlibAreas,
        area: "المنطقة",
        city: "",
      });
    } else if (e.target.value === "دير الزور") {
      //دير الزور
      await this.setState({
        areas: syriaAreas.deirezorAreas,
        area: "المنطقة",
        city: "",
      });
    } else if (e.target.value === "القنيطرة") {
      //القنيطرة
      await this.setState({
        areas: syriaAreas.quneitraAreas,
        area: "المنطقة",
        city: "",
      });
    } else if (e.target.value === "الرقة") {
      //الرقة
      await this.setState({
        areas: syriaAreas.raqqaAreas,
        area: "المنطقة",
        city: "",
      });
    } else {
      await this.setState({ areas: [], area: "المنطقة", city: "" });
    }
    // await this.buildSearchUrl();
  };
  onProvinceChange2 = async (province) => {
    if (province === "دمشق") {
      //دمشق
      await this.setState({
        areas: syriaAreas.damascusAreas,
        area: "المنطقة",
        city: "",
      });
    } else if (province === "ريف دمشق") {
      //ريف دمشق
      await this.setState({
        areas: syriaAreas.rifDamascusAreas,
        area: "المنطقة",
        city: "",
      });
    } else if (province === "حلب") {
      //حلب
      await this.setState({
        areas: syriaAreas.aleppoAreas,
        area: "المنطقة",
        city: "",
      });
    } else if (province === "طرطوس") {
      //طرطوس
      await this.setState({
        areas: syriaAreas.tartousAreas,
        area: "المنطقة",
        city: "",
      });
    } else if (province === "اللاذقية") {
      //اللاذقية
      await this.setState({
        areas: syriaAreas.latakiaAreas,
        area: "المنطقة",
        city: "",
      });
    } else if (province === "حمص") {
      //حمص
      await this.setState({
        areas: syriaAreas.homsAreas,
        area: "المنطقة",
        city: "",
      });
    } else if (province === "حماة") {
      //حماة
      await this.setState({
        areas: syriaAreas.hamaAreas,
        area: "المنطقة",
        city: "",
      });
    } else if (province === "السويداء") {
      //السويداء
      await this.setState({
        areas: syriaAreas.asuwaydaAreas,
        area: "المنطقة",
        city: "",
      });
    } else if (province === "الحسكة") {
      //الحسكة
      await this.setState({
        areas: syriaAreas.hasakaAreas,
        area: "المنطقة",
        city: "",
      });
    } else if (province === "درعا") {
      //درعا
      await this.setState({
        areas: syriaAreas.daraaAreas,
        area: "المنطقة",
        city: "",
      });
    } else if (province === "ادلب") {
      //ادلب
      await this.setState({
        areas: syriaAreas.idlibAreas,
        area: "المنطقة",
        city: "",
      });
    } else if (province === "دير الزور") {
      //دير الزور
      await this.setState({
        areas: syriaAreas.deirezorAreas,
        area: "المنطقة",
        city: "",
      });
    } else if (province === "القنيطرة") {
      //القنيطرة
      await this.setState({
        areas: syriaAreas.quneitraAreas,
        area: "المنطقة",
        city: "",
      });
    } else if (province === "الرقة") {
      //الرقة
      await this.setState({
        areas: syriaAreas.raqqaAreas,
        area: "المنطقة",
        city: "",
      });
    } else {
      await this.setState({ areas: [], area: "المنطقة", city: "" });
    }
    // await this.buildSearchUrl();
  };
  FilterTemplate = (option) => {
    switch (option.label) {
      case "كراج":
        return (
          <div className="p-clearfix">
            <span
              style={{ fontSize: "1em", float: "right", marginTop: "4px" }}
            >{` ${option.label} `}</span>
            <FontAwesomeIcon icon={["fas", "warehouse"]} size="1x" />
          </div>
        );

      case "دوبلكس":
        return (
          <div className="p-clearfix">
            <span
              style={{ fontSize: "1em", float: "right", marginTop: "4px" }}
            >{` ${option.label} `}</span>
            <FontAwesomeIcon icon={["fas", "clone"]} size="1x" />
          </div>
        );

      case "موقف":
        return (
          <div className="p-clearfix">
            <span
              style={{ fontSize: "1em", float: "right", marginTop: "4px" }}
            >{` ${option.label} `}</span>
            <FontAwesomeIcon icon={["fas", "parking"]} size="1x" />
          </div>
        );

      case "مصعد":
        return (
          <div className="p-clearfix">
            <span
              style={{ fontSize: "1em", float: "right", marginTop: "4px" }}
            >{` ${option.label} `}</span>
            <FontAwesomeIcon icon={["fas", "level-up-alt"]} size="1x" />
          </div>
        );

      case "ملجأ":
        return (
          <div className="p-clearfix">
            <span
              style={{ fontSize: "1em", float: "right", marginTop: "4px" }}
            >{` ${option.label} `}</span>
            <FontAwesomeIcon icon={["fas", "archway"]} size="1x" />
          </div>
        );
      case "مفروش":
        return (
          <div className="p-clearfix">
            <span
              style={{ fontSize: "1em", float: "right", marginTop: "4px" }}
            >{` ${option.label} `}</span>
            <FontAwesomeIcon icon={["fas", "couch"]} size="1x" />
          </div>
        );
      // case "اختيار الكل":
      //   return (
      //     <div className="p-clearfix">
      //       <span
      //         style={{ fontSize: "1em", float: "right", marginTop: "4px" }}
      //       >{` ${option.label} `}</span>
      //     </div>
      //   );

      default:
        return <span className="p-clearfix">فلاتر اضافية للبحث</span>;
    }
  };
  selectedFilterTemplate = (value) => {
    switch (value) {
      case "كراج":
        return (
          <div className="filter_multiselected-items">
            <span>{` ${value} `}</span>
            <FontAwesomeIcon icon={["fas", "warehouse"]} size="1x" />
          </div>
        );

      case "دوبلكس":
        return (
          <div className="filter_multiselected-items">
            <span>{` ${value} `}</span>
            <FontAwesomeIcon icon={["fas", "clone"]} size="1x" />
          </div>
        );

      case "موقف":
        return (
          <div className="filter_multiselected-items">
            <span>{` ${value} `}</span>
            <FontAwesomeIcon icon={["fas", "parking"]} size="1x" />
          </div>
        );

      case "مصعد":
        return (
          <div className="filter_multiselected-items">
            <span>{` ${value} `}</span>
            <FontAwesomeIcon icon={["fas", "level-up-alt"]} size="1x" />
          </div>
        );

      case "ملجأ":
        return (
          <div className="filter_multiselected-items">
            <span>{` ${value} `}</span>
            <FontAwesomeIcon icon={["fas", "archway"]} size="1x" />
          </div>
        );
      case "مفروش":
        return (
          <div className="filter_multiselected-items">
            <span>{` ${value} `}</span>
            <FontAwesomeIcon icon={["fas", "couch"]} size="1x" />
          </div>
        );
      // case "اختيار الكل":
      //   return (
      //     <div className="filter_multiselected-items">
      //       <span>{` ${value} `}</span>
      //     </div>
      //   );
      default:
        return (
          <span className="my-multiselected-empty-token">
            فلاتر اضافية للبحث
          </span>
        );
    }
  };
  removeSearchQuery = async () => {
    localStorage.removeItem("ikar_main_type_param");
    await this.setState({ main_type_param: "", agency_name_param: "" });
    this.onSearchSubmit(recordsPerPage, 1, null);
  };

  render() {
    const filters = [
      // { label: "اختيار الكل", value: "اختيار الكل" },
      { label: "كراج", value: "كراج" },
      { label: "دوبلكس", value: "دوبلكس" },
      { label: "موقف", value: "موقف" },
      { label: "مصعد", value: "مصعد" },
      { label: "ملجأ", value: "ملجأ" },
      { label: "مفروش", value: "مفروش" },
    ];
    const {
      largeScreen,
      forSell,
      forRent,
      searchBarFullShow,
      // searchTypeMainProperties,
      searchTypeAllProperties,
      // searchTypeAgencyProperties,
      // searchTypeUserProperties,
      searchTypeForRentProperties,
      searchTypeForSaleProperties,
      searchTypeForWithContacts,
      searchTypeForWithAgencyContacts,
      // searchId,
      // searchNewBarShow,
      agency_name,
      areas,
      // IsGarden,
      // IsMM,
      // IsUsingGPS,
      // forRent,
      // forSell,
      // IsBuildDate,
      // IsReconstructed,
      // showMap,
      // maplays,
      // propertyPosition,
      showLakta,
      //mee
      show_deleted_properties,
      ///meeeeee
      // title,
      order_by,
      type,
      main_type_param,
      agency_name_param,
      // rank,
      // description,
      // reference_id,
      // city,
      province,
      area,
      // street,
      // gps_location,
      bedrooms,
      selectedFilters,
      random,
      bathrooms,
      // toilets,
      // garage,
      // parking,
      // balcony,
      view_direction,
      floor,
      // duplex,
      // total_area,
      // living_area,
      // ground_area,
      // lift,
      // furnitured,
      // type_of_ownership,
      // price,
      // business_status,
      // rent_plan_id,
      // sell_plan_id,
      // visible,
      // built_at,
      // reconstructed_at,
      // status,
      // price_per_day,
      // price_per_mm,
      business_offer,
      // views,
      // created_at,
      // photo_data,
      min_price,
      max_price,
      min_area,
      max_area,
      errors,
    } = this.state;

    return (
      <React.Fragment>
        {this.props.searchMode !== "off" ? (
          <Helmet>
            <meta charSet="utf-8" />
            <title>{this.state.pageTitle}</title>
            <meta name="description" content={this.state.pageDescription} />
          </Helmet>
        ) : null}

        {!searchTypeForWithContacts && !searchTypeForWithAgencyContacts ? (
          <div className="search_div mt-2">
            {/* {this.props.searchMainTitle !== undefined &&
            this.props.searchMainTitle === 'show' ? (
              <div className="text-right">
                <span className="display_gold">للبحث عن عقارات أخرى</span>
              </div>
            ) : null} */}

            {agency_name_param !== "" && this.props.urlFilters !== "false" ? (
              <div className="text-right mr-3">
                <div className="mb-1">
                  <span className="display_7">
                    {`يتم فلترة النتائج تبعا للقيم(`}
                  </span>
                  <span className="display_7">
                    {agency_name_param !== "" &&
                    this.props.urlFilters !== "false" ? (
                      <React.Fragment>
                        <span className="display_7">اسم المكتب : </span>
                        <span className="display_gold">
                          {agency_name_param}
                        </span>
                      </React.Fragment>
                    ) : null}
                    <span className="display_7">{")"}</span>
                  </span>
                  <Link
                    to={{
                      pathname: this.state.destination,
                      // query: { search_mode: 'min' }
                      // search: this.state.normalUrl,
                    }}
                    className={classnames("btn", "px-0", "submit-button")}
                    style={{ width: "200px" }}
                    // onClick={this.onSearchButtonSubmit}
                  >
                    حذف الفلترة الحالية للبحث
                  </Link>
                </div>
              </div>
            ) : null}
            {main_type_param !== "" && this.props.urlFilters !== "false" ? (
              <div className="text-right mr-3">
                <div className="mb-1">
                  <span className="display_7">
                    {`يتم فلترة النتائج تبعا للقيم(`}
                  </span>
                  <span className="display_7">
                    {main_type_param !== "" &&
                    this.props.urlFilters !== "false" ? (
                      <React.Fragment>
                        <span className="display_7">نوع العقار: </span>
                        <span className="display_gold">
                          {main_type_param.split(" OR ").join(",")}
                        </span>
                      </React.Fragment>
                    ) : null}
                    <span className="display_7">{")"}</span>
                  </span>
                  <Link
                    to={{
                      pathname: this.state.destination,
                      // query: { search_mode: 'min' }
                      search: this.state.normalUrl,
                    }}
                    className={classnames("btn", "px-0", "submit-button")}
                    style={{ width: "200px" }}
                    // onClick={this.onSearchButtonSubmit}
                  >
                    حذف الفلترة الحالية للبحث
                  </Link>
                </div>
              </div>
            ) : null}

            {/* <form onSubmit={this.onSearchSubmit.bind(this, recordsPerPage, 1)}> */}
            {/* <form onSubmit={this.onSearchButtonSubmit}> */}
            <form>
              <div className="row">
                <div className="col-md-10">
                  <div className="input-group justify-content-center">
                    <div className="form-group text-right px-1">
                      <label htmlFor="province" className="label_narrow">
                        المحافظة:
                      </label>
                      <select
                        name="province"
                        className={classnames(
                          "form-control",
                          "search-page-province",
                          {
                            "is-invalid": errors.province,
                          }
                        )}
                        onChange={this.onProvinceChange}
                        value={province}
                        readOnly={
                          agency_name_param !== "" &&
                          this.props.urlFilters !== "false"
                        }
                        disabled={
                          agency_name_param !== "" &&
                          this.props.urlFilters !== "false"
                        }

                        // style={{ width: '125px' }}
                      >
                        <option value="المحافظة">المحافظة</option>
                        <option value="دمشق"> دمشق</option>
                        <option value="ريف دمشق"> ريف دمشق</option>
                        <option value="حلب"> حلب</option>
                        <option value="طرطوس"> طرطوس</option>
                        <option value="اللاذقية"> اللاذقية</option>
                        <option value="حمص"> حمص</option>
                        <option value="حماة"> حماة</option>
                        <option value="السويداء"> السويداء</option>
                        <option value="الحسكة"> الحسكة</option>
                        <option value="درعا"> درعا</option>
                        <option value="ادلب"> ادلب</option>
                        <option value="دير الزور"> دير الزور</option>
                        <option value="القنيطرة"> القنيطرة</option>
                        <option value="الرقة"> الرقة</option>
                      </select>
                    </div>
                    <div className="form-group text-right pl-sm-1 pr-sm-0 px-1">
                      <label htmlFor="area" className="label_narrow">
                        المنطقة:
                      </label>
                      <select
                        name="area"
                        className={classnames(
                          "form-control",
                          "search-page-area",
                          {
                            "is-invalid": errors.area,
                          }
                        )}
                        onChange={this.onChange}
                        value={area}
                        // style={{ width: '162px' }}
                      >
                        <option value="المنطقة">المنطقة</option>
                        {areas.map((area, index) => (
                          <option value={area} key={index}>
                            {area}
                          </option>
                        ))}
                      </select>
                    </div>
                    {!largeScreen ? (
                      <React.Fragment>
                        <div className="input-group justify-content-center"></div>
                      </React.Fragment>
                    ) : null}
                    <div className="form-group text-right  px-1">
                      <label
                        htmlFor="business_offer mx-1"
                        className="label_narrow"
                      >
                        نوع العرض:
                      </label>
                      {searchTypeForRentProperties &&
                      !searchTypeForSaleProperties ? (
                        <select
                          name="business_offer"
                          className={classnames(
                            "form-control",
                            "search-page-business-offer",
                            {
                              "is-invalid": errors.business_offer,
                            }
                          )}
                          onChange={this.onBusinessOfferChange}
                          value={business_offer}
                          // style={{ width: '120px' }}
                        >
                          <option value="تأجير">تأجير</option>
                        </select>
                      ) : null}

                      {!searchTypeForRentProperties &&
                      searchTypeForSaleProperties ? (
                        <select
                          name="business_offer"
                          className={classnames(
                            "form-control",
                            "search-page-business-offer",
                            {
                              "is-invalid": errors.business_offer,
                            }
                          )}
                          onChange={this.onBusinessOfferChange}
                          value={business_offer}
                          // style={{ width: '120px' }}
                        >
                          <option value="بيع">بيع</option>
                        </select>
                      ) : null}

                      {!searchTypeForRentProperties &&
                      !searchTypeForSaleProperties ? (
                        <select
                          name="business_offer"
                          className={classnames(
                            "form-control",
                            "search-page-business-offer",
                            {
                              "is-invalid": errors.business_offer,
                            }
                          )}
                          onChange={this.onBusinessOfferChange}
                          value={business_offer}
                          // style={{ width: '120px' }}
                        >
                          <option value="نوع العرض">نوع العرض</option>
                          {generalBusinessOffer.map((businessOffer) => (
                            <option
                              value={businessOffer}
                              key={shortid.generate()}
                            >
                              {businessOffer}
                            </option>
                          ))}
                        </select>
                      ) : null}
                    </div>
                    <div className="form-group text-right pl-sm-1 pr-sm-0 px-1">
                      <label htmlFor="type" className="label_narrow">
                        نوع العقار:
                      </label>
                      <select
                        name="type"
                        className={classnames(
                          "form-control",
                          "search-page-type",
                          {
                            "is-invalid": errors.type,
                          }
                        )}
                        onChange={this.onChange}
                        value={type}
                        readOnly={
                          main_type_param !== "" &&
                          this.props.urlFilters !== "false"
                        }
                        disabled={
                          main_type_param !== "" &&
                          this.props.urlFilters !== "false"
                        }
                        // style={{ width: '115px' }}
                      >
                        <option value="نوع العقار">نوع العقار</option>
                        {generalTypes}
                      </select>
                    </div>
                  </div>
                  {!searchTypeAllProperties || searchBarFullShow ? (
                    <div className="input-group justify-content-center">
                      <div className="form-group text-right px-1">
                        {/* text-right */}
                        <label htmlFor="type" className="label_narrow">
                          أدنى سعر:
                        </label>
                        <select
                          name="min_price"
                          id="minPrice"
                          className={classnames(
                            "search-page-min-price",
                            "form-control",
                            {
                              "is-invalid": errors.min_price,
                            }
                          )}
                          onChange={this.onChange}
                          value={min_price}
                        >
                          <option value="أدنى سعر">أدنى سعر</option>
                          {forSell
                            ? generalSellPrices.map((price) => (
                                <option value={price} key={shortid.generate()}>
                                  {`${formatMoney(price)} ل.س`}
                                </option>
                              ))
                            : forRent
                            ? generalRentPrices.map((price) => (
                                <option value={price} key={shortid.generate()}>
                                  {`${formatMoney(price)} ل.س`}
                                </option>
                              ))
                            : null}
                        </select>
                        {/* {errors.min_price && (
                      <div className="invalid-feedback">
                        {errors.min_price}
                      </div>
                    )} */}
                      </div>
                      <div className="form-group text-right pl-sm-1 pr-sm-0 px-1">
                        <label htmlFor="type" className="label_narrow">
                          أعلى سعر:
                        </label>
                        <select
                          name="max_price"
                          id="maxPrice"
                          className={classnames(
                            "search-page-max-price",
                            "form-control",
                            {
                              "is-invalid": errors.max_price,
                            }
                          )}
                          onChange={this.onChange}
                          value={max_price}
                        >
                          <option value="أعلى سعر">أعلى سعر</option>
                          {forSell ? (
                            <React.Fragment>
                              {generalSellPrices.map((price) => (
                                <option value={price} key={shortid.generate()}>
                                  {`${formatMoney(price)} ل.س`}
                                </option>
                              ))}
                              <option value="900000000000">
                                {`أكبر من ${formatMoney(900000000)} ل.س`}
                              </option>{" "}
                            </React.Fragment>
                          ) : forRent ? (
                            <React.Fragment>
                              {generalRentPrices.map((price) => (
                                <option value={price} key={shortid.generate()}>
                                  {`${formatMoney(price)} ل.س`}
                                </option>
                              ))}
                              <option value="100000000000">
                                {`أكبر من ${formatMoney(100000000)} ل.س`}
                              </option>{" "}
                            </React.Fragment>
                          ) : null}
                        </select>

                        {/* {errors.max_price && (
                      <div className="invalid-feedback">
                        {errors.max_price}
                      </div>
                    )} */}
                      </div>
                      {!largeScreen ? (
                        <React.Fragment>
                          <div className="input-group justify-content-center"></div>
                        </React.Fragment>
                      ) : null}
                      <div className="form-group text-right px-1">
                        {/* text-right */}
                        <label htmlFor="type" className="label_narrow">
                          أدنى مساحة:
                        </label>
                        <select
                          name="min_area"
                          id="minArea"
                          className={classnames(
                            "search-page-min-area",
                            "form-control",
                            {
                              "is-invalid": errors.min_area,
                            }
                          )}
                          onChange={this.onChange}
                          value={min_area}
                        >
                          <option value="أدنى مساحة">أدنى مساحة</option>
                          {generalAreas.map((area) => (
                            <option value={area} key={shortid.generate()}>
                              {`${formatMoney(area)} متر مربع`}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group text-right pl-sm-1 pr-sm-0 px-1">
                        {/* {errors.min_area && (
                      <div className="invalid-feedback">
                        {errors.min_area}
                      </div>
                    )} */}
                        <label htmlFor="type" className="label_narrow">
                          أعلى مساحة:
                        </label>
                        <select
                          name="max_area"
                          id="maxArea"
                          className={classnames(
                            "search-page-max-area",
                            "form-control",
                            {
                              "is-invalid": errors.max_area,
                            }
                          )}
                          onChange={this.onChange}
                          value={max_area}
                        >
                          <option value="أعلى مساحة">أعلى مساحة</option>
                          {generalAreas.map((area) => (
                            <option value={area} key={shortid.generate()}>
                              {`${formatMoney(area)} متر مربع`}
                            </option>
                          ))}
                          <option value={90000000}>
                            {`أكبر من ${formatMoney(9000)} متر مربع`}
                          </option>
                        </select>

                        {/* {errors.max_area && (
                      <div className="invalid-feedback">
                        {errors.max_area}
                      </div>
                    )} */}
                      </div>
                    </div>
                  ) : null}

                  {(errors.min_price || errors.max_price) && (
                    <div className="row">
                      <div className="col-md-6 col-8 mx-auto">
                        <div
                          className="text-right alert alert-danger mt-0 mb-3  py-0"
                          role="alert"
                        >
                          الرجاء التأكد من القيم المدخلة للسعر
                        </div>
                      </div>
                    </div>
                  )}
                  {(errors.min_area || errors.max_area) && (
                    <div className="row">
                      <div className="col-md-6 col-8 mx-auto">
                        <div
                          className="text-right alert alert-danger mt-0 mb-3 py-0"
                          role="alert"
                        >
                          الرجاء التاكد من القيم المدخلة للمساحة
                        </div>
                      </div>
                    </div>
                  )}
                  {searchBarFullShow ? (
                    <React.Fragment>
                      <div className="input-group  justify-content-center">
                        <div className="form-group text-right px-1">
                          <label htmlFor="bedrooms" className="label_narrow">
                            <span>
                              {type === "مقهى" ||
                              type === "مكتب" ||
                              type === "عيادة" ||
                              type === "مطعم" ||
                              type === "منشأة تصنيع" ||
                              type === "محطة وقود" ||
                              type === "محلات تجارية" ||
                              type === "محلات اصلاح" ||
                              type === "محلات لتربية الحيوانات" ? (
                                <span>عدد الغرف:</span>
                              ) : (
                                <span>عدد غرف النوم:</span>
                              )}
                            </span>
                          </label>
                          <select
                            name="bedrooms"
                            className={classnames(
                              "form-control",
                              "search-page-bedroom",
                              {
                                "is-invalid": errors.bedrooms,
                              }
                            )}
                            onChange={this.onChange}
                            value={bedrooms}
                            // style={{ width: '87px' }}
                          >
                            <option value="الغرف">الغرف</option>
                            {/* <option value="0"> 0</option> */}
                            <option value="1"> 1</option>
                            <option value="2"> 2</option>
                            <option value="3"> 3</option>
                            <option value="4"> 4</option>
                            <option value="5"> 5</option>
                            <option value="6"> 6</option>
                            <option value="7"> 7</option>
                            <option value="8"> 8</option>
                            <option value="9"> 9</option>
                            <option value="10"> 10</option>
                            <option value="11"> 11</option>
                            <option value="12"> 12</option>
                          </select>
                        </div>
                        <div className="form-group text-right pl-sm-1 pr-sm-0 px-1">
                          <label htmlFor="bathrooms" className="label_narrow">
                            عدد غرف الاستحمام:
                          </label>
                          <select
                            name="bathrooms"
                            className={classnames(
                              "form-control",
                              "search-page-bathroom",
                              {
                                "is-invalid": errors.bathrooms,
                              }
                            )}
                            onChange={this.onChange}
                            value={bathrooms}
                            // style={{ width: '155px' }}
                          >
                            <option value="غرف الاستحمام">غرف الاستحمام</option>
                            {/* <option value="0"> 0</option> */}
                            <option value="1"> 1</option>
                            <option value="2"> 2</option>
                            <option value="3"> 3</option>
                            <option value="4"> 4</option>
                            <option value="5"> 5</option>
                          </select>
                        </div>
                        {!largeScreen ? (
                          <React.Fragment>
                            <div className="input-group justify-content-center"></div>
                          </React.Fragment>
                        ) : null}
                        <div className="form-group text-right px-1">
                          <label htmlFor="floor" className="label_narrow">
                            رقم الطابق:
                          </label>
                          <select
                            name="floor"
                            className={classnames(
                              "form-control",
                              "search-page-floor",
                              {
                                "is-invalid": errors.floor,
                              }
                            )}
                            onChange={this.onChange}
                            value={floor}
                            // style={{ width: '97px' }}
                          >
                            <option value="الطابق">الطابق</option>
                            <option value="-3">قبو(3 طوابق تحت الأرض)</option>
                            <option value="-2">قبو(طابقين تحت الأرض)</option>
                            <option value="-1">قبو( طابق تحت الأرض)</option>
                            <option value="-25">نص قبو (قبو فايش)</option>
                            <option value="-24">أرضي منخفض</option>
                            <option value="0">أرضي</option>
                            <option value="1"> 1</option>
                            <option value="2"> 2</option>
                            <option value="3"> 3</option>
                            <option value="4"> 4</option>
                            <option value="5"> 5</option>
                            <option value="6"> 6</option>
                            <option value="7"> 7</option>
                            <option value="8"> 8</option>
                            <option value="9"> 9</option>
                            <option value="10"> 10</option>
                            <option value="11"> 11</option>
                            <option value="12"> 12</option>
                            <option value="13"> 13</option>
                            <option value="14"> 14</option>
                            <option value="15"> 15</option>
                            <option value="16"> 16</option>
                            <option value="17"> 17</option>
                            <option value="18"> 18</option>
                            <option value="19"> 19</option>
                            <option value="20"> 20</option>
                            <option value="21"> 21</option>
                            <option value="22"> 22</option>
                            <option value="23"> 23</option>
                            <option value="24"> 24</option>
                            <option value="25"> 25</option>
                            <option value="26"> 26</option>
                            <option value="27"> 27</option>
                          </select>
                        </div>
                        <div className="form-group text-right pl-sm-1 pr-sm-0 px-1">
                          <label
                            htmlFor="view_direction"
                            className="label_narrow"
                          >
                            الاتجاه:
                          </label>
                          <select
                            name="view_direction"
                            className={classnames(
                              "form-control",
                              "search-page-view-direction",
                              "view_direction_style",
                              {
                                "is-invalid": errors.view_direction,
                              }
                            )}
                            onChange={this.onChange}
                            value={view_direction}
                          >
                            <option value="الاتجاه">الاتجاه</option>
                            <option value="شرقي"> شرقي</option>
                            <option value="غربي"> غربي</option>
                            <option value="جنوبي"> جنوبي</option>
                            <option value="شمالي"> شمالي</option>
                            <option value="شمال غربي"> شمال غربي</option>
                            <option value="شمال شرقي"> شمال شرقي</option>
                            <option value="جنوب غربي"> جنوب غربي</option>
                            <option value="جنوب شرقي"> جنوب شرقي</option>
                            <option value="كل الاتجاهات"> كل الاتجاهات</option>
                            <option value="ثلاثة اتجاهات">
                              {" "}
                              ثلاثة اتجاهات
                            </option>
                          </select>
                        </div>
                      </div>

                      <div className="input-group  justify-content-center">
                        <div className="form-group text-right px-1">
                          <label htmlFor="random" className="label_narrow">
                            بحث عن نص محدد:
                          </label>
                          <input
                            type="text"
                            name="random"
                            className={classnames(
                              "form-control",
                              "search-page-random",
                              {
                                "is-invalid": errors.random,
                              }
                            )}
                            onChange={this.onChange}
                            value={random}
                            // style={{ width: '90px' }}
                          />
                        </div>
                        {/* {!largeScreen ? (
                        <React.Fragment>
                          <div className="input-group justify-content-center"></div>
                        </React.Fragment>
                      ) : null} */}
                        <div className="form-group text-right px-1">
                          <label htmlFor="filters" className="label_narrow">
                            فلاتر اضافية:
                          </label>

                          <div>
                            {/* <label for="selectAll1">اختيار الكل</label> */}
                            <MultiSelect
                              //style={{ direction: 'ltr' }}
                              value={selectedFilters}
                              options={filters}
                              //filter={true}
                              onChange={(e) =>
                                this.setState({ selectedFilters: e.value })
                              }
                              className="search-page-filters"
                              style={{
                                minWidth: "12em",
                                height: "calc(1.5em + .75rem + 2px)",
                              }}
                              itemTemplate={this.FilterTemplate}
                              selectedItemTemplate={this.selectedFilterTemplate}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="input-group justify-content-center">
                        {searchTypeAllProperties ||
                        searchTypeForWithContacts ||
                        searchTypeForWithAgencyContacts ? (
                          <div className="form-group text-right px-1">
                            <label
                              htmlFor="agency_name"
                              className="label_narrow"
                            >
                              المكتب العقاري:
                            </label>
                            <input
                              type="text"
                              className={classnames(
                                "form-control",
                                "search-page-office",
                                {
                                  "is-invalid": errors.agency_name,
                                }
                              )}
                              name="agency_name"
                              value={agency_name}
                              onChange={this.onChange}
                              // style={{ width: '120px' }}
                            />
                          </div>
                        ) : null}

                        {searchTypeAllProperties ||
                        (this.props.aIkar !== undefined &&
                          this.props.aIkar === true) ? (
                          <div className="form-group text-right px-3">
                            <label
                              htmlFor="agency_name"
                              className="label_narrow"
                            >
                              العقارات اللقطة:
                            </label>
                            <div className="form-group text-right px-1">
                              <Checkbox
                                checked={showLakta}
                                onChange={(e) =>
                                  this.setState({ showLakta: e.checked })
                                }
                              ></Checkbox>
                            </div>
                          </div>
                        ) : null}
                        {/* //////////meeee */}

                        {searchTypeAllProperties ||
                        (this.props.aIkar !== undefined &&
                          this.props.aIkar === true) ? (
                          <div className="form-group text-right px-3">
                            <label
                              htmlFor="agency_name"
                              className="label_narrow"
                            >
                              العقارات المحذوفة:
                            </label>
                            <div className="form-group text-right px-1">
                              <Checkbox
                                checked={show_deleted_properties}
                                onChange={(e) =>
                                  this.setState({
                                    show_deleted_properties: e.checked,
                                  })
                                }
                              ></Checkbox>
                            </div>
                          </div>
                        ) : null}

                        {/* /////me */}
                      </div>
                    </React.Fragment>
                  ) : null}
                </div>
                <div className="col-md-2">
                  <div className="text-center py-1 my-1">
                    <button
                      // to={{
                      //   pathname: this.props.destination,
                      //   // query: { search_mode: 'min' }
                      //   search: this.state.searchUrl
                      // }}
                      className={classnames(
                        "btn",
                        "px-0",
                        { "submit-search-button": this.state.largeScreen },
                        {
                          "submit-search-button-min": !this.state.largeScreen,
                        }
                      )}
                      onClick={this.onSearchButtonSubmit}
                    >
                      <span>ابحث{` `}</span>
                      {/* {this.state.largeScreen ? <span>ابحث{` `}</span> : null} */}

                      <span>
                        <FontAwesomeIcon icon={["fas", "search"]} />
                      </span>
                    </button>
                    <button
                      className="btn mt-md-1 mx-1 submit-button"
                      onClick={this.handleSizeClick}
                      style={{ width: "80px" }}
                    >
                      فلاتر{` `}
                      {this.state.searchBarFullShow ? (
                        <FontAwesomeIcon
                          icon={["fas", "toggle-on"]}
                          size="xs"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={["fas", "toggle-off"]}
                          size="xs"
                        />
                      )}
                    </button>
                    <br />
                    {largeScreen ? <FacebookButton /> : null}

                    {/* <button
                    className="btn submit-button mt-1"
                    onClick={this.onDefaultSearchClick}
                    // style={{ width: '120px' }}
                  >
                    بحث جديد{` `}
                    <FontAwesomeIcon icon={['fas', 'undo']} />
                  </button> */}
                  </div>
                </div>
                {this.props.searchMode !== "off" ? (
                  <div
                    className="input-group mx-lg-5 mb-1 pr-lg-5 mx-3 mt-lg-0 mt-1"
                    // style={{ borderTop: '1px #fcb814 solid' }}
                  >
                    {/* <div className="form-group text-right px-1"> */}

                    <label
                      htmlFor="order_by"
                      className="pt-2"
                      style={{ color: "#581f18" }}
                    >
                      الترتيب:
                    </label>
                    <select
                      name="order_by"
                      className={classnames(
                        "form-control",
                        "search-page-order-style",
                        {
                          "is-invalid": errors.order_by,
                        }
                      )}
                      onChange={this.onOrderChange}
                      value={order_by}
                      // style={{
                      //   width: '200px',
                      //   maxWidth: '-moz-max-content',
                      //   color: '#581f18'
                      // }}
                    >
                      <option value="ترتيب تلقائي">ترتيب تلقائي</option>
                      <option value="من الأقدم إلى الأحدث">
                        من الأقدم إلى الأحدث
                      </option>
                      <option value="من الأحدث إلى الأقدم">
                        من الأحدث إلى الأقدم
                      </option>
                      <option value="من الأرخص إلى الأغلى">
                        من الأرخص إلى الأغلى
                      </option>
                      <option value="من الأغلى إلى الأرخص">
                        من الأغلى إلى الأرخص
                      </option>
                      {this.props.isLoggedIn ? (
                        <React.Fragment>
                          <option value="من الأكثر مشاهدات">
                            من الأكثر مشاهدات
                          </option>
                          <option value="من الأقل مشاهدات">
                            من الأقل مشاهدات
                          </option>
                          {this.props.ikarAdmin ? (
                            <React.Fragment>
                              <option value="من الأكثر اتصالات">
                                من الأكثر اتصالات
                              </option>
                              <option value="من الأقل اتصالات">
                                من الأقل اتصالات
                              </option>
                            </React.Fragment>
                          ) : null}
                        </React.Fragment>
                      ) : null}
                    </select>
                    {/* </div> */}
                  </div>
                ) : null}
              </div>
            </form>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

SearchBar.propTypes = {
  doSearch: PropTypes.func.isRequired,
  searchParams: PropTypes.object.isRequired,
  isTakingAgencyRole: PropTypes.bool.isRequired,
  //takenAgencyRoleToken: PropTypes.string.isRequired
  getCategories: PropTypes.func.isRequired,
  getCategories2: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  searchParams: state.search.searchParams,
  // short_id6: state.search.short_id6,
  ikarAdmin: state.auth.ikarAdmin,
  isLoggedIn: state.auth.isLoggedIn,
  isTakingAgencyRole: state.auth.isTakingAgencyRole,
  takenAgencyRoleToken: state.auth.takenAgencyRoleToken,
  takenAgencyRoleAgencyRefId: state.auth.takenAgencyRoleAgencyRefId,
});

export default compose(
  withRouter,
  connect(mapStateToProps, { doSearch, getCategories, getCategories2 })
)(SearchBar);
