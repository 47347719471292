import React, { Component } from 'react';
import Header from './components/layout/header';
// import Loader from './components/layout/loader';
import Navigation from './components/layout/navigation';
import Home from './pages/home';
import Footer from './components/layout/footer';
import { Growl } from 'primereact/growl';
import GA from './mainConf/GoogleAnalytics';
import DownloadApp from './pages/DownloadApp';
import { Route, Router, Switch, Redirect } from 'react-router-dom';
//import { browserHistory } from 'react-router';
import { ResReady, ResNotComplete } from './components/layout/resTypes';
import {
  userIsAuthenticated,
  adminIsAuthenticated,
  userIsNotAuthenticated,
  IsAuthenticated,
} from './helper/auth';

import About from './pages/About';
import Privacy from './pages/Privacy';
//meeee
import FAQ from './pages/FAQ';
import JoinUs from './pages/JoinUs';
//import faqtest from "./pages/FAQ";
import { Provider } from 'react-redux';
import NotFound from './pages/NotFound';
import Login from './components/auth/Login';
import Confirmation from './components/auth/Confirmation';
import ResetPassword from './components/auth/ResetPassword';
import EditPRIVACY from './components/privacy/EditPrivacy';
// import SendNotification from './components/notification/sendnotification';
import PasswordRecovery from './components/auth/passwordRecovery';
import store from './store';
import AddProperty from './components/properties/AddProperty';
import Gallery from './components/properties/gallery';

// import ShowPropertiesPerFirstCategory from './categories/ShowPropertiesPerFirstCategory';
import ShowPropertiesPerTwoCategories from './categories/ShowPropertiesPerTwoCategories';
import ShowPropertiesPerThreeCategories from './categories/ShowPropertiesPerThreeCategories';
// import Properties from './components/properties/Properties';
// import Users from './components/users/Users';
import UserProfile from './components/users/UserProfile';
import AgencyProfile from './components/agencies/AgencyProfile';
import AdminAgencyProfile from './components/agencies/AdminAgencyProfile';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import jQuery from 'jquery';
import './assets/css/custom.scss';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'react-image-lightbox/style.css';
import AdminDashBoard from './components/dashBoard/AdminDashBoard';
import DashBoard from './components/dashBoard/DashBoard';
import ShowProperty from './components/properties/ShowProperty';
import SendNotification from './components/notification/sendnotification';
import Search from './components/search/Search';
import ReactResizeDetector from 'react-resize-detector';
import MainPageLatest from './components/homePages/MainPageLatest';
import MainPageMostViewed from './components/homePages/MainPageMostViewed';
import MainPageSpecialOffer from './components/homePages/MainPageSpecialOffer';
import MainPagePerProvince from './components/homePages/MainPagePerProvince';

import EditAgency from './components/agencies/EditAgency';
import { createBrowserHistory } from 'history';
import newMessage from './pages/newMessage';
import FloatingButton from './components/layout/floatingButton';
const history = createBrowserHistory();

class App extends Component {
  constructor() {
    super();
    this.mainContainerRef = React.createRef();
    this.state = {
      inRoot: false,
      searchNewBarShow: true,
      LinkTo: 'default',
      resType: '',
      isPath: false,
    };
  }

  setMainContainerDivHeight = () => {
    jQuery(function ($) {
      var $mainContainerDiv = $('#main_container_outer');
      var $mainContainer = $('.main_container');
      if ($mainContainer.length > 0) {
        var mainContainerHeight = $mainContainer.height();
        $mainContainerDiv.css({ height: mainContainerHeight });
      }
    });
  };
  handleScroll = () => {
    if (this.mainContainerRef) {
      this.mainContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  clearSearch = () => {
    localStorage.removeItem('I_price_min');
    localStorage.removeItem('I_price_max');
    localStorage.removeItem('I_total_area_min');
    localStorage.removeItem('I_total_area_max');

    localStorage.removeItem('I_area');
    localStorage.removeItem('I_basement');
    localStorage.removeItem('I_bathrooms');
    localStorage.removeItem('I_bedrooms');
    localStorage.removeItem('I_business_offer');
    localStorage.removeItem('I_city');
    localStorage.removeItem('I_duplex');
    localStorage.removeItem('I_floor');
    localStorage.removeItem('I_furnitured');
    localStorage.removeItem('I_garage');
    localStorage.removeItem('I_lift');
    localStorage.removeItem('I_parking');
    localStorage.removeItem('I_province');
    localStorage.removeItem('I_random');
    localStorage.removeItem('I_type');
    localStorage.removeItem('I_type');
    localStorage.removeItem('I_view_direction');
    localStorage.removeItem('ikar_main_type_param');
    localStorage.removeItem('ikar_business_offer_param');
    localStorage.removeItem('I_page_number');
  };
  componentDidMount() {
    // if(window.location.pathname.split('/')[0])
    if (
      window.location.pathname === '/' ||
      window.location.pathname === '/search' ||
      window.location.pathname === '/search?search_mode=full'
    ) {
      if (window.location.pathname === '/') {
        //this.clearSearch();
      }
      this.setState({ inRoot: true });
    } else {
      this.setState({ inRoot: false });
      //this.clearSearch();
    }
    if (window.location.pathname === '/') {
      this.setState({ searchNewBarShow: true });
    } else {
      this.setState({ searchNewBarShow: false });
    }
  }
  handlePathChange = (path) => {
    this.handleScroll();
    if (
      // path === '/' ||
      path === '/search' ||
      path === '/search?search_mode=full'
    ) {
    } else {
    }
    if (path === '/') {
      this.clearSearch();
      this.setState({ searchNewBarShow: true });
    } else {
      this.setState({ searchNewBarShow: false });
    }
  };
  onShowMessage = (msg) => {
    this.growl.show({
      severity: 'success',
      summary: msg,
    });
  };
  onChangeResType = (resType) => {
    this.setState({ resType });
  };
  render() {
    const { resType } = this.state;
    // const className =
    //   this.state.isPath === true ? "main_container2" : "main_container";
    return (
      <Provider store={store}>
        <Router history={history}>
          {/* <Router> */}
          <FloatingButton/>
          <div style={{ overflowX: 'hidden' }}>
            {/* <Loader /> */}

            <Navigation
              onPathChange={this.handlePathChange}
              showMessage={this.onShowMessage}
              onSetResType={this.onChangeResType}
            />
            <Growl ref={(gMain) => (this.growl = gMain)} />
            <Header
              handleScroll={this.handleScroll}
              searchNewBarShow={this.state.searchNewBarShow}
              onPathChange={this.handlePathChange}
            />
            <div ref={this.mainContainerRef}></div>
            {/* {this.state.inRoot ? (
              <SearchBar handleScroll={this.handleScroll} />
            ) : null} */}

            <div id="main_container_outer" className="container">
              <div className="mx-auto  main_container">
                <ReactResizeDetector
                  handleWidth
                  handleHeight
                  onResize={this.setMainContainerDivHeight}
                />

                <div className="bg-light p-3 main_container_inner">
                  {resType === 'complete' ? (
                    <React.Fragment>
                      {GA.init() && <GA.RouteTracker />}
                      <Switch>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/search" component={Search} />
                        <Route
                          path="/properties/add"
                          component={userIsAuthenticated(AddProperty)}
                        />
                         
                        <Route
                          path="/properties/add"
                          component={userIsAuthenticated(AddProperty)}
                        />
                        <Route
                          exact
                          path="/newmessage/:id"
                          component={newMessage}
                        />

                        {/* <Route
                        exact
                        path="/properties/edit/:id"
                        component={IsAuthenticated(EditProperty)}
                      /> */}
                        <Route
                          exact
                          path="/profile/:id"
                          component={IsAuthenticated(UserProfile)}
                        />

                        <Route
                          exact
                          path="/admin_agency_profile/:agency_reference_id"
                          component={IsAuthenticated(AdminAgencyProfile)}
                        />
                        <Route
                          exact
                          path="/agency_profile/:agency_reference_id"
                          component={AgencyProfile}
                        />

                        <Route
                          exact
                          path="/properties/:id"
                          component={ShowProperty}
                        />
                        <Route
                          exact
                          path="/properties/:id/gallery"
                          component={Gallery}
                        />
                        <Route
                          exact
                          path="/dashBoard/:to"
                          component={userIsAuthenticated(DashBoard)}
                        />
                        <Route
                          exact
                          path="/adminDashBoard/:to"
                          component={adminIsAuthenticated(AdminDashBoard)}
                        />
                        <Route
                          exact
                          path="/adminDashBoard/editagency/:id"
                          component={adminIsAuthenticated(EditAgency)}
                        />

                        <Route
                          exact
                          path="/adminDashBoard/edit_privacy/:id"
                          component={adminIsAuthenticated(EditPRIVACY)}
                        />
                        <Route
                          exact
                          path="/sendnotification"
                          component={adminIsAuthenticated(SendNotification)}
                        />
                         <Route exact path="/about" component={About} />
                          <Route exact path="/privacy" component={Privacy} />
                        {/* meeeeee */}
                        <Route exact path="/faq" component={FAQ} />

                        <Route exact path="/joins_us" component={JoinUs} />

                        <Route
                          exact
                          path="/downloadapp"
                          component={DownloadApp}
                        />

                        <Route
                          exact
                          path="/dashBoard/edit_faq/:id"
                          component={NotFound}
                        />

                        {/* <Route exact path="/faqtest" component={faqtest} /> */}

                        {/* <Route exact path="/addFAQ" component={addFAQ} />
                        <Route exact path="/editFAQ" component={editFAQ} />
                        <Route
                          exact
                          path="/showFAQByAgency"
                          component={showFAQByAgency}
                        /> */}
                        <Route
                          exact
                          path="/أحدث-العروض"
                          component={MainPageLatest}
                        />
                        <Route
                          exact
                          path="/العروض-الأكثر-مشاهدة"
                          component={MainPageMostViewed}
                        />
                        <Route
                          exact
                          path="/العروض-المغرية"
                          component={MainPageSpecialOffer}
                        />
                        <Route
                          exact
                          path="/properties_per_province/:currentProvince"
                          component={MainPagePerProvince}
                        />
                        <Route
                          exact
                          path="/login"
                          component={userIsNotAuthenticated(Login)}
                        />
                        <Route
                          exact
                          path="/users/confirmation"
                          component={Confirmation}
                        />
                        <Route
                          exact
                          path="/users/password/edit"
                          component={userIsNotAuthenticated(ResetPassword)}
                        />
                        <Route
                          exact
                          path="/passwordrecovery"
                          component={userIsNotAuthenticated(PasswordRecovery)}
                        />
                        <Route
                        // exact
                          path="/:PId/:PTId/:BOId"
                          component={ShowPropertiesPerThreeCategories}
                        />
                        <Route
                        // exact
                          path="/:PTId/:BOId"
                          component={ShowPropertiesPerTwoCategories}
                        />
                        {/* <Redirect to="/" /> */}
                        <Route component={NotFound} />
                      </Switch>
                    </React.Fragment>
                  ) : resType === 'not complete' ? (
                    <ResNotComplete></ResNotComplete>
                  ) : resType === 'ready' ? (
                    <ResReady></ResReady>
                  ) : (
                    <div>
                      <br />
                      <br />
                      <br />
                      <br />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <Footer onPathChange={this.handlePathChange} />
          </div>
        </Router>
      </Provider>
    );
  }
}
library.add(fab, fas, far);

export default App;
