import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import {
  getContactMessages,
  agencyDeleteContactMessages,
} from './../../actions/contactActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { doSearch } from './../../actions/searchActions';
import {
  updateProperty,
  deleteProperty,
} from './../../actions/propertyActionsUD';
import { Link } from 'react-router-dom';
import { loginProcess } from './../../actions/authActions';
import SearchBar from './../search/SearchBar.jsx';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Growl } from 'primereact/growl';
import defaultBackground from './../../assets/images/defaultBackground.png';
import { imageDomain, linkDomain } from './../../mainConf';
import Lightbox from 'react-image-lightbox';
import 'react-confirm-alert/src/react-confirm-alert.css';
import PaginationForProperties from './../layout/Pagination/PaginationForProperties';
import { Helmet } from 'react-helmet';
// import { ResReady, ResNotComplete } from './../layout/resTypes';
class ShowPropertiesWithContactsByAgency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: '',
      pageDescription: '',
      properties: [],
      selectedAgency: {},
      requestSuccessMessage: '',
      deleteSuccessMessage: '',
      agencies: [],
      updatedReferenceId: '',
      updatedMessageReferenceId: '',
      showCards: false,
      showMainPhoto: false,
      bgURL: '',
      total_properties: -1,
      // pageNumber: 1,
      openDialog: false,
      contactMessages: [],
      selectedContactMessage: {},
      resType: '',
      locationSearch: '',
      largeScreen: '',
    };
  }
  resize() {
    let currentWidth = window.innerWidth;
    if (currentWidth >= 768) {
      this.setState({ largeScreen: 't' });
    } else {
      this.setState({ largeScreen: 'f' });
    }
  }
  async componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.locationSearch !== this.state.locationSearch) {
      await this.setState({ locationSearch: nextProps.locationSearch });
    }
    if (
      nextProps.resType !== undefined &&
      nextProps.resType !== this.state.resType
    ) {
      const { resType } = nextProps;
      this.setState({ resType });
    }
    if (
      nextProps.requestSuccessMessage !== this.state.requestSuccessMessage &&
      this.state.requestSuccessMessage !== ''
    ) {
      var result2 = [];
      this.setState({
        requestSuccessMessage: nextProps.requestSuccessMessage,
      });

      switch (nextProps.requestSuccessMessage) {
        case 'اظهار العقار المحدد':
          this.growl.show({
            severity: 'success',
            summary: 'تم اظهار العقار المحدد',
            // detail: 'Order submitted'
          });
          this.state.properties.forEach((obj, index) => {
            result2.push(obj);
            if (obj.reference_id === this.state.updatedReferenceId) {
              result2[index].visible = !obj.visible;
            }
          });
          this.setState({ properties: result2 });
          break;
        case 'اخفاء العقار المحدد':
          this.growl.show({
            severity: 'success',
            summary: 'تم اخفار العقار المحدد',
          });
          this.state.properties.forEach((obj, index) => {
            result2.push(obj);
            if (obj.reference_id === this.state.updatedReferenceId) {
              result2[index].visible = !obj.visible;
            }
          });
          this.setState({ properties: result2 });
          break;

        case 'حذف العقار المحدد':
          this.growl.show({
            severity: 'success',
            summary: 'تم حذف العقار المحدد',
          });
          this.state.properties.forEach((obj, index) => {
            result2.push(obj);
            if (obj.reference_id === this.state.updatedReferenceId) {
              result2[index].business_status = 'مؤرشف';
            }
          });
          // this.state.properties.forEach((obj, index) => {
          //   if (obj.reference_id !== this.state.updatedReferenceId) {
          //     result2.push(obj);
          //   }
          // });
          this.setState({ properties: result2 });
          break;
        case 'استرجاع العقار المحدد':
          this.growl.show({
            severity: 'success',
            summary: 'تم استرجاع العقار المحدد',
          });
          this.state.properties.forEach((obj, index) => {
            result2.push(obj);
            if (obj.reference_id === this.state.updatedReferenceId) {
              result2[index].business_status = 'جديد';
            }
          });
          // this.state.properties.forEach((obj, index) => {
          //   if (obj.reference_id !== this.state.updatedReferenceId) {
          //     result2.push(obj);
          //   }
          // });
          this.setState({ properties: result2 });
          break;
        case 'اظهار العقار المحدد في أول نتائج البحث':
          this.growl.show({
            severity: 'success',
            summary: 'تم اظهار العقار المحدد في أول نتائج البحث',
            detail: 'عند تحديث الصفحة يظهر الترتيب الجديد للعقارات',
          });
          this.state.properties.forEach((obj, index) => {
            result2.push(obj);
            if (obj.reference_id === this.state.updatedReferenceId) {
              result2[index].rank = 1;
            }
          });
          this.setState({ properties: result2 });
          break;
        default:
          break;
      }
    } else if (
      nextProps.deleteSuccessMessage !== this.state.deleteSuccessMessage &&
      this.state.deleteSuccessMessage !== ''
    ) {
      var result22 = [];
      var messages = [];
      this.setState({
        deleteSuccessMessage: nextProps.deleteSuccessMessage,
      });

      switch (nextProps.deleteSuccessMessage) {
        case 'حذف الرسالة المحددة':
          this.growl.show({
            severity: 'success',
            summary: 'تم حذف الرسالة المحددة',
          });
          this.state.contactMessages.forEach((obj, index) => {
            if (obj.reference_id !== this.state.updatedMessageReferenceId) {
              messages.push(obj);
            }
          });
          this.state.properties.forEach((obj, index) => {
            result22.push(obj);
            if (obj.reference_id === this.state.updatedReferenceId) {
              result22[index].num_contacts = result22[index].num_contacts - 1;
            }
          });
          this.setState({ contactMessages: messages, properties: result22 });
          break;

        default:
          break;
      }
    } else if (
      nextProps.properties !== this.state.properties &&
      nextProps.properties[0] !== undefined
    ) {
      const { properties } = nextProps;
      this.setState({
        properties,
        total_properties: properties[0].total_properties,
      });
      // if (nextProps.pageNumber === 1) {
      //   this.setState({
      //     pageNumber: nextProps.pageNumber
      //   });
      // }
    } else if (nextProps.contactMessages !== this.state.contactMessages) {
      const { contactMessages } = nextProps;
      this.setState({
        contactMessages,
      });
    }
    // if (
    //   nextProps.properties[0] === undefined ||
    //   nextProps.properties.length === 0
    // )
    else {
      this.setState({
        properties: [],
        total_properties: 0,
      });
    }
  }
  getContactM = () => {
    // const isTakingAgencyRole = this.props.isTakingAgencyRole;
    // const takenAgencyRoleAgencyRefId = this.props.takenAgencyRoleAgencyRefId;
    // var agency_ref_id = '';
    // if (isTakingAgencyRole) {
    //   agency_ref_id = takenAgencyRoleAgencyRefId;
    // } else {
    //   agency_ref_id = localStorage.getItem('ikar_agency_ref_id');
    // }
    // var property = {};
    // // property.visible = true;
    // property.agency_ref_id = agency_ref_id;
    // property.ranges = {};
    // property.ranges.num_contacts = {};
    // property.ranges.num_contacts.min = 1;
    // property.ranges.num_contacts.max = 50000;
    // const takenAgencyRoleToken = this.props.takenAgencyRoleToken;
    // this.props.doSearch(
    //   property,
    //   recordsPerPage,
    //   1,
    //   'agency_search',
    //   isTakingAgencyRole,
    //   takenAgencyRoleToken
    // );
  };
  componentDidMount() {
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
  }
  onDeleteProperty = async (reference_id, deleteStatus, e) => {
    e.preventDefault();
    var processType = '';
    var property = {};
    // await this.props.deleteProperty(
    //   reference_id,
    //   isTakingAgencyRole,
    //   takenAgencyRoleToken,
    //   processType
    // );
    switch (deleteStatus) {
      case 'set_archived':
        if (!window.confirm('حذف العقار المحدد ؟')) {
          return;
        }
        property = {
          reference_id,
          business_status: 'مؤرشف',
        };
        processType = 'حذف العقار المحدد';
        break;
      case 'remove_archived':
        if (!window.confirm('استرجاع العقار المحدد ؟')) {
          return;
        }
        property = {
          reference_id,
          business_status: 'جديد',
        };
        processType = 'استرجاع العقار المحدد';
        break;
      default:
        break;
    }

    await this.setState({
      requestSuccessMessage: 'ready',
      updatedReferenceId: reference_id,
    });
    const isTakingAgencyRole = this.props.isTakingAgencyRole;
    const takenAgencyRoleToken = this.props.takenAgencyRoleToken;

    const propertyDate = { property };
    await this.props.updateProperty(
      propertyDate,
      isTakingAgencyRole,
      takenAgencyRoleToken,
      processType
    );
  };
  onEditProperty = async (reference_id, visibleStatus, e) => {
    if (!window.confirm('تعديل العقار المحدد ؟')) {
      e.preventDefault();
      return;
    }
    // confirmAlert({
    //   title: 'تعديل العقار المحدد ؟',
    //   message: 'لتعديل العقار اضغط متابعة',
    //   buttons: [
    //     {
    //       label: 'متابعة'
    //       //onClick: () => {}
    //     },
    //     {
    //       label: 'تراجع',
    //       onClick: () => {
    //         e.preventDefault();
    //         return;
    //       }
    //     }
    //   ]
    // });
  };
  onChangeVisibleStatus = async (reference_id, visibleStatus, e) => {
    e.preventDefault();

    var property = {};
    var processType = '';
    switch (visibleStatus) {
      case 'set_visible':
        if (!window.confirm('اظهار العقار المحدد ؟')) {
          return;
        }
        property = {
          reference_id,
          visible: true,
        };
        processType = 'اظهار العقار المحدد';
        break;
      case 'remove_visible':
        if (!window.confirm('اخفاء العقار المحدد ؟')) {
          return;
        }
        property = {
          reference_id,
          visible: false,
        };
        processType = 'اخفاء العقار المحدد';
        break;
      default:
        break;
    }
    await this.setState({
      requestSuccessMessage: 'ready',
      updatedReferenceId: reference_id,
    });
    const propertyDate = { property };
    const isTakingAgencyRole = false;
    // const takenAgencyRoleAgencyRefId = this.props.takenAgencyRoleAgencyRefId;
    const takenAgencyRoleToken = '';
    await this.props.updateProperty(
      propertyDate,
      isTakingAgencyRole,
      takenAgencyRoleToken,
      processType
    );
  };
  showPriceTemplate = (rowData, column) => {
    if (!rowData.price_upon_review) {
      return (
        <React.Fragment>
          <span>
            {rowData.price}
            {` `}
          </span>
          <span className="text-warning">{rowData.pricing_type}</span>
        </React.Fragment>
      );
    } else {
      return <span>{`عند المراجعة`}</span>;
    }
  };
  provinceAreaTemplate = (rowData, column) => {
    return (
      <React.Fragment>
        <span>{rowData.province}</span>
        {(rowData.area!==null && rowData.area!==undefined && rowData.area!=='غير محدد')?` - `:''}
        {(rowData.area!==null && rowData.area!==undefined && rowData.area!=='غير محدد')?<span className="text-warning">{rowData.area}</span>:''}
      </React.Fragment>
    );
  };
  showAgencyTemplate = (rowData, column) => {
    return (
      <Link
        to={`/agency_profile/${rowData.agency_ref_id}`}
        className="mx-auto text-success font-weight-bold"
      >
        {rowData.agency_name}
      </Link>
    );
  };
  showPhotoTemplate = (rowData, column) => {
    var bgURL = '';
    // var thumbnails = '';

    if (
      rowData.thumbnails === null ||
      rowData.thumbnails === undefined ||
      rowData.thumbnails.length === 0
    ) {
      bgURL = defaultBackground;
      // thumbnails = defaultBackground;
    } else {
      bgURL = `${imageDomain}/${rowData.thumbnails[0]}`;
      // thumbnails = `${imageDomain}/${rowData.thumbnails[0]}`;
    }

    // const { showMainPhoto } = this.state;
    return (
      <React.Fragment>
        <img
          ////////src={thumbnails}
          src={bgURL}
          alt=""
          style={{ width: '100px', height: '50px' }}
          onClick={() =>
            this.setState({
              showMainPhoto: true,
              bgURL,
            })
          }
        />
      </React.Fragment>
    );
  };
  onContactClick = async (reference_id, e) => {
    e.preventDefault();
    const contact_message = {};
    contact_message.property_ref_id = reference_id;
    const contactMessageData = { contact_message };
    await this.setState({
      //openDialog: true,
      contactMessages: [],
      selectedContactMessage: {},
    });

    const isTakingAgencyRole = this.props.isTakingAgencyRole;
    const takenAgencyRoleToken = this.props.takenAgencyRoleToken;
    let token = '';
    if (isTakingAgencyRole) {
      token = takenAgencyRoleToken;
    } else {
      token = localStorage.getItem('ikar_token');
    }
    this.props.getContactMessages(contactMessageData, token);
  };

  showNewContactsTemplate = (rowData, column) => {
    // return(
    //   <newMessage/>
    // )

    if (rowData.unread_contacts > 0) {
      return (
        <a
          href={`${linkDomain}/newMessage/${rowData.reference_id}?title=${rowData.title}&province=${rowData.province}&area=${rowData.area}`}
          className="mx-auto text-warning"
        >
          <Button
            //icon="pi pi-check"
            // icon="fa fa-mail-bulk"
            //iconPos="right"
            label={` ${rowData.unread_contacts} `}
            className="p-button-success p-function"
            tooltip="رسائل الطلبات للعقار"
            tooltipOptions={{
              position: 'bottom',
              event: 'hover',
            }}

            // onClick={this.onContactClick.bind(this, rowData.reference_id)}
          >
            <FontAwesomeIcon icon={['fas', 'mail-bulk']}></FontAwesomeIcon>
          </Button>
        </a>
      );
    } else {
      return <span className="text-warning">لا يوجد</span>;
    }
  };

  showContactsTemplate = (rowData, column) => {
    if (rowData.num_contacts > 0) {
      return (
        <a
          href={`${linkDomain}/newMessage/${rowData.reference_id}?title=${rowData.title}&province=${rowData.province}&area=${rowData.area}`}
          className="mx-auto text-warning"
        >
          <Button
            //icon="pi pi-check"
            // icon="fa fa-mail-bulk"
            //iconPos="right"
            label={` ${rowData.num_contacts} `}
            className="p-button-success mx-0 px-1"
            tooltip="رسائل الطلبات للعقار"
            tooltipOptions={{
              position: 'bottom',
              event: 'hover',
            }}
            // onClick={this.onContactClick.bind(this, rowData.reference_id)}
          >
            <FontAwesomeIcon icon={['fas', 'mail-bulk']}></FontAwesomeIcon>
          </Button>
        </a>
      );
    } else {
      return <span className="text-warning">لا يوجد</span>;
    }
  };
  onDeleteContactMessage = async (reference_id, property_ref_id, e) => {
    e.preventDefault();
    if (!window.confirm('حذف الرسالة المحددة ؟')) {
      return;
    }

    var processType = 'حذف الرسالة المحددة';

    await this.setState({
      deleteSuccessMessage: 'ready',
      updatedReferenceId: property_ref_id,
      updatedMessageReferenceId: reference_id,
    });

    const isTakingAgencyRole = this.props.isTakingAgencyRole;
    const takenAgencyRoleToken = this.props.takenAgencyRoleToken;
    let token = '';
    if (isTakingAgencyRole) {
      token = takenAgencyRoleToken;
    } else {
      token = localStorage.getItem('ikar_token');
    }
    this.props.agencyDeleteContactMessages(reference_id, token, processType);
  };
  showContactDetailsTemplate = (rowData, column) => {
    return (
      <div>
        <span className="tooltipicon_message">
          <Button
            // icon="fa fa-times"
            //iconPos="right"
            className="p-button-danger btn_process"
            // tooltip="حذف الرسالة"
            // tooltipOptions={{
            //   position: "bottom",
            //   event: "hover"
            // }}
            style={{ border: '1px solid #9F9F9F' }}
            onClick={this.onDeleteContactMessage.bind(
              this,
              rowData.reference_id,
              rowData.property_ref_id
            )}
          >
            <FontAwesomeIcon icon={['fas', 'times']}></FontAwesomeIcon>
          </Button>
          <span className="tooltiptext_message">
            <h1 style={{ fontSize: 15 }}>حذف الرسالة</h1>
          </span>
        </span>
        <span className="tooltipicon_message1">
          {/* <Link to={`/properties/${rowData.reference_id}`}> */}
          <a href={`${linkDomain}/properties/${rowData.reference_id}`}>
            <Button
              // icon="fa fa-building"
              //iconPos="right"
              className="p-button-secondary btn_process"
              // tooltip="تفاصيل العقار"
              // tooltipOptions={{
              //   position: "bottom",
              //   event: "hover"
              // }}
              style={{ border: '1px solid #9F9F9F' }}
            >
              <FontAwesomeIcon icon={['fas', 'building']}></FontAwesomeIcon>
            </Button>
            {/* </Link> */}
          </a>
          <span className="tooltiptext_message1">
            <h1 style={{ fontSize: 15 }}>عرض العقار</h1>
          </span>
        </span>
      </div>
    );
  };
  showContactDetailsTemplate1 = (rowData, column) => {
    if (column.header === 'الموبايل' && rowData.mobile != null) {
      return (
        <a
          href={`tel:${rowData.mobile}`}
          // onClick="ga('send', 'event', { eventCategory: 'Contact', eventAction: 'Call', eventLabel: 'Mobile Button'});"
          style={{ color: '#007ad9' }}
        >
          {rowData.mobile.replace('+', '')}+
        </a>
      );
    } else if (column.header === 'واتساب' && rowData.whatsapp != null) {
      return (
        <a
          href={`tel:${rowData.whatsapp}`}
          // onClick="ga('send', 'event', { eventCategory: 'Contact', eventAction: 'Call', eventLabel: 'Mobile Button'});"
          style={{ color: '#007ad9' }}
        >
          {rowData.whatsapp.replace('+', '')}+
        </a>
      );
    } else if (column.header === 'تيليغرام' && rowData.telegram != null) {
      return (
        <a
          href={`tel:${rowData.telegram}`}
          // onClick="ga('send', 'event', { eventCategory: 'Contact', eventAction: 'Call', eventLabel: 'Mobile Button'});"
          style={{ color: '#007ad9' }}
        >
          {rowData.telegram.replace('+', '')}+
        </a>
      );
    }
  };
  showPropertyTitleTemplate = (rowData, column) => {
    return (
      // <Link
      //   to={`/properties/${rowData.reference_id}`}
      //   className="mx-auto text-warning"
      // >
      <a
        href={`${linkDomain}/properties/${rowData.reference_id}`}
        className="mx-auto text-warning"
      >
        {rowData.title}
        {/* </Link> */}
      </a>
    );
  };
  showDetailsTemplate = (rowData, column) => {
    return (
      <React.Fragment>
        {/* <div className="row mx-1" >
          <div className="col-6 text-center px-0"> */}
        {rowData.visible ? (
          <Button
            //icon="pi pi-check"
            // icon="fa fa-eye"
            //iconPos="right"
            className="p-button-success btn_process"
            tooltip="العقار ظاهر"
            tooltipOptions={{
              position: 'bottom',
              event: 'hover',
            }}
            onClick={this.onChangeVisibleStatus.bind(
              this,
              rowData.reference_id,
              'remove_visible'
            )}
            disabled={rowData.business_status === 'مؤرشف'}
          >
            <FontAwesomeIcon icon={['fas', 'eye']}></FontAwesomeIcon>
          </Button>
        ) : (
          <Button
            // icon="fa fa-eye"
            //iconPos="right"
            className="p-button-danger btn_process"
            tooltip="العقار مخفي"
            tooltipOptions={{
              position: 'bottom',
              event: 'hover',
            }}
            onClick={this.onChangeVisibleStatus.bind(
              this,
              rowData.reference_id,
              'set_visible'
            )}
            disabled={rowData.business_status === 'مؤرشف'}
          >
            <FontAwesomeIcon icon={['fas', 'eye']}></FontAwesomeIcon>
          </Button>
        )}

        {/* <Link
          to={
            rowData.business_status === 'مؤرشف'
              ? `#!`
              : `/properties/edit/${rowData.reference_id}`
          }
        >
          <Button
            icon="fa fa-edit"
            //iconPos="right"
            className="p-button-warning btn_process"
            tooltip="تعديل العقار"
            tooltipOptions={{
              position: 'bottom',
              event: 'hover'
            }}
            onClick={this.onEditProperty.bind(
              this,
              rowData.reference_id,
              'edit'
            )}
            disabled={rowData.business_status === 'مؤرشف'}
          />
        </Link>

        {rowData.business_status !== 'مؤرشف' ? (
          <Button
            icon="fa fa-times"
            //iconPos="right"
            className="p-button-warning btn_process"
            tooltip="حذف العقار"
            tooltipOptions={{
              position: 'bottom',
              event: 'hover'
            }}
            onClick={this.onDeleteProperty.bind(
              this,
              rowData.reference_id,
              'remove_archived'
            )}
          />
        ) : (
          <Button
            icon="fa fa-times"
            //iconPos="right"
            className="p-button-danger btn_process"
            tooltip="العقار مؤرشف"
            tooltipOptions={{
              position: 'bottom',
              event: 'hover'
            }}
            onClick={this.onDeleteProperty.bind(
              this,
              rowData.reference_id,
              'delete'
            )}
          />
        )} */}
        {/* <Link to={`/properties/${rowData.reference_id}`}> */}
        <a href={`${linkDomain}/properties/${rowData.reference_id}`}>
          <Button
            // icon="fa fa-building"
            //iconPos="right"
            className="p-button-secondary btn_process"
            tooltip="تفاصيل العقار"
            tooltipOptions={{
              position: 'bottom',
              event: 'hover',
            }}
            style={{ border: '1px solid #9F9F9F' }}
          >
            <FontAwesomeIcon icon={['fas', 'building']}></FontAwesomeIcon>
          </Button>
          {/* </Link> */}
        </a>
      </React.Fragment>
    );
  };
  export = () => {
    this.dt.exportCSV();
  };
  render() {

    // const { isLoggedIn, ikarAdmin, isTakingAgencyRole } = this.props;
    const {
      properties,
      showCards,
      showMainPhoto,
      bgURL,
      total_properties,
      // pageNumber,
      openDialog,
      contactMessages,
      selectedContactMessage,
      largeScreen,
      // resType
    } = this.state;
    var contactHeader = (
      <span className="display_5">الرسائل الواردة للعقار المحدد</span>
    );
    var propertyHeader = (
      <div className="px-2 py-0" style={{ textAlign: 'right' }}>
        <Button
          type="button"
          icon="pi pi-external-link"
          iconPos="left"
          label="تصدير الجدول الى ملف اكسل CSV"
          onClick={this.export}
        ></Button>
      </div>
    );
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.pageTitle}</title>
          <meta name="description" content={this.state.pageDescription} />
          <meta name="robots" content="noindex" />
        </Helmet>
        {largeScreen === 't' ? (
          <SearchBar
            searchBarFullShow={false}
            searchType="agency_contact_messages"
            destination="/dashBoard/properties_contacts"
          ></SearchBar>
        ) : null}

        <div className="mb-3 mt-2 text-center">
          <span className="main_title">رسائل المكتب</span>
        </div>
        <div className="property-container">
          <div className="container-fluid">
            <Growl ref={(el) => (this.growl = el)} />
            {showCards ? null : (
              <div
                className="table_container  mt-1"
                //style={{ direction: 'rtl' }}
              >
                {/* <div className="table-responsive"> */}
                <DataTable
                  ref={(el) => {
                    this.dt = el;
                  }}
                  value={properties}
                  responsive={true}
                  //header="المكاتب العقارية"
                  selectionMode="single"
                  selection={this.state.selectedProperty}
                  onSelectionChange={(e) => {
                    this.setState({ selectedProperty: e.value });
                  }}
                  header={propertyHeader}
                >
                  <Column
                    header="الصورة"
                    className="table_td"
                    body={this.showPhotoTemplate}
                    style={{ width: '105px' }}
                  />
                  <Column
                    header="الاسم"
                    className="table_td"
                    body={this.showPropertyTitleTemplate}
                  />
                  <Column
                    field="business_offer"
                    header="نوع العرض"
                    className="table_td"
                    sortable={true}
                  />

                  <Column
                    header="العنوان"
                    className="table_td"
                    body={this.provinceAreaTemplate}
                  />
                  <Column
                    className="table_td"
                    header="السعر"
                    body={this.showPriceTemplate}
                  />
                  {/* <Column
                    header="المكتب"
                    className="table_td"
                    body={this.showAgencyTemplate}
                  /> */}

                  <Column
                    field="views"
                    header="عدد الزيارات"
                    className="table_td"
                    sortable={true}
                  />
                  <Column
                    //field="num_contacts"
                    header=" الرسائل الكلية"
                    className="table_td"
                    sortable={true}
                    body={this.showContactsTemplate}
                  />

                  <Column
                    //field="num_contacts"
                    header="الرسائل الجديدة"
                    className="table_td"
                    sortable={true}
                    body={this.showNewContactsTemplate}
                  />
                  <Column
                    field="rank"
                    header="المرتبة"
                    className="table_td"
                    sortable={true}
                    style={{ width: '60px' }}
                  />
                  <Column
                    body={this.showDetailsTemplate}
                    //header="الاجراءات"
                    style={{ width: '110px' }}
                    className="table_td table-functions"
                  />
                </DataTable>
                {showMainPhoto && (
                  <Lightbox
                    mainSrc={bgURL}
                    nextSrc={bgURL}
                    prevSrc={bgURL}
                    onCloseRequest={() =>
                      this.setState({ showMainPhoto: false })
                    }
                    onMovePrevRequest={
                      () => {
                        return;
                      }
                      // this.setState({
                      //   showMainPhoto: (showMainPhoto + url.length - 1) % url.length
                      // })
                    }
                    onMoveNextRequest={
                      () => {
                        return;
                      }
                      // this.setState({
                      //   photoIndex: (showMainPhoto + 1) % url.length
                      // })
                    }
                  />
                )}
                <Dialog
                  header="الطلبات المستلمه للعقار المحدد"
                  visible={openDialog}
                  style={{ width: '90vw', minHeight: '70vh' }}
                  modal={true}
                  onHide={() => this.setState({ openDialog: false })}
                >
                  <div className="contact-table table_container row justify-content-around align-items-center mt-1">
                    <DataTable
                      scrollable={true}
                      scrollHeight="300px"
                      value={contactMessages}
                      responsive={true}
                      header={contactHeader}
                      //selectionMode="single"
                      selection={selectedContactMessage}
                      onSelectionChange={(e) => {
                        this.setState({ selectedProperty: e.value });
                      }}
                    >
                      <Column
                        header="الاسم"
                        className="table_td"
                        field="name"
                        sortable={true}
                      />
                      <Column
                        field="contact_email"
                        header="البريد"
                        className="table_td"
                      />
                      {/*<Column
                      field="address"
                      header="العنوان"
                      className="table_td"
                      sortable={true}
                    /> */}

                      <Column
                        //  field="mobile"
                        // phone
                        body={this.showContactDetailsTemplate1}
                        header="الموبايل"
                        className="table_td"
                      />
                      {/* <Column
                      className="table_td"
                      header="الهاتف"
                      field="phone"
                    /> */}
                      <Column
                        // field="whatsapp"
                        body={this.showContactDetailsTemplate1}
                        header="واتساب"
                        className="table_td"
                      />
                      <Column
                        // field="telegram"
                        body={this.showContactDetailsTemplate1}
                        header="تيليغرام"
                        className="table_td"
                      />
                      <Column
                        field="message"
                        header="الرسالة"
                        className="table_td"
                      />
                      <Column
                        body={this.showContactDetailsTemplate}
                        //header="الاجراءات"
                        style={{ width: '250px' }}
                        className="table_td table-functions"
                      />
                    </DataTable>
                  </div>
                </Dialog>
              </div>
            )}
            <PaginationForProperties
              resultLabel="عقار"
              recordsNumber={parseInt(total_properties)}
              // pageNumber={pageNumber}
              // onPageChange={this.onSearchButtonSubmit}
              showMessage={true}
              destination="/dashBoard/properties_contacts"
              locationSearch={this.state.locationSearch}
            ></PaginationForProperties>
          </div>
        </div>
        {largeScreen === 'f' ? (
          <SearchBar
            searchBarFullShow={false}
            searchType="agency_contact_messages"
            destination="/dashBoard/properties_contacts"
          ></SearchBar>
        ) : null}
      </React.Fragment>
    );
  }
}

ShowPropertiesWithContactsByAgency.propTypes = {
  properties: PropTypes.array.isRequired,
  contactMessages: PropTypes.array.isRequired,
  doSearch: PropTypes.func.isRequired,
  getContactMessages: PropTypes.func.isRequired,
  agencyDeleteContactMessages: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  ikarAdmin: PropTypes.bool.isRequired,
  isTakingAgencyRole: PropTypes.bool.isRequired,
  // takenAgencyRoleToken: PropTypes.string.isRequired,
  updateProperty: PropTypes.func.isRequired,
  // takenAgencyRoleAgencyRefId: PropTypes.string.isRequired,
  requestSuccessMessage: PropTypes.string.isRequired,
  deleteSuccessMessage: PropTypes.string.isRequired,
  // pageNumber: PropTypes.number.isRequired
};

const mapStateToProps = (state) => ({
  properties: state.search.properties,
  isLoggedIn: state.auth.isLoggedIn,
  ikarAdmin: state.auth.ikarAdmin,
  isTakingAgencyRole: state.auth.isTakingAgencyRole,
  takenAgencyRoleToken: state.auth.takenAgencyRoleToken,
  requestSuccessMessage: state.propertyUD.requestSuccessMessage,
  deleteSuccessMessage: state.contact.deleteSuccessMessage,
  takenAgencyRoleAgencyRefId: state.auth.takenAgencyRoleAgencyRefId,
  short_id: state.propertyUD.short_id,
  short_id2: state.propertyUD.short_id2,
  contactMessages: state.contact.contact_messages,
  // pageNumber: state.search.pageNumber,
  resType: state.search.resType,
});

export default connect(mapStateToProps, {
  doSearch,
  loginProcess,
  getContactMessages,
  agencyDeleteContactMessages,
  updateProperty,
  deleteProperty,
})(ShowPropertiesWithContactsByAgency);
