import {
  DO_SEARCH,
  DO_AGENCY_SEARCH,
  DO_AGENCY_PUBLIC_SEARCH,
  DO_USER_SEARCH,
  GET_PROPERTIES_FOR_RENT,
  GET_PROPERTIES,
  GET_PROPERTIES_BY_AGENCY,
  GET_PROPERTIES_BY_USER,
  GET_PROPERTIES_FOR_SALE,
  GET_PROPERTIES_LATEST,
  GET_PROPERTIES_MOST_VIEWED,
  GET_PROPERTIES_PER_PROVINCE,
  GET_PROPERTIES_SPECIAL_OFFER,
  // ,
  // GET_USERS
} from './types';
import axios from 'axios';
import { mainDomain } from './../mainConf';

// export const getProperties = () => async dispatch => {
//   ///const res=await axios.get('');
//   const res = {};
//   dispatch({
//     type: GET_PROPERTIES,
//     payload: res.data
//   });
// };
export const doSearch = (
  searchParameters,
  recordsPerPage,
  pageNumber,
  searchType,
  isTakingAgencyRole,
  takenAgencyRoleToken,
  mmm
) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };

  if (Object.keys(searchParameters).length === 0) {
    searchParameters.ranges = {};
    searchParameters.ranges.total_area = {};
    //searchParameters.ranges.total_area.min = 'null';
    searchParameters.ranges.total_area.min = '0';
    searchParameters.ranges.total_area.max = '150000';
  }
  var token = '';

  if (searchType === 'admin_search') {
    token = localStorage.getItem('ikar_token');
  } else if (searchType === 'agency_search') {
    if (isTakingAgencyRole) {
      token = takenAgencyRoleToken;
    } else {
      token = localStorage.getItem('ikar_token');
    }
  }
  if (searchType === 'admin_search' || searchType === 'agency_search') {
    await axios
      .post(
        `${mainDomain}/v1/properties/search?per_page=${recordsPerPage}&page=${pageNumber}`,
        searchParameters,
        // ,
        // {
        //   withCredentials: true
        // }
        {
          headers: {
            //'Content-Type': 'application/json',
            Accept: 'application/json',
            //Accept: 'application/json',
            Authorization: token,
          },
        }
      )
      .then(function (response) {
        if (
          response !== null &&
          response !== undefined &&
          Object.keys(response).length > 0
        ) {
          res = response.data;
          res.resType = 'complete';
          res.searchParameters = searchParameters;
          res.pageNumber = pageNumber;

          if (response.headers.total !== undefined && res[0] !== undefined) {
            res[0].total_properties = response.headers.total;
          }
        } else res = {};
      })
      .catch(function (error) {
        if (error.response) {
          errorRes = {
            errorStatus: error.response.status,
            errorMessage: error.response,
          };
        } else if (error.request) {
          errorRes = {
            errorMessage: error.request,
          };
        } else {
          errorRes = { errorMessage: error.message };
        }
        res = errorRes;
        res.resType = 'not complete';
      });
  } else {
    await axios
      .post(
        `${mainDomain}/v1/properties/search?per_page=${recordsPerPage}&page=${pageNumber}`,
        searchParameters
        // ,
        // {
        //   withCredentials: true
        // }
      )
      .then(function (response) {
        if (
          response !== null &&
          response !== undefined &&
          Object.keys(response).length > 0
        ) {
          res = response.data;
          res.resType = 'complete';
          res.searchParameters = searchParameters;
          res.pageNumber = pageNumber;
          if (response.headers.total !== undefined && res[0] !== undefined) {
            res[0].total_properties = response.headers.total;
          }
        } else res = {};
      })
      .catch(function (error) {
        if (error.response) {
          errorRes = {
            errorStatus: error.response.status,
            errorMessage: error.response,
          };
        } else if (error.request) {
          errorRes = {
            errorMessage: error.request,
          };
        } else {
          errorRes = { errorMessage: error.message };
        }
        res = errorRes;
        res.resType = 'not complete';
      })
     
  }
  dispatch({
    type: DO_SEARCH,
    payload: res,
  });
 
};

export const getPropertiesLatest = (
  searchParameters,
  recordsPerPage,
  pageNumber
) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };

  if (Object.keys(searchParameters).length === 0) {
    searchParameters.ranges = {};
    searchParameters.ranges.total_area = {};
    //searchParameters.ranges.total_area.min = 'null';
    searchParameters.ranges.total_area.min = '0';
    searchParameters.ranges.total_area.max = '150000';
  }
  await axios
    .post(
      `${mainDomain}/v1/properties/search?per_page=${recordsPerPage}&page=${pageNumber}`,
      searchParameters
      // ,
      // {
      //   withCredentials: true
      // }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
        res.searchParameters = searchParameters;
        res.pageNumber = pageNumber;
        if (response.headers.total !== undefined && res[0] !== undefined) {
          res[0].total_properties = response.headers.total;
        }
      } else res = {};
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
    });
  dispatch({
    type: GET_PROPERTIES_LATEST,
    payload: res,
  });
};

export const getPropertiesMostViewed = (
  searchParameters,
  recordsPerPage,
  pageNumber
) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };

  if (Object.keys(searchParameters).length === 0) {
    searchParameters.ranges = {};
    searchParameters.ranges.total_area = {};
    //searchParameters.ranges.total_area.min = 'null';
    searchParameters.ranges.total_area.min = '0';
    searchParameters.ranges.total_area.max = '150000';
  }
  await axios
    .post(
      `${mainDomain}/v1/properties/search?per_page=${recordsPerPage}&page=${pageNumber}`,
      searchParameters
      // ,
      // {
      //   withCredentials: true
      // }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
        res.searchParameters = searchParameters;
        res.pageNumber = pageNumber;
        if (response.headers.total !== undefined && res[0] !== undefined) {
          res[0].total_properties = response.headers.total;
        }
      } else res = {};
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
    });
  dispatch({
    type: GET_PROPERTIES_MOST_VIEWED,
    payload: res,
  });
};

export const getPropertiesPerProvince = (
  searchParameters,
  recordsPerPage,
  pageNumber,
  currentProvince
) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };

  if (Object.keys(searchParameters).length === 0) {
    searchParameters.ranges = {};
    searchParameters.ranges.total_area = {};
    //searchParameters.ranges.total_area.min = 'null';
    searchParameters.ranges.total_area.min = '0';
    searchParameters.ranges.total_area.max = '150000';
  }
  await axios
    .post(
      `${mainDomain}/v1/properties/search?per_page=${recordsPerPage}&page=${pageNumber}`,
      searchParameters
      // ,
      // {
      //   withCredentials: true
      // }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
        res.searchParameters = searchParameters;
        res.currentProvince = currentProvince;
        res.pageNumber = pageNumber;
        if (response.headers.total !== undefined && res[0] !== undefined) {
          res[0].total_properties = response.headers.total;
        }
      } else res = {};
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
    });
  dispatch({
    type: GET_PROPERTIES_PER_PROVINCE,
    payload: res,
  });
};

export const getPropertiesSpecialOffer = (
  searchParameters,
  recordsPerPage,
  pageNumber
) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };

  if (Object.keys(searchParameters).length === 0) {
    searchParameters.ranges = {};
    searchParameters.ranges.total_area = {};
    //searchParameters.ranges.total_area.min = 'null';
    searchParameters.ranges.total_area.min = '0';
    searchParameters.ranges.total_area.max = '150000';
  }
  await axios
    .post(
      `${mainDomain}/v1/properties/search?per_page=${recordsPerPage}&page=${pageNumber}`,
      searchParameters
      // ,
      // {
      //   withCredentials: true
      // }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
        res.searchParameters = searchParameters;
        res.pageNumber = pageNumber;
        if (response.headers.total !== undefined && res[0] !== undefined) {
          res[0].total_properties = response.headers.total;
        }
      } else res = {};
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
    });
  dispatch({
    type: GET_PROPERTIES_SPECIAL_OFFER,
    payload: res,
  });
};

export const doAgencySearch = (
  searchParameters,
  recordsPerPage,
  pageNumber
) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  if (Object.keys(searchParameters.agency).length === 0) {
    searchParameters.agency.ranges = {};
    searchParameters.agency.ranges.count_properties = {};
    searchParameters.agency.ranges.count_properties.min = '0';
    searchParameters.agency.ranges.count_properties.max = '150000';
  }
  await axios
    .post(
      `${mainDomain}/v1/admin/agencies/search?per_page=${recordsPerPage}&page=${pageNumber}`,
      searchParameters,
      // {
      //   withCredentials: true
      // }
      // ,
      {
        headers: {
          Accept: 'application/json',
          Authorization: localStorage.getItem('ikar_token'),
        },
      }
      // ,
      // {
      //   headers: {
      //     Accept: 'application/json',
      //     Authorization: localStorage.getItem('ikar_token')
      //   }
      // }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
        res.pageNumber = pageNumber;
        if (response.headers.total !== undefined && res[0] !== undefined) {
          res[0].total_agencies = response.headers.total;
          res.resType = 'complete';
        }
      } else res = {};
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
      res.resType = 'not complete';
    });
  dispatch({
    type: DO_AGENCY_SEARCH,
    payload: res,
  });
};

export const doAgencyPublicSearch = (
  searchParameters,
  recordsPerPage,
  pageNumber
) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  if (Object.keys(searchParameters.agency).length === 0) {
    searchParameters.agency.ranges = {};
    searchParameters.agency.ranges.count_properties = {};
    searchParameters.agency.ranges.count_properties.min = '0';
    searchParameters.agency.ranges.count_properties.max = '150000';
  }
  await axios
    .post(
      `${mainDomain}/v1/admin/agencies/search?per_page=${recordsPerPage}&page=${pageNumber}`,
      searchParameters
      // ,
      // {
      //   withCredentials: true
      // }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
        res.pageNumber = pageNumber;
        if (response.headers.total !== undefined && res[0] !== undefined) {
          res[0].total_agencies = response.headers.total;
          res.resType = 'complete';
        }
      } else res = {};
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
      res.resType = 'not complete';
    });
  dispatch({
    type: DO_AGENCY_PUBLIC_SEARCH,
    payload: res,
  });
};

export const doUserSearch = (
  searchParameters,
  recordsPerPage,
  pageNumber
) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };

  if (Object.keys(searchParameters).length === 0) {
    searchParameters.id = 0;
    // searchParameters.ranges.total_area = {};
    // searchParameters.ranges.total_area.min = 'null';
    // searchParameters.ranges.total_area.min = '0';
    // searchParameters.ranges.total_area.max = '150000';
  }
  await axios
    .post(
      `${mainDomain}/v1/admin/users/search?per_page=${recordsPerPage}&page=${pageNumber}`,
      searchParameters,
      // ,
      // {
      //   withCredentials: true
      // }
      {
        headers: {
          Accept: 'application/json',
          Authorization: localStorage.getItem('ikar_token'),
        },
      }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
        res.pageNumber = pageNumber;
        if (response.headers.total !== undefined && res[0] !== undefined) {
          res[0].total_users = response.headers.total;
        }
      } else res = {};
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
    });
  dispatch({
    type: DO_USER_SEARCH,
    payload: res,
  });
};

export const getProperties = (recordsPerPage, pageNumber) => async (
  dispatch
) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };

  await axios
    .get(
      `${mainDomain}/v1/properties?per_page=${recordsPerPage}&page=${pageNumber}`
      // ,
      // {
      //   withCredentials: true
      // }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
        res.pageNumber = pageNumber;
        if (response.headers.total !== undefined && res[0] !== undefined) {
          res[0].total_properties = response.headers.total;
        }
      } else res = {};
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
    });
  dispatch({
    type: GET_PROPERTIES,
    payload: res,
  });
};

// export const getAgencies = (recordsPerPage, pageNumber) => async dispatch => {
//   let res = {};
//   let errorRes = {
//     errorStatus: '',
//     errorMessage: ''
//   };

//   await axios
//     .get(
//       `${mainDomain}/v1/admin/agencies?per_page=${recordsPerPage}&page=${pageNumber}`,
//       {
//         headers: {
//           Accept: 'application/json',
//           Authorization: localStorage.getItem('ikar_token')
//         }
//       }
//     )
//     .then(function(response) {
//       if (
//         response !== null &&
//         response !== undefined &&
//         Object.keys(response).length > 0
//       ) {
//         res = response.data;
//         res.pageNumber = pageNumber;
//         if (response.headers.total !== undefined && res[0] !== undefined) {
//           res[0].total_agencies = response.headers.total;
//         }
//       } else res = {};
//     })
//     .catch(function(error) {
//       if (error.response) {
//         errorRes = {
//           errorStatus: error.response.status,
//           errorMessage: error.response
//         };
//       } else if (error.request) {
//         errorRes = {
//           errorMessage: error.request
//         };
//       } else {
//         errorRes = { errorMessage: error.message };
//       }
//       res = errorRes;
//     });
//   dispatch({
//     type: GET_AGENCIES,
//     payload: res
//   });
// };

// export const getUsers = (recordsPerPage, pageNumber) => async dispatch => {
//   let res = {};
//   let errorRes = {
//     errorStatus: '',
//     errorMessage: ''
//   };
//   await axios
//     .get(
//       `${mainDomain}/v1/admin/users?per_page=${recordsPerPage}&page=${pageNumber}`,
//       {
//         headers: {
//           Accept: 'application/json',
//           Authorization: localStorage.getItem('ikar_token')
//         }
//       }
//     )
//     .then(function(response) {
//       if (
//         response !== null &&
//         response !== undefined &&
//         Object.keys(response).length > 0
//       ) {
//         res = response.data;
//         res.pageNumber = pageNumber;
//         if (
//           response.headers.total !== undefined &&
//           res.users[0] !== undefined
//         ) {
//           res.users[0].total_users = response.headers.total;
//         }
//       } else res = {};
//     })
//     .catch(function(error) {
//       if (error.response) {
//         errorRes = {
//           errorStatus: error.response.status,
//           errorMessage: error.response
//         };
//       } else if (error.request) {
//         errorRes = {
//           errorMessage: error.request
//         };
//       } else {
//         errorRes = { errorMessage: error.message };
//       }
//       res = errorRes;
//     });
//   dispatch({
//     type: GET_USERS,
//     payload: res
//   });
// };

export const getPropertiesByAgency = (
  isTakingAgencyRole,
  takenAgencyRoleAgencyRefId,
  recordsPerPage,
  pageNumber
) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  var agency_ref_id = '';
  if (isTakingAgencyRole) {
    //////todo
    agency_ref_id = takenAgencyRoleAgencyRefId;
  } else {
    agency_ref_id = localStorage.getItem('ikar_agency_ref_id');
  }
  await axios
    // .get(
    //   `${mainDomain}/v1/properties/search?property[agency_ref_id]=${agency_ref_id}`
    // )
    .post(
      `${mainDomain}/v1/properties/search?per_page=${recordsPerPage}&page=${pageNumber}`,
      {
        agency_ref_id: agency_ref_id,
      }
      // ,
      // {
      //   withCredentials: true
      // }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
        res.pageNumber = pageNumber;
        if (response.headers.total !== undefined && res[0] !== undefined) {
          res[0].total_properties = response.headers.total;
        }
      } else {
        res = {};
      }
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = {
          errorMessage: error.message,
        };
      }
      res = errorRes;
    });
  dispatch({
    type: GET_PROPERTIES_BY_AGENCY,
    payload: res,
  });
};

export const getPropertiesByUser = (
  isTakingAgencyRole,
  takenAgencyRoleEmail,
  recordsPerPage,
  pageNumber
) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  var agent_ref_id = '';
  if (isTakingAgencyRole) {
    //////todo
    agent_ref_id = takenAgencyRoleEmail;
  } else {
    agent_ref_id = localStorage.getItem('ikar_email');
  }
  await axios
    // .get(
    //   `${mainDomain}/v1/properties/search?property[agent_ref_id]=${agent_ref_id}`
    // )
    .post(
      `${mainDomain}/v1/properties/search?per_page=${recordsPerPage}&page=${pageNumber}`,
      {
        agent_ref_id: agent_ref_id,
      }
      // ,
      // {
      //   withCredentials: true
      // }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
        res.pageNumber = pageNumber;
        if (response.headers.total !== undefined && res[0] !== undefined) {
          res[0].total_properties = response.headers.total;
        }
      } else {
        res = {};
      }
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = {
          errorMessage: error.message,
        };
      }
      res = errorRes;
    });
  dispatch({
    type: GET_PROPERTIES_BY_USER,
    payload: res,
  });
};

export const getPropertiesForRent = (recordsPerPage, pageNumber) => async (
  dispatch
) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };

  await axios
    // .get(`${mainDomain}/v1/properties/search?property[business_offer]=تأجير`)

    .post(
      `${mainDomain}/v1/properties/search?per_page=${recordsPerPage}&page=${pageNumber}`,
      {
        business_offer: 'تأجير',
        visible: true,
      }
      // ,
      // {
      //   withCredentials: true
      // }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
        res.pageNumber = pageNumber;
        if (response.headers.total !== undefined && res[0] !== undefined) {
          res[0].total_properties = response.headers.total;
        }
      } else res = {};
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
    });
  dispatch({
    type: GET_PROPERTIES_FOR_RENT,
    payload: res,
  });
};

export const getPropertiesForSale = (recordsPerPage, pageNumber) => async (
  dispatch
) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  await axios
    // .get(`${mainDomain}/v1/properties/search?property[business_offer]=تأجير`)
    .post(
      `${mainDomain}/v1/properties/search?per_page=${recordsPerPage}&page=${pageNumber}`,
      {
        business_offer: 'بيع',
        visible: true,
      }
      // ,
      // {
      //   withCredentials: true
      // }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
        res.pageNumber = pageNumber;
        if (response.headers.total !== undefined && res[0] !== undefined) {
          res[0].total_properties = response.headers.total;
        }
      } else res = {};
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
    });
  dispatch({
    type: GET_PROPERTIES_FOR_SALE,
    payload: res,
  });
};
