import {
  UPDATE_AGENCY,
  UPDATE_AGENCY_RANK,
  BLOCK_AGENCY,
  UPDATE_AGENCY_EMAIL,
  UPDATE_AGENCY_PASSWORD,
  ACTIVE_AGENCY
} from '../actions/types';
import shortid from 'shortid';
const initialState = {
  agencies: [],
  agency: {},
  requestFailed: false,
  requestFailedMessage: {},
  requestSuccessMessage: '',
  short_id: ''
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_AGENCY:
      if (
        (Object.keys(action.payload).length > 0 &&
          action.payload.errorMessage === '') ||
        action.payload.errorMessage === undefined
      ) {
        action.payload.processStatus = 'edit is succeeded';

        return {
          //...state,
          agency: action.payload,
          //add state data
          agencies: [],
          requestFailed: false,
          requestFailedMessage: { error: 'no' },
          requestSuccessMessage: action.payload.message,
          short_id: shortid.generate()
          //
        };
      } else {
        return {
          //...state,
          agency: [],
          requestFailed: true,
          requestFailedMessage: action.payload,
          //add state data
          agencies: [],
          requestSuccessMessage: 'error in edit agency',
          short_id: shortid.generate()
          //
        };
      }

    case UPDATE_AGENCY_RANK:
      if (
        (Object.keys(action.payload).length > 0 &&
          action.payload.errorMessage === '') ||
        action.payload.errorMessage === undefined
      ) {
        action.payload.processStatus = 'edit is succeeded';

        return {
          //...state,
          agency: action.payload,
          //add state data
          agencies: [],
          requestFailed: false,
          requestFailedMessage: { error: 'no' },
          requestSuccessMessage: action.payload.message,
          short_id: shortid.generate()
          //
        };
      } else {
        return {
          //...state,
          agency: [],
          requestFailed: true,
          requestFailedMessage: action.payload,
          //add state data
          agencies: [],
          requestSuccessMessage: '',
          short_id: shortid.generate()
          //
        };
      }

    case UPDATE_AGENCY_EMAIL:
      if (
        (Object.keys(action.payload).length > 0 &&
          action.payload.errorMessage === '') ||
        action.payload.errorMessage === undefined
      ) {
        action.payload.processStatus = 'edit email is succeeded';

        return {
          //...state,
          agency: action.payload,
          //add state data
          agencies: [],
          requestFailed: false,
          requestFailedMessage: { error: 'no' },
          requestSuccessMessage: action.payload.message,
          short_id: shortid.generate()
          //
        };
      } else {
        return {
          //...state,
          agency: [],
          requestFailed: true,
          requestFailedMessage: action.payload,
          //add state data
          agencies: [],
          requestSuccessMessage: 'error in edit agency',
          short_id: shortid.generate()
          //
        };
      }

    case UPDATE_AGENCY_PASSWORD:
      if (
        (Object.keys(action.payload).length > 0 &&
          action.payload.errorMessage === '') ||
        action.payload.errorMessage === undefined
      ) {
        action.payload.processStatus = 'edit is succeeded';

        return {
          //...state,
          agency: action.payload,
          //add state data
          agencies: [],
          requestFailed: false,
          requestFailedMessage: { error: 'no' },
          requestSuccessMessage: action.payload.message,
          short_id: shortid.generate()
          //
        };
      } else {
        return {
          //...state,
          agency: [],
          requestFailed: true,
          requestFailedMessage: action.payload,
          //add state data
          agencies: [],
          requestSuccessMessage: 'error in edit agency',
          short_id: shortid.generate()
          //
        };
      }
    case BLOCK_AGENCY:
      if (
        (Object.keys(action.payload).length > 0 &&
          action.payload.errorMessage === '') ||
        action.payload.errorMessage === undefined
      ) {
        action.payload.processStatus = 'edit is succeeded';

        return {
          //...state,
          agency: action.payload,
          //add state data
          agencies: [],
          requestFailed: false,
          requestFailedMessage: { error: 'no' },
          requestSuccessMessage: action.payload.message,
          short_id: shortid.generate()
          //
        };
      } else {
        return {
          //...state,
          agency: [],
          requestFailed: true,
          requestFailedMessage: action.payload,
          //add state data
          agencies: [],
          requestSuccessMessage: '',
          short_id: shortid.generate()
          //
        };
      }

    case ACTIVE_AGENCY:
      if (
        (Object.keys(action.payload).length > 0 &&
          action.payload.errorMessage === '') ||
        action.payload.errorMessage === undefined
      ) {
        // action.payload.processStatus = 'active is succeeded';

        return {
          //...state,
          agency: action.payload,
          //add state data
          agencies: [],
          requestFailed: false,
          requestFailedMessage: { error: 'no' },
          requestSuccessMessage: action.payload.message,
          short_id: shortid.generate()
          //
        };
      } else {
        return {
          //...state,
          agency: [],
          requestFailed: true,
          requestFailedMessage: action.payload,
          //add state data
          agencies: [],
          requestSuccessMessage: 'error in active agency',
          short_id: shortid.generate()
          //
        };
      }

    default:
      return state;
  }
}
