import React, { Component } from 'react';
import Property from './Property';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { getProperties } from './../../actions/searchActions';
import { Dialog } from 'primereact/dialog';
import InputMask from 'react-input-mask';
import classnames from 'classnames';
import {
  updateProperty,
  updatePropertyRank,
  updatePropertyLakta,
  deleteProperty,
} from './../../actions/propertyActionsUD';
import { Link } from 'react-router-dom';
import { loginProcess } from './../../actions/authActions';
import shortid from 'shortid';
import SearchBar from './../search/SearchBar.jsx';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Growl } from 'primereact/growl';
import defaultBackground from './../../assets/images/defaultBackground.png';
import { imageDomain, linkDomain } from './../../mainConf';
import Lightbox from 'react-image-lightbox';
import { doSearch } from './../../actions/searchActions';
import 'react-confirm-alert/src/react-confirm-alert.css';
import PaginationForProperties from './../layout/Pagination/PaginationForProperties';
// import { ResReady, ResNotComplete } from './../layout/resTypes';
// import { recordsPerPage } from './../../mainConf/index';
import { Checkbox } from 'primereact/checkbox';
import { Helmet } from 'react-helmet';
class Properties extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: '',
      pageDescription: '',
      title: '',
      price: '',
      pricing_type: '',
      price_upon_review: false,
      temp_status: '',
      days: 0,
      properties: [],
      selectedAgency: {},
      requestSuccessMessage: '',
      agencies: [],
      updatedReferenceId: '',
      updatedRank: '',
      updatedDays: '',
      updatedTempStatus: '',
      updatedPricingType: 'ل.س',
      updatedPrice: '',
      updatedPriceUponReview: false,
      showCards: false,
      showMainPhoto: false,
      bgURL: '',
      total_properties: -1,
      // pageNumber: 1,
      showLakta: false,
      newLaktaPrice: false,
      laktaPrice: '',
      laktaPricingType: 'ل.س',
      laktaDays: 2,
      laktaTempStatus: 1,
      laktaTempStatus_items: [
        [0, 'عادي'],
        [1, 'سعر لقطة'],
        [2, 'تجهيز ممتاز'],
        [3, 'موقع رائع'],
      ],
      errors: {},
      resType: '',
      locationSearch: '',
      largeScreen: false,
    };
  }
  resize() {
    let currentWidth = window.innerWidth;
    if (currentWidth >= 768) {
      this.setState({ largeScreen: 't' });
    } else {
      this.setState({ largeScreen: 'f' });
    }
  }
  componentDidMount() {
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
  }
  async componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.locationSearch !== this.state.locationSearch) {
      await this.setState({ locationSearch: nextProps.locationSearch });
    }
    if (
      nextProps.resType !== undefined &&
      nextProps.resType !== this.state.resType
    ) {
      const { resType } = nextProps;
      this.setState({ resType });
    }
    if (
      nextProps.requestSuccessMessage !== this.state.requestSuccessMessage &&
      this.state.requestSuccessMessage !== ''
    ) {
      var result2 = [];
      this.setState({
        requestSuccessMessage: nextProps.requestSuccessMessage,
      });

      switch (nextProps.requestSuccessMessage) {
        case 'اظهار العقار المحدد':
          this.growl.show({
            severity: 'success',
            summary: 'تم اظهار العقار المحدد',
            // detail: 'Order submitted'
          });
          this.state.properties.forEach((obj, index) => {
            result2.push(obj);
            if (obj.reference_id === this.state.updatedReferenceId) {
              result2[index].visible = !obj.visible;
            }
          });
          this.setState({ properties: result2 });
          break;
        case 'اخفاء العقار المحدد':
          this.growl.show({
            severity: 'success',
            summary: 'تم اخفار العقار المحدد',
          });
          this.state.properties.forEach((obj, index) => {
            result2.push(obj);
            if (obj.reference_id === this.state.updatedReferenceId) {
              result2[index].visible = !obj.visible;
            }
          });
          this.setState({ properties: result2 });
          break;

        case 'أرشفة العقار المحدد':
          this.growl.show({
            severity: 'success',
            summary: 'تم أرشفة العقار المحدد',
          });
          this.state.properties.forEach((obj, index) => {
            result2.push(obj);
            if (obj.reference_id === this.state.updatedReferenceId) {
              result2[index].business_status = 'مؤرشف';
            }
          });
          // this.state.properties.forEach((obj, index) => {
          //   if (obj.reference_id !== this.state.updatedReferenceId) {
          //     result2.push(obj);
          //   }
          // });
          this.setState({ properties: result2 });
          break;

        case 'استرجاع العقار المحدد':
          this.growl.show({
            severity: 'success',
            summary: 'تم استرجاع العقار المحدد',
          });
          this.state.properties.forEach((obj, index) => {
            result2.push(obj);
            if (obj.reference_id === this.state.updatedReferenceId) {
              result2[index].business_status = 'جديد';
            }
          });
          // this.state.properties.forEach((obj, index) => {
          //   if (obj.reference_id !== this.state.updatedReferenceId) {
          //     result2.push(obj);
          //   }
          // });
          this.setState({ properties: result2 });
          break;
        case 'تحديد مرتبة للعقار المحدد':
          this.growl.show({
            severity: 'success',
            summary: 'تم تحديد مرتبة للعقار',
            // detail: 'عند تحديث الصفحة يظهر الترتيب الجديد للعقارات'
          });
          this.state.properties.forEach((obj, index) => {
            result2.push(obj);
            if (obj.reference_id === this.state.updatedReferenceId) {
              result2[index].rank = this.state.updatedRank;
            }
          });
          this.setState({ properties: result2 });
          break;

        // case 'إخفاء العقار المحدد من النتائج الأولى للبحث':
        //   this.growl.show({
        //     severity: 'success',
        //     summary: 'تم إخفاء العقار المحدد من النتائج الأولى للبحث',
        //     detail: 'عند تحديث الصفحة يظهر الترتيب الجديد للعقارات'
        //   });
        //   this.state.properties.forEach((obj, index) => {
        //     result2.push(obj);
        //     if (obj.reference_id === this.state.updatedReferenceId) {
        //       result2[index].rank = 102;
        //     }
        //   });
        //   this.setState({ properties: result2 });
        //   break;

        case 'تحديد العقار كلقطة':
          this.growl.show({
            severity: 'success',
            summary: 'تم تحديد العقار كلقطة',
            // detail: 'Order submitted'
          });
          this.state.properties.forEach((obj, index) => {
            result2.push(obj);
            if (obj.reference_id === this.state.updatedReferenceId) {
              result2[index].days = this.state.updatedDays;
              result2[index].temp_status = this.state.updatedTempStatus;
              if (this.state.updatedPricingType !== '') {
                result2[index].pricing_type = this.state.updatedPricingType;
              }
              if (
                this.state.updatedPrice !== '' &&
                parseInt(this.state.updatedPrice) > 0
              ) {
                result2[index].price = this.state.updatedPrice;
              }
              if (
                obj.updatedPricingType === 'السعر عند المراجعة' ||
                this.state.updatedPriceUponReview
              ) {
                result2[index].price_upon_review = true;
              } else {
                result2[index].price_upon_review = false;
              }
            }
          });
          this.setState({ properties: result2 });
          break;

        case 'إزالة العقار كلقطة':
          this.growl.show({
            severity: 'success',
            summary: 'تم إزالة العقار المحدد كلقطة',
            // detail: 'Order submitted'
          });
          this.state.properties.forEach((obj, index) => {
            result2.push(obj);
            if (obj.reference_id === this.state.updatedReferenceId) {
              result2[index].temp_status = this.state.updatedTempStatus;
            }
          });
          this.setState({ properties: result2 });
          break;

        case 'حذف العقار المحدد':
          this.growl.show({
            severity: 'success',
            summary: 'تم حذف العقار المحدد',
            // detail: 'Order submitted'
          });
          this.state.properties.forEach((obj, index) => {
            if (obj.reference_id !== this.state.updatedReferenceId) {
              result2.push(obj);
            }
          });
          this.setState({ properties: result2 });
          break;

        default:
          break;
      }
    } else if (
      nextProps.properties !== this.state.properties &&
      nextProps.properties[0] !== undefined
    ) {
      const { properties } = nextProps;
      this.setState({
        properties,
        total_properties: properties[0].total_properties,
      });
      // if (nextProps.pageNumber === 1) {
      //   this.setState({
      //     pageNumber: nextProps.pageNumber
      //   });
      // }
    } else if (
      nextProps.properties[0] === undefined ||
      nextProps.properties.length === 0
    ) {
      this.setState({
        properties: [],
        total_properties: 0,
      });
    }
  }
  onDeleteProperty = async (reference_id, deleteStatus, e) => {
    e.preventDefault();
    var processType = '';

    if (!window.confirm('حذف العقار المحدد ؟')) {
      return;
    }
    processType = 'حذف العقار المحدد';

    await this.setState({
      requestSuccessMessage: 'ready',
      updatedReferenceId: reference_id,
    });
    const isTakingAgencyRole = this.props.isTakingAgencyRole;
    const takenAgencyRoleToken = this.props.takenAgencyRoleToken;

    await this.props.deleteProperty(
      reference_id,
      isTakingAgencyRole,
      takenAgencyRoleToken,
      processType
    );
  };
  onArchiveProperty = async (reference_id, deleteStatus, e) => {
    e.preventDefault();
    var processType = '';
    var property = {};
    // await this.props.deleteProperty(
    //   reference_id,
    //   isTakingAgencyRole,
    //   takenAgencyRoleToken,
    //   processType
    // );
    switch (deleteStatus) {
      case 'set_archived':
        if (!window.confirm('أرشفة العقار المحدد ؟')) {
          return;
        }
        property = {
          reference_id,
          business_status: 'مؤرشف',
        };
        processType = 'أرشفة العقار المحدد';
        break;
      case 'remove_archived':
        if (!window.confirm('استرجاع العقار المحدد ؟')) {
          return;
        }
        property = {
          reference_id,
          business_status: 'جديد',
        };
        processType = 'استرجاع العقار المحدد';
        break;
      default:
        break;
    }

    await this.setState({
      requestSuccessMessage: 'ready',
      updatedReferenceId: reference_id,
    });
    const isTakingAgencyRole = this.props.isTakingAgencyRole;
    const takenAgencyRoleToken = this.props.takenAgencyRoleToken;

    const propertyDate = { property };
    await this.props.updateProperty(
      propertyDate,
      isTakingAgencyRole,
      takenAgencyRoleToken,
      processType
    );
  };
  onEditProperty = async (reference_id, visibleStatus, e) => {
    if (!window.confirm('تعديل العقار المحدد ؟')) {
      e.preventDefault();
      return;
    }
  };
  onChangeVisibleStatus = async (reference_id, visibleStatus, e) => {
    e.preventDefault();

    var property = {};
    var processType = '';
    switch (visibleStatus) {
      case 'set_visible':
        if (!window.confirm('اظهار العقار المحدد ؟')) {
          return;
        }
        property = {
          reference_id,
          visible: true,
        };
        processType = 'اظهار العقار المحدد';
        break;
      case 'remove_visible':
        if (!window.confirm('اخفاء العقار المحدد ؟')) {
          return;
        }
        property = {
          reference_id,
          visible: false,
        };
        processType = 'اخفاء العقار المحدد';
        break;
      default:
        break;
    }
    await this.setState({
      requestSuccessMessage: 'ready',
      updatedReferenceId: reference_id,
    });
    const propertyDate = { property };
    const isTakingAgencyRole = false;
    // const takenAgencyRoleAgencyRefId = this.props.takenAgencyRoleAgencyRefId;
    const takenAgencyRoleToken = '';
    await this.props.updateProperty(
      propertyDate,
      isTakingAgencyRole,
      takenAgencyRoleToken,
      processType
    );
  };
  // onRankLast = async (reference_id, visibleStatus, e) => {
  //   e.preventDefault();

  //   var property = {};
  //   var processType = '';
  //   switch (visibleStatus) {
  //     case 'remove_rank':
  //       if (!window.confirm('إخفاء العقار المحدد من النتائج الأولى للبحث ؟')) {
  //         return;
  //       }
  //       property = {
  //         reference_id,
  //         rank: 102
  //       };
  //       processType = 'إخفاء العقار المحدد من النتائج الأولى للبحث';
  //       break;
  //     default:
  //       break;
  //   }

  //   await this.setState({
  //     requestSuccessMessage: 'ready',
  //     updatedReferenceId: reference_id
  //   });
  //   const propertyDate = { property };
  //   const isTakingAgencyRole = false;
  //   // const takenAgencyRoleAgencyRefId = this.props.takenAgencyRoleAgencyRefId;
  //   const takenAgencyRoleToken = '';
  //   await this.props.updateProperty(
  //     propertyDate,
  //     isTakingAgencyRole,
  //     takenAgencyRoleToken,
  //     processType
  //   );
  // };
  onChangePropertyRank = async (reference_id, currentRank, e) => {
    e.preventDefault();
    var newRank = prompt('يجب ادخال مرتبة عقار بين 0 و 10');
    if (newRank === null) {
      return;
    }
    if (/^\d+$/.test(newRank)) {
      if (parseInt(newRank) < 0 || parseInt(newRank) > 10) {
        alert('الرقم المدخل غير صحيح');
        return;
      } else if (parseInt(newRank) === parseInt(currentRank)) {
        alert('الرقم المدخل يساوي المرتبة الحالية للعقار');
        return;
      }
    } else {
      alert('الرقم المدخل غير صحيح');
      return;
    }
    var processType = '';
    var property = {};
    processType = 'تحديد مرتبة للعقار المحدد';
    property = {
      reference_id,
      rank: parseInt(newRank),
    };
    await this.setState({
      requestSuccessMessage: 'ready',
      updatedReferenceId: reference_id,
      updatedRank: parseInt(newRank),
    });
    const isTakingAgencyRole = this.props.isTakingAgencyRole;
    const takenAgencyRoleToken = this.props.takenAgencyRoleToken;

    const propertyDate = { property };
    await this.props.updatePropertyRank(
      propertyDate,
      isTakingAgencyRole,
      takenAgencyRoleToken,
      processType
    );
  };
  showPriceTemplate = (rowData, column) => {
    if (
      !rowData.price_upon_review &&
      rowData.pricing_type !== 'السعر عند المراجعة' &&
      rowData.pricing_type !== ''
    ) {
      return (
        <React.Fragment>
          <span>
            {rowData.price}
            {` `}
          </span>
          <span className="text-warning">{rowData.pricing_type}</span>
        </React.Fragment>
      );
    } else {
      return <span>{`عند المراجعة`}</span>;
    }
  };
  provinceAreaTemplate = (rowData, column) => {
    return (
      <React.Fragment>
        <span>{rowData.province}</span>
        {(rowData.area!==null && rowData.area!==undefined && rowData.area!=='غير محدد')?` - `:''}
        {(rowData.area!==null && rowData.area!==undefined && rowData.area!=='غير محدد')?<span className="text-warning">{rowData.area}</span>:''}
        
      </React.Fragment>
    );
  };

  showPhotoTemplate = (rowData, column) => {
    var bgURL = '';
    // var thumbnails = '';

    if (
      rowData.thumbnails === null ||
      rowData.thumbnails === undefined ||
      rowData.thumbnails.length === 0
    ) {
      bgURL = defaultBackground;
      // thumbnails = defaultBackground;
    } else {
      bgURL = `${imageDomain}/${rowData.thumbnails[0]}`;
      // thumbnails = `${imageDomain}/${rowData.thumbnails[0]}`;
    }
    // const { showMainPhoto } = this.state;
    return (
      <React.Fragment>
        <img
          ////////src={thumbnails}
          src={bgURL}
          alt=""
          style={{ width: '100px', height: '50px' }}
          onClick={() =>
            this.setState({
              showMainPhoto: true,
              bgURL,
            })
          }
        />
      </React.Fragment>
    );
  };
  addLakta = async (
    reference_id,
    title,
    price,
    pricing_type,
    price_upon_review,
    temp_status,
    days,
    e
  ) => {
    await this.setState({
      showLakta: true,
      updatedReferenceId: reference_id,
      title,
      price,
      pricing_type,
      price_upon_review,
      temp_status,
      days,
    });
  };
  onAddLaktaSubmit = async (e) => {
    e.preventDefault();
    const {
      laktaDays,
      // laktaTempStatus,
      newLaktaPrice,
      laktaPrice,
      laktaPricingType,
      price_upon_review,
    } = this.state;
    var errors = {};
    // if (laktaDays === '' || Number(laktaDays) === 0) {
    //   errors.laktaDays = 'الرجاء ادخال عدد الايام';
    // }

    var property = {};
    var processType = '';
    if (
      newLaktaPrice === true &&
      (Number(laktaPrice) === 0 || laktaPrice === '') &&
      laktaPricingType !== 'السعر عند المراجعة'
    ) {
      errors.laktaPrice = 'يجب ادخال السعر الجديد';
    }
    if (parseInt(laktaDays) === 0 || laktaDays === '') {
      errors.laktaDays = 'يجب ادخال عدد أيام اللقطة';
    }
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }
    if (!window.confirm('تحديد العقار كلقطة؟')) {
      return;
    }
    property = {
      reference_id: this.state.updatedReferenceId,
      days: Number(laktaDays),
      temp_status: 1,
    };

    if (newLaktaPrice === true && laktaPricingType === 'السعر عند المراجعة') {
      property.price_upon_review = true;
    } else if (
      newLaktaPrice === true &&
      (parseInt(laktaPrice) > 0) & (laktaPrice !== '') &&
      laktaPricingType !== 'السعر عند المراجعة'
    ) {
      property.price = laktaPrice;
      property.price_upon_review = false;
      property.pricing_type = laktaPricingType;
    }
    processType = 'تحديد العقار كلقطة';

    await this.setState({
      requestSuccessMessage: 'ready',
    });
    const propertyDate = { property };
    const isTakingAgencyRole = false;
    // const takenAgencyRoleAgencyRefId = this.props.takenAgencyRoleAgencyRefId;
    const takenAgencyRoleToken = '';
    // var laktaTempStatusName = '';
    // switch (laktaTempStatus) {
    //   case 0:
    //     laktaTempStatusName = 'عادي';
    //     break;
    //   case 1:
    //     laktaTempStatusName = 'سعر لقطة';
    //     break;
    //   case 2:
    //     laktaTempStatusName = 'تجهيز ممتاز';
    //     break;
    //   case 3:
    //     laktaTempStatusName = 'موقع رائع';
    //     break;
    //   default:
    //     break;
    // }
    await this.setState({
      showLakta: false,
      newLaktaPrice: false,
      laktaPrice: '',
      laktaDays: 2,
      laktaTempStatus: 1,
      errors: {},
      updatedDays: laktaDays,
      updatedTempStatus: 'سعر لقطة',
      updatedPriceUponReview:
        newLaktaPrice === false
          ? price_upon_review
          : newLaktaPrice === true && laktaPricingType === 'السعر عند المراجعة'
          ? true
          : newLaktaPrice === true && laktaPricingType !== 'السعر عند المراجعة'
          ? false
          : false,
      updatedPrice: laktaPrice,
      updatedPricingType: newLaktaPrice === true ? laktaPricingType : '',
    });
    await this.props.updatePropertyLakta(
      propertyDate,
      isTakingAgencyRole,
      takenAgencyRoleToken,
      processType
    );
  };
  onRemoveLaktaSubmit = async (e) => {
    e.preventDefault();
    var property = {};
    var processType = '';
    if (!window.confirm('إزالة العقار كلقطة؟')) {
      return;
    }
    property = {
      reference_id: this.state.updatedReferenceId,
      temp_status: 0,
    };

    processType = 'إزالة العقار كلقطة';

    await this.setState({
      requestSuccessMessage: 'ready',
    });
    const propertyDate = { property };
    const isTakingAgencyRole = false;
    const takenAgencyRoleToken = '';
    await this.setState({
      showLakta: false,
      newLaktaPrice: false,
      laktaPrice: '',
      laktaDays: 2,
      laktaTempStatus: 1,
      errors: {},
      updatedTempStatus: 'عادي',
    });
    await this.props.updatePropertyLakta(
      propertyDate,
      isTakingAgencyRole,
      takenAgencyRoleToken,
      processType
    );
  };

  showPropertyTitleTemplate = (rowData, column) => {
    return (
      // <Link
      //   to={`/properties/${rowData.reference_id}`}
      //   className="mx-auto text-warning"
      // >
      <a
        href={`${linkDomain}/properties/${rowData.reference_id}`}
        className="mx-auto text-warning"
      >
        {rowData.title}
      </a>
      //  </Link>
    );
  };
  showDetailsTemplate = (rowData, column) => {
    return (
      <React.Fragment>
        {/* <div className="row mx-1" >
          <div className="col-6 text-center px-0"> */}
        {rowData.visible ? (
          <Button
            //icon="pi pi-check"
            // icon="fa fa-eye"
            //iconPos="right"
            className="p-button-success btn_process"
            tooltip="العقار ظاهر"
            tooltipOptions={{
              position: 'bottom',
              event: 'hover',
            }}
            onClick={this.onChangeVisibleStatus.bind(
              this,
              rowData.reference_id,
              'remove_visible'
            )}
            disabled={rowData.business_status === 'مؤرشف'}
          >
            <FontAwesomeIcon icon={['fas', 'eye']}></FontAwesomeIcon>
          </Button>
        ) : (
          <Button
            // icon="fa fa-eye"
            //iconPos="right"
            className="p-button-danger btn_process"
            tooltip="العقار مخفي"
            tooltipOptions={{
              position: 'bottom',
              event: 'hover',
            }}
            onClick={this.onChangeVisibleStatus.bind(
              this,
              rowData.reference_id,
              'set_visible'
            )}
            disabled={rowData.business_status === 'مؤرشف'}
          >
            <FontAwesomeIcon icon={['fas', 'eye']}></FontAwesomeIcon>
          </Button>
        )}

        <Button
          // icon="fa fa-list-ol"
          //iconPos="right"
          className="p-button-warning btn_process"
          tooltip="تحديد رتبة العقار"
          tooltipOptions={{
            position: 'bottom',
            event: 'hover',
          }}
          onClick={this.onChangePropertyRank.bind(
            this,
            rowData.reference_id,
            rowData.rank
          )}
          disabled={rowData.business_status === 'مؤرشف'}
        >
          <FontAwesomeIcon icon={['fas', 'list-ol']}></FontAwesomeIcon>
        </Button>
        {/* <Button
          icon="fa fa-angle-double-down"
          //iconPos="right"
          className="p-button-warning btn_process"
          tooltip="أخفاء العقار من النتائج الأولى للبحث"
          tooltipOptions={{
            position: 'bottom',
            event: 'hover'
          }}
          onClick={this.onRankLast.bind(
            this,
            rowData.reference_id,
            'remove_rank'
          )}
          disabled={rowData.business_status === 'مؤرشف'}
        /> */}
        <Button
          // icon="fa fa-medal"
          //iconPos="right"
          className={classnames(
            'btn_process ',
            {
              'p-button-success':
                rowData.days !== undefined &&
                rowData.temp_status === 'سعر لقطة',
            },
            {
              'p-button-danger':
                rowData.days === undefined || rowData.temp_status === 'عادي',
            }
          )}
          tooltip="تحديد العقار كلقطة"
          tooltipOptions={{
            position: 'bottom',
            event: 'hover',
          }}
          onClick={this.addLakta.bind(
            this,
            rowData.reference_id,
            rowData.title,
            rowData.price,
            rowData.pricing_type,
            rowData.price_upon_review,
            rowData.temp_status,
            rowData.days
          )}
          disabled={rowData.business_status === 'مؤرشف'}
        >
          <FontAwesomeIcon icon={['fas', 'medal']}></FontAwesomeIcon>
        </Button>

        <Link
          to={
            rowData.business_status === 'مؤرشف'
              ? `#!`
              : // : `/properties/edit/${rowData.reference_id}`
                `/adminDashBoard/edit_property?property_id=${rowData.reference_id}`
          }
        >
          <Button
            // icon="fa fa-edit"
            //iconPos="right"
            className="p-button-warning btn_process"
            tooltip="تعديل العقار"
            tooltipOptions={{
              position: 'bottom',
              event: 'hover',
            }}
            onClick={this.onEditProperty.bind(
              this,
              rowData.reference_id,
              'edit'
            )}
            disabled={rowData.business_status === 'مؤرشف'}
          >
            <FontAwesomeIcon icon={['fas', 'edit']}></FontAwesomeIcon>
          </Button>
        </Link>

        {rowData.business_status !== 'مؤرشف' ? (
          <Button
            // icon="fa fa-archive"
            //iconPos="right"
            className="p-button-warning btn_process"
            tooltip="أرشفة العقار"
            tooltipOptions={{
              position: 'bottom',
              event: 'hover',
            }}
            onClick={this.onArchiveProperty.bind(
              this,
              rowData.reference_id,
              'set_archived'
            )}
          >
            <FontAwesomeIcon icon={['fas', 'archive']}></FontAwesomeIcon>
          </Button>
        ) : (
          <Button
            // icon="fa fa-archive"
            //iconPos="right"
            className="p-button-danger btn_process"
            tooltip="إلغاء أرشفة العقار"
            tooltipOptions={{
              position: 'bottom',
              event: 'hover',
            }}
            onClick={this.onArchiveProperty.bind(
              this,
              rowData.reference_id,
              'remove_archived'
            )}
          >
            <FontAwesomeIcon icon={['fas', 'archive']}></FontAwesomeIcon>
          </Button>
        )}

        <Button
          // icon="fa fa-times"
          //iconPos="right"
          className="p-button-warning btn_process"
          tooltip="حذف العقار"
          tooltipOptions={{
            position: 'bottom',
            event: 'hover',
          }}
          onClick={this.onDeleteProperty.bind(
            this,
            rowData.reference_id,
            'set_archived'
          )}
        >
          <FontAwesomeIcon icon={['fas', 'times']}></FontAwesomeIcon>
        </Button>

        {/* <Link to={`/properties/${rowData.reference_id}`}> */}
        <a href={`${linkDomain}/properties/${rowData.reference_id}`}>
          <Button
            // icon="fa fa-building"
            //iconPos="right"
            className="p-button-secondary btn_process"
            tooltip="تفاصيل العقار"
            tooltipOptions={{
              position: 'bottom',
              event: 'hover',
            }}
            style={{ border: '1px solid #9F9F9F' }}
          >
            <FontAwesomeIcon icon={['fas', 'building']}></FontAwesomeIcon>
          </Button>
        </a>
        {/* </Link> */}
      </React.Fragment>
    );
  };
  export = () => {
    this.dt.exportCSV();
  };
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  onTempStatusChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  render() {
    const { isLoggedIn, ikarAdmin, isTakingAgencyRole } = this.props;
    const {
      properties,
      showCards,
      showMainPhoto,
      bgURL,
      total_properties,
      // pageNumber,
      showLakta,
      newLaktaPrice,
      laktaPrice,
      laktaPricingType,
      laktaDays,
      temp_status,
      // laktaTempStatus,
      // laktaTempStatus_items,
      errors,
      // resType,
      largeScreen,
    } = this.state;
    var propertyHeader = (
      <div className="px-2 py-0" style={{ textAlign: 'right' }}>
        <Button
          type="button"
          icon="pi pi-external-link"
          iconPos="left"
          label="تصدير الجدول الى ملف اكسل CSV"
          onClick={this.export}
        ></Button>
      </div>
    );
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.pageTitle}</title>
          <meta name="description" content={this.state.pageDescription} />
          <meta name="robots" content="noindex" />
        </Helmet>
        <Dialog
          header="تحديد عقار كلقطة"
          visible={showLakta}
          style={{ width: '90%', height: '100%', direction: 'rtl' }}
          // style={{ width: '90vw', Height: '90vh', top: '0', direction: 'rtl' }}
          modal={true}
          onHide={() => this.setState({ showLakta: false })}
          // className="show-submitted-property"
        >
          <form>
            <div className="row bg-light">
              <div className="col-md-6 text-right">
                <span className="display_7">{this.state.title}</span>
              </div>
              <div className="col-md-6 text-right">
                <span className="display_8">
                  {this.state.price_upon_review !== true &&
                  this.state.pricing_type !== 'السعر عند المراجعة' ? (
                    <React.Fragment>
                      السعر الحالي:
                      {` ${this.state.price} ${this.state.pricing_type} `}
                    </React.Fragment>
                  ) : (
                    <span>السعر عند المراجعة</span>
                  )}
                </span>
              </div>
              {this.state.days !== undefined && Number(this.state.days) > 0 ? (
                <React.Fragment>
                  <div className="col-md-6 text-right">
                    <span className="display_7">
                      <React.Fragment>
                        <span className="text-warning">العقار محدد كلقطة</span>
                        <span>{` ${this.state.temp_status} `}</span>
                      </React.Fragment>
                    </span>
                  </div>
                  <div className="col-md-6 text-right">
                    <span className="display_8">
                      الزمن المتبقي كلقطة:
                      {` ${Number(this.state.days).toFixed(3)} `}
                      يوم
                    </span>
                  </div>
                </React.Fragment>
              ) : null}
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="input-group text-right">
                  <div className="form-group text-right ">
                    <label
                      htmlFor="laktaDays"
                      className="lakta-label display_7"
                    >
                      عدد أيام اللقطة:
                    </label>
                    <InputMask
                      mask="9999999"
                      maskChar=""
                      //style={{ direction: 'ltr' }}
                      // type="number"
                      name="laktaDays"
                      id="laktaDays"
                      className={classnames('form-control', {
                        'is-invalid': errors.laktaDays,
                      })}
                      placeholder=""
                      value={laktaDays}
                      onChange={this.onChange}
                    />
                    {errors.laktaDays && (
                      <div className="invalid-feedback">{errors.laktaDays}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                {/* <div className="input-group text-right">
                  <label
                    htmlFor="laktaTempStatus"
                    className="lakta-label display_7"
                  >
                    نوع اللقطة:
                  </label>
                  <select
                    name="laktaTempStatus"
                    value={laktaTempStatus}
                    className={classnames('form-control', {
                      'is-invalid': errors.laktaTempStatus
                    })}
                    onChange={this.onTempStatusChange}
                  >
                    {laktaTempStatus_items.map(item => (
                      <option value={item[0]}>{item[1]}</option>
                    ))}
                  </select>
                </div> */}
              </div>
            </div>
            <div className="row ">
              <div className="col-md-4">
                <div className="input-group text-right py-1 my-1">
                  <Checkbox
                    checked={newLaktaPrice}
                    onChange={(e) =>
                      this.setState({ newLaktaPrice: e.checked })
                    }
                  ></Checkbox>
                  <label className="mx-1 display_7"> سعر جديد لللقطة</label>
                </div>
              </div>
              <div className="col-md-4">
                <div className="input-group text-right">
                  {newLaktaPrice ? (
                    <React.Fragment>
                      <div className="input-group text-right">
                        <label
                          htmlFor="laktaPrice"
                          className="lakta-label display_7"
                        >
                          سعر العقار:
                        </label>
                        <InputMask
                          mask="9999999999"
                          maskChar=""
                          style={{ direction: 'ltr' }}
                          // type="number"
                          name="laktaPrice"
                          id="laktaPrice"
                          className={classnames('form-control', {
                            'is-invalid': errors.laktaPrice,
                          })}
                          placeholder=""
                          value={laktaPrice}
                          onChange={this.onChange}
                        />
                        {errors.laktaPrice && (
                          <div className="invalid-feedback">
                            {errors.laktaPrice}
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  ) : null}
                </div>
              </div>
              <div className="col-md-4">
                <div className="input-group text-right">
                  {newLaktaPrice ? (
                    <React.Fragment>
                      <div className="input-group text-right">
                        <label
                          htmlFor="laktaPricingType"
                          className="lakta-label display_7"
                        >
                          طريقة التسعير:
                        </label>
                        <select
                          name="laktaPricingType"
                          className={classnames('form-control', {
                            'is-invalid': errors.laktaPricingType,
                          })}
                          onChange={this.onChange}
                          value={laktaPricingType}
                        >
                          {/* {price_upon_order ? (
                          <option value="السعر بالاتفاق">السعر بالاتفاق</option>
                        ) : null} */}

                          <React.Fragment>
                            <option value="السعر عند المراجعة">
                              السعر عند المراجعة
                            </option>
                            <option value="ل.س">ل.س</option>
                            <option value="م2/ل.س">م2/ل.س</option>
                            <option value="يوم/ل.س">يوم/ل.س</option>
                            <option value="شهر/ل.س">شهر/ل.س</option>
                            <option value="سنة/ل.س">سنة/ل.س</option>
                            {/* <option value="غير محدد">غير محدد</option> */}
                          </React.Fragment>
                        </select>
                        {errors.laktaPricingType && (
                          <div className="invalid-feedback">
                            {errors.laktaPricingType}
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 text-center">
                <button
                  className="btn btn-block submit-button m-1 mx-auto"
                  onClick={this.onAddLaktaSubmit}
                  style={{
                    width: '200px',
                  }}
                >
                  تحديد اللقطة
                </button>
              </div>
              <div className="col-md-6 text-center">
                {temp_status === 'سعر لقطة' ? (
                  <button
                    className="btn btn-block submit-button m-1  mx-auto"
                    onClick={this.onRemoveLaktaSubmit}
                    style={{ width: '200px' }}
                  >
                    حذف اللقطة
                  </button>
                ) : null}
              </div>
            </div>
          </form>
        </Dialog>
        {largeScreen === 't' ? (
          <SearchBar
            searchBarFullShow={false}
            searchType="all_search"
            destination="/adminDashBoard/properties"
          ></SearchBar>
        ) : null}

        <div className="mb-3 text-center my-2">
          <h1 className="main_title">قائمة العقارات</h1>
        </div>
        <div className="property-container">
          <div className="container-fluid">
            <Growl ref={(el) => (this.growl = el)} />
            <div className="text-right m-2">
              <button
                className="btn btn-danger mt-1 btn-sm"
                onClick={(e) => {
                  this.setState({
                    showCards: !this.state.showCards,
                  });
                }}
              >
                {showCards ? (
                  <span>
                    <FontAwesomeIcon icon={['fas', 'toggle-on']} /> طريقة العرض
                    بطاقات{' '}
                  </span>
                ) : (
                  <span>
                    <FontAwesomeIcon icon={['fas', 'toggle-off']} /> طريقة العرض
                    جدول{' '}
                  </span>
                )}
              </button>
            </div>

            {showCards ? (
              <div className="row justify-content-around align-items-center">
                {properties !== [] &&
                  properties !== undefined &&
                  (properties || []).map((property) => (
                    <Property
                      // key={property.reference_id}
                      key={shortid.generate()}
                      property={property}
                      isLoggedIn={isLoggedIn}
                      ikarAdmin={ikarAdmin}
                      requestSuccessMessage={this.state.requestSuccessMessage}
                      isTakingAgencyRole={isTakingAgencyRole}
                      onHandleDeleteProperty={this.onDeleteProperty}
                    />
                  ))}
              </div>
            ) : (
              <div
                className="table_container mt-1"
                //style={{ direction: 'rtl' }}
              >
                {/* <div className="table-responsive"> */}
                <DataTable
                  ref={(el) => {
                    this.dt = el;
                  }}
                  value={properties}
                  responsive={true}
                  //header="المكاتب العقارية"
                  selectionMode="single"
                  selection={this.state.selectedProperty}
                  onSelectionChange={(e) => {
                    this.setState({ selectedProperty: e.value });
                  }}
                  header={propertyHeader}
                >
                  <Column
                    header="الصورة"
                    className="table_td"
                    body={this.showPhotoTemplate}
                    style={{ width: '110px' }}
                  />
                  <Column
                    header="الاسم"
                    className="table_td"
                    body={this.showPropertyTitleTemplate}
                  />
                  <Column
                    field="business_offer"
                    header="نوع العرض"
                    className="table_td"
                    sortable={true}
                  />

                  <Column
                    header="العنوان"
                    className="table_td"
                    body={this.provinceAreaTemplate}
                  />
                  <Column
                    className="table_td"
                    header="السعر"
                    body={this.showPriceTemplate}
                  />
                  <Column
                    field="agency_name"
                    header="المكتب"
                    className="table_td"
                    sortable={true}
                  />

                  <Column
                    field="views"
                    header="الزيارات"
                    className="table_td"
                    sortable={true}
                  />
                  <Column
                    field="calls"
                    header="الاتصالات"
                    className="table_td"
                    sortable={true}
                  />
                  <Column
                    field="rank"
                    header="المرتبة"
                    className="table_td"
                    sortable={true}
                    style={{ width: '60px' }}
                  />
                  <Column
                    body={this.showDetailsTemplate}
                    //header="الاجراءات"
                    style={{ width: '305px' }}
                    className="table_td table-functions"
                  />
                </DataTable>
                {showMainPhoto && (
                  <Lightbox
                    mainSrc={bgURL}
                    nextSrc={bgURL}
                    prevSrc={bgURL}
                    onCloseRequest={() =>
                      this.setState({ showMainPhoto: false })
                    }
                    onMovePrevRequest={
                      () => {
                        return;
                      }
                      // this.setState({
                      //   showMainPhoto: (showMainPhoto + url.length - 1) % url.length
                      // })
                    }
                    onMoveNextRequest={
                      () => {
                        return;
                      }
                      // this.setState({
                      //   photoIndex: (showMainPhoto + 1) % url.length
                      // })
                    }
                  />
                )}
              </div>
            )}
            <PaginationForProperties
              resultLabel="عقار"
              recordsNumber={parseInt(total_properties)}
              // pageNumber={pageNumber}
              // onPageChange={this.onSearchButtonSubmit}
              showMessage={true}
              destination="/adminDashBoard/properties"
              locationSearch={this.state.locationSearch}
            ></PaginationForProperties>
          </div>
          {largeScreen === 'f' ? (
            <SearchBar
              searchBarFullShow={false}
              searchType="all_search"
              destination="/adminDashBoard/properties"
            ></SearchBar>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

Properties.propTypes = {
  properties: PropTypes.array.isRequired,
  getProperties: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  ikarAdmin: PropTypes.bool.isRequired,
  isTakingAgencyRole: PropTypes.bool.isRequired,
  updateProperty: PropTypes.func.isRequired,
  updatePropertyRank: PropTypes.func.isRequired,
  updatePropertyLakta: PropTypes.func.isRequired,
  requestSuccessMessage: PropTypes.string.isRequired,
  doSearch: PropTypes.func.isRequired,
  // pageNumber: PropTypes.number.isRequired
};

const mapStateToProps = (state) => ({
  properties: state.search.properties,
  isLoggedIn: state.auth.isLoggedIn,
  ikarAdmin: state.auth.ikarAdmin,
  isTakingAgencyRole: state.auth.isTakingAgencyRole,
  requestSuccessMessage: state.propertyUD.requestSuccessMessage,
  short_id: state.propertyUD.short_id,
  short_id2: state.propertyUD.short_id2,
  // pageNumber: state.search.pageNumber,
  resType: state.search.resType,
});

export default connect(mapStateToProps, {
  getProperties,
  doSearch,
  loginProcess,
  updateProperty,
  updatePropertyRank,
  updatePropertyLakta,
  deleteProperty,
})(Properties);
 