import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addContactMessage } from './../../actions/contactActions';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { Captcha } from 'primereact/captcha';
import jQuery from 'jquery';
import { Checkbox } from 'primereact/checkbox';
import { imageDomain } from './../../mainConf';
import ar from 'react-phone-input-2/lang/ar.json';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ReactGA from 'react-ga4';

class ContactAgency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      largeScreen: false,
      ExtraLargeScreen: false,
      //propertyTitle: '',
      requestSuccessMessage1: '',
      contact_message1: {},
      agency_ref_id1: '',
      property_ref_id1: '',
      name1: '',
      contact_email1: '',
      address1: '',
      mobile1: '+963',
      phone1: '',
      whatsapp1: '+963',
      telegram1: '+963',
      telegramTest: false,
      whatsappTest: false,
      message1: '',
      title1: '',
      extraNumber: false,
      errors1: {},
      hideContact: false,
      result: 0,
    };
  }
  getRandomNum = () => {
    return Math.ceil(Math.random() * 15);
  };
  createSum = () => {
    var rNum1 = this.getRandomNum(),
      rNum2 = this.getRandomNum();
    var result = rNum1 + rNum2;
    jQuery(function ($) {
      $('#question').text(rNum1 + ' + ' + rNum2 + '=');
      $('#answer').val('');
    });
    this.checkResult();
    this.setState({ result });
  };
  checkResult = () => {
    if (document.getElementById('answer') !== null) {
      var result = document.getElementById('answer').value;
      // result = result.replace(/([۰-۹])/g, function(token) {
      //   return String.fromCharCode(token.charCodeAt(0) - 1728);
      // });

      var hasInput = !!result;
      var valid = hasInput && parseInt(result) === this.state.result;
      jQuery(function ($) {
        $('#init_message').toggle(!hasInput);
        $('input[id=submit_btn]').prop('disabled', !valid);
        if (!valid) {
          $('#answer').addClass('bg-warning');
          $('#answer').addClass('text-dark');
        } else {
          $('#answer').removeClass('bg-warning');
          $('#answer').removeClass('text-dark');
        }
        $('#fail_message').toggle(hasInput && !valid);
      });
    }
  };
  componentDidMount() {
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
    // var total;
    this.createSum();
    if (
      this.props.agency_ref_id !== undefined &&
      this.props.agency_ref_id !== null
    )
      var messageA = `ارجو التواصل معي بخصوص العقار: ${this.props.title} - ${this.props.business_offer}`;
    this.setState({
      agency_ref_id1: this.props.agency_ref_id,
      property_ref_id1: this.props.property_ref_id,
      hideContact: this.props.hideContact,
      title1: this.props.title,
      message1: messageA,

      //propertyTitle: nextProps.title
    });
  }
  componentWillReceiveProps(nextProps, nextState) {
    if (
      nextProps.requestSuccessMessage !== this.state.requestSuccessMessage1 &&
      this.state.requestSuccessMessage1 !== ''
    ) {
      this.setState({
        requestSuccessMessage1: nextProps.requestSuccessMessage,
      });
      switch (nextProps.requestSuccessMessage) {
        case 'add is succeeded':
          this.props.showMessage();
          break;
        default:
          break;
      }
    }
    if (nextProps.agency_ref_id !== this.state.agency_ref_id1) {
      var messageA = `ارجو التواصل معي بخصوص العقار: ${this.props.title} - ${this.props.business_offer}`;
      this.setState({
        agency_ref_id1: nextProps.agency_ref_id,
        property_ref_id1: nextProps.property_ref_id,
        hideContact: nextProps.hideContact,
        title1: this.props.title,
        message1: messageA,

        //propertyTitle: nextProps.title
      });
    }
  }
  isValidMobile = (num) => {
    // return !isNaN(num);
    // return /^-{0,1}\d+$/.test(num);
    // return /^\d+$/.test(num);
    return /^(\+|00)\d{2,4}-?\d{9,12}$/.test(num);
  };
  onSubmit = async (e) => {
    e.preventDefault();
    if (this.state.hideContact === true) {
      alert('استعراض عقار قبل التثبيت');
      return;
    }
    await ReactGA.event({
      category: 'Contact Agency',
      action: `Property ID: ${this.state.property_ref_id1}`,
    });
    await this.setState({
      requestSuccessMessage1: 'ready',
    });
    const {
      //propertyTitle,
      // requestSuccessMessage1,
      agency_ref_id1,
      property_ref_id1,
      name1,
      contact_email1,
      address1,
      mobile1,
      phone1,
      whatsapp1,
      // telegram1,
      telegramTest,
      whatsappTest,
      message1,
      extraNumber,
      // title1
    } = this.state;

    var errors1 = {};
    if (
      agency_ref_id1 === null ||
      agency_ref_id1 === undefined ||
      agency_ref_id1 === ''
    ) {
      errors1.propertyTitle = 'لا يوجد عقار محدد';
    }
    if (name1.trim() === '') {
      errors1.name1 = 'يجب ادخال اسم المستخدم';
    }

    if (contact_email1.trim() === '') {
      errors1.contact_email1 = 'يجب ادخال الايميل';
    } else if (!/[\w+\-.]+@[a-zA-Z.-]+\.[a-z]{2,3}/.test(contact_email1)) {
      errors1.contact_email1 = 'يجب التأكد من الايميل المدخل';
    }
    // if (address1.trim() === '') {
    //   this.setState({ errors1: { address1: 'يجب ادخال العنوان' } });
    //   return;
    // }

    if (mobile1 !== null && mobile1.trim() === '') {
      errors1.mobile1 = 'يجب ادخال رقم الموبايل';
    }
    if (mobile1.trim() !== '') {
      if (String(mobile1.trim()).charAt(0) !== '+') {
        errors1.mobile1 = 'يجب التأكد من رقم الموبايل';
      }
      // if (mobile1.trim().length < 14 || mobile1.trim().length > 14) {
      //   errors1.mobile1 = 'يجب التأكد من رقم الموبايل';
      // }
      if (mobile1.trim().length > 1 && !this.isValidMobile(mobile1.trim())) {
        errors1.mobile1 = 'يجب التأكد من رقم الموبايل';
      }
    }

    if (
      extraNumber &&
      (whatsappTest || telegramTest) &&
      whatsapp1.trim() === ''
    ) {
      errors1.whatsapp1 = 'يجب ادخال رقم الموبايل';
    }

    if (
      extraNumber &&
      (whatsappTest || telegramTest) &&
      whatsapp1.trim() !== ''
    ) {
      if (String(whatsapp1.trim()).charAt(0) !== '+') {
        errors1.whatsapp1 = 'يجب التأكد من رقم الموبايل';
      }
      // if (whatsapp1.trim().length < 14 || whatsapp1.trim().length > 14) {
      //   errors1.whatsapp1 = 'يجب التأكد من رقم الموبايل';
      // }
      if (
        whatsapp1.trim().length > 1 &&
        !this.isValidMobile(whatsapp1.trim())
      ) {
        errors1.whatsapp1 = 'يجب التأكد من رقم الموبايل';
      }
    } else if (extraNumber && !whatsappTest && !telegramTest) {
      errors1.whatsapp1 = 'يجب اختيار واتس أب أو تلغرام';
    }
    // if (message1.trim() === '') {
    //   errors1.message1 = 'يجب ادخال نص الرسالة';
    // }
    if (Object.keys(errors1).length > 0) {
      this.setState({ errors1 });
      return;
    }
    this.setState({ errors1: {} });
    const contact_message = {
      agency_ref_id: agency_ref_id1,
      property_ref_id: property_ref_id1,
      name: name1,
      contact_email: contact_email1,
      address: address1,
      mobile:
        mobile1.replace(/\s/g, '') === '' ||
        mobile1.replace(/\s/g, '') === '+963'
          ? null
          : mobile1.replace(/\s/g, ''),
      phone: phone1,
      whatsapp:
        extraNumber === true
          ? whatsappTest === true
            ? whatsapp1.replace(/\s/g, '') === '' ||
              whatsapp1.replace(/\s/g, '') === '+963'
              ? null
              : whatsapp1.replace(/\s/g, '')
            : null
          : whatsappTest === true
          ? mobile1.replace(/\s/g, '') === '' ||
            mobile1.replace(/\s/g, '') === '+963'
            ? null
            : mobile1.replace(/\s/g, '')
          : null,
      telegram:
        extraNumber === true
          ? telegramTest === true
            ? whatsapp1.replace(/\s/g, '') === '' ||
              whatsapp1.replace(/\s/g, '') === '+963'
              ? null
              : whatsapp1.replace(/\s/g, '')
            : null
          : telegramTest === true
          ? mobile1.replace(/\s/g, '') === '' ||
            mobile1.replace(/\s/g, '') === '+963'
            ? null
            : mobile1.replace(/\s/g, '')
          : null,
      message: `${message1} << http://ikar.sy/properties/${this.props.property_ref_id} >>`,
    };
    const contactMessageData = { contact_message };
    this.props.addContactMessage(contactMessageData);
    this.setState({ noMessages1: false });
    this.setState({
      contact_message1: {},
      name1: '',
      contact_email1: '',
      address1: '',
      mobile1: '+963',
      phone1: '',
      whatsapp1: '+963',
      telegram1: '+963',
      telegramTest: false,
      whatsappTest: false,
      message1: '',
      errors1: {},
      result: 0,
    });
    this.createSum();
  };
  handleClear = (e) => {
    e.preventDefault();
    this.setState({
      requestSuccessMessage1: '',
      contact_message1: {},
      name1: '',
      contact_email1: '',
      address1: '',
      mobile1: '+963',
      phone1: '',
      whatsapp1: '+963',
      telegram1: '+963',
      telegramTest: false,
      whatsappTest: false,
      message1: '',
      extraNumber: false,
      errors1: {},
    });
    this.createSum();
  };
  resize() {
    let currentWidth = window.innerWidth;
    if (currentWidth >= 992 && currentWidth < 1300) {
      this.setState({ largeScreen: true });
    } else {
      this.setState({ largeScreen: false });
    }

    if (currentWidth >= 1300) {
      this.setState({ ExtraLargeScreen: true });
    } else {
      this.setState({ ExtraLargeScreen: false });
    }
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onMobileChange = (e) => {
    if (e.target.value.length > 16 || e.target.value.length === 1) {
      return;
    }
    if (/^\d+$/.test(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  onWhatsappChange = (e) => {
    if (e.target.value.length > 10 || e.target.value.length === 1) {
      return;
    }
    if (/^\d+$/.test(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value });
      if (
        e.target.value.trim() !== '' &&
        e.target.value.trim() !== '+963' &&
        e.target.value.trim() !== '0' &&
        e.target.value.trim() !== '99' &&
        this.state.whatsappTest === false &&
        this.state.telegramTest === false
      ) {
        this.setState({ whatsappTest: true });
      }

      if (
        (e.target.value.trim() === '' ||
          e.target.value.trim() === '+963' ||
          e.target.value.trim() === '0' ||
          e.target.value.trim() === '99') &&
        (this.state.whatsappTest === true || this.state.telegramTest === true)
      ) {
        this.setState({ whatsappTest: false, telegramTest: false });
      }
    }
  };
  render() {
    const {
      // requestSuccessMessage1,
      name1,
      contact_email1,
      // address1,
      mobile1,
      // phone1,
      // whatsapp1,
      // telegram1,
      telegramTest,
      whatsappTest,
      message1,
      extraNumber,
      // title1,
      // hideContact,
      errors1,
    } = this.state;
    const {
      // agency_ref_id,
      // property_ref_id,
      // agency_name,
      // agency_mobile,
      // agency_business_address,
      shortHeader,
    } = this.props;

    return (
      <div
        className={classnames(
          'mx-auto',
          {
            'float-contact-div': this.state.largeScreen && !shortHeader,
          },
          {
            'float-contact-div2': this.state.largeScreen && shortHeader,
          },
          {
            'float-contact-div-EL': this.state.ExtraLargeScreen && !shortHeader,
          },
          {
            'float-contact-div2-EL': this.state.ExtraLargeScreen && shortHeader,
          },
          {
            'contact-div':
              !this.state.largeScreen && !this.state.ExtralargeScreen,
          }
        )}
      >
        <div className="row">
          <div className="col-11 mx-auto">
            {!this.state.largeScreen ? (
              <div className="text-center pb-l-2 pt-l-1 pb-3 pt-2">
                <span className="display_7_bold">
                  التواصل مع المكتب
                  <FontAwesomeIcon
                    icon={['fas', 'mail-bulk']}
                    size="1x"
                    className="mx-2"
                  />
                </span>
              </div>
            ) : Object.keys(errors1).length < 4 ? (
              <div className="text-center">
                <span className="display_7_bold">التواصل مع المكتب</span>
              </div>
            ) : null}

            <form style={{ direction: 'rtl' }}>
              <div className="row">
                <div
                  className="col-12"
                  // className={classnames({
                  //   'col-6': this.state.largeScreen,
                  //   'col-12': !this.state.largeScreen,
                  //   'bg-primary': !this.state.largeScreen
                  // })}
                >
                  <div className="form-group text-right mb-1 mt-1">
                    <label
                      htmlFor="name"
                      className="label_narrow label_black_with_shadow"
                    >
                      اسم المستخدم:{' '}
                      <span className="display_mandatory">(مطلوب)</span>
                    </label>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors1.name1,
                      })}
                      name="name1"
                      value={name1}
                      onChange={this.onChange}
                    />
                    {errors1.name1 && (
                      <div className="invalid-feedback">{errors1.name1}</div>
                    )}
                  </div>
                  {/* //////meeee */}
                  <div className="form-group text-right mb-1 mt-1">
                    <label
                      htmlFor="contact_email1"
                      className="label_narrow label_black_with_shadow"
                    >
                      البريد الالكتروني:{' '}
                      <span className="display_mandatory">(مطلوب)</span>
                    </label>
                    <input
                      type="email"
                      className={classnames('form-control', {
                        'is-invalid': errors1.contact_email1,
                      })}
                      name="contact_email1"
                      value={contact_email1}
                      onChange={this.onChange}
                    />
                    {errors1.contact_email1 && (
                      <div className="invalid-feedback">
                        {errors1.contact_email1}
                      </div>
                    )}
                  </div>

                  <div className="form-group text-right mb-1">
                    <label
                      htmlFor="mobile1"
                      className="label_narrow label_black_with_shadow"
                    >
                      الموبايل:{' '}
                      <span className="display_mandatory">(مطلوب)</span>
                    </label>
                    <PhoneInput
                      style={{ direction: 'ltr' }}
                      type="tel"
                      name="mobile1"
                      id="mobile1Id"
                      className={classnames('form-control', {
                        'is-invalid': errors1.mobile1,
                      })}
                      countryCodeEditable={false}
                      excludeCountries={['il', 'ps']}
                      // preferredCountries={['il', 'ps']}
                      localization={ar}
                      defaultCountry="sy"
                      placeholder="أدخل رقم الموبايل"
                      value={mobile1}
                      onChange={(mobile1) =>
                        this.setState({ mobile1: mobile1.replace(/\s/g, '') })
                      }
                    />

                    {errors1.mobile1 && (
                      <div className="invalid-feedback-custom">
                        {errors1.mobile1}
                      </div>
                    )}
                  </div>
                  <div
                    className={classnames('p-1', { 'round-div': extraNumber })}
                    style={{ direction: 'rtl' }}
                  >
                    <div className="text-right">
                      <Checkbox
                        onChange={(e) =>
                          this.setState({ whatsappTest: e.checked })
                        }
                        checked={whatsappTest}
                      ></Checkbox>
                      <label
                        className="form-check-label mx-1 ml-2"
                        htmlFor="lift"
                      >
                        واتس أب
                      </label>
                      <Checkbox
                        onChange={(e) =>
                          this.setState({ telegramTest: e.checked })
                        }
                        checked={telegramTest}
                      ></Checkbox>
                      <label className="form-check-label mx-1" htmlFor="lift">
                        تيليغرام
                      </label>
                    </div>
                  </div>
                </div>
                <div
                  className="col-12"
                  // className={classnames({
                  //   'col-6': this.state.largeScreen,
                  //   'col-12': !this.state.largeScreen,
                  //   'bg-primary': !this.state.largeScreen
                  // })}
                >
                  <div className="form-group text-right mb-2 mt-1">
                    <label
                      htmlFor="message1"
                      className="label_narrow label_black_with_shadow"
                    >
                      نص الرسالة:
                    </label>

                    <textarea
                      rows="3"
                      className={classnames('form-control', {
                        'is-invalid': errors1.message1,
                      })}
                      name="message1"
                      value={message1}
                      onChange={this.onChange}
                      style={{ resize: 'none', fontSize: '12px' }}
                    ></textarea>
                    {errors1.message1 && (
                      <div className="invalid-feedback">{errors1.message1}</div>
                    )}
                  </div>
                </div>
              </div>

              {Object.keys(errors1).length === 0 ? (
                <div
                  className="form-group text-right px-1 mb-2"
                  style={{ border: '1px solid #581f18', borderRadius: '5px' }}
                >
                  <label
                    htmlFor="message1"
                    className="label_narrow label_black_with_shadow"
                  >
                    يجب الإجابة عن السؤال:
                  </label>
                  {/* <Captcha
                    siteKey="YOUR_SITE_KEY"
                    onResponse={this.showResponse}
                  ></Captcha> */}
                  <div className="row">
                    <div className="col-6 pl-1 m-0 text-left">
                      <span id="question"></span>
                    </div>
                    <div className="col-3 p-0 m-0 text-right">
                      <input
                        id="answer"
                        type="number"
                        className="form-control mb-1"
                        onKeyUp={this.checkResult}
                        style={{ height: '30px' }}
                      />
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="row mb-1">
                <div className="col-12 mx-auto ">
                  <div className="row">
                    <div className="col-5 pl-0">
                      <input
                        id="submit_btn"
                        type="button"
                        onClick={this.onSubmit}
                        value="ارسال"
                        className="btn btn-block submit-button"
                      />
                    </div>
                    <div className="col-7 pr-1">
                      <input
                        type="button"
                        value="مسح الحقول"
                        className="btn btn-block submit-button"
                        onClick={this.handleClear}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

ContactAgency.propTypes = {
  addContactMessage: PropTypes.func.isRequired,
  contact_message: PropTypes.object.isRequired,
  requestFailed: PropTypes.bool.isRequired,
  requestFailedMessage: PropTypes.object.isRequired,
  requestSuccessMessage: PropTypes.string.isRequired,
};
const mapStateToProps = (state) => ({
  contact_message: state.contact.contact_message,
  requestFailed: state.contact.requestFailed,
  requestFailedMessage: state.contact.requestFailedMessage,
  requestSuccessMessage: state.contact.requestSuccessMessage,
});

export default connect(mapStateToProps, { addContactMessage })(ContactAgency);
