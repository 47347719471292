import React, { Component } from 'react';
import { connect } from 'react-redux';
import { doSearch } from '../actions/searchActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropTypes } from 'prop-types';
// import { recordsPerPage } from './../../mainConf/index';
// import {
//   formatMoney,
//   buildSearchTitle,
//   buildSearchDescription,
// } from './../../mainConf/functions';

class FacebookButton extends Component {
  state = {
    searchParams: {},
    main_type:'',
    description: '',
    type: 'نوع العقار',
    business_offer: 'نوع العرض',
    province: 'المحافظة',
    city: '',
    area: 'المنطقة',
    min_price: 'أدنى سعر',
    max_price: 'أعلى سعر',
    min_area: 'أدنى مساحة',
    max_area: 'أعلى مساحة',
    agency_name: '',
    bedrooms: 'الغرف',
    bathrooms: 'غرف الاستحمام',
    floor: 'الطابق',
    view_direction: 'الاتجاه',
    random: '',
    garage: '',
    duplex: '',
    furnitured: '',
    lift: '',
    parking: '',
    basement: '',
    resType: 'ready',
    properties: [],
    
    // ranges: {price: {min: "", max: ""},
    //     total_area: {min: "", max: ""}
    //   }
  };

  async componentWillReceiveProps(nextProps, nextState) {

    if (
      nextProps.properties !== this.state.properties &&
      nextProps.properties[0] !== undefined
    ) {
      const { properties } = nextProps;
      this.setState({
        properties
      });
    } else if (
      nextProps.properties[0] === undefined ||
      nextProps.properties.length === 0
    ) {
      this.setState({
        properties: []
       
      });
    }

    if (
      nextProps.resType !== undefined &&
      nextProps.resType !== this.state.resType
    ) {
      const { resType } = nextProps;
      this.setState({ resType });
    }

    if (nextProps.searchParams !== this.state.searchParams) {
      this.setState({
        agency_name: nextProps.searchParams.agency_name,
        type: nextProps.searchParams.type,
        business_offer: nextProps.searchParams.business_offer,
        main_type:nextProps.searchParams.main_type,
        province: nextProps.searchParams.province,
        area: nextProps.searchParams.area,
        city: nextProps.searchParams.city,
        searchParams: nextProps.searchParams,
        bedrooms: nextProps.searchParams.bedrooms,
        bathrooms: nextProps.searchParams.bathrooms,
        floor: nextProps.searchParams.floor,
        view_direction: nextProps.searchParams.view_direction,
        random: nextProps.searchParams.random,
        garage: nextProps.searchParams.garage,
        duplex: nextProps.searchParams.duplex,
        furnitured: nextProps.searchParams.furnitured,
        lift: nextProps.searchParams.lift,
        parking: nextProps.searchParams.parking,
        basement: nextProps.searchParams.basement,

        min_price:
          nextProps.searchParams.ranges !== undefined &&
          nextProps.searchParams.ranges.price !== undefined
            ? nextProps.searchParams.ranges.price.min
            : 'أدنى سعر',
        max_price:
          nextProps.searchParams.ranges !== undefined &&
          nextProps.searchParams.ranges.price !== undefined
            ? nextProps.searchParams.ranges.price.max
            : 'أعلى سعر',
        max_area:
          nextProps.searchParams.ranges !== undefined &&
          nextProps.searchParams.ranges.total_area !== undefined
            ? nextProps.searchParams.ranges.total_area.max
            : 'أعلى مساحة',
        min_area:
          nextProps.searchParams.ranges !== undefined &&
          nextProps.searchParams.ranges.total_area !== undefined
            ? nextProps.searchParams.ranges.total_area.min
            : 'أدنى مساحة',

        //  {price:
        //    {min: nextProps.searchParams.ranges,
        //    max:nextProps.searchParams.ranges,
        //   },
        // total_area:
        //  {min: nextProps.searchParams.ranges,
        //    max:nextProps.searchParams.ranges ,
        //   }
        // }
      });
    } else {
      this.setState({
        property: null,
      });
    }
    
  }

  openFacebookDialog = (e) => {
    // alert(this.state.max_price);
    e.preventDefault();
    let province1 = '';
    let main_type1='';
    let business_offer1 = '';
    let max_price1 = '';
    let min_price1 = '';
    let max_area1 = '';
    let min_area1 = '';
    let type1 = '';
    let area1 = '';
    let agency_name1 = '';
    let bedrooms1 = '';
    let bathrooms1 = '';
    let floor1 = '';
    let view_direction1 = '';
    let random1 = '';
    let garage1 = '';
    let duplex1 = '';
    let furnitured1 = '';
    let lift1 = '';
    let parking1 = '';
    let basement1 = '';
    let extraFilter = '';

    extraFilter =
      (this.state.garage !== '' && this.state.garage !== undefined) ||
      (this.state.furnitured !== '' && this.state.furnitured !== undefined) ||
      (this.state.duplex !== '' && this.state.duplex !== undefined) ||
      (this.state.basement !== '' && this.state.basement !== undefined) ||
      (this.state.lift !== '' && this.state.lift !== undefined) ||
      (this.state.parking !== '' && this.state.parking !== undefined)
        ? `- يوجد فلاتر إضافية - :`
        : '';

    this.state.garage !== '' && this.state.garage !== undefined
      ? (garage1 = `له كراج`)
      : (garage1 = '');
    this.state.duplex !== '' && this.state.duplex !== undefined
      ? (duplex1 = `دوبليكس`)
      : (duplex1 = '');
    this.state.furnitured !== '' && this.state.furnitured !== undefined
      ? (furnitured1 = `مفروش`)
      : (furnitured1 = '');
    this.state.basement !== '' && this.state.basement !== undefined
      ? (basement1 = `له ملجأ`)
      : (basement1 = '');
    this.state.lift !== '' && this.state.lift !== undefined
      ? (lift1 = `له مصعد`)
      : (lift1 = '');
    this.state.parking !== '' && this.state.parking !== undefined
      ? (parking1 = `له موقف`)
      : (parking1 = '');

    this.state.random !== '' && this.state.random !== undefined
      ? (random1 = `- نتيجة البحث عن  :  ${this.state.random}`)
      : (random1 = '');
    this.state.bedrooms !== 'الغرف' && this.state.bedrooms !== undefined
      ? (bedrooms1 = `- عدد غرف النوم :  ${this.state.bedrooms}`)
      : (bedrooms1 = '');
    this.state.bathrooms !== 'غرف الاستحمام' &&
    this.state.bathrooms !== undefined
      ? (bathrooms1 = `- عدد غرف الاستحمام :  ${this.state.bathrooms}`)
      : (bathrooms1 = '');
    this.state.floor !== 'الطابق' && this.state.floor !== undefined
      ? (floor1 = `- رقم الطابق :  ${this.state.floor}`)
      : (floor1 = '');

    this.state.view_direction !== 'الاتجاه' &&
    this.state.view_direction !== undefined
      ? (view_direction1 = `- الاتجاه :  ${this.state.view_direction}`)
      : (view_direction1 = '');

    this.state.type !== 'نوع العقار' && this.state.type !== undefined
      ? (type1 = ` - نوع العقار : ${this.state.type}`)
      : (type1 = '');
    this.state.province !== 'المحافظة' && this.state.province !== undefined
      ? (province1 = ` -  ضمن محافظة  : ${this.state.province}`)
      : (province1 = '');
    this.state.area !== 'المنطقة' && this.state.area !== undefined
      ? (area1 = ` - في منطقة  : ${this.state.area}`)
      : (area1 = '');

    this.state.business_offer !== 'نوع العرض' &&
    this.state.business_offer !== undefined
      ? (business_offer1 = ` - نوع العرض : ${this.state.business_offer}`)
      : (business_offer1 = '');

      this.state.main_type !== 'نوع العقار' &&
    this.state.main_type !== undefined
      ? (main_type1 = ` - نوع العقار : ${this.state.main_type.split(' OR ').join(',')}`)
      : (main_type1 = '');

      
    max_price1 =
      this.state.max_price !== 'أعلى سعر' &&
      this.state.max_price !== '' &&
      this.state.max_price !== null
        ? ` - أعلى سعر : ${this.state.max_price}`
        : '';
    min_price1 =
      this.state.min_price !== 'أدنى سعر' &&
      this.state.min_price !== '' &&
      this.state.min_price !== null
        ? ` - أدنى سعر : ${this.state.min_price}`
        : '';

    max_area1 =
      this.state.max_area !== 'أعلى مساحة' &&
      this.state.max_area !== '' &&
      this.state.max_area !== null
        ? ` - أعلى مساحة : ${this.state.max_area}`
        : '';

    min_area1 =
      this.state.min_area !== 'أدنى مساحة' &&
      this.state.min_area !== '' &&
      this.state.min_area !== null
        ? ` - أدنى مساحة : ${this.state.min_area}`
        : '';

    window.open(
      `https://www.facebook.com/v2.10/dialog/share?
           app_id=1499497363745951
          &href=${window.location.href}&quote=
       ابحث عن عقارك المفضل
    ${random1}  
    ${province1}
    ${business_offer1}
    ${type1}
    ${main_type1}
    ${max_price1}
    ${min_price1}
    ${max_area1}
    ${min_area1}
    ${area1}
    ${agency_name1}
    ${bedrooms1}
    ${bathrooms1}
    ${floor1}
    ${view_direction1}
    ${extraFilter}  ${garage1}  ${furnitured1}  ${parking1}  ${duplex1}  ${basement1}  ${lift1}
    ${this.state.description}&hashtag:${this.state.type}`,
      'facebook-share-dialog',
      'width=626,height=436'

      //  `http://${this.state.type}`
    );

  };
  render() {
    const {resType ,properties,searchParams} = this.state;
    return (
      <button
        class="btn btn-social btn-facebook"
        onClick={this.openFacebookDialog}
        disabled={
          
          (resType === 'complete' &&
          (properties === [] ||
            properties.length === 0)) ||
          (this.state.agency_name == undefined || this.state.agency_name == '') &&
          (this.state.area == undefined || this.state.area == 'المنطقة') &&
          (this.state.province == undefined || this.state.province == 'المحافظة') &&
          (this.state.business_offer == undefined || this.state.business_offer == 'نوع العرض') &&
          (this.state.type == undefined || this.state.type == 'نوع العقار') &&
          (this.state.main_type == undefined || this.state.main_type == 'نوع العقار') &&
          (this.state.bedrooms == undefined || this.state.bedrooms == 'الغرف') &&
          (this.state.bathrooms == undefined || this.state.bathrooms == 'غرف الاستحمام') &&
          (this.state.floor == undefined || this.state.floor == 'الطابق') &&
          (this.state.random == undefined || this.state.random == '') &&
          (this.state.garage == undefined || this.state.garage == '') &&
          (this.state.parking == undefined || this.state.parking == '') &&
          (this.state.furnitured == undefined || this.state.furnitured == '') &&
          (this.state.lift == undefined || this.state.lift == '') &&
          (this.state.basement == undefined || this.state.basement == '') &&
          (this.state.duplex == undefined || this.state.duplex == '') &&
          (this.state.view_direction == undefined ||
            this.state.view_direction == 'الاتجاه') &&
          (this.state.max_price == 'أعلى سعر' ||
            this.state.max_price == undefined) &&
          (this.state.min_price == 'أدنى سعر' ||
            this.state.min_price == undefined) &&
          (this.state.min_area == 'أدنى مساحة' ||
            this.state.min_area == undefined) &&
          (this.state.max_area == 'أعلى مساحة' ||
            this.state.max_area == undefined)
        }
      >
        <FontAwesomeIcon
          icon={['fab', 'facebook']}
          size="2x"
          style={{ color: '#3b5998' }}
        />
      </button>
    );
  }
}




const mapStateToProps = (state) => ({
  searchParams: state.search.searchParams,
  resType: state.search.resType,
  properties: state.search.properties,
  property: state.search.property
});
export default connect(mapStateToProps, {
  doSearch,
})(FacebookButton);
