import React, { Component } from "react";
import MainPageLatestH from "./../components/homePages/MainPageLatestH";
import MainPageMostViewedH from "./../components/homePages/MainPageMostViewedH";
import MainPagePerProvinceH from "./../components/homePages/MainPagePerProvinceH";
import MainPageSpecialOfferH from "./../components/homePages/MainPageSpecialOfferH";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { getMainResponse } from "./../actions/mainAction.jsx";
import CurrencyFormat from "react-currency-format";
import { ResReady, ResNotComplete } from "./../components/layout/resTypes";
import { Helmet } from "react-helmet";
import { linkDomain, shwoAppDownloadDialog } from "./../mainConf/index";
import { Dialog } from "primereact/dialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Categories } from "./../categories/Categories";
class Home extends Component {
  constructor(props) {
    super();
    this.state = {
      showDialog: false,
      pageTitle:
        "السوق العقاري الالكتروني الأول في سوريا لبيع وتأجير العقارات | Ikar.sy",
      pageDescription:
        "السوق العقاري الالكتروني الأول في سوريا، عروض عقارات شقق، بيوت، أراضي، تجاري، بنايات و شاليهات للبيع و للإيجار من كل المحافظات السورية مع صور و خرائط و ميزة بحث متطورة. | Ikar.sy",
      mainResponse: {},
      resType: "",
      property: {},
      provinceTest: false,
      largeScreen: false,
    };
  }
  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [month, day, year].join("/");
  }
  componentDidMount() {
    const app_dialog = localStorage.getItem("app_dialog");
    const app_dialog_date = localStorage.getItem("app_dialog_date");
    app_dialog === null || app_dialog === undefined || app_dialog === false
      ? this.setState({ showDialog: true })
      : this.setState({ showDialog: false });

    if (app_dialog_date !== null && app_dialog_date !== undefined) {
      let now = new Date();
      const diffTime = Math.abs(
        Date.parse(this.formatDate(now)) -
          Date.parse(this.formatDate(app_dialog_date))
      );
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      diffDays > 0 ////to change the notification period in days
        ? this.setState({ showDialog: true })
        : this.setState({ showDialog: false });
    }
    var property = {};
    property.business_offer = null;
    property.main_type = null;
    var requestData = { property };
    this.props.getMainResponse(requestData);

    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  componentWillReceiveProps(nextProps, nextState) {
    if (
      nextProps.requestData.property !== undefined &&
      nextProps.requestData.property !== this.state.property
    ) {
      this.setState({ property: nextProps.requestData.property });
    }
    if (nextProps.mainResponse !== this.state.mainResponse) {
      const { mainResponse, resType } = nextProps;
      if (
        nextProps.mainResponse !== undefined &&
        nextProps.mainResponse.per_province !== undefined
      ) {
        var provinceTest = false;
        Object.entries(nextProps.mainResponse.per_province).forEach((value) => {
          if (parseInt(value[1]) > 0) {
            provinceTest = true;
          }
        });
      }
      this.setState({ mainResponse, resType, provinceTest });
    }
  }
  resize() {
    let currentWidth = window.innerWidth;
    if (currentWidth >= 768) {
      this.setState({ largeScreen: true });
    } else {
      this.setState({ largeScreen: false });
    }
  }
  render() {
    const { resType, showDialog } = this.state;
    return (
      <div>
        <Helmet>
          {/* {this.state.mainResponse !== undefined &&
          this.state.mainResponse.total !== '' &&
          this.state.mainResponse.total !== undefined ? (
            <link rel="canonical" href={`${linkDomain}`} />
          ) : null} */}
          <link rel="canonical" href={`${linkDomain}`} />
          <title>{this.state.pageTitle}</title>
          <meta name="description" content={this.state.pageDescription} />
        </Helmet>
        {this.state.largeScreen === false && shwoAppDownloadDialog && (
          <Dialog
            header="تحميل تطبيق عقار"
            visible={showDialog}
            // style={{ width: '90%', height: '100%', direction: 'rtl' }}
            style={{ width: "80vw", direction: "rtl" }}
            modal={true}
            onHide={() => {
              localStorage.setItem("app_dialog", false);
              let now = new Date();
              localStorage.setItem("app_dialog_date", now);
              this.setState({ showDialog: false });
            }}
          >
            {/* style={{backgroundColor:'#fcb815', transition: 'all 0.4s'}} */}
            <div>
              <div className="text-center">
                <p className="display_gold mb-1">تحميل مباشر، أندرويد فقط</p>
                <a
                  className="btn btn-outline-warning mb-4 mt-0"
                  href="http://www.ikar.sy/public/ikar-app.apk"
                >
                  <FontAwesomeIcon icon={["fas", "download"]} />
                  <span className=" px-3">Download </span>
                </a>
                <p className="display_gold mb-1"> Google Play</p>
                <a
                  className="btn btn-outline-warning mb-4 mt-0"
                  href="https://play.google.com/store/apps/details?id=com.ikar.ikar"
                >
                  <FontAwesomeIcon icon={["fas", "download"]} />
                  <span className=" px-3">Download </span>
                </a>
              </div>

              {/* <div className="text-center">
                <p className="display_gold mb-1" style={{ direction: 'ltr' }}>
                  Google play  تحميل من{' '}
                </p>
                <a
                  className="btn btn-outline-warning mt-0"
                  href="http://www.207.180.233.248/public/ikar-app1.apk"
                >
                  <FontAwesomeIcon
                    icon={['fab', 'google-play']}
                    size="1x"
                    style={{ color: '#warning' }}
                  />
                  <span className="px-3">Google Play</span>
                </a>
              </div> */}
              {/* <div className='row'>
            <div className='col-6 m-auto'>
                <button className="btn btn-block submit-button m-1  mx-auto"
                    onClick={this.handleDownloadDialog}
                    style={{ width: '200px' }}>
                    اغلاق
                </button>
            </div>
          </div> */}
            </div>
          </Dialog>
        )}
        <div className="mb-5 text-center row" style={{ direction: "rtl" }}>
          <div className="col-md-8 text-center text-md-left">
            <span className="main_title font-weight-bold">
              {" "}
              سوق العقارات الالكتروني في سورية{" "}
            </span>
          </div>
          <div className="col-md-4">
            {this.state.mainResponse !== undefined &&
            this.state.mainResponse.total !== "" &&
            this.state.mainResponse.total !== undefined ? (
              <React.Fragment>
                <span className="display_8 display_gold">عدد العروض</span>
                <CurrencyFormat
                  value={this.state.mainResponse.total}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={""}
                  className="display_8 display_gold"
                />
              </React.Fragment>
            ) : null}
          </div>
        </div>
        {resType === "complete" ? (
          <React.Fragment>
            {this.state.mainResponse.per_time !== undefined &&
            this.state.mainResponse.per_time.length > 0 ? (
              <MainPageLatestH
                properties={this.state.mainResponse.per_time}
                resType={this.state.resType}
                main_type={
                  this.state.property.main_type !== undefined &&
                  this.state.property.main_type !== null
                    ? this.state.property.main_type
                    : ""
                }
                business_offer={
                  this.state.property.business_offer !== undefined &&
                  this.state.property.business_offer !== null
                    ? this.state.property.business_offer
                    : ""
                }
              ></MainPageLatestH>
            ) : null}

            {this.state.mainResponse.per_rank !== undefined &&
            this.state.mainResponse.per_rank.length > 0 ? (
              <MainPageSpecialOfferH
                properties={this.state.mainResponse.per_rank}
                resType={this.state.resType}
                main_type={
                  this.state.property.main_type !== undefined &&
                  this.state.property.main_type !== null
                    ? this.state.property.main_type
                    : ""
                }
                business_offer={
                  this.state.property.business_offer !== undefined &&
                  this.state.property.business_offer !== null
                    ? this.state.property.business_offer
                    : ""
                }
              ></MainPageSpecialOfferH>
            ) : null}

            {this.state.mainResponse.per_views !== undefined &&
            this.state.mainResponse.per_views.length > 0 ? (
              <MainPageMostViewedH
                properties={this.state.mainResponse.per_views}
                resType={this.state.resType}
                main_type={
                  this.state.property.main_type !== undefined &&
                  this.state.property.main_type !== null
                    ? this.state.property.main_type
                    : ""
                }
                business_offer={
                  this.state.property.business_offer !== undefined &&
                  this.state.property.business_offer !== null
                    ? this.state.property.business_offer
                    : ""
                }
              ></MainPageMostViewedH>
            ) : null}
            {this.state.mainResponse.per_province !== undefined &&
            Object.keys(this.state.mainResponse.per_province).length > 0 &&
            this.state.provinceTest ? (
              <MainPagePerProvinceH
                properties={this.state.mainResponse.per_province}
                resType={this.state.resType}
                main_type={
                  this.state.property.main_type !== undefined &&
                  this.state.property.main_type !== null
                    ? this.state.property.main_type
                    : ""
                }
                business_offer={
                  this.state.property.business_offer !== undefined &&
                  this.state.property.business_offer !== null
                    ? this.state.property.business_offer
                    : ""
                }
              ></MainPagePerProvinceH>
            ) : null}
            <Categories/>
          </React.Fragment>
        ) : resType === "not complete" ? (
          <ResNotComplete></ResNotComplete>
        ) : resType === "ready" ? (
          <ResReady></ResReady>
        ) : (
          <div>
            <br />
            <br />
            <br />
            <br />
          </div>
        )}
        {/* <MainPageSearch></MainPageSearch> */}
        {/* <Pro /> */}
        {/* <Deals /> */}
        {/* <Active /> */}
        {/* <Partners /> */}
      </div>
    );
  }
}
Home.propTypes = {
  mainResponse: PropTypes.object.isRequired,
  getMainResponse: PropTypes.func.isRequired,
  resType: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  mainResponse: state.main.mainResponse,
  resType: state.main.resType,
  requestData: state.main.requestData,
});

export default connect(mapStateToProps, { getMainResponse })(Home);
