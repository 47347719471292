import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { doAgencySearch } from './../../actions/searchActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { MultiSelect } from 'primereact/multiselect';
import { recordsPerPage } from './../../mainConf/index';
import { syriaAreas } from './../../mainConf';
class SearchAgenciesBar extends Component {
  state = {
    searchBarFullShow: false,
    agencies: [],
    searchTypeAllAgencies: false,
    searchTypeUnblockedAgencies: false,
    searchId: 'main',
    requestSuccessMessage: {},
    selectedFilters: [],
    order_by: 'ترتيب تلقائي',
    areas: [],
    name: '',
    description: '',
    contact_email: '',
    password: '',
    password_confirmation: '',
    mobile: '',
    phone: '',
    reference_id: '',
    whatsapp: '',
    telegram: '',
    roles: [],
    gps_location: '',
    showMap: false,
    processScreen: false,
    IsUsingGPS: false,
    propertyPosition: null,
    business_email: '',
    charging_plan_id: 1,
    city: '',
    province: 'المحافظة',
    area: 'المنطقة',
    instagram: '',
    blocked: false,
    is_charged: true,
    other_phones: '',
    street: '',
    twitter: '',
    facebook: '',
    legal_address: '',
    business_address: '',
    admins: [],
    agents: [],
    errors: {},
    pageNumber: 0,
  };
  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.pageNumber !== this.state.pageNumber) {
      this.onSearchSubmit(recordsPerPage, nextProps.pageNumber, false, null);
    }
  }
  handleSizeClick = (e) => {
    e.preventDefault();
    this.setState({ searchBarFullShow: !this.state.searchBarFullShow });
  };
  async componentDidMount() {
    const { searchType, searchId } = this.props;
    switch (searchType) {
      case 'all_agencies_search':
        await this.setState({
          searchTypeAllAgencies: true,
          searchTypeUnblockedAgencies: false,
          searchId,
        });
        break;
      case 'unblocked_agencies_search':
        await this.setState({
          searchTypeAllAgencies: false,
          searchTypeUnblockedAgencies: true,
          searchId,
        });
        break;
      default:
        break;
    }
    this.onSearchSubmit(recordsPerPage, 1, false, null);
  }
  onDefaultSearchClick = async (e) => {
    e.preventDefault();
    await this.setState({
      agencies: [],
      requestSuccessMessage: {},
      selectedFilters: [],
      areas: [],
      name: '',
      description: '',
      contact_email: '',
      password: '',
      password_confirmation: '',
      mobile: '',
      phone: '',
      reference_id: '',
      whatsapp: '',
      telegram: '',
      roles: [],
      gps_location: '',
      showMap: false,
      processScreen: false,
      IsUsingGPS: false,
      propertyPosition: null,
      business_email: '',
      charging_plan_id: 1,
      city: '',
      province: 'المحافظة',
      area: 'المنطقة',
      instagram: '',
      blocked: false,
      is_charged: true,
      other_phones: '',
      street: '',
      twitter: '',
      facebook: '',
      legal_address: '',
      business_address: '',
      admins: [],
      agents: [],
      errors: {},
      pageNumber: 0,
    });
    const agency = {};
    //agency.province = 'dsdasdasasdasd';
    if (this.state.searchTypeAllAgencies) {
    } else if (this.state.searchTypeUnblockedAgencies) {
      agency.blocked = false;
    }
    const agencyDate = { agency };
    // if (Object.keys(agency).length === 0) {
    //   agencyDate.agency.ranges = {};
    //   agencyDate.agency.ranges.count_properties = {};
    //   agencyDate.agency.ranges.count_properties.min = '0';
    //   this.props.doAgencySearch(agencyDate, recordsPerPage, 1);
    // } else {
    this.props.doAgencySearch(agencyDate, recordsPerPage, 1);
    // }

    this.setState({ errors: {} });
  };
  onOrderChange = async (e) => {
    await this.setState({ [e.target.name]: e.target.value });
    // await this.onSearchSubmit(recordsPerPage, 1, null);
    // if(e.target.value!==''){
    await this.onSearchSubmit(recordsPerPage, 1, false, null);
    // }
  };
  onSearchSubmit = (recordsPerPage, pageNumber, newSearch, e) => {
    if (e !== null) {
      e.preventDefault();
    }
    const {
      // searchBarFullShow,
      // searchTypeAllAgencies,
      // searchTypeUnblockedAgencies,
      // searchId,

      selectedFilters,
      // areas,
      name,
      // description,
      contact_email,
      // password,
      // password_confirmation,
      mobile,
      // phone,
      // reference_id,
      whatsapp,
      telegram,
      // roles,
      // gps_location,
      // showMap,
      // processScreen,
      // IsUsingGPS,
      // propertyPosition,
      // business_email,
      // charging_plan_id,
      // city,
      order_by,
      province,
      area,
      // instagram,
      // blocked,
      // is_charged,
      // other_phones,
      // street,
      // twitter,
      // facebook,
      // legal_address,
      // business_address,
      // admins,
      // agents,
      // errors
    } = this.state;

    var Province = '';
    var Area = '';
    if (province === 'المحافظة') {
      // this.setState({ errors: { province: 'يجب ادخال المحافظة' } });
      // return;
    } else {
      Province = province;
    }
    if (area === 'المنطقة') {
      // this.setState({ errors: { area: 'يجب ادخال المنطقة' } });
      // return;
    } else {
      Area = area;
    }

    const agency = {};
    if (Area !== '') {
      agency.city = Area;
    }
    if (Province !== '') {
      agency.province = Province;
    }
    if (Area !== '') {
      agency.area = Area;
    }

    if (contact_email.trim() !== '') {
      agency.contact_email = contact_email;
    }
    if (name.trim() !== '') {
      agency.name = name;
    }
    if (mobile.trim() !== '') {
      agency.mobile = mobile;
    }
    if (whatsapp.trim() !== '') {
      agency.whatsapp = whatsapp;
    }
    if (telegram.trim() !== '') {
      agency.telegram = telegram;
    }
    if (this.state.searchTypeAllAgencies) {
    } else if (this.state.searchTypeUnblockedAgencies) {
      agency.blocked = false;
    }

    agency.order = {};
    if (order_by !== 'ترتيب تلقائي') {
      if (order_by === 'من الأكثر إلى الأقل عقارات') {
        agency.order.count_properties = 'desc';
      } else if (order_by === 'من الأقل إلى الأكثر عقارات') {
        agency.order.count_properties = 'asc';
      } 
    } else if (order_by === 'ترتيب تلقائي') {
      agency.order.count_properties = 'desc';
    }

    selectedFilters.forEach((item) => {
      switch (item) {
        case 'محجوب':
          agency.blocked = true;
          break;
        // case 'غير محجوب':
        //   agency.blocked = false;
        //   break;
        // case 'متعامل بالدفعات':
        //   agency.is_charged = true;
        //   break;
        // case 'غير متعامل بالدفعات':
        //   agency.is_charged = false;
        //   break;

        default:
          break;
      }
    });
    const agencyDate = { agency };
    // if (Object.keys(agency).length === 0) {
    //   agencyDate.agency.ranges = {};
    //   agencyDate.agency.ranges.count_properties = {};
    //   agencyDate.agency.ranges.count_properties.min = '0';
    //   this.props.doAgencySearch(agencyDate, recordsPerPage, pageNumber);
    // } else {s
    if (newSearch) {
      this.props.newSearch();
    }
    this.props.doAgencySearch(agencyDate, recordsPerPage, pageNumber);
    // }
    this.setState({ errors: {} });
  };
  onBusinessOfferChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  onCheckBoxChange = (e) =>
    this.setState({ [e.target.name]: e.target.checked });
  onProvinceChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.value === 'دمشق') {
      //دمشق
      this.setState({ areas: syriaAreas.damascusAreas, phone: '0096311' });
    } else if (e.target.value === 'ريف دمشق') {
      //ريف دمشق
      this.setState({
        areas: syriaAreas.rifDamascusAreas,
        phone: '0096311',
      });
    } else if (e.target.value === 'حلب') {
      //حلب
      this.setState({
        areas: syriaAreas.aleppoAreas,
        phone: '0096321',
      });
    } else if (e.target.value === 'طرطوس') {
      //طرطوس
      this.setState({
        areas: syriaAreas.tartousAreas,
        phone: '0096343',
      });
    } else if (e.target.value === 'اللاذقية') {
      //اللاذقية
      this.setState({ areas: syriaAreas.latakiaAreas, phone: '0096341' });
    } else if (e.target.value === 'حمص') {
      //حمص
      this.setState({
        areas: syriaAreas.homsAreas,
        phone: '0096331',
      });
    } else if (e.target.value === 'حماة') {
      //حماة
      this.setState({ areas: syriaAreas.hamaAreas, phone: '0096333' });
    } else if (e.target.value === 'السويداء') {
      //السويداء
      this.setState({ areas: syriaAreas.asuwaydaAreas, phone: '0096316' });
    } else if (e.target.value === 'الحسكة') {
      //الحسكة
      this.setState({
        areas: syriaAreas.hasakaAreas,
        phone: '0096352',
      });
    } else if (e.target.value === 'درعا') {
      //درعا
      this.setState({ areas: syriaAreas.daraaAreas, phone: '0096315' });
    } else if (e.target.value === 'ادلب') {
      //ادلب
      this.setState({
        areas: syriaAreas.idlibAreas,
        phone: '0096323',
      });
    } else if (e.target.value === 'دير الزور') {
      //دير الزور
      this.setState({ areas: syriaAreas.deirezorAreas, phone: '0096351' });
    } else if (e.target.value === 'القنيطرة') {
      //القنيطرة
      this.setState({ areas: syriaAreas.quneitraAreas, phone: '0096314' });
    } else if (e.target.value === 'الرقة') {
      //الرقة
      this.setState({ areas: syriaAreas.raqqaAreas, phone: '0096322' });
    } else {
      this.setState({ areas: [], phone: '00963' });
    }
  };
  FilterTemplate = (option) => {
    switch (option.label) {
      case 'محجوب':
        return (
          <div className="p-clearfix">
            <span
              style={{ fontSize: '1em', float: 'right', marginTop: '4px' }}
            >{` ${option.label} `}</span>
            <FontAwesomeIcon icon={['fas', 'store']} size="1x" color="red" />
            {`  `}
          </div>
        );

      // case 'غير محجوب':
      //   return (
      //     <div className="p-clearfix">
      //       <span
      //         style={{ fontSize: '1em', float: 'right', marginTop: '4px' }}
      //       >{` ${option.label} `}</span>
      //       <FontAwesomeIcon icon={['fas', 'store']} size="1x" />
      //       {`  `}
      //     </div>
      //   );

      // case 'متعامل بالدفعات':
      //   return (
      //     <div className="p-clearfix">
      //       <span
      //         style={{ fontSize: '1em', float: 'right', marginTop: '4px' }}
      //       >{` ${option.label} `}</span>
      //       <FontAwesomeIcon icon={['fas', 'money-check']} size="1x" />
      //       {`  `}
      //     </div>
      //   );

      // case 'غير متعامل بالدفعات':
      //   return (
      //     <div className="p-clearfix">
      //       <span
      //         style={{ fontSize: '1em', float: 'right', marginTop: '4px' }}
      //       >{` ${option.label} `}</span>
      //       <FontAwesomeIcon
      //         icon={['fas', 'money-check']}
      //         size="1x"
      //         color="red"
      //       />
      //       {`  `}
      //     </div>
      //   );
      default:
        return <span className="p-clearfix">فلاتر اضافية للبحث</span>;
    }
  };
  selectedFilterTemplate = (value) => {
    switch (value) {
      case 'محجوب':
        return (
          <div className="filter_multiselected-items">
            <span>{` ${value} `}</span>
            <FontAwesomeIcon icon={['fas', 'store']} size="1x" />
            {`  `}
          </div>
        );

      // case 'غير محجوب':
      //   return (
      //     <div className="filter_multiselected-items">
      //       <span>{` ${value} `}</span>
      //       <FontAwesomeIcon icon={['fas', 'store']} size="1x" color="red" />
      //       {`  `}
      //     </div>
      //   );

      // case 'متعامل بالدفعات':
      //   return (
      //     <div className="filter_multiselected-items">
      //       <span>{` ${value} `}</span>
      //       <FontAwesomeIcon icon={['fas', 'money-check']} size="1x" />
      //       {`  `}
      //     </div>
      //   );

      // case 'غير متعامل بالدفعات':
      //   return (
      //     <div className="filter_multiselected-items">
      //       <span>{` ${value} `}</span>
      //       <FontAwesomeIcon
      //         icon={['fas', 'money-check']}
      //         size="1x"
      //         color="red"
      //       />
      //       {`  `}
      //     </div>
      //   );
      default:
        return (
          <span className="my-multiselected-empty-token">
            فلاتر اضافية للبحث
          </span>
        );
    }
  };
  render() {
    const filters = [
      { label: 'محجوب', value: 'محجوب' },
      // { label: 'غير محجوب', value: 'غير محجوب' },
      // { label: 'متعامل بالدفعات', value: 'متعامل بالدفعات' }
      // { label: 'غير متعامل بالدفعات', value: 'غير متعامل بالدفعات' }
    ];
    const {
      searchBarFullShow,
      // searchTypeAllAgencies,
      // searchTypeUnblockedAgencies,
      // searchId,
      selectedFilters,
      areas,
      name,
      // description,
      contact_email,
      // password,
      // password_confirmation,
      mobile,
      // phone,
      // reference_id,
      whatsapp,
      telegram,
      // roles,
      // gps_location,
      // showMap,
      // processScreen,
      // IsUsingGPS,
      // propertyPosition,
      // business_email,
      // charging_plan_id,
      // city,
      province,
      area,
      order_by,
      // instagram,
      // blocked,
      // is_charged,
      // other_phones,
      // street,
      // twitter,
      // facebook,
      // legal_address,
      // business_address,
      // admins,
      // agents,
      errors,
    } = this.state;

    return (
      <React.Fragment>
        <div className="search_div mt-2">
          <form
            onSubmit={this.onSearchSubmit.bind(this, recordsPerPage, 1, true)}
          >
            <div className="row">
              <div className="col-md-10">
                <div className="input-group justify-content-start">
                  <div className="form-group text-right px-1">
                    <label htmlFor="province" className="label_narrow">
                      المحافظة:
                    </label>
                    <select
                      name="province"
                      className={classnames('form-control', {
                        'is-invalid': errors.province,
                      })}
                      onChange={this.onProvinceChange}
                      value={province}
                      style={{ width: '125px' }}
                    >
                      <option value="المحافظة">المحافظة</option>
                      <option value="دمشق"> دمشق</option>
                      <option value="ريف دمشق"> ريف دمشق</option>
                      <option value="حلب"> حلب</option>
                      <option value="طرطوس"> طرطوس</option>
                      <option value="اللاذقية"> اللاذقية</option>
                      <option value="حمص"> حمص</option>
                      <option value="حماة"> حماة</option>
                      <option value="السويداء"> السويداء</option>
                      <option value="الحسكة"> الحسكة</option>
                      <option value="درعا"> درعا</option>
                      <option value="ادلب"> ادلب</option>
                      <option value="دير الزور"> دير الزور</option>
                      <option value="القنيطرة"> القنيطرة</option>
                      <option value="الرقة"> الرقة</option>
                    </select>
                  </div>
                  {searchBarFullShow === true ? (
                    <div className="form-group text-right px-1">
                      <label htmlFor="area" className="label_narrow">
                        المنطقة:
                      </label>
                      <select
                        name="area"
                        className={classnames('form-control', {
                          'is-invalid': errors.area,
                        })}
                        onChange={this.onChange}
                        value={area}
                        style={{ width: '162px' }}
                      >
                        <option value="المنطقة">المنطقة</option>
                        {areas.map((area, index) => (
                          <option value={area} key={index}>
                            {area}
                          </option>
                        ))}
                      </select>
                    </div>
                  ) : null}

                  <div className="form-group text-right px-1">
                    <label htmlFor="name" className="label_narrow">
                      اسم المكتب:
                    </label>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors.name,
                      })}
                      name="name"
                      value={name}
                      onChange={this.onChange}
                      style={{ width: '120px' }}
                    />
                  </div>
                  <div className="form-group text-right  px-1">
                    <label htmlFor="mobile" className="label_narrow">
                      رقم الموبايل:
                    </label>
                    <input
                      type="number"
                      className={classnames('form-control', {
                        'is-invalid': errors.mobile,
                      })}
                      name="mobile"
                      value={mobile}
                      onChange={this.onChange}
                      style={{ width: '120px' }}
                    />
                  </div>
                </div>

                <div className="input-group justify-content-start">
                  {searchBarFullShow === true ? (
                    <div className="form-group text-right px-1">
                      <label htmlFor="contact_email" className="label_narrow">
                        البريد الالكتروني:
                      </label>
                      <input
                        type="text"
                        className={classnames('form-control', {
                          'is-invalid': errors.contact_email,
                        })}
                        name="contact_email"
                        value={contact_email}
                        onChange={this.onChange}
                        style={{ width: '120px' }}
                      />
                    </div>
                  ) : null}
                  {searchBarFullShow === true ? (
                    <React.Fragment>
                      <div className="form-group text-right px-1">
                        <label htmlFor="whatsapp" className="label_narrow">
                          رقم ال واتس اب:
                        </label>
                        <input
                          type="number"
                          className={classnames('form-control', {
                            'is-invalid': errors.whatsapp,
                          })}
                          name="whatsapp"
                          value={whatsapp}
                          onChange={this.onChange}
                          style={{ width: '120px' }}
                        />
                      </div>
                      <div className="form-group text-right px-1">
                        <label htmlFor="telegram" className="label_narrow">
                          رقم ال تيلغرام:
                        </label>
                        <input
                          type="number"
                          className={classnames('form-control', {
                            'is-invalid': errors.telegram,
                          })}
                          name="telegram"
                          value={telegram}
                          onChange={this.onChange}
                          style={{ width: '120px' }}
                        />
                      </div>

                      <div className="form-group text-right px-1">
                        <label htmlFor="filters" className="label_narrow">
                          فلاتر اضافية:
                        </label>
                        <div>
                          <MultiSelect
                            //style={{ direction: 'ltr' }}
                            value={selectedFilters}
                            options={filters}
                            onChange={(e) =>
                              this.setState({ selectedFilters: e.value })
                            }
                            style={{
                              minWidth: '12em',
                              height: 'calc(1.5em + .75rem + 2px)',
                            }}
                            itemTemplate={this.FilterTemplate}
                            selectedItemTemplate={this.selectedFilterTemplate}
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  ) : null}
                </div>
              </div>
              <div className="col-md-2">
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn submit-button mx-1 mt-1"
                    style={{ width: '80px' }}
                  >
                    ابحث{` `}
                    <FontAwesomeIcon icon={['fas', 'search']} />
                  </button>
                  {/* <button
                    className="btn btn-danger mt-1"
                    onClick={this.handleSizeClick}
                    // style={{ width: '120px' }}
                  >
                    بحث موسع{` `}
                    {this.state.searchBarFullShow ? (
                      <FontAwesomeIcon icon={['fas', 'toggle-on']} size="xs" />
                    ) : (
                      <FontAwesomeIcon icon={['fas', 'toggle-off']} size="xs" />
                    )}
                  </button> */}
                  <button
                    className="btn mt-1 mx-1 submit-button"
                    onClick={this.handleSizeClick}
                    style={{ width: '80px' }}
                  >
                    فلاتر{` `}
                    {searchBarFullShow ? (
                      <FontAwesomeIcon icon={['fas', 'toggle-on']} size="xs" />
                    ) : (
                      <FontAwesomeIcon icon={['fas', 'toggle-off']} size="xs" />
                    )}
                  </button>
                  {/* <button
                    className="btn submit-button mt-1"
                    onClick={this.onDefaultSearchClick}
                    // style={{ width: '120px' }}
                  >
                    بحث جديد{` `}
                    <FontAwesomeIcon icon={['fas', 'undo']} />
                  </button> */}
                </div>
              </div>
              <div
                    className="input-group mx-lg-5 mb-1 pr-lg-5 mx-3 mt-lg-0 mt-1"
                    // style={{ borderTop: '1px #fcb814 solid' }}
                  >
                    {/* <div className="form-group text-right px-1"> */}

                    <label
                      htmlFor="order_by"
                      className="pt-2"
                      style={{ color: '#581f18' }}
                    >
                      الترتيب:
                    </label>
                    <select
                      name="order_by"
                      className={classnames(
                        'form-control',
                        'search-page-order-style',
                        {
                          'is-invalid': errors.order_by,
                        }
                      )}
                      onChange={this.onOrderChange}
                      value={order_by}
                      // style={{
                      //   width: '200px',
                      //   maxWidth: '-moz-max-content',
                      //   color: '#581f18'
                      // }}
                    >
                      <option value="ترتيب تلقائي">ترتيب تلقائي</option>
                      <option value="من الأكثر إلى الأقل عقارات">
                      من الأكثر إلى الأقل عقارات
                      </option>
                      <option value="من الأقل إلى الأكثر عقارات">
                      من الأقل إلى الأكثر عقارات
                      </option>
                      
                    </select>
                    {/* </div> */}
                  </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

SearchAgenciesBar.propTypes = {
  doAgencySearch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { doAgencySearch })(SearchAgenciesBar);
