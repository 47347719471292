import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { mainDomain } from './../../mainConf';
import classnames from 'classnames';
import { Checkbox } from 'primereact/checkbox';
import { Growl } from 'primereact/growl';
import { addJoin } from './../../actions/joinUsAction';
class AddJoin extends Component {
  constructor(props) {
    super();
    this.state = {
      requestSuccessMessage: '',
      title: '',
      reference_id: '',
      content: '',
      // sugg_number: -100,
      visible: false,
      error1: false,
      error2: false,
      error3: false,
      success: false,
      init2: false,
      pageTitle: 'Ikar.sy |JOIN',
      pageDescription:
        'سوق العقارات الإلكتروني في سورية Ikar.sy مخصص لجمع عروض بيع، شراء و تأجير العقارات من جميع مكاتب العقارات في سورية. الإعلان على Ikar.sy مجاني و متاح فقط للمكاتب العقارية والمتعهدين المسجلين لدى الموقع في جميع المحافظات السورية. | Ikar.sy',
    };
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
      error1: false,
      error2: false,
      error3: false,
    });

  onAddSubmit = async (str, e) => {
    e.preventDefault();
    let { title, content, visible, reference_id } = this.state;

    if (title === '' || title === null) {
      this.setState({ error1: true });
    }

    if (content === '' || content === null) {
      this.setState({ error2: true });
    }
    // if (reference_id === '' || reference_id === null) {
    //   this.setState({ error2: true });
    // }

    const newJOIN = {
      title,
      reference_id,
      content,
      // sugg_number,
      visible,
    };
    //let token = localStorage.getItem("ikar_token");
    // let res = {};
    // let errorRes = {
    //   errorStatus: '',
    //   errorMessage: '',
    // };

    // const isTakingAgencyRole = this.props.isTakingAgencyRole;
    // const takenAgencyRoleToken = this.props.takenAgencyRoleToken;
    await this.setState({
      requestSuccessMessage: 'ready',
    });
    // if (!title === "" && !content === "" && !sugg_number === -100) {
    this.props.addJoin(newJOIN);
  };
  handleClear = (e) => {
    if (e !== null) {
      e.preventDefault();
    }
    this.setState({
      title: '',
      reference_id: '',
      content: '',
      //  sugg_number: '',
      visible: false,
    });
  };
  onCheckBoxChange = async (e) => {
    if (this.state.init2) {
      await this.setState({ init2: false, [e.target.name]: true });
    } else {
      await this.setState({ [e.target.name]: e.target.checked });
    }
  };
  componentWillReceiveProps(nextProps, nextState) {
    if (
      nextProps.requestSuccessMessage !== this.state.requestSuccessMessage &&
      this.state.requestSuccessMessage !== ''
    ) {
      this.setState({
        requestSuccessMessage: nextProps.requestSuccessMessage,
      });

      switch (nextProps.requestSuccessMessage) {
        case 'add is succeeded':
          this.growl.show({
            severity: 'success',
            summary: 'تم اضافة اقتراح جديد',
          });
          this.setState({
            title: '',
            reference_id: '',
            content: '',
            // sugg_number: -100,
            visible: false,
          });
          break;
        default:
          break;
      }
    }
  }
  render() {
    const { visible, init2 } = this.state;
    return (
      <div>
        <Helmet>
          <title>{this.state.pageTitle}</title>
          <meta name="description" content={this.state.pageDescription} />
        </Helmet>
        <Growl ref={(el) => (this.growl = el)} />
        <div className="row">
          <div className="col-md-10 mx-auto">
            <div className="text-center pb-4 pt-3 mb-4">
              <h1 className="main_title">اضافة اقتراحات جديدة</h1>
            </div>
          </div>
        </div>
        <form className="search_div  round-div p-1 mb-4">
          <div className="row justify-content-around mb-4">
            <div className="form-group text-right  col-md-6 col-lg-6 col-sm-12 col-xs-12 mb-4 ">
              <label htmlFor="title">
                <span> الاقتراح: </span>
              </label>
              <div>
                <textarea
                  rows="2"
                  name="title"
                  className={classnames('form-control', {
                    'is-invalid': this.state.error1,
                  })}
                  placeholder=""
                  value={this.state.title}
                  onChange={this.onChange}
                />
              </div>
              {this.state.error1 ? (
                <div className="invalid-feedback-custom">
                  الرجاء ادخال الاقتراح{' '}
                </div>
              ) : (
                ''
              )}
            </div>
            <div className="form-group text-right col-lg-6 col-md-6 col-sm-12 col-xs-12  mb-4">
              <label htmlFor="content">
                الجواب: <span className="display_mandatory"></span>
              </label>
              <div>
                <textarea
                  rows="4"
                  name="content"
                  //   className={classnames('form-control', {
                  //     'is-invalid': errors.description,
                  //   })}
                  className={classnames('form-control', {
                    'is-invalid': this.state.error2,
                  })}
                  placeholder=""
                  value={this.state.content}
                  onChange={this.onChange}
                />
              </div>
              {this.state.error2 ? (
                <div className="invalid-feedback-custom">
                  الرجاء ادخال الجواب{' '}
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <div className="row  justify-content-around mb-4 ">
            <div className="form-group text-right  col-md-12 col-lg-12 col-sm-12 col-xs-12 mb-4">
              <label className="form-check-label " htmlFor="visible">
                مرئي
              </label>
              <span className="form-group text-right ">
                <Checkbox
                  checked={visible}
                  onChange={(e) => this.setState({ visible: e.checked })}
                />
              </span>
            </div>

            {/* <div className="form-group text-right  col-md-6 col-lg-6 col-sm-12 col-xs-12 mb-4">
              <label htmlFor="sugg_number"> رقم الاقتراح:</label>
              <div>
                <select
                  name="sugg_number"
                  //   className={classnames("form-control", "text-right")}
                  onChange={this.onChange}
                  value={this.state.sugg_number}
                  className={classnames('form-control', {
                    'is-invalid': this.state.error3,
                  })}
                  style={{ width: '50%', marginLeft: '50%' }}
                >
                  <option value="-100">غير محدد</option>
                  <option value="1"> 1</option>
                  <option value="2"> 2</option>
                  <option value="3"> 3</option>
                  <option value="4"> 4</option>
                  <option value="5"> 5</option>
                  <option value="6"> 6</option>
                  <option value="7"> 7</option>
                  <option value="8"> 8</option>
                  <option value="9"> 9</option>
                  <option value="10"> 10</option>
                  <option value="11"> 11</option>
                  <option value="12"> 12</option>
                  <option value="13"> 13</option>
                  <option value="14"> 14</option>
                  <option value="15"> 15</option>
                  <option value="16"> 16</option>
                  <option value="17"> 17</option>
                  <option value="18"> 18</option>
                  <option value="19"> 19</option>
                  <option value="20"> 20</option>
                  <option value="21"> 21</option>
                  <option value="22"> 22</option>
                  <option value="23"> 23</option>
                  <option value="24"> 24</option>
                  <option value="25"> 25</option>
                  <option value="26"> 26</option>
                  <option value="27"> 27</option>
                </select>
              </div>
              {this.state.error3 ? (
                <div className="invalid-feedback-custom">
                  الرجاء ادخال رقم الاقتراح{' '}
                </div>
              ) : (
                ''
              )}
            </div> */}
          </div>
        </form>
        <div className="text-right">
          <span>
            لادخال رابط فيسبوك - يوتيوب - انستغرام - تويتر أو رقم هاتف ضمن
            الجواب يجب اتباع المثال التالي{' '}
          </span>
          <div className="form-group text-right col-12  mb-4">
            <div>
              <textarea
                readOnly="true"
                rows="6"
                //   className={classnames('form-control', {
                //     'is-invalid': errors.description,
                //   })}
                className={classnames('form-control', 'text-right', {
                  'is-invalid': this.state.error2,
                })}
                placeholder=""
              >{`فيديو يوتيوب
              <<=https://www.youtube.com/channel/UCHc5tI-ELOt7q6ryCoDB_yw/videos;موقع عقار على اليوتيوب;youtube=>>
               صفحة فيسبوك 
              <<=https://www.facebook.com/Ikarsy-109054930712834;افضل موقع عربي للعقارات;facebook=>>
              رقم اتصال 
              <<=00963923456789;اتصل بنا;mobile=>>`}</textarea>
            </div>
            {/* <div className="text-center">
              <span className="text-success">{`<<=`}</span>
              <span>{`اسم الرابط;رابط الفيديو او الصفحة;facebook|youtube|instagram|twitter|mobile`}</span>
              <span className="text-success">{`=>>`}</span>
            </div> */}
          </div>
        </div>
        <div className="row">
          <div className="col-md-4 md-12 m-auto">
            <button
              className="btn  submit-button m-1 text-center btn-block"
              //  style={{ position: "right" }}
              onClick={this.onAddSubmit.bind(this, 'submit')}
            >
              اضافة
            </button>
          </div>
        </div>

        {/* <h1>{this.state.question}</h1>
        <h1>{this.state.answer}</h1> */}
      </div>
    );
  }
}
addJoin.propTypes = {
  //requestFailedMessage: PropTypes.object.isRequired,
  isTakingAgencyRole: PropTypes.bool.isRequired,

  takenAgencyRoleToken: PropTypes.string.isRequired,
};
const mapStateToProps = (state) => ({
  isTakingAgencyRole: state.auth.isTakingAgencyRole,
  takenAgencyRoleToken: state.auth.takenAgencyRoleToken,
  requestSuccessMessage: state.join.requestSuccessMessage,
  short_id: state.join.short_id,
});
export default connect(mapStateToProps, { addJoin })(AddJoin);
