import React from 'react';

export const mainDomain = 'https://ikar.sy/ikar_api';
export const imageDomain = 'https://ikar.sy';
export const linkDomain = 'https://ikar.sy';

export const noInternetMessage =
  'الرجاء التحقق من جودة اتصالك بالإنترنت قبل المتابعة';


// export const syriaAreas = {
//   damascusAreas: ['مدينة دمشق'],
//   rifDamascusAreas: [
//     'مركز ريف دمشق',
//     'التل',
//     'داريا',
//     'دوما',
//     'الزبداني',
//     'قدسيا',
//     'قطنا',
//     'القطيفة',
//     'النبك',
//     'يبرود'
//   ],
//   aleppoAreas: [
//     'حلب',
//     'جبل سمعان',
//     'أعزاز',
//     'الأتارب',
//     'الباب',
//     'تل رفعت',
//     'جرابلس',
//     'دير حافر',
//     'السفيرة',
//     'عفرين',
//     'عين العرب',
//     'منبج'
//   ],
//   tartousAreas: ['طرطوس', 'صافيتا', 'دريكيش', 'الشيخ بدر', 'بانياس'],
//   latakiaAreas: ['اللاذقية', 'القرداحة', 'الحفّة', 'جبلة'],
//   homsAreas: ['حمص', 'الرستن', 'تدمر', 'المخرم', 'القصير', 'تلكلخ'],
//   hamaAreas: ['حماه', 'الغاب', 'السلمية', 'محردة', 'مصياف'],
//   asuwaydaAreas: ['السويداء', 'شهبا', 'صلخد'],
//   hasakaAreas: ['الحسكة', 'رأس العين', 'المالكية', 'القامشلي'],
//   daraaAreas: ['درعا', 'ازرع', 'الصنمين'],
//   idlibAreas: ['إدلب', 'معرة النعمان', 'أريحا', 'حارم', 'جسر الشغور'],
//   deirezorAreas: ['دير الزور', 'البوكمال', 'الميادين'],
//   quneitraAreas: ['القنيطرة', 'فيق'],
//   raqqaAreas: ['الرقة', 'الثورة', 'تل أبيض']
// };
export const generalStatus = [
  // 'غير محدد',
  'على المخطط',
  'قيد البناء',
  'على الهيكل',
  'بناء قديم',
  'جاهز',
  'إكساء بنسبة 20%',
  'إكساء بنسبة 40%',
  'إكساء بنسبة 60%',
  'إكساء بنسبة 80%',
  'يتم ترميمه',
];

export const generalMainTypes = ['شقق', 'شاليهات', 'بنايات', 'تجاري', 'تجاري'];

export const generalProvinces = [
  'دمشق',
  'ريف دمشق',
  'حلب',
  'طرطوس',
  'اللاذقية',
  'حمص',
  'حماة',
  'السويداء',
  'الحسكة',
  'درعا',
  'ادلب',
  'دير الزور',
  'القنيطرة',
  'الرقة',
];

export const maping = {
  'مدينة دمشق': [33.51298457842506, 36.28872175523222],
  'اشرفيه الوادي': [33.58655869849398, 36.18836406906401],
  الحرجله: [33.36910798871203, 36.3116223340715],
  الحميره: [34.170750953800514, 36.84398183985546],
  الديماس: [33.588579091053326, 36.09113940237457],
  الرحيبه: [33.74400556157606, 36.70495694747279],
  الزبداني: [33.720896511115974, 36.113100258389196],
  السبينه: [33.440661157002154, 36.28191971559959],
  'الست زينب': [33.44563699215167, 36.33835337948115],
  السحل: [34.02150428597246, 36.66129126947944],
  الصبوره: [33.51100283242665, 36.12289671920169],
  الضمير: [33.642782160088515, 36.687841701831786],
  الغزلانيه: [33.39914795381845, 36.4551285842702],
  القطيفه: [33.73938039499785, 36.59536473918385],
  الكسوه: [33.35866227341719, 36.252523166374516],
  المعضميه: [33.462270318703, 36.193556920419404],
  المليحه: [33.48840329593537, 36.377577858919615],
  النبك: [34.01303222595012, 36.7323596221255],
  النشابيه: [33.51440610675679, 36.487634256925084],
  الهامه: [33.5668915706943, 36.22501401931563],
  ببيلا: [33.472258167923925, 36.33357902726109],
  بسيمه: [33.60192933186958, 36.20029467357241],
  'جديده عرطوز': [33.439479620100464, 36.16297971270481],
  'جديده الشيباني': [33.571290745844536, 36.18510268158306],
  'جديده الفضل': [33.443092936031356, 36.15988103434106],
  جرمانا: [33.4879991521568, 36.3443186203728],
  جسرين: [33.50856053744991, 36.39027003129683],
  جمرايا: [33.569968903715626, 36.23450881375465],
  جيرود: [33.82290879119145, 36.76922628004835],
  حرستا: [33.56184481375714, 36.373072473613554],
  'حران العواميد': [33.44744817499956, 36.55042110547667],
  حزه: [33.52099417677741, 36.364628107463474],
  حموريه: [33.52826584104457, 36.390473829839394],
  داريا: [33.456754201460676, 36.23553999226538],
  دمر: [33.52364927000049, 36.20803564683667],
  دوما: [33.57235404179452, 36.41028435233333],
  'دير عطيه': [34.09196136804835, 36.76320921013452],
  عربين: [33.53880309728358, 36.365787434710874],
  'عسال الورد': [33.865212334167246, 36.41253242714767],
  عقربا: [33.44062574806166, 36.37579687281229],
  'عين التينه': [33.81575469082959, 36.55717843800191],
  'عين الفيجه': [33.61496725705957, 36.18034969153229],
  'عين ترما': [33.5137039924876, 36.34773021829697],
  'عين منين': [33.651425300778854, 36.30411117944469],
  قارة: [34.153842569477874, 36.74420378167215],
  قدسيا: [33.548055620267725, 36.21652723308125],
  'ضاحية قدسيا': [33.537458087908774, 36.18860014860806],
  قطنا: [33.43944692647383, 36.080141276167346],
  'كفر بطنا': [33.51405295476411, 36.37360818995707],
  كناكر: [33.26972843571404, 36.094679981095695],
  التل: [33.608978315368006, 36.317110843468484],
  'مرج السلطان': [33.49212390791971, 36.46809438751153],
  مضايا: [33.68793494111755, 36.09791637023658],
  معربا: [33.577699791815306, 36.287841991852424],
  معلولا: [33.84436219936134, 36.549711516405765],
  يبرود: [33.97147828970718, 36.657686400996724],
  يعفور: [33.51337449781468, 36.094637660121855],
  يلدا: [33.464473432755796, 36.31987807337636],
  رنكوس: [33.757037712107135, 36.387534108429094],
  زاكيه: [33.33580341357937, 36.15991141976896],
  زملكا: [33.52662015182843, 36.352343568290316],
  'سبع بيار': [33.76630850476089, 37.68333319490507],
  سرغايا: [33.8091374126125, 36.1584499664804],
  'مركز ريف دمشق': [33.343230998442586, 36.286175328944246],
  سعسع: [33.27579178279541, 36.01711055814835],
  صحنايا: [33.428627871128434, 36.22310410677617],
  'أشرفية صحنايا': [33.4320496244414, 36.244111106964326],
  صيدنايا: [33.6906405641857, 36.36816058025332],
  عدرا: [33.60356781711051, 36.516855356246936],
  منبج: [36.53521679178248, 37.96548921716598],
  'عين العرب': [36.89026701952958, 38.350045008767225],
  عفرين: [36.51193568074161, 36.863922711926186],
  جرابلس: [36.8188757740202, 38.00738229257139],
  'تل رفعت': [36.463782195457846, 37.09465330759672],
  السفيرة: [36.08346551827811, 37.37168656223136],
  الحاضر: [35.99587865815214, 37.0571859326602],
  الباب: [36.37215078008615, 37.51611549399461],
  أعزاز: [36.587567263094506, 37.04823755616238],
  'جبل سمعان': [36.12371485105198, 37.10857736979259],
  الأتارب: [36.13239567307768, 36.8300344650738],
  'دير حافر': [36.15205404798543, 37.707867879483594],
  'سيف الدوله': [36.19325237133052, 37.1277487355611],
  'حلب الجديده': [36.199490431649444, 37.09713634093709],
  العزيزيه: [36.21006966289709, 37.15317062761795],
  الحمدانيه: [36.17726925344268, 37.098662984295494],
  الجميليه: [36.207100935514205, 37.14317473019541],
  حلب: [36.20115059667728, 37.13519854356952],
  الفرقان: [36.2037653, 37.1195263],
  السبيل: [36.2190655, 37.1338454],
  الدريكيش: [34.89487042758335, 36.134805873644076],
  'الشيخ بدر': [34.99411226676461, 36.086867127470825],
  بانياس: [35.185195636855426, 35.95297417163827],
  بملكه: [34.935158224164326, 35.97367162612053],
  صافيتا: [34.81887518585147, 36.11774700088373],
  طرطوس: [34.89600100160369, 35.88663998101261],
  'مشتى الحلو': [34.87790361491653, 36.25402448240816],
  الصفصافة: [34.734980487709, 36.05661408283373],
  جبلة: [35.36881792570293, 35.9201439900372],
  اللاذقية: [35.539894237152225, 35.79270579907318],
  القرداحة: [35.45797751935017, 36.05742927127649],
  الحفّة: [35.58983143631996, 36.02608120703468],
  حمص: [34.72519867174951, 36.704207677998994],
  تلكلخ: [34.66827976850282, 36.25644951797893],
  تلدو: [34.872119815774624, 36.52894094248252],
  تدمر: [34.56971770897107, 38.29327660368431],
  المخرم: [34.819827485786774, 37.07855703948403],
  القصير: [34.50823431045275, 36.58230025603419],
  الرستن: [34.92465396528882, 36.73278828134527],
  'وادي النصارى': [34.7612588819648, 36.32339736437026],
  مصياف: [35.06380406014338, 36.34143745922055],
  محردة: [35.248198115603486, 36.571687102146385],
  السلمية: [35.011471926013385, 37.05611248225529],
  حماه: [35.190276626516955, 36.798477201596874],
  الغاب: [35.5867851540728, 36.35614165331537],
  صلخد: [32.48561724662892, 36.711545139475874],
  شهبا: [32.85689390249781, 36.625843242020544],
  السويداء: [32.71271246750415, 36.56363809560763],
  الحسكة: [36.494701438136964, 40.73314720926311],
  'رأس العين': [36.848201583014486, 40.079412709206885],
  المالكية: [37.176694250919944, 42.13542511029824],
  القامشلي: [37.05505954781928, 41.22889772073687],
  داعل: [32.756239528948136, 36.1384536279439],
  جاسم: [32.99350319541706, 36.056314352742085],
  انخل: [33.014773894185495, 36.12703868481224],
  الصنمين: [33.07471001172796, 36.181111467752174],
  'الشيخ مسكين': [32.83077177428654, 36.155920275093415],
  الحراك: [32.75082573419624, 36.3007595308528],
  ازرع: [32.844648572922246, 36.224799867151575],
  درعا: [32.62631868635424, 36.1018538469375],
  نوا: [32.88664756562564, 36.04022048021262],
  إدلب: [35.93078638916293, 36.642778938268144],
  'معرة النعمان': [35.648070271982434, 36.678328674058896],
  أريحا: [35.81692455285764, 36.60840642352793],
  حارم: [36.20522193719827, 36.515787616900255],
  'جسر الشغور': [35.819598415738035, 36.303341130484476],
  'دير الزور': [35.32895522425763, 40.135058322373254],
  الميادين: [35.01573842441345, 40.44636087721602],
  البوكمال: [34.46491214022361, 40.90707855898237],
  فيق: [33.11616753788669, 35.819440502505884],
  القنيطرة: [33.12615950484215, 35.82562031188825],
  معدان: [35.75190871630967, 39.61498280018754],
  'عين عيسى': [36.38366462986159, 38.86799833619847],
  'تل أبيض': [36.70898968224435, 38.96000886784414],
  الثورة: [35.82311354236201, 38.53701367012442],
  الرقة: [35.93858868895155, 38.99503055289382],
};

export const generalStatusIds = [
  ['على المخطط', '1'],
  ['قيد البناء', '2'],
  ['على الهيكل', '3'],
  ['بناء قديم', '4'],
  ['جاهز', '5'],
  ['إكساء بنسبة 20%', '6'],
  ['إكساء بنسبة 40%', '7'],
  ['إكساء بنسبة 60%', '8'],
  ['إكساء بنسبة 80%', '9'],
  ['يتم ترميمه', '10'],
];
export const generalTypeOfOwnership = [
  // 'غير محدد',
  'طابو أخضر- 2400 سهم',
  'حكم محكمة',
  'حصة سهمية',
  'عقد تنازل',
  'احلال عضوية - جمعية',
  'رقم اكتتاب (جمعية)',
  'وكالة كاتب عدل',
  'طابو زراعي',
  'فروغ',
];
export const generalBusinessOffer = [
  'بيع',
  'تأجير',
  'مزادات',
  'رهن',
  'استثمار',
];

export const generalTypesItems = [
  'استوديو',
  'شقة',
  'بيت',
  'بيت عربي',
  'سويت',
  'شاليه',
  'فيلا',
  'سطح معد للبناء',
  'بناء كامل',
  'قبو',
  'مقهى',
  'مكتب',
  'عيادة',
  'مطعم',
  'فندق',
  'مشفى',
  'صالة',
  'منشأة تصنيع',
  'مستودع',
  'محطة وقود',
  'مرآب',
  'محلات تجارية',
  'محلات اصلاح',
  'محلات لتربية الحيوانات',
  'مزرعة',
  'أراضي زراعية',
  'أرض للبناء',
  'أراضي ذات رخصة سياحية',
  'أراضي ذات رخصة صناعية',
  'شاليه جبلية',
  'تراس'
];
export const generalViewDirections = [
  'شرقي',
  'غربي',
  'جنوبي',
  'شمالي',
  'شمال غربي',
  'شمال شرقي',
  'جنوب غربي',
  'جنوب شرقي',
  'كل الاتجاهات',
  'ثلاثة اتجاهات',
];
export const generalSellPrices = [
  '500000',
  '1000000',
  '2000000',
  '3000000',
  '4000000',
  '5000000',
  '6000000',
  '7000000',
  '8000000',
  '9000000',
  '10000000',
  '12000000',
  '14000000',
  '16000000',
  '18000000',
  '20000000',
  '22000000',
  '24000000',
  '26000000',
  '28000000',
  '30000000',
  '35000000',
  '40000000',
  '45000000',
  '50000000',
  '60000000',
  '70000000',
  '80000000',
  '90000000',
  '100000000',
  '500000000',
  '900000000',
];

export const generalRentPrices = [
  '5000',
  '10000',
  '15000',
  '20000',
  '25000',
  '30000',
  '35000',
  '40000',
  '45000',
  '50000',
  '60000',
  '70000',
  '80000',
  '90000',
  '100000',
  '150000',
  '200000',
  '400000',
  '600000',
  '800000',
  '1000000',
  '1500000',
  '2000000',
  '5000000',
  '10000000',
  '50000000',
  '100000000',
];
export const generalAreas = [
  '10',
  '20',
  '30',
  '40',
  '50',
  '60',
  '70',
  '80',
  '90',
  '100',
  '120',
  '140',
  '160',
  '180',
  '200',
  '250',
  '300',
  '400',
  '500',
  '600',
  '700',
  '800',
  '900',
  '1000',
  '2000',
  '3000',
  '4000',
  '5000',
  '6000',
  '7000',
  '8000',
  '9000',
];
export const generalTypes = (
  <React.Fragment>
    {/* <option value="غير محدد">غير محدد</option> */}
    <optgroup label="شقق">
      <option value="استوديو">استوديو</option>
      <option value="شقة">شقة</option>
      <option value="بيت">بيت</option>
      <option value="سويت">سويت</option>
      <option value="بيت عربي">بيت عربي</option>

    </optgroup>
    <optgroup label="شاليهات">
      <option value="شاليه">شاليه</option>
      <option value="شاليه جبلية">شاليه جبلية</option>
    </optgroup>
    <optgroup label="بنايات">
      <option value="فيلا">فيلا</option>
      <option value="سطح معد للبناء">سطح معد للبناء</option>
      <option value="بناء كامل">بناء كامل</option>
      <option value="قبو">قبو</option>
      <option value="تراس"> تراس</option>
    </optgroup>
    <optgroup label="تجاري">
      <option value="مقهى">مقهى</option>
      <option value="مكتب">مكتب</option>
      <option value="عيادة">عيادة</option>
      <option value="مطعم">مطعم</option>
      <option value="فندق">فندق</option>
      <option value="مشفى">مشفى</option>
      <option value="صالة">صالة</option>
      <option value="منشأة تصنيع">منشأة تصنيع</option>
      <option value="مستودع">مستودع</option>
      <option value="محطة وقود">محطة وقود</option>
      <option value="مرآب">مرآب</option>
      <option value="محلات تجارية">محلات تجارية</option>
      <option value="محلات اصلاح">محلات اصلاح</option>
      <option value="محلات لتربية الحيوانات">محلات لتربية الحيوانات</option>
    </optgroup>
    {/* <optgroup label="محلات">
      
    </optgroup> */}
    <optgroup label="أراضي">
      <option value="مزرعة">مزرعة</option>
      <option value="أراضي زراعية">أراضي زراعية</option>
      <option value="أرض للبناء">أرض للبناء</option>
      <option value="أراضي ذات رخصة سياحية">أراضي ذات رخصة سياحية</option>
      <option value="أراضي ذات رخصة صناعية">أراضي ذات رخصة صناعية</option>
    </optgroup>
  </React.Fragment>
);
export const syriaAreas = {
  damascusAreas: ['مدينة دمشق'],
  rifDamascusAreas: [
    'اشرفيه الوادي',
    'الحرجله',
    'الحميره',
    'الديماس',
    'الرحيبه',
    'الزبداني',
    'السبينه',
    'الست زينب',
    'السحل',
    'الصبوره',
    'الضمير',
    'الغزلانيه',
    'القطيفه',
    'الكسوه',
    'المعضميه',
    'المليحه',
    'النبك',
    'النشابيه',
    'الهامه',
    'ببيلا',
    'بسيمه',
    'جديده عرطوز',
    'جديده الشيباني',
    'جديده الفضل',
    'جرمانا',
    'جسرين',
    'جمرايا',
    'جيرود',
    'حرستا',
    'حران العواميد',
    'حزه',
    'حموريه',
    'داريا',
    'دمر',
    'دوما',
    'دير عطيه',
    'عربين',
    'عسال الورد',
    'عقربا',
    'عين التينه',
    'عين الفيجه',
    'عين ترما',
    'عين منين',
    'قارة',
    'قدسيا',
    'ضاحية قدسيا',
    'قطنا',
    'كفر بطنا',
    'كناكر',
    'التل',
    'مرج السلطان',
    'مضايا',
    'معربا',
    'معلولا',
    'يبرود',
    'يعفور',
    'يلدا',
    'رنكوس',
    'زاكيه',
    'زملكا',
    'سبع بيار',
    'سرغايا',
    'مركز ريف دمشق',
    'سعسع',
    'صحنايا',
    'أشرفية صحنايا',
    'صيدنايا',
    'عدرا',
  ],
  aleppoAreas: [
    'منبج',
    'عين العرب',
    'عفرين',
    'جرابلس',
    'تل رفعت',
    'السفيرة',
    'الحاضر',
    'الباب',
    'أعزاز',
    'جبل سمعان',
    'الأتارب',
    'دير حافر',
    'سيف الدوله',
    'حلب الجديده',
    'العزيزيه',
    'الحمدانيه',
    'الجميليه',
    'حلب',
    'الفرقان',
    'السبيل',
  ],
  tartousAreas: [
    'الدريكيش',
    'الشيخ بدر',
    'بانياس',
    'بملكه',
    'صافيتا',
    'طرطوس',
    'مشتى الحلو',
    'الصفصافة',
  ],
  latakiaAreas: ['جبلة', 'اللاذقية', 'القرداحة', 'الحفّة'],
  homsAreas: [
    'حمص',
    'تلكلخ',
    'تلدو',
    'تدمر',
    'المخرم',
    'القصير',
    'الرستن',
    'وادي النصارى',
  ],
  hamaAreas: ['مصياف', 'محردة', 'السلمية', 'حماه', 'الغاب'],
  asuwaydaAreas: ['صلخد', 'شهبا', 'السويداء'],
  hasakaAreas: ['الحسكة', 'رأس العين', 'المالكية', 'القامشلي'],
  daraaAreas: [
    'داعل',
    'جاسم',
    'انخل',
    'الصنمين',
    'الشيخ مسكين',
    'الحراك',
    'ازرع',
    'درعا',
    'نوا',
  ],
  idlibAreas: ['إدلب', 'معرة النعمان', 'أريحا', 'حارم', 'جسر الشغور'],
  deirezorAreas: ['دير الزور', 'الميادين', 'البوكمال'],
  quneitraAreas: ['فيق', 'القنيطرة'],
  raqqaAreas: ['معدان', 'عين عيسى', 'تل أبيض', 'الثورة', 'الرقة'],
};
export const titleLength = 80;
export const recordsPerPage = 12;
export const mapsEnabled = true;
export const shwoAppDownloadDialog = true;
