import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CurrencyFormat from 'react-currency-format';
import { connect } from 'react-redux';
import { setPath } from './../../actions/settingActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { imageDomain, linkDomain } from './../../mainConf';
import { Growl } from 'primereact/growl';
import defaultBackground from './../../assets/images/defaultBackground.png';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import { Dialog } from 'primereact/dialog';
import ReactGA from 'react-ga4';
//import { Tooltip } from 'primereact/tooltip';
import classnames from 'classnames';
import { noInternetMessage, mapsEnabled } from './../../mainConf';
import { buildAlt } from '../../mainConf/functions';
class Property extends Component {
  state = {
    noImages: false,
    showPropertyInfo: false,
    photoIndex: 0,
    isOpen: false,
    property: {},
    noMessages: true,
    areas: [],
    IsMM: false,
    IsGarden: false,
    IsBuildDate: false,
    IsReconstructed: false,
    IsUsingGPS: false,
    lat: 34.890596,
    lng: 35.881085,
    zoom: 17,
    showCurrentLocation: false,
    propertyPosition: null,
    errors: {},
    title: '',
    rented: true,
    days: 0,
    temp_status: '',
    expiration_date: '',
    rent_start: null,
    rent_end: null,
    pricing_type: 'ل.س',
    type: 'شقة',
    roof_building_area: 100,
    price_upon_review: false,
    rank: 100,
    description: '',
    city: 'غير محدد',
    province: 'غير محدد',
    area: 'غير محدد',
    street: '',
    gps_location: '',
    bedrooms: 0,
    bathrooms: 0,
    toilets: 0,
    garage: true,
    parking: true,
    balcony: 0,
    view_direction: 'غير محدد',
    floor: 0,
    duplex: true,
    total_area: 0,
    living_area: 0,
    ground_area: 0,
    lift: true,
    furnitured: true,
    type_of_ownership: 'طابو أخضر - 2400 سهم',
    price: 0,
    business_status: 'جديد',
    rent_plan_id: 1,
    sell_plan_id: 2,
    visible: false,
    built_at: null,
    reconstructed_at: null,
    status: 'غير محدد',
    price_per_day: 0,
    price_per_mm: 0,
    business_offer: 'بيع',
    views: 1,
    basement: true,
    created_at: null,
    reference_id: '',
    agency_ref_id: '',
    agent_ref_id: '',
    agency_rank: 100,
    agency_name: '',
    agency_mobile: '',
    agency_business_address: '',
    //url: [],
    thumbnails: [],
    onlyOne: false,
  };
  componentDidMount() {
    if (
      this.props.property !== this.state.property &&
      this.props.property !== undefined
    ) {
      const {
        title,
        // rented,
        // days,
        // temp_status,
        // expiration_date,
        // rent_start,
        // rent_end,
        // pricing_type,
        // roof_building_area,
        // price_upon_review,
        // rank,
        // description,
        // city,
        // province,
        // area,
        // street,
        // gps_location,
        // bedrooms,
        // bathrooms,
        // toilets,
        // garage,
        // parking,
        // balcony,
        // view_direction,
        // floor,
        // duplex,
        // total_area,
        // living_area,
        // ground_area,
        // lift,
        // furnitured,
        // type_of_ownership,
        // price,
        // business_status,
        // rent_plan_id,
        // sell_plan_id,
        // visible,
        // built_at,
        // reconstructed_at,
        // status,
        // price_per_day,
        // price_per_mm,
        // business_offer,
        // views,
        // basement,
        // created_at,
        // reference_id,
        // agency_ref_id,
        // agent_ref_id,
        // agency_rank,
        // agency_name,
        // agency_mobile,
        // agency_business_address,
        // //url,
        // thumbnails

        temp_status,
        //expiration_date,
        //rent_start,
        //rent_end,
        pricing_type,
        type,
        //roof_building_area,
        price_upon_review,
        //rank,
        //description,
        //city,
        province,
        area,
        //street,
        gps_location,
        bedrooms,
        bathrooms,
        //toilets,
        garage,
        parking,
        //balcony,
        //view_direction,
        floor,
        //duplex,
        //total_area,
        //living_area,
        //ground_area,
        lift,
        furnitured,
        //type_of_ownership,
        price,
        //business_status,
        //rent_plan_id,
        //sell_plan_id,
        //visible,
        //built_at,
        //reconstructed_at,
        //status,
        //price_per_day,
        //price_per_mm,
        business_offer,
        //views,
        //basement,
        //created_at,
        reference_id,
        agency_ref_id,
        //agent_ref_id,
        //agency_rank,
        agency_name,
        agency_mobile,
        agency_business_address,
        //url,
        thumbnails,
      } = this.props.property;
      this.setState({
        title,
        // rented,
        // days,
        // temp_status,
        // expiration_date,
        // rent_start,
        // rent_end,
        // pricing_type,
        type,
        // roof_building_area,
        price_upon_review,
        // rank,
        // description,
        // city,
        // province,
        // area,
        // street,
        // gps_location,
        // bedrooms,
        // bathrooms,
        // //toilets,
        // garage,
        // parking,
        // balcony,
        // view_direction,
        // floor,
        // duplex,
        // total_area,
        // living_area,
        // ground_area,
        // lift,
        // furnitured,
        // type_of_ownership,
        // price,
        // business_status,
        // rent_plan_id,
        // sell_plan_id,
        // visible,
        // built_at,
        // reconstructed_at,
        // status,
        // price_per_day,
        // price_per_mm,
        // business_offer,
        // views,
        // basement,
        // created_at,
        // reference_id,
        // agency_ref_id,
        // agent_ref_id,
        // agency_rank,
        // agency_name,
        // agency_mobile,
        // agency_business_address

        temp_status,
        //expiration_date,
        //rent_start,
        //rent_end,
        pricing_type,
        //roof_building_area,
        //price_upon_review,
        //rank,
        //description,
        //city,
        province,
        area,
        //street,
        gps_location,
        bedrooms,
        bathrooms,
        //toilets,
        garage,
        parking,
        //balcony,
        //view_direction,
        floor,
        //duplex,
        //total_area,
        //living_area,
        //ground_area,
        lift,
        furnitured,
        //type_of_ownership,
        price,
        //business_status,
        //rent_plan_id,
        //sell_plan_id,
        //visible,
        //built_at,
        //reconstructed_at,
        //status,
        //price_per_day,
        //price_per_mm,
        business_offer,
        //views,
        //basement,
        //created_at,
        reference_id,
        agency_ref_id,
        //agent_ref_id,
        //agency_rank,
        agency_name,
        agency_mobile,
        agency_business_address,
        //url,
        thumbnails,
      });

      if (this.props.onlyOne !== undefined && this.props.onlyOne !== null) {
        this.setState({
          onlyOne: this.props.onlyOne,
        });
      }
      if (
        gps_location !== null &&
        gps_location !== undefined &&
        gps_location !== ''
      ) {
        const lat = parseFloat(gps_location.split(',')[0]);
        const lng = parseFloat(gps_location.split(',')[1]);
        this.setState({
          lat,
          lng,
        });
      }
      if (thumbnails !== undefined && thumbnails !== null) {
        this.setState({
          //url,
          thumbnails,
        });
      }
      this.onTypeChange2(type);
    }
  }
  onTypeChange2 = (type) => {
    if (type === 'سطح معد للبناء') {
      this.setState({
        // IsRoof: true,
        //living_rooms: 0,
        //balcony: 0,
        bedrooms: 0,
        bathrooms: 0,
        //toilets: 0,
        floor: -100,
        //view_direction: 'غير محدد',
        furnitured: false,
        //living_area: '',
        //ground_area: ''
      });
    } else {
      this.setState({
        // IsRoof: false
      });
    }

    if (type === 'بناء كامل') {
      this.setState({
        // IsFullBuilding: true,
        //living_rooms: 0,
        //balcony: 0,
        bedrooms: 0,
        bathrooms: 0,
        //toilets: 0,
        floor: -100,
        //view_direction: 'غير محدد',
        //duplex: false,
        //roof_building_area: ''
      });
    } else {
      this.setState({
        // IsFullBuilding: false
      });
    }

    if (type === 'فندق') {
      this.setState({
        // IsHotel: true,
        //roof_building_area: '',
        //living_rooms: 0,
        //balcony: 0,
        bathrooms: 0,
        //toilets: 0,
        floor: -100,
      });
    } else {
      this.setState({
        // IsHotel: false
      });
    }

    if (type === 'فيلا') {
      this.setState({
        // IsVilla: true,
        floor: -100,
        //roof_building_area: ''
      });
    } else {
      this.setState({
        // IsVilla: false
      });
    }
    if (type === 'مرآب' || type === 'صالة') {
      this.setState({
        // IsGarage: true,
        //roof_building_area: '',
        //living_rooms: 0,
        //balcony: 0,
        bedrooms: 0,
        bathrooms: 0,
        //toilets: 0,
        //view_direction: 'غير محدد',
        garage: false,
        //basement: false,
        parking: false,
        //duplex: false,
        lift: false,
        furnitured: false,
        //ground_area: ''
      });
    } else {
      this.setState({
        // IsGarage: false
      });
    }
    if (
      type === 'محلات تجارية' ||
      type === 'محلات اصلاح' ||
      type === 'محلات لتربية الحيوانات'
    ) {
      this.setState({
        // IsShop: true,
        //roof_building_area: '',
        //living_rooms: 0,
        //balcony: 0,
        bedrooms: 0,
        bathrooms: 0,
        //toilets: 0,
        floor: -100,
        //view_direction: 'غير محدد',
        garage: false,
        //basement: false,
        parking: false,
        //duplex: false,
        lift: false,
        furnitured: false,
        //ground_area: ''
      });
    } else {
      this.setState({
        // IsShop: false
      });
    }
    if (type === 'مستودع' || type === 'منشأة تصنيع') {
      this.setState({
        // IsStoreFactory: true,
        //roof_building_area: '',
        //living_rooms: 0,
        //balcony: 0,
        bedrooms: 0,
        bathrooms: 0,
        //toilets: 0,
        floor: -100,
        //view_direction: 'غير محدد',
        furnitured: false,
        //duplex: false,
        //ground_area: ''
      });
    } else {
      this.setState({
        // IsStoreFactory: false
      });
    }
    if (type === 'مطعم' || type === 'مقهى') {
      this.setState({
        // IsRestaurant: true,
        //roof_building_area: '',
        //living_rooms: 0
      });
    } else {
      this.setState({
        // IsRestaurant: false
      });
    }

    if (type === 'مكتب' || type === 'عيادة') {
      this.setState({
        // IsOffice: true,
        //roof_building_area: '',
        //ground_area: '',
        //living_rooms: 0
      });
    } else {
      this.setState({
        // IsOffice: false
      });
    }
    if (type === 'محطة وقود') {
      this.setState({
        // IsGasStation: true,
        //roof_building_area: '',
        //living_rooms: 0,
        //balcony: 0,
        bedrooms: 0,
        bathrooms: 0,
        //toilets: 0,
        floor: -100,
        //view_direction: 'غير محدد',
        garage: false,
        //basement: false,
        parking: false,
        //duplex: false,
        lift: false,
        furnitured: false,
        //ground_area: ''
      });
    } else {
      this.setState({
        // IsGasStation: false
      });
    }

    if (type === 'محلات لتربية الحيوانات') {
      this.setState({
        // IsForAnimal: true,
        //roof_building_area: '',
        //living_rooms: 0,
        //balcony: 0,
        bedrooms: 0,
        bathrooms: 0,
        //toilets: 0,
        floor: -100,
        //view_direction: 'غير محدد',
        garage: false,
        //basement: false,
        parking: false,
        //duplex: false,
        lift: false,
        furnitured: false,
        //ground_area: ''
      });
    } else {
      this.setState({
        // IsForAnimal: false
      });
    }
    if (type === 'قبو') {
      this.setState({
        // IsUnderGround: true,
        //roof_building_area: '',
        //ground_area: ''
      });
    } else {
      this.setState({
        // IsUnderGround: false
      });
    }

    if (type === 'مزرعة') {
      this.setState({
        // IsFarm: true,
        //roof_building_area: '',
        //living_rooms: 0,
        //balcony: 0,
        bedrooms: 0,
        bathrooms: 0,
        //toilets: 0,
        floor: -100,
        //view_direction: 'غير محدد',
        garage: false,
        //basement: false,
        parking: false,
        //duplex: false,
        lift: false,
        furnitured: false,
        //ground_area: '',
        //status: 'جاهز',
        //built_at: null,
        //reconstructed_at: null
      });
    } else {
      this.setState({
        // IsFarm: false
      });
    }

    if (
      type === 'أراضي زراعية' ||
      type === 'أرض للبناء' ||
      type === 'أراضي ذات رخصة سياحية' ||
      type === 'أراضي ذات رخصة صناعية' ||
      type === 'أراضي ذات رخصة صناعية'
    ) {
      this.setState({
        // IsField: true,
        //roof_building_area: '',
        //living_rooms: 0,
        //balcony: 0,
        bedrooms: 0,
        bathrooms: 0,
        //toilets: 0,
        floor: -100,
        //view_direction: 'غير محدد',
        garage: false,
        //basement: false,
        parking: false,
        //duplex: false,
        lift: false,
        furnitured: false,
        //ground_area: '',
        //status: 'جاهز',
        //built_at: null,
        //reconstructed_at: null
      });
    } else {
      this.setState({
        // IsField: false
      });
    }
  };
  toCurrency = (number) => {
    const formatter = new Intl.NumberFormat('sv-SE', {
      style: 'decimal',
      currency: 'SEK',
    });

    return formatter.format(number);
  };

  onHandleEdit = (id, e) => {
    const path = { page: 'edit-property', id: id };
    this.props.setPath(path);
  };
  onMapShowClick = (e) => {
    e.preventDefault();
    this.setState({ showCurrentLocation: true });
  };
  getNextImage = (e) => {
    e.preventDefault();
    if (this.state.photoIndex !== this.state.thumbnails.length - 1) {
      this.setState({ photoIndex: this.state.photoIndex + 1 });
    } else {
      this.setState({ photoIndex: 0 });
    }
  };
  getPreviousImage = (e) => {
    e.preventDefault();

    if (this.state.photoIndex !== 0) {
      this.setState({ photoIndex: this.state.photoIndex - 1 });
    } else {
      this.setState({ photoIndex: this.state.thumbnails.length - 1 });
    }
  };
  onAgencyClick = () => {
    ReactGA.event({
      category: 'Agency Page',
      action: `Agency ID: ${this.state.agency_ref_id}`,
    });
  };
  render() {
    const position = [this.state.lat, this.state.lng];
    const {
      showCurrentLocation,
      title,
      //rented,
      //days,
      temp_status,
      //expiration_date,
      //rent_start,
      //rent_end,
      pricing_type,
      type,
      //roof_building_area,
      //price_upon_review,
      //rank,
      //description,
      //city,
      province,
      area,
      //street,
      gps_location,
      bedrooms,
      bathrooms,
      //toilets,
      garage,
      parking,
      //balcony,
      //view_direction,
      floor,
      //duplex,
      //total_area,
      //living_area,
      //ground_area,
      lift,
      furnitured,
      //type_of_ownership,
      price,
      //business_status,
      //rent_plan_id,
      //sell_plan_id,
      //visible,
      //built_at,
      //reconstructed_at,
      //status,
      //price_per_day,
      //price_per_mm,
      business_offer,
      //views,
      //basement,
      //created_at,
      reference_id,
      agency_ref_id,
      //agent_ref_id,
      //agency_rank,
      agency_name,
      agency_mobile,
      // agency_business_address,
      //url,
      thumbnails,
    } = this.state;

    // const { isLoggedIn, ikarAdmin, isTakingAgencyRole } = this.props;
    const {
      // showPropertyInfo,
      photoIndex,
      // isOpen,
      onlyOne,
    } = this.state;
    var bgURL = '';

    if (
      thumbnails !== null &&
      thumbnails !== undefined &&
      thumbnails.length === 0
    ) {
      bgURL = defaultBackground;
      this.state.noImages !== true &&
        this.setState({ noImages: true })
    } else {
      //////bgURL = `${imageDomain}/${thumbnails[0]}`;
      bgURL = `${imageDomain}/${thumbnails[0]}`;
      this.state.noImages !== false &&
        this.setState({ noImages: false })
    }
    return (
      <React.Fragment>
        <Growl ref={(el) => (this.growl = el)} />
        <Dialog
          header="موقع العقار المحدد على الخريطة"
          //footer={mapFooter}
          // iconsTemplate={mapIcon}
          visible={showCurrentLocation}
          style={{ width: '90vw', minHeight: '70vh' }}
          modal={true}
          onHide={() => this.setState({ showCurrentLocation: false })}
        >
          <div>
            {showCurrentLocation ? (
              mapsEnabled ? (
                <Map
                  center={position}
                  zoom={this.state.zoom}
                  style={{ width: '85vw', minHeight: '65vh' }}
                >
                  <TileLayer
                    attribution='<a href="http://ikar.sy">Ikar.sy</a> &amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker position={position}>
                    <Popup>
                      <span className="display_8">{title}</span>
                    </Popup>
                  </Marker>
                </Map>
              ) : (
                noInternetMessage
              )
            ) : null}
          </div>
        </Dialog>
        <div
          className={classnames({
            'col-xl-3 col-lg-4 col-md-4 col-sm-6 mb-3': !onlyOne,
          })}
          style={{ direction: 'rtl' }}
        >
          <div className="card text-center mb-3 property_card mx-auto">
            <div
              className="card-body m-0 p-0"
            // style={{
            //   background: `url(${bgURL})center no-repeat /cover`
            // }}
            >
              {thumbnails !== null &&
                thumbnails !== undefined &&
                thumbnails.length > 0 ? (
                <div className="image-container">
                  {/* <Link to={`${imageDomain}/properties/${reference_id}`} className="mx-auto"> */}
                  <a
                    href={`${linkDomain}/properties/${reference_id}`}
                    className="mx-auto"
                  >
                    <img
                      src={`${imageDomain}/${thumbnails[photoIndex]}`}
                      alt={buildAlt(this.state.title,this.state.type,this.state.business_offer,this.state.province,
                        this.state.area, this.state.furnitured,this.state.duplex,this.state.basement,this.state.lift,
                       this.state.parking,this.state.garage )}
                      className="property-main-image"
                    />
                    {photoIndex < thumbnails.length ? (
                      <div className="overlay">
                        <img
                          src={`${imageDomain}/${thumbnails[photoIndex + 1]}`}
                          alt={buildAlt(this.state.title,this.state.type,this.state.business_offer,this.state.province,
                            this.state.area, this.state.furnitured,this.state.duplex,this.state.basement,this.state.lift,
                           this.state.parking,this.state.garage )}
                          className="property-main-image"
                        />
                      </div>
                    ) : null}
                  </a>
                </div>
              ) : (
                <div className="image-container">
                  {/* <Link to={`${imageDomain}/properties/${reference_id}`} className="mx-auto"> */}
                  <a
                    href={`${linkDomain}/properties/${reference_id}`}
                    className="mx-auto"
                  >
                    <img
                      src={bgURL}
                      alt={`${this.state.noImages ? "عقارات في سوريا" : `${this.state.business_offer} - ${this.state.type} - ${this.state.province}`}`}
                      className="property-main-image"
                    />
                  </a>
                </div>
              )}
              {thumbnails !== null &&
                thumbnails !== undefined &&
                thumbnails.length > 1 ? (
                //&& photoIndex < url.length - 1
                <div className="next-arrow">
                  <button
                    className="btn-arrow px-2 py-1 m-1"
                    onClick={this.getNextImage}
                  >
                    <FontAwesomeIcon
                      icon={['fas', 'chevron-left']}
                      size="1x"
                      className="icon-arrow"
                      color="#eac435"
                    ></FontAwesomeIcon>
                  </button>
                </div>
              ) : null}
              {thumbnails !== null &&
                thumbnails !== undefined &&
                thumbnails.length > 1 ? (
                //&& photoIndex > 0
                <div className="previous-arrow">
                  <button
                    className="btn-arrow px-2 py-1 m-1"
                    onClick={this.getPreviousImage}
                  >
                    <FontAwesomeIcon
                      icon={['fas', 'chevron-right']}
                      size="1x"
                      className="icon-arrow"
                      color="#eac435"
                    ></FontAwesomeIcon>
                  </button>
                </div>
              ) : null}
              <div className="property-header-top-right text-left no_select">
                {gps_location !== null &&
                  gps_location !== undefined &&
                  gps_location !== '' &&
                  mapsEnabled ? (
                  <button
                    className="btn-map-marked px-0 py-0 m-0"
                    onClick={this.onMapShowClick}
                  >
                    <FontAwesomeIcon
                      className="icon-map-marked"
                      icon={['fas', 'map-marker-alt']}
                      color="#eac435"
                    ></FontAwesomeIcon>
                  </button>
                ) : null}
              </div>
              {/* {days !== undefined && Number(days) > 0 ? ( */}
              {temp_status !== 'عادي' && temp_status !== 'null' ? (
                <div className="business-offer-div-11  m-1 mr-0 py-1 no_select mx-auto">
                  <span>
                    عقار لقطة
                    {/* {` ${temp_status} `} */}
                  </span>
                </div>
              ) : null}
              <div className="property-header text-right no_select">
                {(area === null || area === undefined || area === 'غير محدد') ?
                  (<span className="property-position">{`${business_offer} ${type
                    .replace(/(\n)/g, ' ')
                    .replace(/(\s+)/g, ' ')
                    .split(/\s+/)
                    .splice(0, 1)
                    .join(' ')} ${province}`}</span>)
                  : province === area ?
                    (
                      <span className="property-position">{`${business_offer} ${type
                        .replace(/(\n)/g, ' ')
                        .replace(/(\s+)/g, ' ')
                        .split(/\s+/)
                        .splice(0, 1)
                        .join(' ')} ${province}`}</span>
                    ) : (
                      <span className="property-position">{`${business_offer} ${type
                        .replace(/(\n)/g, ' ')
                        .replace(/(\s+)/g, ' ')
                        .split(/\s+/)
                        .splice(0, 1)
                        .join(' ')} ${province} ${area}`}</span>
                    )}
                {/* {days !== undefined && Number(days) > 0 ? (
                  <div className="property-lakta">{temp_status}</div>
                ) : null} */}
                {/* <div className="property-title">
                  {total_area > 0 ? `  ${total_area} م2` : null}
                </div> */}
              </div>
              <div className="property-header2 text-right no_select">
                <span className="property-title">{`${title.slice(0, 60)}${title.length > 60 ? '...' : ''
                  }`}</span>
              </div>
              {/* {days !== undefined && Number(days) > 0 ? (
                <div className="property-header2 text-right no_select">
                  <h3 className="property-position">{temp_status}</h3>
                </div>
              ) : null} */}
            </div>
            <div className="card-footer p-0 m-0">
              <div className="no_select property-details">
                <table className="mx-auto">
                  <tbody>
                    <tr>
                      {bedrooms !== 0 && bedrooms !== null ? (
                        <td className="pl-1 tooltipicon2">
                          {type === 'مقهى' ||
                            type === 'مكتب' ||
                            type === 'عيادة' ||
                            type === 'مطعم' ||
                            type === 'منشأة تصنيع' ||
                            type === 'محطة وقود' ||
                            type === 'محلات تجارية' ||
                            type === 'محلات اصلاح' ||
                            type === 'محلات لتربية الحيوانات' ? (
                            <span>
                              {' '}
                              <FontAwesomeIcon icon={['fas', 'door-open']} />
                              <span className="tooltiptext">
                                <div style={{ fontSize: 13 }}>
                                  عدد الغرف: {bedrooms}
                                </div>
                              </span>
                            </span>
                          ) : (
                            <span>
                              <FontAwesomeIcon icon={['fas', 'bed']} />
                              <span className="tooltiptext">
                                <div style={{ fontSize: 13 }}>
                                  عدد غرف النوم: {bedrooms}
                                </div>
                              </span>
                            </span>
                          )}
                          <span className="second-color font-weight-bold">
                            {bedrooms}
                          </span>
                        </td>
                      ) : null}

                      {bathrooms !== 0 && bathrooms !== null ? (
                        <td className="pl-1 tooltipicon">
                          <FontAwesomeIcon
                            // data-toggle="tooltip"
                            // title="Click me"
                            // tooltipOptions={{
                            //   position: "bottom",
                            //   event: "hover",
                            //   color: "#9F9F9F"
                            // }}
                            icon={['fas', 'bath']}
                          />
                          <span className="tooltiptext">
                            <div style={{ fontSize: 13 }}>
                              {' '}
                              غرف الاستحمام: {bathrooms}
                            </div>
                          </span>
                          <span className="second-color font-weight-bold">
                            {bathrooms}
                          </span>
                        </td>
                      ) : null}
                      {/* {toilets !== 0 ? (
                        <td className="pl-1">
                          <FontAwesomeIcon icon={['fas', 'toilet']} />
                          <span className="second-color font-weight-bold">
                            {toilets}
                          </span>
                        </td>
                      ) : null} */}
                      {/* {floor !== null && floor !== -100 && floor !== -1 ? ( */}
                      {floor !== null && floor !== -100 && floor >= -3 ? (
                        <td
                          className="pl-1 tooltipicon"
                        //style={{ borderLeft: '1px solid #3d3f3e' }}
                        >
                          <FontAwesomeIcon icon={['fas', 'building']} />
                          <span className="tooltiptext">
                            <div style={{ fontSize: 13 }}>
                              رقم الطابق: {floor}
                            </div>
                          </span>
                          <span className="second-color font-weight-bold">
                            {floor}
                          </span>
                        </td>
                      ) : null}

                      <td className="pl-2">
                        <span className="text-dark font-weight-bold"></span>
                      </td>

                      {garage === true && garage !== null ? (
                        <td className="pl-1 tooltipicon">
                          <FontAwesomeIcon icon={['fas', 'warehouse']} />
                          <span className="tooltiptext">
                            {' '}
                            <div style={{ fontSize: 13 }}>له كراج</div>
                          </span>
                        </td>
                      ) : null}

                      {furnitured === true && furnitured !== null ? (
                        <td className="pl-1 tooltipicon">
                          <FontAwesomeIcon icon={['fas', 'couch']} />
                          <span className="tooltiptext">
                            {' '}
                            <div style={{ fontSize: 13 }}>مفروش</div>
                          </span>
                        </td>
                      ) : null}

                      {parking === true && parking !== null ? (
                        <td className="pl-1 tooltipicon1">
                          <FontAwesomeIcon icon={['fas', 'parking']} />
                          <span className="tooltiptext">
                            {' '}
                            <div style={{ fontSize: 13 }}>له موقف</div>
                          </span>
                        </td>
                      ) : null}
                      {/* {duplex ? (
                        <td className="px-1">
                          <FontAwesomeIcon icon={['fas', 'clone']} />
                        </td>
                      ) : null} */}

                      {lift === true && lift !== null ? (
                        <td className="pl-1 tooltipicon1">
                          <FontAwesomeIcon icon={['fas', 'level-up-alt']} />
                          <span className="tooltiptext">
                            {' '}
                            <div style={{ fontSize: 13 }}>له مصعد</div>
                          </span>
                        </td>
                      ) : null}

                      {/* {basement ? (
                        <td className="px-1">
                          <FontAwesomeIcon icon={['fas', 'archway']} />
                        </td>
                      ) : null} */}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="card-footer p-0 m-0">
              {/* <span className="danger"> {business_offer} </span>
              {` `} */}
              <div className="row">
                <div className="col-7">
                  <div className="text-right no_select">
                    {/* <Link
                      to={`/agency_profile/${agency_ref_id}`}
                      className="mx-auto"
                    > */}
                    <a
                      href={`${linkDomain}/agency_profile/${agency_ref_id}`}
                      className="mx-auto"
                      onClick={this.onAgencyClick}
                    >
                      <span className="property-footer"> {agency_name} </span>
                    </a>

                    {` `}
                    <div>
                      <span className="property-footer">
                        {agency_mobile.replace('00963', '0')}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-5 p-0 m-0">
                  <div className="property-footer-offer m-1 mr-0 p-0 px-2 no_select">
                    {/* <span className="">
                      {business_offer.split(' ').join('\n')}
                    </span> */}
                    {this.state.price_upon_review ||
                      price === '' ||
                      Number(price) === 0 ? (
                      <span className="px-1">السعر عند الإتصال</span>
                    ) : price > 0 ? (
                      <React.Fragment>
                        <div>
                          <CurrencyFormat
                            value={price}
                            displayType={'text'}
                            thousandSeparator={true}
                            prefix={' '}
                          />
                        </div>
                        <div>{`${pricing_type}`}</div>
                      </React.Fragment>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Property.propTypes = {
  property: PropTypes.object.isRequired,
  //requestSuccessMessage: PropTypes.string.isRequired
};
const mapStateToProps = (state) => ({
  isTakingAgencyRole: state.auth.isTakingAgencyRole,
  takenAgencyRoleToken: state.auth.takenAgencyRoleToken,
});
export default connect(mapStateToProps, { setPath })(Property);
