import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { checkConfirmation } from './../../actions/authPasswordResetActions';
import { Helmet } from 'react-helmet';
class Confirmation extends Component {
  constructor(props) {
    super(props);
    document.title = 'تأكيد حساب المستخدم | Ikar.sy';
    this.state = {
      pageTitle: '',
      pageDescription: '',
      // errors: {},
      resMessages: {},
      processScreen: false,
      processActive: false,
      // process2Active: false,
      processResType: '',
      // receivedToken: ''
    };
  }
  componentDidMount() {
    const searchUrlParams = queryString.parse(this.props.location.search);
    if (
      searchUrlParams.confirmation_token !== undefined &&
      searchUrlParams.confirmation_token !== ''
    ) {
      this.setState({
        errors: {},
        resMessages: {},
        processScreen: true,
        processResType: '',
        processActive: true,
      });
      this.props.checkConfirmation(searchUrlParams.confirmation_token);
    }
  }
  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.processResType !== this.state.processResType) {
      this.setState({
        processResType: nextProps.processResType,
        processScreen: false,
      });
      // if (nextProps.processResType === 'complete') {
      //   this.setState({
      //     // process2Active: true,
      //     // process1Active: true,
      //     // resMessages: {
      //     //   errorMessage: 'تم تفعيل الحساب بنجاح'
      //     // }
      //   });
      // }
      if (
        nextProps.checkConfirmationMessage.errorMessage !== undefined &&
        nextProps.checkConfirmationMessage.errorMessage.data !== undefined &&
        nextProps.checkConfirmationMessage.errorMessage.data.confirmed === false
      ) {
        this.setState({
          // process1Active: true,
          // process2Active: false,
          resMessages: {
            errorMessage:
              'انتهت صلاحية الرابط المرسل, الرجاء اعادة ارسال رسالة التفعيل من جديد',
          },
        });
      }

      if (
        nextProps.checkConfirmationMessage.data !== undefined &&
        nextProps.checkConfirmationMessage.data.confirmed === true
      ) {
        this.setState({
          // process1Active: true,
          // process2Active: false,
          resMessages: {
            errorMessage: 'تم تفعيل الحساب بنجاح',
          },
        });
      }
    }
  }
  render() {
    const { resMessages, processScreen, processActive } = this.state;
    return (
      <div>
        <Helmet>
          <title>{this.state.pageTitle}</title>
          <meta name="description" content={this.state.pageDescription} />
          <meta name="robots" content="noindex" />
        </Helmet>
        <div className="row">
          <div className="col-md-10 mx-auto">
            <div className="mt-2 text-center pb-4">
              <h1 className="main_title">تأكيد حساب المستخدم</h1>
            </div>
          </div>
          <div className="text-center display_5 pb-4 pt-3">
            {/* <Growl ref={el => (this.growl = el)}></Growl> */}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mx-auto">
            <div className="card border-warning mb-3">
              {processScreen ? (
                // <div className="process-loader">Ikar.sy</div>
                <div className="process-loader2">
                  <div className="span text-center">
                    {/* <div className="location_indicator" /> */}
                    <div
                      className="spinner-border text-warning"
                      style={{ width: '3rem', height: '3rem' }}
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                    <p className="text-danger lead text-center">
                      <span>www.</span>
                      <span className="font-weight-bold">Ikar</span>
                      <span className="font-weight-bold">.sy</span>
                    </p>
                  </div>
                </div>
              ) : null}
              {processActive && Object.keys(resMessages).length > 0 ? (
                <div className="alert-success my-3 p-1 text-right display_5">
                  {resMessages.errorMessage}
                </div>
              ) : null}
            </div>
            <div className="row">
              <div className="col-md-6 text-center">
                <a href="/login">
                  <span className="display_7">تسجيل الدخول</span>
                </a>
              </div>
              <div className="col-md-6 text-center">
                <a href="/">
                  <span className="display_7">الصفحة الرئيسية</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Confirmation.propTypes = {
  checkConfirmationMessage: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  checkConfirmationMessage: state.authPasswordReset.checkConfirmationMessage,
  processResType: state.authPasswordReset.processResType,
});
export default connect(mapStateToProps, { checkConfirmation })(Confirmation);
