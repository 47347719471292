import React, { Component } from "react";
import Property from "./../properties/Property";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import shortid from "shortid";
import classnames from "classnames";
import { isNumeric } from "./../../mainConf/functions";
import { getPropertiesPerProvince } from "./../../actions/searchActions";
import PaginationForProperties from "./../layout/Pagination/PaginationForProperties";
// import { ResReady, ResNotComplete } from './../layout/resTypes';
import { recordsPerPage } from "./../../mainConf/index";
import { removeParam } from "./../../mainConf/functions.jsx";
import queryString from "query-string";
import { Link } from "react-router-dom";
import SearchBar from "./../search/SearchBar.jsx";
import { Helmet } from "react-helmet";
import { linkDomain } from "./../../mainConf";
class MainPagePerProvince extends Component {
  constructor(props) {
    super();
    const { currentProvince } = props.match.params;
    let title = "عقارات في " + currentProvince + " | Ikar.sy";
    this.state = {
      is_page_param: false,
      pageTitle: title,
      pageDescription: `عقارات شقق، بيوت، أراضي، تجاري، بنايات و شاليهات للبيع و للإيجار في ${currentProvince} | Ikar.sy
      `,
      propertiesPerProvince: [],
      total_properties: -1,
      searchBarFullShow: false,
      requestSuccessMessage: {},
      errors: {},
      pageNumber: 1,
      searchParameters: {},
      currentProvince: "",
      main_type_param: "",
      business_offer_param: "",
      resType: "ready",
      locationSearch: "",
      normalUrl: "",
      largeScreen: "",
    };
  }

  async componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    let searchURLParams = await queryString.parse(this.props.location.search);
    let lowerSearchURLParams = {};
    await Object.keys(searchURLParams).forEach(function (key, value) {
      lowerSearchURLParams[key.toLowerCase()] = searchURLParams[key];
    });
    searchURLParams = lowerSearchURLParams;
    var main_type_param =
      searchURLParams.main_type !== undefined &&
      searchURLParams.main_type !== null
        ? searchURLParams.main_type
        : "";

    var business_offer_param =
      searchURLParams.business_offer !== undefined &&
      searchURLParams.business_offer !== null
        ? searchURLParams.business_offer
        : "";
    if (main_type_param !== null) {
      await this.setState({ main_type_param });
    }

    if (business_offer_param !== null) {
      await this.setState({ business_offer_param });
    }
    const { currentProvince } = this.props.match.params;
    this.setState({ currentProvince });
    const is_page_param =
      searchURLParams.page === undefined ||
      searchURLParams.page === null ||
      !isNumeric(searchURLParams.page)
        ? false
        : true;
    this.setState({ is_page_param });
    const page =
      searchURLParams.page !== undefined &&
      searchURLParams.page !== null &&
      isNumeric(searchURLParams.page)
        ? searchURLParams.page
        : "1";
    await this.setState({ locationSearch: this.props.location.search, page });
    this.onSearchSubmit(
      recordsPerPage,
      page,
      currentProvince,
      business_offer_param,
      main_type_param
    );
  }

  onSearchSubmit = (
    recordsPerPage,
    pageNumber,
    province,
    business_offer_param,
    main_type_param
  ) => {
    var mainTypeParam = main_type_param;

    var businessOfferParam = business_offer_param;

    const property = {};
    if (mainTypeParam !== undefined || businessOfferParam !== undefined) {
      if (mainTypeParam !== "" && mainTypeParam !== null) {
        property.main_type = mainTypeParam;
      }
      if (businessOfferParam !== "" && businessOfferParam !== null) {
        property.business_offer = businessOfferParam;
      }
    }
    property.visible = true;
    var Province = "";
    if (province === undefined) {
      Province = this.state.currentProvince;
    } else if (province !== "all") {
      Province = province;
    }
    property.province = Province;
    property.order = {};
    property.order.created_at = "desc";
    property.ranges = {};
    // property.ranges.days = {};
    // property.ranges.days.min = -999999;
    // property.ranges.days.max = -1;
    this.setState({ pageNumber });
    this.props.getPropertiesPerProvince(
      property,
      recordsPerPage,
      pageNumber,
      Province
    );
  };
  // removeSearchQuery = async () => {
  //   localStorage.removeItem('ikar_main_type_param');
  //   localStorage.removeItem('ikar_business_offer_param');
  //   await this.setState({ main_type_param: '', business_offer_param: '' });
  //   this.onSearchSubmit(
  //     recordsPerPage,
  //     1,
  //     this.state.currentProvince,
  //     null,
  //     null
  //   );
  // };
  resize() {
    let currentWidth = window.innerWidth;
    if (currentWidth >= 768) {
      this.setState({ largeScreen: "t" });
    } else {
      this.setState({ largeScreen: "f" });
    }
  }
  async componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.location.search !== this.state.locationSearch) {
      var normalUrl = await removeParam("main_type", nextProps.location.search);
      normalUrl = await removeParam("business_offer", normalUrl);
      await this.setState({
        normalUrl,
        locationSearch: nextProps.location.search,
      });
      const searchURLParams = await queryString.parse(
        nextProps.location.search
      );
      const main_type_param =
        searchURLParams.main_type !== undefined &&
        searchURLParams.main_type !== null
          ? searchURLParams.main_type
          : "";

      const business_offer_param =
        searchURLParams.business_offer !== undefined &&
        searchURLParams.business_offer !== null
          ? searchURLParams.business_offer
          : "";
      const page =
        searchURLParams.page !== undefined &&
        searchURLParams.page !== null &&
        isNumeric(searchURLParams.page)
          ? searchURLParams.page
          : "1";
      if (main_type_param !== null) {
        await this.setState({ main_type_param });
      }
      if (business_offer_param !== null) {
        await this.setState({ business_offer_param });
      }
      this.onSearchSubmit(
        recordsPerPage,
        page,
        this.props.match.params.currentProvince,
        business_offer_param,
        main_type_param
      );
    }
    if (
      nextProps.resType !== undefined &&
      nextProps.resType !== this.state.resType
    ) {
      const { resType } = nextProps;
      this.setState({ resType });
    }

    if (
      nextProps.propertiesPerProvince !== this.state.propertiesPerProvince &&
      nextProps.propertiesPerProvince[0] !== undefined
    ) {
      const { propertiesPerProvince } = nextProps;
      this.setState({
        propertiesPerProvince,
        total_properties: propertiesPerProvince[0].total_properties,
        currentProvince: nextProps.currentProvince,
      });
      if (nextProps.pageNumber === 1) {
        this.setState({
          pageNumber: nextProps.pageNumber,
        });
      }
      if (nextProps.location.search !== this.state.locationSearch) {
        normalUrl = await removeParam("main_type", nextProps.location.search);
        normalUrl = await removeParam("business_offer", normalUrl);
        await this.setState({
          normalUrl,
          locationSearch: nextProps.location.search,
        });
        const searchURLParams = await queryString.parse(
          nextProps.location.search
        );
        const main_type_param =
          searchURLParams.main_type !== undefined &&
          searchURLParams.main_type !== null
            ? searchURLParams.main_type
            : "";

        const business_offer_param =
          searchURLParams.business_offer !== undefined &&
          searchURLParams.business_offer !== null
            ? searchURLParams.business_offer
            : "";
        if (main_type_param !== null) {
          await this.setState({ main_type_param });
        }
        if (business_offer_param !== null) {
          await this.setState({ business_offer_param });
        }
      }
    } else if (
      nextProps.propertiesPerProvince[0] === undefined ||
      nextProps.propertiesPerProvince.length === 0
    ) {
      this.setState({
        propertiesPerProvince: [],
        total_properties: 0,
      });
    }
  }
  onButtonClick = async (province, e) => {
    e.preventDefault();
    // this.onSearchSubmit(recordsPerPage, 1, province);

    //window.location = `/properties_per_province/${province}?&page=`;
    var normalUrl = await removeParam("page", this.state.locationSearch);
    var str = normalUrl === "" ? "?" : "";
    window.location = `/properties_per_province/${province}${normalUrl}${str}`;
  };
  render() {
    const {
      propertiesPerProvince,
      total_properties,
      // pageNumber,
      currentProvince,
      main_type_param,
      business_offer_param,
      largeScreen,
    } = this.state;

    if (
      propertiesPerProvince !== undefined &&
      propertiesPerProvince.length > 0
    ) {
    }
    const provinces = [
      "دمشق",
      "ريف دمشق",
      "حلب",
      "طرطوس",
      "اللاذقية",
      "حمص",
      "حماة",
      "السويداء",
      "الحسكة",
      "درعا",
      "ادلب",
      "دير الزور",
      "القنيطرة",
      "الرقة",
    ];
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.pageTitle}</title>
          <meta name="description" content={this.state.pageDescription} />
          <link
            rel="canonical"
            href={`${linkDomain}/properties_per_province/${currentProvince}${
              this.state.pageNumber > 0 && this.state.is_page_param
                ? `?page=${this.state.pageNumber}`
                : ""
            }`}
          />
        </Helmet>
        {largeScreen === "t" ? (
          <SearchBar
            searchBarFullShow={this.state.searchBarFullShow}
            searchType="main_search"
            destination="/search"
            urlFilters="false"
            searchMode="off"
          ></SearchBar>
        ) : null}
        <div className="mb-3 text-center">
          <h1 className="main_title">العقارات تبعاً للمحافظات</h1>
        </div>
        <div className="property-container">
          <div className="container-fluid">
            {main_type_param !== "" || business_offer_param !== "" ? (
              <div className="text-right">
                <div className="mb-1">
                  <span className="display_7">
                    {`يتم فلترة النتائج تبعا للقيم(`}
                  </span>
                  <span className="display_7">
                    {business_offer_param !== "" ? (
                      <React.Fragment>
                        <span className="display_7">نوع العرض: </span>
                        <span className="display_gold">
                          {business_offer_param}
                        </span>{" "}
                      </React.Fragment>
                    ) : null}
                    {business_offer_param !== "" && main_type_param !== ""
                      ? " - "
                      : ""}
                    {main_type_param !== "" ? (
                      <React.Fragment>
                        <span className="display_7">نوع العقار: </span>
                        <span className="display_gold">
                          {main_type_param.split(" OR ").join(",")}
                        </span>
                      </React.Fragment>
                    ) : null}

                    <span className="display_7">{")"}</span>
                  </span>
                  <Link
                    to={{
                      pathname: this.props.location.pathname,
                      // query: { search_mode: 'min' }
                      search: this.state.normalUrl,
                    }}
                    className={classnames(
                      "btn",
                      "px-0",
                      "mb-2",
                      "mx-2",
                      "submit-button"
                    )}
                    style={{ width: "200px" }}
                    // onClick={this.onSearchButtonSubmit}
                  >
                    حذف الفلترة الحالية للبحث
                  </Link>
                </div>
              </div>
            ) : null}
            <div className="text-right mx-2 mb-2" style={{ direction: "rtl" }}>
              {(provinces || []).map((province) => (
                <button
                  key={shortid.generate()}
                  className={classnames(
                    "btn",
                    "btn-outline-warning",
                    "mr-1",
                    "px-1",
                    "my-1",
                    "btn-province",
                    { "btn-province-active": province === currentProvince }
                  )}
                  //className="       "
                  onClick={this.onButtonClick.bind(this, province)}
                >
                  <span style={{ color: "#581f18" }}>{province}</span>
                </button>
              ))}
            </div>
            <div className="row justify-content-around align-items-center">
              {propertiesPerProvince !== [] &&
                propertiesPerProvince !== undefined &&
                (propertiesPerProvince || []).map((property) => (
                  <Property
                    key={shortid.generate()}
                    property={property}
                    isLoggedIn={false}
                    ikarAdmin={false}
                    requestSuccessMessage={this.state.requestSuccessMessage}
                  />
                ))}
            </div>
            <PaginationForProperties
              resultLabel="عقار"
              recordsNumber={total_properties}
              // pageNumber={pageNumber}
              // onPageChange={this.onSearchButtonSubmit}
              showMessage={true}
              destination={`/properties_per_province/${currentProvince}`}
              locationSearch={this.state.locationSearch}
            ></PaginationForProperties>
          </div>
        </div>
        {largeScreen === "f" ? (
          <SearchBar
            searchBarFullShow={this.state.searchBarFullShow}
            searchType="main_search"
            destination="/search"
            urlFilters="false"
            searchMode="off"
          ></SearchBar>
        ) : null}
      </React.Fragment>
    );
  }
}

MainPagePerProvince.propTypes = {
  propertiesPerProvince: PropTypes.array.isRequired,
  getPropertiesPerProvince: PropTypes.func.isRequired,
  currentProvince: PropTypes.string.isRequired,
  // pageNumber: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  propertiesPerProvince: state.search.propertiesPerProvince,
  currentProvince: state.search.currentProvince,
  pageNumber: state.search.pageNumber,
  resType: state.search.resType,
});

export default connect(mapStateToProps, { getPropertiesPerProvince })(
  MainPagePerProvince
);
