import React, { Component } from "react";
import { connect } from "react-redux";
//import bootstrap from 'bootstrap';
import About from "./About";
import { Accordion, Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Helmet } from "react-helmet";
import jQuery from "jquery";
import { PropTypes } from "prop-types";
//import { loginProcess } from '../actions/authActions';
// import { Button } from 'primereact/button';
//import { compose } from 'redux';
import { withRouter } from "react-router-dom";
//import { takeAgencyRole } from '../actions/authActions';
class DownloadApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      largeScreen: false,
      metaTitle: "تطبيق واحد للخدمات العقارية في سوريا | Ikar.sy",
      metaDescription: "Ikar.sy | Mobile application"
    };
  }

  componentDidMount() {
    this.resize();
  }
  resize() {
    let currentWidth = window.innerWidth;
    if (currentWidth >= 992) {
      this.setState({ largeScreen: true });
    } else {
      this.setState({ largeScreen: false });
    }
  }
  render() {
    const styles = {
      bold: { fontWeight: "bold" },
      italic: { fontStyle: "italic" },
      underline: { textDecorationLine: "underline" },
    };

    return (
      // <div class="container">
      //     <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3">
      //         <div class="cols-auto">
      //             <a href='hjhjhjjhj'>
      //             Download
      //             </a>
      //         </div>
      //         <div class="cols-auto">
      //           <a href='hjhjhjjhj'>
      //            Download from google play
      //           </a>
      //         </div>
      //     </div>
      //     <div class="row">
      //             <div class="col">
      //             1 of 3
      //             </div>
      //             <div class="col-md-auto">
      //             Variablehhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh
      //             </div>
      //             <div class="col col-lg-2">
      //             3 of 3
      //             </div>
      //         </div>

      // </div>
      <div>
       <Helmet>
          <title>{this.state.metaTitle}</title>
          <meta name="description" content={this.state.metaDescription} />
        </Helmet>
        <div class="container">
          <h1
            class="text-warning text-center main_title_2"
            style={{ direction: "rtl" }}
          >
            {" "}
تطبيق واحد للخدمات العقارية في سوريا
          </h1>
          <br />
          <div class="row">
            <div class="col-sm cols-md-6-auto">
              <br />
              <img
                src={"./images/ikar_App.jpg"}
                class="img-fluid"
                alt="Ikar mobile app"
              />
            </div>

            <div class="col-sm text-right">
              <br />

              <span class="text-warning main_title font-weight-bold">
                Ikarتطبيق{" "}
              </span>
              <br />
              <br />
              <p class="display_6">
                {" "}
                يجمع لك المكاتب العقارية كلها في مكان واحد لتحقيق هدفك العقاري
                بسهولة وسرعة
              </p>
              <br />
              <div>
              <p className="display_gold mb-1">تحميل مباشر، أندرويد فقط</p>
                <a
                  className="btn btn-outline-warning my-1 my-sm-0-auto"
                  href="http://www.ikar.sy/public/ikar-app.apk"
                >
                  <FontAwesomeIcon icon={["fas", "download"]} />
                  <span className=" px-3">Download </span>
                </a>
                <p className="display_gold mb-1"> Google Play</p>
                <a
                  className="btn btn-outline-warning my-1 my-sm-0-auto"
                  href=" https://play.google.com/store/apps/details?id=com.ikar.ikar"
                >
                  <FontAwesomeIcon icon={["fas", "download"]} />
                  <span className=" px-3">Download </span>
                  </a>
              </div>

              <div>
                {/* <p>أو بشكل مباشر Google play قريبا تحميل التطبيق من </p> */}
                {/* <a
                  className="btn btn-outline-warning my-2 my-sm-0-auto"
                  href="hjhjhjjhj"
                >
                  <FontAwesomeIcon
                    icon={["fab", "google-play"]}
                    size="1x"
                    style={{ color: "#warning" }}
                  />
                  <span className="px-3">Google Play</span>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(withRouter)(DownloadApp);
