export const isNumeric = (num) => {
   return /^\d+$/.test(num);
};
export const removeParam = (key, sourceURL) => {
   var rtn = sourceURL.split('?')[0],
      param,
      params_arr = [],
      queryString = sourceURL.indexOf('?') !== -1 ? sourceURL.split('?')[1] : '';
   if (queryString !== '') {
      params_arr = queryString.split('&');
      for (var i = params_arr.length - 1; i >= 0; i -= 1) {
         param = params_arr[i].split('=')[0];
         if (param === key) {
            params_arr.splice(i, 1);
         }
      }
      rtn = rtn + '?' + params_arr.join('&');
   }
   return rtn;
};

export const formatMoney = (
   amount,
   decimalCount = 0,
   decimal = '.',
   thousands = ','
) => {
   try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 0 : decimalCount;

      const negativeSign = amount < 0 ? '-' : '';

      let i = parseInt(
         (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      let j = i.length > 3 ? i.length % 3 : 0;

      return (
         negativeSign +
         (j ? i.substr(0, j) + thousands : '') +
         i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
         (decimalCount ?
            decimal +
            Math.abs(amount - i)
               .toFixed(decimalCount)
               .slice(2) :
            '')
      );
   } catch (e) {
      console.log(e);
   }
};

export const buildSearchTitle = (
   main_type_param,
   type,
   business_offer,
   furnitured,
   province,
   area
) => {
   let pageTitle = `${type === 'نوع العقار'
      ? main_type_param !== '' && main_type_param !== undefined
         ? main_type_param.replace(/OR /g, '')
         : 'عقارات'
      : type === 'شقة'
         ? 'شقق'
         : type === 'عيادة'
            ? 'عيادات'
            : type === 'بيت'
               ? 'بيوت'
               : type === 'مطعم'
                  ? 'مطاعم'
                  : type === 'مكتب'
                     ? 'مكاتب'
                     : type === 'صالة'
                        ? 'صالات'
                        : type === 'فندق'
                           ? 'فنادق'
                           : type === 'مقهى'
                              ? 'مقاهي'
                              //       : type === 'أراضي زراعية' ||
                              //         type === 'أرض للبناء' ||
                              //         type === 'أراضي ذات رخصة سياحية' ||
                              //         type === 'أراضي ذات رخصة صناعية'
                              //       ? 'أراضي'
                              : `${type}`
      } ${business_offer !== 'نوع العرض' && business_offer === 'بيع' ? 'للبيع' : ''
      } ${business_offer !== 'نوع العرض' && business_offer === 'تأجير'
         ? 'للايجار'
         : ''
      }  ${furnitured === true ? 'مفروش' : ''} ${province !== 'المحافظة' || area !== 'المنطقة' ? 'في ' : ''
      } ${province === 'المحافظة' && area === 'المنطقة' ? 'في ' : ''} ${province === 'المحافظة' ? 'سوريا' : `${province}`
      } | Ikar.sy`;
   return pageTitle;
};

export const buildSearchDescription = (
   main_type_param,
   type,
   business_offer,
   furnitured,
   province,
   area,
   pageNumber,
   bedrooms,
   bathrooms,
   duplex,
   basement,
   lift,
   parking,
   garage,
   view_direction,
   min_area,
   max_area,
   min_price,
   max_price,
   floor
) => {
   // let pageDescription = `عقارات شقق، بيوت، أراضي، تجاري، بنايات و شاليهات للبيع في ${
   //   province === 'المحافظة' ? 'سوريا' : `${province}`
   // } | Ikar.sy`;

   let pageDescription = `${type === 'نوع العقار'
      ? main_type_param !== '' && main_type_param !== undefined
         ? main_type_param.replace(/OR /g, '')
         : 'عقارات'
      : type === 'شقة'
         ? 'شقق'
         : type === 'عيادة'
            ? 'عيادات'
            : type === 'بيت'
               ? 'بيوت'
               : type === 'مطعم'
                  ? 'مطاعم'
                  : type === 'مكتب'
                     ? 'مكاتب'
                     : type === 'صالة'
                        ? 'صالات'
                        : type === 'فندق'
                           ? 'فنادق'
                           : type === 'مقهى'
                              ? 'مقاهي'
                              //       : type === 'أراضي زراعية' ||
                              //         type === 'أرض للبناء' ||
                              //         type === 'أراضي ذات رخصة سياحية' ||
                              //         type === 'أراضي ذات رخصة صناعية'
                              //       ? 'أراضي'
                              : `${type}`
      }
  ${business_offer !== 'نوع العرض' && business_offer === 'بيع' ? 'للبيع' : ''
      }
  ${business_offer !== 'نوع العرض' && business_offer === 'تأجير' ? 'للإيجار' : ''
      }
  ${business_offer !== 'نوع العرض' && business_offer === 'مزادات' ? 'للمزاد' : ''
      }
  ${business_offer !== 'نوع العرض' && business_offer === 'رهن' ? 'للرهن' : ''
      }
  ${business_offer !== 'نوع العرض' && business_offer === 'استثمار' ? 'للاستثمار' : ''
      }

  ${province !== 'المحافظة' || area !== 'المنطقة' ? 'في ' : ''
      } ${province === 'المحافظة' && area === 'المنطقة' ? 'في ' : ''} ${province === 'المحافظة' ? 'سوريا' : `${province}`}
    ${area === 'المنطقة' ? '' : `${area}`}

      ${pageNumber <= 0 ? '' : `-الصفحة `}
        ${furnitured === true ? '-مفروش-' : ''}
  ${duplex === true ? '-دوبلكس-' : ''}
  ${garage === true ? '-كراج-' : ''}
  ${basement === true ? '-ملجأ-' : ''}
  ${lift === true ? '-مصعد-' : ''}
  ${parking === true ? '-موقف-' : ''}
  ${floor === "الطابق" ? '' : `-الطابق `}
  ${floor === "الطابق" ? '' : `${floor}`}
    ${view_direction === "الاتجاه" ? '' : `اتجاه `}
      ${view_direction === "الاتجاه" ? '' : `${view_direction}`}
        ${bedrooms === "الغرف" ? '' : `-عدد الغرف`}
        ${bedrooms === "الغرف" ? '' : `${bedrooms}`}
          ${bathrooms === "غرف الاستحمام" ? '' : `-عدد غرف الاستحمام`}
          ${bathrooms === "غرف الاستحمام" ? '' : `${bathrooms}`}
          ${min_area === "" || min_area === "أدنى مساحة" || parseInt(min_area) === 0 ? '' : `-مساحة أكبر من`} ${min_area === "" || min_area === "أدنى مساحة" || parseInt(min_area) === 0 ? '' : `${parseInt(min_area)}`}
            ${max_area === "" || max_area === "أدنى مساحة" || parseInt(max_area) === 0 ? '' : `-مساحة أقل من`} ${max_area === "" || max_area === "أدنى مساحة" || parseInt(max_area) === 0 ? '' : `${parseInt(max_area)}`}
              ${min_price === "" || min_price === "أدنى سعر" || parseInt(min_price) === 0 ? '' : `-سعر أكبر من`} ${min_price === "" || min_price === "أدنى سعر" || parseInt(min_price) === 0 ? '' : `${parseInt(min_price)}`}
                ${max_price === "" || max_price === "أعلى سعر" || parseInt(max_price) === 0 ? '' : `-سعر أقل من`} ${max_price === "" || max_price === "أعلى سعر" || parseInt(max_price) === 0 ? '' : `${parseInt(max_price)}`}
      ${pageNumber <= 0 ? '' : `${pageNumber}`}
   | Ikar.sy`;


   // let pageDescription = `${
   //   business_offer !== 'نوع العرض' && business_offer === 'بيع' ? 'شراء' : ''
   // } ${
   //   business_offer !== 'نوع العرض' && business_offer === 'تأجير'
   //     ? 'استئجار'
   //     : ''
   // } ${type === 'نوع العقار' ? 'عقار' : `${type}`} ${
   //   province !== 'المحافظة' || area !== 'المنطقة' ? 'في ' : ''
   // } ${province === 'المحافظة' && area === 'المنطقة' ? 'في ' : ''} ${
   //   province === 'المحافظة' ? 'سوريا' : `${province}`
   // } , بحث مع فلاتر متعددة , أفضل عروض العقارات في سوريا`;
   return pageDescription;
};

export const buildShowPropertyTitle = (
   title,
   type,
   bedrooms,
   flatType,
   officeType,
   furnitured,
   business_offer,
   province,
   area
) => {
   // let pageTitle = `${type === 'نوع العقار' ? 'عقار' : `${type.trim()}`} ${
   //   bedrooms !== 'الغرف' &&
   //   bedrooms !== undefined &&
   //   bedrooms !== null &&
   //   bedrooms === 1 &&
   //   flatType
   //     ? 'غرفة نوم واحدة'
   //     : ''
   // } ${
   //   bedrooms !== 'الغرف' &&
   //   bedrooms !== undefined &&
   //   bedrooms !== null &&
   //   bedrooms === 1 &&
   //   officeType
   //     ? 'غرفة واحدة'
   //     : ''
   // } ${
   //   bedrooms !== 'الغرف' &&
   //   bedrooms !== undefined &&
   //   bedrooms !== null &&
   //   bedrooms === 2 &&
   //   flatType
   //     ? 'غرفتي نوم'
   //     : ''
   // } ${
   //   bedrooms !== 'الغرف' &&
   //   bedrooms !== undefined &&
   //   bedrooms !== null &&
   //   bedrooms === 2 &&
   //   officeType
   //     ? 'غرفتي'
   //     : ''
   // } ${
   //   bedrooms !== 'الغرف' &&
   //   bedrooms !== undefined &&
   //   bedrooms !== null &&
   //   bedrooms > 2 &&
   //   flatType
   //     ? ` ${bedrooms} غرف نوم`
   //     : ''
   // } ${
   //   bedrooms !== 'الغرف' &&
   //   bedrooms !== undefined &&
   //   bedrooms !== null &&
   //   bedrooms > 2 &&
   //   officeType
   //     ? ` ${bedrooms} غرف`
   //     : ''
   // } ${furnitured === true ? 'مفروشة' : ''} ${
   //   business_offer !== 'نوع العرض' && business_offer === 'بيع' ? 'للبيع' : ''
   // } ${
   //   business_offer !== 'نوع العرض' && business_offer === 'تأجير'
   //     ? 'للايجار'
   //     : ''
   // } ${province !== 'المحافظة' || area !== 'المنطقة' ? 'في ' : ''} ${
   //   province === 'المحافظة' ? '' : `${province}`
   // } ${area !== 'المنطقة' && area !== province ? `${area}` : ''} | Ikar.sy`;
   return title;
};
export const buildShowPropertyDescription = (
   title,
   gps_location,
   description,
   agency_name
) => {
   let pageDescription = `${agency_name} , ${description
      .split('')
      .slice(0, 100)
      .join('')}  | Ikar.sy`;
   // let pageDescription = `${title} ${
   //   gps_location !== undefined && gps_location !== ''
   //     ? ', العرض مع خريطة للموقع '
   //     : ''
   // } , ${description.split(' ').slice(0, 11).join(' ')} | Ikar.sy`;
   return pageDescription;
};


const randomNumberInRange = (min, max) => {
   return Math.floor(Math.random() *
      (max - min + 1)) + min;
};

export const buildAlt = (
   title,
   type,
   business_offer,
   province,
   area,
   furnitured,
   duplex,
   basement,
   lift,
   parking,
   garage,
   thumbnail,
) => {

   let text = "";
   let alt = "";
   let ale_suffix = ""
   if (thumbnail !== null && thumbnail !== undefined) {
      text = thumbnail.split("/").slice(-1)[0].split("-").slice(-1)[0].split(".")[0];
      if (!title.includes(business_offer)) {
         ale_suffix += " " + business_offer
      }
      if (!title.includes(type)) {
         ale_suffix += " " + type
      }
      if (!title.includes(province)) {
         ale_suffix += " " + province
      }
      if (!title.includes(area)) {
         ale_suffix += " " + area
      }
      if (furnitured && !title.includes('مفروش')) {
         // eslint-disable-next-line no-useless-concat
         ale_suffix += " مفروش"
      }
      if (duplex && !title.includes('دوبلكس')) {
         ale_suffix += " دوبلكس"
      }
      if (lift && !title.includes('مصعد')) {
         ale_suffix += " مصعد"
      }
      if (garage && !title.includes('كراج')) {
         ale_suffix += " كراج"
      }
      if (basement && !title.includes('ملجأ')) {
         ale_suffix += " ملجأ"
      }
      if (parking && !title.includes('موقف')) {
         ale_suffix += " موقف"
      }

      alt = `${title} - ${ale_suffix} - ${text} `
   } else {
      alt = `${title} - ${ale_suffix} `
   }

   return alt;
};
export const buildGalleryTitle = (
   title,
) => {
   let alt = "";
   alt = ` صور ${title}`
   return alt;
};