import { UPDATE_PRIVACY } from './../actions/types';
import shortid from 'shortid';
const initialState = {
  privacys: {},
  privacy: {},
  requestFailed: false,
  requestFailedMessage: {},
  requestSuccessMessage: '',
  short_id: '',
};

export default function (state = initialState, action) {
  
  switch (action.type) {
    case UPDATE_PRIVACY:
      if (
        (Object.keys(action.payload).length > 0 &&
          action.payload.errorMessage === '') ||
        action.payload.errorMessage === undefined
      ) {
           
        action.payload.processStatus = 'edit is succeeded';

        return {
          //...state,
          privacy: action.payload,
          //add state data
          privacys: {},
          requestFailed: false,
         // requestFailedMessage: { error: 'no' },
          requestSuccessMessage: action.payload.message,
          short_id: shortid.generate(),
          //
        };
      } else {
        return {
          //...state,
          privacy: {},
          requestFailed: true,
          requestFailedMessage: action.payload,
          //add state data
          privacys: {},
          requestSuccessMessage: 'error in edit question',
          short_id: shortid.generate(),
          //
        };
      }
    default:
      return state;
  }
}
