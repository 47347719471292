import React from "react";
import { linkDomain } from "./../../../mainConf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const FloatingButton = () => {
  return (
    <div
      style={{
        position: "fixed",
        bottom: "15px",
        right: "15px",
        zIndex: "1000",
      }}
      className="FloatingButton"
    >
      <span
        className="search-button"
        data-toggle="tooltip"
        data-placement="top"
        title="ابحث عن عقارك المفضل"
      >
        <a
          href={`${linkDomain}/search`}
          className="btn btn-warning"
          style={{ color: "#ffffff", borderRadius: "50%" }}
          role="button"
        >
          {" "}
          <FontAwesomeIcon icon={["fas", "search"]} size="1x" />
        </a>
      </span>
    </div>
  );
};

export default FloatingButton;
