import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getUser } from './../../actions/userActions';
import { editUser } from './../../actions/userActionsUD';
import { Growl } from 'primereact/growl';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputMask from 'react-input-mask';
import { Helmet } from 'react-helmet';
class EditUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: '',
      pageDescription: '',
      requestSuccessMessage: '',
      noMessages: true,
      user: {},
      id: '',
      processStatus: '',
      name: '',
      contact_email: '',
      password: '',
      password_configuration: '',
      is_agency_admin: true,
      is_ikar_admin: false,
      surname: '',
      title: '',
      description: '',
      roles: [],
      mobile: '009639',
      facebook: '',
      whatsapp: '009639',
      agency_ref_id: '',
      agency_rank: 1,
      errors: {},
    };
  }
  componentWillReceiveProps(nextProps, nextState) {
    if (
      nextProps.requestSuccessMessage !== this.state.requestSuccessMessage &&
      this.state.requestSuccessMessage !== ''
    ) {
      if (nextProps.requestSuccessMessage === 'edit is succeeded') {
        this.setState({
          requestSuccessMessage: nextProps.requestSuccessMessage,
        });
        this.growl.show({
          severity: 'success',
          summary: 'تم تعديل المستخدم بنجاح',
        });
      }
    }
    if (nextProps.user.processStatus !== this.state.processStatus) {
      var {
        id,
        name,
        contact_email,
        //password,
        is_agency_admin,
        is_ikar_admin,
        surname,
        title,
        description,
        roles,
        mobile,
        facebook,
        whatsapp,
        agency_ref_id,
        agency_rank,
        processStatus,
      } = nextProps.user;

      surname = surname === null ? '' : surname; //
      title = title === null ? '' : title; //
      description = description === null ? '' : description; //

      facebook = facebook === null ? '' : facebook; //
      whatsapp = whatsapp === null ? '' : whatsapp; //

      this.setState({
        id,
        name,
        contact_email,
        //password,
        is_agency_admin,
        is_ikar_admin,
        surname,
        title,
        description,
        roles,
        mobile,
        facebook,
        whatsapp,
        agency_ref_id,
        agency_rank,
        processStatus,
      });
    }
  }
  componentDidMount() {
    const ikarAdmin = this.props.ikarAdmin;
    const isTakingAgencyRole = this.props.isTakingAgencyRole;
    var userEmail = this.props.takenAgencyRoleEmail;

    const takenAgencyRoleToken = this.props.takenAgencyRoleToken;
    if (!isTakingAgencyRole) {
      userEmail = this.props.ikarEmail;
    }
    this.props.getUser(
      ikarAdmin,
      isTakingAgencyRole,
      userEmail,
      takenAgencyRoleToken
    );
  }
  onSubmit = async (e) => {
    e.preventDefault();
    await this.setState({
      requestSuccessMessage: 'ready',
    });
    const {
      id,
      name,
      contact_email,
      password,
      password_configuration,
      // is_agency_admin,
      // is_ikar_admin,
      surname,
      title,
      description,
      // roles,
      mobile,
      facebook,
      whatsapp,
      // agency_ref_id,
      // agency_rank
    } = this.state;
    var errors = {};
    if (contact_email.trim() === '') {
      errors.contact_email = 'يجب ادخال البريد الالكتروني';
    }

    if (password.trim() === '') {
      errors.password = 'يجب ادخال كلمة السر';
    }
    if (password.length < 8) {
      errors.password = 'كلمة السر على الاقل ثمانية رموز';
    }
    if (password_configuration.trim() === '') {
      errors.password_configuration = 'يجب ادخال اعادة كلمة السر';
    }
    if (password !== password_configuration) {
      errors.password_configuration = 'كلمتا السر غير متطابقتين';
    }
    if (name.trim() === '') {
      errors.name = 'يجب ادخال الاسم';
    }
    if (surname.trim() === '') {
      errors.surname = 'يجب ادخال اللقب';
    }

    if (title.trim() === '') {
      errors.title = 'يجب ادخال العنوان ';
    }

    if (mobile.trim() === '' || mobile.trim() === '009639') {
      errors.mobile = 'يجب ادخال رقم الموبايل';
    } else if (mobile.trim() !== '' && mobile.trim() !== '009639') {
      if (String(mobile.trim()).charAt(0) !== '0') {
        errors.mobile = 'يجب التأكد من رقم الموبايل';
      }
      if (mobile.trim().length < 15 || mobile.trim().length > 15) {
        errors.mobile = 'يجب التأكد من رقم الموبايل';
      }
      if (mobile.trim().length > 1 && !this.isNumeric(mobile.trim())) {
        errors.mobile = 'يجب التأكد من رقم الموبايل';
      }
    }

    // if (other_phones.trim().length > 10) {
    //   errors.other_phones = 'يجب التأكد من رقم الاتصال';
    // }

    if (whatsapp.trim() !== '' && whatsapp.trim() !== '009639') {
      if (String(whatsapp.trim()).charAt(0) !== '0') {
        errors.whatsapp = 'يجب التأكد من رقم الواتس أب';
      }
      if (whatsapp.trim().length < 15 || whatsapp.trim().length > 15) {
        errors.whatsapp = 'يجب التأكد من رقم الواتس أب';
      }
      if (whatsapp.trim().length > 1 && !this.isNumeric(whatsapp.trim())) {
        errors.whatsapp = 'يجب التأكد من رقم الواتس أب';
      }
    }

    // if (telegram.trim() !== '' && telegram.trim() !== '009639') {
    //   if (String(telegram.trim()).charAt(0) !== '0') {
    //     errors.telegram = 'يجب التأكد من رقم التيلغرام';
    //   }
    //   if (telegram.trim().length < 15 || telegram.trim().length > 15) {
    //     errors.telegram = 'يجب التأكد من رقم التيلغرام';
    //   }
    //   if (telegram.trim().length > 1 && !this.isNumeric(telegram.trim())) {
    //     errors.telegram = 'يجب التأكد من رقم التيلغرام';
    //   }
    // }
    // if (facebook.trim() === '') {
    //   this.setState({
    //     errors: { facebook: 'يجب ادخال عنوان صفحة ال فيسبوك ' }
    //   });
    //   return;
    // }
    // if (whatsapp.trim() === '') {
    //   this.setState({
    //     errors: { whatsapp: 'يجب ادخال رقم ال واتس اب ' }
    //   });
    //   return;
    // }
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    this.setState({ errors: {} });
    const admin = {
      id,
      name: name === '' ? null : name,
      password,
      surname: surname === '' ? null : surname,
      title: title === '' ? null : title,
      description: description === '' ? null : description,

      mobile:
        mobile.replace(/\s/g, '') === '' ||
        mobile.replace(/\s/g, '') === '009639'
          ? null
          : mobile.replace(/\s/g, ''),

      whatsapp:
        whatsapp.replace(/\s/g, '') === '' ||
        whatsapp.replace(/\s/g, '') === '009639'
          ? null
          : whatsapp.replace(/\s/g, ''),

      facebook: facebook === '' ? null : facebook,
    };
    const UserData = { admin };
    const isTakingAgencyRole = this.props.isTakingAgencyRole;
    const takenAgencyRoleToken = this.props.takenAgencyRoleToken;
    const processType = 'edit is succeeded';
    this.props.editUser(
      UserData,
      isTakingAgencyRole,
      takenAgencyRoleToken,
      processType
    );
    this.setState({ noMessages: false });
    // this.setState({
    //   name: '',
    //   contact_email: '',
    //   password: '',
    //   password_configuration: '',
    //   is_agency_admin: true,
    //   is_ikar_admin: false,
    //   surname: '',
    //   title: '',
    //   description: '',
    //   roles: [],
    //   mobile: '',
    //   facebook: '',
    //   whatsapp: '',
    //   agency_ref_id: '',
    //   agency_rank: 1,
    //   errors: {}
    // });
  };
  handleClear = (e) => {
    e.preventDefault();
    this.setState({
      requestSuccessMessage: '',
      noMessages: true,
      id: '',
      name: '',
      contact_email: '',
      password: '',
      password_configuration: '',
      is_agency_admin: true,
      is_ikar_admin: false,
      surname: '',
      title: '',
      description: '',
      roles: [],
      mobile: '009639',
      facebook: '',
      whatsapp: '009639',
      agency_ref_id: '',
      agency_rank: 1,
      errors: {},
      processStatus: '',
    });
  };
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  onMobileChange = (e) => {
    // if (e.target.value.length > 10 || e.target.value.length === 1) {
    //   return;
    // }
    // if (/^\d+$/.test(e.target.value)) {
    //   this.setState({ [e.target.name]: e.target.value });
    // }

    if (e.target.value.length > 15 || e.target.value.length === 4) {
      return;
    }
    if (/^(009639)\d{0,8}?$/.test(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  render() {
    const {
      name,
      contact_email,
      password,
      password_configuration,
      // is_agency_admin,
      // is_ikar_admin,
      surname,
      title,
      description,
      // roles,
      mobile,
      facebook,
      whatsapp,
      // agency_ref_id,
      agency_rank,
      errors,
      processStatus,
    } = this.state;

    return (
      <div>
        <Helmet>
          <title>{this.state.pageTitle}</title>
          <meta name="description" content={this.state.pageDescription} />
          <meta name="robots" content="noindex"></meta>
        </Helmet>
        <div className="row">
          <div className="col-md-10 mx-auto">
            {/* {message ? (
            <Alert message={message} messageType={messageType} />
          ) : null} */}
            <div className="text-center pb-4 pt-3">
              <span className="main_title">
                <FontAwesomeIcon
                  icon={['fas', 'user-edit']}
                  size="1x"
                  className="text-primary mx-2"
                />
                تعديل مستخدم
              </span>
              <Growl ref={(el) => (this.growl = el)} />
            </div>
            <form onSubmit={this.onSubmit} style={{ direction: 'rtl' }}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group text-right mb-4">
                    <label htmlFor="contact_email">البريد الالكتروني: *</label>
                    <input
                      type="email"
                      readOnly
                      className={classnames('form-control', {
                        'is-invalid': errors.contact_email,
                      })}
                      name="contact_email"
                      value={contact_email}
                      //onChange={this.onChange}
                    />
                    {errors.contact_email && (
                      <div className="invalid-feedback">
                        {errors.contact_email}
                      </div>
                    )}
                  </div>
                  <div className="form-group text-right mb-4">
                    <label htmlFor="password">كلمة السر: *</label>
                    <input
                      type="password"
                      className={classnames('form-control', {
                        'is-invalid': errors.password,
                      })}
                      name="password"
                      value={password}
                      onChange={this.onChange}
                    />
                    {errors.password && (
                      <div className="invalid-feedback">{errors.password}</div>
                    )}
                  </div>
                  <div className="form-group text-right mb-4">
                    <label htmlFor="password_configuration">
                      اعادة كلمة السر: *
                    </label>
                    <input
                      type="password"
                      className={classnames('form-control', {
                        'is-invalid': errors.password_configuration,
                      })}
                      name="password_configuration"
                      value={password_configuration}
                      onChange={this.onChange}
                    />
                    {errors.password_configuration && (
                      <div className="invalid-feedback">
                        {errors.password_configuration}
                      </div>
                    )}
                  </div>

                  <div className="form-group text-right mb-4">
                    <label htmlFor="name">اسم المستخدم: *</label>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors.name,
                      })}
                      name="name"
                      value={name}
                      onChange={this.onChange}
                    />
                    {errors.name && (
                      <div className="invalid-feedback">{errors.name}</div>
                    )}
                  </div>
                  <div className="form-group text-right mb-4">
                    <label htmlFor="surname">اللقب: *</label>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors.surname,
                      })}
                      name="surname"
                      value={surname}
                      onChange={this.onChange}
                    />
                    {errors.surname && (
                      <div className="invalid-feedback">{errors.surname}</div>
                    )}
                  </div>
                  <div className="form-group text-right mb-4">
                    <label htmlFor="title">العنوان: *</label>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors.title,
                      })}
                      name="title"
                      value={title}
                      onChange={this.onChange}
                    />
                    {errors.title && (
                      <div className="invalid-feedback">{errors.title}</div>
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group text-right mb-4">
                    <label htmlFor="description">الوصف:</label>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors.description,
                      })}
                      name="description"
                      value={description}
                      onChange={this.onChange}
                    />
                    {errors.description && (
                      <div className="invalid-feedback">
                        {errors.description}
                      </div>
                    )}
                  </div>

                  <div className="form-group text-right mb-4">
                    <label htmlFor="mobile">رقم الموبايل: *</label>
                    <InputMask
                      mask="9999 999999"
                      maskChar=" "
                      style={{ direction: 'ltr' }}
                      // type="number"
                      className={classnames('form-control', {
                        'is-invalid': errors.mobile,
                      })}
                      name="mobile"
                      value={mobile}
                      onChange={this.onChange}
                      id="mobileId"
                    />
                    {errors.mobile && (
                      <div className="invalid-feedback">{errors.mobile}</div>
                    )}
                  </div>
                  <div className="form-group text-right mb-4">
                    <label htmlFor="facebook">عنوان صفحةالفيسبوك:</label>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors.facebook,
                      })}
                      name="facebook"
                      value={facebook}
                      onChange={this.onChange}
                    />
                    {errors.facebook && (
                      <div className="invalid-feedback">{errors.facebook}</div>
                    )}
                  </div>
                  <div className="form-group text-right mb-4">
                    <label htmlFor="whatsapp">رقم الواتس اب:</label>
                    <InputMask
                      mask="9999 999999"
                      maskChar=" "
                      style={{ direction: 'ltr' }}
                      // type="number"
                      className={classnames('form-control', {
                        'is-invalid': errors.whatsapp,
                      })}
                      name="whatsapp"
                      value={whatsapp}
                      onChange={this.onChange}
                      id="whatsappId"
                    />
                    {errors.whatsapp && (
                      <div className="invalid-feedback">{errors.whatsapp}</div>
                    )}
                  </div>
                  <div className="form-group text-right mb-4">
                    <label htmlFor="agency_rank">مرتبة المكتب:</label>
                    <input
                      type="number"
                      className={classnames('form-control', {
                        'is-invalid': errors.agency_rank,
                      })}
                      name="agency_rank"
                      value={agency_rank}
                      onChange={this.onChange}
                    />
                    {errors.agency_rank && (
                      <div className="invalid-feedback">
                        {errors.agency_rank}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-8 mx-auto">
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="submit"
                        value="تعديل مستخدم"
                        className="btn btn-success btn-block submit-button m-1"
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="submit"
                        value="مسح الحقول"
                        className="btn btn-danger btn-block submit-button m-1"
                        onClick={this.handleClear}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
            {Object.keys(errors).length > 0 ? (
              <div className="alert alert-warning mt-2 text-right">
                الرجاء تعبئة الحقول المطلوبة
              </div>
            ) : null}
            {this.props.requestFailed &&
            !this.state.noMessages &&
            this.props.requestFailedMessage.errorMessage.data.errors[0]
              .detail ===
              'User contact_email must be unique. User with contact email: user123@ikar.com already exists.' ? (
              <div className="alert alert-danger mt-2 text-right">
                الايميل المدخل موجود مسبقا
              </div>
            ) : null}
            {processStatus === 'updated successfully' ? (
              //  this.growl.show({
              //     severity: 'info',
              //     summary: 'Success',
              //     detail: 'تم اضافة مستخدم بنجاح'
              //   })
              <div className="alert alert-success mt-2 text-right">
                تم تعديل مستخدم بنجاح
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

EditUser.propTypes = {
  getUser: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
  // user: PropTypes.object.isRequired,
  requestFailed: PropTypes.bool.isRequired,
  requestFailedMessage: PropTypes.object.isRequired,
  isTakingAgencyRole: PropTypes.bool.isRequired,
  takenAgencyRoleEmail: PropTypes.string.isRequired,
  takenAgencyRoleToken: PropTypes.string.isRequired,
  requestSuccessMessage: PropTypes.string.isRequired,
  ikarEmail: PropTypes.string.isRequired,
};
const mapStateToProps = (state) => ({
  user: state.user.user,
  requestFailed: state.userUD.requestFailed,
  requestFailedMessage: state.userUD.requestFailedMessage,
  requestSuccessMessage: state.userUD.requestSuccessMessage,
  isTakingAgencyRole: state.auth.isTakingAgencyRole,
  takenAgencyRoleEmail: state.auth.takenAgencyRoleEmail,
  takenAgencyRoleToken: state.auth.takenAgencyRoleToken,
  ikarEmail: state.auth.ikarEmail,
});

export default connect(mapStateToProps, { getUser, editUser })(EditUser);
