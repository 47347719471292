import React, { ReactFragment, useState } from "react";
import { linkDomain } from "./../../mainConf";
const SearchCategories = (props) => {
  const {
    business_offer_from_category,
    province_from_category,
    property_type_from_category,
    categoriesPerBusinessOffer,
    categoriesPerPropertyType,
    categoriesPerProvince,
  } = props;
  return (
    <React.Fragment>
      {(business_offer_from_category ||
        property_type_from_category ||
        province_from_category) && (
        <ol
          itemScope
          itemType="http://schema.org/BreadcrumbList"
          className="ikar-breadcrumbs text-right categories-title-div mt-2"
        >
          <li
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ListItem"
            className="mr-1"
          >
            <a
              itemProp="item"
              href={`${linkDomain}/عقارات/${business_offer_from_category}`}
            >
              <span itemProp="name">{business_offer_from_category}</span>
            </a>
            <meta itemProp="position" content="1" />
          </li>
          {` > `}
          <li
            itemProp="itemListElement"
            itemScope
            itemType="http://schema.org/ListItem"
          >
            <a
              itemProp="item"
              href={`${linkDomain}/${property_type_from_category}/${business_offer_from_category}`}
            >
              <span itemProp="name">{property_type_from_category}</span>
            </a>
            <meta itemProp="position" content="2" />
          </li>

          {province_from_category && (
            <>
              {` > `}
              <li
                itemProp="itemListElement"
                itemScope
                itemType="http://schema.org/ListItem"
              >
                <a
                  itemProp="item"
                  href={`${linkDomain}/${province_from_category}/${property_type_from_category}/${business_offer_from_category}`}
                >
                  <span itemProp="name">{province_from_category}</span>
                </a>
                <meta itemProp="position" content="2" />
              </li>
            </>
          )}
        </ol>
        // <div className="text-right categories-title-div mt-2">
        //   <span className="mr-1">
        //     <a href={`${linkDomain}/عقارات/${business_offer_from_category}`}>
        //       <span>{business_offer_from_category}</span>
        //     </a>
        //     {` > `}
        //     <a
        //       href={`${linkDomain}/${property_type_from_category}/${business_offer_from_category}`}
        //     >
        //       <span>{property_type_from_category}</span>
        //     </a>
        //     {province_from_category && (
        //       <>
        //         {` > `}
        //         <a
        //           href={`${linkDomain}/${province_from_category}/${property_type_from_category}/${business_offer_from_category}`}
        //         >
        //           <span>{province_from_category}</span>
        //         </a>
        //       </>
        //     )}
        //   </span>
        // </div>
      )}
      {Object.keys(categoriesPerPropertyType).length > 0 ? (
        <div className="mb-3 text-center categories-div">
          <div className="row">
            {Object.keys(categoriesPerPropertyType).map((item, index) => {
              const propertyTypeDisplayName = item;
              const businessOfferDisplayName = business_offer_from_category;
              return (
                <div className="col-lg-2 col-md-3 col-sm-6" key={index}>
                  <a
                    href={`${linkDomain}/${propertyTypeDisplayName}/${businessOfferDisplayName}`}
                  >
                    <span>
                      {`${
                        item.length > 15 ? `${item.substring(0, 15)} ...` : item
                      }`}
                    </span>
                    <span className="category-item">
                      {` (${categoriesPerPropertyType[item]})`}
                    </span>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
      {Object.keys(categoriesPerProvince).length > 0 ? (
        <div className="mb-3 text-center categories-div">
          <div className="row">
            {Object.keys(categoriesPerProvince).map((item, index) => {
              const provinceDisplayName = item;
              const propertyTypeDisplayName = property_type_from_category;
              const businessOfferDisplayName = business_offer_from_category;
              return (
                <div className="col-lg-2 col-md-3 col-sm-6" key={index}>
                  <a
                    href={`${linkDomain}/${provinceDisplayName}/${propertyTypeDisplayName}/${businessOfferDisplayName}`}
                  >
                    <span>
                      {`${
                        item.length > 15 ? `${item.substring(0, 15)} ...` : item
                      }`}
                    </span>
                    <span className="category-item">{` (${categoriesPerProvince[item]})`}</span>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
      {Object.keys(categoriesPerBusinessOffer).length > 0 ? (
        <div className="mb-3 text-center categories-div">
          <div className="row">
            {Object.keys(categoriesPerBusinessOffer).map((item, index) => {
              const propertyTypeDisplayName = property_type_from_category;
              const businessOfferDisplayName = item;
              return (
                <div className="col-lg-2 col-md-3 col-sm-6" key={index}>
                  <a
                    href={`${linkDomain}/${propertyTypeDisplayName}/${businessOfferDisplayName}`}
                  >
                    <span>
                      {`${
                        item.length > 15 ? `${item.substring(0, 15)} ...` : item
                      }`}
                    </span>
                    <span className="category-item">{` (${categoriesPerBusinessOffer[item]})`}</span>
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default SearchCategories;
