import React, { Component } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import shortid from 'shortid';
import { recordsPerPage } from './../../../mainConf/index';
import CurrencyFormat from 'react-currency-format';
class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      recordsNumber: 0,
      recordsPerPage: 3,
      pagesNumber: 0,
      visiblePagesNumber: 8,
      resultLabel: '',
      showMessage: false,
      pRange: []
      //,extraPages: false
    };
  }

  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.recordsNumber !== this.state.recordsNumber) {
      const pagesNumber = Math.ceil(nextProps.recordsNumber / recordsPerPage);
      //let extraPages = pagesNumber <= this.state.visiblePagesNumber ? false : true;
      this.setPaginationRange(pagesNumber, 1);
      if (nextProps.showMessage !== undefined) {
        this.setState({
          recordsNumber: nextProps.recordsNumber,
          recordsPerPage: recordsPerPage,
          pagesNumber,
          resultLabel: nextProps.resultLabel,
          showMessage: nextProps.showMessage
          //,extraPages
        });
      } else {
        this.setState({
          recordsNumber: nextProps.recordsNumber,
          recordsPerPage: recordsPerPage,
          pagesNumber,
          resultLabel: nextProps.resultLabel
          //,extraPages
        });
      }
    }
    if (
      nextProps.pageNumber !== this.state.currentPage &&
      nextProps.pageNumber === 1
    ) {
      this.setState({ currentPage: 1 });
      this.onPageClick(1, null);
    }
  }
  setPaginationRange = (pagesNumber, currentPage, e) => {
    var counter = 0;
    var min = 1;
    var max = pagesNumber;
    for (var i = currentPage - 4; i <= currentPage + 4; i++) {
      for (var j = 1; j <= 4; j++) {
        if (counter < 4) {
          if (currentPage - j > 0) {
            min = currentPage - j;
            counter += 1;
          }
          if (currentPage + j <= pagesNumber) {
            max = currentPage + j;
            counter += 1;
          }
        }
      }
    }
    var pRange = [];
    for (var c = min; c <= max; c++) {
      pRange.push(c);
    }

    this.setState({ pRange });
  };
  onPageClick = async (pageNumber, e) => {
    if (e !== null) {
      e.preventDefault();
    }
    this.setState({ currentPage: pageNumber });
    await this.props.onPageChange(this.state.recordsPerPage, pageNumber);
    await this.setPaginationRange(this.state.pagesNumber, pageNumber);
  };
  onPreviousClick = async e => {
    e.preventDefault();
    var currentPage = this.state.currentPage - 1;
    this.setState({ currentPage });
    await this.props.onPageChange(this.state.recordsPerPage, currentPage);
    await this.setPaginationRange(this.state.pagesNumber, currentPage);
  };
  onNextClick = async e => {
    e.preventDefault();
    var currentPage = this.state.currentPage + 1;
    this.setState({ currentPage });
    await this.props.onPageChange(this.state.recordsPerPage, currentPage);
    await this.setPaginationRange(this.state.pagesNumber, currentPage);
  };
  test = () => {
    if (window.innerWidth < 432) {
      return true;
    } else {
      return false;
    }
  };
  componentDidMount() {
    window.addEventListener(
      'resize',
      () => {
        this.setState({
          isMobile: window.innerWidth < 432
        });
        if (window.innerWidth < 432) {
          localStorage.setItem('isMobile', 'true');
        } else {
          localStorage.setItem('isMobile', 'false');
        }
      },
      false
    );
  }
  render() {
    const {
      recordsNumber,
      // recordsPerPage,
      currentPage,
      pagesNumber,
      //,extraPages
      resultLabel,
      showMessage,
      pRange
    } = this.state;
    const className = this.test() === true ? 'pagination-sm' : '';
    const className2 = this.test() === true ? 'page_link_sm2' : 'page_link_sm';
    const className3 = this.test() === true ? 'marginr' : '';
    return (
      <nav
        style={{ direction: 'rtl' }}
        className={classnames('mt-2', className3)}
      >
        <ul
          className={classnames(
            'pagination',
            'justify-content-center',
            className
          )}
        >
          {recordsNumber > 0 ? (
            <li
              className={classnames('page-item', 'ml-2', 'noselect', {
                disabled: currentPage === 1
              })}
            >
              <Link
                to="#"
                className={classnames(
                  'page-link',
                  'page_link_pervious',
                  'noselect',
                  {
                    display_gold: currentPage > 1
                  }
                )}
                //tabIndex="-1"
                onClick={this.onPreviousClick}
              >
                <FontAwesomeIcon
                  icon={['fas', 'angle-right']}
                ></FontAwesomeIcon>
                {currentPage > 1 ? (
                  <span>
                    {` `}السابق{` `}
                  </span>
                ) : null}
              </Link>
            </li>
          ) : null}
          {pRange.map((element, index) => {
            return (
              <li
                key={shortid.generate()}
                className={classnames('page-item', 'ml-2', {
                  active: currentPage === element
                })}
              >
                <Link
                  to="#"
                  className={classnames('page-link', 'noselect', className2)}
                  onClick={this.onPageClick.bind(this, element)}
                >
                  {element}
                </Link>
              </li>
            );
          })}
          {/* {Array.apply(1, Array(pagesNumber)).map((element, index) => { */}
          {recordsNumber > 0 ? (
            <li
              className={classnames('page-item', 'noselect', {
                disabled: currentPage === pagesNumber
              })}
            >
              <Link
                to="#"
                className={classnames(
                  'page-link',
                  'page_link_next',
                  'noselect',
                  {
                    display_gold: currentPage < pagesNumber
                  }
                )}
                onClick={this.onNextClick}
              >
                {currentPage < pagesNumber ? (
                  <span>
                    {` `}التالي{` `}
                  </span>
                ) : null}
                <FontAwesomeIcon icon={['fas', 'angle-left']}></FontAwesomeIcon>
              </Link>
            </li>
          ) : null}
        </ul>
        <div className="noselect disabled text-center font-weight-bold">
          {showMessage ? (
            recordsNumber > 0 ? (
              <span className="display_gold">
                {`(`}
                <CurrencyFormat
                  value={recordsNumber}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={' '}
                />
                {` نتائج)`}
                {resultLabel !== 'عقار' ? <span>{resultLabel}</span> : null}
              </span>
            ) : (
              <span className="display_gold display_7">
                لا يوجد نتائج للبحث المدخل
              </span>
            )
          ) : null}
        </div>
      </nav>
    );
  }
}

export default Pagination;
