import React, { Component } from 'react';
// import Growl from 'primereact/growl';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { forgetPasswordProcess } from './../../actions/authPasswordResetActions';
import { Helmet } from 'react-helmet';
class PasswordRecovery extends Component {
  constructor(props) {
    super(props);
    document.title = 'إعادة تعيين كلمة السر | Ikar.sy';
    this.state = {
      pageTitle: '',
      pageDescription: '',
      recoveryEmail: '',
      recoveryMobile: '09',
      recoveryPassword: '',
      recoveryPasswordConvermation: '',
      errors: {},
      processResType: '',
      process1Active: true,
      process2Active: false,
      processScreen: false,
      resMessages: {},
    };
  }
  componentWillReceiveProps(nextPro, nextState) {
    if (nextPro.processResType !== this.state.processResType) {
      this.setState({
        processResType: nextPro.processResType,
        processScreen: false,
      });
      if (
        nextPro.resMessage.errorMessage !== undefined &&
        nextPro.resMessage.errorMessage.data !== undefined &&
        nextPro.resMessage.errorMessage.data.errors[0].detail ===
          `user with email ${this.state.recoveryEmail} does not exist, or email has not been confirmed yet!.` &&
        this.state.process1Active
      ) {
        this.setState({
          process1Active: true,
          process2Active: false,
          resMessages: {
            errorMessage: 'الإيميل المدخل غير موجود أو غير مفعل بعد',
          },
        });
      } else if (
        nextPro.resMessage.errorMessage !== undefined &&
        nextPro.resMessage.errorMessage.data !== undefined &&
        nextPro.resMessage.errorMessage.data.errors[0].detail ===
          `user with email ${this.state.recoveryEmail} does not exist, or email has not been confirmed yet!.` &&
        this.state.process2Active
      ) {
        this.setState({
          process1Active: false,
          process2Active: true,
          resMessages: {
            errorMessage: 'الرجاء التحقق من رقم الهاتف المدخل',
          },
        });
      }

      if (
        nextPro.resMessage.message !== undefined &&
        nextPro.resMessage.message ===
          `please enter your mobile number to ensure this process` &&
        this.state.process1Active
      ) {
        this.setState({
          process1Active: false,
          process2Active: true,
          resMessages: {},
        });
      } else if (
        nextPro.resMessage.message !== undefined &&
        nextPro.resMessage.message ===
          `password reset link has been sent to your email.` &&
        this.state.process1Active
      ) {
        this.setState({
          process1Active: false,
          process2Active: false,
          resMessages: {
            errorMessage: 'تم ارسال رابط تغيير كلمة السر إلى ايميلك',
          },
        });
      }

      // else if (
      //   nextPro.resMessage.message !== undefined &&
      //   nextPro.resMessage.message ===
      //     `please enter your mobile number to ensure this process` &&
      //   this.state.process2Active
      // ) {
      //   this.setState({
      //     process1Active: false,
      //     process2Active: true,
      //     resMessages: {
      //       errorMessage: 'الرجاء التحقق من رقم الهاتف المدخل'
      //     }
      //   });
      // }
    }
  }
  onEmailSubmit = async (e) => {
    e.preventDefault();
    const { recoveryEmail } = this.state;
    var errors = {};
    if (recoveryEmail.trim() === '') {
      errors.recoveryEmail = 'يجب ادخال الايميل';
    } else if (!/[\w+\-.]+@[a-zA-Z.-]+\.[a-z]{2,3}/.test(recoveryEmail)) {
      errors.recoveryEmail = 'يجب التأكد من الايميل المدخل';
    }
    if (Object.keys(errors).length > 0) {
      this.setState({ errors, resMessages: {} });
      return;
    }
    await this.setState({
      errors: {},
      resMessages: {},
      processScreen: true,
      processResType: '',
    });
    var user = {};
    user.email = recoveryEmail;
    this.props.forgetPasswordProcess(user);
  };
  onEmailMobileSubmit = async (e) => {
    e.preventDefault();
    const { recoveryEmail, recoveryMobile } = this.state;
    var errors = {};
    if (recoveryEmail.trim() === '') {
      errors.recoveryEmail = 'يجب ادخال الايميل';
    } else if (!/[\w+\-.]+@[a-zA-Z.-]+\.[a-z]{2,3}/.test(recoveryEmail)) {
      errors.recoveryEmail = 'يجب التأكد من الايميل المدخل';
    }
    if (
      (recoveryMobile !== null && recoveryMobile.trim() === '') ||
      recoveryMobile.trim() === '09'
    ) {
      errors.recoveryMobile = 'يجب ادخال رقم الموبايل';
    } else if (recoveryMobile.trim() !== '' && recoveryMobile.trim() !== '09') {
      if (String(recoveryMobile.trim()).charAt(0) !== '0') {
        errors.recoveryMobile = 'يجب التأكد من رقم الموبايل';
      }
      if (
        recoveryMobile.trim().length < 10 ||
        recoveryMobile.trim().length > 10
      ) {
        errors.recoveryMobile = 'يجب التأكد من رقم الموبايل';
      }
      if (
        recoveryMobile.trim().length > 1 &&
        !this.isNumeric(recoveryMobile.trim())
      ) {
        errors.recoveryMobile = 'يجب التأكد من رقم الموبايل';
      }
    }
    if (Object.keys(errors).length > 0) {
      this.setState({ errors, resMessages: {} });
      return;
    }
    await this.setState({
      errors: {},
      resMessages: {},
      processScreen: true,
      processResType: '',
    });
    var user = {
      email: recoveryEmail,
      mobile:
        recoveryMobile.replace(/\s/g, '') === '' ||
        recoveryMobile.replace(/\s/g, '') === '09'
          ? null
          : recoveryMobile.replace(/\s/g, ''),
    };

    this.props.forgetPasswordProcess(user);
  };
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onMobileChange = (e) => {
    if (e.target.value.length > 10 || e.target.value.length === 1) {
      return;
    }
    if (/^\d+$/.test(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  isNumeric = (num) => {
    // return !isNaN(num);
    // return /^-{0,1}\d+$/.test(num);
    return /^\d+$/.test(num);
  };
  render() {
    const {
      errors,
      recoveryEmail,
      recoveryMobile,
      process1Active,
      process2Active,
      resMessages,
      processScreen,
    } = this.state;
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.pageTitle}</title>
          <meta name="description" content={this.state.pageDescription} />
          <meta name="robots" content="noindex" />
        </Helmet>
        <div className="row">
          <div className="col-md-10 mx-auto">
            <div className="mt-2 text-center pb-4">
              <h1 className="main_title">اعادة تعيين كلمة السر</h1>
            </div>
          </div>
        </div>
        {/* <Growl ref={el => (this.growl = el)} /> */}

        <div className="row">
          <div className="col-md-6 mx-auto">
            <div
              className="card border-warning mb-3"
              // style={{ maxWidth: '18rem' }}
            >
              {processScreen ? (
                // <div className="process-loader">Ikar.sy</div>
                <div className="process-loader2">
                  <div className="span text-center">
                    {/* <div className="location_indicator" /> */}
                    <div
                      className="spinner-border text-warning"
                      style={{ width: '3rem', height: '3rem' }}
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                    <p className="text-danger lead text-center">
                      <span>www.</span>
                      <span className="font-weight-bold">Ikar</span>
                      <span className="font-weight-bold">.sy</span>
                    </p>
                  </div>
                </div>
              ) : null}
              {process1Active ? (
                <React.Fragment>
                  <div className="card-header text-right">معلومات المستخدم</div>
                  <div className="card-body">
                    <form
                      onSubmit={this.onEmailSubmit}
                      style={{ direction: 'rtl' }}
                    >
                      <div className="form-group text-right mb-2">
                        <label htmlFor="recoveryEmail">ادخل ايميلك:</label>
                        <input
                          type="email"
                          className={classnames('form-control', {
                            'is-invalid': errors.recoveryEmail,
                          })}
                          name="recoveryEmail"
                          value={recoveryEmail}
                          onChange={this.onChange}
                        ></input>
                        {errors.recoveryEmail && (
                          <div className="invalid-feedback">
                            {errors.recoveryEmail}
                          </div>
                        )}
                      </div>
                      <input
                        type="submit"
                        value="اعادة تعيين كلمة السر"
                        className="btn btn-block submit-button"
                      ></input>
                      {Object.keys(resMessages).length > 0 ? (
                        <div className="alert-warning mt-2 p-1 text-right">
                          {resMessages.errorMessage}
                        </div>
                      ) : null}
                    </form>
                  </div>
                </React.Fragment>
              ) : process2Active ? (
                <React.Fragment>
                  <div className="card-header text-right">معلومات المستخدم</div>
                  <div className="card-body">
                    <form
                      onSubmit={this.onEmailMobileSubmit}
                      style={{ direction: 'rtl' }}
                    >
                      <div className="form-group text-right mb-2">
                        <label htmlFor="recoveryEmail">ادخل ايميلك:</label>
                        <input
                          type="email"
                          className={classnames('form-control', {
                            'is-invalid': errors.recoveryEmail,
                          })}
                          name="recoveryEmail"
                          value={recoveryEmail}
                          onChange={this.onChange}
                          readOnly
                        ></input>
                        {errors.recoveryEmail && (
                          <div className="invalid-feedback">
                            {errors.recoveryEmail}
                          </div>
                        )}
                      </div>
                      <div className="form-group text-right mb-2">
                        <label htmlFor="recoveryMobile">
                          ادخل رقم الموبايل:
                        </label>
                        <input
                          type="tel"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          name="recoveryMobile"
                          // id="mobileId"
                          className={classnames('form-control', {
                            'is-invalid': errors.recoveryMobile,
                          })}
                          style={{ direction: 'ltr' }}
                          placeholder=""
                          value={recoveryMobile}
                          onChange={this.onMobileChange}
                        />
                        {errors.recoveryMobile && (
                          <div className="invalid-feedback">
                            {errors.recoveryMobile}
                          </div>
                        )}
                      </div>
                      <input
                        type="submit"
                        value="اعادة تعيين كلمة السر"
                        className="btn btn-block submit-button"
                      ></input>
                      {Object.keys(resMessages).length > 0 ? (
                        <div className="alert-warning mt-2 mx-1 p-1 text-right">
                          {resMessages.errorMessage}
                        </div>
                      ) : null}
                    </form>
                  </div>
                </React.Fragment>
              ) : Object.keys(resMessages).length > 0 ? (
                <div className="alert-success my-3 p-1 text-right display_5">
                  {resMessages.errorMessage}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
PasswordRecovery.propTypes = {
  forgetPasswordProcess: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  resMessage: state.authPasswordReset.resMessage,
  processResType: state.authPasswordReset.processResType,
});
export default connect(mapStateToProps, { forgetPasswordProcess })(
  PasswordRecovery
);
