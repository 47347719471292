import {GET_ABOUTS , DELETE_ABOUT , ADD_ABOUT ,GET_ABOUT} from './../actions/types';
import shortid from 'shortid';
const initialState = {
    abouts: [],
    about: {},
    requestFailed: false,
    requestFailedMessage: {},
    requestSuccessMessage: '',
    deleteSuccessMessage: '',
    requestMessage: '',
    resType: 'ready',
  };
  
  export default function (state = initialState, action) {
    switch (action.type) {
        
    case GET_ABOUTS:
      
        if (
          Object.keys(action.payload).length > 0 &&
          (action.payload.errorMessage === '' ||
            action.payload.errorMessage === undefined)
        ) {
         // action.payload.processStatus = 'get joinus';
          return {   
          //...state,
          about: action.payload,
          //add state data
          abouts: action.payload,
          requestFailed: false,
          requestFailedMessage: {},
          requestSuccessMessage: '',
          resType: action.payload.resType,
          };
        } else {
          return {  
          about: {},
          requestFailed: true,
          requestFailedMessage: action.payload,
          //add state data
          abouts: [],
          requestSuccessMessage: '',
          requestMessage: '',
          resType: action.payload.resType,
          //
          };
         
         
        }
        case DELETE_ABOUT:
          if (
            Object.keys(action.payload).length > 0 &&
            (action.payload.errorMessage === '' ||
              action.payload.errorMessage === undefined)
          ) {
            return {
              abouts: [],
              about: {},
              requestFailed: false,
              requestFailedMessage: {},
              deleteSuccessMessage: action.payload.message,
              short_id: shortid.generate(),
            };
          } else {
            return {
              abouts: [],
              about: {},
              requestFailed: true,
              requestFailedMessage: action.payload,
              deleteSuccessMessage: '',
            };
          }
          
        
        case ADD_ABOUT:
          if (
            Object.keys(action.payload).length > 0 &&
            (action.payload.errorMessage === '' ||
              action.payload.errorMessage === undefined)
          ) {
            if (action.payload.error === undefined) {
              return {
                //...state,
                about: action.payload,
                requestFailed: false,
                requestFailedMessage: { error: 'no' },
                //add state
                abouts: [],
                requestSuccessMessage: 'add is succeeded',
                resType: action.payload.resType,
                short_id: shortid.generate(),
              };
            } else {
              return {
                //...state,
                about: {},
                requestFailed: true,
                requestFailedMessage: action.payload,
                //add state
                abouts: [],
                //requestSuccessMessage: 'error in add question',
                resType: action.payload.resType,
                short_id: shortid.generate(),
              };
            }
          } else {
            return {
              //...state,
              about: {},
              requestFailed: true,
              requestFailedMessage: action.payload,
              //add state
              abouts: [],
              //requestSuccessMessage: 'error in add question',
              resType: action.payload.resType,
            };
          }

          case GET_ABOUT:
            if (
              Object.keys(action.payload).length > 0 &&
              (action.payload.errorMessage === '' ||
                action.payload.errorMessage === undefined)
            ) {
              action.payload.processStatus = 'get about';
              return {
                //...state,
                about: action.payload,
                //add state data
                abouts: [],
                requestFailed: false,
                requestFailedMessage: {},
                requestMessage: 'get about ready',
                resType: action.payload.resType,
                //
              };
            } else {
              return {
                //...state,
                about: {},
                requestFailed: true,
                requestFailedMessage: action.payload,
                //add state data
                abouts: [],
                requestSuccessMessage: '',
                requestMessage: '',
                resType: action.payload.resType,
                //
              };
            }



            default:
              return state;

            }
          
        }