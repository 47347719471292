import { combineReducers } from 'redux';
import authReducer from './authReducer';
import propertyReducer from './propertyReducer';
import propertyReducerUD from './propertyReducerUD';
import userReducer from './userReducer';
import userReducerUD from './userReducerUD';
import agencyReducer from './agencyReducer';
import agencyReducerUD from './agencyReducerUD';
// import clientReducer from './clientReducer';
import settingReducer from './settingReducer';
import searchReducer from './searchReducer';
import contactReducer from './contactReducer';
import mainReducer from './mainReducer';
import faqReducer from './faqReducer';
import joinusReducer from './joinusReducer';
import faqReducerUD from './faqReducerUD';
import joinUsReducerUD from './joinUsReducerUD';
import authPasswordResetReducer from './authPasswordResetReducer';
import { routerReducer } from 'react-router-redux';
import aboutReducer from './aboutReducer';
import aboutReducerUD from './aboutReducerUD';
import privacyReducer from './privacyReducer';
import privacyReducerUD from './privacyReducerUD';
import categoryReducer from './categoryReducer';

export default combineReducers({
  property: propertyReducer,
  propertyUD: propertyReducerUD,
  user: userReducer,
  userUD: userReducerUD,
  agency: agencyReducer,
  agencyUD: agencyReducerUD,
  // client: clientReducer,
  auth: authReducer,
  routing: routerReducer,
  setting: settingReducer,
  search: searchReducer,
  contact: contactReducer,
  main: mainReducer,
  faq: faqReducer,
  faqUD: faqReducerUD,
  joinUD: joinUsReducerUD,
  join:joinusReducer,
  authPasswordReset: authPasswordResetReducer,
  about:aboutReducer,
  aboutUD:aboutReducerUD,
  privacy:privacyReducer,
  privacyUD:privacyReducerUD,
  category:categoryReducer
  
});
