import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from './../../../assets/images/logo.png';
import minLogo from './../../../assets/images/min_logo.png';
import menu from './../../../assets/images/menu.png';
import {
  loginProcess,
  logoutProcess,
  loginChecking,
  leaveAgencyRole,
} from './../../../actions/authActions';
import classnames from 'classnames';
import { Navbar, NavDropdown, Nav } from 'react-bootstrap';
import jQuery from 'jquery';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { linkDomain } from './../../../mainConf';

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      largeScreen: false,
      isTakingAgencyRole: false,
      resType: '',
      isMobile: false,
    };
  }
  componentWillReceiveProps(nextProps, nextSate) {
    if (this.state.isLoggedIn !== nextProps.isLoggedIn) {
      this.setState({ isLoggedIn: nextProps.isLoggedIn });
      this.resize();
      if (this.storageAvailable('localStorage')) {
        const first_login =
          localStorage.getItem('first_login') === 'true' ? true : false;

        if (first_login === true) {
          // alert('0');
          localStorage.setItem('first_login', 'false');
          if (nextProps.ikarAdmin === true) {
            // alert('0000');
            this.props.showMessage('تم تسجيل الدخول');
            this.props.onPathChange('/adminDashBoard/agencies');
            //this.props.history.push('/adminDashBoard/agencies');
          } else {
            this.props.onPathChange('/dashBoard/properties_by_agency');
            //this.props.history.push('/dashBoard/properties_by_agency');
          }
        }
      } else {
        alert('Please enable cookies in your browser before continue');
      }
    }
    if (
      nextProps.resType !== undefined &&
      nextProps.resType !== this.state.resType
    ) {
      const { resType } = nextProps;
      this.setState({ resType });
      this.props.onSetResType(resType);
    }
    if (this.storageAvailable('localStorage')) {
      const first_login =
        localStorage.getItem('first_login') === 'true' ? true : false;

      if (first_login === true) {
        // alert('0');
        localStorage.setItem('first_login', 'false');

        if (
          this.state.isTakingAgencyRole !== nextProps.isTakingAgencyRole &&
          nextProps.isTakingAgencyRole === true
        ) {
          this.setState({ isTakingAgencyRole: nextProps.isTakingAgencyRole });
          this.props.showMessage('تم تسجيل الدخول كمكتب');
          this.props.onPathChange('/dashBoard/properties_by_agency');
        }
      }
    } else {
      alert('Please enable cookies in your browser before continue');
    }
  }
  resize() {
    let currentWidth = window.innerWidth;
    if (currentWidth >= 992) {
      this.setState({ largeScreen: true });
    } else {
      this.setState({ largeScreen: false });
    }
  }
  storageAvailable = (type) => {
    try {
      var storage = window[type],
        x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch (e) {
      return false;
    }
  };
  onLogout = (e) => {
    e.preventDefault();
    this.props.logoutProcess();
    this.props.showMessage('تم تسجيل الخروج');
    localStorage.removeItem('ikar_token');
    localStorage.removeItem('ikar_id');
    localStorage.removeItem('ikar_name');
    localStorage.removeItem('ikar_a');
    localStorage.removeItem('ikar_a_a');
    localStorage.removeItem('ikar_agency_ref_id');
    localStorage.removeItem('ikar_email');
    localStorage.removeItem('ikar_is_b');
    localStorage.removeItem('first_login');

    localStorage.removeItem('ikar_is_taking_agency_role');
    localStorage.removeItem('ikar_taken_agency_role_email');
    localStorage.removeItem('ikar_taken_agency_role_id');
    localStorage.removeItem('ikar_taken_agency_role_name');
    localStorage.removeItem('ikar_taken_agency_role_token');
    localStorage.removeItem('ikar_taken_agency_role_info');
    localStorage.removeItem('ikar_taken_agency_role_agency_refId');

    this.setState({ isTakingAgencyRole: false });
    this.props.onPathChange('/');
    this.props.history.push('/');
  };
  componentDidMount() {
    this.props.loginChecking();
    this.resize();
    ////////////////////////////me
    window.addEventListener(
      'resize',
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
        });
      },
      false
    );
    window.addEventListener('resize', this.resize.bind(this));
    jQuery(function ($) {
      // bottom header custom fixed
      var $window = $(window);
      var $hMenuBar = $('.hNavBar');
      if ($('#mainNavBarAnchor').length) {
        // Run this on scroll events.
        $window.scroll(function () {
          if (
            document.body.scrollTop > 84 ||
            document.documentElement.scrollTop > 84
          ) {
            $('.mainNavBar').css({ paddingTop: '0px' });
            $('.mainNavBar').css({ paddingBottom: '0px' });
            $hMenuBar.addClass('miniNavigator');
            var $navbarToggler = $('.navbar-toggler');
            if ($('.navbar-toggler').length) {
              $navbarToggler.removeClass('collapsed');
            }
            var $navbarCollapse = $('.navbar-collapse');
            if ($('.navbar-collapse').length) {
              $navbarCollapse.removeClass('show');
            }
          } else {
            $('.mainNavBar').css({ paddingTop: '4px' });
            $('.mainNavBar').css({ paddingBottom: '4px' });
            if (document.body.offsetWidth >= 768) {
              $hMenuBar.removeClass('miniNavigator');
            }
          }
        });
      }
    });
  }

  onNavClick = (str, e) => {
    // this.props.onPathChange(str);
    this.resize();
    if (!this.state.largeScreen) {
      jQuery(function ($) {
        var $hMenuBar = $('.hNavBar');
        $hMenuBar.addClass('miniNavigator');
      });
    }
  };
  leaveAgencyRole = () => {
    if (window.confirm('العودة الى مسؤول النظام')) {
      this.props.leaveAgencyRole();
      this.props.history.push('/adminDashBoard/agencies');
      this.props.showMessage('تم تسجيل الدخول كمسؤول نظام');
      this.props.onPathChange('/adminDashBoard/agencies');
      this.setState({ isTakingAgencyRole: false });
    }
  };
  onMenuBtnClick = (e) => {
    e.preventDefault();
    this.resize();
    jQuery(function ($) {
      var $hMenuBar = $('.hNavBar');
      if ($('.hNavBar').length) {
        $hMenuBar.toggleClass('miniNavigator');
      }
      var $navbarToggler = $('.navbar-toggler');
      if ($('.navbar-toggler').length) {
        $navbarToggler.addClass('collapsed');
      }
      var $navbarCollapse = $('.navbar-collapse');
      if ($('.navbar-collapse').length) {
        $navbarCollapse.addClass('show');
      }
    });
  };

  render() {
    const {
      isLoggedIn,
      ikarAdmin,
      agencyAdmin,
      // ikarEmail,
      ikarId,
      ikarName,
      isTakingAgencyRole,
      // takenAgencyRoleEmail,
      takenAgencyRoleId,
      takenAgencyRoleName,
    } = this.props;
    return (
      <React.Fragment>
        <div id="mainNavBarAnchor" />

        <header
          className={classnames('hNavBar', {
            miniNavigator: !this.state.largeScreen,
          })}
        >
          <button className="btn menu-btn" onClick={this.onMenuBtnClick}>
            <img
              src={menu}
              className="menu-logo"
              alt=""
              // style={{ width: '60px', height: '35px' }}
            />
            {/* <span className="display_5_bold">القائمة</span> */}
          </button>

          <Navbar
            expand="md"
            className="container mainNavBar"
            style={{ direction: 'rtl' }}
          >
            <a href={`#!`} className="navbar-brand mx-2">
              {/* <Link
              to="#!"
              className="navbar-brand mx-2"
              //onClick={this.onNavClick.bind(this, '/')}
            > */}
              {this.state.largeScreen ? (
                <img
                  src={logo}
                  className="logo"
                  alt=""
                  onClick={this.onMenuBtnClick}
                />
              ) : (
                <img
                  src={minLogo}
                  className="logo"
                  alt=""
                  onClick={this.onMenuBtnClick}
                />
              )}
              {/* </Link> */}
            </a>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <a
                  href={`${linkDomain}/`}
                  className="nav-link white_with_shadow mx-1"
                  onClick={this.onNavClick.bind(this, '/')}
                >
                  {/* <Link
                  to="/"
                  className="nav-link white_with_shadow mx-1"
                  onClick={this.onNavClick.bind(this, '/')}
                > */}
                  <span className="px-1">الرئيسية</span>
                  <FontAwesomeIcon icon={['fas', 'home']} color="#fcb814" />
                  {/* </Link> */}
                </a>
                <a
                  href={`${linkDomain}/عقارات/بيع`}
                  className="nav-link white_with_shadow mx-1"
                  onClick={this.onNavClick.bind(this, '/عقارات/بيع')}
                >
                  {/* <Link
                  to="/"
                  className="nav-link white_with_shadow mx-1"
                  onClick={this.onNavClick.bind(this, '/')}
                > */}
                  <span className="px-1">للبيع</span>
                  <FontAwesomeIcon icon={['fas', 'home']} color="#fcb814" />
                  {/* </Link> */}
                </a>
                <a
                  href={`${linkDomain}/عقارات/تأجير`}
                  className="nav-link white_with_shadow mx-1"
                  onClick={this.onNavClick.bind(this, '/عقارات/تأجير')}
                >
                  {/* <Link
                  to="/"
                  className="nav-link white_with_shadow mx-1"
                  onClick={this.onNavClick.bind(this, '/')}
                > */}
                  <span className="px-1">للايجار</span>
                  <FontAwesomeIcon icon={['fas', 'home']} color="#fcb814" />
                  {/* </Link> */}
                </a>
                {/* {!isLoggedIn ? (
                  <a
                    href={`${linkDomain}/search`}
                    className="nav-link white_with_shadow mx-1"
                    onClick={this.onNavClick.bind(this, '/search')}
                  >
                   
                    <span className="px-1">ابحث على Ikar.sy</span>
                    <FontAwesomeIcon icon={['fas', 'search']} color="#fcb814" />
                   
                  </a>
                ) : null} */}
                {agencyAdmin || isTakingAgencyRole ? (
                  <React.Fragment>
                    <NavDropdown title="العقارات" className="white_with_shadow">
                      <NavDropdown.Item
                        href="/dashBoard/add_property"
                        className="text-right"
                        onClick={this.onNavClick.bind(
                          this,
                          '/dashBoard/add_property'
                        )}
                      >
                        اضافة عقار
                        {` `}
                        <FontAwesomeIcon icon={['fas', 'plus-square']} />
                      </NavDropdown.Item>

                      <NavDropdown.Item
                        href="/dashBoard/properties_by_agency"
                        className="text-right"
                        onClick={this.onNavClick.bind(
                          this,
                          '/dashBoard/properties_by_agency'
                        )}
                      >
                        عقارات المكتب
                        {` `}
                        <FontAwesomeIcon icon={['fas', 'city']} />
                      </NavDropdown.Item>

                      <NavDropdown.Item
                        href="/dashBoard/properties_contacts"
                        className="text-right"
                        onClick={this.onNavClick.bind(
                          this,
                          '/dashBoard/properties_contacts'
                        )}
                      >
                        رسائل المكتب
                        {` `}
                        <FontAwesomeIcon icon={['fas', 'search']} />
                      </NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown
                      title="المكتب العقاري"
                      className="white_with_shadow"
                    >
                      <NavDropdown.Item
                        href="/dashBoard/edit_agency"
                        className="text-right"
                        onClick={this.onNavClick.bind(
                          this,
                          '/dashBoard/edit_agency'
                        )}
                      >
                        تعديل المكتب
                        {` `}
                        <FontAwesomeIcon icon={['fas', 'edit']} />
                      </NavDropdown.Item>
                    </NavDropdown>
                    <NavDropdown
                      title="للعرض على ikar"
                      className="white_with_shadow"
                    >
                       <NavDropdown.Item
                        href="/dashBoard/show_about_by_admin"
                        className="text-right"
                        onClick={this.onNavClick.bind(
                          this,
                          '/dashBoard/show_about_by_admin'
                        )}
                      >
                       حولIKAR
                        {` `}
                        <FontAwesomeIcon icon={['fas', 'edit']} />
                      </NavDropdown.Item>
                    </NavDropdown>
                
                  </React.Fragment>
                ) : null}
                {ikarAdmin && !isTakingAgencyRole ? (
                  <React.Fragment>
                    <NavDropdown title="العقارات" className="white_with_shadow">
                      <NavDropdown.Item
                        href="/adminDashBoard/properties"
                        className="text-right"
                        onClick={this.onNavClick.bind(
                          this,
                          '/adminDashBoard/properties'
                        )}
                      >
                        قائمة العقارات
                        {` `}
                        <FontAwesomeIcon icon={['fas', 'search']} />
                      </NavDropdown.Item>

                      <NavDropdown.Item
                        href="/adminDashBoard/properties_contacts"
                        className="text-right"
                        onClick={this.onNavClick.bind(
                          this,
                          '/adminDashBoard/properties_contacts'
                        )}
                      >
                        رسائل المكاتب
                        {` `}
                        <FontAwesomeIcon icon={['fas', 'search']} />
                      </NavDropdown.Item>
                    </NavDropdown>

                    <NavDropdown
                      title="المكاتب العقارية"
                      className="white_with_shadow"
                    >
                      <NavDropdown.Item
                        href="/adminDashBoard/agency_registration"
                        className="text-right"
                        onClick={this.onNavClick.bind(
                          this,
                          '/adminDashBoard/agency_registration'
                        )}
                      >
                        اضافة مكتب
                        {` `}
                        <FontAwesomeIcon icon={['fas', 'plus']} />
                      </NavDropdown.Item>
                      <NavDropdown.Item
                        href="/adminDashBoard/agencies"
                        className="text-right"
                        onClick={this.onNavClick.bind(
                          this,
                          '/adminDashBoard/agencies'
                        )}
                      >
                        المكاتب
                        {` `}
                        <FontAwesomeIcon icon={['fas', 'binoculars']} />
                      </NavDropdown.Item>

                      <NavDropdown.Item
                        href="/adminDashBoard/users"
                        className="text-right"
                        onClick={this.onNavClick.bind(
                          this,
                          '/adminDashBoard/users'
                        )}
                      >
                        المستخدمين
                        {` `}
                        <FontAwesomeIcon icon={['fas', 'users']} className="" />
                      </NavDropdown.Item>
                    </NavDropdown>
                    {/* ///////// */}
                    <NavDropdown
                      title="الاسئلة الشائعة"
                      className="white_with_shadow"
                    >
                      <NavDropdown.Item
                        href="/adminDashBoard/show_faqs_by_admin"
                        className="text-right"
                        onClick={this.onNavClick.bind(
                          this,
                          '/adminDashBoard/show_faqs_by_admin'
                        )}
                      >
                        قائمة الأسئلة الشائعة
                        {` `}
                        <FontAwesomeIcon icon={['fas', 'search']} />
                      </NavDropdown.Item>
                    </NavDropdown>

                    {/* <NavDropdown
                      title="انضم إلينا"
                      className="white_with_shadow"
                    >
                      <NavDropdown.Item
                        href="/adminDashBoard/show_joinus_by_admin"
                        className="text-right"
                        onClick={this.onNavClick.bind(
                          this,
                          '/adminDashBoard/show_joinus_by_admin'
                        )}
                      >
                        قائمة انضم إلينا
                        {` `}
                        <FontAwesomeIcon icon={['fas', 'search']} />
                      </NavDropdown.Item>
                    </NavDropdown> */}
                    <NavDropdown
                      title="للعرض على ikar"
                      className="white_with_shadow"
                    >
                       <NavDropdown.Item
                        href="/adminDashBoard/show_about_by_admin"
                        className="text-right"
                        onClick={this.onNavClick.bind(
                          this,
                          '/adminDashBoard/show_about_by_admin'
                        )}
                      >
                        حول IKAR
                        {` `}
                        <FontAwesomeIcon icon={['fas', 'edit']} />
                      </NavDropdown.Item>
                    </NavDropdown>

                  </React.Fragment>
                ) : null}
                {!isLoggedIn ? (
                  <React.Fragment>
                    <a
                      href={`${linkDomain}/about`}
                      className="nav-link  white_with_shadow mx-1"
                      onClick={this.onNavClick.bind(this, '/about')}
                    >
                      {/* <Link
                    to="/about"
                    className="nav-link  white_with_shadow mx-1"
                    onClick={this.onNavClick.bind(this, '/about')}
                  > */}
                      {/* <Nav.Link href="/about"> */}
                      <span className="px-1">للعرض على Ikar.sy</span>
                      <FontAwesomeIcon
                        icon={['fas', 'globe']}
                        //className="text-warning"
                        color="#fcb814"
                      />
                      {/* </Nav.Link> */}
                      {/* </Link> */}
                    </a>

                    <a
                      href={`${linkDomain}/faq`}
                      className="nav-link white_with_shadow mx-1"
                      onClick={this.onNavClick.bind(this, '/faq')}
                    >
                      <span className="px-1">الأسئلة الشائعة</span>
                      <FontAwesomeIcon
                        icon={['fas', 'question-circle']}
                        //className="text-warning"
                        color="#fcb814"
                      />
                    </a>

                    {/* <a
                      href={`${linkDomain}/joins_us`}
                      className="nav-link white_with_shadow mx-1"
                      onClick={this.onNavClick.bind(this, '/joins_us')}
                    >
                      <span className="px-1">انضم إلينا</span>
                      <FontAwesomeIcon
                        icon={['fas', 'plus-circle']}
                        //className="text-warning"
                        color="#fcb814"
                      />
                    </a> */}

                    <a
                      href={`${linkDomain}/downloadapp`}
                      className="nav-link white_with_shadow mx-1"
                      onClick={this.onNavClick.bind(this, '/faq')}
                    >
                      <span className="px-1"> تطبيق IKAR</span>
                      <FontAwesomeIcon
                        icon={['fas', 'mobile-alt']}
                        //className="text-warning"
                        color="#fcb814"
                      />
                    </a>
                  </React.Fragment>
                ) : null}
              </Nav>
              <Nav className="mr-auto">
                {isLoggedIn ? (
                  <React.Fragment>
                    {!isTakingAgencyRole && ikarAdmin ? (
                      <a
                        href={`${linkDomain}/profile/${ikarId}`}
                        className="nav-link  white_with_shadow text-primary  mx-1"
                        onClick={this.onNavClick.bind(this, '/profile')}
                      >
                        {/* <Link
                        to={`/profile/${ikarId}`}
                        // to={{
                        //   pathname: '/profile',
                        //   query: { user_email: ikarEmail }
                        // }}
                        className="nav-link  white_with_shadow text-primary  mx-1"
                        onClick={this.onNavClick.bind(this, '/profile')}
                      > */}
                        {ikarName}
                        {/* </Link> */}
                      </a>
                    ) : null}
                    {!isTakingAgencyRole && !ikarAdmin ? (
                      <a
                        href={`${linkDomain}/profile/${ikarId}`}
                        className="nav-link  white_with_shadow text-primary"
                        onClick={this.onNavClick.bind(this, '/profile')}
                      >
                        {/* <Link
                        to={`/profile/${ikarId}`}
                        // to={{
                        //   pathname: '/profile',
                        //   query: { user_email: ikarEmail }
                        // }}
                        className="nav-link  white_with_shadow text-primary"
                        onClick={this.onNavClick.bind(this, '/profile')}
                      > */}
                        {ikarName}
                        {/* </Link> */}
                      </a>
                    ) : null}
                    {isTakingAgencyRole ? (
                      <React.Fragment>
                        <a
                          href={`${linkDomain}/profile/${takenAgencyRoleId}`}
                          className="nav-link  white_with_shadow text-warning  mx-1"
                          onClick={this.onNavClick.bind(this, '/profile')}
                        >
                          {/* <Link
                          to={`/profile/${takenAgencyRoleId}`}
                          // to={{
                          //   pathname: '/profile',
                          //   query: { user_email: takenAgencyRoleEmail }
                          // }}
                          className="nav-link  white_with_shadow text-warning  mx-1"
                          onClick={this.onNavClick.bind(this, '/profile')}
                        > */}
                          {takenAgencyRoleName}
                          {`  `}
                          <FontAwesomeIcon icon={['fas', 'angle-right']} />
                          {/* </Link> */}
                        </a>
                        <span className="text-primary">
                          {/* angle-right  */}
                        </span>
                        <a
                          href={`#!`}
                          className="nav-link  white_with_shadow text-primary  mx-1"
                          onClick={this.leaveAgencyRole}
                        >
                          {/* <Link
                          to="#!"
                          className="nav-link  white_with_shadow text-primary  mx-1"
                          onClick={this.leaveAgencyRole}
                        > */}
                          {ikarName}
                          {/* {`Admin`} */}
                          {/* </Link> */}
                        </a>
                      </React.Fragment>
                    ) : null}
                    <a
                      href={`${linkDomain}/`}
                      className="nav-link nav-link-log white_with_shadow  mx-1"
                      onClick={this.onLogout}
                    >
                      {/* <Link
                      to="/"
                      className="nav-link nav-link-log white_with_shadow  mx-1"
                      onClick={this.onLogout}
                    > */}
                      <span className="px-1">تسجيل الخروج</span>
                      <FontAwesomeIcon
                        icon={['fas', 'sign-out-alt']}
                        //className="text-warning"
                        color="#fcb814"
                      />
                      {/* </Link> */}
                    </a>
                  </React.Fragment>
                ) : (
                  <a
                    href={`${linkDomain}/login`}
                    className="nav-link nav-link-log white_with_shadow  mx-1 "
                    onClick={this.onNavClick.bind(this, '/login')}
                  >
                    {/* <Link
                    to="/login"
                    className="nav-link nav-link-log white_with_shadow  mx-1 "
                    onClick={this.onNavClick.bind(this, '/login')}
                  > */}
                    <span className="px-1">تسجيل الدخول</span>
                    <FontAwesomeIcon
                      icon={['fas', 'sign-in-alt']}
                      //className="text-warning"
                      color="#fcb814"
                    />
                    {/* </Link> */}
                  </a>
                )}
                {/* /////////////me */}

                {/* //////meeeeeeeeeee */}
              </Nav>
              <Nav.Link href="#" />
            </Navbar.Collapse>
          </Navbar>
        </header>
      </React.Fragment>
    );
  }
}

Navigation.propTypes = {
  loginRes: PropTypes.object.isRequired,
  loginProcess: PropTypes.func.isRequired,
  leaveAgencyRole: PropTypes.func.isRequired,
  logoutProcess: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  ikarAdmin: PropTypes.bool.isRequired,
  ikarEmail: PropTypes.string.isRequired,
  agencyAdmin: PropTypes.bool.isRequired,
  //takenAgencyRoleEmail: PropTypes.string.isRequired,
  isTakingAgencyRole: PropTypes.bool.isRequired,
  // resType: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  loginRes: state.auth.loginRes,
  isLoggedIn: state.auth.isLoggedIn,
  ikarAdmin: state.auth.ikarAdmin,
  ikarEmail: state.auth.ikarEmail,
  ikarId: state.auth.ikarId,
  ikarName: state.auth.ikarName,
  agencyAdmin: state.auth.agencyAdmin,
  isTakingAgencyRole: state.auth.isTakingAgencyRole,
  takenAgencyRoleEmail: state.auth.takenAgencyRoleEmail,
  takenAgencyRoleId: state.auth.takenAgencyRoleId,
  takenAgencyRoleName: state.auth.takenAgencyRoleName,
  resType: state.auth.resType,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    loginProcess,
    logoutProcess,
    loginChecking,
    leaveAgencyRole,
  })
)(Navigation);
