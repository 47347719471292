import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import { getProperty, callForProperty } from './../../actions/propertyActions';
import CurrencyFormat from 'react-currency-format';
import shortid from 'shortid';
import SearchBar from './../search/SearchBar.jsx';
import Lightbox from 'react-image-lightbox';
import { imageDomain, linkDomain } from './../../mainConf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContactAgency from '../contact/ContactAgency';
import { Growl } from 'primereact/growl';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import { Dialog } from 'primereact/dialog';
import { Link } from 'react-router-dom';
import { noInternetMessage, mapsEnabled } from './../../mainConf';
import { ResReady, ResNotComplete } from './../layout/resTypes';
import logo from './../../assets/images/logo.png';
import SearchCategories from './../search/SearchCategories';
// import logo from './../../assets/images/logo.png';
import { createBrowserHistory } from 'history';
import { Helmet } from 'react-helmet';
import {
  buildShowPropertyTitle,
  buildShowPropertyDescription,
  buildAlt,
  buildGalleryTitle,
} from './../../mainConf/functions';
class Gallery extends Component {
  constructor(props) {
    super();
    this.horizontalContainerRef = React.createRef();
    this.state = {
      pageNotFound: false,
      id: '',
      pageTitle: '',
      pageDescription: '',
      showCurrentLocation: false,
      zoom: 17,
      visible: true,
      largeScreen: false,
      mediumScreen: false,
      source: '',
      photoIndex: 0,
      alt:"",
      IsMM: false,
      IsGarden: false,
      IsBuildDate: false,
      IsReconstructed: false,
      IsUsingGPS: false,
      showMap: false,
      maplays: null,
      propertyPosition: null,
      errors: {},
      title: '',
      sell_plan_id: 0,
      days: 0,
      temp_status: '',
      expiration_date: '',
      rented: true,
      rent_start: null,
      rent_end: null,
      pricing_type: 'ل.س',
      type: 'شقة',
      roof_building_area: 100,
      price_upon_review: false,
      rank: 100,
      icons: false,
      description: '',
      city: '',
      province: '',
      area: '',
      street: '',
      gps_location: '',
      bedrooms: 0,
      bathrooms: 0,
      toilets: 0,
      garage: true,
      parking: true,
      balcony: 0,
      view_direction: '',
      floor: 0,
      duplex: true,
      total_area: 0,
      living_area: 0,
      living_rooms: 0,
      ground_area: 0,
      lift: true,
      furnitured: true,
      type_of_ownership: 'طابو أخضر - 2400 سهم',
      price: 0,
      business_status: 'جديد',
      rent_plan_id: 1,
      // sell_plan_id: 2,
      built_at: null,
      reconstructed_at: null,
      status: '',
      price_per_day: 0,
      price_per_mm: 0,
      business_offer: 'بيع',
      views: 1,
      basement: true,
      created_at: null,
      reference_id: '',
      agency_ref_id: '',
      agent_ref_id: '',
      agency_rank: 100,
      agency_name: '',
      agency_mobile: '',
      agency_whatsapp: '',
      agency_telegram: '',
      agency_business_address: '',
      url2: [],
      thumbnails2: [],
      url: [],
      thumbnails: [],
      resType: 'ready',
      shortHeader: false,
      hideContact: false,
    };
  }
  

  componentWillReceiveProps(nextProps, nextState) {
    var icons = false;
    if (
      nextProps.requestFailedMessage &&
      nextProps.requestFailedMessage !== undefined &&
      nextProps.requestFailedMessage.errorMessage !== undefined &&
      nextProps.requestFailedMessage.errorMessage !== null &&
      // !this.state.noMessages &&
      nextProps.requestFailedMessage.errorMessage.data !== undefined &&
      nextProps.requestFailedMessage.errorMessage.data.errors !== undefined &&
      nextProps.requestFailedMessage.errorMessage.data.errors[0] !==
        undefined &&
      nextProps.requestFailedMessage.errorMessage.data.errors[0].detail !==
        undefined &&
      nextProps.requestFailedMessage.errorMessage.data.errors[0].detail
        .detail === `can't show this property now, it is hidden or Archived`
    ) {
      var errors = {};
      errors.visible = 'هذه الصفحة غير متوفرة حالياً';
      this.setState({
        pageNotFound: true,
        errors,
      });
    } else if (
      nextProps.requestFailedMessage !== undefined &&
      nextProps.requestFailedMessage.errorMessage !== undefined &&
      nextProps.requestFailedMessage.errorMessage !== null &&
      // !this.state.noMessages &&
      nextProps.requestFailedMessage.errorMessage.data !== undefined &&
      nextProps.requestFailedMessage.errorMessage.data.errors !== undefined &&
      nextProps.requestFailedMessage.errorMessage.data.errors[0] !==
        undefined &&
      nextProps.requestFailedMessage.errorMessage.data.errors[0].detail !==
        undefined &&
      this.props.match !== undefined &&
      nextProps.requestFailedMessage.errorMessage.data.errors[0].detail ===
        `Property with Reference_id: ${this.props.match.params.id} does not exist`
    ) {
      this.setState({ pageNotFound: true });
    } else if (
      nextProps.property !== undefined &&
      nextProps.property.reference_id !== this.state.reference_id &&
      nextProps.resType !== 'not complete'
    ) {
      const {
        title,
        sell_plan_id,
        days,
        temp_status,
        expiration_date,
        rented,
        rent_start,
        rent_end,
        pricing_type,
        type,
        roof_building_area,
        price_upon_review,
        rank,
        description,
        city,
        province,
        area,
        street,
        gps_location,
        bedrooms,
        bathrooms,
        toilets,
        garage,
        parking,
        balcony,
        view_direction,
        floor,
        duplex,
        total_area,
        living_area,
        living_rooms,
        ground_area,
        lift,
        furnitured,
        type_of_ownership,
        price,
        business_status,
        rent_plan_id,
        visible,
        built_at,
        reconstructed_at,
        status,
        price_per_day,
        price_per_mm,
        business_offer,
        views,
        basement,
        created_at,
        reference_id,
        agency_ref_id,
        agent_ref_id,
        agency_rank,
        agency_name,
        agency_mobile,
        agency_whatsapp,
        agency_telegram,
        agency_business_address,
        url,
        thumbnails,
      } = nextProps.property;
      let flatType =
        type === 'استوديو' ||
        type === 'شقة' ||
        type === 'بيت' ||
        type === 'شاليه' ||
        type === 'فيلا'
          ? true
          : false;
      let officeType =
        type === 'مقهى' ||
        type === 'مكتب' ||
        type === 'عيادة' ||
        type === 'مطعم' ||
        type === 'منشأة تصنيع' ||
        type === 'محطة وقود' ||
        type === 'محلات تجارية' ||
        type === 'محلات اصلاح' ||
        type === 'محلات لتربية الحيوانات'
          ? true
          : false;
      let pageTitle =
        this.props.source === undefined
          ? buildShowPropertyTitle(
              title,
              type,
              bedrooms,
              flatType,
              officeType,
              furnitured,
              business_offer,
              province,
              area
            )
          : '';

      let pageDescription =
        this.props.source === undefined
          ? buildShowPropertyDescription(
              title,
              gps_location,
              description,
              agency_name
            )
          : '';
      
      if (
        
        (url === null || url === undefined || url.length === 0)
      ) {
        this.setState({ shortHeader: true });
      } else {
        this.setState({ shortHeader: false });
      }

      this.setState({
        pageNotFound: false,
        pageTitle: buildGalleryTitle(this.state.title),
        pageDescription: buildGalleryTitle(this.state.description ),
        title,
        description,
        visible,
        sell_plan_id,
        days,
        temp_status,
        expiration_date,
        rented,
        rent_start,
        rent_end,
        pricing_type,
        type,
        roof_building_area,
        price_upon_review,
        rank,
        description,
        city,
        province,
        area,
        street,
        gps_location,
        bedrooms,
        bathrooms,
        toilets,
        garage,
        parking,
        balcony,
        view_direction,
        floor,
        duplex,
        total_area,
        living_area,
        living_rooms,
        ground_area,
        lift,
        furnitured,
        type_of_ownership,
        price,
        business_status,
        rent_plan_id,
        built_at,
        reconstructed_at,
        status,
        icons,
        price_per_day,
        price_per_mm,
        business_offer,
        views,
        basement,
        created_at,
        reference_id,
        agency_ref_id,
        agent_ref_id,
        agency_rank,
        agency_name,
        agency_mobile,
        agency_whatsapp,
        agency_telegram,
        agency_business_address,
        url,
        thumbnails: thumbnails,
      });

    }
 
    
   

    if (
      nextProps.resType !== undefined &&
      nextProps.resType !== this.state.resType
    ) {
      const { resType } = nextProps;
      this.setState({ resType });
    }

    if (nextProps.propertyO !== undefined && nextProps.propertyO !== null) {
      // if (nextProps.propertyO.reference_id !== this.state.reference_id) {
      if (Object.keys(nextProps.propertyO).length > 0) {
        const {
          title,
          // sell_plan_id,
          days,
          temp_status,
          expiration_date,
          rented,
          rent_start,
          rent_end,
          pricing_type,
          type,
          roof_building_area,
          price_upon_review,
          rank,
          description,
          city,
          province,
          area,
          street,
          gps_location,
          bedrooms,
          bathrooms,
          // toilets,
          garage,
          parking,
          balcony,
          view_direction,
          floor,
          duplex,
          total_area,
          living_area,
          living_rooms,
          ground_area,
          lift,
          furnitured,
          type_of_ownership,
          price,
          business_status,
          rent_plan_id,
          sell_plan_id,
          visible,
          built_at,
          reconstructed_at,
          status,
          price_per_day,
          price_per_mm,
          business_offer,
          views,
          basement,
          // created_at,
          // reference_id,
          // agency_ref_id,
          // agent_ref_id,
          // agency_rank,
          // agency_name,
          // agency_mobile,
          // agency_whatsapp,
          // agency_telegram,
          // agency_business_address,
          photo_data,
          url,
          thumbnails,
        } = nextProps.propertyO;
        icons = false;
        if (
          (bedrooms !== null && bedrooms !== undefined && bedrooms !== 0) ||
          (living_rooms !== null &&
            living_rooms !== undefined &&
            living_rooms !== 0) ||
          (bathrooms !== null && bathrooms !== undefined && bathrooms !== 0) ||
          (balcony !== null && balcony !== undefined && balcony !== 0) ||
          (floor !== undefined && floor !== null && floor !== -100) ||
          (garage !== null && garage !== undefined && garage === true) ||
          (furnitured !== null &&
            furnitured !== undefined &&
            furnitured === true) ||
          (parking !== null && parking !== undefined && parking === true) ||
          (duplex !== null && duplex !== undefined && duplex === true) ||
          (lift !== null && lift !== undefined && lift === true) ||
          (basement !== null && basement !== undefined && basement === true)
        ) {
          icons = true;
        }
        if (
          (gps_location === null ||
            gps_location === undefined ||
            gps_location === '' ||
            !mapsEnabled) &&
          (photo_data === null ||
            photo_data === undefined ||
            photo_data.length === 0) &&
          (this.state.url === null ||
            this.state.url === undefined ||
            this.state.url.length === 0)
        ) {
          this.setState({ shortHeader: true });
        } else {
          this.setState({ shortHeader: false });
        }

        this.setState({
          title,
          days,
          temp_status,
          expiration_date,
          rented,
          rent_start,
          rent_end,
          pricing_type,
          type,
          roof_building_area,
          price_upon_review,
          rank,
          icons,
          description,
          city,
          province,
          area,
          street,
          gps_location,
          bedrooms,
          bathrooms,
          garage,
          parking,
          balcony,
          view_direction,
          floor,
          duplex,
          total_area,
          living_area,
          living_rooms,
          ground_area,
          lift,
          furnitured,
          type_of_ownership,
          price,
          business_status,
          rent_plan_id,
          sell_plan_id,
          visible,
          built_at,
          reconstructed_at,
          status,
          price_per_day,
          price_per_mm,
          business_offer,
          views,
          basement,
          url2: url,
          thumbnails2: thumbnails,
          url: photo_data,
          thumbnails: photo_data,
          hideContact: true,
        });
      }
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
    if (
      this.props.match !== undefined &&
      this.props.match.params !== undefined
    ) {
      const { id } = this.props.match.params;
      if (id !== undefined && id !== null) {
        const ikarAdmin = this.props.ikarAdmin;
        const isTakingAgencyRole = this.props.isTakingAgencyRole;
        const takenAgencyRoleToken = this.props.takenAgencyRoleToken;
        this.setState({ id });
        this.props.getProperty(
          ikarAdmin,
          isTakingAgencyRole,
          takenAgencyRoleToken,
          id
        );
      }
    }
  }


  resize() {
    let currentWidth = window.innerWidth;
    if (currentWidth >= 992) {
      this.setState({ largeScreen: true });
    } else {
      this.setState({ largeScreen: false });
    }
    if (currentWidth >= 576) {
      this.setState({ mediumScreen: true });
    } else {
      this.setState({ mediumScreen: false });
    }
  }


    render() {
        return (
          <div>
          <Helmet>
          {this.state.title !== '' ? (
                <link
                  rel="canonical"
                  href={`${linkDomain}/properties/${this.state.id}/gallery`}
                />
              ) : null}

            <title>{buildGalleryTitle(this.state.title)}</title>
            <meta name="description" content={buildGalleryTitle(this.state.description)} />
          </Helmet>
        <div>
          <div className="row justify-content-around align-items-right">
                <a href={`${linkDomain}/properties/${this.state.reference_id}`}> العودة إلى تفاصيل العقار</a>
          </div>
          <br></br>
        <div className="row justify-content-around align-items-center">

{this.state.url.map(thumbnail => (
                      <img
                        src={`${imageDomain}/${thumbnail}`}
                        alt={buildAlt(this.state.title,this.state.type,this.state.business_offer,this.state.province,
                          this.state.area, this.state.furnitured,this.state.duplex,this.state.basement,this.state.lift,
                         this.state.parking,this.state.garage, thumbnail )}
                        className="img-show-property-thumbnail-first"
                      />
                    ) 
                )}
            </div>
        </div>
        </div>);}
}


const mapStateToProps = (state) => ({
  property: state.property.property,
  resType: state.property.resType,
  requestFailedMessage: state.property.requestFailedMessage,
  ikarAdmin: state.auth.ikarAdmin,
  isLoggedIn: state.auth.isLoggedIn,
  isTakingAgencyRole: state.auth.isTakingAgencyRole,
  takenAgencyRoleToken: state.auth.takenAgencyRoleToken,
  takenAgencyRoleAgencyRefId: state.auth.takenAgencyRoleAgencyRefId,
  takenAgencyRoleId: state.auth.takenAgencyRoleId,
  ikarId: state.auth.ikarId,
  agencyRefId: state.auth.agencyRefId,
});


export default connect(mapStateToProps, { getProperty, callForProperty })(
  Gallery
);
