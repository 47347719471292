import {
  UPDATE_AGENCY,
  BLOCK_AGENCY,
  UPDATE_AGENCY_RANK,
  UPDATE_AGENCY_EMAIL,
  UPDATE_AGENCY_PASSWORD,
  ACTIVE_AGENCY,
} from './types';
import axios from 'axios';
import { mainDomain } from './../mainConf';

export const editAgency = (
  agency,
  isTakingAgencyRole,
  takenAgencyRoleToken,
  processType
) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  var token = '';
  if (isTakingAgencyRole) {
    token = takenAgencyRoleToken;
  } else {
    token = localStorage.getItem('ikar_token');
  }

  await axios
    .put(
      `${mainDomain}/v1/admin/agency/update`,
      agency,
      // ,
      // {
      //   withCredentials: true,
      // }
      {
        headers: {
          Accept: 'application/json',
          //'Content-Type': 'application/json',
          //'Access-Control-Allow-Origin': '*',
          //'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
          Authorization: token,
        },
      }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
        res.message = processType;
      } else res = {};
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
    });
  dispatch({
    type: UPDATE_AGENCY,
    payload: res,
  });
};

export const editAgencyRank = (
  agency,
  isTakingAgencyRole,
  takenAgencyRoleToken,
  processType
) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  var token = '';
  if (isTakingAgencyRole) {
    token = takenAgencyRoleToken;
  } else {
    token = localStorage.getItem('ikar_token');
  }

  await axios
    .put(
      `${mainDomain}/v1/admin/agency/edit_rank`,
      agency,
      // ,
      // {
      //   withCredentials: true,
      // }
      {
        headers: {
          Accept: 'application/json',
          //'Content-Type': 'application/json',
          //'Access-Control-Allow-Origin': '*',
          //'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
          Authorization: token,
        },
      }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
        res.message = processType;
      } else res = {};
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
    });
  dispatch({
    type: UPDATE_AGENCY_RANK,
    payload: res,
  });
};

export const editAgencyEmail = (
  agency,
  isTakingAgencyRole,
  takenAgencyRoleToken,
  processType
) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  var token = '';
  if (isTakingAgencyRole) {
    token = takenAgencyRoleToken;
  } else {
    token = localStorage.getItem('ikar_token');
  }

  await axios
    .post(
      `${mainDomain}/v1/users/email/update`,
      agency,
      // ,
      // {
      //   withCredentials: true,
      // }
      {
        headers: {
          Accept: 'application/json',
          //'Content-Type': 'application/json',
          //'Access-Control-Allow-Origin': '*',
          //'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
          Authorization: token,
        },
      }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
        res.message = processType;
      } else res = {};
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
    });
  dispatch({
    type: UPDATE_AGENCY_EMAIL,
    payload: res,
  });
};

export const editAgencyPassword = (
  agency,
  isTakingAgencyRole,
  takenAgencyRoleToken,
  processType
) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  var token = '';
  if (isTakingAgencyRole) {
    token = takenAgencyRoleToken;
  } else {
    token = localStorage.getItem('ikar_token');
  }

  await axios
    .post(
      `${mainDomain}/v1/users/pwd/update`,
      agency,
      // ,
      // {
      //   withCredentials: true,
      // }
      {
        headers: {
          Accept: 'application/json',
          //'Content-Type': 'application/json',
          //'Access-Control-Allow-Origin': '*',
          //'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,OPTIONS',
          Authorization: token,
        },
      }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
        res.message = processType;
      } else res = {};
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
    });
  dispatch({
    type: UPDATE_AGENCY_PASSWORD,
    payload: res,
  });
};

export const blockAgency = (blockData, processType) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  var token = '';
  token = localStorage.getItem('ikar_token');
  await axios
    .post(
      `${mainDomain}/v1/admin/agency/block`,
      blockData,
      // ,
      // {
      //   withCredentials: true,
      // }
      {
        headers: {
          Accept: 'application/json',
          Authorization: token,
        },
      }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
        res.message = processType;
      } else res = {};
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
    });
  dispatch({
    type: BLOCK_AGENCY,
    payload: res,
  });
};

export const activeAgency = (activeData, processType) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  var token = '';
  token = localStorage.getItem('ikar_token');
  await axios
    .put(
      `${mainDomain}/v1/admin/confirm`,
      activeData,
      // ,
      // {
      //   withCredentials: true,
      // }
      {
        headers: {
          Accept: 'application/json',
          Authorization: token,
        },
      }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
        res.message = processType;
      } else res = {};
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
    });
  dispatch({
    type: ACTIVE_AGENCY,
    payload: res,
  });
};
