import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';
import { Checkbox } from 'primereact/checkbox';
import { getFaq } from './../../actions/faqActions';
import { editFaq } from './../../actions/faqActionsUD';
import queryString from 'query-string';
import { Growl } from 'primereact/growl';
import NotFound from './../../pages/NotFound';
class EditFAQ extends Component {
  constructor() {
    super();
    this.state = {
      reference_id: '',
      directory: '',
      requestSuccessMessage: '',
      requestMessage: '',
      requestFailedMessage: {},
      faq: {},
      resType: '',
      title: '',
      details: '',
      question_number: '',
      visible: false,
      init2: false,
      error1: false,
      error2: false,
      error3: false,
      pageTitle: 'Ikar.sy |FAQ',
      pageDescription:
        'سوق العقارات الإلكتروني في سورية Ikar.sy مخصص لجمع عروض بيع، شراء و تأجير العقارات من جميع مكاتب العقارات في سورية. الإعلان على Ikar.sy مجاني و متاح فقط للمكاتب العقارية والمتعهدين المسجلين لدى الموقع في جميع المحافظات السورية. | Ikar.sy',
    };
  }
  componentWillReceiveProps = async (nextProps, nextState) => {
    if (
      nextProps.requestSuccessMessage !== this.state.requestSuccessMessage &&
      this.state.requestSuccessMessage === 'ready' &&
      this.state.requestFailedMessage.error === undefined
    ) {
      this.setState({
        // requestSuccessMessage: nextProps.requestSuccessMessage,
        requestSuccessMessage: nextProps.requestFailedMessage,
      });

      switch (nextProps.requestSuccessMessage) {
        case 'تعديل السؤال المحدد':
          this.growl.show({
            severity: 'success',
            summary: 'تم تعديل السؤال بنجاح',
          });

          const searchUrlParams = await queryString.parse(
            this.props.locationSearch
          );
          if (searchUrlParams.faq_id !== undefined) {
            // agency_ref_id = searchUrlParams.agency_id;
            const id = searchUrlParams.faq_id;
            if (id !== undefined && id !== null) {
              this.props.getFaq(true, id);
            }
          }
          break;

        case 'error in edit question':
          this.growl.show({
            severity: 'error',
            summary: 'حدثت مشكلة في تعديل السؤال الرجاء المحاولة لاحقا ',
          });
          break;

        default:
          break;
      }
    } else {
      if (
        nextProps.faq !== this.state.faq &&
        nextProps.requestMessage === 'get faq ready'
      ) {
        await this.setState({
          faq: nextProps.faq,
          title: nextProps.faq.title,
          details: nextProps.faq.details,
          visible: nextProps.faq.visible,
          question_number: nextProps.faq.question_number,
          reference_id: nextProps.faq.reference_id,
        });
      }

      if (
        nextProps.faq !== undefined &&
        nextProps.faq !== this.state.faq &&
        nextProps.requestMessage === 'edit is succeeded'
      ) {
        const { title, details, question_number, visible } = nextProps.faq;

        await this.setState({
          title,
          details,
          question_number,
          visible,
        });
      }
    }

    if (
      nextProps.resType !== undefined &&
      nextProps.resType !== this.state.resType
    ) {
      const { resType } = nextProps;
      this.setState({ resType });
    }
  };
  async componentDidMount() {
    const searchUrlParams = await queryString.parse(this.props.locationSearch);

    if (searchUrlParams.faq_id !== undefined) {
      // agency_ref_id = searchUrlParams.agency_id;
      const id = searchUrlParams.faq_id;
      if (id !== undefined && id !== null) {
        this.props.getFaq(true, id);
      }
    }
  }
  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
      error1: false,
      error2: false,
      error3: false,
    });
  onEditSubmit = async (str, e) => {
    e.preventDefault();
    const { title, details, visible, reference_id } = this.state;

    const question_number = parseInt(this.state.question_number);
    if (question_number === -100 || question_number === null) {
      this.setState({ error3: true });
    }
    //question_number = parseInt(question_number);

    if (title === '' || title === null) {
      this.setState({ error1: true });
    }

    if (details === '' || details === null) {
      this.setState({ error2: true });
    }
    //const question_number = 1;
    //const visible = true;
    // const title = "what";
    // const details = "yes";

    const faqObject = {
      title,
      details,
      question_number,
      visible,
      reference_id,
    };
    //let token = localStorage.getItem("ikar_token");
    let res = {};
    let errorRes = {
      errorStatus: '',
      errorMessage: '',
    };
    this.setState({ requestSuccessMessage: 'ready' });
    this.props.editFaq(faqObject, 'تعديل السؤال المحدد');
  };
  onCheckBoxChange = async (e) => {
    if (this.state.init2) {
      await this.setState({ init2: false, [e.target.name]: true });
    } else {
      await this.setState({ [e.target.name]: e.target.checked });
    }
  };
  render() {
    const {
      visible,
      title,
      details,
      question_number,
      faq,
      resType,
    } = this.state;

    return (
      <div>
        <Helmet>
          <title>{this.state.pageTitle}</title>
          <meta name="description" content={this.state.pageDescription} />
        </Helmet>
        {/* //////////// */}
        {resType === 'complete' ? (
          <div>
            <Growl ref={(el) => (this.growl = el)} />
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="text-center pb-4 pt-3 mb-4">
                  <h1 className="main_title">تعديل سؤال</h1>
                </div>
              </div>
            </div>
            <form className="search_div  round-div p-1 mb-4">
              <div className="row justify-content-around mb-4">
                <div className="form-group text-right  col-md-6 col-lg-6 col-sm-12 col-xs-12 mb-4 ">
                  <label htmlFor="title">
                    <span> السؤال: </span>
                  </label>
                  <div>
                    <textarea
                      rows="2"
                      name="title"
                      className={classnames('form-control', {
                        'is-invalid': this.state.error1,
                      })}
                      placeholder=""
                      value={this.state.title}
                      onChange={this.onChange}
                    />
                  </div>
                  {this.state.error1 ? (
                    <div className="invalid-feedback-custom">
                      الرجاء ادخال السؤال{' '}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                <div className="form-group text-right col-lg-6 col-md-6 col-sm-12 col-xs-12  mb-4">
                  <label htmlFor="details">
                    الجواب: <span className="display_mandatory"></span>
                  </label>
                  <div>
                    <textarea
                      rows="4"
                      name="details"
                      className={classnames('form-control', {
                        'is-invalid': this.state.error2,
                      })}
                      placeholder=""
                      value={this.state.details}
                      onChange={this.onChange}
                    />
                  </div>
                  {this.state.error2 ? (
                    <div className="invalid-feedback-custom">
                      الرجاء ادخال الجواب{' '}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="row justify-content-around mb-4 ">
                <div className="form-group text-right col-lg-6 col-md-6 col-sm-12 col-xs-12  mb-4">
                  <label htmlFor="question_number"> رقم السؤال:</label>
                  <div>
                    <select
                      name="question_number"
                      className={classnames('form-control', 'text-right')}
                      onChange={this.onChange}
                      value={this.state.question_number}
                      style={{ width: '50%', marginLeft: '50%' }}
                    >
                      <option value="-100">غير محدد</option>
                      <option value="1"> 1</option>
                      <option value="2"> 2</option>
                      <option value="3"> 3</option>
                      <option value="4"> 4</option>
                      <option value="5"> 5</option>
                      <option value="6"> 6</option>
                      <option value="7"> 7</option>
                      <option value="8"> 8</option>
                      <option value="9"> 9</option>
                      <option value="10"> 10</option>
                      <option value="11"> 11</option>
                      <option value="12"> 12</option>
                      <option value="13"> 13</option>
                      <option value="14"> 14</option>
                      <option value="15"> 15</option>
                      <option value="16"> 16</option>
                      <option value="17"> 17</option>
                      <option value="18"> 18</option>
                      <option value="19"> 19</option>
                      <option value="20"> 20</option>
                      <option value="21"> 21</option>
                      <option value="22"> 22</option>
                      <option value="23"> 23</option>
                      <option value="24"> 24</option>
                      <option value="25"> 25</option>
                      <option value="26"> 26</option>
                      <option value="27"> 27</option>
                    </select>
                  </div>
                  {this.state.error3 ? (
                    <div className="invalid-feedback-custom">
                      الرجاء ادخال رقم السؤال{' '}
                    </div>
                  ) : (
                    ''
                  )}
                </div>

                <div className="form-group text-right col-lg-6 col-md-6 col-sm-12 col-xs-12  mb-4">
                  <label className="form-check-label " htmlFor="visible">
                    مرئي
                  </label>
                  <span className="form-group text-right ">
                    <Checkbox
                      checked={visible}
                      onChange={(e) => this.setState({ visible: e.checked })}
                    />
                  </span>
                </div>
              </div>
            </form>
            <div className="text-right">
              <span>
                لادخال رابط فيسبوك - يوتيوب - انستغرام - تويتر أو رقم هاتف ضمن
                الجواب يجب اتباع المثال التالي{' '}
              </span>
              <div className="form-group text-right col-12  mb-4">
                <div>
                  <textarea
                    readOnly="true"
                    rows="6"
                    //   className={classnames('form-control', {
                    //     'is-invalid': errors.description,
                    //   })}
                    className={classnames('form-control', 'text-right', {
                      'is-invalid': this.state.error2,
                    })}
                    placeholder=""
                  >{`فيديو يوتيوب
              <<=https://www.youtube.com/channel/UCHc5tI-ELOt7q6ryCoDB_yw/videos;موقع عقار على اليوتيوب;youtube=>>
               صفحة فيسبوك 
              <<=https://www.facebook.com/Ikarsy-109054930712834;افضل موقع عربي للعقارات;facebook=>>
              رقم اتصال 
              <<=00963923456789;اتصل بنا;mobile=>>`}</textarea>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 md-12 m-auto">
                <button
                  className="btn  submit-button m-1 text-center btn-block"
                  //  style={{ position: "right" }}
                  onClick={this.onEditSubmit.bind(this, 'submit')}
                >
                  تعديل السؤال
                </button>
              </div>
            </div>
          </div>
        ) : resType === 'not complete' ? (
          <NotFound></NotFound>
        ) : null}
      </div>
    );
  }
}

EditFAQ.propTypes = {
  editFaq: PropTypes.func.isRequired,
  getFaq: PropTypes.func.isRequired,
  faq: PropTypes.object.isRequired,
  requestFailed: PropTypes.bool.isRequired,
  requestFailedMessage: PropTypes.object.isRequired,
  isTakingAgencyRole: PropTypes.bool.isRequired,
  ikarAdmin: PropTypes.bool.isRequired,
  requestSuccessMessage: PropTypes.string.isRequired,
  short_id: PropTypes.string.isRequired,
  resType: PropTypes.string.isRequired,
};
const mapStateToProps = (state) => ({
  ////reducers
  faq: state.faq.faq,
  requestFailed: state.faq.requestFailed,
  requestFailedMessage: state.faq.requestFailedMessage,
  isTakingAgencyRole: state.auth.isTakingAgencyRole,
  takenAgencyRoleToken: state.auth.takenAgencyRoleToken,
  ikarAdmin: state.auth.ikarAdmin,
  requestSuccessMessage: state.faqUD.requestSuccessMessage,
  requestMessage: state.faq.requestMessage,
  short_id: state.faqUD.short_id,
  resType: state.faq.resType,
  requestMessage: state.faq.requestMessage,
  // uploadedPhotoName: state.property.uploadedPhotoName,
  directory: state.faq.directory,
});
export default connect(mapStateToProps, {
  editFaq,
  getFaq,
  // addPropertyPhoto,
})(EditFAQ);
