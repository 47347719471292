import { UPDATE_USER } from '../actions/types';
import shortid from 'shortid';
const initialState = {
  users: [],
  user: {},
  requestFailed: false,
  requestFailedMessage: {},
  requestSuccessMessage: '',
  short_id: ''
};

export default function(state = initialState, action) {
  switch (action.type) {
    case UPDATE_USER:
      if (
        (Object.keys(action.payload).length > 0 &&
          action.payload.errorMessage === '') ||
        action.payload.errorMessage === undefined
      ) {
        action.payload.user.processStatus = 'updated successfully';
        return {
          user: action.payload.user,
          users: [],
          requestFailed: false,
          requestFailedMessage: { error: 'no' },
          requestSuccessMessage: action.payload.message,
          short_id: shortid.generate()
        };
      } else {
        return {
          //...state,
          user: [],
          requestFailed: true,
          requestFailedMessage: action.payload,
          //add state data
          users: [],
          requestSuccessMessage: '',
          short_id: shortid.generate()
          //
        };
      }

    default:
      return state;
  }
}
