import {
  LOGIN_PROCESS,
  LOGOUT_PROCESS,
  LOGIN_CHECKING,
  TAKE_AGENCY_ROLE,
  LEAVE_AGENCY_ROLE,
  // LEAVE_AGENCY_ROLE2,
} from './types';
import axios from 'axios';
import { mainDomain } from './../mainConf';

export const loginProcess = (loginData) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  await axios
    .post(
      `${mainDomain}/v1/login`,
      loginData
      // , {
      //   withCredentials: true,
      // }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
      } else res = {};
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }

      res = errorRes;
    });
  dispatch({
    type: LOGIN_PROCESS,
    payload: res,
  });
};
const storageAvailable = (type) => {
  try {
    var storage = window[type],
      x = '__storage_test__';
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e) {
    return false;
  }
};
export const loginChecking = () => async (dispatch) => {
  var localRes = {};
  let res = {};
  let resType = 'ready';
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  if (storageAvailable('localStorage')) {
    var token = localStorage.getItem('ikar_token');
    var id = localStorage.getItem('ikar_id');
    var name = localStorage.getItem('ikar_name');
    var ikar_admin = localStorage.getItem('ikar_a') === 'true' ? true : false;
    var blocked = localStorage.getItem('ikar_is_b') === 'true' ? true : false;
    var agency_admin =
      localStorage.getItem('ikar_a_a') === 'true' ? true : false;
    var ikar_email = localStorage.getItem('ikar_email');
    var ikar_agency_ref_id = localStorage.getItem('ikar_agency_ref_id');

    var is_taking_agency_role =
      localStorage.getItem('ikar_is_taking_agency_role') === 'true'
        ? true
        : false;
    var taken_agency_role_email = localStorage.getItem(
      'ikar_taken_agency_role_email'
    );
    var taken_agency_role_id = localStorage.getItem(
      'ikar_taken_agency_role_id'
    );
    var taken_agency_role_name = localStorage.getItem(
      'ikar_taken_agency_role_name'
    );
    var taken_agency_role_token = localStorage.getItem(
      'ikar_taken_agency_role_token'
    );
    var taken_agency_role_info = localStorage.getItem(
      'ikar_taken_agency_role_info'
    );
    var taken_agency_role_agency_refId = localStorage.getItem(
      'ikar_taken_agency_role_agency_refId'
    );

    //var ikar_admin = localStorage.getItem('ikar_a');
    //var agency_admin = localStorage.getItem('agency_admin');
    // var auth_token = { ikar_token, ikar_admin, agency_admin };

    // if (token === undefined || token === '') {
    //   ikar_admin = false;
    //   agency_admin = false;
    // }
    // if (
    //   (ikar_admin !== undefined && ikar_admin === true) ||
    //   // &&
    //   // token !== undefined &&
    //   // token !== ''
    //   (agency_admin !== undefined && agency_admin === true)
    //   // &&
    //   // token !== undefined &&
    //   // token !== ''
    // ) {
    if (token === undefined || token === '') {
      ikar_admin = false;
      agency_admin = false;
    }
    if (
      (ikar_admin !== undefined &&
        ikar_admin === true &&
        token !== undefined &&
        token !== '') ||
      (agency_admin !== undefined &&
        agency_admin === true &&
        token !== undefined &&
        token !== '')
    ) {
      await axios
        // .post(`${mainDomain}/v1/test_token`, null, {
        //   withCredentials: true,
        // })
        .post(`${mainDomain}/v1/test_token`, { token })
        .then(function (response) {
          if (
            response !== null &&
            response !== undefined &&
            Object.keys(response).length > 0
          ) {
            res = response.data;
            resType = 'complete';
          } else res = {};
        })
        .catch(function (error) {
          if (error.response) {
            errorRes = {
              errorStatus: error.response.status,
              errorMessage: error.response,
            };
          } else if (error.request) {
            errorRes = {
              errorMessage: error.request,
            };
          } else {
            errorRes = { errorMessage: error.message };
          }
          res = errorRes;
          resType = 'not complete';
        });
      if (
        res.ikar_admin !== undefined &&
        res.ikar_admin === true &&
        res.agency_admin !== undefined &&
        res.agency_admin === false
      ) {
        ikar_admin = true;
        // blocked = false;
      } else {
        ikar_admin = false;
        // blocked = false;
      }
      if (
        res.agency_admin !== undefined &&
        res.agency_admin === true &&
        res.ikar_admin !== undefined &&
        res.ikar_admin === false
      ) {
        agency_admin = true;
        blocked = res.blocked;
      } else {
        agency_admin = false;
        // blocked = true;
      }
      // if (
      //   ikar_admin === true ||
      //   agency_admin === true
      //   // &&
      //   // token !== undefined &&
      //   // token !== ''
      // ) {
      if (
        (ikar_admin === true || agency_admin === true) &&
        token !== undefined &&
        token !== ''
      ) {
        /////// localStorage.setItem('ikar_token', token);
        localStorage.setItem('ikar_is_b', res.blocked);
        localStorage.setItem('ikar_a', ikar_admin);
        localStorage.setItem('ikar_a_a', agency_admin);
        localStorage.setItem('ikar_id', res.id);
        localStorage.setItem('ikar_name', res.name);
      }
    } else {
      resType = 'complete';
    }
    // if (
    //   (res.ikar_admin === undefined && res.agency_admin === undefined) ||
    //   (ikar_admin === false && agency_admin === false)
    //   //1111   &&
    //   //1111 resType === 'complete'
    //   //  &&
    //   // token !== undefined &&
    //   // token !== ''
    // ) {
    if (
      ((res.ikar_admin === undefined && res.agency_admin === undefined) ||
        (ikar_admin === false && agency_admin === false)) &&
      resType === 'complete' &&
      token !== undefined &&
      token !== ''
    ) {
      localStorage.removeItem('ikar_token');
      localStorage.removeItem('ikar_id');
      localStorage.removeItem('ikar_name');
      localStorage.removeItem('ikar_a');
      localStorage.removeItem('ikar_a_a');
      localStorage.removeItem('ikar_agency_ref_id');
      localStorage.removeItem('ikar_email');
      localStorage.removeItem('ikar_is_b');
      localStorage.removeItem('first_login');

      localStorage.removeItem('ikar_is_taking_agency_role');
      localStorage.removeItem('ikar_taken_agency_role_email');
      localStorage.removeItem('ikar_taken_agency_role_id');
      localStorage.removeItem('ikar_taken_agency_role_name');
      localStorage.removeItem('ikar_taken_agency_role_token');
      localStorage.removeItem('ikar_taken_agency_role_info');
      localStorage.removeItem('ikar_taken_agency_role_agency_refId');
      // alert('dd');
      token = '';
      id = '';
      name = '';
      ikar_agency_ref_id = '';
      ikar_email = '';
      is_taking_agency_role = false;
      taken_agency_role_email = '';
      taken_agency_role_token = '';
      taken_agency_role_info = '';
      taken_agency_role_agency_refId = '';
      taken_agency_role_id = '';
      taken_agency_role_name = '';
    }
    localRes = {
      resType,
      token,
      id,
      name,
      ikar_admin,
      agency_admin,
      blocked,
      email: ikar_email,
      agency_ref_id: ikar_agency_ref_id,
      is_taking_agency_role,
      taken_agency_role_email,
      taken_agency_role_token,
      taken_agency_role_info,
      taken_agency_role_agency_refId,
      taken_agency_role_id,
      taken_agency_role_name,
    };
  } else {
    alert('Please enable cookies in your browser before continue');
  }
  // localRes.email = ikar_email;
  // localRes.agency_ref_id = ikar_agency_ref_id;
  dispatch({
    type: LOGIN_CHECKING,
    payload: localRes,
  });
};

export const takeAgencyRole = (agencyRefId) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  await axios
    .post(
      `${mainDomain}/v1/become`,
      { agency_ref_id: agencyRefId },
      // ,
      // {
      //   withCredentials: true,
      // }
      {
        headers: {
          Accept: 'application/json',
          //'Content-Type': 'application/json',
          Authorization: localStorage.getItem('ikar_token'),
        },
      }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
        //res.agency_ref_id = agencyRefId;
      } else {
        res = {};
      }
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        if (error.request) {
          errorRes = {
            errorMessage: error.request,
          };
        }
      } else {
        errorRes = {
          errorMessage: error.message,
        };
      }
      res = errorRes;
    });
  dispatch({
    type: TAKE_AGENCY_ROLE,
    payload: res,
  });
};

// export const leaveAgencyRole = () => async (dispatch) => {
//   let res = {};
//   let errorRes = {
//     errorStatus: '',
//     errorMessage: '',
//   };
//   await axios
//     .get(`${mainDomain}/v1/return`, null, {
//       withCredentials: true,
//     })
//     .then(function (response) {
//       if (
//         response !== null &&
//         response !== undefined &&
//         Object.keys(response).length > 0
//       ) {
//         res = response.data;
//       } else {
//         res = {};
//       }
//     })
//     .catch(function (error) {
//       if (error.response) {
//         errorRes = {
//           errorStatus: error.response.status,
//           errorMessage: error.response,
//         };
//       } else if (error.request) {
//         if (error.request) {
//           errorRes = {
//             errorMessage: error.request,
//           };
//         }
//       } else {
//         errorRes = {
//           errorMessage: error.message,
//         };
//       }
//       res = errorRes;
//     });
//   dispatch({
//     type: LEAVE_AGENCY_ROLE,
//     payload: res,
//   });
// };

// export const leaveAgencyRole2 = () => async (dispatch) => {
//   let res = {};

//   dispatch({
//     type: LEAVE_AGENCY_ROLE2,
//     payload: res,
//   });
// };
// export const logoutProcess = () => async (dispatch) => {
//   let res = {};
//   let errorRes = {
//     errorStatus: '',
//     errorMessage: '',
//   };
//   await axios
//     .post(`${mainDomain}/v1/logout`, null, {
//       withCredentials: true,
//     })
//     .then(function (response) {
//       if (
//         response !== null &&
//         response !== undefined &&
//         Object.keys(response).length > 0
//       ) {
//         res = response.data;
//       } else {
//         res = {};
//       }
//     })
//     .catch(function (error) {
//       if (error.response) {
//         errorRes = {
//           errorStatus: error.response.status,
//           errorMessage: error.response,
//         };
//       } else if (error.request) {
//         if (error.request) {
//           errorRes = {
//             errorMessage: error.request,
//           };
//         }
//       } else {
//         errorRes = {
//           errorMessage: error.message,
//         };
//       }
//       res = errorRes;
//     });
//   dispatch({
//     type: LOGOUT_PROCESS,
//     payload: res,
//   });
// };

export const leaveAgencyRole = () => (dispatch) => {
  let res = {};
  dispatch({
    type: LEAVE_AGENCY_ROLE,
    payload: res,
  });
};
export const logoutProcess = () => (dispatch) => {
  let res = {};
  dispatch({
    type: LOGOUT_PROCESS,
    payload: res,
  });
};
