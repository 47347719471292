import React, { Component } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { resetPassword } from './../../actions/authPasswordResetActions';
import { Helmet } from 'react-helmet';
class ResetPassword extends Component {
  constructor(props) {
    super(props);
    document.title = 'تغيير كلمة السر | Ikar.sy';
    this.state = {
      pageTitle: '',
      pageDescription: '',
      newPassword: '',
      newPasswordConfirmation: '',
      errors: {},
      resMessages: {},
      processResType: '',
      processScreen: false,
      process1Active: false,
      process2Active: false,
      receivedToken: '',
    };
  }
  componentDidMount() {
    const searchUrlParams = queryString.parse(this.props.location.search);
    if (
      searchUrlParams.reset_password_token !== undefined &&
      searchUrlParams.reset_password_token !== ''
    ) {
      this.setState({
        process1Active: true,
        receivedToken: searchUrlParams.reset_password_token,
      });
    }
  }
  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.processResType !== this.state.processResType) {
      this.setState({
        processResType: nextProps.processResType,
        processScreen: false,
      });
      if (nextProps.processResType === 'complete') {
        this.setState({
          process2Active: true,
          process1Active: false,
          resMessages: {
            errorMessage: 'تم تعديل كلمة السر بنجاح',
          },
        });
      }
      if (
        nextProps.resetPasswordMessage.errorMessage !== undefined &&
        nextProps.resetPasswordMessage.errorMessage.data !== undefined &&
        nextProps.resetPasswordMessage.errorMessage.data.errors[0].title ===
          `Reset_password_token expired. Request a new reset_password_token and try again` &&
        this.state.process1Active
      ) {
        this.setState({
          // process1Active: true,
          // process2Active: false,
          resMessages: {
            errorMessage:
              'انتهت صلاحية الرابط المرسل, الرجاء اعادة ارسال رسالة التفعيل من جديد',
          },
        });
      }
      // else if (
      //   nextPro.resetPasswordMessage.errorMessage !== undefined &&
      //   nextPro.resetPasswordMessage.errorMessage.data !== undefined &&
      //   nextPro.resetPasswordMessage.errorMessage.data.errors[0].detail ===
      //     `user with email ${this.state.recoveryEmail} does not exist, or email has not been confirmed yet!.` &&
      //   this.state.process2Active
      // ) {
      //   this.setState({
      //     process1Active: false,
      //     process2Active: true,
      //     resMessages: {
      //       errorMessage: 'الرجاء التحقق من رقم الهاتف المدخل'
      //     }
      //   });
      // }

      // if (
      //   nextPro.resetPasswordMessage.message !== undefined &&
      //   nextPro.resetPasswordMessage.message ===
      //     `please enter your mobile number to ensure this process` &&
      //   this.state.process1Active
      // ) {
      //   this.setState({
      //     process1Active: false,
      //     process2Active: true,
      //     resMessages: {}
      //   });
      // } else if (
      //   nextPro.resetPasswordMessage.message !== undefined &&
      //   nextPro.resetPasswordMessage.message ===
      //     `password reset link has been sent to your email.` &&
      //   this.state.process2Active
      // ) {
      //   this.setState({
      //     process1Active: false,
      //     process2Active: false,
      //     resMessages: {
      //       errorMessage: 'تم ارسال رابط تغيير كلمة السر إلى ايميلك'
      //     }
      //   });
      // }
      // else if (
      //   nextPro.resetPasswordMessage.message !== undefined &&
      //   nextPro.resetPasswordMessage.message ===
      //     `please enter your mobile number to ensure this process` &&
      //   this.state.process2Active
      // ) {
      //   this.setState({
      //     process1Active: false,
      //     process2Active: true,
      //     resMessages: {
      //       errorMessage: 'الرجاء التحقق من رقم الهاتف المدخل'
      //     }
      //   });
      // }
    }
  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onSubmitNewPassword = (e) => {
    e.preventDefault();
    var errors = {};
    const { newPassword, newPasswordConfirmation, receivedToken } = this.state;
    if (newPassword.trim() === '') {
      errors.newPassword = 'يجب ادخال كلمة السر';
    } else if (newPassword.length < 9) {
      errors.newPassword = 'كلمة السر على الاقل تسعة رموز';
    }
    if (newPasswordConfirmation.trim() === '') {
      errors.newPasswordConfirmation = 'يجب ادخال اعادة كلمة السر';
    } else if (newPassword !== newPasswordConfirmation) {
      errors.newPasswordConfirmation = 'كلمتا السر غير متطابقتين';
    }
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }
    this.setState({
      errors: {},
      resMessages: {},
      processScreen: true,
      processResType: '',
    });
    var passwordData = {};
    passwordData.user = {};
    passwordData.user.reset_password_token = receivedToken;
    passwordData.user.password = newPassword;
    passwordData.user.password_confirmation = newPasswordConfirmation;
    this.props.resetPassword(passwordData);
  };
  render() {
    const {
      newPassword,
      newPasswordConfirmation,
      errors,
      resMessages,
      processScreen,
      process1Active,
      process2Active,
    } = this.state;
    return (
      <div>
        <Helmet>
          <title>{this.state.pageTitle}</title>
          <meta name="description" content={this.state.pageDescription} />
        </Helmet>
        <div className="row">
          <div className="col-md-10 mx-auto">
            <div className="mt-2 text-center pb-4">
              <h1 className="main_title">تغيير كلمة السر</h1>
            </div>
          </div>
          <div className="text-center display_5 pb-4 pt-3">
            {/* <Growl ref={el => (this.growl = el)}></Growl> */}
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mx-auto">
            <div className="card border-warning mb-3">
              {processScreen ? (
                // <div className="process-loader">Ikar.sy</div>
                <div className="process-loader2">
                  <div className="span text-center">
                    {/* <div className="location_indicator" /> */}
                    <div
                      className="spinner-border text-warning"
                      style={{ width: '3rem', height: '3rem' }}
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                    <p className="text-danger lead text-center">
                      <span>www.</span>
                      <span className="font-weight-bold">Ikar</span>
                      <span className="font-weight-bold">.sy</span>
                    </p>
                  </div>
                </div>
              ) : null}
              {process1Active ? (
                <React.Fragment>
                  <div className="card-header text-right">معلومات المستخدم</div>
                  <div className="card-body">
                    <form
                      onSubmit={this.onSubmitNewPassword}
                      style={{ direction: 'rtl' }}
                    >
                      <div className="form-group text-right mb-2">
                        <label htmlFor="newPassword">كلمةالسر:</label>
                        <input
                          type="password"
                          name="newPassword"
                          value={newPassword}
                          className={classnames('form-control', {
                            'is-invalid': errors.newPassword,
                          })}
                          onChange={this.onChange}
                        />
                        {errors.newPassword && (
                          <div className="invalid-feedback">
                            {errors.newPassword}
                          </div>
                        )}
                      </div>
                      <div className="form-group text-right mb-2">
                        <label htmlFor="newPasswordConfirmation">
                          اعادة كلمة السر:
                        </label>
                        <input
                          type="password"
                          name="newPasswordConfirmation"
                          value={newPasswordConfirmation}
                          className={classnames('form-control', {
                            'is-invalid': errors.newPasswordConfirmation,
                          })}
                          onChange={this.onChange}
                        />
                        {errors.newPasswordConfirmation && (
                          <div className="invalid-feedback">
                            {errors.newPasswordConfirmation}
                          </div>
                        )}
                      </div>
                      <input
                        type="submit"
                        value="اعادة تعيين كلمة السر"
                        className="btn btn-block submit-button"
                      ></input>
                      {Object.keys(resMessages).length > 0 ? (
                        <div className="alert-warning mt-2 p-1 text-right">
                          {resMessages.errorMessage}
                        </div>
                      ) : null}
                    </form>
                  </div>
                </React.Fragment>
              ) : process2Active &&
                !process1Active &&
                Object.keys(resMessages).length > 0 ? (
                <div className="alert-success my-3 p-1 text-right display_5">
                  {resMessages.errorMessage}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
ResetPassword.propTypes = {
  resetPasswordMessage: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  resetPasswordMessage: state.authPasswordReset.resetPasswordMessage,
  processResType: state.authPasswordReset.processResType,
});
export default connect(mapStateToProps, { resetPassword })(ResetPassword);
