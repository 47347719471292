import {
  UPDATE_PROPERTY,
  UPDATE_PROPERTY_RANK,
  UPDATE_PROPERTY_LAKTA,
  DELETE_PROPERTY,
} from './types';
import axios from 'axios';
import { mainDomain } from './../mainConf';

export const updateProperty = (
  property,
  isTakingAgencyRole,
  takenAgencyRoleToken,
  processType
) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  var token = '';
  if (isTakingAgencyRole) {
    token = takenAgencyRoleToken;
  } else {
    token = localStorage.getItem('ikar_token');
  }
  await axios
    .put(
      `${mainDomain}/v1/property/update`,
      property,
      // ,
      // {
      //   withCredentials: true
      // }
      {
        headers: {
          Accept: 'application/json',
          Authorization: token,
        },
      }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
        res.message = processType;
      }
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
    });
  dispatch({
    type: UPDATE_PROPERTY,
    payload: res,
  });
};

export const updatePropertyRank = (
  property,
  isTakingAgencyRole,
  takenAgencyRoleToken,
  processType
) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  var token = '';
  if (isTakingAgencyRole) {
    token = takenAgencyRoleToken;
  } else {
    token = localStorage.getItem('ikar_token');
  }
  await axios
    .put(
      `${mainDomain}/v1/property/edit_rank`,
      property,
      // ,
      // {
      //   withCredentials: true
      // }
      {
        headers: {
          Accept: 'application/json',
          Authorization: token,
        },
      }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
        res.message = processType;
      }
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
    });
  dispatch({
    type: UPDATE_PROPERTY_RANK,
    payload: res,
  });
};

export const updatePropertyLakta = (
  property,
  isTakingAgencyRole,
  takenAgencyRoleToken,
  processType
) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  var token = '';
  if (isTakingAgencyRole) {
    token = takenAgencyRoleToken;
  } else {
    token = localStorage.getItem('ikar_token');
  }
  await axios
    .put(
      `${mainDomain}/v1/property/manage_laqta`,
      property,
      // ,
      // {
      //   withCredentials: true
      // }
      {
        headers: {
          Accept: 'application/json',
          Authorization: token,
        },
      }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
        res.message = processType;
      }
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
    });
  dispatch({
    type: UPDATE_PROPERTY_LAKTA,
    payload: res,
  });
};
export const deleteProperty = (
  referenceId,
  isTakingAgencyRole,
  takenAgencyRoleToken,
  processType
) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  var token = '';
  if (isTakingAgencyRole) {
    token = takenAgencyRoleToken;
  } else {
    token = localStorage.getItem('ikar_token');
  }
  await axios
    .delete(
      `${mainDomain}/v1/property?reference_id=${referenceId}`,
      // ,
      // {
      //   withCredentials: true
      // }
      {
        headers: {
          Accept: 'application/json',
          Authorization: token,
        },
      }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
        res.message = processType;
      }
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
    });
  dispatch({
    type: DELETE_PROPERTY,
    payload: res,
  });
};
