import React, { Component } from 'react';
import Property from './Property';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { getPropertiesByUser } from './../../actions/searchActions';
import {
  updateProperty,
  deleteProperty,
} from './../../actions/propertyActionsUD';
import { Link } from 'react-router-dom';
import { loginProcess } from './../../actions/authActions';
import shortid from 'shortid';
import SearchBar from './../search/SearchBar.jsx';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Growl } from 'primereact/growl';
import defaultBackground from './../../assets/images/defaultBackground.png';
import { imageDomain } from './../../mainConf';
import Lightbox from 'react-image-lightbox';
import Pagination from './../layout/Pagination/Pagination';
class ShowPropertiesByUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      properties: [],
      selectedAgency: {},
      requestSuccessMessage: '',
      agencies: [],
      updatedReferenceId: '',
      takenAgencyRoleEmail: '',
      showCards: false,
      showMainPhoto: false,
      bgURL: '',
      total_properties: -1,
      pageNumber: 1,
    };
  }
  componentWillReceiveProps(nextProps, nextState) {
    if (
      nextProps.requestSuccessMessage !== this.state.requestSuccessMessage &&
      this.state.requestSuccessMessage !== ''
    ) {
      var result2 = [];
      this.setState({
        requestSuccessMessage: nextProps.requestSuccessMessage,
      });
      switch (nextProps.requestSuccessMessage) {
        case 'اظهار العقار المحدد':
          this.growl.show({
            severity: 'success',
            summary: 'تم اظهار العقار المحدد',
            // detail: 'Order submitted'
          });
          this.state.properties.forEach((obj, index) => {
            result2.push(obj);
            if (obj.reference_id === this.state.updatedReferenceId) {
              result2[index].visible = !obj.visible;
            }
          });
          this.setState({ properties: result2 });
          break;
        case 'اخفاء العقار المحدد':
          this.growl.show({
            severity: 'success',
            summary: 'تم اخفاء العقار المحدد',
          });
          this.state.properties.forEach((obj, index) => {
            result2.push(obj);
            if (obj.reference_id === this.state.updatedReferenceId) {
              result2[index].visible = !obj.visible;
            }
          });
          this.setState({ properties: result2 });
          break;

        case 'حذف العقار المحدد':
          this.growl.show({
            severity: 'success',
            summary: 'تم حذف العقار المحدد',
          });
          this.state.properties.forEach((obj, index) => {
            result2.push(obj);
            if (obj.reference_id === this.state.updatedReferenceId) {
              result2[index].business_status = 'مؤرشف';
            }
          });
          // this.state.properties.forEach((obj, index) => {
          //   if (obj.reference_id !== this.state.updatedReferenceId) {
          //     result2.push(obj);
          //   }
          // });
          this.setState({ properties: result2 });
          break;
        case 'استرجاع العقار المحدد':
          this.growl.show({
            severity: 'success',
            summary: 'تم استرجاع العقار المحدد',
          });
          this.state.properties.forEach((obj, index) => {
            result2.push(obj);
            if (obj.reference_id === this.state.updatedReferenceId) {
              result2[index].business_status = 'جديد';
            }
          });
          // this.state.properties.forEach((obj, index) => {
          //   if (obj.reference_id !== this.state.updatedReferenceId) {
          //     result2.push(obj);
          //   }
          // });
          this.setState({ properties: result2 });
          break;
        default:
          break;
      }
    } else if (
      nextProps.properties !== this.state.properties &&
      nextProps.properties[0] !== undefined &&
      nextProps.properties.length > 0
    ) {
      const { properties } = nextProps;
      this.setState({
        properties,
        total_properties: properties[0].total_properties,
      });
      if (nextProps.pageNumber === 1) {
        this.setState({
          pageNumber: nextProps.pageNumber,
        });
      }
    } else {
      this.setState({
        properties: [],
        total_properties: 0,
      });
    }
  }
  componentDidMount() {
    const isTakingAgencyRole = this.props.isTakingAgencyRole;
    const takenAgencyRoleEmail = this.props.takenAgencyRoleEmail;
    var agent_ref_id = '';
    if (isTakingAgencyRole) {
      agent_ref_id = takenAgencyRoleEmail;
    } else {
      agent_ref_id = localStorage.getItem('ikar_email');
    }
    this.setState({ takenAgencyRoleEmail: agent_ref_id });
  }
  onEditProperty = async (reference_id, visibleStatus, e) => {
    if (!window.confirm('تعديل العقار المحدد ؟')) {
      e.preventDefault();
      return;
    }
  };
  onDeleteProperty = async (reference_id, deleteStatus, e) => {
    e.preventDefault();
    var processType = '';
    var property = {};
    // await this.props.deleteProperty(
    //   reference_id,
    //   isTakingAgencyRole,
    //   takenAgencyRoleToken,
    //   processType
    // );
    switch (deleteStatus) {
      case 'set_archived':
        if (!window.confirm('حذف العقار المحدد ؟')) {
          return;
        }
        property = {
          reference_id,
          business_status: 'مؤرشف',
        };
        processType = 'حذف العقار المحدد';
        break;
      case 'remove_archived':
        if (!window.confirm('استرجاع العقار المحدد ؟')) {
          return;
        }
        property = {
          reference_id,
          business_status: 'جديد',
        };
        processType = 'استرجاع العقار المحدد';
        break;
      default:
        break;
    }

    await this.setState({
      requestSuccessMessage: 'ready',
      updatedReferenceId: reference_id,
    });
    const isTakingAgencyRole = this.props.isTakingAgencyRole;
    const takenAgencyRoleToken = this.props.takenAgencyRoleToken;

    const propertyDate = { property };
    await this.props.updateProperty(
      propertyDate,
      isTakingAgencyRole,
      takenAgencyRoleToken,
      processType
    );
  };
  onChangeVisibleStatus = async (reference_id, visibleStatus, e) => {
    e.preventDefault();

    var property = {};
    var processType = '';
    switch (visibleStatus) {
      case 'set_visible':
        if (!window.confirm('اظهار العقار المحدد ؟')) {
          return;
        }
        property = {
          reference_id,
          visible: true,
        };
        processType = 'اظهار العقار المحدد';
        break;
      case 'remove_visible':
        if (!window.confirm('اخفاء العقار المحدد ؟')) {
          return;
        }
        property = {
          reference_id,
          visible: false,
        };
        processType = 'اخفاء العقار المحدد';
        break;
      default:
        break;
    }

    await this.setState({
      requestSuccessMessage: 'ready',
      updatedReferenceId: reference_id,
    });
    const propertyDate = { property };
    const isTakingAgencyRole = false;
    // const takenAgencyRoleAgencyRefId = this.props.takenAgencyRoleAgencyRefId;
    const takenAgencyRoleToken = '';
    await this.props.updateProperty(
      propertyDate,
      isTakingAgencyRole,
      takenAgencyRoleToken,
      processType
    );
  };
  showPriceTemplate = (rowData, column) => {
    if (!rowData.price_upon_review) {
      return (
        <React.Fragment>
          <span>
            {rowData.price}
            {` `}
          </span>
          <span className="text-warning">{rowData.pricing_type}</span>
        </React.Fragment>
      );
    } else {
      return <span>{`عند المراجعة`}</span>;
    }
  };
  provinceAreaTemplate = (rowData, column) => {
    return (
      <React.Fragment>
        <span>{rowData.province}</span>
        {(rowData.area!==null && rowData.area!==undefined && rowData.area!=='غير محدد')?` - `:''}
        {(rowData.area!==null && rowData.area!==undefined && rowData.area!=='غير محدد')?<span className="text-warning">{rowData.area}</span>:''}
      </React.Fragment>
    );
  };
  showPhotoTemplate = (rowData, column) => {
    var bgURL = '';
    // var thumbnails = '';

    if (
      rowData.thumbnails === null ||
      rowData.thumbnails === undefined ||
      rowData.thumbnails.length === 0
    ) {
      bgURL = defaultBackground;
      // thumbnails = defaultBackground;
    } else {
      bgURL = `${imageDomain}/${rowData.thumbnails[0]}`;
      // thumbnails = `${imageDomain}/${rowData.thumbnails[0]}`;
    }
    // const { showMainPhoto } = this.state;
    return (
      <React.Fragment>
        <img
          ////////src={thumbnails}
          src={bgURL}
          alt=""
          style={{ width: '100px', height: '50px' }}
          onClick={() =>
            this.setState({
              showMainPhoto: true,
              bgURL,
            })
          }
        />
      </React.Fragment>
    );
  };
  showPropertyTitleTemplate = (rowData, column) => {
    return (
      <Link
        to={`/properties/${rowData.reference_id}`}
        className="mx-auto text-warning"
      >
        {rowData.title}
      </Link>
    );
  };
  showDetailsTemplate = (rowData, column) => {
    return (
      <React.Fragment>
        {/* <div className="row mx-1" >
          <div className="col-6 text-center px-0"> */}
        {rowData.visible ? (
          <Button
            //icon="pi pi-check"
            icon="fa fa-eye"
            //iconPos="right"
            className="p-button-success btn_process"
            tooltip="العقار ظاهر"
            tooltipOptions={{
              position: 'bottom',
              event: 'hover',
            }}
            onClick={this.onChangeVisibleStatus.bind(
              this,
              rowData.reference_id,
              'remove_visible'
            )}
          />
        ) : (
          <Button
            icon="fa fa-eye"
            //iconPos="right"
            className="p-button-danger btn_process"
            tooltip="العقار مخفي"
            tooltipOptions={{
              position: 'bottom',
              event: 'hover',
            }}
            onClick={this.onChangeVisibleStatus.bind(
              this,
              rowData.reference_id,
              'set_visible'
            )}
          />
        )}
        <Link
          to={
            rowData.business_status === 'مؤرشف'
              ? `#!`
              : `/properties/edit/${rowData.reference_id}`
          }
        >
          <Button
            icon="fa fa-edit"
            //iconPos="right"
            className="p-button-warning btn_process"
            tooltip="تعديل العقار"
            tooltipOptions={{
              position: 'bottom',
              event: 'hover',
            }}
            onClick={this.onEditProperty.bind(
              this,
              rowData.reference_id,
              'edit'
            )}
          />
        </Link>

        {rowData.business_status !== 'مؤرشف' ? (
          <Button
            icon="fa fa-times"
            //iconPos="right"
            className="p-button-warning btn_process"
            tooltip="حذف العقار"
            tooltipOptions={{
              position: 'bottom',
              event: 'hover',
            }}
            onClick={this.onDeleteProperty.bind(
              this,
              rowData.reference_id,
              'set_archived'
            )}
          />
        ) : (
          <Button
            icon="fa fa-times"
            //iconPos="right"
            className="p-button-danger btn_process"
            tooltip="العقار مؤرشف"
            tooltipOptions={{
              position: 'bottom',
              event: 'hover',
            }}
            onClick={this.onDeleteProperty.bind(
              this,
              rowData.reference_id,
              'remove_archived'
            )}
          />
        )}
        <Link to={`/properties/${rowData.reference_id}`}>
          <Button
            icon="fa fa-building"
            //iconPos="right"
            className="p-button-secondary btn_process"
            tooltip="تفاصيل العقار"
            tooltipOptions={{
              position: 'bottom',
              event: 'hover',
            }}
            style={{ border: '1px solid #9F9F9F' }}
          />
        </Link>
      </React.Fragment>
    );
  };
  export = () => {
    this.dt.exportCSV();
  };
  render() {
    const { isLoggedIn, ikarAdmin, isTakingAgencyRole } = this.props;
    const {
      properties,
      showCards,
      showMainPhoto,
      bgURL,
      total_properties,
      pageNumber,
    } = this.state;
    var propertyHeader = (
      <div className="px-2 py-0" style={{ textAlign: 'right' }}>
        <Button
          type="button"
          icon="pi pi-external-link"
          iconPos="left"
          label="تصدير الجدول الى ملف اكسل CSV"
          onClick={this.export}
        ></Button>
      </div>
    );
    return (
      <React.Fragment>
        <SearchBar
          searchBarFullShow={false}
          searchType="user_properties_search"
          searchId={this.state.takenAgencyRoleEmail}
          pageNumber={pageNumber}
        ></SearchBar>
        <div className="text-right mb-3">
          {/* {ikarAdmin ? (
            <Link to="/properties/add" className="btn btn-primary">
              اضافة عقار
            </Link>
          ) : null} */}
        </div>
        <div className="mb-3 text-center">
          <span className="main_title">عقارات المستخدم</span>
        </div>
        <div className="property-container">
          <div className="container-fluid">
            <Growl ref={(el) => (this.growl = el)} />
            <div className="text-right m-2">
              <button
                className="btn btn-danger mt-1 btn-sm"
                onClick={(e) => {
                  this.setState({
                    showCards: !this.state.showCards,
                  });
                }}
              >
                {showCards ? (
                  <span>
                    <FontAwesomeIcon icon={['fas', 'toggle-on']} /> طريقة العرض
                    بطاقات{' '}
                  </span>
                ) : (
                  <span>
                    <FontAwesomeIcon icon={['fas', 'toggle-off']} /> طريقة العرض
                    جدول{' '}
                  </span>
                )}
              </button>
            </div>
            {showCards ? (
              <div className="row justify-content-around align-items-center">
                {properties !== [] &&
                  properties !== undefined &&
                  (properties || []).map((property) => (
                    <Property
                      // key={property.reference_id}
                      key={shortid.generate()}
                      property={property}
                      isLoggedIn={isLoggedIn}
                      ikarAdmin={ikarAdmin}
                      requestSuccessMessage={this.state.requestSuccessMessage}
                      isTakingAgencyRole={isTakingAgencyRole}
                      onHandleDeleteProperty={this.onDeleteProperty}
                    />
                  ))}
              </div>
            ) : (
              <div
                className="table_container  mt-1"
                //style={{ direction: 'rtl' }}
              >
                {/* <div className="table-responsive"> */}
                <DataTable
                  ref={(el) => {
                    this.dt = el;
                  }}
                  value={properties}
                  responsive={true}
                  //header="المكاتب العقارية"
                  selectionMode="single"
                  selection={this.state.selectedProperty}
                  onSelectionChange={(e) => {
                    this.setState({ selectedProperty: e.value });
                  }}
                  header={propertyHeader}
                >
                  <Column
                    field="name"
                    header="الصورة"
                    className="table_td"
                    body={this.showPhotoTemplate}
                    style={{ width: '105px' }}
                  />
                  <Column
                    header="الاسم"
                    className="table_td"
                    body={this.showPropertyTitleTemplate}
                  />
                  <Column
                    field="business_offer"
                    header="نوع العرض"
                    className="table_td"
                    sortable={true}
                  />

                  <Column
                    header="العنوان"
                    className="table_td"
                    body={this.provinceAreaTemplate}
                  />
                  <Column
                    className="table_td"
                    header="السعر"
                    body={this.showPriceTemplate}
                  />
                  <Column
                    field="agency_name"
                    header="المكتب"
                    className="table_td"
                    sortable={true}
                  />

                  <Column
                    field="views"
                    header="عدد الزيارات"
                    className="table_td"
                    sortable={true}
                  />
                  <Column
                    body={this.showDetailsTemplate}
                    //header="الاجراءات"
                    style={{ width: '220px' }}
                    className="table_td table-functions"
                  />
                </DataTable>
                {showMainPhoto && (
                  <Lightbox
                    mainSrc={bgURL}
                    nextSrc={bgURL}
                    prevSrc={bgURL}
                    onCloseRequest={() =>
                      this.setState({ showMainPhoto: false })
                    }
                    onMovePrevRequest={
                      () => {
                        return;
                      }
                      // this.setState({
                      //   showMainPhoto: (showMainPhoto + url.length - 1) % url.length
                      // })
                    }
                    onMoveNextRequest={
                      () => {
                        return;
                      }
                      // this.setState({
                      //   photoIndex: (showMainPhoto + 1) % url.length
                      // })
                    }
                  />
                )}
              </div>
            )}
            <Pagination
              resultLabel="عقار"
              recordsNumber={total_properties}
              onPageChange={this.onSearchSubmit}
              pageNumber={pageNumber}
              showMessage={true}
            ></Pagination>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ShowPropertiesByUser.propTypes = {
  properties: PropTypes.array.isRequired,
  getPropertiesByUser: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  isTakingAgencyRole: PropTypes.bool.isRequired,
  takenAgencyRoleToken: PropTypes.string.isRequired,
  takenAgencyRoleEmail: PropTypes.string.isRequired,
  ikarAdmin: PropTypes.bool.isRequired,
  updateProperty: PropTypes.func.isRequired,
  requestSuccessMessage: PropTypes.string.isRequired,
  pageNumber: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  properties: state.search.properties,
  isLoggedIn: state.auth.isLoggedIn,
  isTakingAgencyRole: state.auth.isTakingAgencyRole,
  takenAgencyRoleToken: state.auth.takenAgencyRoleToken,
  takenAgencyRoleEmail: state.auth.takenAgencyRoleEmail,
  ikarAdmin: state.auth.ikarAdmin,
  requestSuccessMessage: state.propertyUD.requestSuccessMessage,
  short_id: state.propertyUD.short_id,
  short_id2: state.propertyUD.short_id2,
  pageNumber: state.search.pageNumber,
});

export default connect(mapStateToProps, {
  getPropertiesByUser,
  loginProcess,
  updateProperty,
  deleteProperty,
})(ShowPropertiesByUser);
