import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { doUserSearch } from './../../actions/searchActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { MultiSelect } from 'primereact/multiselect';
import { recordsPerPage } from './../../mainConf/index';

class SearchUsersBar extends Component {
  state = {
    users: [],
    searchTypeAllUsers: false,
    searchTypeAgencyUsers: false,
    searchId: 'main',
    requestSuccessMessage: {},

    selectedFilters: [],
    name: '',
    email: '',
    password: '',
    password_configuration: '',
    isAgencyAdmin: true,
    isIkarAdmin: false,
    surname: '',
    title: '',
    description: '',
    roles: [],
    mobile: '',
    facebook: '',
    whatsapp: '',
    agency_ref_id: '',
    agency_rank: 1,
    errors: {},
    pageNumber: 0,
    searchBarFullShow: false,
  };
  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.pageNumber !== this.state.pageNumber) {
      this.onSearchSubmit(recordsPerPage, nextProps.pageNumber, false, null);
    }
  }
  async componentDidMount() {
    const { searchType, searchId } = this.props;
    switch (searchType) {
      case 'all_users_search':
        await this.setState({
          searchTypeAllUsers: true,
          searchTypeAgencyUsers: false,
          searchId,
        });
        break;
      case 'agency_users_search':
        await this.setState({
          searchTypeAllUsers: false,
          searchTypeAgencyUsers: true,
          searchId,
        });
        break;
      default:
        break;
    }
    this.onSearchSubmit(recordsPerPage, 1, false, null);
  }
  onDefaultSearchClick = async (e) => {
    e.preventDefault();
    await this.setState({
      users: [],
      requestSuccessMessage: {},
      selectedFilters: [],
      name: '',
      email: '',
      password: '',
      password_configuration: '',
      isAgencyAdmin: true,
      isIkarAdmin: false,
      surname: '',
      title: '',
      description: '',
      roles: [],
      mobile: '',
      facebook: '',
      whatsapp: '',
      agency_ref_id: '',
      agency_rank: 1,
      errors: {},
      pageNumber: 0,
    });
    const user = {};
    //user.name = 'sadasasdadasdad';
    if (this.state.searchTypeAllUsers) {
    } else if (this.state.searchTypeAgencyUsers) {
      //user.agency_ref_id = this.state.searchId;
    }
    this.props.doUserSearch(user, recordsPerPage, 1);
    this.setState({ errors: {} });
  };
  handleSizeClick = (e) => {
    e.preventDefault();
    this.setState({ searchBarFullShow: !this.state.searchBarFullShow });
  };
  onSearchSubmit = (recordsPerPage, pageNumber, newSearch, e) => {
    if (e !== null) {
      e.preventDefault();
    }
    const {
      // searchTypeAllUsers,
      // searchTypeAgencyUsers,
      // searchId,
      selectedFilters,
      name,
      email,
      // password,
      // password_configuration,
      // isAgencyAdmin,
      // isIkarAdmin,
      // surname,
      title,
      // description,
      // roles,
      mobile,
      // facebook,
      whatsapp,
      // agency_ref_id,
      // agency_rank,
      // errors
    } = this.state;

    const user = {};

    if (email.trim() !== '') {
      user.email = email;
    }
    if (name.trim() !== '') {
      user.name = name;
    }
    if (mobile.trim() !== '') {
      user.mobile = mobile;
    }
    if (whatsapp.trim() !== '') {
      user.whatsapp = whatsapp;
    }
    if (title.trim() !== '') {
      user.title = title;
    }
    if (this.state.searchTypeAllUsers) {
    } else if (this.state.searchTypeAgencyUsers) {
      //user.agency_ref_id = this.state.searchId;
    }

    selectedFilters.forEach((item) => {
      switch (item) {
        case 'محجوب':
          user.blocked = true;
          break;

        // case 'غير محجوب':
        //   user.blocked = false;
        //   break;
        default:
          break;
      }
    });
    if (newSearch) {
      this.props.newSearch();
    }
    this.props.doUserSearch(user, recordsPerPage, pageNumber);
    this.setState({ errors: {} });
  };

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  onCheckBoxChange = (e) =>
    this.setState({ [e.target.name]: e.target.checked });

  FilterTemplate = (option) => {
    switch (option.label) {
      case 'محجوب':
        return (
          <div className="p-clearfix">
            <span
              style={{ fontSize: '1em', float: 'right', marginTop: '4px' }}
            >{` ${option.label} `}</span>
            <FontAwesomeIcon icon={['fas', 'user']} size="1x" color="red" />
            {`  `}
          </div>
        );

      // case 'غير محجوب':
      //   return (
      //     <div className="p-clearfix">
      //       <span
      //         style={{ fontSize: '1em', float: 'right', marginTop: '4px' }}
      //       >{` ${option.label} `}</span>
      //       <FontAwesomeIcon icon={['fas', 'user']} size="1x" />
      //       {`  `}
      //     </div>
      //   );

      default:
        return <span className="p-clearfix">فلاتر اضافية للبحث</span>;
    }
  };
  selectedFilterTemplate = (value) => {
    switch (value) {
      case 'محجوب':
        return (
          <div className="filter_multiselected-items">
            <span>{` ${value} `}</span>
            <FontAwesomeIcon icon={['fas', 'user']} size="1x" />
            {`  `}
          </div>
        );

      // case 'غير محجوب':
      //   return (
      //     <div className="filter_multiselected-items">
      //       <span>{` ${value} `}</span>
      //       <FontAwesomeIcon icon={['fas', 'user']} size="1x" color="red" />
      //       {`  `}
      //     </div>
      //   );

      default:
        return (
          <span className="my-multiselected-empty-token">
            فلاتر اضافية للبحث
          </span>
        );
    }
  };
  render() {
    const filters = [
      { label: 'محجوب', value: 'محجوب' },
      // { label: 'غير محجوب', value: 'غير محجوب' }
    ];
    const {
      // searchTypeAllUsers,
      // searchTypeAgencyUsers,
      // searchId,
      selectedFilters,
      name,
      email,
      // password,
      // password_configuration,
      // isAgencyAdmin,
      // isIkarAdmin,
      // surname,
      title,
      // description,
      // roles,
      mobile,
      // facebook,
      whatsapp,
      // agency_ref_id,
      // agency_rank,
      errors,
      searchBarFullShow,
    } = this.state;

    return (
      <React.Fragment>
        <div className="search_div mt-2">
          <form
            onSubmit={this.onSearchSubmit.bind(this, recordsPerPage, 1, true)}
          >
            <div className="row">
              <div className="col-md-10">
                <div className="input-group justify-content-start">
                  <div className="form-group text-right px-1">
                    <label htmlFor="name" className="label_narrow">
                      اسم المستخدم:
                    </label>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors.name,
                      })}
                      name="name"
                      value={name}
                      onChange={this.onChange}
                      style={{ width: '120px' }}
                    />
                  </div>
                  {searchBarFullShow ? (
                    <React.Fragment>
                      <div className="form-group text-right px-1">
                        <label htmlFor="email" className="label_narrow">
                          البريد الالكتروني:
                        </label>
                        <input
                          type="text"
                          className={classnames('form-control', {
                            'is-invalid': errors.email,
                          })}
                          name="email"
                          value={email}
                          onChange={this.onChange}
                          style={{ width: '120px' }}
                        />
                      </div>
                      <div className="form-group text-right px-1">
                        <label htmlFor="title" className="label_narrow">
                          العنوان:
                        </label>
                        <input
                          type="text"
                          className={classnames('form-control', {
                            'is-invalid': errors.title,
                          })}
                          name="title"
                          value={title}
                          onChange={this.onChange}
                          style={{ width: '120px' }}
                        />
                      </div>
                    </React.Fragment>
                  ) : null}
                  <div className="form-group text-right  px-1">
                    <label htmlFor="mobile" className="label_narrow">
                      رقم الموبايل:
                    </label>
                    <input
                      type="number"
                      className={classnames('form-control', {
                        'is-invalid': errors.mobile,
                      })}
                      name="mobile"
                      value={mobile}
                      onChange={this.onChange}
                      style={{ width: '120px' }}
                    />
                  </div>
                  {searchBarFullShow ? (
                    <React.Fragment>
                      <div className="form-group text-right px-1">
                        <label htmlFor="whatsapp" className="label_narrow">
                          رقم ال واتس اب:
                        </label>
                        <input
                          type="number"
                          className={classnames('form-control', {
                            'is-invalid': errors.whatsapp,
                          })}
                          name="whatsapp"
                          value={whatsapp}
                          onChange={this.onChange}
                          style={{ width: '120px' }}
                        />
                      </div>
                      <div className="form-group text-right px-1">
                        <label htmlFor="filters" className="label_narrow">
                          فلاتر اضافية:
                        </label>
                        <div>
                          <MultiSelect
                            //style={{ direction: 'ltr' }}
                            value={selectedFilters}
                            options={filters}
                            onChange={(e) => {
                              this.setState({ selectedFilters: e.value });
                            }}
                            style={{
                              minWidth: '12em',
                              height: 'calc(1.5em + .75rem + 2px)',
                            }}
                            itemTemplate={this.FilterTemplate}
                            selectedItemTemplate={this.selectedFilterTemplate}
                          />
                        </div>
                      </div>
                    </React.Fragment>
                  ) : null}
                </div>

                <div className="input-group justify-content-start"></div>
              </div>
              <div className="col-md-2">
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn mx-1 mt-1 submit-button"
                    style={{ width: '80px' }}
                  >
                    ابحث{` `}
                    <FontAwesomeIcon icon={['fas', 'search']} />
                  </button>
                  <button
                    className="btn mt-1 mx-1 submit-button"
                    onClick={this.handleSizeClick}
                    style={{ width: '80px' }}
                  >
                    فلاتر{` `}
                    {searchBarFullShow ? (
                      <FontAwesomeIcon icon={['fas', 'toggle-on']} size="xs" />
                    ) : (
                      <FontAwesomeIcon icon={['fas', 'toggle-off']} size="xs" />
                    )}
                  </button>
                  {/* <button
                    className="btn mt-1 submit-button"
                    onClick={this.onDefaultSearchClick}
                    // style={{ width: '120px' }}
                  >
                    بحث جديد{` `}
                    <FontAwesomeIcon icon={['fas', 'undo']} />
                  </button> */}
                </div>
              </div>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

SearchUsersBar.propTypes = {
  doUserSearch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { doUserSearch })(SearchUsersBar);
