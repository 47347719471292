import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getAgency } from './../../actions/agencyActions';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog } from 'primereact/dialog';
import { syriaAreas } from './../../mainConf';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import { Link } from 'react-router-dom';
import { imageDomain } from './../../mainConf';
import defaultBackground from './../../assets/images/defaultBackground.png';
import Lightbox from 'react-image-lightbox';
import { noInternetMessage } from './../../mainConf';
import { mapsEnabled } from './../../mainConf/index';
import { Helmet } from 'react-helmet';
import { linkDomain } from './../../mainConf';
import NotFound from '../../pages/NotFound';
class AdminAgencyProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageNotFound: false,
      pageTitle: '',
      pageDescription: '',
      showMessages: true,
      selectedUsers: [],
      noMessages: true,
      areas: [],
      isOpen: false,
      //user: {},
      name: '',
      description: '',
      contact_email: '',
      password: '',
      password_confirmation: '',
      mobile: '',
      phone: '',
      reference_id: '',
      whatsapp: '',
      telegram: '',
      roles: [],
      gps_location: '',
      lat: 34.890596,
      lng: 35.881085,
      zoom: 17,
      showCurrentLocation: false,
      processScreen: false,
      IsUsingGPS: false,
      propertyPosition: null,
      business_email: '',
      charging_plan_id: 1,
      city: '',
      province: 'غير محدد',
      area: 'غير محدد',
      instagram: '',
      is_blocked: false,
      is_charged: true,
      other_phones: '',
      street: '',
      twitter: '',
      facebook: '',
      legal_address: '',
      business_address: '',
      admins: [],
      agents: [],
      errors: {},
      processStatus: '',
      photo: '',
      thumbnail: '',
    };
  }

  componentWillReceiveProps(nextProps, nextState) {
    if (
      nextProps.requestFailedMessage.errorMessage !== undefined &&
      nextProps.requestFailedMessage.errorMessage !== null &&
      // !this.state.noMessages &&
      nextProps.requestFailedMessage.errorMessage.data !== undefined &&
      nextProps.requestFailedMessage.errorMessage.data.errors !== undefined &&
      nextProps.requestFailedMessage.errorMessage.data.errors[0] !==
        undefined &&
      nextProps.requestFailedMessage.errorMessage.data.errors[0].detail !==
        undefined &&
      nextProps.requestFailedMessage.errorMessage.data.errors[0].detail ===
        `Agency with Reference_id: ${this.props.match.params.agency_reference_id} does not exist`
    ) {
      this.setState({ pageNotFound: true });
    } else if (
      nextProps.agency.processStatus !== this.state.processStatus &&
      nextProps.agency !== []
    ) {
      var {
        reference_id,
        name,
        description,
        contact_email,
        mobile,
        phone,
        whatsapp,
        telegram,
        roles,
        gps_location,
        business_email,
        charging_plan_id,
        city,
        area,
        facebook,
        instagram,
        is_blocked,
        is_charged,
        other_phones,
        province,
        street,
        twitter,
        legal_address,
        business_address,
        // password,
        agents,
        processStatus,
        photo,
        thumbnail,
      } = nextProps.agency;
      this.setState({
        province,
      });
      this.onProvinceChange2(province);
      this.setState({
        pageNotFound: false,
        reference_id,
        name,
        description,
        contact_email,
        mobile,
        phone,
        whatsapp,
        telegram,
        roles,
        gps_location,
        business_email,
        charging_plan_id,
        city,
        area: area,
        facebook,
        instagram,
        is_blocked,
        is_charged,
        other_phones,
        //province,
        street,
        twitter,
        legal_address,
        business_address,
        //password,
        agents,
        processStatus,
        photo,
        thumbnail,
      });
      if (
        mapsEnabled &&
        gps_location !== null &&
        gps_location !== undefined &&
        gps_location !== ''
      ) {
        const lat = parseFloat(gps_location.split(',')[0]);
        const lng = parseFloat(gps_location.split(',')[1]);
        this.setState({
          lat,
          lng,
        });
      }
    }
  }
  componentDidMount() {
    // var agencyReferenceId = '';
    // if (this.props.location.query !== undefined) {
    //   if (this.props.location.query.agency_reference_id !== undefined) {
    //     agencyReferenceId = this.props.location.query.agency_reference_id;
    //   }
    // }
    const { agency_reference_id } = this.props.match.params;
    const ikarAdmin = this.props.ikarAdmin;
    const isTakingAgencyRole = this.props.isTakingAgencyRole;
    // const takenAgencyRoleAgencyRefId = this.props.takenAgencyRoleAgencyRefId;
    const takenAgencyRoleToken = this.props.takenAgencyRoleToken;
    this.props.getAgency(
      ikarAdmin,
      isTakingAgencyRole,
      agency_reference_id,
      takenAgencyRoleToken
    );
  }

  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  onCheckBoxChange = (e) =>
    this.setState({ [e.target.name]: e.target.checked });
  onProvinceChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.value === 'دمشق') {
      //دمشق
      this.setState({ areas: syriaAreas.damascusAreas });
    } else if (e.target.value === 'ريف دمشق') {
      //ريف دمشق
      this.setState({
        areas: syriaAreas.rifDamascusAreas,
      });
    } else if (e.target.value === 'حلب') {
      //حلب
      this.setState({
        areas: syriaAreas.aleppoAreas,
      });
    } else if (e.target.value === 'طرطوس') {
      //طرطوس
      this.setState({
        areas: syriaAreas.tartousAreas,
      });
    } else if (e.target.value === 'اللاذقية') {
      //اللاذقية
      this.setState({ areas: syriaAreas.latakiaAreas });
    } else if (e.target.value === 'حمص') {
      //حمص
      this.setState({
        areas: syriaAreas.homsAreas,
      });
    } else if (e.target.value === 'حماة') {
      //حماة
      this.setState({ areas: syriaAreas.hamaAreas });
    } else if (e.target.value === 'السويداء') {
      //السويداء
      this.setState({ areas: syriaAreas.asuwaydaAreas });
    } else if (e.target.value === 'الحسكة') {
      //الحسكة
      this.setState({
        areas: syriaAreas.hasakaAreas,
      });
    } else if (e.target.value === 'درعا') {
      //درعا
      this.setState({ areas: syriaAreas.daraaAreas });
    } else if (e.target.value === 'ادلب') {
      //ادلب
      this.setState({
        areas: syriaAreas.idlibAreas,
      });
    } else if (e.target.value === 'دير الزور') {
      //دير الزور
      this.setState({ areas: syriaAreas.deirezorAreas });
    } else if (e.target.value === 'القنيطرة') {
      //القنيطرة
      this.setState({ areas: syriaAreas.quneitraAreas });
    } else if (e.target.value === 'الرقة') {
      //الرقة
      this.setState({ areas: syriaAreas.raqqaAreas });
    } else {
      this.setState({ areas: [] });
    }
  };

  onProvinceChange2 = (province) => {
    if (province === 'دمشق') {
      //دمشق
      this.setState({ areas: syriaAreas.damascusAreas });
    } else if (province === 'ريف دمشق') {
      //ريف دمشق
      this.setState({
        areas: syriaAreas.rifDamascusAreas,
      });
    } else if (province === 'حلب') {
      //حلب
      this.setState({
        areas: syriaAreas.aleppoAreas,
      });
    } else if (province === 'طرطوس') {
      //طرطوس
      this.setState({
        areas: syriaAreas.tartousAreas,
      });
    } else if (province === 'اللاذقية') {
      //اللاذقية
      this.setState({ areas: syriaAreas.latakiaAreas });
    } else if (province === 'حمص') {
      //حمص
      this.setState({
        areas: syriaAreas.homsAreas,
      });
    } else if (province === 'حماة') {
      //حماة
      this.setState({ areas: syriaAreas.hamaAreas });
    } else if (province === 'السويداء') {
      //السويداء
      this.setState({ areas: syriaAreas.asuwaydaAreas });
    } else if (province === 'الحسكة') {
      //الحسكة
      this.setState({
        areas: syriaAreas.hasakaAreas,
      });
    } else if (province === 'درعا') {
      //درعا
      this.setState({ areas: syriaAreas.daraaAreas });
    } else if (province === 'ادلب') {
      //ادلب
      this.setState({
        areas: syriaAreas.idlibAreas,
      });
    } else if (province === 'دير الزور') {
      //دير الزور
      this.setState({ areas: syriaAreas.deirezorAreas });
    } else if (province === 'القنيطرة') {
      //القنيطرة
      this.setState({ areas: syriaAreas.quneitraAreas });
    } else if (province === 'الرقة') {
      //الرقة
      this.setState({ areas: syriaAreas.raqqaAreas });
    } else {
      this.setState({ areas: [] });
    }
  };
  onMapShowClick = (e) => {
    e.preventDefault();
    this.setState({ showCurrentLocation: true });
  };
  render() {
    const position = [this.state.lat, this.state.lng];
    const {
      //selectedUsers,
      //noMessages,
      areas,
      //user,
      name,
      description,
      contact_email,
      //password,
      //password_confirmation,
      mobile,
      phone,
      //reference_id,
      whatsapp,
      telegram,
      //roles,
      gps_location,
      showCurrentLocation,
      //processScreen,
      //IsUsingGPS,
      //propertyPosition,
      business_email,
      //charging_plan_id,
      //city,
      area,
      instagram,
      //is_blocked,
      //is_charged,
      other_phones,
      province,
      street,
      twitter,
      facebook,
      legal_address,
      business_address,
      //admins,
      //agents,
      errors,
      //processStatus,
      isOpen,
      photo,
      thumbnail,
    } = this.state;
    // const { users } = this.props;
    // const initGbsLocation = {
    //   center: { lat: 34.881866, lng: 35.878255 },
    //   //center: { lat: 36.890257, lng: 30.707417 },
    //   zoom: 12
    // };
    return (
      <React.Fragment>
        {this.state.pageNotFound ? (
          <NotFound />
        ) : (
          <div className="simple-div">
            <Helmet>
              <title>{this.state.pageTitle}</title>
              <meta name="description" content={this.state.pageDescription} />
            </Helmet>
            <div className="row">
              <div className="col-md-10 mx-auto">
                <Dialog
                  header="موقع المكتب المحدد على الخريطة"
                  //footer={mapFooter}
                  // iconsTemplate={mapIcon}
                  visible={showCurrentLocation}
                  style={{ width: '90vw', minHeight: '70vh' }}
                  modal={true}
                  onHide={() => this.setState({ showCurrentLocation: false })}
                >
                  <div>
                    {showCurrentLocation ? (
                      mapsEnabled ? (
                        <Map
                          center={position}
                          zoom={this.state.zoom}
                          style={{ width: '85vw', minHeight: '65vh' }}
                        >
                          <TileLayer
                            attribution='<a href="http://ikar.sy">Ikar.sy</a> &amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                          />
                          <Marker position={position}>
                            <Popup>
                              <span className="display_8">{name}</span>
                            </Popup>
                          </Marker>
                        </Map>
                      ) : (
                        noInternetMessage
                      )
                    ) : null}
                  </div>
                </Dialog>

                {thumbnail !== null &&
                thumbnail !== undefined &&
                thumbnail.length !== 0 ? (
                  <div className="row" style={{ direction: 'rtl' }}>
                    <div className="col-md-6" style={{ margin: 'auto' }}>
                      <div className="text-center text-md-left pb-2 display_5">
                        <h1 className="text-primary">
                          <FontAwesomeIcon
                            icon={['fas', 'book-reader']}
                            size="1x"
                            className="text-primary mx-2"
                          />
                          معلومات المكتب
                        </h1>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mt-1 mb-1 text-center text-md-right">
                        <img
                          className="fileupload_img"
                          src={`${imageDomain}/${thumbnail}`}
                          alt={`${name} profile`}
                          onClick={() =>
                            this.setState({
                              isOpen: true,
                            })
                          }
                        />
                      </div>
                      {isOpen && (
                        <Lightbox
                          mainSrc={`${imageDomain}/${photo}`}
                          nextSrc={`${imageDomain}/${photo}`}
                          prevSrc={`${imageDomain}/${photo}`}
                          onCloseRequest={() =>
                            this.setState({ isOpen: false })
                          }
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="row" style={{ direction: 'rtl' }}>
                    <div className="col-md-6" style={{ margin: 'auto' }}>
                      <div className="text-center text-md-left pb-2  display_5">
                        <span className="text-primary">
                          <FontAwesomeIcon
                            icon={['fas', 'book-reader']}
                            size="1x"
                            className="text-primary mx-2"
                          />
                          معلومات المكتب
                        </span>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mt-1 mb-1 text-center text-md-right">
                        <img
                          className="fileupload_img"
                          src={defaultBackground}
                          alt={`${name} profile`}
                          onClick={() =>
                            this.setState({
                              isOpen: true,
                            })
                          }
                        />
                      </div>
                      {isOpen && (
                        <Lightbox
                          mainSrc={`${defaultBackground}`}
                          nextSrc={`${defaultBackground}`}
                          prevSrc={`${defaultBackground}`}
                          onCloseRequest={() =>
                            this.setState({ isOpen: false })
                          }
                        />
                      )}
                    </div>
                  </div>
                )}

                <div className="card text-center">
                  <div className="card-header text-primary lead text-uppercase">
                    {contact_email}
                  </div>
                  <div className="card-body" style={{ direction: 'rtl' }}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group text-right mb-4">
                          <label htmlFor="contact_email">
                            البريد الالكتروني:
                          </label>
                          <input
                            type="email"
                            className={classnames('form-control', {
                              'is-invalid': errors.contact_email,
                            })}
                            name="contact_email"
                            value={contact_email}
                            onChange={this.onChange}
                          />
                          {errors.contact_email && (
                            <div className="invalid-feedback">
                              {errors.contact_email}
                            </div>
                          )}
                        </div>
                        {/* <div className="form-group text-right mb-4">
                    <label htmlFor="password">كلمة السر:</label>
                    <input
                      type="password"
                      className={classnames('form-control', {
                        'is-invalid': errors.password
                      })}
                      name="password"
                      value={password}
                      onChange={this.onChange}
                    />
                    {errors.password && (
                      <div className="invalid-feedback">{errors.password}</div>
                    )}
                  </div>
                  <div className="form-group text-right mb-4">
                    <label htmlFor="password_confirmation">
                      اعادة كلمة السر:
                    </label>
                    <input
                      type="password"
                      className={classnames('form-control', {
                        'is-invalid': errors.password_confirmation
                      })}
                      name="password_confirmation"
                      value={password_confirmation}
                      onChange={this.onChange}
                    />
                    {errors.password_confirmation && (
                      <div className="invalid-feedback">
                        {errors.password_confirmation}
                      </div>
                    )}
                  </div> */}
                        <div className="form-group text-right mb-4">
                          <label htmlFor="name">اسم المكتب:</label>
                          <input
                            type="text"
                            className={classnames('form-control', {
                              'is-invalid': errors.name,
                            })}
                            name="name"
                            value={name}
                            onChange={this.onChange}
                          />
                          {errors.name && (
                            <div className="invalid-feedback">
                              {errors.name}
                            </div>
                          )}
                        </div>
                        <div className="form-group text-right  mb-4">
                          <label htmlFor="province">المحافظة:</label>
                          <select
                            name="province"
                            className={classnames('form-control', {
                              'is-invalid': errors.province,
                            })}
                            //onChange={this.onProvinceChange}
                            value={province}
                            readOnly
                            disabled
                          >
                            <option value="غير محدد">غير محدد</option>
                            <option value="دمشق"> دمشق</option>
                            <option value="ريف دمشق"> ريف دمشق</option>
                            <option value="حلب"> حلب</option>
                            <option value="طرطوس"> طرطوس</option>
                            <option value="اللاذقية"> اللاذقية</option>
                            <option value="حمص"> حمص</option>
                            <option value="حماة"> حماة</option>
                            <option value="السويداء"> السويداء</option>
                            <option value="الحسكة"> الحسكة</option>
                            <option value="درعا"> درعا</option>
                            <option value="ادلب"> ادلب</option>
                            <option value="دير الزور"> دير الزور</option>
                            <option value="القنيطرة"> القنيطرة</option>
                            <option value="الرقة"> الرقة</option>
                          </select>
                          {errors.province && (
                            <div className="invalid-feedback">
                              {errors.province}
                            </div>
                          )}
                        </div>
                        <div className="form-group text-right  mb-4">
                          <label htmlFor="area">المنطقة:</label>
                          <select
                            name="area"
                            className={classnames('form-control', {
                              'is-invalid': errors.area,
                            })}
                            //onChange={this.onChange}
                            value={area}
                            readOnly
                            disabled
                          >
                            <option value="غير محدد">غير محدد</option>
                            {areas.map((area, index) => (
                              <option value={area} key={index}>
                                {area}
                              </option>
                            ))}
                          </select>
                          {errors.area && (
                            <div className="invalid-feedback">
                              {errors.area}
                            </div>
                          )}
                        </div>
                        {mapsEnabled &&
                        gps_location !== null &&
                        gps_location !== undefined &&
                        gps_location !== '' ? (
                          <div className="form-group round-div py-1">
                            <div className="col-12 text-center">
                              <button
                                className="px-2 py-1 m-1"
                                onClick={this.onMapShowClick}
                              >
                                موقع المكتب على الخريطة
                                <FontAwesomeIcon
                                  className="icon_map_marked"
                                  icon={['fas', 'map-marked']}
                                  color="blue"
                                ></FontAwesomeIcon>
                              </button>
                            </div>

                            {/* <div className="col-12 text-right">
                        <div className="row">
                          <div className="col-12 text-right my-1">
                            {!propertyPosition ? (
                              <span>خط العرض : </span>
                            ) : null}
                            {IsUsingGPS ? (
                              propertyPosition ? (
                                <React.Fragment>
                                  خط العرض :{' '}
                                  <span className="text-primary mx-2">
                                    {propertyPosition.lat()}
                                  </span>
                                  <FontAwesomeIcon
                                    icon={['fas', 'map-marked-alt']}
                                    size="1x"
                                    color="blue"
                                  />
                                </React.Fragment>
                              ) : null
                            ) : null}
                          </div>
                          <div className="col-12 text-right my-1">
                            {!propertyPosition ? (
                              <span>خط الطول : </span>
                            ) : null}
                            {IsUsingGPS ? (
                              propertyPosition ? (
                                <React.Fragment>
                                  خط الطول :{' '}
                                  <span className="text-primary mx-2">
                                    {propertyPosition.lng()}
                                  </span>
                                  <FontAwesomeIcon
                                    icon={['fas', 'map-marked-alt']}
                                    size="1x"
                                    color="blue"
                                  />
                                </React.Fragment>
                              ) : null
                            ) : null}
                          </div>
                        </div>
                      </div> */}
                          </div>
                        ) : null}
                        {/* <div className="form-group text-right mb-4">
                    <label htmlFor="city">المدينة:</label>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors.city
                      })}
                      name="city"
                      value={city}
                      onChange={this.onChange}
                    />
                    {errors.city && (
                      <div className="invalid-feedback">{errors.city}</div>
                    )}
                  </div> */}
                        {/* <div className="form-group text-right mb-4">
                    <label htmlFor="area">عنوان تفصيلي:</label>
                    <input
                      type="text"
                      className={classnames('form-control', {
                        'is-invalid': errors.area
                      })}
                      name="area"
                      value={area}
                      onChange={this.onChange}
                    />
                    {errors.area && (
                      <div className="invalid-feedback">{errors.area}</div>
                    )}
                  </div> */}
                        <div className="form-group text-right mb-4">
                          <label htmlFor="street">الشارع:</label>
                          <input
                            type="text"
                            className={classnames('form-control', {
                              'is-invalid': errors.street,
                            })}
                            name="street"
                            value={street}
                            onChange={this.onChange}
                          />
                          {errors.street && (
                            <div className="invalid-feedback">
                              {errors.street}
                            </div>
                          )}
                        </div>
                        <div className="form-group text-right mb-4">
                          <label htmlFor="legal_address">العنوان الدائم:</label>
                          <input
                            type="text"
                            className={classnames('form-control', {
                              'is-invalid': errors.legal_address,
                            })}
                            name="legal_address"
                            value={legal_address}
                            onChange={this.onChange}
                          />
                          {errors.legal_address && (
                            <div className="invalid-feedback">
                              {errors.legal_address}
                            </div>
                          )}
                        </div>
                        <div className="form-group text-right mb-4">
                          <label htmlFor="business_address">عنوان العمل:</label>
                          <input
                            type="text"
                            className={classnames('form-control', {
                              'is-invalid': errors.business_address,
                            })}
                            name="business_address"
                            value={business_address}
                            onChange={this.onChange}
                          />
                          {errors.business_address && (
                            <div className="invalid-feedback">
                              {errors.business_address}
                            </div>
                          )}
                        </div>
                        <div className="form-group text-right mb-4">
                          <label htmlFor="description">الوصف:</label>
                          <input
                            type="text"
                            className={classnames('form-control', {
                              'is-invalid': errors.description,
                            })}
                            name="description"
                            value={description}
                            onChange={this.onChange}
                          />
                          {errors.description && (
                            <div className="invalid-feedback">
                              {errors.description}
                            </div>
                          )}
                        </div>
                        <div className="form-group text-right mb-4">
                          <label htmlFor="phone">رقم الهاتف:</label>
                          <input
                            type="number"
                            className={classnames('form-control', {
                              'is-invalid': errors.phone,
                            })}
                            name="phone"
                            value={phone}
                            onChange={this.onChange}
                          />
                          {errors.phone && (
                            <div className="invalid-feedback">
                              {errors.phone}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group text-right mb-4">
                          <label htmlFor="mobile">رقم الموبايل:</label>
                          {mobile !== '' && mobile !== null ? (
                            <div class=" text-right">
                              <a href={`tel:${mobile}`}>{mobile}</a>
                            </div>
                          ) : (
                            <React.Fragment>
                              <input
                                type="number"
                                className={classnames('form-control', {
                                  'is-invalid': errors.mobile,
                                })}
                                name="mobile"
                                value={mobile}
                                onChange={this.onChange}
                              />
                              {errors.mobile && (
                                <div className="invalid-feedback">
                                  {errors.mobile}
                                </div>
                              )}
                            </React.Fragment>
                          )}
                        </div>
                        <div className="form-group text-right mb-4">
                          <label htmlFor="other_phones">ارقام اخرى:</label>
                          {other_phones !== '' && other_phones !== null ? (
                            <div class=" text-right">
                              <a href={`tel:${other_phones}`}>{other_phones}</a>
                            </div>
                          ) : (
                            <React.Fragment>
                              <input
                                type="number"
                                className={classnames('form-control', {
                                  'is-invalid': errors.other_phones,
                                })}
                                name="other_phones"
                                value={other_phones}
                                onChange={this.onChange}
                              />
                              {errors.other_phones && (
                                <div className="invalid-feedback">
                                  {errors.other_phones}
                                </div>
                              )}
                            </React.Fragment>
                          )}
                        </div>
                        <div className="form-group text-right mb-4">
                          <label htmlFor="twitter">عنوان صفحة ال تويتر:</label>
                          <input
                            type="text"
                            className={classnames('form-control', {
                              'is-invalid': errors.twitter,
                            })}
                            name="twitter"
                            value={twitter}
                            onChange={this.onChange}
                          />
                          {errors.twitter && (
                            <div className="invalid-feedback">
                              {errors.twitter}
                            </div>
                          )}
                        </div>
                        <div className="form-group text-right mb-4">
                          <label htmlFor="facebook">عنوان صفحةال فيسبوك:</label>
                          <input
                            type="text"
                            className={classnames('form-control', {
                              'is-invalid': errors.facebook,
                            })}
                            name="facebook"
                            value={facebook}
                            onChange={this.onChange}
                          />
                          {errors.facebook && (
                            <div className="invalid-feedback">
                              {errors.facebook}
                            </div>
                          )}
                        </div>
                        <div className="form-group text-right mb-4">
                          <label htmlFor="whatsapp">رقم ال واتس اب:</label>
                          {whatsapp !== '' && whatsapp !== null ? (
                            <div class=" text-right">
                              <a href={`tel:${whatsapp}`}>{whatsapp}</a>
                            </div>
                          ) : (
                            <React.Fragment>
                              <input
                                type="number"
                                className={classnames('form-control', {
                                  'is-invalid': errors.whatsapp,
                                })}
                                name="whatsapp"
                                value={whatsapp}
                                onChange={this.onChange}
                              />
                              {errors.whatsapp && (
                                <div className="invalid-feedback">
                                  {errors.whatsapp}
                                </div>
                              )}
                            </React.Fragment>
                          )}
                        </div>
                        <div className="form-group text-right mb-4">
                          <label htmlFor="telegram">رقم ال تيلغرام:</label>
                          {telegram !== '' && telegram !== null ? (
                            <div class=" text-right">
                              <a href={`tel:${telegram}`}>{telegram}</a>
                            </div>
                          ) : (
                            <React.Fragment>
                              <input
                                type="number"
                                className={classnames('form-control', {
                                  'is-invalid': errors.telegram,
                                })}
                                name="telegram"
                                value={telegram}
                                onChange={this.onChange}
                              />
                              {errors.telegram && (
                                <div className="invalid-feedback">
                                  {errors.telegram}
                                </div>
                              )}
                            </React.Fragment>
                          )}
                        </div>
                        <div className="form-group text-right mb-4">
                          <label htmlFor="instagram">
                            عنوان صفحة ال انستغرام:
                          </label>
                          <input
                            type="text"
                            className={classnames('form-control', {
                              'is-invalid': errors.instagram,
                            })}
                            name="instagram"
                            value={instagram}
                            onChange={this.onChange}
                          />
                          {errors.instagram && (
                            <div className="invalid-feedback">
                              {errors.instagram}
                            </div>
                          )}
                        </div>
                        <div className="form-group text-right mb-4">
                          <label htmlFor="business_email">ايميل العمل:</label>
                          <input
                            type="email"
                            className={classnames('form-control', {
                              'is-invalid': errors.business_email,
                            })}
                            name="business_email"
                            value={business_email}
                            onChange={this.onChange}
                          />
                          {errors.business_email && (
                            <div className="invalid-feedback">
                              {errors.business_email}
                            </div>
                          )}
                        </div>
                        {/* <div className="form-group text-right">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="is_blocked"
                          className="form-check-input"
                          checked={is_blocked}
                          onChange={this.onCheckBoxChange}
                        />
                        <label
                          className="form-check-label mx-3"
                          htmlFor="duplex"
                        >
                          المكتب محجوب
                        </label>
                      </div>
                    </div>
                    <div className="form-group text-right">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          name="is_charged"
                          className="form-check-input"
                          checked={is_charged}
                          onChange={this.onCheckBoxChange}
                        />
                        <label
                          className="form-check-label mx-3"
                          htmlFor="duplex"
                        >
                          المكتب متعامل بالدفعات
                        </label>
                      </div>
                    </div> */}
                      </div>
                    </div>
                  </div>
                  <div className="card-footer text-muted">
                    <a href={`${linkDomain}/`} className="btn btn-warning">
                      الصفحة الرئيسية
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

AdminAgencyProfile.propTypes = {
  getAgency: PropTypes.func.isRequired,
  agency: PropTypes.object.isRequired,
  agencies: PropTypes.array.isRequired,
  requestFailed: PropTypes.bool.isRequired,
  requestFailedMessage: PropTypes.object.isRequired,
  ikarAdmin: PropTypes.bool.isRequired,
  isTakingAgencyRole: PropTypes.bool.isRequired,
  takenAgencyRoleAgencyRefId: PropTypes.string.isRequired,
  takenAgencyRoleToken: PropTypes.string.isRequired,
};
const mapStateToProps = (state) => ({
  agency: state.agency.agency,
  agencies: state.agency.agencies,
  requestFailed: state.agency.requestFailed,
  requestFailedMessage: state.agency.requestFailedMessage,
  ikarAdmin: state.auth.ikarAdmin,
  isTakingAgencyRole: state.auth.isTakingAgencyRole,
  takenAgencyRoleAgencyRefId: state.auth.takenAgencyRoleAgencyRefId,
  takenAgencyRoleToken: state.auth.takenAgencyRoleToken,
});

export default connect(mapStateToProps, { getAgency })(AdminAgencyProfile);
