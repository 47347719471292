import React from 'react';
export const ResReady = () => {
  return (
    <div className="text-center mx-auto">
      <div className="span text-center">
        {/* <div className="location_indicator" /> */}
        <div
          className="spinner-border text-warning"
          style={{ width: '3rem', height: '3rem' }}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
        <p className="text-danger lead text-center">
          <span>www.</span>
          <span className="font-weight-bold">Ikar</span>
          <span className="font-weight-bold">.sy</span>
        </p>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
};

export const ResNotComplete = () => {
  return (
    <div className="text-center mx-auto">
      <div className="span text-center">
        {/* <div className="location_indicator" /> */}
        <div
          className="spinner-border text-danger"
          style={{ width: '3rem', height: '3rem' }}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
        <p className="text-danger lead text-center">
          <span>www.</span>
          <span className="font-weight-bold">Ikar</span>
          <span className="font-weight-bold">.sy</span>
        </p>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
  );
  // return (
  //   <div className="text-center mx-auto">
  //     <div className="span text-center">
  //       <p>
  //         <span className="text-danger">
  //           الرجاء التحقق من جودة اتصالك بالانترنيت
  //         </span>
  //         <br />
  //         <br />
  //         <br />
  //         <br />
  //         <br />
  //         <br />
  //         <br />
  //         <br />
  //         <br />
  //         <br />
  //         <br />
  //         <br />
  //         <br />
  //         <br />
  //         <br />
  //         <br />
  //         <br />
  //         <br />
  //       </p>
  //       {/* <p>
  //             <FontAwesomeIcon
  //               icon={['fas', 'user-astronaut']}
  //               size="2x"
  //             ></FontAwesomeIcon>
  //           </p> */}
  //     </div>
  //   </div>
  // );
};
