import React, { Component } from 'react';
import PropTypes from 'prop-types';
import waterMark from './../assets/images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Helmet } from 'react-helmet';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
//import { loginProcess } from '../actions/authActions';
import { getAbouts, deleteAbout } from '../actions/aboutAction';
import { linkDomain } from '../mainConf/index';
import { Growl } from 'primereact/growl';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { ResReady, ResNotComplete } from './../components/layout/resTypes';
class About extends Component {
  constructor(props) {
    super();
    this.state = {
      pageTitle: 'Ikar.sy | للإشتراك و إمكانية النشر على موقعنا',
      pageDescription:
        'سوق العقارات الإلكتروني في سورية Ikar.sy مخصص لجمع عروض بيع، شراء و تأجير العقارات من جميع مكاتب العقارات في سورية. الإعلان على Ikar.sy مجاني و متاح فقط للمكاتب العقارية والمتعهدين المسجلين لدى الموقع في جميع المحافظات السورية. | Ikar.sy',
      abouts: [],
      about: {},
      total_abouts: 0,
      deleteSuccessMessage: '',
      updatedReferenceId: '',
      selectedAbout: {},
      resType: '',
    };
  }

  componentDidMount() {
    if (this.props.ikarAdmin) this.props.getAbouts(true);
    else {
      this.props.getAbouts(false);
    }
  }

  // export = () => {
  //   this.dt.exportCSV();
  // };

  onEditAbout = async (e) => {
    if (!window.confirm('تعديل الوصف المحدد ؟')) {
      e.preventDefault();
      return;
    }
  };

  showDetailsTemplate = (rowData, column) => {
    return (
      <React.Fragment>
        <Button
          //icon="pi pi-check"
          // icon="fa fa-money-check"
          //iconPos="right"
          className="p-button-success btn_process"
          tooltip="حذف الوصف المحدد"
          tooltipOptions={{
            position: 'bottom',
            event: 'hover',
          }}
          onClick={this.onDeleteAbout.bind(
            this,
            rowData.reference_id,
            'remove_faq'
          )}
        >
          <FontAwesomeIcon icon={['fas', 'times']}></FontAwesomeIcon>
        </Button>

        <Link
          to={`/adminDashBoard/edit_about?about_id=${rowData.reference_id}`}
        >
          <Button
            // icon="fa fa-edit"
            //iconPos="right"
            className="p-button-warning btn_process"
            tooltip="تعديل الوصف"
            tooltipOptions={{
              position: 'bottom',
              event: 'hover',
            }}
            onClick={this.onEditAbout.bind(this)}
            disabled={rowData.business_status === 'مؤرشف'}
          >
            <FontAwesomeIcon icon={['fas', 'edit']}></FontAwesomeIcon>
          </Button>
        </Link>
      </React.Fragment>
    );
  };

  onDeleteAbout = async (reference_id, e) => {
    let processType = 'حذف الوصف المحدد';
    if (!window.confirm('حذف الوصف المحدد ؟')) {
      return;
    }
    await this.setState({
      deleteSuccessMessage: 'ready',
      updatedReferenceId: reference_id,
    });
    await this.props.deleteAbout(reference_id, processType);
  };

  componentWillReceiveProps(nextProps, nextState) {
    let result2 = [];
    if (
      nextProps.resType !== this.state.resType
    ){
      this.setState({
        resType: nextProps.resType,
      });
    }
    if (
      nextProps.abouts !== this.state.abouts &&
      nextProps.abouts[0] !== undefined &&
      nextProps.abouts[0] !== null
    ) {
      this.setState({
        resType: nextProps.resType,
        abouts: nextProps.abouts,
        total_abouts: nextProps.abouts[0].total_abouts,
      });
    } else {
      this.setState({
        abouts: [],
        total_abouts: 0,
      });
    }

    if (
      nextProps.about !== this.state.about
      // nextProps.about !== undefined &&
      // nextProps.about !== null
    ) {
      this.setState({
        resType: nextProps.resType,
        about: nextProps.about,
      });
    } else {
      this.setState({
        about: {},
      });
    }

    if (
      nextProps.deleteSuccessMessage !== this.state.deleteSuccessMessage &&
      this.state.deleteSuccessMessage !== '' &&
      nextProps.deleteSuccessMessage !== undefined &&
      nextProps.deleteSuccessMessage !== ''
    ) {
      this.setState({
        deleteSuccessMessage: nextProps.deleteSuccessMessage,
      });
      switch (nextProps.deleteSuccessMessage) {
        case 'حذف الوصف المحدد':
          this.growl.show({
            severity: 'success',
            summary: 'تم حذف الوصف المحدد',
          });
          this.state.abouts.forEach((obj, index) => {
            if (obj.reference_id !== this.state.updatedReferenceId) {
              result2.push(obj);
            }
          });
          this.setState({ abouts: result2 });
          break;

        default:
          break;
      }
    }
  }

  render() {
    const { about, abouts } = this.state;
    return (
      <div>
        <Helmet>
          <title>{this.state.pageTitle}</title>
          <meta name="description" content={this.state.pageDescription} />
        </Helmet>
        <br />
        {this.props.ikarAdmin ? (
          <div className="text-right mx-2">
            <a href={`${linkDomain}/adminDashBoard/add_about`}>
              ادخال وصف جديد
            </a>
          </div>
        ) : null}
        {/* <h1 className="display-4 logo_color text-center text-uppercase">
          Ikar.sy
        </h1> */}

        <br />
        {this.props.ikarAdmin ? (
          <div className="property-container">
            <div className="container-fluid">
              <Growl ref={(el) => (this.growl = el)} />
              {/* row justify-content-around align-items-center  */}
              <div className="table_container mt-1">
                <DataTable
                  ref={(el) => {
                    this.dt = el;
                  }}
                  value={abouts}
                  responsive={true}
                  //header="المكاتب العقارية"
                  selectionMode="single"
                  selection={this.state.selectedAbout}
                  onSelectionChange={(e) => {
                    this.setState({ selectedAbout: e.value });
                  }}
                  // header={joinHeader}
                >
                  <Column
                    field="content"
                    header="التفاصيل"
                    sortable={true}
                    className="table_td"
                    // style={{ width: "150px" }}
                  />

                  <Column
                    body={this.showDetailsTemplate}
                    // header="الاجراءات"
                    style={{ width: '150px' }}
                    className="table_td table-functions"
                  />
                </DataTable>
                <br />
              </div>
            </div>
          </div>
        ) : this.state.resType === 'complete' ? (
          <div className="lead text-justify" style={{ direction: 'rtl' }}>
            {/* {about.content} */}
            {about.content !== undefined ? (
              String(about.content)
                .split(/[<<>>]/)
                .map((element) =>
                  element[0] !== '=' ? (
                    <spen>{element}</spen>
                  ) : element
                      .split(';')[2]
                      .substring(0, element.split(';')[2].length - 1) ===
                    'facebook' ? (
                    <a href={element.split(';')[0].substring(1)}>
                      {element.split(';')[1]}
                      <span>
                        <FontAwesomeIcon
                          icon={['fab', 'facebook']}
                          size="x"
                          style={{ color: '#3b5998' }}
                        />
                      </span>
                    </a>
                  ) : element
                      .split(';')[2]
                      .substring(0, element.split(';')[2].length - 1) ===
                    'youtube' ? (
                    <a href={element.split(';')[0].substring(1)}>
                      {element.split(';')[1]}
                      <span>
                        <FontAwesomeIcon
                          icon={['fab', 'youtube']}
                          size="x"
                          style={{ color: '#c4302b' }}
                        />
                      </span>
                    </a>
                  ) : element
                      .split(';')[2]
                      .substring(0, element.split(';')[2].length - 1) ===
                    'twitter' ? (
                    <a href={element.split(';')[0].substring(1)}>
                      {element.split(';')[1]}
                      <span>
                        <FontAwesomeIcon
                          icon={['fab', 'twitter']}
                          size="x"
                          style={{ color: '#c4302b' }}
                        />
                      </span>
                    </a>
                  ) : element
                      .split(';')[2]
                      .substring(0, element.split(';')[2].length - 1) ===
                    'instagram' ? (
                    <a href={element.split(';')[0].substring(1)}>
                      {element.split(';')[1]}
                      <span>
                        <FontAwesomeIcon
                          icon={['fab', 'instagram']}
                          size="x"
                          style={{ color: '#c4302b' }}
                        />
                      </span>
                    </a>
                  ) : element
                      .split(';')[2]
                      .substring(0, element.split(';')[2].length - 1) ===
                    'mobile' ? (
                    <a
                      href={`tel:${element.split(';')[0].substring(1)}`}
                      // onClick="ga('send', 'event', { eventCategory: 'Contact', eventAction: 'Call', eventLabel: 'Mobile Button'});"
                      style={{ color: '#f18805' }}
                    >
                      <span className="show-property-link">
                        {element.split(';')[1]}
                      </span>
                    </a>
                  ) : null
                )
            ) : (
              <ResReady></ResReady>
            )}

            <br />
            <br />
            <br />
          </div>
        ) : this.state.resType === 'not complete' ? (
          <ResNotComplete></ResNotComplete>
        ) : this.state.resType === 'ready' ? (
          <ResReady></ResReady>
        ) : (
          <div>
            <br />
            <br />
            <br />
            <br />
          </div>
        )}
        <div className="text-center">
          <img src={waterMark} alt="" />
        </div>
        {/* {this.props.ikarAdmin ? (
          <div>
            <button
              className={classnames(
                "btn",
                "px-0",
                { "submit-search-button": this.state.largeScreen },
                {
                  "submit-search-button-min": !this.state.largeScreen,
                }
              )}
                onClick={this.onDeleteAbout.bind(
                this,
                about.reference_id,
                'remove_faq'
              )}
            >
              حذف
            </button>
          </div>
        ) : null}  */}
      </div>
    );
  }
}
About.propTypes = {
  abouts: PropTypes.array.isRequired,
  about: PropTypes.object.isRequired,
  ikarAdmin: PropTypes.bool.isRequired,
  // ikarEmail: PropTypes.string.isRequired,
  // agencyAdmin: PropTypes.bool.isRequired,
  //takenAgencyRoleEmail: PropTypes.string.isRequired,
  // isTakingAgencyRole: PropTypes.bool.isRequired,
  getAbouts: PropTypes.func.isRequired,
  deleteAbout: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  ikarAdmin: state.auth.ikarAdmin,
  abouts: state.about.abouts,
  about: state.about.about,
  deleteSuccessMessage: state.about.deleteSuccessMessage,
  resType: state.about.resType,
});
//export default About;
export default connect(mapStateToProps, { getAbouts, deleteAbout })(About);
