import React, { Component } from 'react';

import { Helmet } from 'react-helmet';

import classnames from 'classnames';
import { Growl } from 'primereact/growl';
import axios from 'axios';
import { mainDomain } from './../../mainConf';



class SendNotification extends Component {
  constructor(props) {
    super();
    this.state = {
        requestSuccessMessage: '',
        title: '',
        content: '',
        type:'admin',
        error1: false,
        error2: false,
        error3: false,
        success: false,
        init2: false,
        pageTitle: 'Ikar.sy |Notification',
        pageDescription:
          'إرسال اشعار للمكاتب والمستخدمين لتطبيق عقار سوق العقارات الاول في سوريا ',
      };
    }
 
    onAddSubmit = async (str, e) => {
        e.preventDefault();
        let { content } = this.state.content;
        let {title}=this.state.title;
    
    console.log(title);
    console.log(content);
        if (this.state.content === '' || this.state.content === null) {
          this.setState({ error2: true });
        }
     
        if (this.state.title === '' || this.state.title === null) {
          this.setState({ error3: true });
        }
    
     
        await this.setState({
          requestSuccessMessage: 'ready',
        });


if(!this.state.error2){
          let res = {};
          let errorRes = {
            errorStatus: '',
            errorMessage: '',
    
          };
          var token = '';
    
       
            token = localStorage.getItem('ikar_token');
            
            await axios
              .post(
                `${mainDomain}/v1/notifications/search`,
                {"notification": 
                { "fcm":"",
                "type":this.state.type,
                "agency_ref_id":""
                }
                },
                {
                  headers: {
                    //'Content-Type': 'application/json',
                    Accept: 'application/json',
                    //Accept: 'application/json',
                    // Authorization: token,
                  },
                }
              )
              .then(function (response) {
                if (
                  response !== null &&
                  response !== undefined &&
                  Object.keys(response).length > 0
                ) {
    
                  res = response.data;
                  res.resType = 'complete';
    
                } else res = {};
              })
              .catch(function (error) {
                if (error.response) {
                  errorRes = {
                    errorStatus: error.response.status,
                    errorMessage: error.response,
                  };
                } else if (error.request) {
                  errorRes = {
                    errorMessage: error.request,
                  };
                } else {
                  errorRes = { errorMessage: error.message };
                }
                res = errorRes;
                res.resType = 'not complete';
              }
              ) ;
           

           let tokens=[];
         
           res.forEach((user) => {
             tokens.push(user.fcm);
           });
         
           await axios
             .post(
               `${mainDomain}/v1/notification/send`,
               {title:this.state.title,
               body:this.state.content,
                refs:tokens
               },
               // ,
               // {
               //   withCredentials: true,
               // }
               {
                 headers: {
                   Accept: 'application/json',
                   Authorization: localStorage.getItem('ikar_token'),
                 },
               }
             )
             .then(function (response) {
               if (
                 response !== null &&
                 response !== undefined &&
                 Object.keys(response).length > 0
               ) {
                 res = response.data;
                 res.resType = 'complete';
               } else res = {};
             })
             .catch(function (error) {
               if (error.response) {
                 errorRes = {
                   errorStatus: error.response.status,
                   errorMessage: error.response,
                 };
               } else if (error.request) {
                 errorRes = {
                   errorMessage: error.request,
                 };
               } else {
                 errorRes = { errorMessage: error.message };
               }
               res = errorRes;
               res.resType = 'not complete';
             });

            }
else{
console.log(title);
console.log(content);


}    
      };

    componentWillReceiveProps(nextProps, nextState) {
        if (
          nextProps.requestSuccessMessage !== this.state.requestSuccessMessage &&
          this.state.requestSuccessMessage !== ''
        ) {
          this.setState({
            requestSuccessMessage: nextProps.requestSuccessMessage,
          });
    
          switch (nextProps.requestSuccessMessage) {
            case 'add is succeeded':
              this.growl.show({
                severity: 'success',
                summary: 'تم ارسال الاشعار بنجاح   ',
              });
              this.setState({
               
                content: '',
               
              });
              break;
            default:
              break;
          }
        }
      }
      onChange = (e) =>
      this.setState({
        [e.target.name]: e.target.value,
        error1: false,
        error2: false,
        error3: false,
      });
      handleClear = (e) => {
        if (e !== null) {
          e.preventDefault();
        }
       
      };
      
      render() {
        return (
          <div>
            <Helmet>
              <title>{this.state.pageTitle}</title>
              <meta name="description" content={this.state.pageDescription} />
            </Helmet>
            <Growl ref={(el) => (this.growl = el)} />
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="text-center pb-4 pt-3 mb-4">
                  <h1 className="main_title">ارسال اشعار </h1>
                </div>
              </div>
            </div>
            <form className="search_div  round-div p-1 mb-4">
              <div className="row justify-content-around mb-4">
              
              <div className="form-group text-right  mb-3">

<label htmlFor="business_offer">
  عنوان الاشعار: <span className="display_mandatory">(مطلوب)</span>
</label>
<span className="p-float-label text-right">

  <input
   
    type="text"
    name="title"
    className={classnames('form-control', {
      'is-invalid': this.state.error2,
    })}
    placeholder=""
    value={this.state.title}
    onChange={this.onChange}
    style={{ transition: 'all 0.4s' }}
  />

</span>


</div>
              
              
                <div className="form-group text-right  col-md-6 col-lg-6 col-sm-12 col-xs-12 mb-4 ">
                  <label htmlFor="content">
                    <span> نص الاشعار: </span>
                  </label>
                  <div>

                    <textarea
                      rows="2"
                      name="content"
                      className={classnames('form-control', {
                        'is-invalid': this.state.error3,
                      })}
                      placeholder=""
                      value={this.state.content}
                      onChange={this.onChange}
                    
                    />
                  </div>
                  {this.state.error3? (
                    <div className="invalid-feedback-custom">
                      الرجاء ادخال الاشعار{' '}
                    </div>
                  ) : (
                    ''
                  )}
                </div>

                <select
                  name="type"
                  //   className={classnames("form-control", "text-right")}
                  onChange={this.onChange}
                  value={this.state.type}
                  className={classnames('form-control', {
                    'is-invalid': this.state.error3,
                  })}
                  style={{ width: '50%', marginLeft: '50%' }}
                >
                  <option value="admin"> مكاتب</option>
                  <option value="client"> مستخدمين</option>
      
                </select>


                </div>
             </form>


         <div className="row">
          <div className="col-md-4 md-12 m-auto">
            <button
              className="btn  submit-button m-1 text-center btn-block"
             onClick={this.onAddSubmit.bind(this, 'submit')}
            >
              ارسال
            </button>
          </div>
         </div>
         </div>
  );
}
}


export default SendNotification;
