import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import classnames from "classnames";
import { getPrivacy } from "./../../actions/privacyAction";
import { editPrivacy } from "./../../actions/privacyActionUD";
import queryString from "query-string";
import { Growl } from "primereact/growl";
import NotFound from "./../../pages/NotFound";
class EditPRIVACY extends Component {
  constructor(props) {
    
    console.log(props);
    super(props);
    const id = this.props.match.params.id;
    console.log(this.props.match.params.id);
    // if (id !== undefined && id !== null) {
      this.props.getPrivacy(id);
    this.state = {
      reference_id: "",
      directory: "",
      requestSuccessMessage: "",
      requestMessage: "",
      requestFailedMessage: {},
      privacy: {},
      resType: "",
      content: "",
      init2: false,
      error1: false,
      error2: false,
      error3: false,
      pageTitle: "Ikar.sy |PRIVACY",

      pageDescription:'  صفحة سياسة الخصوصية الخاصة بسوق العقارات الأول في سوريا |Ikar.sy',
    };
  }

  componentWillReceiveProps = async (nextProps, nextState) => {
    if (
      nextProps.requestSuccessMessage !== this.state.requestSuccessMessage &&
      this.state.requestSuccessMessage === "ready" &&
      this.state.requestFailedMessage.error === undefined
    ) {
      this.setState({
        // requestSuccessMessage: nextProps.requestSuccessMessage,
        requestSuccessMessage: nextProps.requestFailedMessage,
      });

      switch (nextProps.requestSuccessMessage) {
        case "تعديل سياسة الخصوصية المحددة":
          this.growl.show({
            severity: "success",
            summary: "تم تعديل سياسة الخصوصية بنجاح",
          });
          console.log(this.props.match.params);
          const searchUrlParams = await queryString.parse(
            this.props.locationSearch
          );
 
          // if (searchUrlParams.privacy_id !== undefined) {
            // agency_ref_id = searchUrlParams.agency_id;
            const id = this.props.match.params.id;
            console.log(this.props.match.params.id);
            // if (id !== undefined && id !== null) {
              this.props.getPrivacy(id);
            // }
          // }
          break;

        case "error in edit question":
          this.growl.show({
            severity: "error",
            summary: "حدثت مشكلة في تعديل البند الرجاء المحاولة لاحقا ",
          });
          break;

        default:
          break;
      }
    } else {
      if (nextProps.privacy !== this.state.privacy) {
        await this.setState({
          privacy: nextProps.privacy,
          content: nextProps.privacy.content,

          reference_id: nextProps.privacy.reference_id,
        });
      }

      if (
        nextProps.privacy !== undefined &&
        nextProps.privacy !== this.state.privacy &&
        nextProps.requestMessage === "edit is succeeded"
      ) {
        const { content } = nextProps.privacy;

        await this.setState({
          content,
        });
      }
    }

    if (
      nextProps.resType !== undefined &&
      nextProps.resType !== this.state.resType
    ) {
      const { resType } = nextProps;
      this.setState({ resType });
    }
  };

  async componentDidMount() {
    const searchUrlParams = await queryString.parse(this.props.locationSearch);

    if (searchUrlParams.privacy_id !== undefined) {
      // agency_ref_id = searchUrlParams.agency_id;
      const id = searchUrlParams.privacy_id;
      if (id !== undefined && id !== null) {
        this.props.getPrivacy(id);
      }
    }
  }

  onChange = (e) =>
    this.setState({
      [e.target.name]: e.target.value,
      error1: false,
      error2: false,
      error3: false,
    });

  onEditSubmit = async (str, e) => {
    e.preventDefault();
    const { content, reference_id } = this.state;
    if (content === "" || content === null) {
      this.setState({ error2: true });
    }

    const privacyObject = {
      content,
      reference_id,
    };
    this.setState({ requestSuccessMessage: "ready" });
    this.props.editPrivacy(privacyObject, "تعديل سياسة الخصوصية المحددة");
  };

  onCheckBoxChange = async (e) => {
    if (this.state.init2) {
      await this.setState({ init2: false, [e.target.name]: true });
    } else {
      await this.setState({ [e.target.name]: e.target.checked });
    }
  };

  render() {
  
    const {
      resType,
    } = this.state;
    return (
      <div>
        <Helmet>
          <title>{this.state.pageTitle}</title>
          <meta name="description" content={this.state.pageDescription} />
        </Helmet>
        {/* //////////// */}
        {resType === "complete" ? (
          <div>
            <Growl ref={(el) => (this.growl = el)} />
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="text-center pb-4 pt-3 mb-4">
                  <h1 className="main_title">تعديل اقتراح</h1>
                </div>
              </div>
            </div>
            <form className="search_div  round-div p-1 mb-4">
              <div className="row justify-content-around mb-4">
                <div className="form-group text-right col-lg-6 col-md-6 col-sm-12 col-xs-12  mb-4">
                  <label htmlFor="content">
                    التفاصيل: <span className="display_mandatory"></span>
                  </label>
                  <div>
                    <textarea
                      rows="4"
                      name="content"
                      className="form-control"
                      placeholder=""
                      value={this.state.content}
                      onChange={this.onChange}
                    />
                  </div>
                  {this.state.error2 ? (
                    <div className="invalid-feedback-custom">
                      الرجاء ادخال التفاصيل{" "}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </form>
         
            <div className="row">
              <div className="col-md-4 md-12 m-auto">
                <button
                  className="btn  submit-button m-1 text-center btn-block"
                  //  style={{ position: "right" }}
                  onClick={this.onEditSubmit.bind(this, "submit")}
                >
                  تعديل الاقتراح
                </button>
              </div>
            </div>
          </div>
        ) : resType === "not complete" ? (
          <NotFound></NotFound>
        ) : null}
      </div>
    );
  }
}

EditPRIVACY.propTypes = {
  editPrivacy: PropTypes.func.isRequired,
  getPrivacy: PropTypes.func.isRequired,
  privacy: PropTypes.object.isRequired,
  requestFailed: PropTypes.bool.isRequired,
  requestFailedMessage: PropTypes.object.isRequired,
  isTakingAgencyRole: PropTypes.bool.isRequired,
  ikarAdmin: PropTypes.bool.isRequired,
  requestSuccessMessage: PropTypes.string.isRequired,
  short_id: PropTypes.string.isRequired,
  resType: PropTypes.string.isRequired,
};
const mapStateToProps = (state) => ({
  ////reducers
  privacy: state.privacy.privacy,
  requestFailed: state.privacy.requestFailed,
  requestFailedMessage: state.privacy.requestFailedMessage,
  isTakingAgencyRole: state.auth.isTakingAgencyRole,
  takenAgencyRoleToken: state.auth.takenAgencyRoleToken,
  ikarAdmin: state.auth.ikarAdmin,
  requestSuccessMessage: state.privacyUD.requestSuccessMessage,
  requestMessage: state.privacy.requestMessage,
  short_id: state.privacyUD.short_id,
  resType: state.privacy.resType,
  directory: state.privacy.directory,
});
export default connect(mapStateToProps, {
  editPrivacy,
  getPrivacy,
})(EditPRIVACY);
