import {
  UPDATE_PROPERTY,
  UPDATE_PROPERTY_RANK,
  UPDATE_PROPERTY_LAKTA,
  DELETE_PROPERTY
} from '../actions/types';
import shortid from 'shortid';

const initialState = {
  properties: [],
  property: {},
  requestFailed: false,
  requestFailedMessage: {},
  requestSuccessMessage: '',
  short_id: ''
};

export default function(state = initialState, action) {
  switch (action.type) {
    case DELETE_PROPERTY:
      if (
        Object.keys(action.payload).length > 0 &&
        (action.payload.errorMessage === '' ||
          action.payload.errorMessage === undefined)
      ) {
        action.payload.processStatus = 'delete property successfully';
        return {
          //...state,
          property: action.payload,
          //add state data
          properties: [],
          requestFailed: false,
          requestFailedMessage: { error: 'no' },
          requestSuccessMessage: action.payload.message,
          short_id: shortid.generate()
          //
        };
      } else {
        return {
          //...state,
          property: [],
          requestFailed: true,
          requestFailedMessage: action.payload,
          //add state data
          properties: [],
          requestSuccessMessage: '',
          short_id: shortid.generate()
          //
        };
      }

    case UPDATE_PROPERTY:
      if (
        Object.keys(action.payload).length > 0 &&
        (action.payload.errorMessage === '' ||
          action.payload.errorMessage === undefined)
      ) {
        return {
          //...state,
          property: action.payload,
          //add state data
          properties: [],
          requestFailed: false,
          requestFailedMessage: { error: 'no' },
          requestSuccessMessage: action.payload.message,
          //requestSuccessMessage: 'edit is succeeded',
          short_id: shortid.generate()
          //
        };
      } else {
        return {
          //...state,
          property: {},
          requestFailed: true,
          requestFailedMessage: action.payload,
          //add state data
          properties: [],
          requestSuccessMessage: 'error in property edit',
          short_id: shortid.generate()
          //
        };
      }

    case UPDATE_PROPERTY_RANK:
      if (
        Object.keys(action.payload).length > 0 &&
        (action.payload.errorMessage === '' ||
          action.payload.errorMessage === undefined)
      ) {
        return {
          //...state,
          property: action.payload,
          //add state data
          properties: [],
          requestFailed: false,
          requestFailedMessage: { error: 'no' },
          requestSuccessMessage: action.payload.message,
          //requestSuccessMessage: 'edit is succeeded',
          short_id: shortid.generate()
          //
        };
      } else {
        return {
          //...state,
          property: {},
          requestFailed: true,
          requestFailedMessage: action.payload,
          //add state data
          properties: [],
          requestSuccessMessage: '',
          short_id: shortid.generate()
          //
        };
      }

    case UPDATE_PROPERTY_LAKTA:
      if (
        Object.keys(action.payload).length > 0 &&
        (action.payload.errorMessage === '' ||
          action.payload.errorMessage === undefined)
      ) {
        return {
          //...state,
          property: action.payload,
          //add state data
          properties: [],
          requestFailed: false,
          requestFailedMessage: { error: 'no' },
          requestSuccessMessage: action.payload.message,
          //requestSuccessMessage: 'edit is succeeded',
          short_id: shortid.generate()
          //
        };
      } else {
        return {
          //...state,
          property: {},
          requestFailed: true,
          requestFailedMessage: action.payload,
          //add state data
          properties: [],
          requestSuccessMessage: '',
          short_id: shortid.generate()
          //
        };
      }
    default:
      return state;
  }
}
