import { GET_SETTINGS, SET_PATH } from './types';

export const getSettings = () => async dispatch => {
  const res = {};
  dispatch({
    type: GET_SETTINGS,
    payload: res.data
  });
};
export const setPath = path => async dispatch => {
  const res =  path ;
  dispatch({
    type: SET_PATH,
    payload: res
  });
};
