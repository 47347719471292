export const GET_PROPERTIES = 'GET_PROPERTIES';
export const GET_PROPERTY = 'GET_PROPERTY';
export const ADD_PROPERTY = 'ADD_PROPERTY';
export const ADD_PROPERTY_PHOTO = 'ADD_PROPERTY_PHOTO';
export const CALL_FOR_PROPERTY = 'CALL_FOR_PROPERTY';
export const DELETE_PROPERTY = 'DELETE_PROPERTY';
export const UPDATE_PROPERTY = 'UPDATE_PROPERTY';
export const UPDATE_PROPERTY_RANK = 'UPDATE_PROPERTY_RANK';
export const UPDATE_PROPERTY_LAKTA = 'UPDATE_PROPERTY_LAKTA';
export const GET_TOKEN = 'GET_TOKEN';
export const SEND_NOTIFICATION = 'SEND_NOTIFICATION';
export const GET_PROPERTIES_BY_AGENCY = 'GET_PROPERTIES_BY_AGENCY';
export const GET_PROPERTIES_BY_USER = 'GET_PROPERTIES_BY_USER';
export const GET_PROPERTIES_LATEST = 'GET_PROPERTIES_LATEST';
export const GET_PROPERTIES_MOST_VIEWED = 'GET_PROPERTIES_MOST_VIEWED';
export const GET_PROPERTIES_PER_PROVINCE = 'GET_PROPERTIES_PER_PROVINCE';
export const GET_PROPERTIES_SPECIAL_OFFER = 'GET_PROPERTIES_SPECIAL_OFFER';
// export const GET_USERS = 'GET_USERS';
export const GET_USER = 'GET_USER';
export const ADD_USER = 'ADD_USER';
export const DELETE_USER = 'DELETE_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const SHOW_USER_PROFILE = 'SHOW_USER_PROFILE';

// export const GET_AGENCIES = 'GET_AGENCIES';
export const GET_AGENCY = 'GET_AGENCY';
export const ADD_AGENCY = 'ADD_AGENCY';
export const DELETE_AGENCY = 'DELETE_AGENCY';
export const UPDATE_AGENCY = 'UPDATE_AGENCY';
export const UPDATE_AGENCY_RANK = 'UPDATE_AGENCY_RANK';
export const UPDATE_AGENCY_EMAIL = 'UPDATE_AGENCY_EMAIL';
export const UPDATE_AGENCY_PASSWORD = 'UPDATE_AGENCY_PASSWORD';
export const SHOW_AGENCY_PROFILE = 'SHOW_AGENCY_PROFILE';
export const BLOCK_AGENCY = 'BLOCK_AGENCY';
export const ACTIVE_AGENCY = 'ACTIVE_AGENCY';

export const GET_CLIENTS = 'GET_CLIENTS';
export const ADD_CLIENT = 'ADD_CLIENT';

export const LOGIN_PROCESS = 'LOGIN_PROCESS';
export const LOGIN_CHECKING = 'LOGIN_CHECKING';
export const LOGOUT_PROCESS = 'LOGOUT_PROCESS';
export const TAKE_AGENCY_ROLE = 'TAKE_AGENCY_ROLE';
export const LEAVE_AGENCY_ROLE = 'LEAVE_AGENCY_ROLE';
export const LEAVE_AGENCY_ROLE2 = 'LEAVE_AGENCY_ROLE2';
export const FORGET_PASSWORD_PROCESS = 'FORGET_PASSWORD_PROCESS';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const CHECK_CONFIRMATION = 'CHECK_CONFIRMATION';

export const GET_SETTINGS = 'GET_SETTINGS';
export const SET_PATH = 'SET_PATH';

export const DO_SEARCH = 'DO_SEARCH';
export const DO_AGENCY_PUBLIC_SEARCH = 'DO_AGENCY_PUBLIC_SEARCH';
export const DO_AGENCY_SEARCH = 'DO_AGENCY_SEARCH';
export const DO_USER_SEARCH = 'DO_USER_SEARCH';

export const GET_PROPERTIES_FOR_RENT = 'GET_PROPERTIES_FOR_RENT';
export const GET_PROPERTIES_FOR_SALE = 'GET_PROPERTIES_FOR_SALE';

export const ADD_CONTACT_MESSAGE = 'ADD_CONTACT_MESSAGE';
export const GET_CONTACT_MESSAGES = 'GET_CONTACT_MESSAGES';
export const DELETE_CONTACT_MESSAGES = 'DELETE_CONTACT_MESSAGES';

export const GET_MAIN_RESPONSE = 'GET_MAIN_RESPONSE';
export const SET_MAIN_RESPONSE = 'SET_MAIN_RESPONSE';

export const ADD_FAQ = 'ADD_FAQ';
export const GET_FAQ = 'GET_FAQ';
export const GET_FAQS = 'GET_FAQS';
export const UPDATE_FAQ = 'UPDATE_FAQ';
export const DELETE_FAQ = 'DELETE_FAQ';
export const GET_JOINS = 'GET_JOINS';
export const DELETE_JOIN= 'DELETE_JOIN';
export const ADD_JOIN = 'ADD_JOIN';
export const GET_JOIN = 'GET_JOIN';
export const UPDATE_JOIN = 'UPDATE_JOIN';
export const GET_READ_MESSAGES_AGENCY ='GET_READ_MESSAGES_AGENCY';

export const GET_ABOUTS = 'GET_ABOUTS';
export const GET_ABOUT = 'GET_ABOUT';
export const DELETE_ABOUT= 'DELETE_ABOUT';
export const ADD_ABOUT = 'ADD_ABOUT';
export const UPDATE_ABOUT = 'UPDATE_ABOUT';

export const Get_Privacies = 'Get_Privacies';
export const GET_PRIVACY = 'GET_PRIVACY';
export const DELETE_PRIVACY= 'DELETE_PRIVACY';
export const ADD_PRIVACY = 'ADD_PRIVACY';
export const UPDATE_PRIVACY = 'UPDATE_PRIVACY';



export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES2 = 'GET_CATEGORIES2';


