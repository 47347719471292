import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { blockAgency, activeAgency } from './../../actions/agencyActionsUD';
// import { getUsers } from './../../actions/searchActions';
import { editUser } from './../../actions/userActionsUD';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { loginProcess } from './../../actions/authActions';
import jQuery from 'jquery';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import classnames from 'classnames';
import { Button } from 'primereact/button';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { takeAgencyRole } from './../../actions/authActions';
import { Growl } from 'primereact/growl';
import SearchUsersBar from './../search/SearchUsersBar.jsx';
import Pagination from './../layout/Pagination/Pagination';
// import { recordsPerPage } from './../../mainConf/index';
import { Helmet } from 'react-helmet';
class Users extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: '',
      pageDescription: '',
      selectedUser: {},
      requestSuccessMessage: '',
      users: [],
      updatedRef: '',
      total_users: 0,
      pageNumber: 1,
    };
  }
  componentWillReceiveProps(nextProps, nextState) {
    if (
      nextProps.loginFailedMessage !== undefined &&
      nextProps.loginFailedMessage.data !== undefined &&
      nextProps.loginFailedMessage.data.error !== undefined &&
      nextProps.loginFailedMessage.data.error === `not confirmed yet` &&
      this.state.process1Active
    ) {
      this.growl.show({
        severity: 'success',
        summary: 'حساب  المستخدم المحدد غير مفعل بعد',
      });
    }
    if (
      nextProps.requestSuccessMessage !== this.state.requestSuccessMessage &&
      this.state.requestSuccessMessage !== ''
    ) {
      var result2 = [];
      this.setState({
        requestSuccessMessage: nextProps.requestSuccessMessage,
      });
      switch (nextProps.requestSuccessMessage) {
        case 'حجب المستخدم المحدد':
          this.growl.show({
            severity: 'success',
            summary: 'تم حجب المستخدم المحدد',
          });
          this.state.users.forEach((obj, index) => {
            result2.push(obj);
            if (obj.id === this.state.updatedRef) {
              result2[index].blocked = !obj.blocked;
            }
          });
          this.setState({ users: result2 });
          break;
        case 'الغاء حجب المستخدم المحدد':
          this.growl.show({
            severity: 'success',
            summary: 'تم الغاء حجب المستخدم المحدد',
          });
          this.state.users.forEach((obj, index) => {
            result2.push(obj);
            if (obj.id === this.state.updatedRef) {
              result2[index].blocked = !obj.blocked;
            }
          });
          this.setState({ users: result2 });
          break;
        case 'تفعيل الحساب المحدد':
          this.growl.show({
            severity: 'success',
            summary: 'تم تفعيل الحساب المحدد',
          });
          this.state.users.forEach((obj, index) => {
            result2.push(obj);
            if (obj.agency_ref_id === this.state.updatedRef) {
              result2[index].confirmed = true;
              result2[index].blocked = false;
            }
          });
          this.setState({ users: result2 });
          break;

        case 'error in active agency':
          this.growl.show({
            severity: 'error',
            summary: 'حذث خطأ أثناء تفعيل الحساب المحدد الرجاء المحاولة لاحقا',
          });

          break;
        default:
          break;
      }
    } else if (
      nextProps.users !== this.state.users &&
      nextProps.users[0] !== undefined
    ) {
      this.setState({
        users: nextProps.users,
        total_users: nextProps.users[0].total_users,
      });
    } else {
      this.setState({
        users: [],
        total_users: 0,
      });
    }
  }
  componentDidMount() {
    jQuery(function ($) {
      $('#userTable').on('click', '.clickable-row', function (event) {
        $(this)
          .addClass('table-success')
          .siblings()
          .removeClass('table-success');
      });
    });
  }
  handleTakeAgencyRole = (referenceId, e) => {
    if (!window.confirm('الدخول كالمستخدم المحدد ؟')) {
      return;
    }
    this.props.takeAgencyRole(referenceId);
    // this.props.history.push("/dashBoard/properties_by_agency");
    //this.props.history.push('/');
  };
  onChangeBlockedStatus = async (reference_id, id, blockedStatus, e) => {
    e.preventDefault();

    var blockDate = {};
    var processType = '';
    switch (blockedStatus) {
      case 'set_blocked':
        if (!window.confirm('حجب المستخدم المحدد ؟')) {
          return;
        }
        blockDate = {
          reference_id,
          blocked: true,
        };
        processType = 'حجب المستخدم المحدد';
        break;
      case 'remove_blocked':
        if (!window.confirm('الغاء حجب المستخدم المحدد ؟')) {
          return;
        }
        blockDate = {
          reference_id,
          blocked: false,
        };
        processType = 'الغاء حجب المستخدم المحدد';
        break;
      default:
        break;
    }

    await this.setState({
      requestSuccessMessage: 'ready',
      updatedRef: id,
    });
    // const userDate = { admin };
    await this.props.blockAgency(blockDate, processType);
  };
  onActiveAgency = async (id, e) => {
    e.preventDefault();
    var admin = {};
    var activeData = {};
    var processType = '';
    if (!window.confirm('تفعيل الحساب المحدد ؟')) {
      return;
    }
    admin = {
      agency_ref_id: id,
      confirmed: true,
    };
    processType = 'تفعيل الحساب المحدد';
    activeData = { admin };
    await this.setState({
      requestSuccessMessage: 'ready',
      updatedRef: id,
    });
    await this.props.activeAgency(activeData, processType);
  };
  ikarAdminTemplate = (rowData, column) => {
    return (
      <span
        className={classnames(
          'text-center',
          { 'text-success': rowData.ikar_admin },
          { 'text-danger': !rowData.ikar_admin }
        )}
      >
        {rowData.ikar_admin ? <span>نعم</span> : <span>لا</span>}
      </span>
    );
  };
  export = () => {
    this.dt.exportCSV();
  };
  agencyAdminTemplate = (rowData, column) => {
    return (
      <span
        className={classnames(
          'text-center',
          { 'text-success': rowData.agency_admin },
          { 'text-danger': !rowData.agency_admin }
        )}
      >
        {rowData.agency_admin ? <span>نعم</span> : <span>لا</span>}
      </span>
    );
  };
  agencyStatusTemplate = (rowData, column) => {
    return (
      <span
        className={classnames(
          'text-center',
          { 'text-success': rowData.confirmed },
          { 'text-danger': !rowData.confirmed }
        )}
      >
        {rowData.confirmed ? <span>نعم</span> : <span>لا</span>}
      </span>
    );
  };

  showDetailsTemplate = (rowData, column) => {
    return (
      <React.Fragment>
        {!rowData.ikar_admin ? (
          <React.Fragment>
            {rowData.blocked ? (
              <Button
                //icon="pi pi-check"
                // icon="fa fa-user"
                //iconPos="right"
                className="p-button-danger btn_process"
                tooltip="المستخدم محجوب"
                tooltipOptions={{
                  position: 'bottom',
                  event: 'hover',
                }}
                disabled={!rowData.confirmed}
                onClick={this.onChangeBlockedStatus.bind(
                  this,
                  rowData.agency_ref_id,
                  rowData.id,
                  'remove_blocked'
                )}
              >
                <FontAwesomeIcon icon={['fas', 'user']}></FontAwesomeIcon>
              </Button>
            ) : (
              <Button
                // icon="fa fa-user"
                //iconPos="right"
                className="p-button-success btn_process"
                tooltip="المستخدم غير محجوب"
                tooltipOptions={{
                  position: 'bottom',
                  event: 'hover',
                }}
                disabled={!rowData.confirmed}
                onClick={this.onChangeBlockedStatus.bind(
                  this,
                  rowData.agency_ref_id,
                  rowData.id,
                  'set_blocked'
                )}
              >
                <FontAwesomeIcon icon={['fas', 'user']}></FontAwesomeIcon>
              </Button>
            )}
          </React.Fragment>
        ) : null}

        {!rowData.ikar_admin && !rowData.blocked ? (
          <Button
            type="button"
            // icon="fa fa-universal-access"
            className="p-button-warning btn_process"
            //onChange={(e) => this.setState({country: e.value})}
            onClick={this.handleTakeAgencyRole.bind(
              this,
              rowData.agency_ref_id
              // ,
              // rowData.reference_id
            )}
            disabled={!rowData.confirmed}
            tooltip="الدخول كالمستخدم المحدد"
            tooltipOptions={{
              position: 'bottom',
              event: 'hover',
            }}
          >
            <FontAwesomeIcon
              icon={['fas', 'universal-access']}
            ></FontAwesomeIcon>
          </Button>
        ) : null}
        {/* <Link
          to="#!"
          className="btn btn-secondary btn-sm"
          onClick={this.handleTakeAgencyRole.bind(this, rowData.email)}
        >
          <FontAwesomeIcon icon={['fas', 'arrow-circle-right']} /> الدخول
          كمستخدم
        </Link> */}
        <Button
          className="p-button-warning btn_process"
          tooltip="تفعيل الحساب"
          tooltipOptions={{
            position: 'bottom',
            event: 'hover',
          }}
          onClick={this.onActiveAgency.bind(this, rowData.agency_ref_id)}
          disabled={rowData.confirmed}
        >
          <FontAwesomeIcon icon={['fas', 'unlock']}></FontAwesomeIcon>
        </Button>
        <Link
          to={`/profile/${rowData.id}`}
          // to={{
          //   pathname: "/profile",
          //   query: { user_email: rowData.email }
          // }}
        >
          <Button
            // icon="fa fa-address-card"
            //iconPos="right"
            className="p-button-secondary btn_process"
            tooltip="معلومات المستخدم"
            tooltipOptions={{
              position: 'bottom',
              event: 'hover',
            }}
            style={{ border: '1px solid #9F9F9F' }}
          >
            <FontAwesomeIcon icon={['fas', 'address-card']}></FontAwesomeIcon>
          </Button>
        </Link>
      </React.Fragment>
    );
  };
  onSearchSubmit = (recordsPerPage, pageNumber) => {
    this.setState({ pageNumber });
  };
  onNewSearch = () => {
    this.setState({ pageNumber: 1 });
  };
  render() {
    const { users, total_users, pageNumber } = this.state;
    var userHeader = (
      <div className="px-2 py-0" style={{ textAlign: 'right' }}>
        <Button
          type="button"
          icon="pi pi-external-link"
          iconPos="left"
          label="تصدير الجدول الى ملف اكسل CSV"
          onClick={this.export}
        ></Button>
      </div>
    );
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.pageTitle}</title>
          <meta name="description" content={this.state.pageDescription} />
          <meta name="robots" content="noindex"></meta>
        </Helmet>
        <SearchUsersBar
          searchType="all_users_search"
          searchId=""
          pageNumber={pageNumber}
          newSearch={this.onNewSearch}
        ></SearchUsersBar>
        <div className="mb-3 text-center my-2">
          <span className="main_title">قائمة المستخدمين</span>
        </div>
        <div className="property-container">
          <div className="container-fluid">
            <Growl ref={(el) => (this.growl = el)} />
            <div className="table_container mt-1">
              {/* <div className="table-responsive"> */}
              <DataTable
                ref={(el) => {
                  this.dt = el;
                }}
                value={users}
                responsive={true}
                // resizableColumns={true}
                // columnResizeMode="fit"
                selectionMode="single"
                selection={this.state.selectedUser}
                onSelectionChange={(e) => {
                  this.setState({ selectedUser: e.value });
                }}
                header={userHeader}
              >
                {/* {dynamicColumns} */}
                <Column
                  field="email"
                  header="الايميل"
                  sortable={true}
                  className="table_td"
                />
                <Column
                  field="name"
                  header="الاسم"
                  sortable={true}
                  className="table_td"
                />

                <Column field="mobile" header="الموبايل" className="table_td" />
                <Column field="facebook" header="فيسبوك" className="table_td" />
                <Column
                  body={this.agencyAdminTemplate}
                  className="table_td"
                  header="مدير مكتب؟"
                />
                <Column
                  body={this.ikarAdminTemplate}
                  className="table_td"
                  header="مدير نظام؟"
                />
                <Column
                  body={this.agencyStatusTemplate}
                  className="table_td"
                  header="الحساب مفعل"
                />
                <Column
                  body={this.showDetailsTemplate}
                  className="table_td table-functions"
                  //header="الاجراءات"
                  style={{ width: '180px' }}
                />
              </DataTable>
            </div>
            <Pagination
              resultLabel="مستخدم"
              recordsNumber={total_users}
              onPageChange={this.onSearchSubmit}
              pageNumber={pageNumber}
              showMessage={true}
            ></Pagination>
          </div>
          {/* </div> */}
        </div>
      </React.Fragment>
    );
  }
}

Users.propTypes = {
  users: PropTypes.array.isRequired,
  // getUsers: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  ikarAdmin: PropTypes.bool.isRequired,
  takeAgencyRole: PropTypes.func.isRequired,
  editUser: PropTypes.func.isRequired,
  blockAgency: PropTypes.func.isRequired,
  requestSuccessMessage: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  users: state.search.users,
  isLoggedIn: state.auth.isLoggedIn,
  ikarAdmin: state.auth.ikarAdmin,
  // requestSuccessMessage: state.userUD.requestSuccessMessage,
  requestSuccessMessage: state.agencyUD.requestSuccessMessage,
  short_id: state.agencyUD.short_id,
  short_id2: state.user.short_id2,
  loginFailedMessage: state.auth.loginFailedMessage,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    editUser,
    loginProcess,
    takeAgencyRole,
    blockAgency,
    activeAgency,
  })
)(Users);
