import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import { mainDomain } from './../../mainConf';
import classnames from 'classnames';
import { Checkbox } from 'primereact/checkbox';
import { Growl } from 'primereact/growl';
import { addAbout } from './../../actions/aboutAction';
class AddAbout extends Component {
  constructor(props) {
    super();
    this.state = {
        requestSuccessMessage: '',
        title: '',
        reference_id: '',
        content: '',
        // sugg_number: -100,
        visible: false,
        error1: false,
        error2: false,
        error3: false,
        success: false,
        init2: false,
        pageTitle: 'Ikar.sy |JOIN',
        pageDescription:
          'سوق العقارات الإلكتروني في سورية Ikar.sy مخصص لجمع عروض بيع، شراء و تأجير العقارات من جميع مكاتب العقارات في سورية. الإعلان على Ikar.sy مجاني و متاح فقط للمكاتب العقارية والمتعهدين المسجلين لدى الموقع في جميع المحافظات السورية. | Ikar.sy',
      };
    }
    onAddSubmit = async (str, e) => {
        e.preventDefault();
        let { content } = this.state;
    
    
        if (content === '' || content === null) {
          this.setState({ error2: true });
        }
    
        const newABOUT = {
          content,
        };
        await this.setState({
          requestSuccessMessage: 'ready',
        });
        // if (!title === "" && !content === "" && !sugg_number === -100) {
        this.props.addAbout(newABOUT);
      };

    componentWillReceiveProps(nextProps, nextState) {
        if (
          nextProps.requestSuccessMessage !== this.state.requestSuccessMessage &&
          this.state.requestSuccessMessage !== ''
        ) {
          this.setState({
            requestSuccessMessage: nextProps.requestSuccessMessage,
          });
    
          switch (nextProps.requestSuccessMessage) {
            case 'add is succeeded':
              this.growl.show({
                severity: 'success',
                summary: 'تم اضافة اقتراح جديد',
              });
              this.setState({
               
                content: '',
               
              });
              break;
            default:
              break;
          }
        }
      }
      onChange = (e) =>
      this.setState({
        [e.target.name]: e.target.value,
        error1: false,
        error2: false,
        error3: false,
      });
      handleClear = (e) => {
        if (e !== null) {
          e.preventDefault();
        }
        this.setState({
          
          content: '',
        
        });
      };
      


      render() {
       // const { visible, init2 } = this.state;
        return (
          <div>
            <Helmet>
              <title>{this.state.pageTitle}</title>
              <meta name="description" content={this.state.pageDescription} />
            </Helmet>
            <Growl ref={(el) => (this.growl = el)} />
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="text-center pb-4 pt-3 mb-4">
                  <h1 className="main_title">اضافة اقتراحات جديدة</h1>
                </div>
              </div>
            </div>
            <form className="search_div  round-div p-1 mb-4">
              <div className="row justify-content-around mb-4">
                <div className="form-group text-right  col-md-6 col-lg-6 col-sm-12 col-xs-12 mb-4 ">
                  <label htmlFor="content">
                    <span> الاقتراح: </span>
                  </label>
                  <div>
                    <textarea
                      rows="2"
                      name="content"
                      className={classnames('form-control', {
                        'is-invalid': this.state.error2,
                      })}
                      placeholder=""
                      value={this.state.content}
                      onChange={this.onChange}
                    />
                  </div>
                  {this.state.error2 ? (
                    <div className="invalid-feedback-custom">
                      الرجاء ادخال الاقتراح{' '}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
                </div>
             </form>


         <div className="row">
          <div className="col-md-4 md-12 m-auto">
            <button
              className="btn  submit-button m-1 text-center btn-block"
              //  style={{ position: "right" }}
              onClick={this.onAddSubmit.bind(this, 'submit')}
            >
              اضافة
            </button>
          </div>
         </div>
         </div>
  );
}
}
addAbout.propTypes = {
    //requestFailedMessage: PropTypes.object.isRequired,
    isTakingAgencyRole: PropTypes.bool.isRequired,
  
    takenAgencyRoleToken: PropTypes.string.isRequired,
  };
const mapStateToProps = (state) => ({
    isTakingAgencyRole: state.auth.isTakingAgencyRole,
    takenAgencyRoleToken: state.auth.takenAgencyRoleToken,
    requestSuccessMessage: state.about.requestSuccessMessage,
    short_id: state.about.short_id,
  });


export default connect(mapStateToProps, { addAbout })(AddAbout);