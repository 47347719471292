import React, { Component } from "react";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import { doSearch } from "./../../actions/searchActions";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { linkDomain } from "./../../mainConf";
import {
  syriaAreas,
  generalBusinessOffer,
  generalProvinces,
  generalTypesItems,
} from "./../../mainConf";

class SearchCategories extends Component {
  state = {
    // searchURLParams: {},
    searchUrl: "",
    searchUrl1: "",
    searchUrl2: "",
    searchUrl3: "",
    searchUrl4: "",
    searchParams: {},
    largeScreen: false,
    destination: "search",
    areas: [],

    type: "نوع العقار", //flat
    business_offer: "نوع العرض", //"rent"
    city: "",
    province: "المحافظة",
    area: "المنطقة",

    main_type_param: "",
  };
  async componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.location.search !== this.state.locationSearch) {
      await this.setState({
        locationSearch: nextProps.location.search,
      });
      await this.getSearch();
    }
  }
  isNumeric = (num) => {
    return /^\d+$/.test(num);
  };
  isInt = (num) => {
    return /^-{0,1}\d+$/.test(num);
  };
  getSearch = async () => {
    await this.setState({ locationSearch: this.props.location.search });

    await this.setState({
      area: this.props.area,
      province: this.props.province,
      business_offer: this.props.business_offer,
      type: this.props.type,
    });

    this.buildSearchUrl();
  };

  async componentDidMount() {
    this.resize();
    window.addEventListener("resize", this.resize.bind(this));
    this.getSearch();
  }
  resize() {
    let currentWidth = window.innerWidth;
    if (currentWidth >= 768) {
      this.setState({ largeScreen: true });
    } else {
      this.setState({ largeScreen: false });
    }
  }

  buildSearchUrl = async () => {
    const {
      type,
      // main_type_param,
      province,
      area,
      business_offer,
    } = this.props;

    var Province = "";
    var Area = "";
    var Type = "";
    var BusinessOffer = "";

    if (province !== "المحافظة") {
      Province = province;
    }
    if (area !== "المنطقة") {
      Area = area;
    }
    if (type !== "نوع العقار") {
      Type = type;
    }
    if (business_offer !== "نوع العرض") {
      BusinessOffer = business_offer;
    }

    const property = {};
    if (Type !== "") {
      property.type = Type;
    }
    // if (main_type_param !== '' && this.props.urlFilters !== 'false') {
    //   property.main_type = main_type_param;
    // }
    if (BusinessOffer !== "") {
      property.business_offer = BusinessOffer;
    }

    if (Province !== "") {
      property.province = Province;
    }
    if (Area !== "") {
      property.city = Area;
    }
    if (Area !== "") {
      property.area = Area;
    }

    var searchUrl = [];

    if (property.business_offer !== undefined) {
      searchUrl.push(
        `business_offer_id=${generalBusinessOffer.findIndex(
          (x) => x === property.business_offer
        )}`
      );
    }

    await this.setState({ searchUrl1: searchUrl.join("&") });
    if (property.type !== undefined) {
      searchUrl.push(
        `type_id=${generalTypesItems.findIndex((x) => x === property.type)}`
      );
    }
    await this.setState({ searchUrl2: searchUrl.join("&") });
    if (property.province !== undefined) {
      searchUrl.push(
        `province_id=${generalProvinces.findIndex(
          (x) => x === property.province
        )}`
      );
    }
    await this.setState({ searchUrl3: searchUrl.join("&") });
    if (property.area !== undefined) {
      if (property.province === "دمشق") {
        searchUrl.push(
          `district_id=${syriaAreas.damascusAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === "ريف دمشق") {
        searchUrl.push(
          `district_id=${syriaAreas.rifDamascusAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === "حلب") {
        searchUrl.push(
          `district_id=${syriaAreas.aleppoAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === "طرطوس") {
        searchUrl.push(
          `district_id=${syriaAreas.tartousAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === "اللاذقية") {
        searchUrl.push(
          `district_id=${syriaAreas.latakiaAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === "حمص") {
        searchUrl.push(
          `district_id=${syriaAreas.homsAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === "حماة") {
        searchUrl.push(
          `district_id=${syriaAreas.hamaAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === "السويداء") {
        searchUrl.push(
          `district_id=${syriaAreas.asuwaydaAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === "الحسكة") {
        searchUrl.push(
          `district_id=${syriaAreas.hasakaAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === "درعا") {
        searchUrl.push(
          `district_id=${syriaAreas.daraaAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === "ادلب") {
        searchUrl.push(
          `district_id=${syriaAreas.idlibAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === "دير الزور") {
        searchUrl.push(
          `district_id=${syriaAreas.deirezorAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === "القنيطرة") {
        searchUrl.push(
          `district_id=${syriaAreas.quneitraAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === "الرقة") {
        searchUrl.push(
          `district_id=${syriaAreas.raqqaAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      }
    }
    await this.setState({ searchUrl4: searchUrl.join("&") });
    // if (property.main_type !== undefined) {
    //   searchUrl.push(`main_type=${property.main_type}`);
    // }

    // var normalUrl = await removeParam('main_type', `?${searchUrl.join('&')}`);
    // await this.setState({
    //   normalUrl
    // });
    await this.setState({ searchUrl: searchUrl.join("&") });
  };
  onSearchButtonSubmit = async (url, e) => {
    e.preventDefault();
    await this.buildSearchUrl();
    window.location = url;
  };

  handleSizeClick = (e) => {
    e.preventDefault();
    this.setState({ searchBarFullShow: !this.state.searchBarFullShow });
  };

  removeSearchQuery = async () => {
    localStorage.removeItem("ikar_main_type_param");
    await this.setState({ main_type_param: "" });
  };
  formatMoney = (amount, decimalCount = 0, decimal = ".", thousands = ",") => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 0 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString();
      let j = i.length > 3 ? i.length % 3 : 0;

      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
        (decimalCount
          ? decimal +
            Math.abs(amount - i)
              .toFixed(decimalCount)
              .slice(2)
          : "")
      );
    } catch (e) {}
  };

  render() {
    const {
      type,

      province,
      area,

      business_offer,
    } = this.props;
    return (
      <React.Fragment>
        <div className="text-right my-1 d-inline">
          {business_offer !== "نوع العرض" ? (
            <span className="search-category">
              {!this.props.disabled ? (
                <a
                  href={`${linkDomain}/${this.state.destination}?${this.state.searchUrl1}`}
                  className="px-1 search-category-link"
                  style={{ width: "200px" }}
                >
                  {business_offer}
                </a>
              ) : (
                <span>{business_offer}</span>
              )}{" "}
              >{" "}
            </span>
          ) : null}
          {type !== "نوع العقار" ? (
            <span className="search-category">
              {!this.props.disabled ? (
                <a
                  href={`${linkDomain}/${this.state.destination}?${this.state.searchUrl2}`}
                  className="px-1 search-category-link"
                  style={{ width: "200px" }}
                >
                  {type}
                </a>
              ) : (
                <span>{type}</span>
              )}{" "}
              >{" "}
            </span>
          ) : null}

          {province !== "المحافظة" ? (
            <span className="search-category">
              {!this.props.disabled ? (
                <a
                  href={`${linkDomain}/${this.state.destination}?${this.state.searchUrl3}`}
                  className="px-1 search-category-link"
                  style={{ width: "200px" }}
                >
                  {province}
                </a>
              ) : (
                <span>{province}</span>
              )}{" "}
              >{" "}
            </span>
          ) : null}
          {area !== "المنطقة" ? (
            <span className="search-category">
              {!this.props.disabled ? (
                <a
                  href={`${linkDomain}/${this.state.destination}?${this.state.searchUrl4}`}
                  className="px-1 search-category-link"
                  style={{ width: "200px" }}
                >
                  {area}
                </a>
              ) : (
                <span>{area}</span>
              )}{" "}
            </span>
          ) : null}
        </div>
      </React.Fragment>
    );
  }
}

SearchCategories.propTypes = {
  doSearch: PropTypes.func.isRequired,
  searchParams: PropTypes.object.isRequired,
  isTakingAgencyRole: PropTypes.bool.isRequired,
  //takenAgencyRoleToken: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  searchParams: state.search.searchParams,
  // short_id6: state.search.short_id6,
  isTakingAgencyRole: state.auth.isTakingAgencyRole,
  takenAgencyRoleToken: state.auth.takenAgencyRoleToken,
  takenAgencyRoleAgencyRefId: state.auth.takenAgencyRoleAgencyRefId,
});

export default compose(
  withRouter,
  connect(mapStateToProps, { doSearch })
)(SearchCategories);
