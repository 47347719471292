import { ADD_FAQ, GET_FAQ, GET_FAQS, DELETE_FAQ } from '../actions/types';
import shortid from 'shortid';
const initialState = {
  faqs: [],
  faq: {},
  requestFailed: false,
  requestFailedMessage: {},
  requestSuccessMessage: '',
  deleteSuccessMessage: '',
  requestMessage: '',
  resType: 'ready',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ADD_FAQ:
      if (
        Object.keys(action.payload).length > 0 &&
        (action.payload.errorMessage === '' ||
          action.payload.errorMessage === undefined)
      ) {
        if (action.payload.error === undefined) {
          return {
            //...state,
            faq: action.payload,
            requestFailed: false,
            requestFailedMessage: { error: 'no' },
            //add state
            faqs: [],
            requestSuccessMessage: 'add is succeeded',
            resType: action.payload.resType,
            short_id: shortid.generate(),
          };
        } else {
          return {
            //...state,
            faq: {},
            requestFailed: true,
            requestFailedMessage: action.payload,
            //add state
            faqs: [],
            requestSuccessMessage: 'error in add question',
            resType: action.payload.resType,
            short_id: shortid.generate(),
          };
        }
      } else {
        return {
          //...state,
          faq: {},
          requestFailed: true,
          requestFailedMessage: action.payload,
          //add state
          faqs: [],
          requestSuccessMessage: 'error in add question',
          resType: action.payload.resType,
        };
      }

    case GET_FAQ:
      if (
        action.payload.length > 0 &&
        (action.payload.errorMessage === '' ||
          action.payload.errorMessage === undefined)
      ) {
        action.payload.processStatus = 'get question';
        return {
          //...state,
          faq: action.payload[0],
          //add state data
          faqs: [],
          requestFailed: false,
          requestFailedMessage: {},
          requestMessage: 'get faq ready',
          resType: action.payload.resType,
          //
        };
      } else {
        return {
          //...state,
          faq: {},
          requestFailed: true,
          requestFailedMessage: action.payload,
          //add state data
          faqs: [],
          requestSuccessMessage: '',
          requestMessage: '',
          resType: action.payload.resType,
          //
        };
      }

    case GET_FAQS:
      if (
        Object.keys(action.payload).length > 0 &&
        (action.payload.errorMessage === '' ||
          action.payload.errorMessage === undefined)
      ) {
        action.payload.processStatus = 'get questions';
        return {
          //...state,
          faq: {},
          //add state data
          faqs: action.payload,
          requestFailed: false,
          requestFailedMessage: {},
          requestSuccessMessage: '',
          resType: action.payload.resType,
          //
        };
      } else {
        return {
          //...state,
          faq: {},
          requestFailed: true,
          requestFailedMessage: action.payload,
          //add state data
          faqs: [],
          requestSuccessMessage: '',
          resType: action.payload.resType,
          //
        };
      }

    case DELETE_FAQ:
      if (
        Object.keys(action.payload).length > 0 &&
        (action.payload.errorMessage === '' ||
          action.payload.errorMessage === undefined)
      ) {
        return {
          faqs: [],
          faq: {},
          requestFailed: false,
          requestFailedMessage: {},
          deleteSuccessMessage: action.payload.message,
          short_id: shortid.generate(),
        };
      } else {
        return {
          faqs: [],
          faq: {},
          requestFailed: true,
          requestFailedMessage: action.payload,
          deleteSuccessMessage: '',
        };
      }
    default:
      return state;
  }
}
