import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';
import { addProperty, addPropertyPhoto } from './../../actions/propertyActions';
import classnames from 'classnames';
import { Checkbox } from 'primereact/checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToggleButton } from 'primereact/togglebutton';
import { Dialog } from 'primereact/dialog';
// import { Button } from 'primereact/button';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import { Calendar } from 'primereact/calendar';
import shortid from 'shortid';
import { mapsEnabled, titleLength } from './../../mainConf';
// import InputMask from 'react-input-mask';
import {
  syriaAreas,
  generalStatus,
  generalTypes,
  generalTypeOfOwnership,
  generalBusinessOffer,
  maping
} from './../../mainConf';
import base64toblob from 'base64toblob';
// import { relative } from 'path';
import photoMagician from 'photo-magician';
import waterMark from './../../assets/images/logo.png';
import imageFileToBase64 from 'image-file-to-base64-exif';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { Growl } from 'primereact/growl';
import ShowProperty from './ShowProperty';
import { noInternetMessage } from './../../mainConf';
import { Helmet } from 'react-helmet';
class AddProperty extends Component {
  constructor() {
    super();
    this.state = {
      pageTitle: '',
      position: [34.890596, 35.881085],
      showCurrentLocation: false,
      pageDescription: '',
      imageResType: '',
      uploadedImages: [],
      uploadNewImages: false,
      compressNewImages: false,
      directory: '',
      firstImagesUploaded: false,
      requestMessage: '',
      fullSelectedFilesArray: [],
      fullSelectedFilesArrayNextAll: [],
      firstSelectedFile: [],
      photo_data: [],
      photo_name: [],

      imagesFull: false,
      distanceInPixel: 10,
      scale: 100,
      quality: 82,
      property: {},
      processScreen: false,
      photoIndex: 0,
      isOpen: false,
      IsOpenSubmit: false,

      showMessages: false,
      mortgage: false,
      areas: [],
      IsMM: false,
      IsGarden: false,
      IsRoof: false,
      IsLookingFor: false,
      IsBuildDate: false,
      IsReconstructed: false,
      IsUsingGPS: false,
      IsField: false,
      IsFarm: false,
      IsUnderGround: false,
      IsForAnimal: false,
      IsGasStation: false,
      IsFirstFloor: true,
      IsFullBuilding: false,
      IsOffice: false,
      IsVilla: false,
      IsGarage: false,
      IsShop: false,
      IsStoreFactory: false,
      IsRestaurant: false,
      IsHotel: false,
      showMap: false,
      maplays: null,
      hasLocation: false,
      lat: 34.890596,
      lng: 35.881085,
      latNew: '',
      lngNew: '',
      zoom: 18,
      propertyPosition: null,
      title: '',
      underConstruction: false,
      type: 'شقة', //flat
      forRent: false,
      forSell: true,
      rank: 10,
      description: '',
      reference_id: '',
      city: '',
      province: 'غير محدد',
      area: 'غير محدد',
      street: '',
      gps_location: '', //"105 , 104"
      bedrooms: 0,
      bathrooms: 0,
      toilets: 0,
      garage: false,
      basement: false,
      parking: false,
      balcony: 0,
      view_direction: 'غير محدد',
      floor: -100,
      duplex: false,
      total_area: '',
      living_area: '',
      living_rooms: 0,
      ground_area: '',
      roof_building_area: '',
      price_upon_review: false,
      //price_upon_order: false,
      rented: false,
      rent_start: null,
      rent_end: null,
      pricing_type: 'ل.س',
      lift: false,
      furnitured: false,
      type_of_ownership: 'طابو أخضر- 2400 سهم', //"private"
      price: '',
      business_status: 'جديد', //new
      rent_plan_id: 1,
      sell_plan_id: 1,
      visible: true,
      built_at: null, //dateTime "thu jul 07 12:20:15 +0300 2017"
      reconstructed_at: null, //dateTime "thu jul 07 12:20:15 +0300 2018"
      status: 'جاهز', //"ready"
      price_per_day: '0',
      price_per_mm: '0',
      business_offer: 'بيع', //"rent"
      views: 0,
      created_at: null, //dateTime "thu jul 07 12:20:15 +0300 2018"
      // photo_uploaded_name: [],
      showImages: false,
      errors: {},
      propertyO: {},
    };
  }
  getCurrentLocation = async () => {
    const currentLocation = window.navigator && window.navigator.geolocation;
    if (currentLocation) {
      await currentLocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          const position2 = [lat, lng];
          this.setState({
            lat,
            lng,
            position: position2,
          });
        },
        (error) => {
          this.setState({
            lat: 34.890596,
            lng: 35.881085,
          });
        }
      );
    }
  };
  onMapClick = async (e) => {
    const lat = e.latlng.lat;
    const lng = e.latlng.lng;
    const gps_location = `${lat},${lng}`;
    const position = [e.latlng.lat, e.latlng.lng];
    await this.setState({
      propertyPosition: e.latlng,
      gps_location,
      IsUsingGPS: true,
      position,
    });
  };
  onDeleteImage = (name, e) => {
    var selectedFileArray = [];
    const fullSelectedFilesArray = [];
    for (var i = 0; i < this.state.fullSelectedFilesArray.length; i++) {
      if (this.state.fullSelectedFilesArray[i][0] !== name) {
        selectedFileArray = [];
        selectedFileArray.push(
          this.state.fullSelectedFilesArray[i][0],
          this.state.fullSelectedFilesArray[i][1],
          this.state.fullSelectedFilesArray[i][2],
          this.state.fullSelectedFilesArray[i][3],
          this.state.fullSelectedFilesArray[i][4],
          this.state.fullSelectedFilesArray[i][5]
        );
        fullSelectedFilesArray.push(selectedFileArray);
      }
    }
    var uploadedImagesNames = [];
    for (var j = 0; j < this.state.uploadedImages.length; j++) {
      if (this.state.uploadedImages[j] !== name) {
        // names = [];
        uploadedImagesNames.push(this.state.uploadedImages[j]);
      }
    }

    var Base64Array = [];
    var Base64ArrayStr = '';
    var NamesArray = [];
    for (var ii = 0; ii < fullSelectedFilesArray.length; ii++) {
      Base64ArrayStr = fullSelectedFilesArray[ii][2].base64;
      Base64Array.push(Base64ArrayStr);
      // NamesArray.push(fullSelectedFilesArray[ii][2].name);
      NamesArray.push(fullSelectedFilesArray[ii][4] + '.jpeg');
    }

    var firstSelectedFile = [];
    var fullSelectedFilesArrayNextAll = [];
    if (this.state.firstSelectedFile.length === 0) {
      firstSelectedFile.push(fullSelectedFilesArray[0][0]);
      for (var n = 1; n < fullSelectedFilesArray.length; n++) {
        fullSelectedFilesArrayNextAll.push(fullSelectedFilesArray[n][0]);
      }
    } else {
      firstSelectedFile = this.state.firstSelectedFile;
      for (var nn = 0; nn < fullSelectedFilesArray.length; nn++) {
        if (fullSelectedFilesArray[nn][0] !== this.state.firstSelectedFile[0]) {
          fullSelectedFilesArrayNextAll.push(fullSelectedFilesArray[nn][0]);
        }
      }
    }

    this.setState({
      firstSelectedFile,
      fullSelectedFilesArray,
      fullSelectedFilesArrayNextAll,
      uploadedImages: uploadedImagesNames,
      photo_data: Base64Array,
      photo_name: NamesArray,
    });
    if (document.getElementById('upload_images') !== null) {
      document.getElementById('upload_images').value = '';
    }
  };

  onAddSubmit = async (str, e) => {
    e.preventDefault();
    await this.setState({
      requestMessage: 'ready',
    });
    const {
      // requestMessage,
      // areas,
      // fullSelectedFilesArray,
      // processScreen,
      IsGarden,
      // IsField,
      // IsFarm,
      // IsFullBuilding,
      // IsOffice,
      // IsVilla,
      // IsGarage,
      // IsShop,
      // IsStoreFactory,
      // IsRestaurant,
      // IsHotel,
      // IsForAnimal,
      // IsGasStation,
      // IsUnderGround,
      IsRoof,
      // IsLookingFor,
      IsMM,
      // IsOpenSubmit,
      // IsUsingGPS,
      forRent,
      forSell,
      IsBuildDate,
      IsReconstructed,
      // showMap,
      // initLocation,
      // maplays,
      // propertyPosition,
      title,
      type,
      rank,
      description,
      // reference_id,
      // city,
      province,
      area,
      street,
      gps_location,
      bedrooms,
      bathrooms,
      // toilets,
      garage,
      basement,
      parking,
      balcony,
      view_direction,
      floor,
      duplex,
      total_area,
      living_area,
      living_rooms,
      ground_area,
      roof_building_area,
      price_upon_review,
      //price_upon_order,
      rented,
      rent_start,
      rent_end,
      pricing_type,
      lift,
      furnitured,
      type_of_ownership,
      price,
      business_status,
      rent_plan_id,
      sell_plan_id,
      visible,
      built_at,
      reconstructed_at,
      status,
      // price_per_day,
      // price_per_mm,
      business_offer,
      views,
      // created_at,
      photo_data,
      directory,
      photo_name,
      // propertyO
    } = this.state;
    this.setState({
      showMessages: false,
      errors: {},
    });
    var errors = {};
    if (title.trim() === '') {
      errors.title = 'يجب ادخال العنوان';
    }
    if (title.length > titleLength) {
      errors.title = `يجب الا يتجاوز العنوان المدخل ${titleLength} حرف`;
    }
    if (type === 'غير محدد') {
      errors.type = 'يجب تحديد نوع العقار';
    }
    if (business_offer === 'غير محدد') {
      errors.business_offer = 'يجب تحديد نوع العرض';
    }
    if (province === 'غير محدد' || province === '') {
      errors.province = 'يجب ادخال المحافظة';
    }

    // if (area === 'غير محدد' || area === '') {
    //   errors.area = 'يجب ادخال المنطقة';
    // }
    var area1 = area;
    var gps_location1 = gps_location;
    if (area === 'غير محدد' || area === '') {
      area1 = null;
      gps_location1 = '';
    }
    // if (type_of_ownership === 'غير محدد') {
    //   errors.type_of_ownership = 'يجب تحديد نوع الملكية';
    // }
    if (description.trim() === '') {
      errors.description = 'يجب ادخال وصف العقار';
    }
    // if (parseInt(bedrooms) === 0) {
    //   errors.bedrooms = 'يجب ادخال عدد غرف النوم';
    // }
    // if (parseInt(living_rooms) === 0) {
    //   errors.living_rooms = 'يجب ادخال عدد غرف الصالون';
    // }
    // if (parseInt(toilets) === 0) {
    //   errors.toilets = 'يجب ادخال عدد غرف التواليت';
    // }
    // if (parseInt(bathrooms) === 0) {
    //   errors.bathrooms = 'يجب ادخال عدد غرف الاستحمام';
    // }
    // if (parseInt(floor) === -100) {
    //   errors.floor = 'يجب تحديد رقم الطابق';
    // }
    // if (view_direction === 'غير محدد') {
    //   errors.view_direction = 'يجب تحديد اتجاه العقار';
    // }
    // if (IsMM) {
    //   if (price_per_mm === 0 || price_per_mm === '') {
    //     errors.price_per_mm = 'يجب ادخال سعر المتر المربع';
    //   }
    // }

    if (total_area !== '' && !this.isNumeric(total_area)) {
      errors.total_area = 'يجب التأكد من المساحة الكلية للعقار';
    }

    if (living_area !== '' && !this.isNumeric(living_area)) {
      errors.living_area = 'يجب التأكد من مساحة العقار';
    }

    if (IsGarden && ground_area !== '' && !this.isNumeric(ground_area)) {
      errors.ground_area = 'يجب التأكد من مساحة الحديقة';
    }
    if (IsGarden && ground_area === '') {
      errors.ground_area = 'يجب التأكد من مساحة الحديقة';
    }

    if (IsRoof) {
      // if(roof_building_area=== 0 && roof_building_area===''){
      //   errors.ground_area = 'يجب ادخال المساحة الصالحة للبناء';
      // }
      // if(roof_building_area=== 0 && roof_building_area===''){
      //   errors.total_area = 'يجب ادخال المساحة الكلية';
      // }
      if (roof_building_area !== '' && !this.isNumeric(roof_building_area)) {
        errors.roof_building_area = 'التحقق من مساحة السطح المعد للبناء';
      }

      if (
        roof_building_area !== '' &&
        total_area !== '' &&
        this.isNumeric(roof_building_area) &&
        this.isNumeric(total_area) &&
        parseInt(roof_building_area) > parseInt(total_area)
      ) {
        errors.roof_building_area = 'التحقق من مساحة السطح المعد للبناء';
      }
    }
    if (forRent && !price_upon_review) {
      if (price !== '' && !this.isNumeric(price)) {
        errors.price = 'يجب التأكد من مبلغ إيجار العقار';
      }
      if (parseInt(price) === 0 || price === '') {
        errors.price = 'يجب ادخال مبلغ إيجار العقار';
      }
    }
    if (forSell && !price_upon_review) {
      if (price !== '' && !this.isNumeric(price)) {
        errors.price = 'يجب التأكد من سعر العقار';
      }
      if (parseInt(price) === 0 || price === '') {
        errors.price = 'يجب ادخال سعر العقار';
      }
    }

    if (forRent && rented && rent_end === null) {
      errors.rent_end = 'يجب ادخال تاريخ نهاية عقد الايجار';
    }
    var today = new Date();
    if (forRent && rented && rent_end !== null) {
      if (rent_end < today) {
        errors.rent_end =
          'لا يمكن ادخال تاريخ نهاية عقد الايجار اقدم من تاريخ اليوم';
      }
    }

    if (forRent && rented && rent_end !== null && rent_start !== null) {
      if (rent_end < rent_start) {
        errors.rent_end =
          'لا يمكن ادخال تاريخ نهاية عقد الايجار اقدم من تاريخ بداية العقد';
      }
    }

    if (status === 'غير محدد') {
      errors.status = 'يجب ادخال حالة البناء';
    }

    if (IsBuildDate && built_at === null) {
      errors.built_at = 'يجب ادخال تاريخ البناء';
    }

    if (IsReconstructed && reconstructed_at === null) {
      errors.reconstructed_at = 'يجب ادخال تاريخ اعادة البناء';
    }

    if (IsBuildDate) {
      if (built_at !== null && built_at > today) {
        errors.built_at = 'لا يمكن ادخال تاريخ البناء احدث من تاريخ اليوم';
      }
    }

    if (IsReconstructed) {
      if (reconstructed_at !== null && reconstructed_at > today) {
        errors.reconstructed_at =
          'لا يمكن ادخال تاريخ اعادة البناء احدث من تاريخ اليوم';
      }
    }
    if (IsReconstructed) {
      if (
        reconstructed_at !== null &&
        reconstructed_at !== null &&
        reconstructed_at < built_at
      ) {
        errors.reconstructed_at =
          'لا يمكن ادخال تاريخ اعادة بناء قبل تاريخ البناء';
      }
    }
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }
    var shortId = shortid.generate();
    var referenceId = shortId;
    var groundArea = 0;
    if (IsGarden && (ground_area === '' || parseInt(ground_area) === 0)) {
      groundArea = 0;
    } else {
      groundArea = this.state.ground_area;
    }
    if (!IsGarden) {
      groundArea = -1;
    }
    var pricePerMm = 0;
    if (IsMM) {
      pricePerMm = this.state.price_per_mm;
    }
    var pricePerDay = 0;
    if (forRent) {
      pricePerDay = this.state.price_per_day;
    }

    var builtAt = null;
    //var builtAt = '2075-08-25';
    if (IsBuildDate && this.state.built_at !== null) {
      builtAt = document.querySelector('.builtAtCalendar .p-inputtext').value;
    }
    var reconstructedAt = null;
    if (IsReconstructed && this.state.reconstructed_at !== null) {
      reconstructedAt = document.querySelector(
        '.ReconstructedAtCalendar .p-inputtext'
      ).value;
    }

    var rentStartAt = null;
    if (forRent && rented && this.state.rent_start !== null) {
      rentStartAt = document.querySelector(
        '.rent_start_at_calendar .p-inputtext'
      ).value;
    }

    var rentEndAt = null;
    if (forRent && rented && this.state.rent_end !== null) {
      rentEndAt = document.querySelector('.rent_end_at_calendar .p-inputtext')
        .value;
    }

    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0');
    var yyyy = today.getFullYear();

    today = yyyy + '-' + mm + '-' + dd;

    // var createdAt = today;
    const property = {
      title,
      type,
      rank,
      description,
      reference_id: referenceId,
      city: area1,
      province,
      area: area1,
      street,
      gps_location: gps_location1,
      bedrooms: parseInt(bedrooms),
      bathrooms: parseInt(bathrooms),
      // toilets: parseInt(toilets),
      garage,
      basement,
      parking,
      balcony: parseInt(balcony),
      view_direction: view_direction === 'غير محدد' ? null : view_direction,
      // floor: parseInt(floor) === -100 ? null : parseInt(floor),
      floor: parseInt(floor),
      duplex,
      total_area: total_area === '' ? 0 : total_area,
      living_area: living_area === '' ? 0 : living_area,
      living_rooms: parseInt(living_rooms),
      ground_area: groundArea === '' ? 0 : groundArea,
      roof_building_area: roof_building_area === '' ? 0 : roof_building_area,
      price_upon_review,
      //price_upon_order,
      rented,
      rent_start: rentStartAt,
      rent_end: rentEndAt,
      pricing_type,
      lift,
      furnitured,
      type_of_ownership,
      price: price === '' ? 0 : price,
      business_status,
      rent_plan_id,
      sell_plan_id,
      visible,
      built_at: builtAt,
      reconstructed_at: reconstructedAt,
      status,
      price_per_day: pricePerDay,
      price_per_mm: pricePerMm,
      business_offer,
      // created_at: createdAt,
      views,
      directory: directory !== '' ? directory : null,
      // photo_data:,
      names: photo_name !== [] ? photo_name : null,
    };
    const property2 = {
      title,
      type,
      rank,
      description,
      reference_id: referenceId,
      city: area,
      province,
      area,
      street,
      gps_location,
      bedrooms: parseInt(bedrooms),
      bathrooms: parseInt(bathrooms),
      // toilets: parseInt(toilets),
      garage,
      basement,
      parking,
      balcony: parseInt(balcony),
      view_direction: view_direction === 'غير محدد' ? null : view_direction,
      // floor: parseInt(floor) === -100 ? null : parseInt(floor),
      floor: parseInt(floor),
      duplex,
      total_area: total_area === '' ? 0 : total_area,
      living_area: living_area === '' ? 0 : living_area,
      living_rooms: parseInt(living_rooms),
      ground_area: groundArea === '' ? 0 : groundArea,
      roof_building_area: roof_building_area === '' ? 0 : roof_building_area,
      price_upon_review,
      //price_upon_order,
      rented,
      rent_start: rentStartAt,
      rent_end: rentEndAt,
      pricing_type,
      lift,
      furnitured,
      type_of_ownership,
      price: price === '' ? 0 : price,
      business_status,
      rent_plan_id,
      sell_plan_id,
      visible,
      built_at: builtAt,
      reconstructed_at: reconstructedAt,
      status,
      price_per_day: pricePerDay,
      price_per_mm: pricePerMm,
      business_offer,
      // created_at: createdAt,
      views,
      directory,
      photo_data,
      photo_name,
    };
    // const property = {
    //   title: title === '' ? null : title,
    //   type: type === '' ? null : type,
    //   rank: rank === '' ? null : rank,
    //   description: description === '' ? null : description,
    //   reference_id: referenceId,
    //   city: area === '' ? null : area,
    //   province: province === '' ? null : province,
    //   area: area === '' ? null : area,
    //   street: street === '' ? null : street,
    //   gps_location: gps_location === '' ? null : gps_location,
    //   bedrooms: bedrooms === '' ? null : bedrooms,
    //   bathrooms: bathrooms === '' ? null : bathrooms,
    //   toilets: toilets === '' ? null : toilets,
    //   garage: garage === '' ? null : garage,
    //   basement: basement === '' ? null : basement,
    //   parking: parking === '' ? null : parking,
    //   balcony: balcony === '' ? null : balcony,
    //   view_direction: view_direction === '' ? null : view_direction,
    //   floor: floor === '' ? null : floor,
    //   duplex: duplex === '' ? null : duplex,
    //   total_area: total_area === '' ? null : total_area,
    //   living_area: living_area === '' ? null : living_area,
    //   ground_area: groundArea === '' ? null : groundArea,
    //   roof_building_area: roof_building_area === '' ? null : roof_building_area,
    //   price_upon_review: price_upon_review === '' ? null : price_upon_review,
    //   //price_upon_order,
    //   living_rooms: 0,
    //   rented: rented === '' ? null : rented,
    //   //rent_start: rentStartAt === '' ? null : rentStartAt,
    //   //rent_end: rentEndAt === '' ? null : rentEndAt,
    //   pricing_type: pricing_type === '' ? null : pricing_type,
    //   lift: lift === '' ? null : lift,
    //   furnitured: furnitured === '' ? null : furnitured,
    //   type_of_ownership: type_of_ownership === '' ? null : type_of_ownership,
    //   price: price === '' ? null : price,
    //   business_status: business_status === '' ? null : business_status,
    //   rent_plan_id: rent_plan_id === '' ? null : rent_plan_id,
    //   sell_plan_id: sell_plan_id === '' ? null : sell_plan_id,
    //   visible: visible === '' ? null : visible,
    //   //built_at: builtAt === '' ? null : builtAt,
    //   //reconstructed_at: reconstructedAt === '' ? null : reconstructedAt,
    //   status: status === '' ? null : status,
    //   price_per_day: pricePerDay === '' ? null : pricePerDay,
    //   price_per_mm: pricePerMm === '' ? null : pricePerMm,
    //   business_offer: business_offer === '' ? null : business_offer,
    //   //created_at: createdAt === '' ? null : createdAt,
    //   views: views === '' ? null : views,
    //   photo_data: photo_data === '' ? null : photo_data,
    //   photo_name: photo_name === '' ? null : photo_name
    // };
    const newProperty = { property };

    const isTakingAgencyRole = this.props.isTakingAgencyRole;
    const takenAgencyRoleToken = this.props.takenAgencyRoleToken;
    if (str === 'submit') {
      this.setState({ propertyO: property2, IsOpenSubmit: true });
      return;
    }
    // if (str === 'show') {
    // }

    if (str === 'add') {
      this.props.addProperty(
        newProperty,
        isTakingAgencyRole,
        takenAgencyRoleToken
      );
      if (this.state.fullSelectedFilesArray.length === 0) {
        this.setState({ imagesFull: false });
      } else {
        this.setState({ imagesFull: true });
      }
      this.setState({
        //requestMessage: '',
        imageResType: '',
        showCurrentLocation: false,
        uploadedImages: [],
        uploadNewImages: false,
        compressNewImages: false,
        directory: '',
        firstImagesUploaded: false,
        processScreen: true,
        showMessages: true,
        distanceInPixel: 10,
        scale: 100,

        quality: 82,
        property: {},
        photoIndex: 0,
        isOpen: false,
        IsOpenSubmit: false,
        fullSelectedFilesArray: [],
        fullSelectedFilesArrayNextAll: [],
        firstSelectedFile: [],
        areas: [],
        IsMM: false,
        IsGarden: false,
        IsRoof: false,
        IsLookingFor: false,
        IsBuildDate: false,
        IsReconstructed: false,
        IsUsingGPS: false,
        IsField: false,
        IsFarm: false,
        IsUnderGround: false,
        IsForAnimal: false,
        IsGasStation: false,
        IsFirstFloor: true,
        IsFullBuilding: false,
        IsOffice: false,
        IsVilla: false,
        IsGarage: false,
        IsShop: false,
        IsStoreFactory: false,
        IsRestaurant: false,
        IsHotel: false,
        showMap: false,
        maplays: null,
        hasLocation: false,
        lat: 34.890596,
        lng: 35.881085,
        zoom: 18,
        propertyPosition: null,
        title: '',
        type: 'شقة', //flat
        forRent: false,
        forSell: true,
        rank: 10,
        description: '',
        reference_id: '',
        city: '',
        province: 'غير محدد',
        area: 'غير محدد',
        street: '',
        gps_location: '', //"105 , 104"
        bedrooms: 0,
        bathrooms: 0,
        toilets: 0,
        garage: false,
        basement: false,
        parking: false,
        balcony: 0,
        view_direction: 'غير محدد',
        floor: -100,
        duplex: false,
        total_area: '',
        living_area: '',
        living_rooms: 0,
        ground_area: '',
        roof_building_area: '',
        price_upon_review: false,
        //price_upon_order: false,
        rented: false,
        rent_start: null,
        rent_end: null,
        pricing_type: 'ل.س',
        lift: false,
        furnitured: false,
        type_of_ownership: 'طابو أخضر- 2400 سهم', //"private"
        price: '',
        business_status: 'جديد', //new
        rent_plan_id: 1,
        sell_plan_id: 1,
        visible: true,
        built_at: null, //dateTime "thu jul 07 12:20:15 +0300 2017"
        reconstructed_at: null, //dateTime "thu jul 07 12:20:15 +0300 2018"
        status: 'جاهز', //"ready"
        price_per_day: '0',
        price_per_mm: '0',
        business_offer: 'بيع', //"rent"
        views: 0,
        created_at: null, //dateTime "thu jul 07 12:20:15 +0300 2018"
        photo_data: [],
        photo_name: [],
        // photo_uploaded_name: [],
        showImages: false,
        errors: {},
        propertyO: {},
      });
      if (document.getElementById('upload_images') !== null) {
        document.getElementById('upload_images').value = '';
      }
      Array.from(
        document.getElementsByClassName('p-button-text-icon-left')
      ).forEach(function (element, index, array) {
        if (element.textContent === 'حذف كافة صور العقار') {
          element.click();
        }
      });
    }
  };
  isNumeric = (num) => {
    // return !isNaN(num);
    // return /^-{0,1}\d+$/.test(num);
    return /^[0-9]\d*(((,\d{3}){1})?(\.\d{0,2})?)$/.test(num);
  };

  onProvinceAreaChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.value !== 'غير محدد') {
      const lat = maping[e.target.value][0];
      const lng = maping[e.target.value][1];

      // const gps_location = `${lat},${lng}`;
      const position = [lat, lng];
      this.setState({
        propertyPosition: null,
        gps_location: '',
        IsUsingGPS: false,
        position,
      });
    }
    else {
      this.setState({
        propertyPosition: null,
        gps_location: '',
        IsUsingGPS: false,
        position: [34.890596, 35.881085],
      });
    }

  }
  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  }
  onTitleChange = (e) => {
    if (e.target.value.length > titleLength) {
      return;
    }
    this.setState({ [e.target.name]: e.target.value });
  };
  onStatusChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.value === 'على المخطط') {
      this.setState({ underConstruction: true });
    } else {
      this.setState({ underConstruction: false });
    }
  };
  // onTypeChange = e => {
  //   if (e.target.value === 'سطح معد للبناء') {
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       IsRoof: true
  //     });
  //   } else {
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       IsRoof: false
  //     });
  //   }

  //   if (e.target.value === 'بناء كامل') {
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       IsFullBuilding: true
  //     });
  //   } else {
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       IsFullBuilding: false
  //     });
  //   }

  //   if (e.target.value === 'فندق') {
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       IsHotel: true
  //     });
  //   } else {
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       IsHotel: false
  //     });
  //   }

  //   if (e.target.value === 'فيلا') {
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       IsVilla: true
  //     });
  //   } else {
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       IsVilla: false
  //     });
  //   }
  //   if (e.target.value === 'مرآب') {
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       IsGarage: true
  //     });
  //   } else {
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       IsGarage: false
  //     });
  //   }
  //   if (
  //     e.target.value === 'محلات تجارية' ||
  //     e.target.value === 'محلات اصلاح' ||
  //     e.target.value === 'محلات لتربية الحيوانات'
  //   ) {
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       IsShop: true
  //     });
  //   } else {
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       IsShop: false
  //     });
  //   }
  //   if (e.target.value === 'مستودع' || e.target.value === 'منشأة تصنيع') {
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       IsStoreFactory: true
  //     });
  //   } else {
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       IsStoreFactory: false
  //     });
  //   }
  //   if (e.target.value === 'مطعم') {
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       IsRestaurant: true
  //     });
  //   } else {
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       IsRestaurant: false
  //     });
  //   }

  //   if (e.target.value === 'مكتب' || e.target.value === 'عيادة') {
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       IsOffice: true
  //     });
  //   } else {
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       IsOffice: false
  //     });
  //   }
  //   if (e.target.value === 'محطة وقود') {
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       IsGasStation: true
  //     });
  //   } else {
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       IsGasStation: false
  //     });
  //   }

  //   if (e.target.value === 'محلات لتربية الحيوانات') {
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       IsForAnimal: true
  //     });
  //   } else {
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       IsForAnimal: false
  //     });
  //   }
  //   if (e.target.value === 'قبو') {
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       IsUnderGround: true
  //     });
  //   } else {
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       IsUnderGround: false
  //     });
  //   }

  //   if (e.target.value === 'مزرعة') {
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       IsFarm: true
  //     });
  //   } else {
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       IsFarm: false
  //     });
  //   }

  //   if (
  //     e.target.value === 'أراضي زراعية' ||
  //     e.target.value === 'أرض للبناء' ||
  //     e.target.value === 'أراضي ذات رخصة سياحية' ||
  //     e.target.value === 'أراضي ذات رخصة صناعية' ||
  //     e.target.value === 'أراضي ذات رخصة صناعية'
  //   ) {
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       IsField: true
  //     });
  //   } else {
  //     this.setState({
  //       [e.target.name]: e.target.value,
  //       IsField: false
  //     });
  //   }
  // };
  onTypeChange = async (e) => {
    if (e.target.value !== 'سطح معد للبناء') {
      this.setState({ roof_building_area: '' });
    }
    if (e.target.value === 'سطح معد للبناء') {
      this.setState({
        [e.target.name]: e.target.value,
        IsRoof: true,
        living_rooms: 0,
        balcony: 0,
        bedrooms: 0,
        bathrooms: 0,
        toilets: 0,
        floor: -100,
        IsFirstFloor: true,
        view_direction: 'غير محدد',
        furnitured: false,
        duplex: false,
        living_area: '',
        ground_area: '',
        total_area: '',
        IsGarden: false,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        IsRoof: false,
      });
    }

    if (e.target.value === 'بناء كامل') {
      this.setState({
        [e.target.name]: e.target.value,
        IsFullBuilding: true,
        living_rooms: 0,
        balcony: 0,
        bedrooms: 0,
        bathrooms: 0,
        toilets: 0,
        floor: -100,
        IsFirstFloor: true,
        view_direction: 'غير محدد',
        duplex: false,
        roof_building_area: '',
        living_area: '',
        ground_area: '',
        total_area: '',
        IsGarden: false,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        IsFullBuilding: false,
      });
    }

    if (e.target.value === 'فندق') {
      this.setState({
        [e.target.name]: e.target.value,
        IsHotel: true,
        roof_building_area: '',
        living_rooms: 0,
        balcony: 0,
        bathrooms: 0,
        toilets: 0,
        floor: -100,
        IsFirstFloor: true,
        living_area: '',
        ground_area: '',
        total_area: '',
        IsGarden: false,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        IsHotel: false,
      });
    }

    if (e.target.value === 'فيلا') {
      this.setState({
        [e.target.name]: e.target.value,
        IsVilla: true,
        floor: -100,
        IsFirstFloor: true,
        roof_building_area: '',
        living_area: '',
        ground_area: '',
        total_area: '',
        IsGarden: false,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        IsVilla: false,
      });
    }
    if (e.target.value === 'مرآب' || e.target.value === 'صالة') {
      this.setState({
        [e.target.name]: e.target.value,
        IsGarage: true,
        roof_building_area: '',
        living_rooms: 0,
        balcony: 0,
        bedrooms: 0,
        bathrooms: 0,
        toilets: 0,
        view_direction: 'غير محدد',
        garage: false,
        basement: false,
        parking: false,
        duplex: false,
        lift: false,
        furnitured: false,
        ground_area: '',
        living_area: '',
        total_area: '',
        IsGarden: false,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        IsGarage: false,
      });
    }
    if (
      e.target.value === 'محلات تجارية' ||
      e.target.value === 'محلات اصلاح' ||
      e.target.value === 'محلات لتربية الحيوانات'
    ) {
      this.setState({
        [e.target.name]: e.target.value,
        IsShop: true,
        roof_building_area: '',
        living_rooms: 0,
        balcony: 0,
        bedrooms: 0,
        bathrooms: 0,
        toilets: 0,
        floor: -100,
        IsFirstFloor: true,
        view_direction: 'غير محدد',
        garage: false,
        basement: false,
        parking: false,
        duplex: false,
        lift: false,
        furnitured: false,
        ground_area: '',
        living_area: '',
        total_area: '',
        IsGarden: false,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        IsShop: false,
      });
    }
    if (e.target.value === 'مستودع' || e.target.value === 'منشأة تصنيع') {
      this.setState({
        [e.target.name]: e.target.value,
        IsStoreFactory: true,
        roof_building_area: '',
        living_rooms: 0,
        balcony: 0,
        bedrooms: 0,
        bathrooms: 0,
        toilets: 0,
        floor: -100,
        IsFirstFloor: true,
        view_direction: 'غير محدد',
        furnitured: false,
        duplex: false,
        ground_area: '',
        living_area: '',
        total_area: '',
        IsGarden: false,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        IsStoreFactory: false,
      });
    }
    if (e.target.value === 'مطعم' || e.target.value === 'مقهى') {
      this.setState({
        [e.target.name]: e.target.value,
        IsRestaurant: true,
        roof_building_area: '',
        living_rooms: 0,
        living_area: '',
        ground_area: '',
        total_area: '',
        IsGarden: false,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        IsRestaurant: false,
      });
    }

    if (e.target.value === 'مكتب' || e.target.value === 'عيادة') {
      this.setState({
        [e.target.name]: e.target.value,
        IsOffice: true,
        roof_building_area: '',
        ground_area: '',
        living_area: '',
        total_area: '',
        IsGarden: false,
        living_rooms: 0,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        IsOffice: false,
      });
    }
    if (e.target.value === 'محطة وقود') {
      this.setState({
        [e.target.name]: e.target.value,
        IsGasStation: true,
        roof_building_area: '',
        living_rooms: 0,
        balcony: 0,
        bedrooms: 0,
        bathrooms: 0,
        toilets: 0,
        floor: -100,
        IsFirstFloor: true,
        view_direction: 'غير محدد',
        garage: false,
        basement: false,
        parking: false,
        duplex: false,
        lift: false,
        furnitured: false,
        ground_area: '',
        living_area: '',
        total_area: '',
        IsGarden: false,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        IsGasStation: false,
      });
    }

    if (e.target.value === 'محلات لتربية الحيوانات') {
      this.setState({
        [e.target.name]: e.target.value,
        IsForAnimal: true,
        roof_building_area: '',
        living_rooms: 0,
        balcony: 0,
        bedrooms: 0,
        bathrooms: 0,
        toilets: 0,
        floor: -100,
        IsFirstFloor: true,
        view_direction: 'غير محدد',
        garage: false,
        basement: false,
        parking: false,
        duplex: false,
        lift: false,
        furnitured: false,
        ground_area: '',
        living_area: '',
        total_area: '',
        IsGarden: false,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        IsForAnimal: false,
      });
    }
    if (e.target.value === 'قبو') {
      this.setState({
        [e.target.name]: e.target.value,
        IsUnderGround: true,
        roof_building_area: '',
        ground_area: '',
        living_area: '',
        total_area: '',
        IsGarden: false,
        basement: false,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        IsUnderGround: false,
      });
    }

    if (e.target.value === 'مزرعة') {
      this.setState({
        [e.target.name]: e.target.value,
        IsFarm: true,
        roof_building_area: '',
        living_rooms: 0,
        balcony: 0,
        bedrooms: 0,
        bathrooms: 0,
        toilets: 0,
        floor: -100,
        IsFirstFloor: true,
        view_direction: 'غير محدد',
        garage: false,
        basement: false,
        parking: false,
        duplex: false,
        lift: false,
        furnitured: false,
        ground_area: '',
        living_area: '',
        total_area: '',
        IsGarden: false,
        status: 'جاهز',
        built_at: null,
        reconstructed_at: null,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        IsFarm: false,
      });
    }

    if (
      e.target.value === 'أراضي زراعية' ||
      e.target.value === 'أرض للبناء' ||
      e.target.value === 'أراضي ذات رخصة سياحية' ||
      e.target.value === 'أراضي ذات رخصة صناعية' ||
      e.target.value === 'أراضي ذات رخصة صناعية'
    ) {
      this.setState({
        [e.target.name]: e.target.value,
        IsField: true,
        roof_building_area: '',
        living_rooms: 0,
        balcony: 0,
        bedrooms: 0,
        bathrooms: 0,
        toilets: 0,
        floor: -100,
        IsFirstFloor: true,
        view_direction: 'غير محدد',
        garage: false,
        basement: false,
        parking: false,
        duplex: false,
        lift: false,
        furnitured: false,
        ground_area: '',
        living_area: '',
        total_area: '',
        IsGarden: false,
        status: 'جاهز',
        built_at: null,
        reconstructed_at: null,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        IsField: false,
      });
    }
    await this.setState({
      living_area: '',
      ground_area: '',
      total_area: '',
      IsGarden: false,
    });
    this.onAreaChange();
  };
  onBusinessOfferChange = (e) => {
    if (e.target.value === 'أبحث للإيجار') {
      this.setState({
        [e.target.name]: e.target.value,
        forRent: true,
        forSell: false,
        IsLookingFor: true,
        mortgage: false,
      });
      this.onDeleteImages();
    } else if (e.target.value === 'تأجير') {
      this.setState({
        [e.target.name]: e.target.value,
        forRent: true,
        forSell: false,
        IsLookingFor: false,
        mortgage: false,
      });
    } else if (e.target.value === 'أبحث للشراء') {
      this.setState({
        [e.target.name]: e.target.value,
        IsLookingFor: true,
        forRent: false,
        forSell: true,
        mortgage: false,
      });
      this.onDeleteImages();
    } else if (e.target.value === 'رهن') {
      this.setState({
        [e.target.name]: e.target.value,
        IsLookingFor: false,
        forRent: false,
        forSell: false,
        mortgage: true,
      });
    } else {
      this.setState({
        [e.target.name]: e.target.value,
        forRent: false,
        forSell: true,
        IsLookingFor: false,
        mortgage: false,
      });
    }
  };
  onCheckBoxChange = (e) =>
    this.setState({ [e.target.name]: e.target.checked });
  onAreaChange = (e) => {
    if (document.getElementById('livingArea') !== null) {
      let livingArea = document.getElementById('livingArea').value;
      let groundArea = 0;
      if (document.getElementById('groundArea') !== null) {
        groundArea = document.getElementById('groundArea').value;
      } else {
        groundArea = 0;
      }
      if (livingArea !== '' && !this.isNumeric(livingArea)) {
        return;
      }
      if (groundArea !== '' && !this.isNumeric(groundArea)) {
        return;
      }
      if (typeof livingArea !== 'number') {
        if (isNaN(parseFloat(livingArea))) {
          livingArea = 0;
        }
      }
      if (typeof groundArea !== 'number') {
        if (isNaN(parseFloat(groundArea))) {
          groundArea = 0;
        }
      }
      let totalArea = parseFloat(livingArea) + parseFloat(groundArea);
      // let pricePerMM = 0;
      // if (document.getElementById('pricePerMM') !== null) {
      //   pricePerMM = document.getElementById('pricePerMM').value;
      // }
      // if (pricePerMM !== '' && !this.isNumeric(pricePerMM)) {
      //   return;
      // }
      // if (typeof pricePerDay !== 'number') {
      //   if (isNaN(parseFloat(pricePerMM))) {
      //     pricePerMM = 0;
      //   }
      // }

      // let price = parseFloat(pricePerMM) * totalArea;
      this.setState({
        living_area: livingArea === 0 ? '' : livingArea,
        ground_area: groundArea === 0 ? '' : groundArea,
        total_area: totalArea === 0 ? '' : totalArea,
        // price_per_mm: pricePerMM === 0 ? '' : pricePerMM,
        // price: price === 0 ? '' : price,
      });
    }
  };
  onTotalAreaChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      roof_building_area: e.target.value,
    });
  };
  onPricePerDayChange = (e) => {
    let pricePerDay = document.getElementById('pricePerDay').value;
    if (pricePerDay !== '' && !this.isNumeric(pricePerDay)) {
      return;
    }
    if (typeof pricePerDay !== 'number') {
      if (isNaN(parseFloat(pricePerDay))) {
        pricePerDay = 0;
      }
    }

    let price = parseFloat(pricePerDay) * 30;
    this.setState({
      price_per_day: pricePerDay,
      price,
    });
  };
  onPricePerMMChange = () => {
    let pricePerMM = document.getElementById('pricePerMM').value;
    let totalArea = document.getElementById('totalArea').value;
    if (pricePerMM !== '' && !this.isNumeric(pricePerMM)) {
      return;
    }
    if (totalArea !== '' && !this.isNumeric(totalArea)) {
      return;
    }
    if (typeof pricePerDay !== 'number') {
      if (isNaN(parseFloat(pricePerMM))) {
        pricePerMM = 0;
      }
    }
    if (typeof totalArea !== 'number') {
      if (isNaN(parseFloat(totalArea))) {
        totalArea = 0;
      }
    }

    let price = parseFloat(pricePerMM) * totalArea;
    this.setState({
      price_per_mm: pricePerMM,
      price,
    });
  };
  onPriceChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onFloorChange = async (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (parseInt(e.target.value) === 0 || parseInt(e.target.value) === -100) {
      await this.setState({ IsFirstFloor: true });
    } else {
      await this.setState({
        IsFirstFloor: false,
        IsGarden: false,
        ground_area: 0,
      });
    }
    this.onAreaChange();
  };

  onProvinceChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, area: 'غير محدد' });
    this.setState({
      propertyPosition: null,
      gps_location: '',
      IsUsingGPS: false,
      position: [34.890596, 35.881085],
    });
    if (e.target.value === 'دمشق') {
      //دمشق
      this.setState({ areas: syriaAreas.damascusAreas });
    } else if (e.target.value === 'ريف دمشق') {
      //ريف دمشق
      this.setState({
        areas: syriaAreas.rifDamascusAreas,
      });
    } else if (e.target.value === 'حلب') {
      //حلب
      this.setState({
        areas: syriaAreas.aleppoAreas,
      });
    } else if (e.target.value === 'طرطوس') {
      //طرطوس
      this.setState({
        areas: syriaAreas.tartousAreas,
      });
    } else if (e.target.value === 'اللاذقية') {
      //اللاذقية
      this.setState({ areas: syriaAreas.latakiaAreas });
    } else if (e.target.value === 'حمص') {
      //حمص
      this.setState({
        areas: syriaAreas.homsAreas,
      });
    } else if (e.target.value === 'حماة') {
      //حماة
      this.setState({ areas: syriaAreas.hamaAreas });
    } else if (e.target.value === 'السويداء') {
      //السويداء
      this.setState({ areas: syriaAreas.asuwaydaAreas });
    } else if (e.target.value === 'الحسكة') {
      //الحسكة
      this.setState({
        areas: syriaAreas.hasakaAreas,
      });
    } else if (e.target.value === 'درعا') {
      //درعا
      this.setState({ areas: syriaAreas.daraaAreas });
    } else if (e.target.value === 'ادلب') {
      //ادلب
      this.setState({
        areas: syriaAreas.idlibAreas,
      });
    } else if (e.target.value === 'دير الزور') {
      //دير الزور
      this.setState({ areas: syriaAreas.deirezorAreas });
    } else if (e.target.value === 'القنيطرة') {
      //القنيطرة
      this.setState({ areas: syriaAreas.quneitraAreas });
    } else if (e.target.value === 'الرقة') {
      //الرقة
      this.setState({ areas: syriaAreas.raqqaAreas });
    } else {
      this.setState({ areas: [] });
    }
  };
  componentDidMount() {
    this.getCurrentLocation();
  }
  async componentWillReceiveProps(nextProps, nextState) {
    var uploadedImages = [];
    // var photo_uploaded_name = '';
    if (
      nextProps.requestMessage !== this.state.requestMessage &&
      this.state.requestMessage !== ''
    ) {
      // this.setState({
      //   requestMessage: nextProps.requestMessage
      // });
      switch (nextProps.requestMessage) {
        case 'add is succeeded':
          if (this.state.imagesFull === true) {
            this.growl.show({
              severity: 'success',
              summary: 'تم ادخال العقار بنجاح',
            });
          } else if (this.state.imagesFull === false) {
            this.growl.show({
              severity: 'info',
              summary:
                'تم ادخال العقار ولكنه لن يظهر ضمن الصفحة الرئيسية لعدم ادخال صور له',
            });
          }
          break;

        case 'upload first image':
          // this.growl.show({
          //   severity: 'success',
          //   summary: 'تم رفع اول صورة بنجاح'
          // });
          uploadedImages = [];
          uploadedImages.push(nextProps.uploadedPhotoName);

          // photo_uploaded_name = this.state.fullSelectedFilesArray;
          for (var i = 0; i < this.state.fullSelectedFilesArray.length; i++) {
            if (
              this.state.fullSelectedFilesArray[i][0] ===
              nextProps.uploadedPhotoName
            ) {
              this.state.fullSelectedFilesArray[i][5] = 'uploaded';
            }
          }

          await this.setState({
            uploadedImages,
            firstImagesUploaded: true,
            directory: nextProps.directory,
          });
          this.uploadImages();
          break;

        case 'error in photo uploading':
          for (var k = 0; k < this.state.fullSelectedFilesArray.length; k++) {
            if (
              this.state.fullSelectedFilesArray[k][0] ===
              nextProps.uploadedPhotoName
            ) {
              this.state.fullSelectedFilesArray[k][5] = 'not uploaded';
            }
          }

          break;

        case 'upload image':
          // this.growl.show({
          //   severity: 'success',
          //   summary: 'تم رفع صورة بنجاح'
          // });
          uploadedImages = this.state.uploadedImages;
          if (!uploadedImages.includes(nextProps.uploadedPhotoName)) {
            uploadedImages.push(nextProps.uploadedPhotoName);
          }

          // photo_uploaded_name = this.state.fullSelectedFilesArray;
          for (
            var ii = 0;
            ii < this.state.fullSelectedFilesArray.length;
            ii++
          ) {
            if (
              this.state.fullSelectedFilesArray[ii][0] ===
              nextProps.uploadedPhotoName
            ) {
              this.state.fullSelectedFilesArray[ii][5] = 'uploaded';
            }
          }

          await this.setState({
            uploadedImages,
            firstImagesUploaded: true,
            // directory: nextProps.directory
          });
          break;

        default:
          break;
      }
    }
    if (nextProps.property !== this.state.property) {
      this.setState({ property: nextProps.property, processScreen: false });
    }
  }
  onHandleFormClear = (e) => {
    e.preventDefault();
    this.setState({
      imageResType: '',
      uploadedImages: [],
      uploadNewImages: false,
      compressNewImages: false,
      directory: '',
      firstImagesUploaded: false,
      requestMessage: '',
      showCurrentLocation: false,
      distanceInPixel: 10,
      scale: 100,
      quality: 82,
      property: {},
      processScreen: false,
      photoIndex: 0,
      isOpen: false,
      IsOpenSubmit: false,
      fullSelectedFilesArray: [],
      fullSelectedFilesArrayNextAll: [],
      firstSelectedFile: [],
      showMessages: false,
      areas: [],
      IsMM: false,
      IsGarden: false,
      IsRoof: false,
      IsLookingFor: false,
      IsBuildDate: false,
      IsReconstructed: false,
      IsUsingGPS: false,
      IsField: false,
      IsFarm: false,
      IsUnderGround: false,
      IsForAnimal: false,
      IsGasStation: false,
      IsFirstFloor: true,
      IsFullBuilding: false,
      IsOffice: false,
      IsVilla: false,
      IsGarage: false,
      IsShop: false,
      IsStoreFactory: false,
      IsRestaurant: false,
      IsHotel: false,
      showMap: false,
      maplays: null,
      hasLocation: false,
      lat: 34.890596,
      lng: 35.881085,
      zoom: 18,
      propertyPosition: null,
      title: '',
      underConstruction: false,
      type: 'شقة', //flat
      forRent: false,
      forSell: true,
      rank: 10,
      description: '',
      reference_id: '',
      city: '',
      province: 'غير محدد',
      area: 'غير محدد',
      street: '',
      gps_location: '', //"105 , 104"
      bedrooms: 0,
      bathrooms: 0,
      toilets: 0,
      garage: false,
      basement: false,
      parking: false,
      balcony: 0,
      view_direction: 'غير محدد',
      floor: -100,
      duplex: false,
      total_area: '',
      living_area: '',
      living_rooms: 0,
      ground_area: '',
      roof_building_area: '',
      price_upon_review: false,
      //price_upon_order: false,
      rented: false,
      rent_start: null,
      rent_end: null,
      pricing_type: 'ل.س',
      lift: false,
      furnitured: false,
      type_of_ownership: 'طابو أخضر- 2400 سهم', //"private"
      price: '',
      business_status: 'جديد', //new
      rent_plan_id: 1,
      sell_plan_id: 1,
      visible: true,
      built_at: null, //dateTime "thu jul 07 12:20:15 +0300 2017"
      reconstructed_at: null, //dateTime "thu jul 07 12:20:15 +0300 2018"
      status: 'جاهز', //"ready"
      price_per_day: '0',
      price_per_mm: '0',
      business_offer: 'بيع', //"rent"
      views: 0,
      created_at: null, //dateTime "thu jul 07 12:20:15 +0300 2018"
      photo_data: [],
      photo_name: [],
      // photo_uploaded_name: [],
      showImages: false,
      errors: {},
      propertyO: {},
    });

    Array.from(
      document.getElementsByClassName('p-button-text-icon-left')
    ).forEach(function (element, index, array) {
      if (element.textContent === 'حذف كافة صور العقار') {
        element.click();
      }
    });
  };
  toBlob = (imgUrl) => {
    let blob = base64toblob(imgUrl.split(',')[1], 'image/jpeg');
    let url = window.URL.createObjectURL(blob);
    return url;
  };
  buildFile = (blob, name) => {
    return new File([blob], name);
  };
  addImgObj = (imageFile) => {
    return new Promise((resolve) => {
      let img = new Image();
      img.src = imageFile;

      img.addEventListener('load', function () {
        var obj = {};
        obj.width1 = this.width;
        obj.height1 = this.height;
        resolve(obj);
      });
    });
  };
  onImageCompressor = async (originImage, imageFile) => {
    var resultObjToPass = {};
    let canvas = document.createElement('canvas');
    let ctx = canvas.getContext('2d');

    let img = new Image();
    img.src = imageFile;
    await img.addEventListener('load', function () { });
    if (img.width === 0) {
      const obj = await this.addImgObj(imageFile);
    }
    let scale = this.state.scale / 100;
    if (img.width > 1200) {
      scale = 1200 / img.width;
      scale = scale.toFixed(4);
    }
    let width = img.width * scale;
    let height = img.height * scale;
    canvas.setAttribute('width', width);
    canvas.setAttribute('height', height);
    ctx.drawImage(img, 0, 0, width, height);
    let quality = this.state.quality ? this.state.quality / 100 : 1;
    let base64 = canvas.toDataURL('image/jpeg', quality);
    let fileName = originImage.name;

    let lastDot = fileName.lastIndexOf('.');
    fileName = fileName.substr(0, lastDot); // + '.jpeg'

    let objToPass = {
      canvas: canvas,
      original: originImage,
      compressed: {
        blob: this.toBlob(base64),
        base64: base64,
        name: fileName,
        file: this.buildFile(base64, fileName),
      },
    };

    objToPass.compressed.size = Math.round(
      objToPass.compressed.file.size / 1000
    ); //+ ' kB'
    objToPass.compressed.type = 'image/jpeg';
    resultObjToPass = await objToPass;
    return resultObjToPass;
  };

  getBase64 = async (file) => {
    var resultValue = imageFileToBase64(file);
    return resultValue;
  };
  onImageProcess = async (imageFile) => {
    const magician = new photoMagician();
    let resultImage = '';
    await magician
      .addWaterMark({
        cover: imageFile,
        mode: 'image',
        waterMark: waterMark,
        width: 100,
        height: 50,
        opacity: 0.6,
        coordinate: [0, 0],
      })
      .then((data) => {
        resultImage = data;
      })
      .catch((err) => {
        console.error('add image WaterMark error', err);
      });
    return resultImage;
  };
  // onUploadImages = async e => {
  //   this.setState({ errors: {} });
  //   var propertyImages = e.target.files;
  //   var selectedFileArray = [];
  //   var fullSelectedFilesArray = [];
  //   var f = [];
  //   var url = '';
  //   var obj = {};
  //   for (var i = 0; i < propertyImages.length; i++) {
  //     var filesize = (propertyImages[i].size / 1024 / 1024).toFixed(4);
  //     if (filesize <= 9) {
  //       selectedFileArray = [];
  //       obj = {};
  //       url = URL.createObjectURL(propertyImages[i]);
  //       obj = propertyImages[i];
  //       obj.objectURL = url;
  //       var Base64ArrayStr = await this.getBase64(obj);
  //       var compressedImage = await this.onImageCompressor(obj, Base64ArrayStr);

  //       selectedFileArray.push(
  //         compressedImage.compressed.name,
  //         compressedImage.compressed.size,
  //         compressedImage.compressed,
  //         i,
  //         shortid.generate()
  //       );

  //       fullSelectedFilesArray.push(selectedFileArray);
  //       f.push(selectedFileArray);

  //       ///////////////----------
  //       var match = false;

  //       for (var j = 0; j < this.state.fullSelectedFilesArray.length; j++) {
  //         match = false;
  //         selectedFileArray = [];

  //         for (var k = 0; k < fullSelectedFilesArray.length; k++) {
  //           if (
  //             this.state.fullSelectedFilesArray[j][0] ===
  //             fullSelectedFilesArray[k][0]
  //           ) {
  //             match = true;
  //             fullSelectedFilesArray[k][5] = this.state.fullSelectedFilesArray[
  //               j
  //             ][5];
  //           }
  //         }
  //         if (match === false) {
  //           selectedFileArray.push(
  //             this.state.fullSelectedFilesArray[j][0],
  //             this.state.fullSelectedFilesArray[j][1],
  //             this.state.fullSelectedFilesArray[j][2],
  //             this.state.fullSelectedFilesArray[j][3],
  //             this.state.fullSelectedFilesArray[j][4],
  //             // shortid.generate(),
  //             this.state.fullSelectedFilesArray[j][5]
  //           );
  //           fullSelectedFilesArray.push(selectedFileArray);
  //         }
  //       }
  //       //var a = this.convertToBase64(fullSelectedFilesArray);asdasdasddss
  //       var Base64Array = [];
  //       var NamesArray = [];

  //       // for (var ii = 0; ii < fullSelectedFilesArray.length; ii++) {
  //       Base64ArrayStr =
  //         fullSelectedFilesArray[fullSelectedFilesArray.length - 1][2].base64;
  //       Base64Array.push(Base64ArrayStr);
  //       // NamesArray.push(fullSelectedFilesArray[ii][2].name);
  //       NamesArray.push(
  //         fullSelectedFilesArray[fullSelectedFilesArray.length - 1][4] + '.jpeg'
  //       );
  //       // }

  //       var firstSelectedFile = [];
  //       var fullSelectedFilesArrayNextAll = [];
  //       if (this.state.firstSelectedFile.length === 0) {
  //         firstSelectedFile.push(fullSelectedFilesArray[0][0]);
  //         if (fullSelectedFilesArray.length > 0) {
  //           fullSelectedFilesArrayNextAll.push(
  //             fullSelectedFilesArray[fullSelectedFilesArray.length - 1][0]
  //           );
  //         }
  //         // for (var n = 1; n < fullSelectedFilesArray.length; n++) {

  //         // }
  //       } else {
  //         firstSelectedFile = this.state.firstSelectedFile;
  //         // for (var nn = 0; nn < fullSelectedFilesArray.length; nn++) {
  //         if (
  //           fullSelectedFilesArray[fullSelectedFilesArray.length - 1][0] !==
  //           this.state.firstSelectedFile[0]
  //         ) {
  //           fullSelectedFilesArrayNextAll.push(
  //             fullSelectedFilesArray[fullSelectedFilesArray.length - 1][0]
  //           );
  //         }
  //         // }
  //       }

  //       await this.setState({
  //         firstSelectedFile,
  //         fullSelectedFilesArray,
  //         fullSelectedFilesArrayNextAll,
  //         photo_data: Base64Array,
  //         photo_name: NamesArray
  //       });
  //     } else {
  //       this.setState({
  //         errors: {
  //           fileSize: `الملف ${propertyImages[i].name} حجمه اكبر من 5 ميغا بايت`
  //         }
  //       });
  //       return;
  //     }
  //   }
  //   this.uploadImages();
  // };
  onUploadImages = async (e) => {
    this.setState({ errors: {} });
    var propertyImages = e.target.files;
    var selectedFileArray = [];
    var fullSelectedFilesArray = [];
    var f = [];
    var url = '';
    var obj = {};
    await this.setState({ uploadNewImages: true, compressNewImages: true });
    for (var i = 0; i < propertyImages.length; i++) {
      var filesize = (propertyImages[i].size / 1024 / 1024).toFixed(4);
      if (filesize <= 15) {
        selectedFileArray = [];
        obj = {};
        url = URL.createObjectURL(propertyImages[i]);
        obj = propertyImages[i];
        obj.objectURL = url;
        var Base64ArrayStr = await this.getBase64(obj);
        var compressedImage = await this.onImageCompressor(obj, Base64ArrayStr);

        selectedFileArray.push(
          compressedImage.compressed.name,
          compressedImage.compressed.size,
          compressedImage.compressed,
          i,
          shortid.generate()
        );

        fullSelectedFilesArray.push(selectedFileArray);
        f.push(selectedFileArray);
      } else {
        this.setState({
          errors: {
            fileSize: `${this.state.errors.fileSize !== undefined
                ? this.state.errors.fileSize
                : ''
              } - الملف ${propertyImages[i].name} حجمه اكبر من 15 ميغا بايت`,
          },
        });
        continue;
      }
    }
    var match = false;
    await this.setState({ compressNewImages: false });
    for (var j = 0; j < this.state.fullSelectedFilesArray.length; j++) {
      match = false;
      selectedFileArray = [];

      for (var k = 0; k < fullSelectedFilesArray.length; k++) {
        if (
          this.state.fullSelectedFilesArray[j][0] ===
          fullSelectedFilesArray[k][0]
        ) {
          match = true;
          fullSelectedFilesArray[k][5] = this.state.fullSelectedFilesArray[
            j
          ][5];
        }
      }
      if (match === false) {
        selectedFileArray.push(
          this.state.fullSelectedFilesArray[j][0],
          this.state.fullSelectedFilesArray[j][1],
          this.state.fullSelectedFilesArray[j][2],
          this.state.fullSelectedFilesArray[j][3],
          this.state.fullSelectedFilesArray[j][4],
          // shortid.generate(),
          this.state.fullSelectedFilesArray[j][5]
        );
        fullSelectedFilesArray.push(selectedFileArray);
      }
    }
    //var a = this.convertToBase64(fullSelectedFilesArray);asdasdasddss
    var Base64Array = [];
    var NamesArray = [];

    for (var ii = 0; ii < fullSelectedFilesArray.length; ii++) {
      Base64ArrayStr = fullSelectedFilesArray[ii][2].base64;
      Base64Array.push(Base64ArrayStr);
      // NamesArray.push(fullSelectedFilesArray[ii][2].name);
      NamesArray.push(fullSelectedFilesArray[ii][4] + '.jpeg');
    }

    var firstSelectedFile = [];
    var fullSelectedFilesArrayNextAll = [];
    if (this.state.firstSelectedFile.length === 0) {
      firstSelectedFile.push(fullSelectedFilesArray[0][0]);
      for (var n = 1; n < fullSelectedFilesArray.length; n++) {
        fullSelectedFilesArrayNextAll.push(fullSelectedFilesArray[n][0]);
      }
    } else {
      firstSelectedFile = this.state.firstSelectedFile;
      for (var nn = 0; nn < fullSelectedFilesArray.length; nn++) {
        if (fullSelectedFilesArray[nn][0] !== this.state.firstSelectedFile[0]) {
          fullSelectedFilesArrayNextAll.push(fullSelectedFilesArray[nn][0]);
        }
      }
    }

    await this.setState({
      firstSelectedFile,
      fullSelectedFilesArray,
      fullSelectedFilesArrayNextAll,
      photo_data: Base64Array,
      photo_name: NamesArray,
    });
    this.uploadImages();
    await this.setState({ uploadNewImages: false });
  };
  uploadImages = async () => {
    var processType = '';
    var photoObject = {};
    var uploadedPhotoName = '';
    if (this.state.firstImagesUploaded === false) {
      processType = 'upload first image';
      await this.setState({
        requestMessage: 'ready',
        // updatedReferenceId: reference_id
      });
      for (var i = 0; i < this.state.fullSelectedFilesArray.length; i++) {
        if (
          this.state.fullSelectedFilesArray[i][0] ===
          this.state.firstSelectedFile[0]
        ) {
          uploadedPhotoName = this.state.fullSelectedFilesArray[i][0];
          photoObject = {
            photo_name: this.state.fullSelectedFilesArray[i][4],
            photo_data: this.state.fullSelectedFilesArray[i][2].base64,
          };
          const isTakingAgencyRole = this.props.isTakingAgencyRole;
          const takenAgencyRoleToken = this.props.takenAgencyRoleToken;
          await this.props.addPropertyPhoto(
            photoObject,
            processType,
            uploadedPhotoName,
            isTakingAgencyRole,
            takenAgencyRoleToken
          );
          break;
        }
      }
    } else {
      processType = 'upload image';
      var match = false;
      for (var j = 0; j < this.state.fullSelectedFilesArray.length; j++) {
        match = false;

        for (var k = 0; k < this.state.uploadedImages.length; k++) {
          if (
            this.state.fullSelectedFilesArray[j][0] ===
            this.state.uploadedImages[k]
          ) {
            match = true;
          }
        }
        if (match === false) {
          await this.setState({
            requestMessage: 'ready',
          });
          uploadedPhotoName = this.state.fullSelectedFilesArray[j][0];
          photoObject = {
            directory: this.state.directory,
            photo_name: this.state.fullSelectedFilesArray[j][4],
            photo_data: this.state.fullSelectedFilesArray[j][2].base64,
          };
          const isTakingAgencyRole = this.props.isTakingAgencyRole;
          const takenAgencyRoleToken = this.props.takenAgencyRoleToken;
          this.props.addPropertyPhoto(
            photoObject,
            processType,
            uploadedPhotoName,
            isTakingAgencyRole,
            takenAgencyRoleToken
          );
        }
      }
    }
  };
  onDeleteImages = () => {
    this.setState({
      fullSelectedFilesArray: [],
      fullSelectedFilesArrayNextAll: [],
      firstSelectedFile: [],
      imageResType: '',
      uploadedImages: [],
      uploadNewImages: false,
      compressNewImages: false,
      directory: '',
      firstImagesUploaded: false,
      errors: {},
      photo_data: [],
      photo_name: [],
      requestMessage: '',
    });
    if (document.getElementById('upload_images') !== null) {
      document.getElementById('upload_images').value = '';
    }
    // Array.from(
    //   document.getElementsByClassName('p-button-text-icon-left')
    // ).forEach(function(element, index, array) {
    //   if (element.textContent === 'حذف كافة صور العقار') {

    //     element.click();
    //   }
    // });
  };
  onShowImgaesClick = () => {
    this.setState({ showImages: true });
  };
  onSortProcess = async ({ oldIndex, newIndex }) => {
    if (oldIndex === newIndex) {
      return;
    }
    await this.setState(({ fullSelectedFilesArray }) => ({
      fullSelectedFilesArray: arrayMove(
        fullSelectedFilesArray,
        oldIndex,
        newIndex
      ),
    }));
    // await this.setState(({ photo_uploaded_name }) => ({
    //   photo_uploaded_name: arrayMove(photo_uploaded_name, oldIndex, newIndex)
    // }));

    var Base64Array = [];
    var Base64ArrayStr = '';
    var NamesArray = [];
    for (var ii = 0; ii < this.state.fullSelectedFilesArray.length; ii++) {
      Base64ArrayStr = this.state.fullSelectedFilesArray[ii][2].base64;
      Base64Array.push(Base64ArrayStr);
      // NamesArray.push(this.state.fullSelectedFilesArray[ii][2].name);
      NamesArray.push(this.state.fullSelectedFilesArray[ii][4] + '.jpeg');
    }
    this.setState({ photo_data: Base64Array, photo_name: NamesArray });
  };
  handleToggleButton = (e) => {
    this.setState({ showMap: e.value });
    if (!e.value) {
      this.setState({
        propertyPosition: null,
        gps_location: '',
        IsUsingGPS: false,
      });
    }
  };
  // mapRef = createRef<Map>()
  onMapShowClick = (e) => {
    e.preventDefault();
    this.setState({ showCurrentLocation: true });
  };
  render() {
    const {
      // requestMessage,
      photoIndex,
      // showMessages,
      isOpen,
      IsOpenSubmit,
      areas,
      // mortgage,
      fullSelectedFilesArray,
      processScreen,
      IsGarden,
      IsField,
      IsFarm,
      IsUnderGround,
      IsForAnimal,
      IsGasStation,
      IsFullBuilding,
      IsOffice,
      IsVilla,
      IsGarage,
      IsShop,
      IsStoreFactory,
      IsRestaurant,
      IsHotel,
      IsRoof,
      IsLookingFor,
      // IsMM,
      IsUsingGPS,
      forRent,
      forSell,
      IsBuildDate,
      IsReconstructed,
      IsFirstFloor,
      showMap,

      // maplays,
      propertyPosition,
      title,
      underConstruction,
      type,
      // rank,
      description,
      // reference_id,
      // city,
      province,
      area,
      street,
      // gps_location,
      bedrooms,
      bathrooms,
      // toilets,
      garage,
      basement,
      parking,
      balcony,
      view_direction,
      floor,
      duplex,
      total_area,
      living_area,
      living_rooms,
      ground_area,
      roof_building_area,
      price_upon_review,
      //price_upon_order,
      rented,
      rent_start,
      rent_end,
      pricing_type,
      lift,
      furnitured,
      type_of_ownership,
      price,
      business_status,
      // rent_plan_id,
      // sell_plan_id,
      // visible,
      built_at,
      reconstructed_at,
      status,
      // price_per_day,
      // price_per_mm,
      business_offer,
      // views,
      // created_at,
      // photo_data,
      // photo_name,
      // showImages,
      errors,
      propertyO,
    } = this.state;

    const ar = {
      firstDayOfWeek: 6,
      dayNames: [
        'الأحد',
        'الأثنين',
        'الثلاثاء',
        'الأربعاء',
        'الخميس',
        'الجمعة',
        'السبت',
      ],
      dayNamesShort: [
        'الأحد',
        'الأثنين',
        'الثلاثاء',
        'الأربعاء',
        'الخميس',
        'الجمعة',
        'السبت',
      ],
      dayNamesMin: [
        'الأحد',
        'الأثنين',
        'الثلاثاء',
        'الأربعاء',
        'الخميس',
        'الجمعة',
        'السبت',
      ],
      monthNames: [
        'كانون الثاني',
        'شباط',
        'آذار',
        'نيسان',
        'أيار',
        'حزيران',
        'تموز',
        'آب',
        'أيلول',
        'تشرين الأول',
        'تشرين الثاني',
        'كانون الأول',
      ],
      monthNamesShort: [
        'كانون الثاني',
        'شباط',
        'آذار',
        'نيسان',
        'أيار',
        'حزيران',
        'تموز',
        'آب',
        'أيلول',
        'تشرين الأول',
        'تشرين الثاني',
        'كانون الأول',
      ],
      isRTL: true,
    };
    const listGrid = [];
    (fullSelectedFilesArray || []).map((selectedFile, index) =>
      listGrid.push(
        <div
          key={index}
          className="fileupload_image_inside_container"
        // className={classnames(
        //   {
        //     fileupload_image_inside_container_finish:
        //       selectedFile[5] === 'uploaded'
        //   },
        //   {
        //     fileupload_image_inside_container_start:
        //       selectedFile[5] === undefined
        //   }
        // )}
        >
          {selectedFile[5] !== 'uploaded' &&
            selectedFile[5] !== 'not uploaded' ? (
            // <div className="process-loader">Ikar.sy</div>
            <div className="process-loader2">
              <div className="span text-center">
                {/* <div className="location_indicator" /> */}
                <div
                  className="spinner-border text-warning"
                  style={{ width: '3rem', height: '3rem' }}
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>
                <p className="text-danger lead text-center">
                  <span>www.</span>
                  <span className="font-weight-bold">Ikar</span>
                  <span className="font-weight-bold">.sy</span>
                </p>
              </div>
            </div>
          ) : selectedFile[5] === 'not uploaded' ? (
            // <div className="process-loader">Ikar.sy</div>
            <div className="process-loader-error">
              <div className="span text-center">
                {/* <div className="location_indicator" /> */}
                {/* <div
                  className="spinner-border text-warning"
                  style={{ width: '3rem', height: '3rem' }}
                  role="status"
                >
                  <span className="sr-only">Loading...</span>
                </div>*/}
                <p className="text-danger text-center dispaly_7">
                  <span>الرجاء تحميل الصورة من جديد</span>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={this.uploadImages}
                  // style={{ width: '165px' }}
                  >
                    اعادة تحميل{' '}
                    <FontAwesomeIcon icon={['fas', 'undo']}></FontAwesomeIcon>
                  </button>
                </p>
              </div>
            </div>
          ) : null}
          <div className="text-center fileupload_div_delete_image">
            <button
              type="button"
              className="btn btn-primary fileupload_btn_deleted"
              onClick={this.onDeleteImage.bind(this, selectedFile[0])}
            >
              <FontAwesomeIcon icon={['fas', 'times']} color="white" />
            </button>
          </div>
          <div className="mt-1 mb-0 text-center no_select">
            <img
              className="fileupload_property_img no_select_img"
              src={selectedFile[2].blob}
              alt={selectedFile[2].name}
              onClick={() =>
                this.setState({
                  photoIndex: index,
                  isOpen: true,
                })
              }
            />
          </div>
          <div
            className="mx-auto my-0 px-2 text-center text-warning no_select"
            style={{ wordWrap: 'break-word' }}
          >
            <span className="text-danger">{` ${index + 1} `}</span>
            <span className="small">{selectedFile[0]}</span>
            <p className="text-dark">{` ${selectedFile[1]}KB`}</p>
          </div>
          {/* <div className="col-12 px-1 my-1 text-center h6"></div> */}
        </div>
      )
    );

    const SortableItem = SortableElement(({ value }) => (
      <div className="fileupload_image_container col-md-3 col-sm-4 col-6">
        {value}
      </div>
    ));

    const SortableList = SortableContainer(({ items }) => {
      return (
        <div
          className="row"
        //className="style1"
        // style={{ backgroundColor: 'blue' ,width:"300px"}}
        >
          {items.map((value, index) => (
            <SortableItem key={`item-${index}`} index={index} value={value} />
          ))}
        </div>
      );
    });
    return (
      <div className="container-fluid">
        <Helmet>
          <title>{this.state.pageTitle}</title>
          <meta name="description" content={this.state.pageDescription} />
          <meta name="robots" content="noindex" />
        </Helmet>
        <div className="row mb-3">
          <div className="col-md-12">
            <h1 className="text-center my-2 main_title">إضافة عقار</h1>
          </div>
        </div>
        <Dialog
          header="الموقع الحالي للعقار على الخريطة"
          //footer={mapFooter}
          // iconsTemplate={mapIcon}
          visible={this.state.showCurrentLocation}
          style={{ width: '90vw', minHeight: '70vh' }}
          modal={true}
          onHide={() => this.setState({ showCurrentLocation: false })}
        >
          <div>
            {this.state.showCurrentLocation ? (
              mapsEnabled ? (
                <Map
                  center={this.state.position}
                  zoom={this.state.zoom}
                  style={{ width: '85vw', minHeight: '65vh' }}
                >
                  <TileLayer
                    attribution='<a href="http://ikar.sy">Ikar.sy</a> &amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  />
                  <Marker position={this.state.position}>
                    <Popup>
                      <span className="display_8">{title}</span>
                    </Popup>
                  </Marker>
                </Map>
              ) : (
                noInternetMessage
              )
            ) : null}
          </div>
        </Dialog>
        <Dialog
          header="اكمال اضافة عقار"
          //footer={mapFooter}
          // iconsTemplate={mapIcon}
          visible={IsOpenSubmit}
          style={{ width: '98%', height: '90%' }}
          modal={true}
          onHide={() => this.setState({ IsOpenSubmit: false })}
          className="show-submitted-property"
        >
          {/* <button
            className="btn btn-block submit-button m-1"
            onClick={this.onAddSubmit.bind(this, 'show')}
          >
            استعراض عقار
          </button> */}
          <div className="row mb-4">
            <div className="col-4"></div>
            <div className="col-4">
              <button
                className="btn btn-block submit-button m-1"
                onClick={this.onAddSubmit.bind(this, 'add')}
              >
                نشر على الموقع
              </button>
            </div>
            <div className="col-4"></div>
          </div>
          {/* {IsOpenSubmit ? ( */}
          <div>
            <ShowProperty propertyO={propertyO} source="add"></ShowProperty>
          </div>
          {/* ) : null} */}
        </Dialog>
        <Growl ref={(propertyAdd) => (this.growl = propertyAdd)} />
        <form
          //onSubmit={this.onAddSubmit.bind(this, 'submit')}
          style={{ direction: 'rtl' }}
        >
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group text-right  mb-3">
                <label htmlFor="business_offer">
                  نوع العرض: <span className="display_mandatory">(مطلوب)</span>
                </label>
                <select
                  name="business_offer"
                  className={classnames('form-control', {
                    'is-invalid': errors.business_offer,
                  })}
                  onChange={this.onBusinessOfferChange}
                  value={business_offer}
                >
                  {generalBusinessOffer.map((businessOffer) => (
                    <option value={businessOffer} key={shortid.generate()}>
                      {businessOffer}
                    </option>
                  ))}
                </select>
                {errors.business_offer && (
                  <div className="invalid-feedback">
                    {errors.business_offer}
                  </div>
                )}
              </div>
              <div className="form-group text-right  mb-3">

                <label htmlFor="business_offer">
                  العنوان العام للعقار: <span className="display_mandatory">(مطلوب)</span>
                </label>
                <span className="p-float-label text-right">

                  <input
                    //id="search-input"
                    type="text"
                    name="title"
                    onChange={this.onChange}
                    className={classnames('form-control', {
                      'is-invalid': errors.title,
                    })}
                    value={title}
                    // placeholder="أدخل نص للبحث: دمشق شقة مفروشة"
                    style={{ transition: 'all 0.4s' }}
                  />
                  {title === '' ? (
                    <label htmlFor="title" className="no_select text-right">
                      {business_offer === 'تأجير'
                        ? '(مثال) : للتأجير شقة بموقع مميز في المزة'
                        : 'ابحث عن(مثال):' && business_offer == 'بيع'
                          ? '(مثال) : للبيع شقة إطلالة مميزة في الكسوة '
                          : 'ابحث عن(مثال):' && business_offer == 'رهن'
                            ? '(مثال) : للرهن شقة في مشروع دمر لمدة سنة'
                            : 'ابحث عن(مثال):' && business_offer == 'مزادات'
                              ? '(مثال) : شقة للبيع بالمزاد العلني في التل'
                              : 'ابحث عن(مثال):'}
                    </label>
                  ) : null}
                </span>

                {errors.title && (
                  <div className="invalid-feedback">{errors.title}</div>
                )}
              </div>

              <div className="form-group text-right  mb-3">
                <label htmlFor="type">
                  نوع العقار: <span className="display_mandatory">(مطلوب)</span>
                </label>
                <select
                  name="type"
                  className={classnames('form-control', {
                    'is-invalid': errors.type,
                  })}
                  onChange={this.onTypeChange}
                  value={type}
                >
                  {generalTypes}
                </select>
                {errors.type && (
                  <div className="invalid-feedback">{errors.type}</div>
                )}
              </div>

              <div className="form-group text-right  mb-3">
                <label htmlFor="province">
                  المحافظة: <span className="display_mandatory">(مطلوب)</span>
                </label>
                <select
                  name="province"
                  className={classnames('form-control', {
                    'is-invalid': errors.province,
                  })}
                  onChange={this.onProvinceChange}
                  value={province}
                >
                  <option value="غير محدد">غير محدد</option>
                  <option value="دمشق"> دمشق</option>
                  <option value="ريف دمشق"> ريف دمشق</option>
                  <option value="حلب"> حلب</option>
                  <option value="طرطوس"> طرطوس</option>
                  <option value="اللاذقية"> اللاذقية</option>
                  <option value="حمص"> حمص</option>
                  <option value="حماة"> حماة</option>
                  <option value="السويداء"> السويداء</option>
                  <option value="الحسكة"> الحسكة</option>
                  <option value="درعا"> درعا</option>
                  <option value="ادلب"> ادلب</option>
                  <option value="دير الزور"> دير الزور</option>
                  <option value="القنيطرة"> القنيطرة</option>
                  <option value="الرقة"> الرقة</option>
                </select>
                {errors.province && (
                  <div className="invalid-feedback">{errors.province}</div>
                )}
              </div>
              <div className="form-group text-right  mb-3">
                <label htmlFor="area">
                  المنطقة:
                  {/* <span className="display_mandatory">(مطلوب)</span> */}
                </label>
                <select
                  name="area"
                  className={classnames('form-control', {
                    'is-invalid': errors.area,
                  })}
                  onChange={
                    this.onProvinceAreaChange
                  }
                  value={area}
                >
                  <option value="غير محدد">غير محدد</option>
                  {areas.map((area, index) => (
                    <option value={area} key={index}>
                      {area}
                    </option>
                  ))}
                </select>
                {errors.area && (
                  <div className="invalid-feedback">{errors.area}</div>
                )}
              </div>
              <div className="form-group text-right mb-3">
                <label htmlFor="street">موقع العقار:</label>
                <input
                  type="text"
                  className={classnames('form-control', {
                    'is-invalid': errors.street,
                  })}
                  name="street"
                  value={street}
                  onChange={this.onChange}
                />
                {errors.street && (
                  <div className="invalid-feedback">{errors.street}</div>
                )}
              </div>
              {this.state.gps_location !== null &&
                this.state.gps_location !== undefined &&
                this.state.gps_location !== '' &&
                mapsEnabled &&
                IsUsingGPS ? (
                <div className="form-group round-div py-1">
                  <div className="col-12 text-center">
                    <button
                      className="px-2 py-1 m-1"
                      onClick={this.onMapShowClick}
                    >
                      موقع العقار على الخريطة
                      <FontAwesomeIcon
                        className="icon_map_marked"
                        icon={['fas', 'map-marked']}
                        color="blue"
                      ></FontAwesomeIcon>
                    </button>
                  </div>
                </div>
              ) : null}
              {mapsEnabled ? (
                <div className="form-group round-div py-1">
                  <div className="col-12 text-center">
                    <ToggleButton
                      style={{ width: '175px', fontSize: '16px' }}
                      checked={IsUsingGPS}
                      onChange={this.handleToggleButton}
                      onLabel="عدم تحديد احداثيات"
                      offLabel="تحديد احداثيات"
                      onIcon="pi pi-map-marker"
                      offIcon="pi pi-globe"
                      disabled={area === 'غير محدد'}
                    />
                  </div>
                  <Dialog
                    header="حدد موقع عقارك على الخريطة"
                    //footer={mapFooter}
                    // iconsTemplate={mapIcon}
                    visible={showMap}
                    style={{ width: '90vw', minHeight: '70vh' }}
                    modal={true}
                    onHide={() => this.setState({ showMap: false })}
                  >
                    <div>
                      {showMap ? (
                        mapsEnabled && window !== undefined ? (
                          // &&
                          // window !== undefined &&
                          // window.google !== undefined
                          <Map
                            center={this.state.position}
                            length={4}
                            onClick={this.onMapClick}
                            // onLocationfound={this.handleLocationFound}
                            ref={this.mapRef}
                            zoom={this.state.zoom}
                            style={{ width: '85vw', minHeight: '65vh' }}
                          >
                            <TileLayer
                              attribution='<a href="http://ikar.sy">Ikar.sy</a> &amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                          </Map>
                        ) : (
                          noInternetMessage
                        )
                      ) : null}
                    </div>
                  </Dialog>
                  <div className="col-12 text-right">
                    <div className="row">
                      <div className="col-12 text-right my-1">
                        {!propertyPosition ? <span>خط العرض : </span> : null}
                        {IsUsingGPS && mapsEnabled ? (
                          propertyPosition ? (
                            <React.Fragment>
                              خط العرض :{' '}
                              <span className="text-primary mx-2">
                                {propertyPosition.lat}
                              </span>
                              <FontAwesomeIcon
                                icon={['fas', 'map-marked-alt']}
                                size="1x"
                                color="blue"
                              />
                            </React.Fragment>
                          ) : null
                        ) : null}
                      </div>
                      <div className="col-12 text-right my-1">
                        {!propertyPosition ? <span>خط الطول : </span> : null}
                        {IsUsingGPS && mapsEnabled ? (
                          propertyPosition ? (
                            <React.Fragment>
                              خط الطول :{' '}
                              <span className="text-primary mx-2">
                                {propertyPosition.lng}
                              </span>
                              <FontAwesomeIcon
                                icon={['fas', 'map-marked-alt']}
                                size="1x"
                                color="blue"
                              />
                            </React.Fragment>
                          ) : null
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="form-group text-right  mb-3">
                <label htmlFor="type_of_ownership">
                  نوع الملكية:{' '}
                  <span className="display_mandatory">(مطلوب)</span>
                </label>
                <select
                  name="type_of_ownership"
                  className={classnames('form-control', {
                    'is-invalid': errors.type_of_ownership,
                  })}
                  onChange={this.onChange}
                  value={type_of_ownership}
                >
                  {(generalTypeOfOwnership || []).map((typeOfOwnership) => (
                    <option value={typeOfOwnership} key={shortid.generate()}>
                      {typeOfOwnership}
                    </option>
                  ))}
                  {/* <option value="غير محدد">غير محدد</option>
                  <option value="طابو أخضر- 2400 سهم">
                    طابو أخضر- 2400 سهم
                  </option>
                  <option value="حكم محكمة"> حكم محكمة</option>
                  <option value="حصة سهمية"> حصة سهمية</option>
                  <option value="عقد تنازل"> عقد تنازل</option>
                  <option value="احلال عضوية - جمعية">
                    احلال عضوية - جمعية
                  </option> */}
                </select>
                {errors.type_of_ownership && (
                  <div className="invalid-feedback">
                    {errors.type_of_ownership}
                  </div>
                )}
              </div>
              <div className="form-group text-right  mb-3">
                <label htmlFor="description">
                  وصف العقار: <span className="display_mandatory">(مطلوب)</span>
                </label>
                <textarea
                  rows="4"
                  name="description"
                  className={classnames('form-control', {
                    'is-invalid': errors.description,
                  })}
                  placeholder=""
                  value={description}
                  onChange={this.onChange}
                />
                {errors.description && (
                  <div className="invalid-feedback">{errors.description}</div>
                )}
              </div>
              {!IsField &&
                !IsFarm &&
                !IsRoof &&
                !IsFullBuilding &&
                !IsGasStation &&
                !IsForAnimal &&
                !IsShop &&
                !IsStoreFactory ? (
                <React.Fragment>
                  {!IsGarage ? (
                    <React.Fragment>
                      <div className="form-group text-right  mb-3">
                        {!IsOffice && !IsRestaurant && !IsHotel ? (
                          <label htmlFor="bedrooms">عدد غرف النوم:</label>
                        ) : (
                          <label htmlFor="bedrooms">عدد الغرف :</label>
                        )}

                        <select
                          name="bedrooms"
                          className={classnames('form-control', {
                            'is-invalid': errors.bedrooms,
                          })}
                          onChange={this.onChange}
                          value={bedrooms}
                        >
                          {/* <option value="غير محدد">غير محدد</option> */}
                          <option value="0"> 0</option>
                          <option value="1"> 1</option>
                          <option value="2"> 2</option>
                          <option value="3"> 3</option>
                          <option value="4"> 4</option>
                          <option value="5"> 5</option>
                          <option value="6"> 6</option>
                          <option value="7"> 7</option>
                          <option value="8"> 8</option>
                          <option value="9"> 9</option>
                          <option value="10"> 10</option>
                          <option value="11"> 11</option>
                          <option value="12"> 12</option>
                        </select>
                        {errors.bedrooms && (
                          <div className="invalid-feedback">
                            {errors.bedrooms}
                          </div>
                        )}
                      </div>
                      {!IsOffice && !IsRestaurant && !IsHotel ? (
                        <div className="form-group text-right  mb-3">
                          <label htmlFor="living_rooms">عدد غرف الصالون:</label>
                          <select
                            name="living_rooms"
                            className={classnames('form-control', {
                              'is-invalid': errors.living_rooms,
                            })}
                            onChange={this.onChange}
                            value={living_rooms}
                          >
                            {/* <option value="غير محدد">غير محدد</option> */}
                            <option value="0"> 0</option>
                            <option value="1"> 1</option>
                            <option value="2"> 2</option>
                            <option value="3"> 3</option>
                            <option value="4"> 4</option>
                            <option value="5"> 5</option>
                          </select>
                          {errors.living_rooms && (
                            <div className="invalid-feedback">
                              {errors.living_rooms}
                            </div>
                          )}
                        </div>
                      ) : null}
                    </React.Fragment>
                  ) : null}
                  {/* <div className="form-group text-right  mb-3">
                    <label htmlFor="toilets">عدد التواليت: *</label>
                    <select
                      name="toilets"
                      className={classnames('form-control', {
                        'is-invalid': errors.toilets
                      })}
                      onChange={this.onChange}
                      value={toilets}
                    >
                      <option value="0"> 0</option>
                      <option value="1"> 1</option>
                      <option value="2"> 2</option>
                      <option value="3"> 3</option>
                      <option value="4"> 4</option>
                      <option value="5"> 5</option>
                      <option value="6"> 6</option>
                    </select>
                    {errors.toilets && (
                      <div className="invalid-feedback">{errors.toilets}</div>
                    )}
                  </div> */}
                  {!IsHotel ? (
                    <React.Fragment>
                      {!IsGarage ? (
                        <React.Fragment>
                          <div className="form-group text-right  mb-3">
                            <label htmlFor="bathrooms">عدد الحمامات:</label>
                            <select
                              name="bathrooms"
                              className={classnames('form-control', {
                                'is-invalid': errors.bathrooms,
                              })}
                              onChange={this.onChange}
                              value={bathrooms}
                            >
                              {/* <option value="غير محدد">غير محدد</option> */}
                              <option value="0"> 0</option>
                              <option value="1"> 1</option>
                              <option value="2"> 2</option>
                              <option value="3"> 3</option>
                              <option value="4"> 4</option>
                              <option value="5"> 5</option>
                            </select>
                            {errors.bathrooms && (
                              <div className="invalid-feedback">
                                {errors.bathrooms}
                              </div>
                            )}
                          </div>

                          <div className="form-group text-right  mb-3">
                            <label htmlFor="balcony">عدد البلكون:</label>
                            <select
                              name="balcony"
                              className={classnames('form-control', {
                                'is-invalid': errors.balcony,
                              })}
                              onChange={this.onChange}
                              value={balcony}
                            >
                              {/* <option value="غير محدد">غير محدد</option> */}
                              <option value="0"> 0</option>
                              <option value="1"> 1</option>
                              <option value="2"> 2</option>
                              <option value="3"> 3</option>
                              <option value="4"> 4</option>
                              <option value="5"> 5</option>
                              <option value="6"> 6</option>
                            </select>
                            {errors.balcony && (
                              <div className="invalid-feedback">
                                {errors.balcony}
                              </div>
                            )}
                          </div>
                        </React.Fragment>
                      ) : null}
                      {!IsVilla ? (
                        <div className="form-group text-right  mb-3">
                          <label htmlFor="floor">رقم الطابق:</label>
                          <select
                            name="floor"
                            className={classnames('form-control', {
                              'is-invalid': errors.floor,
                            })}
                            onChange={this.onFloorChange}
                            value={floor}
                          >
                            <option value="-100">غير محدد</option>
                            <option value="-3">قبو(3 طوابق تحت الأرض)</option>
                            <option value="-2">قبو(طابقين تحت الأرض)</option>
                            <option value="-1">قبو( طابق تحت الأرض)</option>
                            {!IsUnderGround ? (
                              <React.Fragment>
                                <option value="-25">نص قبو (قبو فايش)</option>
                                <option value="-24">أرضي منخفض</option>
                                <option value="0">أرضي</option>
                                <option value="1"> 1</option>
                                <option value="2"> 2</option>
                                <option value="3"> 3</option>
                                <option value="4"> 4</option>
                                <option value="5"> 5</option>
                                <option value="6"> 6</option>
                                <option value="7"> 7</option>
                                <option value="8"> 8</option>
                                <option value="9"> 9</option>
                                <option value="10"> 10</option>
                                <option value="11"> 11</option>
                                <option value="12"> 12</option>
                                <option value="13"> 13</option>
                                <option value="14"> 14</option>
                                <option value="15"> 15</option>
                                <option value="16"> 16</option>
                                <option value="17"> 17</option>
                                <option value="18"> 18</option>
                                <option value="19"> 19</option>
                                <option value="20"> 20</option>
                                <option value="21"> 21</option>
                                <option value="22"> 22</option>
                                <option value="23"> 23</option>
                                <option value="24"> 24</option>
                                <option value="25"> 25</option>
                                <option value="26"> 26</option>
                                <option value="27"> 27</option>
                              </React.Fragment>
                            ) : null}
                          </select>
                          {errors.floor && (
                            <div className="invalid-feedback">
                              {errors.floor}
                            </div>
                          )}
                        </div>
                      ) : null}
                    </React.Fragment>
                  ) : null}
                  {!IsGarage ? (
                    <div className="form-group text-right  mb-3">
                      <label htmlFor="view_direction">اتجاه العقار:</label>
                      <select
                        name="view_direction"
                        className={classnames('form-control', {
                          'is-invalid': errors.view_direction,
                        })}
                        onChange={this.onChange}
                        value={view_direction}
                      >
                        <option value="غير محدد">غير محدد</option>
                        <option value="شرقي"> شرقي</option>
                        <option value="غربي"> غربي</option>
                        <option value="جنوبي"> جنوبي</option>
                        <option value="شمالي"> شمالي</option>
                        <option value="شمال غربي"> شمال غربي</option>
                        <option value="شمال شرقي"> شمال شرقي</option>
                        <option value="جنوب غربي"> جنوب غربي</option>
                        <option value="جنوب شرقي"> جنوب شرقي</option>
                        <option value="كل الاتجاهات"> كل الاتجاهات</option>
                        <option value="ثلاثة اتجاهات"> ثلاثة اتجاهات</option>
                      </select>
                      {errors.view_direction && (
                        <div className="invalid-feedback">
                          {errors.view_direction}
                        </div>
                      )}
                    </div>
                  ) : null}
                </React.Fragment>
              ) : null}
            </div>
            <div className="col-lg-6">
              {!IsField &&
                !IsFarm &&
                !IsGasStation &&
                !IsForAnimal &&
                !IsGarage &&
                !IsShop ? (
                <React.Fragment>
                  <div className="row mb-3">
                    <div className="col-6">
                      <div className="form-group text-right">
                        <Checkbox
                          onChange={(e) => this.setState({ garage: e.checked })}
                          checked={garage}
                        />
                        <label
                          className="form-check-label mx-1"
                          htmlFor="garage"
                        >
                          له كراج
                        </label>
                        <FontAwesomeIcon
                          icon={['fas', 'warehouse']}
                          size="1x"
                        />
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-group text-right">
                        <Checkbox
                          onChange={(e) =>
                            this.setState({ basement: e.checked })
                          }
                          checked={basement}
                        />
                        <label
                          className="form-check-label mx-1"
                          htmlFor="basement"
                        >
                          له ملجأ
                        </label>
                        <FontAwesomeIcon icon={['fas', 'archway']} size="1x" />
                      </div>
                    </div>
                  </div>

                  <div className="row mb-3">
                    {!IsFullBuilding && !IsStoreFactory && !IsRoof ? (
                      <div className="col-6">
                        <div className="form-group text-right">
                          <Checkbox
                            onChange={(e) =>
                              this.setState({ duplex: e.checked })
                            }
                            checked={duplex}
                          />
                          <label
                            className="form-check-label mx-1"
                            htmlFor="duplex"
                          >
                            دوبلكس
                          </label>
                          <FontAwesomeIcon icon={['fas', 'clone']} size="1x" />
                        </div>
                      </div>
                    ) : null}
                    <div className="col-6">
                      <div className="form-group text-right">
                        <Checkbox
                          onChange={(e) =>
                            this.setState({ parking: e.checked })
                          }
                          checked={parking}
                        />
                        <label
                          className="form-check-label mx-1"
                          htmlFor="parking"
                        >
                          له موقف
                        </label>
                        <FontAwesomeIcon icon={['fas', 'parking']} size="1x" />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-6">
                      <div className="form-group text-right">
                        <Checkbox
                          onChange={(e) => this.setState({ lift: e.checked })}
                          checked={lift}
                        />
                        <label className="form-check-label mx-1" htmlFor="lift">
                          له مصعد
                        </label>
                        {/* <FontAwesomeIcon
                      icon={['fas', 'angle-double-up']}
                      size="x2"
                    /> */}
                        <FontAwesomeIcon
                          icon={['fas', 'level-up-alt']}
                          size="1x"
                        />
                      </div>
                    </div>
                    {!IsForAnimal && !IsRoof && !IsGarage && !IsStoreFactory ? (
                      <div className="col-6">
                        <div className="form-group text-right">
                          <Checkbox
                            onChange={(e) =>
                              this.setState({ furnitured: e.checked })
                            }
                            checked={furnitured}
                          />
                          <label
                            className="form-check-label mx-1"
                            htmlFor="furnitured"
                          >
                            مفروش
                          </label>
                          <FontAwesomeIcon icon={['fas', 'couch']} size="1x" />
                        </div>
                      </div>
                    ) : null}
                  </div>
                </React.Fragment>
              ) : null}

              <div className="round-div p-1">
                {!IsRoof ? (
                  <div className="form-group text-right  mb-3">
                    <label htmlFor="living_area">مساحة العقار (البناء):</label>
                    <div className="row">
                      <div className="col-10">
                        {/* <InputMask
                          mask="9999999"
                          maskChar=""
                          style={{ direction: 'ltr' }}
                          // type="number"
                          name="living_area"
                          id="livingArea"
                          className={classnames('form-control', {
                            'is-invalid': errors.living_area
                          })}
                          placeholder=""
                          value={living_area}
                          onChange={this.onAreaChange}
                        /> */}

                        <input
                          type="number"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          name="living_area"
                          id="livingArea"
                          className={classnames('form-control', {
                            'is-invalid': errors.living_area,
                          })}
                          style={{ direction: 'ltr' }}
                          placeholder=""
                          value={living_area}
                          onChange={this.onAreaChange}
                        />
                        {errors.living_area && (
                          <div className="invalid-feedback">
                            {errors.living_area}
                          </div>
                        )}
                      </div>
                      <div className="col-2">م2</div>
                    </div>
                  </div>
                ) : null}
                {!IsRoof &&
                  IsFirstFloor &&
                  !IsField &&
                  !IsFarm &&
                  !IsForAnimal &&
                  !IsStoreFactory &&
                  !IsUnderGround &&
                  !IsOffice &&
                  !IsGasStation &&
                  !IsGarage &&
                  !IsShop ? (
                  <div className="row mb-3">
                    <div className="col-6">
                      <div className="form-group text-right">
                        <Checkbox
                          onChange={async (e) => {
                            await this.setState({
                              IsGarden: e.checked,
                              ground_area: '',
                            });
                            // if (!e.checked) {
                            //   await this.setState({
                            //     ground_area: '',
                            //   });
                            // }

                            this.onAreaChange();
                          }}
                          checked={IsGarden}
                        />
                        <label
                          className="form-check-label mx-3"
                          htmlFor="IsGarden"
                        >
                          له حديقة-وجيبة
                        </label>
                      </div>
                    </div>
                    <div className="col-6">
                      {IsGarden ? (
                        <React.Fragment>
                          {/* <div className="row">
                          <div className="col-10"> */}
                          {/* <InputMask
                            mask="9999999"
                            maskChar=""
                            style={{ direction: 'ltr' }}
                            name="ground_area"
                            id="groundArea"
                            className={classnames('form-control', {
                              'is-invalid': errors.ground_area
                            })}
                            placeholder=""
                            value={ground_area}
                            onChange={this.onAreaChange}
                          /> */}

                          <input
                            type="number"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            name="ground_area"
                            id="groundArea"
                            className={classnames('form-control', {
                              'is-invalid': errors.ground_area,
                            })}
                            style={{ direction: 'ltr' }}
                            placeholder=""
                            value={ground_area}
                            onChange={this.onAreaChange}
                          />
                          {errors.ground_area && (
                            <div className="invalid-feedback">
                              {errors.ground_area}
                            </div>
                          )}
                          {/* </div>
                          <div className="col-2">م2</div>
                        </div> */}
                        </React.Fragment>
                      ) : null}
                    </div>
                  </div>
                ) : null}

                <div className="form-group text-right  mb-3">
                  <label htmlFor="total_area">المساحة الكلية:</label>
                  <div className="row">
                    <div className="col-10">
                      {/* <InputMask
                        mask="9999999"
                        maskChar=""
                        style={{ direction: 'ltr' }}
                        name="total_area"
                        id="totalArea"
                        className={classnames('form-control', {
                          'is-invalid': errors.total_area
                        })}
                        placeholder=""
                        value={total_area}
                        onChange={this.onTotalAreaChange}
                        readOnly={IsRoof ? false : true}
                      /> */}
                      <input
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        name="total_area"
                        id="totalArea"
                        className={classnames('form-control', {
                          'is-invalid': errors.total_area,
                        })}
                        style={{ direction: 'ltr' }}
                        placeholder=""
                        value={total_area}
                        onChange={this.onTotalAreaChange}
                        readOnly={IsRoof ? false : true}
                      />
                    </div>
                    <div className="col-2">م2</div>
                  </div>

                  {errors.total_area && (
                    <div className="invalid-feedback">{errors.total_area}</div>
                  )}
                </div>
                {IsRoof ? (
                  <div className="form-group text-right  mb-3">
                    <label htmlFor="roof_building_area">
                      المساحة الصالحة للبناء:
                    </label>
                    <div className="row">
                      <div className="col-10">
                        {/* <InputMask
                          mask="9999999"
                          maskChar=""
                          style={{ direction: 'ltr' }}
                          // type="number"
                          name="roof_building_area"
                          id="roofBuildingArea"
                          className={classnames('form-control', {
                            'is-invalid': errors.roof_building_area
                          })}
                          placeholder=""
                          value={roof_building_area}
                          onChange={this.onChange}
                        /> */}

                        <input
                          type="number"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          name="roof_building_area"
                          id="roofBuildingArea"
                          className={classnames('form-control', {
                            'is-invalid': errors.roof_building_area,
                          })}
                          style={{ direction: 'ltr' }}
                          placeholder=""
                          value={roof_building_area}
                          onChange={this.onChange}
                        />
                        {errors.roof_building_area && (
                          <div className="invalid-feedback">
                            {errors.roof_building_area}
                          </div>
                        )}
                      </div>
                      <div className="col-2">م2</div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="round-div p-1">
                <div className="row mb-3">
                  <div className="col-12">
                    <div className="form-group text-right">
                      <Checkbox
                        onChange={(e) => {
                          this.setState({
                            price_upon_review: e.checked,
                          });
                          // if (e.checked) {
                          //   this.setState({ price_upon_order: false });
                          // }
                        }}
                        checked={price_upon_review}
                      />
                      <label
                        className="form-check-label mx-3"
                        htmlFor="price_upon_review"
                      >
                        السعر عند المراجعة
                      </label>
                      <FontAwesomeIcon icon={['fas', 'phone']} size="1x" />
                    </div>
                  </div>
                  {/*<div className="col-6">
                     <div className="form-group text-right">
                      <Checkbox
                        onChange={e => {
                          this.setState({
                            price_upon_order: e.checked
                          });
                          if (e.checked) {
                            this.setState({ price_upon_review: false });
                          }
                        }}
                        checked={price_upon_order}
                      />
                      <label className="form-check-label mx-3" htmlFor="price_upon_order">
                        السعر عند الطلب
                      </label>
                      <FontAwesomeIcon
                        icon={['fas', 'money-check']}
                        size="1x"
                      />
                    </div> 
                  </div>*/}
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="form-group text-right  mb-3">
                      <label htmlFor="price">
                        ثمن العقار:{' '}
                        <span className="display_mandatory">(مطلوب)</span>
                      </label>
                      {/* <InputMask
                        mask="9999999999"
                        maskChar=""
                        style={{ direction: 'ltr' }}
                        // type="number"
                        id="totalPrice"
                        className={classnames('form-control', {
                          'is-invalid': errors.price
                        })}
                        name="price"
                        placeholder=""
                        value={price}
                        onChange={this.onPriceChange}
                        readOnly={price_upon_review ? true : false}
                      /> */}
                      <input
                        type="number"
                        inputMode="numeric"
                        pattern="[0-9]*"
                        name="price"
                        id="totalPrice"
                        className={classnames('form-control', {
                          'is-invalid': errors.price,
                        })}
                        style={{ direction: 'ltr' }}
                        placeholder=""
                        value={price}
                        onChange={this.onPriceChange}
                        readOnly={price_upon_review ? true : false}
                      />

                      {errors.price && (
                        <div className="invalid-feedback">{errors.price}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group text-right  mb-3">
                      <label htmlFor="pricing_type">طريقة التسعير:</label>
                      <select
                        name="pricing_type"
                        className={classnames('form-control', {
                          'is-invalid': errors.pricing_type,
                        })}
                        onChange={this.onChange}
                        value={pricing_type}
                      >
                        {price_upon_review ? (
                          <option value="السعر عند المراجعة">
                            السعر عند المراجعة
                          </option>
                        ) : null}
                        {/* {price_upon_order ? (
                          <option value="السعر بالاتفاق">السعر بالاتفاق</option>
                        ) : null} */}
                        {!price_upon_review ? (
                          //!price_upon_order && !price_upon_review ? (
                          <React.Fragment>
                            <option value="ل.س">ل.س</option>
                            {forSell ? (
                              <option value="م2/ل.س">م2/ل.س</option>
                            ) : null}

                            {forRent ? (
                              <React.Fragment>
                                <option value="يوم/ل.س">يوم/ل.س</option>
                                <option value="شهر/ل.س">شهر/ل.س</option>
                                <option value="سنة/ل.س">سنة/ل.س</option>
                              </React.Fragment>
                            ) : null}

                            {/* <option value="غير محدد">غير محدد</option> */}
                          </React.Fragment>
                        ) : null}
                      </select>
                      {errors.pricing_type && (
                        <div className="invalid-feedback">
                          {errors.pricing_type}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {forRent ? (
                  <React.Fragment>
                    <div className="row mb-3">
                      <div className="col-12">
                        <div className="form-group text-right">
                          <Checkbox
                            onChange={(e) => {
                              this.setState({
                                rented: e.checked,
                              });
                              // if (e.checked) {
                              //   this.setState({ price_upon_order: false });
                              // }
                            }}
                            checked={rented}
                          />
                          <label
                            className="form-check-label mx-3"
                            htmlFor="rented"
                          >
                            العقار مؤجر حاليا
                          </label>
                          <FontAwesomeIcon
                            icon={['fas', 'business-time']}
                            size="1x"
                          />
                        </div>
                      </div>
                    </div>
                    {rented ? (
                      <div className="row mb-3">
                        <div className="col-md-6">
                          <div className="form-group text-right  mb-3">
                            <label htmlFor="rent_start">مؤجر من تاريخ:</label>
                            <div className="rent_start_at_calendar text-center">
                              <Calendar
                                className={classnames({
                                  'is-invalid': errors.rent_start,
                                })}
                                style={{ direction: 'ltr' }}
                                value={rent_start}
                                onChange={(e) => {
                                  this.setState({ rent_start: e.value });
                                  document.querySelector(
                                    '.rent_start_at_calendar .p-inputtext'
                                  ).readOnly = true;
                                }}
                                locale={ar}
                                showIcon={true}
                                dateFormat="yy-mm-dd"
                              />
                              {errors.rent_start && (
                                <div className="alert-danger">
                                  {errors.rent_start}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group text-right  mb-3">
                            <label htmlFor="rent_end">
                              مؤجر حتى تاريخ:{' '}
                              <span className="display_mandatory">(مطلوب)</span>
                            </label>
                            <div className="rent_end_at_calendar text-center">
                              <Calendar
                                className={classnames({
                                  'is-invalid': errors.rent_end,
                                })}
                                style={{ direction: 'ltr' }}
                                value={rent_end}
                                onChange={(e) => {
                                  this.setState({ rent_end: e.value });
                                  document.querySelector(
                                    '.rent_end_at_calendar .p-inputtext'
                                  ).readOnly = true;
                                }}
                                locale={ar}
                                showIcon={true}
                                dateFormat="yy-mm-dd"
                              />
                              {errors.rent_end && (
                                <div className="alert-danger">
                                  {errors.rent_end}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </React.Fragment>
                ) : null}
                {/* {!price_upon_review ? (
                  <React.Fragment>
                    {forRent ? (
                      <div className="form-group text-right  mb-3">
                        <label htmlFor="price_per_day">
                          اجار العقار باليوم:
                        </label>
                        <div className="row">
                          <div className="col-10">
                            <input
                              type="number"
                              name="price_per_day"
                              id="pricePerDay"
                              className={classnames('form-control', {
                                'is-invalid': errors.price_per_day
                              })}
                              placeholder=""
                              value={price_per_day}
                              onChange={this.onPricePerDayChange}
                            />
                            {errors.price_per_day && (
                              <div className="invalid-feedback">
                                {errors.price_per_day}
                              </div>
                            )}
                          </div>
                          <div className="col-2">ل.س</div>
                        </div>
                      </div>
                    ) : null}
                    {forSell ? (
                      <div className="row mb-3 pt-1">
                        <div className="col-6">
                          <div className="form-group text-right">
                            <Checkbox
                              onChange={e => {
                                this.setState({ IsMM: e.checked });
                                document.getElementById('totalPrice').readOnly =
                                  e.checked;
                              }}
                              checked={IsMM}
                            />
                            <label
                              className="form-check-label mx-3"
                              htmlFor="IsMM"
                            >
                              سعر المتر المربع
                            </label>
                          </div>
                        </div>
                        <div className="col-6">
                          {IsMM ? (
                            <React.Fragment>
                              <input
                                type="number"
                                name="price_per_mm"
                                id="pricePerMM"
                                className={classnames('form-control', {
                                  'is-invalid': errors.price_per_mm
                                })}
                                placeholder=""
                                value={price_per_mm}
                                onChange={this.onPricePerMMChange}
                              />
                              {errors.price_per_mm && (
                                <div className="invalid-feedback">
                                  {errors.price_per_mm}
                                </div>
                              )}
                            </React.Fragment>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                    <div className="form-group text-right  mb-3">
                      <label htmlFor="price">ثمن العقار: *</label>
                      <div className="row">
                        <div className="col-10">
                          <input
                            type="text"
                            name="price"
                            id="totalPrice"
                            className={classnames('form-control', {
                              'is-invalid': errors.price
                            })}
                            placeholder=""
                            value={price}
                            onChange={this.onPriceChange}
                          />
                        </div>
                        <div className="col-2">ل.س</div>
                      </div>

                      {errors.price && (
                        <div className="invalid-feedback">{errors.price}</div>
                      )}
                    </div>
                  </React.Fragment>
                ) : null} */}
              </div>
              {!IsField ? (
                <React.Fragment>
                  <div className="form-group text-right  mb-3">
                    <label htmlFor="status">
                      حالة البناء:{' '}
                      <span className="display_mandatory">(مطلوب)</span>
                    </label>
                    <select
                      name="status"
                      className={classnames('form-control', {
                        'is-invalid': errors.status,
                      })}
                      onChange={this.onStatusChange}
                      value={status}
                    >
                      {(generalStatus || []).map((status) => (
                        <option value={status} key={shortid.generate()}>
                          {status}
                        </option>
                      ))}
                    </select>
                    {errors.status && (
                      <div className="invalid-feedback">{errors.status}</div>
                    )}
                  </div>
                  <div
                    className="form-group text-right  mb-3"
                    style={{ display: 'none' }}
                  >
                    <label htmlFor="business_status">حالة العرض:</label>
                    <select
                      name="business_status"
                      className="form-control margin-top"
                      onChange={this.onChange}
                      value={business_status}
                      style={{ display: 'none' }}
                    >
                      <option value="جديد"> جديد</option>
                      <option value="محجوز"> محجوز</option>
                      <option value="تم تحديثه"> تم تحديثه</option>
                      <option value="مباع"> مباع</option>
                      <option value="مأرشف">مأرشف</option>
                    </select>
                  </div>
                </React.Fragment>
              ) : null}

              {/* <div className="row mb-3">
                <div className="col-md-12">
                  <div className="form-group text-right">
                    <Checkbox
                      onChange={e => this.setState({ visible: e.checked })}
                      checked={visible}
                    />
                    <label className="form-check-label mx-2" htmlFor="visible">
                      عرض العقار تلقائيا
                    </label>
                    <FontAwesomeIcon icon={['fas', 'clone']} size="1x" />
                  </div>
                </div>
              </div> */}
              {!IsField ? (
                <React.Fragment>
                  <div className="row mb-3">
                    <div className="col-6">
                      <div className="form-group text-right">
                        <Checkbox
                          onChange={(e) => {
                            this.setState({ IsBuildDate: e.checked });
                            if (!e.checked) {
                              this.setState({
                                built_at: null,
                              });
                            }
                          }}
                          checked={IsBuildDate}
                        />
                        <label className="form-check-label mx-3">
                          تاريخ البناء
                        </label>
                      </div>
                    </div>
                    <div className="col-6 text-right">
                      {IsBuildDate ? (
                        <div className="builtAtCalendar">
                          <Calendar
                            view="month"
                            yearNavigator={true}
                            yearRange="1970:2040"
                            className={classnames({
                              'is-invalid': errors.built_at,
                            })}
                            style={{ direction: 'ltr' }}
                            value={built_at}
                            onChange={(e) => {
                              this.setState({ built_at: e.value });
                              document.querySelector(
                                '.builtAtCalendar .p-inputtext'
                              ).readOnly = true;
                            }}
                            locale={ar}
                            showIcon={true}
                            dateFormat="yy-mm-dd"
                          //////dateFormat="dd/mm/yy"
                          />
                          {errors.built_at && (
                            <div className="alert-danger">
                              {errors.built_at}
                            </div>
                          )}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  {!underConstruction ? (
                    <div className="row mb-3">
                      <div className="col-6">
                        <div className="form-group text-right">
                          <Checkbox
                            onChange={(e) => {
                              this.setState({ IsReconstructed: e.checked });
                              if (!e.checked) {
                                this.setState({
                                  reconstructed_at: null,
                                });
                              }
                            }}
                            checked={IsReconstructed}
                          />
                          <label className="form-check-label mx-3">
                            تم اعادة بناؤه
                          </label>
                        </div>
                      </div>
                      <div className="col-6 text-right">
                        {IsReconstructed ? (
                          <div className="ReconstructedAtCalendar">
                            <Calendar
                              readOnly={true}
                              view="month"
                              yearNavigator={true}
                              yearRange="1970:2040"
                              className={classnames({
                                'is-invalid': errors.reconstructed_at,
                              })}
                              style={{ direction: 'ltr' }}
                              value={reconstructed_at}
                              onChange={(e) => {
                                this.setState({ reconstructed_at: e.value });
                                document.querySelector(
                                  '.ReconstructedAtCalendar .p-inputtext'
                                ).readOnly = true;
                              }}
                              locale={ar}
                              showIcon={true}
                              dateFormat="yy-mm-dd"
                            />
                            {errors.reconstructed_at && (
                              <div className="alert-danger">
                                {errors.reconstructed_at}
                              </div>
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                </React.Fragment>
              ) : null}
              {IsLookingFor ? null : (
                <div className="form-group text-right  mb-3">
                  <label htmlFor="propertyImages">تحميل صور للعقار:</label>
                  <div class="text-danger">
                    (إضافة صور ل عقارك يزيد من احتمالية ظهوره ضمن الصفحة
                    الرئيسية)
                  </div>
                  <div className="fileupload-images">
                    <div className="row">
                      <div className="col-md-5 mx-1">
                        <label
                          htmlFor="upload_images"
                          className="btn btn-primary"
                          style={{ width: '165px' }}
                          disabled={
                            this.state.uploadNewImages === true ||
                            !(
                              (this.state.uploadedImages.length ===
                                this.state.photo_name.length &&
                                this.state.photo_name.length > 0) ||
                              this.state.photo_name.length === 0
                            )
                          }
                        >
                          <input
                            type="file"
                            id="upload_images"
                            name="upload_images[]"
                            accept=".jpg, .jpeg"
                            multiple
                            className="d-none"
                            onChange={this.onUploadImages}
                            disabled={
                              this.state.uploadNewImages === true ||
                              !(
                                (this.state.uploadedImages.length ===
                                  this.state.photo_name.length &&
                                  this.state.photo_name.length > 0) ||
                                this.state.photo_name.length === 0
                              )
                            }
                          />
                          تحميل صور العقار{' '}
                          <FontAwesomeIcon
                            icon={['fas', 'plus']}
                            color="white"
                          />
                        </label>
                      </div>

                      <div className="col-md-5 mx-1">
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={this.onDeleteImages}
                          style={{ width: '165px' }}
                        >
                          حذف صور العقار{' '}
                          <FontAwesomeIcon
                            icon={['fas', 'times']}
                            color="white"
                          />
                        </button>
                      </div>

                      <div className="col-md-2"></div>
                      {errors.fileSize && (
                        <div className="bg-danger">{errors.fileSize}</div>
                      )}
                    </div>
                  </div>
                  {this.state.compressNewImages ? (
                    <React.Fragment>
                      <div style={{ position: 'relative' }}>
                        <div className="process-loader-compressor">
                          <div className="span text-center">
                            {/* <div className="location_indicator" /> */}
                            <div
                              className="spinner-border text-warning"
                              style={{ width: '3rem', height: '3rem' }}
                              role="status"
                            ></div>
                            <p className="text-danger lead text-center">
                              <span className="font-weight-bold text-warning">
                                يتم ضغط الصور
                              </span>
                              <br />
                              <span>www.</span>
                              <span className="font-weight-bold">Ikar</span>
                              <span className="font-weight-bold">.sy</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : null}
                  <div className="row">
                    {isOpen && (
                      <Lightbox
                        mainSrc={`${fullSelectedFilesArray[photoIndex][2].blob}`}
                        nextSrc={`${fullSelectedFilesArray[
                            (photoIndex + 1) % fullSelectedFilesArray.length
                          ][2].blob
                          }`}
                        prevSrc={`${fullSelectedFilesArray[
                            (photoIndex + fullSelectedFilesArray.length - 1) %
                            fullSelectedFilesArray.length
                          ][2].blob
                          }`}
                        onCloseRequest={() => this.setState({ isOpen: false })}
                        onMovePrevRequest={() =>
                          this.setState({
                            photoIndex:
                              (photoIndex + fullSelectedFilesArray.length - 1) %
                              fullSelectedFilesArray.length,
                          })
                        }
                        onMoveNextRequest={() =>
                          this.setState({
                            photoIndex:
                              (photoIndex + 1) % fullSelectedFilesArray.length,
                          })
                        }
                      />
                    )}
                  </div>
                </div>
              )}

              {/* </Dialog> */}
              <div style={{ direction: 'ltr' }}>
                <SortableList
                  axis="xy"
                  distance={this.state.distanceInPixel}
                  items={listGrid}
                  onSortEnd={this.onSortProcess}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-8 mx-auto">
              <div className="row">
                <div className="col-md-6">
                  <button
                    className="btn btn-block submit-button m-1"
                    onClick={this.onAddSubmit.bind(this, 'submit')}
                    disabled={
                      this.state.uploadNewImages === true ||
                      !(
                        (this.state.uploadedImages.length ===
                          this.state.photo_name.length &&
                          this.state.photo_name.length > 0) ||
                        this.state.photo_name.length === 0
                      )
                    }
                  >
                    اضافة عقار
                  </button>
                  {/* <input
                    type="submit"
                    value="اضافة عقار"
                    className="btn btn-block submit-button m-1"
                  /> */}
                </div>
                <div className="col-md-6">
                  <button
                    className="btn btn-block submit-button m-1"
                    onClick={this.onHandleFormClear}
                  >
                    مسح الحقول
                  </button>
                  {/* <input
                    type="submit"
                    value="مسح الحقول"
                    className="btn btn-block submit-button m-1"
                    onClick={this.onHandleFormClear}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </form>
        {/* Object.keys(this.props.property).length > 0  */}

        {Object.keys(errors).length > 0 ? (
          <div className="alert alert-warning mt-2 text-right">
            الرجاء تعبئة الحقول المطلوبة
          </div>
        ) : null}
        {processScreen === true ? (
          // <div className="process-loader">Ikar.sy</div>
          <div className="process-loader">
            <div className="span text-center">
              {/* <div className="location_indicator" /> */}
              <div
                className="spinner-border text-warning"
                style={{ width: '3rem', height: '3rem' }}
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
              <p className="text-danger lead text-center">
                <span>www.</span>
                <span className="font-weight-bold">Ikar</span>
                <span className="font-weight-bold">.sy</span>
              </p>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
AddProperty.propTypes = {
  addProperty: PropTypes.func.isRequired,
  property: PropTypes.object.isRequired,
  requestFailed: PropTypes.bool.isRequired,
  //requestFailedMessage: PropTypes.object.isRequired,
  isTakingAgencyRole: PropTypes.bool.isRequired,
  // takenAgencyRoleToken: PropTypes.string.isRequired,
  requestMessage: PropTypes.string.isRequired,
};
const mapStateToProps = (state) => ({
  property: state.property.property,
  requestFailed: state.property.requestFailed,
  requestFailedMessage: state.property.requestFailedMessage,
  isTakingAgencyRole: state.auth.isTakingAgencyRole,
  takenAgencyRoleToken: state.auth.takenAgencyRoleToken,
  requestMessage: state.property.requestMessage,
  uploadedPhotoName: state.property.uploadedPhotoName,
  directory: state.property.directory,
});
export default connect(mapStateToProps, { addProperty, addPropertyPhoto })(
  AddProperty
);
