import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getAgency } from './../../actions/agencyActions';
import {
  editAgency,
  editAgencyEmail,
  editAgencyPassword,
} from './../../actions/agencyActionsUD';
import {
  logoutProcess,
  // leaveAgencyRole2,
  leaveAgencyRole,
  loginChecking,
} from './../../actions/authActions';
import { Growl } from 'primereact/growl';
import classnames from 'classnames';
import Lightbox from 'react-image-lightbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ResReady, ResNotComplete } from './../layout/resTypes';
import { Dialog } from 'primereact/dialog';
import { syriaAreas } from './../../mainConf';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import { ToggleButton } from 'primereact/togglebutton';
import base64toblob from 'base64toblob';
import imageFileToBase64 from 'image-file-to-base64-exif';
import { imageDomain } from './../../mainConf';
import image2base64 from 'image-to-base64';

// import InputMask from 'react-input-mask';
import { noInternetMessage, mapsEnabled } from './../../mainConf';
import { Helmet } from 'react-helmet';
class EditAgency extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: '',
      pageDescription: '',
      updateEmail: false,
      updatePassword: false,
      updateAll: true,
      agency: {},
      requestSuccessMessage: '',
      showMessages: true,
      isOpen: false,
      isOpen2: false,
      scale: 100,
      quality: 75,
      selectedUsers: [],
      noMessages: true,
      areas: [],
      user: {},
      reference_id: '',
      name: '',
      description: '',
      contact_email: '',
      password: '',
      password_confirmation: '',
      mobile: '009639',
      phone: '00963',
      whatsapp: '009639',
      telegram: '009639',
      roles: [],
      gps_location: '',
      hasLocation: false,
      lat: 34.890596,
      lng: 35.881085,
      zoom: 17,
      resType: '',
      showMap: false,
      showCurrentLocation: false,
      processScreen: false,
      IsUsingGPS: false,
      propertyPosition: null,
      business_email: '',
      charging_plan_id: 1,
      city: '',
      province: 'غير محدد',
      area: 'غير محدد',
      instagram: '',
      is_blocked: false,
      is_charged: true,
      other_phones: '',
      street: '',
      twitter: '',
      facebook: '',
      legal_address: '',
      business_address: '',
      admins: [],
      agents: [],
      photoIndex: 0,
      fullSelectedFilesArray: [],
      photo_name: '',
      photo_data: '',
      photo: '',
      thumbnail: '',
      errors: {},
      processStatus: '',
      resMessages: {},
    };
  }
  async componentWillReceiveProps(nextProps, nextState) {
    var errors = {};
    var noErrors = true;
    if (nextProps.resType !== this.state.resType) {
      this.setState({
        resType: nextProps.resType,
        processScreen: false,
      });

      if (
        nextProps.requestFailed &&
        nextProps.requestFailedMessage.errorMessage !== undefined &&
        nextProps.requestFailedMessage.errorMessage !== null &&
        // !this.state.noMessages &&
        nextProps.requestFailedMessage.errorMessage.data !== undefined &&
        nextProps.requestFailedMessage.errorMessage.data.error !== undefined &&
        nextProps.requestFailedMessage.errorMessage.data.error ===
          `You have to confirm your email address before continuing.`
      ) {
        noErrors = false;
        alert(
          `تم ارسال رسالة تفعيل الى الايميل, الرجاء النقر على رابط التفعيل قبل المتابعة`
        );
        // this.onLogout();
        this.setState({
          resMessages: {
            message: `تم ارسال رسالة تفعيل الى الايميل, الرجاء النقر على رابط التفعيل قبل المتابعة`,
          },
        });
      } else if (
        nextProps.requestFailed &&
        nextProps.requestFailedMessage.errorMessage !== undefined &&
        nextProps.requestFailedMessage.errorMessage !== null &&
        // !this.state.noMessages &&
        nextProps.requestFailedMessage.errorMessage.data !== undefined &&
        nextProps.requestFailedMessage.errorMessage.data.errors !== undefined &&
        nextProps.requestFailedMessage.errorMessage.data.errors[0] !==
          undefined &&
        nextProps.requestFailedMessage.errorMessage.data.errors[0].detail !==
          undefined &&
        nextProps.requestFailedMessage.errorMessage.data.errors[0].detail ===
          `user's email must be unique. User with email: ${this.state.contact_email} already exists.`
      ) {
        noErrors = false;
        errors = {};
        errors.contact_email = 'الايميل المدخل موجود مسبقا';
        this.setState({
          resMessages: {
            message: 'الايميل المدخل موجود مسبقا',
          },
          errors,
        });
      } else if (
        nextProps.requestFailed &&
        nextProps.requestFailedMessage.errorMessage !== undefined &&
        nextProps.requestFailedMessage.errorMessage !== null &&
        // !this.state.noMessages &&
        nextProps.requestFailedMessage.errorMessage.data !== undefined &&
        nextProps.requestFailedMessage.errorMessage.data.status !== undefined &&
        nextProps.requestFailedMessage.errorMessage.data.status ===
          `Current Email and New email cannot be the same`
      ) {
        noErrors = false;
        errors = {};
        errors.contact_email =
          'لا يمكن ان يكون الايميل الجديد مطابق للايميل الحالي';
        this.setState({
          resMessages: {
            message: 'لا يمكن ان يكون الايميل الجديد مطابق للايميل الحالي',
          },
          errors,
        });
      } else if (
        nextProps.requestFailed &&
        nextProps.requestFailedMessage.errorMessage !== undefined &&
        nextProps.requestFailedMessage.errorMessage !== null &&
        nextProps.requestFailedMessage.errorMessage.data !== undefined &&
        nextProps.requestFailedMessage.errorMessage.data.errors !== undefined &&
        nextProps.requestFailedMessage.errorMessage.data.errors[0] !==
          undefined &&
        nextProps.requestFailedMessage.errorMessage.data.errors[0].detail !==
          undefined &&
        // !this.state.noMessages &&
        nextProps.requestFailedMessage.errorMessage.data.errors[0].detail ===
          `Agency's mobile must be unique. Agency with mobile: ${this.state.mobile} already exists.`
      ) {
        noErrors = false;
        errors = {};
        errors.mobile = 'رقم الموبايل المدخل موجود مسبقا';
        this.setState({
          resMessages: {
            message: 'رقم الموبايل المدخل موجود مسبقا',
          },
          errors,
        });
      } else if (
        nextProps.requestFailed &&
        nextProps.requestFailedMessage.errorMessage !== undefined &&
        nextProps.requestFailedMessage.errorMessage !== null &&
        nextProps.requestFailedMessage.errorMessage.data !== undefined &&
        nextProps.requestFailedMessage.errorMessage.data.errors !== undefined &&
        nextProps.requestFailedMessage.errorMessage.data.errors[0] !==
          undefined &&
        nextProps.requestFailedMessage.errorMessage.data.errors[0].detail !==
          undefined &&
        // !this.state.noMessages &&
        nextProps.requestFailedMessage.errorMessage.data.errors[0].detail ===
          `Create agency request body is not valid  => contact_email : is in invalid format`
      ) {
        noErrors = false;
        errors = {};
        errors.contact_email = 'الرجاء التأكد من الايميل المدخل';
        this.setState({
          resMessages: {
            message: 'الرجاء التأكد من الايميل المدخل',
          },
          errors,
        });
      }
    }
    if (
      nextProps.requestSuccessMessage !== this.state.requestSuccessMessage &&
      this.state.requestSuccessMessage !== ''
    ) {
      if (nextProps.requestSuccessMessage === 'edit is succeeded') {
        this.setState({
          requestSuccessMessage: nextProps.requestSuccessMessage,
        });
        this.growl.show({
          severity: 'success',
          summary: 'تم تعديل معلومات المكتب بنجاح',
        });
        this.handleClear(null);
        this.getAgency();
      } else if (
        nextProps.requestSuccessMessage === 'edit email is succeeded'
      ) {
        this.setState({
          requestSuccessMessage: nextProps.requestSuccessMessage,
        });
        this.growl.show({
          severity: 'success',
          summary: 'الرجاء مراجعة البريد الالكتروني لتفعيل الايميل الجديد',
        });
        // await this.props.loginChecking();
        if (this.props.isTakingAgencyRole) {
          await this.leaveAgencyRole();
          window.location = `/`;
        } else {
          setTimeout(function () {
            window.location = `/`;
          }, 3000);
        }
        // this.handleClear(null);
        // this.getAgency();
      } else if (nextProps.requestSuccessMessage === 'error in agency edit') {
        this.setState({
          requestSuccessMessage: nextProps.requestSuccessMessage,
        });
        if (noErrors === true) {
          this.growl.show({
            severity: 'error',
            summary:
              'حدثت مشكلة في تعديل بيانات المكتب الرجاء المحاولة بعد قليل',
          });

          this.getAgency();
        }
      }
    }
    //  if (nextProps.agency.processStatus !== this.state.processStatus) {
    else if (nextProps.agency !== this.state.agency) {
      var {
        reference_id,
        name,
        description,
        contact_email,
        mobile,
        phone,
        whatsapp,
        telegram,
        roles,
        gps_location,
        business_email,
        charging_plan_id,
        city,
        area,
        facebook,
        instagram,
        is_blocked,
        is_charged,
        other_phones,
        province,
        street,
        twitter,
        legal_address,
        business_address,
        // password,
        agents,
        processStatus,
        // photo_name,
        // photo_data
        photo,
        thumbnail,
      } = nextProps.agency;
      this.setState({
        province,
      });
      await this.onProvinceChange2(province);
      // let selectedFileArray = [];
      // let compressedImage = {};
      // let fullSelectedFilesArray = [];
      // compressedImage.blob = nextProps.agency.photo_data;
      // compressedImage.name = nextProps.agency.photo_name;
      // selectedFileArray.push(
      //   nextProps.agency.photo_name,
      //   '0',
      //   compressedImage,
      //   1
      // );
      // fullSelectedFilesArray.push(selectedFileArray);
      this.setState({
        reference_id,
        name,
        description: description === null ? '' : description,
        contact_email,
        mobile: mobile === null ? this.state.mobile : mobile,
        phone: phone !== null ? phone : this.state.phone,
        whatsapp: whatsapp === null ? this.state.whatsapp : whatsapp,
        telegram: telegram === null ? this.state.telegram : telegram,
        roles,
        gps_location,
        business_email: business_email === null ? '' : business_email,
        charging_plan_id,
        city,
        area: area,
        facebook: facebook === null ? '' : facebook,
        instagram: instagram === null ? '' : instagram,
        is_blocked,
        is_charged,
        other_phones: other_phones === null ? '' : other_phones,
        //province,
        street: street === null ? '' : street,
        twitter: twitter === null ? '' : twitter,
        legal_address: legal_address === null ? '' : legal_address,
        business_address: business_address === null ? '' : business_address,
        //password,
        agents,
        processStatus,
        photo,
        thumbnail,
        // photo_name,
        // photo_data,
        // fullSelectedFilesArray
      });
      if (
        gps_location !== null &&
        gps_location !== undefined &&
        gps_location !== '' &&
        mapsEnabled
      ) {
        const lat = parseFloat(gps_location.split(',')[0]);
        const lng = parseFloat(gps_location.split(',')[1]);
        this.setState({
          lat,
          lng,
          IsUsingGPS: true,
          propertyPosition: { lat: lat, lng: lng },
        });
      }
    }
    if (
      nextProps.resType !== undefined &&
      nextProps.resType !== this.state.resType
    ) {
      const { resType } = nextProps;
      this.setState({ resType });
    }
  }
  handleToggleButton = (e) => {
    this.setState({ showMap: e.value });
    if (!e.value) {
      this.setState({
        propertyPosition: null,
        gps_location: '',
        IsUsingGPS: false,
      });
    }
  };
  getAgency = () => {
    if (
      this.props.match !== undefined &&
      this.props.match.params !== undefined
    ) {
      const { id } = this.props.match.params;
      var AgencyRefId = id;
    } else {
      AgencyRefId = this.props.takenAgencyRoleAgencyRefId;
      if (!this.props.isTakingAgencyRole) {
        AgencyRefId = this.props.agencyRefId;
      }
    }

    const ikarAdmin = this.props.ikarAdmin;
    const isTakingAgencyRole = this.props.isTakingAgencyRole;
    const takenAgencyRoleToken = this.props.takenAgencyRoleToken;
    this.props.getAgency(
      ikarAdmin,
      isTakingAgencyRole,
      AgencyRefId,
      takenAgencyRoleToken
    );
  };
  leaveAgencyRole = async () => {
    await this.props.leaveAgencyRole();
    // leaveAgencyRole2,
    // await this.props.loginChecking();
    //window.location = `/adminDashBoard/agencies`;
    // this.props.history.push('/adminDashBoard/agencies');
    // this.props.showMessage('تم تسجيل الدخول كمسؤول نظام');
    // this.props.onPathChange('/adminDashBoard/agencies');
    // this.setState({ isTakingAgencyRole: false });
  };
  onLogout = () => {
    this.props.logoutProcess();
    localStorage.removeItem('ikar_token');
    localStorage.removeItem('ikar_a');
    localStorage.removeItem('ikar_a_a');
    localStorage.removeItem('ikar_agency_ref_id');
    localStorage.removeItem('ikar_email');
    localStorage.removeItem('ikar_is_b');
    localStorage.removeItem('first_login');

    localStorage.removeItem('ikar_is_taking_agency_role');
    localStorage.removeItem('ikar_taken_agency_role_email');
    localStorage.removeItem('ikar_taken_agency_role_token');
    localStorage.removeItem('ikar_taken_agency_role_info');
    localStorage.removeItem('ikar_taken_agency_role_agency_refId');
    this.setState({ isTakingAgencyRole: false });
    // this.props.onPathChange('/');
    // this.props.history.push('/');
  };
  componentDidMount() {
    if (
      this.props.match !== undefined &&
      this.props.match.params !== undefined
    ) {
      const { id } = this.props.match.params;
      var AgencyRefId = id;
    } else {
      AgencyRefId = this.props.takenAgencyRoleAgencyRefId;
      if (!this.props.isTakingAgencyRole) {
        AgencyRefId = this.props.agencyRefId;
      }
    }

    const ikarAdmin = this.props.ikarAdmin;
    const isTakingAgencyRole = this.props.isTakingAgencyRole;
    //var AgencyRefId = this.props.takenAgencyRoleAgencyRefId;
    const takenAgencyRoleToken = this.props.takenAgencyRoleToken;
    // if (!isTakingAgencyRole) {
    //   AgencyRefId = this.props.agencyRefId;
    // }
    this.props.getAgency(
      ikarAdmin,
      isTakingAgencyRole,
      AgencyRefId,
      takenAgencyRoleToken
    );

    // jQuery(function($) {
    //   $('#userTable').on('click', '.clickable-row', function(event) {
    //     $(this)
    //       .addClass('table-success')
    //       .siblings()
    //       .removeClass('table-success');
    //   });
    // });
  }
  // name,contact_email,password,is_agency_admin,is_ikar_admin,surname,title,description,roles,mobile,facebook,whatsapp,agency_ref_id,agency_rank,
  onSubmit = async (e) => {
    e.preventDefault();
    await this.setState({
      requestSuccessMessage: 'ready',
      processStatus: '',
      agency: {},
    });

    const {
      name,
      description,
      mobile,
      phone,
      reference_id,
      whatsapp,
      telegram,
      roles,
      gps_location,

      business_email,
      charging_plan_id,
      // city,
      area,
      instagram,
      is_blocked,
      is_charged,
      other_phones,
      province,
      street,
      twitter,
      facebook,
      legal_address,
      business_address,
      // admins,
      agents,
      // fullSelectedFilesArray,
      photo_name,
      photo_data,
      // photo,
      // thumbnail
    } = this.state;

    var errors = {};
    // if (contact_email.trim() === '') {
    //   errors.contact_email = 'يجب ادخال الايميل';
    // } else if (!/[\w+\-.]+@[a-zA-Z.-]+\.[a-z]{2,3}/.test(contact_email)) {
    //   errors.contact_email = 'يجب التأكد من الايميل المدخل';
    // }
    if (business_email !== '' && business_email !== null) {
      if (!/[\w+\-.]+@[a-zA-Z.-]+\.[a-z]{2,3}/.test(business_email)) {
        errors.business_email = 'يجب التأكد من الايميل المدخل';
      }
    }
    // if (password.trim() === '') {
    //   errors.password = 'يجب ادخال كلمة السر';
    // } else if (password.length < 9) {
    //   errors.password = 'كلمة السر على الاقل تسعة رموز';
    // }
    // if (password_confirmation.trim() === '') {
    //   errors.password_confirmation = 'يجب ادخال اعادة كلمة السر';
    // } else if (password !== password_confirmation) {
    //   errors.password_confirmation = 'كلمتا السر غير متطابقتين';
    // }
    if (name.trim() === '') {
      errors.name = 'يجب ادخال الاسم';
    }
    if (photo_name.trim() === '' && photo_data.length > 0) {
      errors.photo_name = 'يجب ادخال اسم الشعار';
    }
    if (name.length > 30) {
      errors.name = 'يجب الا يتجاوز اسم المكتب المدخل 30 حرف';
    }
    if (province === 'غير محدد' || province === '') {
      errors.province = 'يجب ادخال المحافظة';
    }

    // if (area === 'غير محدد' || area === '') {
    //   errors.area = 'يجب ادخال المنطقة';
    // }

    var area1=area;
    if(area === 'غير محدد' || area === '')
       area1=null;

    // if (city.trim() === '') {
    //   this.setState({
    //     errors: { city: 'يجب ادخال العنوان التفصيلي' }
    //   });
    //   return;
    // }
    // if (phone.trim() === '') {
    //   this.setState({ errors: { phone: 'يجب ادخال رقم الهاتف' } });
    //   return;
    // }
    if (
      (mobile !== null && mobile.trim() === '') ||
      mobile.trim() === '009639'
    ) {
      errors.mobile = 'يجب ادخال رقم الموبايل';
    } else if (mobile.trim() !== '' && mobile.trim() !== '009639') {
      if (String(mobile.trim()).charAt(0) !== '0') {
        errors.mobile = 'يجب التأكد من رقم الموبايل';
      }
      if (mobile.trim().length < 14 || mobile.trim().length > 14) {
        errors.mobile = 'يجب التأكد من رقم الموبايل';
      }
      if (mobile.trim().length > 1 && !this.isNumeric(mobile.trim())) {
        errors.mobile = 'يجب التأكد من رقم الموبايل';
      }
    }

    if (other_phones !== null && other_phones.trim().length > 10) {
      errors.other_phones = 'يجب التأكد من رقم الاتصال';
    }

    if (
      other_phones !== null &&
      other_phones.trim().length > 1 &&
      !this.isNumeric(other_phones.trim())
    ) {
      errors.other_phones = 'يجب التأكد من رقم الاتصال';
    }

    if (
      whatsapp !== null &&
      whatsapp.trim() !== '' &&
      whatsapp.trim() !== '009639'
    ) {
      if (String(whatsapp.trim()).charAt(0) !== '0') {
        errors.whatsapp = 'يجب التأكد من رقم الواتس أب';
      }
      if (whatsapp.trim().length < 14 || whatsapp.trim().length > 14) {
        errors.whatsapp = 'يجب التأكد من رقم الواتس أب';
      }
      if (whatsapp.trim().length > 1 && !this.isNumeric(whatsapp.trim())) {
        errors.whatsapp = 'يجب التأكد من رقم الواتس أب';
      }
    }

    if (
      telegram !== null &&
      telegram.trim() !== '' &&
      telegram.trim() !== '009639'
    ) {
      if (String(telegram.trim()).charAt(0) !== '0') {
        errors.telegram = 'يجب التأكد من رقم التيلغرام';
      }
      if (telegram.trim().length < 14 || telegram.trim().length > 14) {
        errors.telegram = 'يجب التأكد من رقم التيلغرام';
      }
      if (telegram.trim().length > 1 && !this.isNumeric(telegram.trim())) {
        errors.telegram = 'يجب التأكد من رقم التيلغرام';
      }
    }

    if (phone !== null && phone.trim() !== '' && phone.trim() !== '00963') {
      if (String(phone.trim()).charAt(0) !== '0') {
        errors.phone = 'يجب التأكد من رقم الهاتف الأرضي';
      }
      if (phone.trim().length > 7 && phone.trim().length < 11) {
        errors.phone = 'يجب التأكد من رقم الهاتف الأرضي';
      }
      if (phone.trim().length > 1 && !this.isNumeric(phone.trim())) {
        errors.phone = 'يجب التأكد من رقم الهاتف الأرضي';
      }
    }
    // if (telegram.trim() !== "" && telegram.trim() !== "09") {
    //   if (String(telegram.trim()).charAt(0) !== "0") {
    //     errors.telegram = "يجب التأكد من رقم ال تيلغرام";
    //   }
    //   if (telegram.trim().length < 10) {
    //     errors.telegram = "يجب التأكد من رقم ال تيلغرام";
    //   }
    // }

    // if (whatsapp.trim() !== "" && whatsapp.trim() !== "09") {
    //   if (String(whatsapp.trim()).charAt(0) !== "0") {
    //     errors.whatsapp = "يجب التأكد من رقم ال واتساب";
    //   }
    //   if (whatsapp.trim().length < 10) {
    //     errors.whatsapp = "يجب التأكد من رقم ال واتساب";
    //   }
    // }
    // if (facebook.trim() === '') {
    //   this.setState({ errors: { facebook: 'يجب ادخال عنوان صفحة ال فيسبوك' } });
    //   return;
    // }
    // if (legal_address.trim() === '') {
    //   this.setState({
    //     errors: { legal_address: 'يجب ادخال العنوان ' }
    //   });
    //   return;
    // }
    // if (business_address.trim() === '') {
    //   this.setState({
    //     errors: { business_address: 'يجب ادخال العنوان ' }
    //   });
    //   return;
    // }
    // if (contact_email.trim() === '') {
    //   this.setState({
    //     errors: { contact_email: 'يجب ادخال الايميل' }
    //   });
    //   return;
    // }
    var Whatsapp = '';
    if (whatsapp === null) {
      Whatsapp = '';
    } else {
      Whatsapp = whatsapp;
    }

    var Telegram = '';
    if (telegram === null) {
      Telegram = '';
    } else {
      Telegram = telegram;
    }
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    await this.setState({
      errors: {},
      resMessages: {},
      processScreen: true,
      resType: '',
    });
    const agency = {
      reference_id,
      name: name === '' ? null : name,
      description: description === '' ? null : description,
      //contact_email: contact_email === '' ? null : contact_email,
      mobile:
        mobile === null ||
        mobile.replace(/\s/g, '') === '' ||
        mobile.replace(/\s/g, '') === '009639'
          ? null
          : mobile.replace(/\s/g, ''),

      // phone: phone.replace(/\s/g, '') === '' ? null : phone.replace(/\s/g, ''),
      phone:
        phone === null ||
        phone.replace(/\s/g, '') === '00963' ||
        phone.replace(/\s/g, '').length === 7
          ? null
          : phone.replace(/\s/g, ''),
      whatsapp:
        Whatsapp === null ||
        Whatsapp.replace(/\s/g, '') === '' ||
        Whatsapp.replace(/\s/g, '') === '009639'
          ? null
          : Whatsapp.replace(/\s/g, ''),

      telegram:
        Telegram === null ||
        Telegram.replace(/\s/g, '') === '' ||
        Telegram.replace(/\s/g, '') === '009639'
          ? null
          : Telegram.replace(/\s/g, ''),

      roles: roles === '' ? null : roles,
      gps_location: gps_location === '' ? null : gps_location,
      business_email: business_email === '' ? null : business_email,
      charging_plan_id: charging_plan_id === '' ? null : charging_plan_id,
      city: area1 === '' ? null : area1,
      area: area1 === '' ? null : area1,
      facebook: facebook === '' ? null : facebook,
      instagram: instagram === '' ? null : instagram,
      is_blocked: is_blocked === '' ? null : is_blocked,
      is_charged: is_charged === '' ? null : is_charged,
      other_phones: other_phones === '' ? null : other_phones,
      province: province === '' ? null : province,
      street: street === '' ? null : street,
      twitter: twitter === '' ? null : twitter,
      legal_address: legal_address === '' ? null : legal_address,
      business_address: business_address === '' ? null : business_address,
      //password: password === '' ? null : password,
      agents: agents === '' ? null : agents,
      photo_name:
        photo_name === 'no' ? '' : photo_name === '' ? null : photo_name,
      photo_data:
        photo_data === 'no' ? '' : photo_data === '' ? null : photo_data,
    };

    const agencyDate = { agency };
    const isTakingAgencyRole = this.props.isTakingAgencyRole;
    // const takenAgencyRoleAgencyRefId = this.props.takenAgencyRoleAgencyRefId;
    const takenAgencyRoleToken = this.props.takenAgencyRoleToken;
    const processType = 'edit is succeeded';
    this.props.editAgency(
      agencyDate,
      isTakingAgencyRole,
      takenAgencyRoleToken,
      processType
    );

    this.setState({ noMessages: false });
  };

  onSubmitEmail = async (e) => {
    e.preventDefault();
    await this.setState({
      requestSuccessMessage: 'ready',
      processStatus: '',
      agency: {},
    });

    const { contact_email } = this.state;

    var errors = {};
    if (contact_email.trim() === '') {
      errors.contact_email = 'يجب ادخال الايميل';
    } else if (!/[\w+\-.]+@[a-zA-Z.-]+\.[a-z]{2,3}/.test(contact_email)) {
      errors.contact_email = 'يجب التأكد من الايميل المدخل';
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    await this.setState({
      errors: {},
      resMessages: {},
      processScreen: true,
      resType: '',
    });
    const user = {
      email: contact_email === '' ? null : contact_email,
    };

    const agencyDate = { user };

    const isTakingAgencyRole = this.props.isTakingAgencyRole;
    // const takenAgencyRoleAgencyRefId = this.props.takenAgencyRoleAgencyRefId;
    const takenAgencyRoleToken = this.props.takenAgencyRoleToken;

    const processType = 'edit email is succeeded';
    await this.props.editAgencyEmail(
      agencyDate,
      isTakingAgencyRole,
      takenAgencyRoleToken,
      processType
    );

    this.setState({ noMessages: false });
  };

  onSubmitPassword = async (e) => {
    e.preventDefault();
    await this.setState({
      requestSuccessMessage: 'ready',
      processStatus: '',
      agency: {},
    });

    const { password, password_confirmation } = this.state;

    var errors = {};

    if (password.trim() === '') {
      errors.password = 'يجب ادخال كلمة السر';
    } else if (password.length < 9) {
      errors.password = 'كلمة السر على الاقل تسعة رموز';
    }
    if (password_confirmation.trim() === '') {
      errors.password_confirmation = 'يجب ادخال اعادة كلمة السر';
    } else if (password !== password_confirmation) {
      errors.password_confirmation = 'كلمتا السر غير متطابقتين';
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    await this.setState({
      errors: {},
      resMessages: {},
      processScreen: true,
      resType: '',
    });
    const user = {
      password: password === '' ? null : password,
      password_confirmation,
    };

    const agencyDate = { user };
    const isTakingAgencyRole = this.props.isTakingAgencyRole;
    // const takenAgencyRoleAgencyRefId = this.props.takenAgencyRoleAgencyRefId;
    const takenAgencyRoleToken = this.props.takenAgencyRoleToken;
    const processType = 'edit is succeeded';
    this.props.editAgencyPassword(
      agencyDate,
      isTakingAgencyRole,
      takenAgencyRoleToken,
      processType
    );

    this.setState({ noMessages: false });
  };
  handleClear = (e) => {
    if (e !== null) {
      e.preventDefault();
    }
    this.setState({
      selectedUsers: [],
      noMessages: true,
      isOpen: false,
      areas: [],
      user: {},
      name: '',
      description: '',
      contact_email: '',
      password: '',
      password_confirmation: '',
      mobile: '009639',
      phone: '00963',
      reference_id: '',
      whatsapp: '009639',
      telegram: '009639',
      roles: [],
      gps_location: '',
      showMap: false,
      showCurrentLocation: false,
      processScreen: false,
      IsUsingGPS: false,
      propertyPosition: null,
      business_email: '',
      charging_plan_id: 1,
      city: '',
      area: 'غير محدد',
      instagram: '',
      is_blocked: false,
      is_charged: true,
      other_phones: '',
      province: 'غير محدد',
      street: '',
      twitter: '',
      facebook: '',
      legal_address: '',
      business_address: '',
      admins: [],
      agents: [],
      errors: {},
      photoIndex: 0,
      fullSelectedFilesArray: [],
      photo_name: '',
      photo_data: '',
      photo: '',
      thumbnail: '',
      processStatus: '',
    });
  };
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  onMobileChange = (e) => {
    // if (e.target.value.length > 10 || e.target.value.length === 1) {
    //   return;
    // }
    // if (/^\d+$/.test(e.target.value)) {
    //   this.setState({ [e.target.name]: e.target.value });
    // }

    if (e.target.value.length > 14 || e.target.value.length === 4) {
      return;
    }
    if (/^(009639)\d{0,8}?$/.test(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  onPhoneChange = (e) => {
    if (e.target.value.length > 16 || e.target.value.length === 3) {
      return;
    }
    if (/^(00963)\d{0,9}?$/.test(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  onNameChange = (e) => {
    if (e.target.value.length > 30) {
      return;
    }
    this.setState({ [e.target.name]: e.target.value });
  };
  onCheckBoxChange = (e) =>
    this.setState({ [e.target.name]: e.target.checked });
  onProvinceChange = (e) => {
    this.setState({ [e.target.name]: e.target.value, area: 'غير محدد' });
    if (e.target.value === 'دمشق') {
      //دمشق
      this.setState({ areas: syriaAreas.damascusAreas, phone: '0096311' });
    } else if (e.target.value === 'ريف دمشق') {
      //ريف دمشق
      this.setState({
        areas: syriaAreas.rifDamascusAreas,
        phone: '0096311',
      });
    } else if (e.target.value === 'حلب') {
      //حلب
      this.setState({
        areas: syriaAreas.aleppoAreas,
        phone: '0096321',
      });
    } else if (e.target.value === 'طرطوس') {
      //طرطوس
      this.setState({
        areas: syriaAreas.tartousAreas,
        phone: '0096343',
      });
    } else if (e.target.value === 'اللاذقية') {
      //اللاذقية
      this.setState({ areas: syriaAreas.latakiaAreas, phone: '0096341' });
    } else if (e.target.value === 'حمص') {
      //حمص
      this.setState({
        areas: syriaAreas.homsAreas,
        phone: '0096331',
      });
    } else if (e.target.value === 'حماة') {
      //حماة
      this.setState({ areas: syriaAreas.hamaAreas, phone: '0096333' });
    } else if (e.target.value === 'السويداء') {
      //السويداء
      this.setState({ areas: syriaAreas.asuwaydaAreas, phone: '0096316' });
    } else if (e.target.value === 'الحسكة') {
      //الحسكة
      this.setState({
        areas: syriaAreas.hasakaAreas,
        phone: '0096352',
      });
    } else if (e.target.value === 'درعا') {
      //درعا
      this.setState({ areas: syriaAreas.daraaAreas, phone: '0096315' });
    } else if (e.target.value === 'ادلب') {
      //ادلب
      this.setState({
        areas: syriaAreas.idlibAreas,
        phone: '0096323',
      });
    } else if (e.target.value === 'دير الزور') {
      //دير الزور
      this.setState({ areas: syriaAreas.deirezorAreas, phone: '0096351' });
    } else if (e.target.value === 'القنيطرة') {
      //القنيطرة
      this.setState({ areas: syriaAreas.quneitraAreas, phone: '0096314' });
    } else if (e.target.value === 'الرقة') {
      //الرقة
      this.setState({ areas: syriaAreas.raqqaAreas, phone: '0096322' });
    } else {
      this.setState({ areas: [], phone: '00963' });
    }
  };
  onProvinceChange2 = (province) => {
    if (province === 'دمشق') {
      //دمشق
      this.setState({
        areas: syriaAreas.damascusAreas,
        phone: '0096311',
      });
    } else if (province === 'ريف دمشق') {
      //ريف دمشق
      this.setState({
        areas: syriaAreas.rifDamascusAreas,
        phone: '0096311',
      });
    } else if (province === 'حلب') {
      //حلب
      this.setState({
        areas: syriaAreas.aleppoAreas,
        phone: '0096321',
      });
    } else if (province === 'طرطوس') {
      //طرطوس
      this.setState({
        areas: syriaAreas.tartousAreas,
        phone: '0096343',
      });
    } else if (province === 'اللاذقية') {
      //اللاذقية
      this.setState({ areas: syriaAreas.latakiaAreas, phone: '0096341' });
    } else if (province === 'حمص') {
      //حمص
      this.setState({
        areas: syriaAreas.homsAreas,
        phone: '0096331',
      });
    } else if (province === 'حماة') {
      //حماة
      this.setState({ areas: syriaAreas.hamaAreas, phone: '0096333' });
    } else if (province === 'السويداء') {
      //السويداء
      this.setState({
        areas: syriaAreas.asuwaydaAreas,
        phone: '0096316',
      });
    } else if (province === 'الحسكة') {
      //الحسكة
      this.setState({
        areas: syriaAreas.hasakaAreas,
        phone: '0096352',
      });
    } else if (province === 'درعا') {
      //درعا
      this.setState({ areas: syriaAreas.daraaAreas, phone: '0096315' });
    } else if (province === 'ادلب') {
      //ادلب
      this.setState({
        areas: syriaAreas.idlibAreas,
        phone: '0096323',
      });
    } else if (province === 'دير الزور') {
      //دير الزور
      this.setState({
        areas: syriaAreas.deirezorAreas,
        phone: '0096351',
      });
    } else if (province === 'القنيطرة') {
      //القنيطرة
      this.setState({
        areas: syriaAreas.quneitraAreas,
        phone: '0096314',
      });
    } else if (province === 'الرقة') {
      //الرقة
      this.setState({ areas: syriaAreas.raqqaAreas, phone: '0096322' });
    } else {
      this.setState({ areas: [], phone: '00963' });
    }
  };
  onMapShowClick = (e) => {
    e.preventDefault();
    this.setState({ showCurrentLocation: true });
  };
  onMapClick = async (e) => {
    const lat = e.latlng.lat;
    const lng = e.latlng.lng;
    const gps_location = `${lat},${lng}`;

    await this.setState({
      propertyPosition: e.latlng,
      gps_location,
      IsUsingGPS: true,
    });
  };
  onUploadImage = async (e) => {
    this.setState({ errors: {} });
    var propertyImages = e.target.files;
    var selectedFileArray = [];
    var fullSelectedFilesArray = [];
    var url = '';
    var obj = {};
    for (var i = 0; i < propertyImages.length; i++) {
      var filesize = (propertyImages[i].size / 1024 / 1024).toFixed(4);
      if (filesize <= 5) {
        selectedFileArray = [];
        obj = {};
        url = URL.createObjectURL(propertyImages[i]);
        obj = propertyImages[i];
        obj.objectURL = url;
        var Base64ArrayStr = await this.getBase64(obj);
        var compressedImage = await this.onImageCompressor(obj, Base64ArrayStr);

        selectedFileArray.push(
          compressedImage.compressed.name,
          compressedImage.compressed.size,
          compressedImage.compressed,
          i
        );

        fullSelectedFilesArray.push(selectedFileArray);
      } else {
        this.setState({
          errors: {
            fileSize: `الملف ${propertyImages[i].name} حجمه اكبر من 5 ميغا بايت`,
          },
        });
        return;
      }
    }
    var Base64Array = [];
    for (var ii = 0; ii < fullSelectedFilesArray.length; ii++) {
      Base64ArrayStr = fullSelectedFilesArray[ii][2].base64;
      Base64Array.push(Base64ArrayStr);
    }
    this.setState({
      fullSelectedFilesArray,
      photo_data: Base64Array[0],
      photo_name: compressedImage.compressed.name,
    });
  };
  onDeleteImage = () => {
    this.setState({
      fullSelectedFilesArray: [],
      errors: {},
      photo_data: '',
      photo_name: '',
    });
    document.getElementById('upload_images').value = '';
    image2base64(this.state.photo)
      .then((response) => {})
      .catch((error) => {});
    // Array.from(
    //   document.getElementsByClassName('p-button-text-icon-left')
    // ).forEach(function(element, index, array) {
    //   if (element.textContent === 'حذف كافة صور العقار') {

    //     element.click();
    //   }
    // });
  };
  onDeleteImage2 = () => {
    this.setState({
      fullSelectedFilesArray: [],
      errors: {},
      photo_data: 'no',
      photo_name: 'no',
      photo: '',
    });
  };
  getBase64 = async (file) => {
    var resultValue = imageFileToBase64(file);
    return resultValue;
  };
  addImgObj = (imageFile) => {
    return new Promise((resolve) => {
      let img = new Image();
      img.src = imageFile;

      img.addEventListener('load', function () {
        var obj = {};
        obj.width1 = this.width;
        obj.height1 = this.height;
        resolve(obj);
      });
    });
  };
  onImageCompressor = async (originImage, imageFile) => {
    var resultObjToPass = {};
    let canvas = document.createElement('canvas');
    let ctx = canvas.getContext('2d');

    let img = new Image();
    img.src = imageFile;
    await img.addEventListener('load', function () {});
    if (img.width === 0) {
      const obj = await this.addImgObj(imageFile);
    }
    let scale = this.state.scale / 100;
    if (img.width > 1200) {
      scale = 1200 / img.width;
      scale = scale.toFixed(4);
    }
    let width = img.width * scale;
    let height = img.height * scale;
    canvas.setAttribute('width', width);
    canvas.setAttribute('height', height);
    ctx.drawImage(img, 0, 0, width, height);
    let quality = this.state.quality ? this.state.quality / 100 : 1;
    let base64 = canvas.toDataURL('image/jpeg', quality);
    let fileName = originImage.name;

    let lastDot = fileName.lastIndexOf('.');
    fileName = fileName.substr(0, lastDot); // + '.jpeg'

    let objToPass = {
      canvas: canvas,
      original: originImage,
      compressed: {
        blob: this.toBlob(base64),
        base64: base64,
        name: fileName,
        file: this.buildFile(base64, fileName),
      },
    };

    objToPass.compressed.size = Math.round(
      objToPass.compressed.file.size / 1000
    ); //+ ' kB'
    objToPass.compressed.type = 'image/jpeg';
    resultObjToPass = await objToPass;
    return resultObjToPass;
  };
  toBlob = (imgUrl) => {
    let blob = base64toblob(imgUrl.split(',')[1], 'image/jpeg');
    let url = window.URL.createObjectURL(blob);
    return url;
  };
  buildFile = (blob, name) => {
    return new File([blob], name);
  };
  isNumeric = (num) => {
    // return !isNaN(num);
    // return /^-{0,1}\d+$/.test(num);
    return /^\d+$/.test(num);
  };
  onUpdateAll = (e) => {
    this.setState({
      updateAll: true,
      updateEmail: false,
      updatePassword: false,
    });
    this.getAgency();
  };
  onUpdateEmail = (e) => {
    this.setState({
      updateAll: false,
      updateEmail: true,
      updatePassword: false,
    });
    this.getAgency();
  };
  onUpdatePassword = (e) => {
    this.setState({
      updateAll: false,
      updateEmail: false,
      updatePassword: true,
    });
    this.getAgency();
  };
  // mapRef = createRef<Map>()
  render() {
    const position = [this.state.lat, this.state.lng];
    const {
      updateAll,
      updateEmail,
      updatePassword,
      resMessages,
      // selectedUsers,
      // noMessages,
      isOpen,
      isOpen2,
      areas,
      // user,
      name,
      description,
      contact_email,
      password,
      password_confirmation,
      mobile,
      phone,
      // reference_id,
      whatsapp,
      telegram,
      // roles,
      gps_location,
      showMap,
      showCurrentLocation,
      // processScreen,
      IsUsingGPS,
      propertyPosition,
      business_email,
      // charging_plan_id,
      // city,
      area,
      instagram,
      // is_blocked,
      // is_charged,
      other_phones,
      province,
      street,
      twitter,
      facebook,
      legal_address,
      business_address,
      // admins,
      // agents,
      fullSelectedFilesArray,
      photoIndex,
      photo_name,
      // photo_data,
      photo,
      resType,
      // thumbnail,
      errors,
      processScreen,
    } = this.state;
    var photoName = '';
    if (
      photo !== null &&
      photo !== undefined &&
      photo[0] !== undefined &&
      photo[0] !== ''
    ) {
      photoName = photo[0].split('/').pop();
    }
    return (
      <div>
        <Helmet>
          <title>{this.state.pageTitle}</title>
          <meta name="description" content={this.state.pageDescription} />
          <meta name="robots" content="noindex" />
        </Helmet>
        <Growl ref={(el) => (this.growl = el)} />
        {resType === 'complete' ? (
          <React.Fragment>
            <div className="row">
              <div className="col-md-10 mx-auto">
                <div className="text-center pb-4 pt-3">
                  <h1 className="main_title">
                    <FontAwesomeIcon
                      icon={['fas', 'address-card']}
                      size="1x"
                      className="text-primary mx-2"
                    />
                    تعديل مكتب عقاري
                  </h1>
                </div>
              </div>
            </div>
            <Dialog
              header="موقع المكتب المحدد على الخريطة"
              //footer={mapFooter}
              // iconsTemplate={mapIcon}
              visible={showCurrentLocation}
              style={{ width: '90vw', minHeight: '70vh' }}
              modal={true}
              onHide={() => this.setState({ showCurrentLocation: false })}
            >
              <div>
                {showCurrentLocation ? (
                  mapsEnabled ? (
                    <Map
                      center={position}
                      zoom={this.state.zoom}
                      style={{ width: '85vw', minHeight: '65vh' }}
                    >
                      <TileLayer
                        attribution='<a href="http://ikar.sy">Ikar.sy</a> &amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                      />
                      <Marker position={position}>
                        <Popup>
                          <span className="display_8">{name}</span>
                        </Popup>
                      </Marker>
                    </Map>
                  ) : (
                    noInternetMessage
                  )
                ) : null}
              </div>
            </Dialog>

            {processScreen ? (
              // <div className="process-loader">Ikar.sy</div>
              <div className="process-loader2">
                <div className="span text-center">
                  {/* <div className="location_indicator" /> */}
                  <div
                    className="spinner-border text-warning"
                    style={{ width: '3rem', height: '3rem' }}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                  <p className="text-danger lead text-center">
                    <span>www.</span>
                    <span className="font-weight-bold">Ikar</span>
                    <span className="font-weight-bold">.sy</span>
                  </p>
                </div>
              </div>
            ) : null}
            <div className="text-center mb-4">
              <button
                className={classnames(
                  'btn',
                  'mx-2',
                  'my-1',
                  {
                    'btn-warning': updateAll,
                  },
                  {
                    'btn-primary': !updateAll,
                  }
                )}
                style={{ width: '190px' }}
                onClick={this.onUpdateAll}
              >
                <span className="update-agency-button">
                  تعديل معلومات المكتب
                </span>
              </button>

              <button
                className={classnames(
                  'btn',
                  'mx-2',
                  'my-1',
                  {
                    'btn-warning': updateEmail,
                  },
                  {
                    'btn-primary': !updateEmail,
                  }
                )}
                style={{ width: '190px' }}
                onClick={this.onUpdateEmail}
              >
                <span className="update-agency-button">تعديل ايميل المكتب</span>
              </button>

              <button
                className={classnames(
                  'btn',
                  'mx-2',
                  'my-1',
                  {
                    'btn-warning': updatePassword,
                  },
                  {
                    'btn-primary': !updatePassword,
                  }
                )}
                style={{ width: '190px' }}
                onClick={this.onUpdatePassword}
              >
                <span className="update-agency-button">
                  تعديل كلمة سر المكتب
                </span>
              </button>
            </div>
            {updateAll ? (
              <div className="row">
                <div className="col-md-10 mx-auto">
                  <form
                    // onSubmit={this.onSubmit}
                    style={{ direction: 'rtl' }}
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group text-right mb-4">
                          <label htmlFor="name">
                            اسم المكتب:{' '}
                            <span className="display_mandatory">(مطلوب)</span>
                          </label>
                          <input
                            type="text"
                            className={classnames('form-control', {
                              'is-invalid': errors.name,
                            })}
                            name="name"
                            value={name}
                            onChange={this.onNameChange}
                          />
                          {errors.name && (
                            <div className="invalid-feedback">
                              {errors.name}
                            </div>
                          )}
                        </div>
                        <div className="form-group text-right  mb-4">
                          <label htmlFor="province">
                            المحافظة:{' '}
                            <span className="display_mandatory">(مطلوب)</span>
                          </label>
                          <select
                            name="province"
                            className={classnames('form-control', {
                              'is-invalid': errors.province,
                            })}
                            onChange={this.onProvinceChange}
                            value={province}
                          >
                            <option value="غير محدد">غير محدد</option>
                            <option value="دمشق"> دمشق</option>
                            <option value="ريف دمشق"> ريف دمشق</option>
                            <option value="حلب"> حلب</option>
                            <option value="طرطوس"> طرطوس</option>
                            <option value="اللاذقية"> اللاذقية</option>
                            <option value="حمص"> حمص</option>
                            <option value="حماة"> حماة</option>
                            <option value="السويداء"> السويداء</option>
                            <option value="الحسكة"> الحسكة</option>
                            <option value="درعا"> درعا</option>
                            <option value="ادلب"> ادلب</option>
                            <option value="دير الزور"> دير الزور</option>
                            <option value="القنيطرة"> القنيطرة</option>
                            <option value="الرقة"> الرقة</option>
                          </select>
                          {errors.province && (
                            <div className="invalid-feedback">
                              {errors.province}
                            </div>
                          )}
                        </div>
                        <div className="form-group text-right  mb-4">
                          <label htmlFor="area">
                            المنطقة:{' '}
                            {/* <span className="display_mandatory">(مطلوب)</span> */}
                          </label>
                          <select
                            name="area"
                            className={classnames('form-control', {
                              'is-invalid': errors.area,
                            })}
                            onChange={this.onChange}
                            value={area}
                          >
                            <option value="غير محدد">غير محدد</option>
                            {areas.map((area, index) => (
                              <option value={area} key={index}>
                                {area}
                              </option>
                            ))}
                          </select>
                          {errors.area && (
                            <div className="invalid-feedback">
                              {errors.area}
                            </div>
                          )}
                        </div>
                        {gps_location !== null &&
                        gps_location !== undefined &&
                        gps_location !== '' &&
                        mapsEnabled &&
                        IsUsingGPS ? (
                          <div className="form-group round-div py-1">
                            <div className="col-12 text-center">
                              <button
                                className="px-2 py-1 m-1"
                                onClick={this.onMapShowClick}
                              >
                                موقع المكتب على الخريطة
                                <FontAwesomeIcon
                                  className="icon_map_marked"
                                  icon={['fas', 'map-marked']}
                                  color="blue"
                                ></FontAwesomeIcon>
                              </button>
                            </div>
                          </div>
                        ) : null}
                        {mapsEnabled ? (
                          <div className="form-group round-div py-1">
                            <div className="col-12 text-center">
                              <ToggleButton
                                style={{ width: '175px', fontSize: '16px' }}
                                checked={IsUsingGPS}
                                onChange={this.handleToggleButton}
                                onLabel="عدم تحديد احداثيات"
                                offLabel="تحديد احداثيات"
                                onIcon="pi pi-map-marker"
                                offIcon="pi pi-globe"
                              />
                            </div>
                            <Dialog
                              header="حدد موقع مكتبك على الخريطة"
                              //footer={mapFooter}
                              // iconsTemplate={mapIcon}
                              visible={showMap}
                              style={{ width: '90vw', minHeight: '70vh' }}
                              modal={true}
                              onHide={() => this.setState({ showMap: false })}
                            >
                              <div>
                                {showMap ? (
                                  <Map
                                    center={position}
                                    length={4}
                                    onClick={this.onMapClick}
                                    //onLocationfound={this.handleLocationFound}
                                    ref={this.mapRef}
                                    zoom={this.state.zoom}
                                    style={{ width: '85vw', minHeight: '65vh' }}
                                  >
                                    <TileLayer
                                      attribution='<a href="http://ikar.sy">Ikar.sy</a> &amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                  </Map>
                                ) : null}
                              </div>
                            </Dialog>
                            <div className="col-12 text-right">
                              <div className="row">
                                <div className="col-12 text-right my-1">
                                  {!propertyPosition ? (
                                    <span>خط العرض : </span>
                                  ) : null}
                                  {IsUsingGPS && mapsEnabled ? (
                                    propertyPosition ? (
                                      <React.Fragment>
                                        خط العرض :{' '}
                                        <span className="text-primary mx-2">
                                          {propertyPosition.lat}
                                        </span>
                                        <FontAwesomeIcon
                                          icon={['fas', 'map-marked-alt']}
                                          size="1x"
                                          color="blue"
                                        />
                                      </React.Fragment>
                                    ) : null
                                  ) : null}
                                </div>
                                <div className="col-12 text-right my-1">
                                  {!propertyPosition ? (
                                    <span>خط الطول : </span>
                                  ) : null}
                                  {IsUsingGPS && mapsEnabled ? (
                                    propertyPosition ? (
                                      <React.Fragment>
                                        خط الطول :{' '}
                                        <span className="text-primary mx-2">
                                          {propertyPosition.lng}
                                        </span>
                                        <FontAwesomeIcon
                                          icon={['fas', 'map-marked-alt']}
                                          size="1x"
                                          color="blue"
                                        />
                                      </React.Fragment>
                                    ) : null
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}

                        <div className="form-group text-right mb-4">
                          <label htmlFor="street">الشارع:</label>
                          <input
                            type="text"
                            className={classnames('form-control', {
                              'is-invalid': errors.street,
                            })}
                            name="street"
                            value={street}
                            onChange={this.onChange}
                          />
                          {errors.street && (
                            <div className="invalid-feedback">
                              {errors.street}
                            </div>
                          )}
                        </div>
                        <div className="form-group text-right mb-4">
                          <label htmlFor="legal_address">العنوان الدائم:</label>
                          <input
                            type="text"
                            className={classnames('form-control', {
                              'is-invalid': errors.legal_address,
                            })}
                            name="legal_address"
                            value={legal_address}
                            onChange={this.onChange}
                          />
                          {errors.legal_address && (
                            <div className="invalid-feedback">
                              {errors.legal_address}
                            </div>
                          )}
                        </div>
                        <div className="form-group text-right mb-4">
                          <label htmlFor="business_address">عنوان العمل:</label>
                          <input
                            type="text"
                            className={classnames('form-control', {
                              'is-invalid': errors.business_address,
                            })}
                            name="business_address"
                            value={business_address}
                            onChange={this.onChange}
                          />
                          {errors.business_address && (
                            <div className="invalid-feedback">
                              {errors.business_address}
                            </div>
                          )}
                        </div>
                        <div className="form-group text-right mb-4">
                          <label htmlFor="description">الوصف:</label>
                          <input
                            type="text"
                            className={classnames('form-control', {
                              'is-invalid': errors.description,
                            })}
                            name="description"
                            value={description}
                            onChange={this.onChange}
                          />
                          {errors.description && (
                            <div className="invalid-feedback">
                              {errors.description}
                            </div>
                          )}
                        </div>
                        <div className="form-group text-right mb-4">
                          <label htmlFor="phone">
                            رقم الهاتف (______11-00963):
                          </label>
                          <input
                            type="tel"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            name="phone"
                            // id="phoneId"
                            className={classnames('form-control', {
                              'is-invalid': errors.phone,
                            })}
                            style={{ direction: 'ltr' }}
                            placeholder=""
                            value={phone}
                            onChange={this.onPhoneChange}
                          />

                          {errors.phone && (
                            <div className="invalid-feedback">
                              {errors.phone}
                            </div>
                          )}
                        </div>
                        <div className="form-group text-right mb-4">
                          <label htmlFor="mobile">
                            رقم الموبايل (________9-00963):{' '}
                            <span className="display_mandatory">(مطلوب)</span>
                          </label>
                          <input
                            type="tel"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            name="mobile"
                            // id="mobileId"
                            className={classnames('form-control', {
                              'is-invalid': errors.mobile,
                            })}
                            style={{ direction: 'ltr' }}
                            placeholder=""
                            value={mobile}
                            onChange={this.onMobileChange}
                          />

                          {errors.mobile && (
                            <div className="invalid-feedback">
                              {errors.mobile}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group text-right mb-4">
                          <label htmlFor="whatsapp">رقم ال واتس اب:</label>
                          <input
                            type="tel"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            name="whatsapp"
                            // id="whatsappId"
                            className={classnames('form-control', {
                              'is-invalid': errors.whatsapp,
                            })}
                            style={{ direction: 'ltr' }}
                            placeholder=""
                            value={whatsapp}
                            onChange={this.onMobileChange}
                          />

                          {errors.whatsapp && (
                            <div className="invalid-feedback">
                              {errors.whatsapp}
                            </div>
                          )}
                        </div>
                        <div className="form-group text-right mb-4">
                          <label htmlFor="telegram">رقم ال تيلغرام:</label>
                          <input
                            type="tel"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            name="telegram"
                            // id="telegramId"
                            className={classnames('form-control', {
                              'is-invalid': errors.telegram,
                            })}
                            style={{ direction: 'ltr' }}
                            placeholder=""
                            value={telegram}
                            onChange={this.onMobileChange}
                          />

                          {errors.telegram && (
                            <div className="invalid-feedback">
                              {errors.telegram}
                            </div>
                          )}
                        </div>
                        <div className="form-group text-right mb-4">
                          <label htmlFor="other_phones">ارقام اخرى:</label>
                          <input
                            type="tel"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            name="other_phones"
                            // id="other_phonesId"
                            className={classnames('form-control', {
                              'is-invalid': errors.other_phones,
                            })}
                            style={{ direction: 'ltr' }}
                            placeholder=""
                            value={other_phones}
                            onChange={this.onChange}
                          />
                          {/* <InputMask
                      mask="9999 999999"
                      maskChar=" "
                      style={{ direction: 'ltr' }}
                      // type="number"
                      className={classnames('form-control', {
                        'is-invalid': errors.other_phones
                      })}
                      name="other_phones"
                      value={other_phones}
                      onChange={this.onChange}
                      id="other_phonesId"
                    /> */}
                          {/* <input
                      type="number"
                      className={classnames('form-control', {
                        'is-invalid': errors.other_phones
                      })}
                      name="other_phones"
                      value={other_phones}
                      onChange={this.onChange}
                    /> */}
                          {errors.other_phones && (
                            <div className="invalid-feedback">
                              {errors.other_phones}
                            </div>
                          )}
                        </div>
                        <div className="form-group text-right mb-4">
                          <label htmlFor="twitter">عنوان صفحة ال تويتر:</label>
                          <input
                            type="text"
                            className={classnames('form-control', {
                              'is-invalid': errors.twitter,
                            })}
                            name="twitter"
                            value={twitter}
                            onChange={this.onChange}
                          />
                          {errors.twitter && (
                            <div className="invalid-feedback">
                              {errors.twitter}
                            </div>
                          )}
                        </div>
                        <div className="form-group text-right mb-4">
                          <label htmlFor="facebook">عنوان صفحةال فيسبوك:</label>
                          <input
                            type="text"
                            className={classnames('form-control', {
                              'is-invalid': errors.facebook,
                            })}
                            name="facebook"
                            value={facebook}
                            onChange={this.onChange}
                          />
                          {errors.facebook && (
                            <div className="invalid-feedback">
                              {errors.facebook}
                            </div>
                          )}
                        </div>

                        <div className="form-group text-right mb-4">
                          <label htmlFor="instagram">
                            عنوان صفحة ال انستغرام:
                          </label>
                          <input
                            type="text"
                            className={classnames('form-control', {
                              'is-invalid': errors.instagram,
                            })}
                            name="instagram"
                            value={instagram}
                            onChange={this.onChange}
                          />
                          {errors.instagram && (
                            <div className="invalid-feedback">
                              {errors.instagram}
                            </div>
                          )}
                        </div>

                        <div className="form-group text-right mb-4">
                          <label htmlFor="business_email">ايميل العمل:</label>
                          <input
                            type="email"
                            className={classnames('form-control', {
                              'is-invalid': errors.business_email,
                            })}
                            name="business_email"
                            value={business_email}
                            onChange={this.onChange}
                          />
                          {errors.business_email && (
                            <div className="invalid-feedback">
                              {errors.business_email}
                            </div>
                          )}
                        </div>
                        {/* <div className="form-group text-right">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="is_blocked"
                        className="form-check-input"
                        checked={is_blocked}
                        onChange={this.onCheckBoxChange}
                      />
                      <label className="form-check-label mx-3" htmlFor="duplex">
                        المكتب محجوب
                      </label>
                    </div>
                  </div>
                  <div className="form-group text-right">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        name="is_charged"
                        className="form-check-input"
                        checked={is_charged}
                        onChange={this.onCheckBoxChange}
                      />
                      <label className="form-check-label mx-3" htmlFor="duplex">
                        المكتب متعامل بالدفعات
                      </label>
                    </div>
                  </div> */}
                        <div className="form-group text-right  mb-3">
                          <label htmlFor="propertyImages">
                            تحميل شعار المكتب:
                          </label>
                          <div className="fileupload-images">
                            <div className="row">
                              <div className="col-md-10  mx-auto">
                                <label
                                  htmlFor="upload_images"
                                  className="btn btn-primary"
                                  style={{ width: '175px' }}
                                >
                                  <input
                                    type="file"
                                    id="upload_images"
                                    name="upload_images[]"
                                    accept=".jpg, .jpeg"
                                    //multiple
                                    className="d-none"
                                    onChange={this.onUploadImage}
                                  />
                                  تحميل شعار المكتب{' '}
                                  <FontAwesomeIcon
                                    icon={['fas', 'plus']}
                                    color="white"
                                  />
                                </label>
                              </div>

                              <div className="col-md-2"></div>
                              {errors.fileSize && (
                                <div className="bg-danger">
                                  {errors.fileSize}
                                </div>
                              )}
                            </div>
                          </div>
                          {fullSelectedFilesArray.length > 0 ? (
                            (fullSelectedFilesArray || []).map(
                              (fullSelectedFilesArray, index) => (
                                <div
                                  key={index}
                                  className="fileupload_image_inside_container"
                                >
                                  <div className="text-center fileupload_div_delete_image">
                                    <button
                                      type="button"
                                      className="btn btn-primary fileupload_btn_deleted"
                                      onClick={this.onDeleteImage.bind(
                                        this,
                                        fullSelectedFilesArray[0]
                                      )}
                                    >
                                      <FontAwesomeIcon
                                        icon={['fas', 'times']}
                                        color="white"
                                      />
                                    </button>
                                  </div>
                                  <div className="mt-1 mb-1 text-center">
                                    <img
                                      className="fileupload_img"
                                      src={fullSelectedFilesArray[2].blob}
                                      alt={fullSelectedFilesArray[2].name}
                                      onClick={() =>
                                        this.setState({
                                          photoIndex: index,
                                          isOpen: true,
                                        })
                                      }
                                    />
                                  </div>
                                  <div
                                    className="mx-auto my-0 px-2 text-center text-warning small"
                                    style={{ wordWrap: 'break-word' }}
                                  >
                                    {/* {fullSelectedFilesArray[0]} */}
                                    {/* <label htmlFor="photo_name">تغيير اسم الشعار:</label> */}
                                    <div className="text-center">
                                      <input
                                        type="text"
                                        className={classnames({
                                          'is-invalid': errors.photo_name,
                                        })}
                                        name="photo_name"
                                        value={photo_name}
                                        onChange={this.onChange}
                                      />
                                      {errors.photo_name && (
                                        <div className="invalid-feedback">
                                          {errors.photo_name}
                                        </div>
                                      )}
                                    </div>
                                    {/* <p className="text-dark">{` ${
                              fullSelectedFilesArray[1]
                            }KB`}</p> */}
                                  </div>
                                  {/* <div className="col-12 px-1 my-1 text-center h6"></div> */}
                                </div>
                              )
                            )
                          ) : photo !== null &&
                            photo !== undefined &&
                            photo !== '' &&
                            photo.length > 0 ? (
                            <div className="fileupload_image_inside_container">
                              <div className="text-center fileupload_div_delete_image">
                                <button
                                  type="button"
                                  className="btn btn-primary fileupload_btn_deleted"
                                  onClick={this.onDeleteImage2}
                                >
                                  <FontAwesomeIcon
                                    icon={['fas', 'times']}
                                    color="white"
                                  />
                                </button>
                              </div>
                              <div className="mt-1 mb-1 text-center">
                                <img
                                  className="fileupload_img"
                                  src={`${imageDomain}/${photo}`}
                                  alt={`${name} profile`}
                                  onClick={() =>
                                    this.setState({
                                      isOpen2: true,
                                    })
                                  }
                                />
                              </div>
                              <div
                                className="mx-auto my-0 px-2 text-center text-warning small"
                                style={{ wordWrap: 'break-word' }}
                              >
                                {/* {fullSelectedFilesArray[0]} */}
                                {/* <label htmlFor="photo">تغيير اسم الشعار:</label> */}
                                <div className="text-center">
                                  {photoName}
                                  {/* <input
                              type="text"
                              className={classnames({
                                'is-invalid': errors.photo_name
                              })}
                              name="photo_name"
                              value={photoName}
                              onChange={this.onChange}
                              readOnly
                            />
                            {errors.photo_name && (
                              <div className="invalid-feedback">
                                {errors.photo_name}
                              </div>
                            )} */}
                                </div>
                                {/* <p className="text-dark">{` ${
                          fullSelectedFilesArray[1]
                        }KB`}</p> */}
                              </div>
                              {/* <div className="col-12 px-1 my-1 text-center h6"></div> */}
                            </div>
                          ) : null}
                          {fullSelectedFilesArray.length > 0 ? (
                            <div className="row">
                              {isOpen && (
                                <Lightbox
                                  mainSrc={`${fullSelectedFilesArray[photoIndex][2].blob}`}
                                  nextSrc={`${
                                    fullSelectedFilesArray[
                                      (photoIndex + 1) %
                                        fullSelectedFilesArray.length
                                    ][2].blob
                                  }`}
                                  prevSrc={`${
                                    fullSelectedFilesArray[
                                      (photoIndex +
                                        fullSelectedFilesArray.length -
                                        1) %
                                        fullSelectedFilesArray.length
                                    ][2].blob
                                  }`}
                                  onCloseRequest={() =>
                                    this.setState({ isOpen: false })
                                  }
                                  onMovePrevRequest={() =>
                                    this.setState({
                                      photoIndex:
                                        (photoIndex +
                                          fullSelectedFilesArray.length -
                                          1) %
                                        fullSelectedFilesArray.length,
                                    })
                                  }
                                  onMoveNextRequest={() =>
                                    this.setState({
                                      photoIndex:
                                        (photoIndex + 1) %
                                        fullSelectedFilesArray.length,
                                    })
                                  }
                                />
                              )}
                            </div>
                          ) : (
                            <div className="row">
                              {isOpen2 && (
                                <Lightbox
                                  mainSrc={`${imageDomain}/${photo}`}
                                  nextSrc={`${imageDomain}/${photo}`}
                                  prevSrc={`${imageDomain}/${photo}`}
                                  onCloseRequest={() =>
                                    this.setState({ isOpen2: false })
                                  }
                                />
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-8 mx-auto">
                        <div className="row">
                          <div className="col-md-6">
                            <button
                              className="btn btn-block submit-button m-1"
                              onClick={this.onSubmit}
                            >
                              تعديل مكتب
                            </button>
                          </div>
                          <div className="col-md-6">
                            <button
                              className="btn btn-block submit-button  m-1"
                              onClick={this.handleClear}
                            >
                              مسح الحقول
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  {Object.keys(errors).length > 0 ? (
                    <div className="alert alert-warning mt-2 text-right">
                      الرجاء تعبئة الحقول المطلوبة
                    </div>
                  ) : null}
                  {Object.keys(resMessages).length > 0 ? (
                    <div className="alert alert-danger mt-2 text-right">
                      {resMessages.message}
                    </div>
                  ) : null}
                </div>
              </div>
            ) : //  main update
            updateEmail ? (
              <div className="row">
                <div className="col-md-10 mx-auto">
                  <form
                    onSubmit={this.onSubmitEmail}
                    style={{ direction: 'rtl' }}
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group text-right mb-4">
                          <label htmlFor="contact_email">
                            البريد الالكتروني:{' '}
                            <span className="display_mandatory">(مطلوب)</span>
                          </label>
                          <input
                            type="email"
                            className={classnames('form-control', {
                              'is-invalid': errors.contact_email,
                            })}
                            name="contact_email"
                            value={contact_email}
                            onChange={this.onChange}
                          />
                          {errors.contact_email && (
                            <div className="invalid-feedback">
                              {errors.contact_email}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6"></div>
                    </div>
                    <div className="row">
                      <div className="col-8 mx-auto">
                        <div className="row">
                          <div className="col-md-6 mx-auto">
                            <input
                              type="submit"
                              value="تعديل الايميل"
                              className="btn btn-block submit-button m-1"
                            />
                          </div>
                          {/* <div className="col-md-6">
                            <input
                              value="مسح الحقول"
                              className="btn btn-block submit-button  m-1"
                              onClick={this.handleClear}
                            />
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </form>
                  {Object.keys(errors).length > 0 ? (
                    <div className="alert alert-warning mt-2 text-right">
                      الرجاء تعبئة الحقول المطلوبة
                    </div>
                  ) : null}
                  {Object.keys(resMessages).length > 0 ? (
                    <div className="alert alert-danger mt-2 text-right">
                      {resMessages.message}
                    </div>
                  ) : null}
                </div>
              </div>
            ) : updatePassword ? (
              <div className="row">
                <div className="col-md-10 mx-auto">
                  <form
                    onSubmit={this.onSubmitPassword}
                    style={{ direction: 'rtl' }}
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group text-right mb-4">
                          <label htmlFor="password">
                            كلمة السر:{' '}
                            <span className="display_mandatory">(مطلوب)</span>
                          </label>
                          <input
                            type="password"
                            className={classnames('form-control', {
                              'is-invalid': errors.password,
                            })}
                            name="password"
                            value={password}
                            onChange={this.onChange}
                          />
                          {errors.password && (
                            <div className="invalid-feedback">
                              {errors.password}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group text-right mb-4">
                          <label htmlFor="password_confirmation">
                            اعادة كلمة السر:{' '}
                            <span className="display_mandatory">(مطلوب)</span>
                          </label>
                          <input
                            type="password"
                            className={classnames('form-control', {
                              'is-invalid': errors.password_confirmation,
                            })}
                            name="password_confirmation"
                            value={password_confirmation}
                            onChange={this.onChange}
                          />
                          {errors.password_confirmation && (
                            <div className="invalid-feedback">
                              {errors.password_confirmation}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-8 mx-auto">
                        <div className="row">
                          <div className="col-md-6 mx-auto">
                            <input
                              type="submit"
                              value="تعديل كلمة السر"
                              className="btn btn-block submit-button m-1"
                            />
                          </div>
                          {/* <div className="col-md-6">
                            <input
                              value="مسح الحقول"
                              className="btn btn-block submit-button  m-1"
                              onClick={this.handleClear}
                            />
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </form>
                  {Object.keys(errors).length > 0 ? (
                    <div className="alert alert-warning mt-2 text-right">
                      الرجاء تعبئة الحقول المطلوبة
                    </div>
                  ) : null}
                  {Object.keys(resMessages).length > 0 ? (
                    <div className="alert alert-danger mt-2 text-right">
                      {resMessages.message}
                    </div>
                  ) : null}
                </div>
              </div>
            ) : null}
          </React.Fragment>
        ) : resType === 'not complete' ? (
          <ResNotComplete></ResNotComplete>
        ) : resType === 'ready' ? (
          <ResReady></ResReady>
        ) : (
          <div>
            <br />
            <br />
            <br />
            <br />
          </div>
        )}
      </div>
    );
  }
}

EditAgency.propTypes = {
  getAgency: PropTypes.func.isRequired,
  editAgency: PropTypes.func.isRequired,
  agency: PropTypes.object.isRequired,
  agencies: PropTypes.array.isRequired,
  users: PropTypes.array.isRequired,
  requestFailed: PropTypes.bool.isRequired,
  requestFailedMessage: PropTypes.object.isRequired,
  requestSuccessMessage: PropTypes.string.isRequired,
  ikarAdmin: PropTypes.bool.isRequired,
  isTakingAgencyRole: PropTypes.bool.isRequired,
  // takenAgencyRoleAgencyRefId: PropTypes.string.isRequired,
  // takenAgencyRoleToken: PropTypes.string.isRequired,
  short_id: PropTypes.string.isRequired,
  resType: PropTypes.string.isRequired,
};
const mapStateToProps = (state) => ({
  agency: state.agency.agency,
  users: state.search.users,
  requestFailed: state.agencyUD.requestFailed,
  requestFailedMessage: state.agencyUD.requestFailedMessage,
  requestSuccessMessage: state.agencyUD.requestSuccessMessage,
  ikarAdmin: state.auth.ikarAdmin,
  isTakingAgencyRole: state.auth.isTakingAgencyRole,
  takenAgencyRoleAgencyRefId: state.auth.takenAgencyRoleAgencyRefId,
  takenAgencyRoleToken: state.auth.takenAgencyRoleToken,
  agencyRefId: state.auth.agencyRefId,
  agencies: state.agency.agencies,
  short_id: state.agencyUD.short_id,
  resType: state.agency.resType,
});

export default connect(mapStateToProps, {
  getAgency,
  editAgency,
  editAgencyEmail,
  editAgencyPassword,
  logoutProcess,
  // leaveAgencyRole2,
  leaveAgencyRole,
  loginChecking,
})(EditAgency);
