import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import { loginProcess } from './../../actions/authActions';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { getAgencies } from './../../actions/searchActions';
import {
  editAgency,
  editAgencyRank,
  blockAgency,
  activeAgency,
} from './../../actions/agencyActionsUD';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Growl } from 'primereact/growl';
import Lightbox from 'react-image-lightbox';
import SearchAgenciesBar from './../search/SearchAgenciesBar.jsx';
import Pagination from './../layout/Pagination/Pagination';
import defaultBackground from './../../assets/images/defaultBackground.png';
import { imageDomain } from './../../mainConf';
import { takeAgencyRole } from './../../actions/authActions';
import { Helmet } from 'react-helmet';
class Agencies extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: '',
      pageDescription: '',
      selectedAgency: {},
      requestSuccessMessage: '',
      agencies: [],
      updatedReferenceId: '',
      updatedRank: '',
      total_agencies: 0,
      pageNumber: 1,
      bgURL: '',
      showMainPhoto: false,
    };
  }
  componentWillReceiveProps(nextProps, nextState) {
    if (
      nextProps.requestSuccessMessage !== this.state.requestSuccessMessage &&
      this.state.requestSuccessMessage !== ''
    ) {
      var result2 = [];
      this.setState({
        requestSuccessMessage: nextProps.requestSuccessMessage,
      });

      switch (nextProps.requestSuccessMessage) {
        case 'حجب المكتب المحدد':
          this.growl.show({
            severity: 'success',
            summary: 'تم حجب المكتب المحدد',
            // detail: 'Order submitted'
          });
          //this.props.getAgencies();
          this.state.agencies.forEach((obj, index) => {
            result2.push(obj);
            if (obj.reference_id === this.state.updatedReferenceId) {
              result2[index].blocked = !obj.blocked;
              // result2[index].blocked = !obj.updatedReferenceId;
            }
          });
          this.setState({ agencies: result2 });
          break;
        case 'الغاء حجب المكتب المحدد':
          this.growl.show({
            severity: 'success',
            summary: 'تم الغاء حجب المكتب المحدد',
          });
          this.state.agencies.forEach((obj, index) => {
            result2.push(obj);
            if (obj.reference_id === this.state.updatedReferenceId) {
              result2[index].blocked = !obj.blocked;
            }
          });
          this.setState({ agencies: result2 });
          break;

        case 'تفعيل الحساب المحدد':
          this.growl.show({
            severity: 'success',
            summary: 'تم تفعيل الحساب المحدد',
          });
          this.state.agencies.forEach((obj, index) => {
            result2.push(obj);
            if (obj.reference_id === this.state.updatedReferenceId) {
              result2[index].confirmed = true;
            }
          });
          this.setState({ agencies: result2 });
          break;

        case 'error in active agency':
          this.growl.show({
            severity: 'error',
            summary: 'حذث خطأ أثناء تفعيل الحساب المحدد الرجاء المحاولة لاحقا',
          });

          break;

        case 'تحديد المكتب المحدد كمتعامل بالدفعات':
          this.growl.show({
            severity: 'success',
            summary: 'تم تحديد المكتب المحدد كمتعامل بالدفعات',
          });
          this.state.agencies.forEach((obj, index) => {
            result2.push(obj);
            if (obj.reference_id === this.state.updatedReferenceId) {
              result2[index].is_charged = !obj.is_charged;
            }
          });
          this.setState({ agencies: result2 });
          break;
        case 'الغاء تحديد المكتب المحدد كمتعامل بالدفعات':
          this.growl.show({
            severity: 'success',
            summary: 'تم الغاء تحديد المكتب المحدد كمتعامل بالدفعات',
          });
          this.state.agencies.forEach((obj, index) => {
            result2.push(obj);
            if (obj.reference_id === this.state.updatedReferenceId) {
              result2[index].is_charged = !obj.is_charged;
            }
          });
          this.setState({ agencies: result2 });
          break;

        case 'تحديد مرتبة للمكتب':
          this.growl.show({
            severity: 'success',
            summary: 'تم تحديد مرتبة للمكتب',
            // detail: 'Order submitted'
          });
          //this.props.getAgencies();
          this.state.agencies.forEach((obj, index) => {
            result2.push(obj);
            if (obj.reference_id === this.state.updatedReferenceId) {
              result2[index].rank = this.state.updatedRank;
            }
          });
          this.setState({ agencies: result2 });
          break;
        default:
          break;
      }
    } else if (
      nextProps.agencies !== this.state.agencies &&
      nextProps.agencies[0] !== undefined &&
      nextProps.agencies[0] !== null
    ) {
      this.setState({
        agencies: nextProps.agencies,
        total_agencies: nextProps.agencies[0].total_agencies,
      });
    } else {
      this.setState({
        agencies: [],
        total_agencies: 0,
      });
    }
  }
  componentDidMount() {
    // jQuery(function($) {
    //   $('#agencyTable').on('click', '.clickable-row', function(event) {
    //     $(this)
    //       .addClass('table-success')
    //       .siblings()
    //       .removeClass('table-success');
    //   });
    // });
  }

  onChangeBlockedStatus = async (reference_id, blockedStatus, e) => {
    e.preventDefault();
    var blockDate = {};
    var processType = '';
    switch (blockedStatus) {
      case 'set_blocked':
        if (!window.confirm('حجب المكتب المحدد ؟')) {
          return;
        }
        blockDate = {
          reference_id,
          blocked: true,
        };
        processType = 'حجب المكتب المحدد';
        break;
      case 'remove_blocked':
        if (!window.confirm('الغاء حجب المكتب المحدد ؟')) {
          return;
        }
        blockDate = {
          reference_id,
          blocked: false,
        };
        processType = 'الغاء حجب المكتب المحدد';
        break;
      default:
        break;
    }

    await this.setState({
      requestSuccessMessage: 'ready',
      updatedReferenceId: reference_id,
    });
    // const isTakingAgencyRole = false;
    // const takenAgencyRoleAgencyRefId = this.props.takenAgencyRoleAgencyRefId;
    // const takenAgencyRoleToken = '';
    await this.props.blockAgency(blockDate, processType);
  };

  onActiveAgency = async (id, e) => {
    e.preventDefault();
    var admin = {};
    var activeData = {};
    var processType = '';
    if (!window.confirm('تفعيل الحساب المحدد ؟')) {
      return;
    }
    admin = {
      agency_ref_id: id,
      confirmed: true,
    };
    processType = 'تفعيل الحساب المحدد';
    activeData = { admin };
    await this.setState({
      requestSuccessMessage: 'ready',
      updatedReferenceId: id,
    });
    await this.props.activeAgency(activeData, processType);
  };
  onChangeAgencyRank = async (reference_id, currentRank, e) => {
    e.preventDefault();
    var newRank = prompt('يجب ادخال مرتبة مكتب بين 0 و 10');
    if (newRank === null) {
      return;
    }
    if (/^\d+$/.test(newRank)) {
      if (parseInt(newRank) < 0 || parseInt(newRank) > 10) {
        alert('الرقم المدخل غير صحيح');
        return;
      } else if (parseInt(newRank) === parseInt(currentRank)) {
        alert('الرقم المدخل يساوي المرتبة الحالية للمكتب');
        return;
      }
    } else {
      alert('الرقم المدخل غير صحيح');
      return;
    }

    var agency = {};
    var processType = '';
    processType = 'تحديد مرتبة للمكتب';
    agency = {
      reference_id,
      rank: parseInt(newRank),
    };
    await this.setState({
      requestSuccessMessage: 'ready',
      updatedReferenceId: reference_id,
      updatedRank: parseInt(newRank),
    });
    const agencyDate = { agency };
    const isTakingAgencyRole = false;
    // const takenAgencyRoleAgencyRefId = this.props.takenAgencyRoleAgencyRefId;
    const takenAgencyRoleToken = '';
    await this.props.editAgencyRank(
      agencyDate,
      isTakingAgencyRole,
      takenAgencyRoleToken,
      processType
    );
  };

  onChangeChargedStatus = async (reference_id, chargedStatus, e) => {
    e.preventDefault();

    var agency = {};
    var processType = '';
    switch (chargedStatus) {
      case 'set_charged':
        if (!window.confirm('تحديد المكتب المحدد كمتعامل بالدفعات ؟')) {
          return;
        }
        agency = {
          reference_id,
          is_charged: true,
        };
        processType = 'تحديد المكتب المحدد كمتعامل بالدفعات';
        break;
      case 'remove_charged':
        if (!window.confirm('الغاء تحديد المكتب المحدد كمتعامل بالدفعات ؟')) {
          return;
        }
        agency = {
          reference_id,
          is_charged: false,
        };
        processType = 'الغاء تحديد المكتب المحدد كمتعامل بالدفعات';
        break;
      default:
        break;
    }
    await this.setState({
      requestSuccessMessage: 'ready',
      updatedReferenceId: reference_id,
    });
    const agencyDate = { agency };
    const isTakingAgencyRole = false;
    // const takenAgencyRoleAgencyRefId = this.props.takenAgencyRoleAgencyRefId;
    const takenAgencyRoleToken = '';
    await this.props.editAgency(
      agencyDate,
      isTakingAgencyRole,
      takenAgencyRoleToken,
      processType
    );
  };
  agencyStatusTemplate = (rowData, column) => {
    return (
      <span
        className={classnames(
          'text-center',
          { 'text-success': rowData.confirmed },
          { 'text-danger': !rowData.confirmed }
        )}
      >
        {rowData.confirmed ? <span>نعم</span> : <span>لا</span>}
      </span>
    );
  };
  propertyCountTemplate = (rowData, column) => {
    return (
      <Link
        to={`/adminDashBoard/properties_by_agency?agency_id=${rowData.reference_id}&name=${rowData.name}`}
      >
        {rowData.count_properties}
        {/* <Button
        // icon="fa fa-building"
        //iconPos="right"
        className="p-button-warning btn_process"
        tooltip="عقارات المكتب"
        tooltipOptions={{
          position: 'bottom',
          event: 'hover'
        }}
        style={{ border: '1px solid #9F9F9F' }}
      >
        <FontAwesomeIcon icon={['fas', 'building']}></FontAwesomeIcon>
      </Button> */}
      </Link>
    );
  };
  showDetailsTemplate = (rowData, column) => {
    return (
      <React.Fragment>
        {rowData.blocked ? (
          <Button
            //icon="pi pi-check"
            // icon="fa fa-store"
            //iconPos="right"
            className="p-button-danger btn_process"
            tooltip="المكتب محجوب"
            tooltipOptions={{
              position: 'bottom',
              event: 'hover',
            }}
            onClick={this.onChangeBlockedStatus.bind(
              this,
              rowData.reference_id,
              'remove_blocked'
            )}
          >
            <FontAwesomeIcon icon={['fas', 'store']}></FontAwesomeIcon>
          </Button>
        ) : (
          <Button
            // icon="fa fa-store"
            //iconPos="right"
            className="p-button-success btn_process"
            tooltip="المكتب غير محجوب"
            tooltipOptions={{
              position: 'bottom',
              event: 'hover',
            }}
            onClick={this.onChangeBlockedStatus.bind(
              this,
              rowData.reference_id,
              'set_blocked'
            )}
          >
            <FontAwesomeIcon icon={['fas', 'store']}></FontAwesomeIcon>
          </Button>
        )}
        {/* </div>
          <div className="col-6 text-center px-0"> */}
        {/* {rowData.is_charged ? (
          <Button
            //icon="pi pi-check"
            icon="fa fa-money-check"
            //iconPos="right"
            className="p-button-success btn_process"
            tooltip="المكتب متعامل بالدفعات"
            tooltipOptions={{
              position: 'bottom',
              event: 'hover'
            }}
            onClick={this.onChangeChargedStatus.bind(
              this,
              rowData.reference_id,
              'remove_charged'
            )}
          />
        ) : (
          <Button
            icon="fa fa-money-check"
            //stack="fa fa-times fa-stack-1x"
            //iconPos="right"
            className="p-button-danger btn_process"
            tooltip="المكتب غير متعامل بالدفعات"
            tooltipOptions={{
              position: 'bottom',
              event: 'hover'
            }}
            onClick={this.onChangeChargedStatus.bind(
              this,
              rowData.reference_id,
              'set_charged'
            )}
          >
          </Button>
        )} */}
        {/* </div>
        </div> */}
        {/* {!rowData.blocked ? (
          <Button
            type="button"
            icon="fa fa-universal-access"
            className="p-button-warning btn_process"
            //onChange={(e) => this.setState({country: e.value})}
            onClick={this.handleTakeAgencyRole.bind(
              this,
              rowData.reference_id
              // ,
              // rowData.reference_id
            )}
            tooltip="الدخول كالمكتب المحدد"
            tooltipOptions={{
              position: 'bottom',
              event: 'hover'
            }}
          ></Button>
        ) : null} */}

        <Button
          //icon="pi pi-check"
          // icon="fa fa-list-ol"
          //iconPos="right"
          className="p-button-warning btn_process"
          tooltip="تحديد رتبة المكتب"
          tooltipOptions={{
            position: 'bottom',
            event: 'hover',
          }}
          onClick={this.onChangeAgencyRank.bind(
            this,
            rowData.reference_id,
            rowData.rank
          )}
        >
          <FontAwesomeIcon icon={['fas', 'list-ol']}></FontAwesomeIcon>
        </Button>

        <Button
          className="p-button-warning btn_process"
          tooltip="تفعيل الحساب"
          tooltipOptions={{
            position: 'bottom',
            event: 'hover',
          }}
          onClick={this.onActiveAgency.bind(this, rowData.reference_id)}
          disabled={rowData.confirmed}
        >
          <FontAwesomeIcon icon={['fas', 'unlock']}></FontAwesomeIcon>
        </Button>
        {/* <Link to={`/adminDashBoard/editagency/${rowData.reference_id}`}>
          <Button
            icon="fa fa-edit"
            //iconPos="right"
            className="p-button-warning btn_process"
            tooltip="تعديل المكتب"
            tooltipOptions={{
              position: 'bottom',
              event: 'hover'
            }}
            style={{ border: '1px solid #9F9F9F' }}
          />
        </Link> */}

        <Link to={`/admin_agency_profile/${rowData.reference_id}`}>
          <Button
            // icon="fa fa-store-alt"
            //iconPos="right"
            className="p-button-secondary btn_process"
            tooltip="معلومات المكتب"
            tooltipOptions={{
              position: 'bottom',
              event: 'hover',
            }}
            style={{ border: '1px solid #9F9F9F' }}
          >
            <FontAwesomeIcon icon={['fas', 'store-alt']}></FontAwesomeIcon>
          </Button>
        </Link>
      </React.Fragment>
    );
  };
  export = () => {
    this.dt.exportCSV();
  };
  onSearchSubmit = (recordsPerPage, pageNumber) => {
    this.setState({ pageNumber });
  };
  showPhotoTemplate = (rowData, column) => {
    var bgURL = '';
    // var thumbnail = '';

    if (
      rowData.photo === null ||
      rowData.photo === undefined ||
      rowData.photo.length === 0
    ) {
      bgURL = defaultBackground;
      // thumbnail = defaultBackground;
    } else {
      bgURL = `${imageDomain}/${rowData.photo[0]}`;
      // thumbnail = `${imageDomain}/${rowData.thumbnail[0]}`;
    }
    // const { showMainPhoto } = this.state;
    return (
      <React.Fragment>
        <img
          ////////src={thumbnail}
          src={bgURL}
          alt=""
          style={{ width: '100px', height: '50px' }}
          onClick={() =>
            this.setState({
              showMainPhoto: true,
              bgURL,
            })
          }
        />
      </React.Fragment>
    );
  };
  handleTakeAgencyRole = (referenceId, e) => {
    if (!window.confirm('الدخول كالمستخدم المحدد ؟')) {
      return;
    }
    this.props.takeAgencyRole(referenceId);
    // this.props.history.push("/dashBoard/properties_by_agency");
    //this.props.history.push('/');
  };
  onNewSearch = () => {
    this.setState({ pageNumber: 1 });
  };
  render() {
    const {
      agencies,
      total_agencies,
      pageNumber,
      bgURL,
      showMainPhoto,
    } = this.state;
    var agencyHeader = (
      <div className="px-2 py-0" style={{ textAlign: 'right' }}>
        <Button
          type="button"
          icon="pi pi-external-link"
          iconPos="left"
          label="تصدير الجدول الى ملف اكسل CSV"
          onClick={this.export}
        ></Button>
      </div>
    );

    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.pageTitle}</title>
          <meta name="description" content={this.state.pageDescription} />
        </Helmet>
        <SearchAgenciesBar
          searchType="all_agencies_search"
          searchId=""
          pageNumber={pageNumber}
          newSearch={this.onNewSearch}
        ></SearchAgenciesBar>
        <div className="mb-3 text-center my-2">
          <h1 className="main_title">قائمة المكاتب العقارية</h1>
        </div>
        <div className="property-container">
          <div className="container-fluid">
            <Growl ref={(el) => (this.growl = el)} />
            {/* row justify-content-around align-items-center  */}
            <div className="table_container mt-1">
              <DataTable
                ref={(el) => {
                  this.dt = el;
                }}
                value={agencies}
                responsive={true}
                //header="المكاتب العقارية"
                selectionMode="single"
                selection={this.state.selectedAgency}
                onSelectionChange={(e) => {
                  this.setState({ selectedAgency: e.value });
                }}
                header={agencyHeader}
              >
                {/* <Column
                    header="الصورة"
                    className="table_td"
                    body={this.showPhotoTemplate}
                    style={{ width: '105px' }}
                  /> */}
                <Column
                  field="name"
                  header="الاسم"
                  sortable={true}
                  className="table_td"
                  // style={{ width: "150px" }}
                />
                <Column
                  field="contact_email"
                  header="الايميل"
                  sortable={true}
                  className="table_td"
                  // style={{ width: "150px" }}
                />
                <Column
                  field="province"
                  header="المحافظة"
                  sortable={true}
                  className="table_td"
                />
                <Column field="phone" header="الأرضي" className="table_td" />
                <Column className="table_td" field="mobile" header="الموبايل" />
                <Column
                  body={this.propertyCountTemplate}
                  // field="count_properties"
                  header="العقارات"
                  className="table_td"
                  // sortable={true}
                />
                <Column
                  field="views_count"
                  header="المشاهدات"
                  className="table_td"
                  sortable={true}
                />

                <Column
                  field="calls"
                  header="الاتصالات"
                  className="table_td"
                  sortable={true}
                />
                <Column
                  field="rank"
                  header="رتبة المكتب"
                  className="table_td"
                  sortable={true}
                />
                <Column
                  body={this.agencyStatusTemplate}
                  className="table_td"
                  header="الحساب مفعل"
                />
                <Column
                  body={this.showDetailsTemplate}
                  //header="الاجراءات"
                  style={{ width: '190px' }}
                  className="table_td table-functions"
                />
              </DataTable>
              {showMainPhoto && (
                <Lightbox
                  mainSrc={bgURL}
                  nextSrc={bgURL}
                  prevSrc={bgURL}
                  onCloseRequest={() => this.setState({ showMainPhoto: false })}
                  onMovePrevRequest={
                    () => {
                      return;
                    }
                    // this.setState({
                    //   showMainPhoto: (showMainPhoto + url.length - 1) % url.length
                    // })
                  }
                  onMoveNextRequest={
                    () => {
                      return;
                    }
                    // this.setState({
                    //   photoIndex: (showMainPhoto + 1) % url.length
                    // })
                  }
                />
              )}
            </div>
            <Pagination
              resultLabel="مكتب"
              recordsNumber={total_agencies}
              onPageChange={this.onSearchSubmit}
              pageNumber={pageNumber}
              showMessage={true}
            ></Pagination>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Agencies.propTypes = {
  agencies: PropTypes.array.isRequired,
  // getAgencies: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  ikarAdmin: PropTypes.bool.isRequired,
  editAgency: PropTypes.func.isRequired,
  editAgencyRank: PropTypes.func.isRequired,
  blockAgency: PropTypes.func.isRequired,
  requestSuccessMessage: PropTypes.string.isRequired,
  takeAgencyRole: PropTypes.func.isRequired,
  isTakingAgencyRole: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  agencies: state.search.agencies,
  isLoggedIn: state.auth.isLoggedIn,
  ikarAdmin: state.auth.ikarAdmin,
  requestSuccessMessage: state.agencyUD.requestSuccessMessage,
  short_id: state.agencyUD.short_id,
  short_id2: state.agency.short_id2,
  isTakingAgencyRole: state.auth.isTakingAgencyRole,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    loginProcess,
    editAgency,
    editAgencyRank,
    blockAgency,
    activeAgency,
    takeAgencyRole,
  })
)(Agencies);
