import { UPDATE_JOIN } from '../actions/types';
import shortid from 'shortid';
const initialState = {
  join_us: [],
  join: {},
  requestFailed: false,
  requestFailedMessage: {},
  requestSuccessMessage: '',
  short_id: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case UPDATE_JOIN:
      if (
        (Object.keys(action.payload).length > 0 &&
          action.payload.errorMessage === '') ||
        action.payload.errorMessage === undefined
      ) {
        action.payload.processStatus = 'edit is succeeded';

        return {
          //...state,
          join: action.payload,
          //add state data
          join_us: [],
          requestFailed: false,
          requestFailedMessage: { error: 'no' },
          requestSuccessMessage: action.payload.message,
          short_id: shortid.generate(),
          //
        };
      } else {
        return {
          //...state,
          join: [],
          requestFailed: true,
          requestFailedMessage: action.payload,
          //add state data
          join_us: [],
          requestSuccessMessage: 'error in edit question',
          short_id: shortid.generate(),
          //
        };
      }
    default:
      return state;
  }
}
