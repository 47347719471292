import {Get_Privacies , DELETE_PRIVACY , ADD_PRIVACY , GET_PRIVACY } from './types';
import axios from 'axios';
import { mainDomain } from './../mainConf';


export const getPrivacys = (ikarAdmin) => async (dispatch) => {
      let res = {};
      let errorRes = {
        errorStatus: '',
        errorMessage: '',

      };
      var token = '';

      if (ikarAdmin) {
        token = localStorage.getItem('ikar_token');
        
        await axios
          .get(
            `${mainDomain}/v1/privacy`,
            // ,
            // {
            //   withCredentials: true,
            // }
            {
              headers: {
                //'Content-Type': 'application/json',
                Accept: 'application/json',
                //Accept: 'application/json',
                Authorization: token,
              },
            }
          )
          .then(function (response) {
            if (
              response !== null &&
              response !== undefined &&
              Object.keys(response).length > 0
            ) {

              res = response.data;
              res.resType = 'complete';

            } else res = {};
          })
          .catch(function (error) {
            if (error.response) {
              errorRes = {
                errorStatus: error.response.status,
                errorMessage: error.response,
              };
            } else if (error.request) {
              errorRes = {
                errorMessage: error.request,
              };
            } else {
              errorRes = { errorMessage: error.message };
            }
            res = errorRes;
            res.resType = 'not complete';
          }
          ) ;
      }
      else {
      await axios
        .get(
          `${mainDomain}/v1/privacy`,

          {
            headers: {
              Accept: 'application/json'
             // Authorization: localStorage.getItem('ikar_token'),
            },
          }
        )
        .then(function (response) {

          if (
            response !== null &&
            response !== undefined &&
            Object.keys(response).length > 0
          ) {
            res = response.data;
            res.resType = 'complete';
          } else res = {};

        })
        .catch(function (error) {
          if (error.response) {
            errorRes = {
              errorStatus: error.response.status,
              errorMessage: error.response,
            };
          } else if (error.request) {
            errorRes = {
              errorMessage: error.request,
            };
          } else {
            errorRes = { errorMessage: error.message };
          }

          res = errorRes;
          res.resType = 'not complete';

        }
        ) ;

    }

      dispatch({
        type: Get_Privacies,
        payload: res,
      });
      
      return res;
  };

  //////////////////////////////////////////////////////////////////////////////////////

  export const deletePrivacy = (reference_id, processType) => async (dispatch) => {
    let res = {};
    let errorRes = {
      errorStatus: '',
      errorMessage: '',
    };
    await axios
      .delete(`${mainDomain}/v1/privacy?reference_id=${reference_id}`, {
        headers: {
          Accept: 'application/json',
          Authorization: localStorage.getItem('ikar_token'),
        },
      })
      .then(function (response) {
        if (
          response !== null &&
          response !== undefined &&
          Object.keys(response).length > 0
        ) {
          res = response.data;
          res.message = processType;
        } else {
          res = {};
        }
      })
      .catch(function (error) {
        if (error.response) {
          errorRes = {
            errorStatus: error.response.status,
            errorMessage: error.response,
          };
        } else if (error.request) {
          errorRes = {
            errorMessage: error.request,
          };
        } else {
          errorRes = { errorMessage: error.message };
        }
        res = errorRes;
      });
    dispatch({
      type: DELETE_PRIVACY,
      payload: res,
    });
  };

  ////////////////////////////////////////////////////////////////////////////////

  export const addPrivacy = (privacy) => async (dispatch) => {
    let res = {};
    let errorRes = {
      errorStatus: '',
      errorMessage: '',
    };
    await axios
      .post(
        `${mainDomain}/v1/privacy`,
        {privacy:privacy},
        // ,
        // {
        //   withCredentials: true,
        // }
        {
          headers: {
            Accept: 'application/json',
            Authorization: localStorage.getItem('ikar_token'),
          },
        }
      )
      .then(function (response) {
        if (
          response !== null &&
          response !== undefined &&
          Object.keys(response).length > 0
        ) {
          res = response.data;
          res.resType = 'complete';
        } else res = {};
      })
      .catch(function (error) {
        if (error.response) {
          errorRes = {
            errorStatus: error.response.status,
            errorMessage: error.response,
          };
        } else if (error.request) {
          errorRes = {
            errorMessage: error.request,
          };
        } else {
          errorRes = { errorMessage: error.message };
        }
        res = errorRes;
        res.resType = 'not complete';
      });
    dispatch({
      type: ADD_PRIVACY,
      payload: res,
    });
  };

 ///////////////////////////////////////////////////////////////////
 export const getPrivacy = ( privacyReferenceId) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  var token = '';
  var privacyRefId = privacyReferenceId;
    token = localStorage.getItem('ikar_token');
    await axios
      .get(`${mainDomain}/v1/privacy/show?reference_id=${privacyRefId}`, {
        headers: {
          //'Content-Type': 'application/json',
          Accept: 'application/json',
          //Accept: 'application/json',
          Authorization: token,
        },
      })
      .then(function (response) {
        console.log(response);
        if (
          response !== null &&
          response !== undefined &&
          Object.keys(response).length > 0
        ) {
          res = response.data;
          res.resType = 'complete';
        } else res = {};
      })
      .catch(function (error) {
        if (error.response) {
          errorRes = {
            errorStatus: error.response.status,
            errorMessage: error.response,
          };
        } else if (error.request) {
          errorRes = {
            errorMessage: error.request,
          };
        } else {
          errorRes = { errorMessage: error.message };
        }
        res = errorRes;
        res.resType = 'not complete';
      });

  dispatch({
    type: Get_Privacies,
    payload: res,
  });
  return res;
};