import {
  GET_PROPERTY,
  ADD_PROPERTY,
  ADD_PROPERTY_PHOTO,
} from '../actions/types';

const initialState = {
  properties: [],
  property: {},
  requestFailed: false,
  requestFailedMessage: {},
  requestSuccessMessage: '',
  requestMessage: '',
  resType: 'ready',
  uploadedPhotoName: '',
  directory: '',
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_PROPERTY:
      if (
        Object.keys(action.payload).length > 0 &&
        (action.payload.errorMessage === '' ||
          action.payload.errorMessage === undefined)
      ) {
        return {
          //...state,
          property: action.payload,
          //add state data
          properties: [],
          requestFailed: false,
          requestFailedMessage: { error: 'no' },
          requestSuccessMessage: '',
          requestMessage: 'get property ready',
          resType: action.payload.resType,
          //
        };
      } else {
        return {
          //...state,
          property: [],
          requestFailed: true,
          requestFailedMessage: action.payload,
          //add state data
          properties: [],
          requestSuccessMessage: '',
          requestMessage: '',
          resType: action.payload.resType,
          //
        };
      }
    // case GET_PROPERTY:
    //   return {
    //     ...state,
    //     ///property: action.payload

    //     property: state.properties.filter(
    //       property => property.id == action.payload
    //     )[0]
    //   };

    // case ADD_PROPERTY:
    //   return { ...state, properties: [action.payload, ...state.properties] };

    case ADD_PROPERTY:
      if (
        Object.keys(action.payload).length > 0 &&
        action.payload.error === undefined &&
        (action.payload.errorMessage === '' ||
          action.payload.errorMessage === undefined)
      ) {
        return {
          //...state,
          property: action.payload,
          // property: action.payload,
          requestFailed: false,
          requestFailedMessage: { error: 'no' },
          //add state data
          properties: [],
          requestMessage: 'add is succeeded',
          //
        };
      } else {
        return {
          //...state,
          properties: [],
          property: {},
          requestFailed: true,
          requestFailedMessage: action.payload,
          requestMessage: 'add is not succeeded',
        };
      }

    case ADD_PROPERTY_PHOTO:
      if (
        Object.keys(action.payload).length > 0 &&
        action.payload.error === undefined &&
        (action.payload.errorMessage === '' ||
          action.payload.errorMessage === undefined)
      ) {
        return {
          //...state,
          property: action.payload,
          // property: action.payload,
          requestFailed: false,
          requestFailedMessage: { error: 'no' },
          //add state data
          properties: [],
          requestMessage: action.payload.message,
          uploadedPhotoName: action.payload.uploadedPhotoName,
          directory: action.payload.directory,
          //
        };
      } else {
        return {
          //...state,
          properties: [],
          property: {},
          requestFailed: true,
          requestFailedMessage: action.payload,
          requestMessage: 'error in photo uploading',
          uploadedPhotoName: action.payload.uploadedPhotoName,
        };
      }

    default:
      return state;
  }
}
