import { ADD_AGENCY, GET_AGENCY } from '../actions/types';

const initialState = {
  agencies: [],
  agency: {},
  requestFailed: false,
  requestFailedMessage: {},
  requestSuccessMessage: '',
  resType: 'ready'
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_AGENCY:
      if (
        Object.keys(action.payload).length > 0 &&
        (action.payload.errorMessage === '' ||
          action.payload.errorMessage === undefined)
      ) {
        if (action.payload.error === undefined) {
          return {
            //...state,
            agency: action.payload,
            requestFailed: false,
            requestFailedMessage: { error: 'no' },
            //add state
            agencies: [],
            requestSuccessMessage: 'add is succeeded',
            resType: action.payload.resType
          };
        } else {
          return {
            //...state,
            agency: {},
            requestFailed: true,
            requestFailedMessage: action.payload,
            //add state
            agencies: [],
            requestSuccessMessage: 'error in add agency',
            resType: action.payload.resType
          };
        }
      } else {
        return {
          //...state,
          agency: {},
          requestFailed: true,
          requestFailedMessage: action.payload,
          //add state
          agencies: [],
          requestSuccessMessage: 'error in add agency',
          resType: action.payload.resType
        };
      }

    case GET_AGENCY:
      if (
        Object.keys(action.payload).length > 0 &&
        (action.payload.errorMessage === '' ||
          action.payload.errorMessage === undefined)
      ) {
        action.payload.processStatus = 'get agency';
        return {
          //...state,
          agency: action.payload,
          //add state data
          agencies: [],
          requestFailed: false,
          requestFailedMessage: {},
          requestSuccessMessage: '',
          resType: action.payload.resType
          //
        };
      } else {
        return {
          //...state,
          agency: [],
          requestFailed: true,
          requestFailedMessage: action.payload,
          //add state data
          agencies: [],
          requestSuccessMessage: '',
          resType: action.payload.resType
          //
        };
      }

    default:
      return state;
  }
}
