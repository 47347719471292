import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { getPropertiesPerProvince } from './../../actions/searchActions';
import HContainer from './../layout/hContainer/HContainer';
class MainPagePerProvinceH extends Component {
  constructor(props) {
    super();
  }
  state = {
    propertiesPerProvince: {},
    total_properties: 0,
    searchBarFullShow: false,
    requestSuccessMessage: {},
    errors: {},
    pageNumber: 1,
    searchParameters: {},
    currentProvince: '',
    resType: '',
  };

  onSearchSubmit = (recordsPerPage, pageNumber, province) => {
    const property = {};
    property.visible = true;
    if (province !== 'all') {
      property.province = province;
    }
    this.setState({ pageNumber });
    this.props.getPropertiesPerProvince(
      property,
      recordsPerPage,
      pageNumber,
      province
    );
  };
  componentDidMount() {
    if (
      this.props.properties !== this.state.propertiesPerProvince &&
      this.props.properties !== undefined
    ) {
      this.setState({
        propertiesPerProvince: this.props.properties,
        ///total_properties: properties[0].total_properties,
        //currentProvince: properties[0].province
      });
    } else {
      this.setState({
        propertiesPerProvince: {},
        //total_properties: 0
      });
    }
    //this.onSearchSubmit(recordsPerPage, 1);
  }
  componentWillReceiveProps(nextProps, nextState) {
    if (
      nextProps.properties !== this.state.propertiesPerProvince &&
      nextProps.properties !== undefined
    ) {
      this.setState({
        propertiesPerProvince: nextProps.properties,
        ///total_properties: properties[0].total_properties,
        //currentProvince: properties[0].province
      });
    } else {
      this.setState({
        propertiesPerProvince: {},
        //total_properties: 0
      });
    }

    if (
      nextProps.resType !== this.state.resType &&
      nextProps.resType !== undefined
    ) {
      const { resType } = nextProps;
      this.setState({
        resType,
      });
    } else {
      this.setState({
        resType: '',
      });
    }
  }

  render() {
    const {
      propertiesPerProvince,
      // total_properties,
      // pageNumber,
      currentProvince,
      resType,
    } = this.state;

    return (
      <React.Fragment>
        <HContainer
          rows="10"
          cardsByRow="1"
          currentProvince={currentProvince}
          propertiesData={propertiesPerProvince}
          title1="حسب المحافظة"
          title2=""
          link={`/properties_per_province/${currentProvince}`}
          onSearch={this.onSearchSubmit}
          resType={resType}
          main_type={this.props.main_type}
          business_offer={this.props.business_offer}
        ></HContainer>
      </React.Fragment>
    );
  }
}

MainPagePerProvinceH.propTypes = {
  propertiesPerProvince: PropTypes.array.isRequired,
  getPropertiesPerProvince: PropTypes.func.isRequired,
  currentProvince: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  propertiesPerProvince: state.search.propertiesPerProvince,
  currentProvince: state.search.currentProvince,
});

export default connect(mapStateToProps, { getPropertiesPerProvince })(
  MainPagePerProvinceH
);
