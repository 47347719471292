import { ADD_USER, GET_USER } from '../actions/types';
const initialState = {
  requestFailed: false,
  requestFailedMessage: {},
  requestSuccessMessage: ''
};

export default function(state = initialState, action) {
  switch (action.type) {
    case ADD_USER:
      if (
        (Object.keys(action.payload).length > 0 &&
          action.payload.errorMessage === '') ||
        action.payload.errorMessage === undefined
      ) {
        if (action.payload.error === undefined) {
          return {
            //...state,
            user: action.payload,
            requestFailed: false,
            requestFailedMessage: { error: 'no' },
            ///add state
            users: []
          };
        } else {
          return {
            //...state,
            user: {},
            requestFailed: true,
            requestFailedMessage: action.payload,
            ///add state
            users: []
          };
        }
      } else {
        return {
          //...state,
          user: {},
          requestFailed: true,
          requestFailedMessage: action.payload,
          ///add state
          users: []
        };
      }

    case GET_USER:
      if (
        (Object.keys(action.payload).length > 0 &&
          action.payload.errorMessage === '') ||
        action.payload.errorMessage === undefined
      ) {
        action.payload.processStatus = 'get user';
        return {
          //...state,
          user: action.payload,
          //add state data
          users: [],
          requestFailed: false,
          requestFailedMessage: {},
          requestSuccessMessage: ''
          //
        };
      } else {
        return {
          //...state,
          user: [],
          requestFailed: true,
          requestFailedMessage: action.payload,
          //add state data
          users: [],
          requestSuccessMessage: ''
          //
        };
      }

    default:
      return state;
  }
}
