import React, { Component } from "react";
import Property from "./../properties/Property";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import shortid from "shortid";
import SearchBar from "./SearchBar.jsx";
import { linkDomain } from "./../../mainConf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PaginationForProperties from "./../layout/Pagination/PaginationForProperties";
import { formatMoney } from "./../../mainConf/functions";
import { Helmet } from "react-helmet";
import FacebookButton from "./../../facebookButton/FacebookButton";
import SearchCategory from "./SearchCategory";
import queryString from "query-string";
import { isNumeric } from "../../mainConf/functions";

class Search extends Component {
  constructor(props) {
    super();
  }
  state = {
    is_page_param: false,
    // pageTitle: '',
    // pageDescription: '',
    properties: [],
    searchBarFullShow: false,
    requestSuccessMessage: {},
    errors: {},
    total_properties: -1,
    locationSearch: "",
    resType: "ready",
    largeScreen: "",
    categories: {},
    categories2: {},
    categoriesPerProvince: {},
    categoriesPerBusinessOffer: {},
    categoriesPerPropertyType: {},
    requestDataType: "",
    requestDataType2: "",
    property_type_from_category: "",
    business_offer_from_category: "",
    province_from_category: "",
  };
  resize() {
    let currentWidth = window.innerWidth;
    if (currentWidth >= 768) {
      this.setState({ largeScreen: "t" });
    } else {
      this.setState({ largeScreen: "f" });
    }
  }
  async componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    let searchURLParams = await queryString.parse(this.props.location.search);
    let lowerSearchURLParams = {};
    await Object.keys(searchURLParams).forEach(function (key, value) {
      lowerSearchURLParams[key.toLowerCase()] = searchURLParams[key];
    });
    searchURLParams = lowerSearchURLParams;

    const is_page_param =
      searchURLParams.page === undefined ||
      searchURLParams.page === null ||
      !isNumeric(searchURLParams.page)
        ? false
        : true;
    this.setState({ is_page_param });
  }
  async componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.location.search !== this.state.locationSearch) {
      await this.setState({ locationSearch: nextProps.location.search });
    }
    if (
      nextProps.resType !== undefined &&
      nextProps.resType !== this.state.resType
    ) {
      const { resType } = nextProps;
      this.setState({ resType });
    }
    if (
      nextProps.properties !== this.state.properties &&
      nextProps.properties[0] !== undefined
    ) {
      const { properties } = nextProps;
      this.setState({
        properties,
        total_properties: properties[0].total_properties,
      });
    } else if (
      nextProps.properties[0] === undefined ||
      nextProps.properties.length === 0
    ) {
      this.setState({
        properties: [],
        total_properties: 0,
      });
    }
    if (
      nextProps.requestData !== undefined &&
      nextProps.requestData.aggs_on !== undefined
    ) {
      let property_type_from_category = "";
      let province_from_category = "";

      if (nextProps.requestData.province !== undefined) {
        province_from_category = nextProps.requestData.province;
      }
      if (nextProps.requestData.type !== undefined) {
        property_type_from_category = nextProps.requestData.type;
      } else {
        property_type_from_category = "عقارات";
      }

      this.setState({
        property_type_from_category,
        province_from_category,
      });
    }

    if (
      nextProps.requestData2 !== undefined &&
      nextProps.requestData2.aggs_on !== undefined
    ) {
      let business_offer_from_category = "";
      if (nextProps.requestData2.business_offer !== undefined) {
        business_offer_from_category = nextProps.requestData2.business_offer;
      } else {
        business_offer_from_category = "عروض";
      }

      this.setState({
        business_offer_from_category,
      });
    }

    if (
      nextProps.requestData !== undefined &&
      nextProps.requestData.aggs_on !== undefined &&
      nextProps.requestData.aggs_on !== this.state.requestDataType
    ) {
      const { requestData } = nextProps;
      this.setState({
        requestDataType: nextProps.requestData.aggs_on,
      });
    }

    if (
      nextProps.requestData2 !== undefined &&
      nextProps.requestData2.aggs_on !== undefined &&
      nextProps.requestData2.aggs_on !== this.state.requestDataType2
    ) {
      const { requestData2 } = nextProps;
      this.setState({
        requestDataType2: nextProps.requestData2.aggs_on,
      });
    }

    if (
      nextProps.categories !== this.state.categories &&
      nextProps.categories.requestData !== undefined &&
      nextProps.categories.requestData.aggs_on !== undefined
    ) {
      const { categories } = nextProps;
      let categoriesPerProvince = {};
      let categoriesPerPropertyType = {};
      if (
        nextProps.categories.requestData.aggs_on !== undefined &&
        nextProps.categories.requestData.aggs_on === "province"
      ) {
        categoriesPerProvince = nextProps.categories.per_province;
      }
      if (
        nextProps.categories.requestData.aggs_on !== undefined &&
        nextProps.categories.requestData.aggs_on === "type"
      ) {
        categoriesPerPropertyType = nextProps.categories.per_type;
      }
      this.setState({
        categories,
        categoriesPerProvince,
        categoriesPerPropertyType,
      });
    }

    if (
      nextProps.categories2 !== this.state.categories2 &&
      nextProps.categories2.requestData !== undefined &&
      nextProps.categories2.requestData.aggs_on !== undefined
    ) {
      const { categories2 } = nextProps;
      let categoriesPerBusinessOffer = {};

      if (
        nextProps.categories2.requestData.aggs_on !== undefined &&
        nextProps.categories2.requestData.aggs_on === "business_offer"
      ) {
        categoriesPerBusinessOffer = nextProps.categories2.per_business_offer;
      }
      this.setState({
        categories2,
        categoriesPerBusinessOffer,
      });
    }
  }
  onSearchClick = () => {
    var elmntToView = document.getElementById("search_bar_section");
    elmntToView.scrollIntoView({ behavior: "smooth" });
  };
  render() {
    const {
      properties,
      total_properties,
      resType,
      largeScreen,
      property_type_from_category,
      business_offer_from_category,
      province_from_category,
    } = this.state;
    return (
      <React.Fragment>
        <Helmet>
          {/* <title>{this.state.pageTitle}</title>
          <meta name="description" content={this.state.pageDescription} /> */}
          <link
            rel="canonical"
            href={`${linkDomain}/search${
              this.state.pageNumber > 0 && this.state.is_page_param
                ? `?page=${this.state.pageNumber}`
                : ""
            }`}
          />
        </Helmet>
        {largeScreen === "t" ? (
          <SearchBar
            searchBarFullShow={this.state.searchBarFullShow}
            searchType="main_search"
            destination="/search"
          ></SearchBar>
        ) : null}
        {largeScreen === "f" ? (
          <div className="text-center my-1">
            <a href={`${linkDomain}/`} className="btn btn-warning mx-1 my-1">
              <span>
                <FontAwesomeIcon icon={["fas", "home"]} />
              </span>
              <span>{` `}الصفحة الرئيسية</span>
            </a>

            <button
              className="btn btn-warning mx-1 my-1"
              onClick={this.onSearchClick}
            >
              <span>
                <FontAwesomeIcon icon={["fas", "search"]} />
              </span>
              <span>{` `}ابحث عن عقارك المفضل</span>
            </button>
            <br />
            <FacebookButton />
          </div>
        ) : null}
        {/* Categories */}
        <SearchCategory
          business_offer_from_category={business_offer_from_category}
          province_from_category={province_from_category}
          property_type_from_category={property_type_from_category}
          categoriesPerBusinessOffer={this.state.categoriesPerBusinessOffer}
          categoriesPerPropertyType={this.state.categoriesPerPropertyType}
          categoriesPerProvince={this.state.categoriesPerProvince}
        ></SearchCategory>
        <div className="mb-3 text-center">
          <span className="main_title">نتائج البحث </span>
          <span className="main_title">
            {total_properties === 0 || total_properties === -1 ? (
              ""
            ) : (
              <span>{formatMoney(total_properties)}</span>
            )}
          </span>
        </div>
        <React.Fragment>
          {resType === "complete" &&
          (properties === [] ||
            // properties === undefined ||
            properties.length === 0) ? (
            <React.Fragment>
              <div className="text-center">
                <span className="display_gold display_7">
                  لا يوجد نتائج للبحث المدخل
                </span>
              </div>

              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </React.Fragment>
          ) : null}
          <div className="property-container">
            <div className="container-fluid">
              <div className="row justify-content-around align-items-center">
                {properties !== [] &&
                  properties !== undefined &&
                  (properties || []).map((property) => (
                    <Property
                      key={shortid.generate()}
                      property={property}
                      isLoggedIn={false}
                      ikarAdmin={false}
                      requestSuccessMessage={this.state.requestSuccessMessage}
                    />
                  ))}
              </div>
              <PaginationForProperties
                resultLabel="عقار"
                recordsNumber={total_properties}
                // pageNumber={pageNumber}
                // onPageChange={this.onSearchButtonSubmit}
                showMessage={true}
                destination="/search"
                locationSearch={this.state.locationSearch}
              ></PaginationForProperties>
            </div>
          </div>
          {largeScreen === "f" ? (
            <div id="search_bar_section">
              <SearchBar
                searchBarFullShow={this.state.searchBarFullShow}
                searchType="main_search"
                destination="/search"
              ></SearchBar>
            </div>
          ) : null}
        </React.Fragment>
      </React.Fragment>
    );
  }
}

Search.propTypes = {
  properties: PropTypes.array.isRequired,
  categories: PropTypes.object.isRequired,
  categories2: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  properties: state.search.properties,
  resType: state.search.resType,
  categories: state.category.categories,
  requestData: state.category.requestData,
  categories2: state.category.categories2,
  requestData2: state.category.requestData2,
});

export default connect(mapStateToProps, {})(Search);
