import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';
import { loginProcess } from './../../actions/authActions';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFaqs, deleteFaq } from './../../actions/faqActions';
import { editFaq } from './../../actions/faqActionsUD';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { Growl } from 'primereact/growl';
import { linkDomain } from './../../mainConf/index';
import Lightbox from 'react-image-lightbox';
import defaultBackground from './../../assets/images/defaultBackground.png';
import { imageDomain } from './../../mainConf';
import { takeAgencyRole } from './../../actions/authActions';
import { Helmet } from 'react-helmet';
class ShowFaqByAdmin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pageTitle: '',
      pageDescription: '',
      selectedFaq: {},
      requestSuccessMessage: '',
      deleteSuccessMessage: '',
      faqs: [],
      updatedReferenceId: '',
      updatedRank: '',
      total_faqs: 0,
      pageNumber: 1,
      bgURL: '',

      showMainPhoto: false,
    };
  }
  componentWillReceiveProps(nextProps, nextState) {
    let result2 = [];
    if (
      nextProps.deleteSuccessMessage !== this.state.deleteSuccessMessage &&
      this.state.deleteSuccessMessage !== '' &&
      nextProps.deleteSuccessMessage !== undefined &&
      nextProps.deleteSuccessMessage !== ''
    ) {
      this.setState({
        deleteSuccessMessage: nextProps.deleteSuccessMessage,
      });

      switch (nextProps.deleteSuccessMessage) {
        case 'حذف السؤال المحدد':
          this.growl.show({
            severity: 'success',
            summary: 'تم حذف السؤال المحدد',
          });
          this.state.faqs.forEach((obj, index) => {
            if (obj.reference_id !== this.state.updatedReferenceId) {
              result2.push(obj);
            }
          });
          this.setState({ faqs: result2 });
          break;

        default:
          break;
      }
    } else if (
      nextProps.requestSuccessMessage !== this.state.requestSuccessMessage &&
      this.state.requestSuccessMessage !== ''
    ) {
      this.setState({
        requestSuccessMessage: nextProps.requestSuccessMessage,
      });

      switch (nextProps.requestSuccessMessage) {
        case 'إظهار السؤال المحدد':
          this.growl.show({
            severity: 'success',
            summary: 'تم إظهار السؤال المحدد',
          });
          this.state.faqs.forEach((obj, index) => {
            result2.push(obj);
            if (obj.reference_id === this.state.updatedReferenceId) {
              result2[index].visible = !obj.visible;
            }
          });
          this.setState({ faqs: result2 });
          break;
        case 'إخفاء السؤال المحدد':
          this.growl.show({
            severity: 'success',
            summary: 'تم إخفاء السؤال المحدد',
          });
          this.state.faqs.forEach((obj, index) => {
            result2.push(obj);
            if (obj.reference_id === this.state.updatedReferenceId) {
              result2[index].visible = !obj.visible;
            }
          });
          this.setState({ faqs: result2 });
          break;

        default:
          break;
      }
    } else if (
      nextProps.faqs !== this.state.faqs &&
      nextProps.faqs[0] !== undefined &&
      nextProps.faqs[0] !== null
    ) {
      this.setState({
        faqs: nextProps.faqs,
        total_faqs: nextProps.faqs[0].total_faqs,
      });
    } else {
      this.setState({
        faqs: [],
        total_faqs: 0,
      });
    }
  }
  componentDidMount() {
    this.props.getFaqs(this.props.ikarAdmin);
    // jQuery(function($) {
    //   $('#agencyTable').on('click', '.clickable-row', function(event) {
    //     $(this)
    //       .addClass('table-success')
    //       .siblings()
    //       .removeClass('table-success');
    //   });
    // });
  }

  onChangeVisibility = async (reference_id, chargedStatus, e) => {
    e.preventDefault();

    var faq = {};
    var processType = '';
    switch (chargedStatus) {
      case 'visible':
        if (!window.confirm('إظهار السؤال المحدد؟')) {
          return;
        }
        faq = {
          reference_id,
          visible: true,
        };
        processType = 'إظهار السؤال المحدد';
        break;
      case 'hide':
        if (!window.confirm('إخفاء السؤال المحدد؟')) {
          return;
        }
        faq = {
          reference_id,
          visible: false,
        };
        processType = 'إخفاء السؤال المحدد';
        break;
      default:
        break;
    }
    await this.setState({
      requestSuccessMessage: 'ready',
      updatedReferenceId: reference_id,
    });
    const faqDate = { faq };
    await this.props.editFaq(faqDate, processType);
  };
  faqStatusTemplate = (rowData, column) => {
    return (
      <span
        className={classnames(
          'text-center',
          { 'text-success': rowData.visible },
          { 'text-danger': !rowData.visible }
        )}
      >
        {rowData.visible ? <span>نعم</span> : <span>لا</span>}
      </span>
    );
  };
  onEditFaq = async (e) => {
    if (!window.confirm('تعديل السؤال المحدد ؟')) {
      e.preventDefault();
      return;
    }
  };
  showDetailsTemplate = (rowData, column) => {
    return (
      <React.Fragment>
        {rowData.visible ? (
          <Button
            //icon="pi pi-check"
            // icon="fa fa-store"
            //iconPos="right"
            className="p-button-success btn_process"
            tooltip="إخفاء السؤال"
            tooltipOptions={{
              position: 'bottom',
              event: 'hover',
            }}
            onClick={this.onChangeVisibility.bind(
              this,
              rowData.reference_id,
              'hide'
            )}
          >
            <FontAwesomeIcon icon={['fas', 'store']}></FontAwesomeIcon>
          </Button>
        ) : (
          <Button
            // icon="fa fa-store"
            //iconPos="right"
            className="p-button-danger btn_process"
            tooltip="إظهار السؤال"
            tooltipOptions={{
              position: 'bottom',
              event: 'hover',
            }}
            onClick={this.onChangeVisibility.bind(
              this,
              rowData.reference_id,
              'visible'
            )}
          >
            <FontAwesomeIcon icon={['fas', 'store']}></FontAwesomeIcon>
          </Button>
        )}
        {/* </div>
          <div className="col-6 text-center px-0"> */}

        <Button
          //icon="pi pi-check"
          // icon="fa fa-money-check"
          //iconPos="right"
          className="p-button-success btn_process"
          tooltip="حذف السؤال المحدد"
          tooltipOptions={{
            position: 'bottom',
            event: 'hover',
          }}
          onClick={this.onDeleteFaq.bind(
            this,
            rowData.reference_id,
            'remove_faq'
          )}
        >
          <FontAwesomeIcon icon={['fas', 'times']}></FontAwesomeIcon>
        </Button>

        <Link to={`/adminDashBoard/edit_faq?faq_id=${rowData.reference_id}`}>
          <Button
            // icon="fa fa-edit"
            //iconPos="right"
            className="p-button-warning btn_process"
            tooltip="تعديل السؤال"
            tooltipOptions={{
              position: 'bottom',
              event: 'hover',
            }}
            onClick={this.onEditFaq.bind(this)}
            disabled={rowData.business_status === 'مؤرشف'}
          >
            <FontAwesomeIcon icon={['fas', 'edit']}></FontAwesomeIcon>
          </Button>
        </Link>
      </React.Fragment>
    );
  };
  export = () => {
    this.dt.exportCSV();
  };
  onSearchSubmit = (recordsPerPage, pageNumber) => {
    this.setState({ pageNumber });
  };
  showPhotoTemplate = (rowData, column) => {
    var bgURL = '';
    // var thumbnail = '';

    if (
      rowData.photo === null ||
      rowData.photo === undefined ||
      rowData.photo.length === 0
    ) {
      bgURL = defaultBackground;
      // thumbnail = defaultBackground;
    } else {
      bgURL = `${imageDomain}/${rowData.photo[0]}`;
      // thumbnail = `${imageDomain}/${rowData.thumbnail[0]}`;
    }
    // const { showMainPhoto } = this.state;
    return (
      <React.Fragment>
        <img
          ////////src={thumbnail}
          src={bgURL}
          alt=""
          style={{ width: '100px', height: '50px' }}
          onClick={() =>
            this.setState({
              showMainPhoto: true,
              bgURL,
            })
          }
        />
      </React.Fragment>
    );
  };
  handleTakeAgencyRole = (referenceId, e) => {
    if (!window.confirm('الدخول كالمستخدم المحدد ؟')) {
      return;
    }
    this.props.takeAgencyRole(referenceId);
    // this.props.history.push("/dashBoard/properties_by_agency");
    //this.props.history.push('/');
  };
  onNewSearch = () => {
    this.setState({ pageNumber: 1 });
  };
  onDeleteFaq = async (reference_id, e) => {
    let processType = 'حذف السؤال المحدد';
    if (!window.confirm('حذف السؤال المحدد ؟')) {
      return;
    }
    await this.setState({
      deleteSuccessMessage: 'ready',
      updatedReferenceId: reference_id,
    });
    await this.props.deleteFaq(reference_id, processType);
  };
  render() {
    const { faqs, total_faqs, pageNumber, bgURL, showMainPhoto } = this.state;
    var faqHeader = (
      <div className="px-2 py-0" style={{ textAlign: 'right' }}>
        <Button
          type="button"
          icon="pi pi-external-link"
          iconPos="left"
          label="تصدير الجدول الى ملف اكسل CSV"
          onClick={this.export}
        ></Button>
      </div>
    );

    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.pageTitle}</title>
          <meta name="description" content={this.state.pageDescription} />
        </Helmet>
        <div className="mb-3 text-center my-2">
          <h1 className="main_title">قائمة الأسئلة الشائعة</h1>
        </div>
        <div className="text-right mx-2">
          <a href={`${linkDomain}/adminDashBoard/add_faq`}>ادخال سؤال جديد</a>
        </div>
        <div className="property-container">
          <div className="container-fluid">
            <Growl ref={(el) => (this.growl = el)} />
            {/* row justify-content-around align-items-center  */}
            <div className="table_container mt-1">
              <DataTable
                ref={(el) => {
                  this.dt = el;
                }}
                value={faqs}
                responsive={true}
                //header="المكاتب العقارية"
                selectionMode="single"
                selection={this.state.selectedFaq}
                onSelectionChange={(e) => {
                  this.setState({ selectedFaq: e.value });
                }}
                header={faqHeader}
              >
                {/* <Column
                    header="الصورة"
                    className="table_td"
                    body={this.showPhotoTemplate}
                    style={{ width: '105px' }}
                  /> */}
                <Column
                  field="title"
                  header="العنوان"
                  sortable={true}
                  className="table_td"
                  // style={{ width: "150px" }}
                />
                <Column
                  field="details"
                  header="التفاصيل"
                  sortable={true}
                  className="table_td"
                  // style={{ width: "150px" }}
                />

                <Column
                  field="question_number"
                  header="رقم السؤال"
                  className="table_td"
                  sortable={true}
                  style={{ width: '90px' }}
                />
                {/* <Column
                  body={this.faqStatusTemplate}
                  className="table_td"
                  header="السؤال ظاهر"
                /> */}
                <Column
                  body={this.showDetailsTemplate}
                  // header="الاجراءات"
                  style={{ width: '150px' }}
                  className="table_td table-functions"
                />
              </DataTable>
              {showMainPhoto && (
                <Lightbox
                  mainSrc={bgURL}
                  nextSrc={bgURL}
                  prevSrc={bgURL}
                  onCloseRequest={() => this.setState({ showMainPhoto: false })}
                  onMovePrevRequest={
                    () => {
                      return;
                    }
                    // this.setState({
                    //   showMainPhoto: (showMainPhoto + url.length - 1) % url.length
                    // })
                  }
                  onMoveNextRequest={
                    () => {
                      return;
                    }
                    // this.setState({
                    //   photoIndex: (showMainPhoto + 1) % url.length
                    // })
                  }
                />
              )}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

ShowFaqByAdmin.propTypes = {
  faqs: PropTypes.array.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  ikarAdmin: PropTypes.bool.isRequired,
  editFaq: PropTypes.func.isRequired,
  getFaqs: PropTypes.func.isRequired,
  deleteFaq: PropTypes.func.isRequired,
  requestSuccessMessage: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  faqs: state.faq.faqs,
  isLoggedIn: state.auth.isLoggedIn,
  ikarAdmin: state.auth.ikarAdmin,
  requestSuccessMessage: state.faqUD.requestSuccessMessage,
  deleteSuccessMessage: state.faq.deleteSuccessMessage,
  // short_id: state.agencyUD.short_id,
  // short_id2: state.agency.short_id2,
});

export default compose(
  withRouter,
  connect(mapStateToProps, {
    loginProcess,
    editFaq,
    getFaqs,
    deleteFaq,
    takeAgencyRole,
  })
)(ShowFaqByAdmin);
