import { GET_MAIN_RESPONSE, SET_MAIN_RESPONSE } from './types';
import axios from 'axios';
import { mainDomain } from './../mainConf';

// export const getProperties = () => async dispatch => {
//   ///const res=await axios.get('');
//   const res = {};
//   dispatch({
//     type: GET_PROPERTIES,
//     payload: res.data
//   });
// };

export const getMainResponse = (requestData) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  await axios
    .post(
      `${mainDomain}/v1/home`,
      requestData
      // , {
      //   withCredentials: true
      // }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
        res.resType = 'complete';
        res.requestData = requestData;
      } else res = {};
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
      res.resType = 'not complete';
      res.requestData = '';
    });

  dispatch({
    type: GET_MAIN_RESPONSE,
    payload: res,
  });
};

export const setMainResponse = (requestDate) => async (dispatch) => {
  let res = requestDate;

  dispatch({
    type: SET_MAIN_RESPONSE,
    payload: res,
  });
};
