import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { loginProcess } from './../../actions/authActions';
import { Growl } from 'primereact/growl';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { noInternetMessage } from './../../mainConf/index';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

class Login extends Component {
  constructor(props) {
    super(props);
    document.title = 'تسجيل الدخول | Ikar.sy';
    this.state = {
      pageTitle: '',
      pageDescription: '',
      hide: true,
      email: '',
      mobile: '09', //009639
      password: '',
      errors: {},
      showMessages: false,
      resType: '',
      process1Active: true,
      process2Active: false,
      processScreen: false,
      resMessages: {},
    };
  }
  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.resType !== this.state.resType) {
      this.setState({
        resType: nextProps.resType,
        processScreen: false,
      });

      if (
        nextProps.loginFailedMessage !== undefined &&
        nextProps.loginFailedMessage.data !== undefined &&
        nextProps.loginFailedMessage.data.error !== undefined &&
        nextProps.loginFailedMessage.data.error === `invalid cred.` &&
        this.state.process1Active
      ) {
        this.setState({
          process1Active: true,
          process2Active: false,
          resMessages: {
            message: 'الرجاء التأكد من ايميل المستخدم أو كلمة السر',
          },
        });
      } else if (
        nextProps.loginFailedMessage !== undefined &&
        nextProps.loginFailedMessage.data !== undefined &&
        nextProps.loginFailedMessage.data.error !== undefined &&
        nextProps.loginFailedMessage.data.error === `not confirmed yet` &&
        this.state.process1Active
      ) {
        this.setState({
          process1Active: true,
          process2Active: false,
          resMessages: {
            message:
              'الرجاء الدخول الى الايميل لتفعيل الحساب الحالي قبل تسجيل الدخول',
          },
        });
      } else if (
        nextProps.loginFailedMessage !== undefined &&
        nextProps.loginFailedMessage.data !== undefined &&
        nextProps.loginFailedMessage.data.errors !== undefined &&
        nextProps.loginFailedMessage.data.errors[0].detail ===
          `Login request body is not valid  => email : is in invalid format` &&
        this.state.process1Active
      ) {
        this.setState({
          process1Active: true,
          process2Active: false,
          resMessages: {
            message: 'الرجاء التأكد من الايميل المدخل',
          },
        });
      } else if (
        nextProps.loginFailedMessage !== undefined &&
        nextProps.loginFailedMessage.data !== undefined &&
        nextProps.loginFailedMessage.data.errors !== undefined &&
        nextProps.loginFailedMessage.data.errors[0].detail ===
          `you must login in other way`
      ) {
        this.setState({
          process1Active: false,
          process2Active: true,
          resMessages: {
            message: 'الرجاء ادخال رقم موبايلك لاتمام عملية الدخول',
          },
        });
      } else if (
        nextProps.loginFailedMessage !== undefined &&
        nextProps.loginFailedMessage.data !== undefined &&
        nextProps.loginFailedMessage.data.error !== undefined &&
        nextProps.loginFailedMessage.data.error === `invalid cred.` &&
        this.state.process2Active
      ) {
        this.setState({
          process1Active: false,
          process2Active: true,
          resMessages: {
            message: 'الرجاء التأكد من رقم الموبايل أو كلمة السر',
          },
        });
      } else if (
        nextProps.loginFailedMessage !== undefined &&
        nextProps.loginFailedMessage.data !== undefined &&
        nextProps.loginFailedMessage.data.error !== undefined &&
        nextProps.loginFailedMessage.data.error === `not confirmed yet` &&
        this.state.process2Active
      ) {
        this.setState({
          process1Active: false,
          process2Active: true,
          resMessages: {
            message:
              'الرجاء الدخول الى الايميل لتفعيل الحساب الحالي قبل تسجيل الدخول',
          },
        });
      }
    }
  }
  onSubmit = async (e) => {
    e.preventDefault();
    await this.setState({ showMessages: false, errors: {} });
    const { email, password } = this.state;
    var errors = {};

    if (this.storageAvailable('localStorage')) {
      if (email.trim() === '') {
        errors.email = 'يجب ادخال الايميل';
      }

      // if (!/[\w+\-.]+@[a-zA-Z.-]+\.[a-z]{2,3}/.test(email)) {
      else if (!/[\w+\-.]+@[a-zA-Z.-]+\.[a-zA-Z]{2,3}/.test(email)) {
        errors.email = 'يجب التأكد من الايميل المدخل';
      }
      if (password === '') {
        errors.password = 'يجب ادخال كلمة السر';
      }
      if (password.length < 9) {
        errors.password = 'يجب أن لا تقل كلمة السر عن  تسعة محارف';
      }
      const loginData = { email, password };

      if (Object.keys(errors).length > 0) {
        this.setState({ errors, resMessages: {} });
        return;
      }
      await this.setState({
        errors: {},
        resMessages: {},
        processScreen: true,
        resType: '',
      });

      this.props.loginProcess(loginData);
    } else {
      alert('Please enable cookies in your browser before continue');
    }
  };
  onSubmit2 = async (e) => {
    e.preventDefault();
    await this.setState({ showMessages: false, errors: {} });
    const { mobile, password } = this.state;
    var errors = {};

    if (this.storageAvailable('localStorage')) {
      if (
        (mobile !== null && mobile.trim() === '') ||
        mobile.trim() === '09' //009639
      ) {
        errors.mobile = 'يجب ادخال رقم الموبايل';
      } else if (mobile.trim() !== '' && mobile.trim() !== '09') {
        ///14
        //009639
        if (String(mobile.trim()).charAt(0) !== '0') {
          ///0
          errors.mobile = 'يجب التأكد من رقم الموبايل';
        }
        if (mobile.trim().length < 10 || mobile.trim().length > 10) {
          ///14
          errors.mobile = 'يجب التأكد من رقم الموبايل';
        }
        if (mobile.trim().length > 1 && !this.isNumeric(mobile.trim())) {
          errors.mobile = 'يجب التأكد من رقم الموبايل';
        }
      }

      if (password === '') {
        errors.password = 'يجب ادخال كلمة السر';
      }
      if (password.length < 9) {
        errors.password = 'يجب أن لا تقل كلمة السر عن  تسعة محارف';
      }
      const loginData = {
        mobile:
          mobile.replace(/\s/g, '') === '' || mobile.replace(/\s/g, '') === '09' //009639
            ? null
            : `00963${mobile.substr(1).replace(/\s/g, '')}`,
        password,
      };

      if (Object.keys(errors).length > 0) {
        this.setState({ errors, resMessages: {} });
        return;
      }
      await this.setState({
        errors: {},
        resMessages: {},
        processScreen: true,
        resType: '',
      });

      this.props.loginProcess(loginData);
    } else {
      alert('Please enable cookies in your browser before continue');
    }
  };
  storageAvailable = (type) => {
    try {
      var storage = window[type],
        x = '__storage_test__';
      storage.setItem(x, x);
      storage.removeItem(x);
      return true;
    } catch (e) {
      return false;
    }
  };
  onChange = (e) => this.setState({ [e.target.name]: e.target.value });
  onMobileChange = (e) => {
    if (e.target.value.length > 10 || e.target.value.length === 1) {
      return;
    }
    if (/^(09)\d{0,8}?$/.test(e.target.value)) {
      //009639
      this.setState({ [e.target.name]: e.target.value });
    }
  };
  isNumeric = (num) => {
    // return !isNaN(num);
    // return /^-{0,1}\d+$/.test(num);
    return /^\d+$/.test(num);
  };
  // handleChange(e) {
  //   this.setState({
  //     [e.target.name]: e.target.value
  //   });
  // }
  changeType = () => {
    this.setState({ hide: !this.state.hide });
  };
  onProcessChange = (e) => {
    if (e.target.value === 'process1') {
      this.setState({ process1Active: true, process2Active: false });
    } else if (e.target.value === 'process2') {
      this.setState({ process2Active: true, process1Active: false });
    }
  };
  render() {
    const {
      errors,
      hide,
      mobile,
      process1Active,
      process2Active,
      resMessages,
      processScreen,
    } = this.state;
    return (
      <div className="login-div">
        <Helmet>
          <title>{this.state.pageTitle}</title>
          <meta name="description" content={this.state.pageDescription} />
          <meta name="robots" content="noindex" />
        </Helmet>
        <div className="row">
          <div className="col-11 col-md-8 col-lg-7 col-xl-6 mx-auto">
            {/* {message ? (
                <Alert message={message} messageType={messageType} />
              ) : null} */}
            <div className="display_6 text-center round-div py-1">
              {
                'اذا لم يكن لديك حساب يمكنك التواصل معنا (واتس / تلغرام / اتصال) على الرقم'
              }
              <a
                href="tel:00963981785225"
                // onClick="ga('send', 'event', { eventCategory: 'Contact', eventAction: 'Call', eventLabel: 'Mobile Button'});"
                style={{ color: '#f18805' }}
              >
                {` 0981785225 `}
              </a>
            </div>
            <div className="text-center pb-4 pt-3">
              <h1 className="main_title">
                <FontAwesomeIcon
                  icon={['fas', 'user-lock']}
                  size="1x"
                  className="second-color mx-2"
                />{' '}
                تسجيل الدخول
              </h1>
              <Growl ref={(el) => (this.growl = el)} />
            </div>

            {processScreen ? (
              // <div className="process-loader">Ikar.sy</div>
              <div className="process-loader2">
                <div className="span text-center">
                  {/* <div className="location_indicator" /> */}
                  <div
                    className="spinner-border text-warning"
                    style={{ width: '3rem', height: '3rem' }}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                  <p className="text-danger lead text-center">
                    <span>www.</span>
                    <span className="font-weight-bold">Ikar</span>
                    <span className="font-weight-bold">.sy</span>
                  </p>
                </div>
              </div>
            ) : null}
            <div className="input-group justify-content-end justify-content-md-center mb-2">
              <div className="form-group text-right">
                <input
                  id="process2"
                  type="radio"
                  name="processType"
                  value="process2"
                  onChange={this.onProcessChange}
                  checked={this.state.process2Active}
                  style={{ color: '#fcb815' }}
                />
                <label
                  htmlFor="process2"
                  className="no_select login_label_narrow pr-1 pl-1 display_gold"
                >
                  تسجيل الدخول برقم الموبايل
                </label>
              </div>
              <div className="form-group text-right">
                <input
                  id="process1"
                  type="radio"
                  name="processType"
                  value="process1"
                  className=" ml-4"
                  checked={this.state.process1Active}
                  onChange={this.onProcessChange}
                />
                <label
                  htmlFor="process1"
                  className="no_select login_label_narrow pr-1 pl-1 display_gold"
                >
                  تسجيل الدخول بالبريد الالكتروني
                </label>
              </div>
            </div>
            {process1Active ? (
              <React.Fragment>
                <form onSubmit={this.onSubmit} style={{ direction: 'rtl' }}>
                  <div className="form-group text-right mb-4">
                    <label htmlFor="email">البريد الالكتروني:</label>
                    <input
                      type="email"
                      className={classnames('form-control', {
                        'is-invalid': errors.email,
                      })}
                      name="email"
                      value={this.state.email}
                      onChange={this.onChange}
                    />
                    {errors.email && (
                      <div className="invalid-feedback">{errors.email}</div>
                    )}
                  </div>
                  <div className="form-group text-right">
                    <label htmlFor="password" className="password-label">
                      كلمة السر:
                      <input
                        type={hide ? 'password' : 'text'}
                        className={classnames('form-control', {
                          'is-invalid': errors.password,
                        })}
                        name="password"
                        value={this.state.password}
                        onChange={this.onChange}
                      />
                      {errors.password && (
                        <div className="invalid-feedback">
                          {errors.password}
                        </div>
                      )}
                      <span
                        className={classnames({
                          'password-hide-show-e': errors.password,
                          'password-hide-show': !errors.password,
                        })}
                        onClick={this.changeType}
                      >
                        {hide ? (
                          <FontAwesomeIcon
                            icon={['fas', 'eye']}
                            size="2x"
                            color="#fcb814"
                          ></FontAwesomeIcon>
                        ) : (
                          <FontAwesomeIcon
                            icon={['fas', 'eye-slash']}
                            size="2x"
                            color="#fcb814"
                          ></FontAwesomeIcon>
                        )}
                      </span>
                    </label>
                  </div>
                  <input
                    type="submit"
                    value="تسجيل الدخول"
                    className="btn btn-block submit-button"
                  />
                </form>
                <div className="text-right py-2">
                  <Link to="/passwordrecovery">
                    <span className="display_7">نسيت كلمة السر؟</span>
                  </Link>
                </div>
              </React.Fragment>
            ) : process2Active ? (
              <React.Fragment>
                <form onSubmit={this.onSubmit2} style={{ direction: 'rtl' }}>
                  <div className="form-group text-right mb-4">
                    <label htmlFor="mobile">رقم الموبايل:</label>
                    <input
                      type="tel"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      name="mobile"
                      // id="mobileId"
                      className={classnames('form-control', {
                        'is-invalid': errors.mobile,
                      })}
                      style={{ direction: 'ltr' }}
                      placeholder=""
                      value={mobile}
                      onChange={this.onMobileChange}
                    />
                    {errors.mobile && (
                      <div className="invalid-feedback">{errors.mobile}</div>
                    )}
                  </div>
                  <div className="form-group text-right">
                    <label htmlFor="password" className="password-label">
                      كلمة السر:
                      <input
                        type={hide ? 'password' : 'text'}
                        className={classnames('form-control', {
                          'is-invalid': errors.password,
                        })}
                        name="password"
                        value={this.state.password}
                        onChange={this.onChange}
                      />
                      {errors.password && (
                        <div className="invalid-feedback">
                          {errors.password}
                        </div>
                      )}
                      <span
                        className={classnames({
                          'password-hide-show-e': errors.password,
                          'password-hide-show': !errors.password,
                        })}
                        onClick={this.changeType}
                      >
                        {hide ? (
                          <FontAwesomeIcon
                            icon={['fas', 'eye']}
                            size="2x"
                            color="#fcb814"
                          ></FontAwesomeIcon>
                        ) : (
                          <FontAwesomeIcon
                            icon={['fas', 'eye-slash']}
                            size="2x"
                            color="#fcb814"
                          ></FontAwesomeIcon>
                        )}
                      </span>
                    </label>
                  </div>
                  <input
                    type="submit"
                    value="تسجيل الدخول"
                    className="btn btn-block submit-button"
                  />
                </form>
                <div className="text-right py-2">
                  <Link to="/passwordrecovery">
                    <span className="display_7">نسيت كلمة السر؟</span>
                  </Link>
                </div>
              </React.Fragment>
            ) : null}
            {Object.keys(resMessages).length > 0 ? (
              <div className="alert alert-danger mt-2 text-right">
                {resMessages.message}
              </div>
            ) : null}
            {/* {showMessages &&
            loginFailed &&
            loginFailedMessage.data !== undefined &&
            loginFailedMessage.data.error === `invalid cred.` ? (
              <div className="alert alert-danger mt-2 text-right">
                الرجاء التأكد من اسم المستخدم أو كلمة السر
              </div>
            ) : showMessages &&
              loginFailed &&
              loginFailedMessage.data !== undefined &&
              loginFailedMessage.data.errors !== undefined &&
              loginFailedMessage.data.errors[0].detail ===
                `Login request body is not valid  => email : is in invalid format` ? (
              <div className="alert alert-danger mt-2 text-right">
                الرجاء التأكد من الايميل المدخل
              </div>
            ) : showMessages &&
              loginFailed &&
              loginFailedMessage.response !== undefined &&
              loginFailedMessage.response === '' ? (
              <div className="alert alert-danger mt-2 text-right">
                {noInternetMessage}
              </div>
            ) : null} */}
          </div>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  loginRes: PropTypes.object.isRequired,
  loginProcess: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  ikarAdmin: PropTypes.bool.isRequired,
  agencyAdmin: PropTypes.bool.isRequired,
};

//const mapStateToProps = state => ({
function mapStateToProps(state) {
  const loginRes = state.auth.loginRes;
  const isLoggedIn = state.auth.isLoggedIn;
  const ikarAdmin = state.auth.ikarAdmin;
  const agencyAdmin = state.auth.agencyAdmin;
  const loginFailed = state.auth.loginFailed;
  const loginFailedMessage = state.auth.loginFailedMessage;
  const resType = state.auth.resType;
  return {
    loginRes,
    isLoggedIn,
    ikarAdmin,
    agencyAdmin,
    loginFailed,
    loginFailedMessage,
    resType,
  };
}
//)
export default connect(mapStateToProps, { loginProcess })(Login);
