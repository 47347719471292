import decode from 'jwt-decode';
import {
  LOGIN_PROCESS,
  LOGOUT_PROCESS,
  LOGIN_CHECKING,
  TAKE_AGENCY_ROLE,
  LEAVE_AGENCY_ROLE,
  LEAVE_AGENCY_ROLE2,
} from '../actions/types';

const initialState = {
  loginRes: {},
  isLoggedIn: false,
  ikarAdmin: false,
  ikarEmail: '',
  ikarId: '',
  ikarName: '',
  isBlocked: false,
  agencyRefId: '',
  agencyAdmin: false,
  loginFailed: false,
  loginFailedMessage: '',
  requestFailedMessage: {},
  isTakingAgencyRole: false,
  takenAgencyRoleEmail: '',
  takenAgencyRoleId: '',
  takenAgencyRoleName: '',
  takenAgencyRoleToken: '',
  takenAgencyRoleInfo: '',
  takenAgencyRoleAgencyRefId: '',
  resType: 'ready',
};
function isTokenExpired(inputToken) {
  try {
    const decoded = decode(inputToken);
    if (decoded.exp < Date.now() / 1000) {
      // Checking if token is expired.
      return true;
    } else return false;
  } catch (err) {
    return false;
  }
}
export default function (state = initialState, action) {
  switch (action.type) {
    case TAKE_AGENCY_ROLE:
      if (
        Object.keys(action.payload).length > 0 &&
        (action.payload.errorMessage === '' ||
          action.payload.errorMessage === undefined)
      ) {
        // var eMessage = {};
        // if (!action.payload.confirmed) {
        //   eMessage.data = {};
        //   eMessage.data.error = 'not confirmed yet';
        // }
        if (
          (action.payload.ikar_admin || action.payload.agency_admin) &&
          action.payload.confirmed
        ) {
          localStorage.setItem('ikar_is_taking_agency_role', 'true');
          localStorage.setItem(
            'ikar_taken_agency_role_email',
            action.payload.email
          );
          localStorage.setItem('ikar_taken_agency_role_id', action.payload.id);
          localStorage.setItem(
            'ikar_taken_agency_role_name',
            action.payload.name
          );
          localStorage.setItem(
            'ikar_taken_agency_role_token',
            action.payload.token
          );
          localStorage.setItem('ikar_taken_agency_role_info', action.payload);
          localStorage.setItem(
            'ikar_taken_agency_role_agency_refId',
            action.payload.agency_ref_id
          );
          localStorage.setItem('first_login', 'true');
          return {
            ...state,
            isTakingAgencyRole: true,
            takenAgencyRoleEmail: action.payload.email,
            takenAgencyRoleId: action.payload.id,
            takenAgencyRoleName: action.payload.name,
            takenAgencyRoleToken: action.payload.token,
            // takenAgencyRoleToken: '',
            takenAgencyRoleInfo: action.payload,
            takenAgencyRoleAgencyRefId: action.payload.agency_ref_id,
          };
        } else {
          return {
            ...state,
            requestFailedMessage: action.payload,
            isTakingAgencyRole: false,
            takenAgencyRoleEmail: '',
            takenAgencyRoleId: '',
            takenAgencyRoleName: '',
            takenAgencyRoleToken: '',
            takenAgencyRoleInfo: '',
            // loginFailedMessage: eMessage,
            takenAgencyRoleAgencyRefId: '',
          };
        }
      } else {
        return {
          ...state,
          requestFailedMessage: action.payload,
          isTakingAgencyRole: false,
          takenAgencyRoleEmail: '',
          takenAgencyRoleId: '',
          takenAgencyRoleName: '',
          takenAgencyRoleToken: '',
          takenAgencyRoleInfo: '',
          takenAgencyRoleAgencyRefId: '',
        };
      }

    case LEAVE_AGENCY_ROLE:
      localStorage.setItem('ikar_is_taking_agency_role', 'false');
      localStorage.setItem('ikar_taken_agency_role_email', '');
      localStorage.setItem('ikar_taken_agency_role_id', '');
      localStorage.setItem('ikar_taken_agency_role_name', '');
      localStorage.setItem('ikar_taken_agency_role_token', '');
      localStorage.setItem('ikar_taken_agency_role_info', '');
      localStorage.setItem('ikar_taken_agency_role_agency_refId', '');
      return {
        ...state,
        isTakingAgencyRole: false,
        takenAgencyRoleEmail: '',
        takenAgencyRoleId: '',
        takenAgencyRoleName: '',
        takenAgencyRoleToken: '',
        takenAgencyRoleInfo: '',
        takenAgencyRoleAgencyRefId: '',
      };
    // if (
    //   Object.keys(action.payload).length > 0 &&
    //   (action.payload.errorMessage === '' ||
    //     action.payload.errorMessage === undefined) &&
    //   action.payload.message === 'succeeded'
    // ) {
    //   localStorage.setItem('ikar_is_taking_agency_role', 'false');
    //   localStorage.setItem('ikar_taken_agency_role_email', '');
    //   localStorage.setItem('ikar_taken_agency_role_id', '');
    //   localStorage.setItem('ikar_taken_agency_role_name', '');
    //   // localStorage.setItem('ikar_taken_agency_role_token', '');
    //   localStorage.setItem('ikar_taken_agency_role_info', '');
    //   localStorage.setItem('ikar_taken_agency_role_agency_refId', '');
    //   return {
    //     ...state,
    //     isTakingAgencyRole: false,
    //     takenAgencyRoleEmail: '',
    //     takenAgencyRoleId: '',
    //     takenAgencyRoleName: '',
    //     takenAgencyRoleToken: '',
    //     takenAgencyRoleInfo: '',
    //     takenAgencyRoleAgencyRefId: ''
    //   };
    // } else {
    //   alert('leave_agency_role e');
    //   return {
    //     ...state
    //   };
    // }

    case LEAVE_AGENCY_ROLE2: {
      localStorage.setItem('ikar_is_taking_agency_role', 'false');
      localStorage.setItem('ikar_taken_agency_role_email', '');
      localStorage.setItem('ikar_taken_agency_role_id', '');
      localStorage.setItem('ikar_taken_agency_role_name', '');
      // localStorage.setItem('ikar_taken_agency_role_token', '');
      localStorage.setItem('ikar_taken_agency_role_info', '');
      localStorage.setItem('ikar_taken_agency_role_agency_refId', '');
      return {
        ...state,
        isTakingAgencyRole: false,
        takenAgencyRoleEmail: '',
        takenAgencyRoleId: '',
        takenAgencyRoleName: '',
        takenAgencyRoleToken: '',
        takenAgencyRoleInfo: '',
        takenAgencyRoleAgencyRefId: '',
      };
    }

    case LOGIN_PROCESS:
      if (
        Object.keys(action.payload).length > 0 &&
        (action.payload.errorMessage === '' ||
          action.payload.errorMessage === undefined)
      ) {
        var eMessage = {};
        if (!action.payload.confirmed) {
          eMessage.data = {};
          eMessage.data.error = 'not confirmed yet';
        }
        if (
          (action.payload.ikar_admin || action.payload.agency_admin) &&
          action.payload.confirmed
        ) {
          localStorage.setItem('first_login', 'true');
          localStorage.setItem('ikar_token', action.payload.token);
          //localStorage.setItem('ikar_email', action.payload.reference_id);
          localStorage.setItem('ikar_email', action.payload.email);
          localStorage.setItem('ikar_id', action.payload.id);
          localStorage.setItem('ikar_name', action.payload.name);
          localStorage.setItem(
            'ikar_agency_ref_id',
            action.payload.agency_ref_id
          );
          localStorage.setItem('ikar_is_b', action.payload.blocked);
          localStorage.setItem('ikar_a', action.payload.ikar_admin);
          localStorage.setItem('ikar_a_a', action.payload.agency_admin);
          return {
            ...state,
            loginRes: action.payload,
            isLoggedIn: true,
            isBlocked: action.payload.blocked,
            ikarAdmin: action.payload.ikar_admin,
            agencyAdmin: action.payload.agency_admin,
            ikarEmail: action.payload.email,
            ikarId: action.payload.id,
            ikarName: action.payload.name,
            agencyRefId: action.payload.agency_ref_id,
            resType: action.payload.resType,

            takenAgencyRoleToken: '',
            takenAgencyRoleAgencyRefId: '',
          };
        } else {
          return {
            ...state,
            loginRes: action.payload,
            isLoggedIn: false,
            ikarAdmin: false,
            agencyAdmin: false,
            isBlocked: false,
            loginFailed: true,
            loginFailedMessage: eMessage,
            resType: action.payload.resType,
            takenAgencyRoleToken: '',
            takenAgencyRoleAgencyRefId: '',
          };
        }
      } else {
        return {
          ...state,
          loginRes: action.payload,
          isLoggedIn: false,
          ikarAdmin: false,
          agencyAdmin: false,
          isBlocked: false,
          loginFailed: true,
          loginFailedMessage: action.payload.errorMessage,
          resType: action.payload.resType,
          takenAgencyRoleToken: '',
          takenAgencyRoleAgencyRefId: '',
        };
      }

    case LOGIN_CHECKING:
      // if (
      //   Object.keys(action.payload).length > 0
      //   // &&
      //   // action.payload.token !== undefined &&
      //   // action.payload.token !== null &&
      //   // !isTokenExpired(action.payload.token)
      // ) {
      if (
        Object.keys(action.payload).length > 0 &&
        action.payload.token !== undefined &&
        action.payload.token !== null &&
        !isTokenExpired(action.payload.token)
      ) {
        if (action.payload.ikar_admin || action.payload.agency_admin) {
          return {
            loginRes: action.payload,
            isLoggedIn: true,
            ikarAdmin: action.payload.ikar_admin,
            agencyAdmin: action.payload.agency_admin,
            ikarEmail: action.payload.email,
            ikarId: action.payload.id,
            ikarName: action.payload.name,
            agencyRefId: action.payload.agency_ref_id,
            isBlocked: action.payload.blocked,
            loginFailed: false,
            loginFailedMessage: '',
            requestFailedMessage: {},
            isTakingAgencyRole: action.payload.is_taking_agency_role,
            takenAgencyRoleEmail: action.payload.taken_agency_role_email,
            takenAgencyRoleId: action.payload.taken_agency_role_id,
            takenAgencyRoleName: action.payload.taken_agency_role_name,
            takenAgencyRoleToken: action.payload.taken_agency_role_token,
            // takenAgencyRoleToken: '',
            takenAgencyRoleInfo: action.payload.taken_agency_role_info,
            takenAgencyRoleAgencyRefId:
              action.payload.taken_agency_role_agency_refId,
            resType: action.payload.resType,
            // isTakingAgencyRole: false,
            // takenAgencyRoleEmail: "",
            // takenAgencyRoleToken: "",
            // takenAgencyRoleInfo: "",
            // takenAgencyRoleAgencyRefId: ""
          };
        } else {
          return {
            loginRes: action.payload,
            isLoggedIn: false,
            ikarAdmin: false,
            agencyAdmin: false,
            ikarEmail: '',
            ikarId: '',
            ikarName: '',
            agencyRefId: '',
            loginFailed: false,
            isBlocked: false,
            loginFailedMessage: '',
            requestFailedMessage: {},
            isTakingAgencyRole: false,
            takenAgencyRoleEmail: '',
            takenAgencyRoleId: '',
            takenAgencyRoleName: '',
            takenAgencyRoleToken: '',
            takenAgencyRoleInfo: '',
            takenAgencyRoleAgencyRefId: '',
            resType: action.payload.resType,
          };
        }
      } else {
        return {
          loginRes: action.payload,
          isLoggedIn: false,
          ikarAdmin: false,
          agencyAdmin: false,
          ikarEmail: '',
          ikarId: '',
          ikarName: '',
          agencyRefId: '',
          loginFailed: false,
          isBlocked: false,
          loginFailedMessage: '',
          requestFailedMessage: {},
          isTakingAgencyRole: false,
          takenAgencyRoleEmail: '',
          takenAgencyRoleId: '',
          takenAgencyRoleName: '',
          takenAgencyRoleToken: '',
          takenAgencyRoleInfo: '',
          takenAgencyRoleAgencyRefId: '',
          resType: action.payload.resType,
        };
      }
    // case LOGOUT_PROCESS:
    //   if (
    //     Object.keys(action.payload).length > 0 &&
    //     (action.payload.errorMessage === '' ||
    //       action.payload.errorMessage === undefined) &&
    //     action.payload.message === 'logged out successfully'
    //   ) {
    //     return {
    //       loginRes: {},
    //       isLoggedIn: false,
    //       ikarAdmin: false,
    //       ikarEmail: '',
    //       ikarId: '',
    //       ikarName: '',
    //       agencyRefId: '',
    //       agencyAdmin: false,
    //       loginFailed: false,
    //       isBlocked: false,
    //       loginFailedMessage: '',
    //       requestFailedMessage: {},
    //       isTakingAgencyRole: false,
    //       takenAgencyRoleEmail: '',
    //       takenAgencyRoleId: '',
    //       takenAgencyRoleName: '',
    //       takenAgencyRoleToken: '',
    //       takenAgencyRoleInfo: '',
    //       takenAgencyRoleAgencyRefId: '',
    //     };
    //   } else {
    //     alert('log out e');
    //     return { ...state };
    //   }

    case LOGOUT_PROCESS:
      return {
        loginRes: {},
        isLoggedIn: false,
        ikarAdmin: false,
        ikarEmail: '',
        ikarId: '',
        ikarName: '',
        agencyRefId: '',
        agencyAdmin: false,
        loginFailed: false,
        isBlocked: false,
        loginFailedMessage: '',
        requestFailedMessage: {},
        isTakingAgencyRole: false,
        takenAgencyRoleEmail: '',
        takenAgencyRoleId: '',
        takenAgencyRoleName: '',
        takenAgencyRoleToken: '',
        takenAgencyRoleInfo: '',
        takenAgencyRoleAgencyRefId: '',
      };

    default:
      return state;
  }
}
