import {
  // GET_SETTING,
  SET_PATH
} from '../actions/types';

const initialState = {
  dashboardPath: { page: '', id: '' }
};

export default function(state = initialState, action) {
  switch (action.type) {
    // case GET_PROPERTIES:
    //   return {
    //     ...state
    //     ///  , properties: action.payload
    //   };
    case SET_PATH:
      return {
        dashboardPath: action.payload
      };

    default:
      return state;
  }
}
