import {
  ADD_CONTACT_MESSAGE,
  GET_CONTACT_MESSAGES,
  DELETE_CONTACT_MESSAGES,
  GET_READ_MESSAGES_AGENCY,
} from '../actions/types';
import shortid from 'shortid';

const initialState = {
  contact_messages: [],
  contact_message: {},
  requestFailed: false,
  requestFailedMessage: {},
  requestSuccessMessage: '',
  deleteSuccessMessage: '',
  isReadNewMessage:false,
};
export default function(state = initialState, action) {
  switch (action.type) {

    case GET_READ_MESSAGES_AGENCY:
      if (
        Object.keys(action.payload).length > 0 &&
        action.payload.error === undefined &&
        (action.payload.errorMessage === '' ||
          action.payload.errorMessage === undefined)
      ) {
        return {
          isReadNewMessage:action.payload.read,
          contact_message: {},
          requestFailed: false,
          requestFailedMessage: { error: 'no' },
          contact_messages: [],
          requestSuccessMessage: 'get is succeeded',
          deleteSuccessMessage: ''
        };
      } else {
        return {
          isReadNewMessage:'',
          contact_message: {},
          contact_messages: [],
          requestFailed: true,
          requestFailedMessage: action.payload,
          requestSuccessMessage: '',
          deleteSuccessMessage: ''
        };
      }
     


    case ADD_CONTACT_MESSAGE:
      if (
        Object.keys(action.payload).length > 0 &&
        action.payload.error === undefined &&
        (action.payload.errorMessage === '' ||
          action.payload.errorMessage === undefined)
      ) {
       
        return {
          contact_message: action.payload,
          requestFailed: false,
          requestFailedMessage: { error: 'no' },
          contact_messages: [],
          requestSuccessMessage: 'add is succeeded',
          deleteSuccessMessage: ''
        };
      } else {
        return {
          contact_message: {},
          contact_messages: [],
          requestFailed: true,
          requestFailedMessage: action.payload,
          requestSuccessMessage: '',
          deleteSuccessMessage: ''
        };
      }

    case GET_CONTACT_MESSAGES:
      if (
        Object.keys(action.payload).length > 0 &&
        (action.payload.errorMessage === '' ||
          action.payload.errorMessage === undefined)
      ) {
        return {
          contact_messages: action.payload,
          contact_message: {},
          requestFailed: false,
          requestFailedMessage: {},
          requestSuccessMessage: 'get is succeeded',
          deleteSuccessMessage: ''
        };
      } else {
        return {
          contact_messages: [],
          contact_message: {},
          requestFailed: true,
          requestFailedMessage: action.payload,
          requestSuccessMessage: '',
          deleteSuccessMessage: ''
        };
      }

    case DELETE_CONTACT_MESSAGES:
      if (
        Object.keys(action.payload).length > 0 &&
        (action.payload.errorMessage === '' ||
          action.payload.errorMessage === undefined)
      ) {
        return {
          contact_messages: [],
          contact_message: {},
          requestFailed: false,
          requestFailedMessage: {},
          deleteSuccessMessage: action.payload.message,
          short_id: shortid.generate()
        };
      } else {
        return {
          contact_messages: [],
          contact_message: {},
          requestFailed: true,
          requestFailedMessage: action.payload,
          deleteSuccessMessage: ''
        };
      }
    default:
      return state;
  }
}
