import React, { Component } from 'react';
import Property from './../../properties/Property';
import Province from './../../provinces/Province';
import { connect } from 'react-redux';
import { linkDomain } from './../../../mainConf';
import shortid from 'shortid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { recordsPerPage } from './../../../mainConf/index';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { ResReady, ResNotComplete } from './../../layout/resTypes';
class HContainer extends Component {
  constructor(props) {
    super(props);
    this.horizontalContainerRef = React.createRef();
    this.state = {
      largeScreen: false,
      rows: 1,
      cardsByRow: 8,
      propertiesData: [],
      propertiesDataObject: {},
      title1: '',
      title2: '',
      link: '',
      currentProvince: 'دمشق',
      resType: '',
      locationSearch: '',
    };
  }
  resize() {
    let currentWidth = window.innerWidth;
    if (currentWidth >= 992) {
      this.setState({ largeScreen: true });
    } else {
      this.setState({ largeScreen: false });
    }
  }
  componentDidMount() {
    window.addEventListener('resize', this.resize.bind(this));
  }
  async componentWillReceiveProps(nextProps, nextState) {
    var search = [];
    if (nextProps.main_type !== '' || nextProps.business_offer !== '') {
      search = [];
      if (nextProps.main_type !== '') {
        search.push(`main_type=${nextProps.main_type}`);
      }

      if (nextProps.business_offer !== '') {
        search.push(`business_offer=${nextProps.business_offer}`);
      }
      await this.setState({ locationSearch: search.join('&') });
    } else if (nextProps.main_type === '' || nextProps.business_offer === '') {
      search = [];
      await this.setState({ locationSearch: search.join('&') });
    }
    if (
      nextProps.propertiesData !== undefined &&
      nextProps.propertiesData !== this.state.propertiesData
    ) {
      this.resize();
      if (nextProps.title1 === 'حسب المحافظة') {
        const {
          rows,
          cardsByRow,
          propertiesData,
          title1,
          title2,
          link,
          currentProvince,
        } = nextProps;
        this.setState({
          rows,
          cardsByRow,
          propertiesData: Object.entries(propertiesData),
          title1,
          title2,
          link,
          currentProvince,
          resType: 'complete',
        });
        this.activateSideBarItems();
      } else {
        const {
          rows,
          cardsByRow,
          propertiesData,
          title1,
          title2,
          link,
          currentProvince,
        } = nextProps;
        this.setState({
          rows,
          cardsByRow,
          propertiesData,
          title1,
          title2,
          link,
          currentProvince,
          resType: 'complete',
        });
        this.activateSideBarItems();
      }
    }
    if (
      nextProps.resType !== undefined &&
      nextProps.resType !== '' &&
      nextProps.resType !== this.state.resType
    ) {
      const { resType } = nextProps;
      this.setState({
        resType,
      });
    }
  }
  onButtonClick = (province, e) => {
    e.preventDefault();
    this.props.onSearch(recordsPerPage, 1, province);
  };
  activateSideBarItems = () => {
    let provinceBtns = document.querySelectorAll('.btn-province');
    provinceBtns.forEach(function (btn) {
      btn.addEventListener('click', function () {
        // Set clicked image as display image.
        //let newImageSrc = btn.dataset.largeVersion;
        //mainImage.setAttribute('src', newImageSrc);
        // Change which image is current.
        if (document.querySelector('.btn-province-active') !== null) {
          document
            .querySelector('.btn-province-active')
            .classList.remove('btn-province-active');
        }
        btn.classList.add('btn-province-active');
      });
    });
  };
  rightButtonClick = () => {
    //this.horizontalContainerRef.current.scrollLeft += 500;

    // this.mainContainerRef.current.scrollIntoView({ behavior: 'smooth' });
    if (this.state.largeScreen) {
      this.horizontalContainerRef.current.scrollBy({
        behavior: 'smooth',
        left: 500,
        top: 0,
      });
    } else {
      this.horizontalContainerRef.current.scrollBy({
        behavior: 'smooth',
        left: 250,
        top: 0,
      });
    }
  };
  leftButtonClick = () => {
    //this.horizontalContainerRef.current.scrollLeft -= 500;
    if (this.state.largeScreen) {
      this.horizontalContainerRef.current.scrollBy({
        behavior: 'smooth',
        left: -500,
        top: 0,
      });
    } else {
      this.horizontalContainerRef.current.scrollBy({
        behavior: 'smooth',
        left: -250,
        top: 0,
      });
    }
  };

  render() {
    const {
      // rows,
      // cardsByRow,
      propertiesData,
      title1,
      // title2,
      link,
      // currentProvince,
      resType,
    } = this.state;
    // const provinces = [
    //   'دمشق',
    //   'ريف دمشق',
    //   'حلب',
    //   'طرطوس',
    //   'اللاذقية',
    //   'حمص',
    //   'حماة',
    //   'السويداء',
    //   'الحسكة',
    //   'درعا',
    //   'ادلب',
    //   'دير الزور',
    //   'القنيطرة',
    //   'الرقة'
    // ];
    return (
      <div className="horizontal-container-outer mx-auto">
        <button
          className="horizontal-container-right-button"
          onClick={this.rightButtonClick}
        >
          <FontAwesomeIcon
            icon={['fas', 'angle-double-right']}
            size="2x"
            color="#fcb814"
          ></FontAwesomeIcon>
        </button>
        <button
          className="horizontal-container-left-button"
          onClick={this.leftButtonClick}
        >
          <FontAwesomeIcon
            icon={['fas', 'angle-double-left']}
            size="2x"
            color="#fcb814"
          ></FontAwesomeIcon>
        </button>
        <div className="row px-2 mx-2">
          <div className={classnames('col-6', 'text-right', 'h-c-title')}>
            {title1 !== 'حسب المحافظة' ? (
              <a
                href={`${linkDomain}${link}${
                  this.state.locationSearch !== ''
                    ? `?${this.state.locationSearch}`
                    : ``
                }`}
              >
                {/* <Link
                to={{
                  pathname: `${linkDomain}${link}`,
                  // query: { search_mode: 'min' }
                  search: this.state.locationSearch,
                }}
              > */}
                {this.state.largeScreen ? (
                  <span className="display_gold display_2_bold">{title1}</span>
                ) : (
                  <span className="display_gold">{title1}</span>
                )}
                {/* </Link> */}
              </a>
            ) : (
              <div>
                {this.state.largeScreen ? (
                  <span
                    className={classnames('display_gold', 'display_2_bold')}
                  >
                    {title1}
                  </span>
                ) : (
                  <span className={classnames('display_gold')}>{title1}</span>
                )}
              </div>
            )}
          </div>
          {title1 !== 'حسب المحافظة' ? (
            <div className="col-6 text-left h-c-title">
              {/* <Link to={link} onClick={this.saveSearchQuery}>
                <span
                // className={classnames({
                //   display_gold: title1 === 'عروض مغرية'
                // })}
                >
                  المزيد
                </span>
                {` `}
                <FontAwesomeIcon
                  icon={['fas', 'angle-double-left']}
                  // className={classnames({
                  //   display_gold: title1 === 'عروض مغرية'
                  // })}
                ></FontAwesomeIcon>
              </Link> */}
              <a
                href={`${linkDomain}${link}${
                  this.state.locationSearch !== ''
                    ? `?${this.state.locationSearch}`
                    : ``
                }`}
              >
                {/* <Link
                to={{
                  pathname: `${linkDomain}${link}`,
                  // query: { search_mode: 'min' }
                  search: this.state.locationSearch,
                }}
              > */}
                {this.state.largeScreen ? (
                  <span className="display_gold display_2_bold">المزيد</span>
                ) : (
                  <span className="display_gold">المزيد</span>
                )}
                {` `}
                <FontAwesomeIcon
                  icon={['fas', 'angle-double-left']}
                  className="display_gold"
                  // className={classnames({
                  //   display_gold: title1 === 'عروض مغرية'
                  // })}
                ></FontAwesomeIcon>
                {/* </Link> */}
              </a>
            </div>
          ) : null}
        </div>

        <React.Fragment>
          {/* {title1 === 'حسب المحافظة' ? (
            <div className="text-right mx-2 mb-2">
              {(provinces || []).map(province => (
                <button
                  key={shortid.generate()}
                  className={classnames(
                    'btn',
                    'btn-outline-warning',
                    'mr-1',
                    'px-1',
                    'my-1',
                    'btn-province',
                    { 'btn-province-active': province === currentProvince }
                  )}
                  //className="       "
                  onClick={this.onButtonClick.bind(this, province)}
                >
                  <span style={{ color: '#581f18' }}>{province}</span>
                </button>
              ))}
            </div>
          ) : null} */}
          {/* <span className="text-danger">
                الرجاء التحقق من جودة الاتصال بالانترنيت
              </span> */}
          <div
            ref={this.horizontalContainerRef}
            className={classnames('horizontal-container', {
              'horizontal-container-all':
                title1 !== 'عروض مغرية' || resType !== 'complete',
            })}
            // style={{
            //   background: `url(${best_offer_background})bottom no-repeat`
            // }}
          >
            {resType === 'complete' ? (
              propertiesData === undefined ||
              Object.keys(propertiesData).length === 0 ? (
                <div className="text-center mx-auto">
                  <div className="span text-center">
                    <p>
                      <span className="text-warning">لا يوجد عقارات حاليا</span>
                    </p>
                  </div>
                </div>
              ) : title1 === 'حسب المحافظة' ? (
                propertiesData !== [] &&
                propertiesData !== undefined &&
                (propertiesData || []).map((province) =>
                  // (provinces || []).map(province => (
                  province[1] > 0 ? (
                    <div className="card-item" key={shortid.generate()}>
                      <Province
                        currentProvince={province[0]}
                        propertiesNumber={province[1]}
                        lSearch={this.state.locationSearch}
                        main_type={this.props.main_type}
                        business_offer={this.props.business_offer}
                        //isLoggedIn={false}
                        //ikarAdmin={false}
                        // requestSuccessMessage={this.state.requestSuccessMessage}
                        // onlyOne={true}
                      />
                    </div>
                  ) : null
                )
              ) : (
                propertiesData !== [] &&
                propertiesData !== undefined &&
                (propertiesData || []).map((property) => (
                  <div className="card-item" key={shortid.generate()}>
                    <Property
                      property={property}
                      isLoggedIn={false}
                      ikarAdmin={false}
                      requestSuccessMessage={this.state.requestSuccessMessage}
                      onlyOne={true}
                    />
                  </div>
                ))
              )
            ) : resType === 'not complete' ? (
              <ResNotComplete></ResNotComplete>
            ) : resType === 'ready' ? (
              <ResReady></ResReady>
            ) : (
              <div>
                <br />
                <br />
                <br />
                <br />
              </div>
            )}
          </div>
        </React.Fragment>
      </div>
    );
  }
}
HContainer.propTypes = {
  // business_offer_param: PropTypes.string.isRequired,
  // main_type_param: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  business_offer_param: state.main.business_offer_param,
  main_type_param: state.main.main_type_param,
});
export default connect(mapStateToProps, {})(HContainer);
