import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { linkDomain } from './../mainConf';
class NotFound extends Component {
  constructor(props) {
    super();
    this.state = {
      pageTitle: 'Page not found',
      pageDescription: '',
    };
  }
  render() {
    return (
      <div>
        <Helmet>
          <title>{this.state.pageTitle}</title>
          <meta name="description" content={this.state.pageDescription} />
          <meta name="robots" content="noindex" />
        </Helmet>
        <div className="row">
          <div className="col-10 mx-auto">
            <div className="display_4 text-center my-2">
              <span className="text-danger font-weight-bold">404 </span>
              Page not found
            </div>
            <div className="text-right">
              <p className="display_6 display_gold font-weight-normal">
                عفواً, لكن هذه الصفحة غير موجودة
              </p>
              <ul style={{ direction: 'rtl' }}>
                <li className="display_gold">تأكد من رابط الصفحة</li>
                <li className="display_gold">
                  ربما تم حذف العرض من قبل الوسيط العقاري
                </li>
              </ul>
            </div>
            <div className="text-center  mt-2">
              <a href={`${linkDomain}/search`} className="btn btn-warning">
                البحث عن عقار مشابه
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default NotFound;
