import { ADD_USER, GET_USER } from './types';
import axios from 'axios';
import { mainDomain } from './../mainConf';

export const addUser = (user) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };

  await axios
    .post(
      `${mainDomain}/v1/admin/user`,
      user,
      // ,
      // {
      //   withCredentials: true
      // }
      {
        headers: {
          //'Content-Type': 'application/json',
          Accept: 'application/json',
          //Accept: 'application/json',
          Authorization: localStorage.getItem('ikar_token'),
        },
      }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
      } else res = {};
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
    });

  dispatch({
    type: ADD_USER,
    payload: res,
  });
};

export const getUser = (
  ikarAdmin,
  isTakingAgencyRole,
  id,
  takenAgencyRoleToken
) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  var ref_id = id;
  var token = '';
  if (isTakingAgencyRole) {
    token = takenAgencyRoleToken;
  } else {
    token = localStorage.getItem('ikar_token');
  }
  await axios
    .get(
      `${mainDomain}/v1/admin/user?id=${ref_id}`,
      // ,
      // {
      //   withCredentials: true
      // }
      {
        headers: {
          //'Content-Type': 'application/json',
          Accept: 'application/json',
          //Accept: 'application/json',
          Authorization: token,
        },
      }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
      } else res = {};
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
    });
  dispatch({
    type: GET_USER,
    payload: res,
  });
};
