import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
// import { Link } from 'react-router-dom';
import ShowPropertiesByAgency from './../properties/ShowPropertiesByAgency';
import ShowPropertiesByUser from './../properties/ShowPropertiesByUser';
import AddProperty from './../properties/AddProperty';
import EditProperty from './../properties/EditProperty';
import EditUser from './../users/EditUser';
import EditAgency from './../agencies/EditAgency';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Growl } from 'primereact/growl';
import ShowPropertiesWithContactsByAgency from './../properties/ShowPropertiesWithContactsByAgency';
import { Helmet } from 'react-helmet';
import About from './../../pages/About';
import Privacy from './../../pages/Privacy';
class DashBoard extends Component {
  constructor() {
    super();
    document.title = 'Ikar.sy';
  }
  state = {
    pageTitle: 'صفحة مدير مكتب | Ikar.sy',
    pageDescription: '',
    linkTo: 'default',
    locationSearch: '',
  };
  activateSideBarItems = () => {
    let sidebarLinks = document.querySelectorAll('.dashboard-sidebar-link');
    sidebarLinks.forEach(function (link) {
      link.addEventListener('click', function () {
        // Set clicked image as display image.
        // let newImageSrc = link.dataset.largeVersion;
        //mainImage.setAttribute('src', newImageSrc);
        // Change which image is current.
        document
          .querySelector('.dashboard-active')
          .classList.remove('dashboard-active');
        link.parentNode.classList.add('dashboard-active');
      });
    });
  };
  async componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.location.search !== this.state.locationSearch) {
      await this.setState({ locationSearch: nextProps.location.search });
    }
    // if (nextProps.match.params.to !== this.state.LinkTo) {
    //   this.setState({ linkTo: nextProps.match.params.to });
    // }
  }
  componentDidMount() {
    if (this.props.location.search !== this.state.locationSearch) {
      this.setState({ locationSearch: this.props.location.search });
    }
    const { to } = this.props.match.params;

    if (this.props.isBlocked) {
      this.growl.show({
        severity: 'warn',
        summary: 'لا تمتلك صلاحية تنفيذ الاجراء المطلوب',
        detail: 'الرجاء التواصل مع ادارة الموقع لمزيد من المعلومات',
      });
    } else {
      this.setState({ linkTo: to });
      this.activateSideBarItems();
    }
  }
  handleLinkTo = (linkTo, e) => {
    if (this.props.isBlocked) {
      this.growl.show({
        severity: 'warn',
        summary: 'لا تمتلك صلاحية تنفيذ الاجراء المطلوب',
        detail: 'الرجاء التواصل مع ادارة الموقع لمزيد من المعلومات',
      });
    } else {
      this.setState({ linkTo });
    }
  };
  render() {
    const { linkTo } = this.state;
    const {
      // isTakingAgencyRole, takenAgencyRoleEmail,
      isBlocked,
    } = this.props;
    return (
      <div>
        <Helmet>
          <title>{this.state.pageTitle}</title>
          <meta name="description" content={this.state.pageDescription} />
          <meta name="robots" content="noindex" />
        </Helmet>
        <div className="container-fluid">
          <Growl ref={(el) => (this.growl = el)} />
          <div className="row">
            <div className="col-12 dashboard-sidebar">
              <nav className="navbar navbar-expand-lg navbar-light ">
                <div className="bottom-border text-center text-warning ">
                  <FontAwesomeIcon
                    icon={['fas', 'user-cog']}
                    size="2x"
                    color="#F8C012"
                  />
                  <br />
                  مستخدم نظام
                </div>
                <ul className="navbar-nav ml-auto my-auto">
                <li className="nav-item mx-1">
                    <a
                      href="/dashBoard/show_about_by_admin"
                      //className="nav-link text-white p-1 mx-1 dashboard-sidebar-link"
                      className={classnames(
                        'nav-link',
                        'text-white',
                        'p-1',
                        'mx-1',
                        'dashboard-sidebar-link',
                        { 'dashboard-active': linkTo === 'show_about_by_admin' }
                      )}
                    
                    >
                      <FontAwesomeIcon
                        icon={['fas', 'search']}
                        className="text-light mr-2"
                      />
                       {' '}
                  IKAR حول 
                    {' '}
                    </a>
                  </li>
           
                  <li className="nav-item mx-1" style={{ display: 'none' }}>
                    <a
                      href="#!"
                      className="nav-link text-white p-1 mx-1 dashboard-sidebar-link dashboard-active"
                      // onClick={this.handleLinkTo.bind(this, 'edit_user')}
                    >
                      <FontAwesomeIcon
                        icon={['fas', 'plus']}
                        className="text-light mr-2"
                      />
                      لوحة التحكم
                    </a>
                  </li>
                  <li className="nav-item mx-1">
                    {/* <Link
                      to="/dashBoard/properties_contacts" */}
                    <a
                      href="/dashBoard/properties_contacts"
                      //className="nav-link text-white p-1 mx-1 dashboard-sidebar-link"
                      className={classnames(
                        'nav-link',
                        'text-white',
                        'p-1',
                        'mx-1',
                        'dashboard-sidebar-link',
                        { 'dashboard-active': linkTo === 'properties_contacts' }
                      )}
                      // onClick={this.handleLinkTo.bind(
                      //   this,
                      //   'properties_contacts'
                      // )}
                    >
                      <FontAwesomeIcon
                        icon={['fas', 'search']}
                        className="text-light mr-2"
                      />
                      رسائل المكتب
                    </a>
                  </li>
                
                  {/* <li className="nav-item mx-1">
                    <Link
                      to="/dashBoard/edit_user"
                      //className="nav-link text-white p-1 mx-1 dashboard-sidebar-link"
                      className={classnames(
                        'nav-link',
                        'text-white',
                        'p-1',
                        'mx-1',
                        'dashboard-sidebar-link',
                        { 'dashboard-active': linkTo === 'edit_user' }
                      )}
                      //onClick={this.handleLinkTo.bind(this, 'edit_user')}
                    >
                      <FontAwesomeIcon
                        icon={['fas', 'edit']}
                        className="text-light mr-2"
                      />
                      تعديل المستخدم
                    </Link>
                  </li> */}
                  <li className="nav-item mx-1">
                    <a
                      href="/dashBoard/edit_agency"
                      //className="nav-link text-white p-1 mx-1 dashboard-sidebar-link"
                      className={classnames(
                        'nav-link',
                        'text-white',
                        'p-1',
                        'mx-1',
                        'dashboard-sidebar-link',
                        { 'dashboard-active': linkTo === 'edit_agency' }
                      )}
                      // onClick={this.handleLinkTo.bind(this, 'edit_agency')}
                    >
                      <FontAwesomeIcon
                        icon={['fas', 'edit']}
                        className="text-light mr-2"
                      />
                      تعديل المكتب
                    </a>
                  </li>
                  <li className="nav-item mx-1">
                    <a
                      href="/dashBoard/add_property"
                      //className="nav-link text-white p-1 mx-1 dashboard-sidebar-link"
                      className={classnames(
                        'nav-link',
                        'text-white',
                        'p-1',
                        'mx-1',
                        'dashboard-sidebar-link',
                        { 'dashboard-active': linkTo === 'add_property' }
                      )}
                      // onClick={this.handleLinkTo.bind(this, 'add_property')}
                    >
                      <FontAwesomeIcon
                        icon={['fas', 'plus-square']}
                        className="text-light mr-2"
                      />
                      اضافة عقار
                    </a>
                  </li>
                  <li className="nav-item mx-1">
                    <a
                      href="/dashBoard/properties_by_agency"
                      //className="nav-link text-white p-1 mx-1 dashboard-sidebar-link"
                      className={classnames(
                        'nav-link',
                        'text-white',
                        'p-1',
                        'mx-1',
                        'dashboard-sidebar-link',
                        {
                          'dashboard-active': linkTo === 'properties_by_agency',
                        }
                      )}
                      // onClick={this.handleLinkTo.bind(
                      //   this,
                      //   'properties_by_agency'
                      // )}
                    >
                      <FontAwesomeIcon
                        icon={['fas', 'city']}
                        className="text-light mr-2"
                      />
                      عقارات المكتب
                    </a>
                  </li>
                  {/* <li className="nav-item mx-1">
                    <Link
                      to="/properties_by_user"
                      className="nav-link text-white p-1 mx-1 dashboard-sidebar-link"
                      onClick={this.handleLinkTo.bind(
                        this,
                        'properties_review_by_user'
                      )}
                    >
                      <FontAwesomeIcon
                        icon={['fas', 'city']}
                        className="text-light mr-2"
                      />
                      عقارات المستخدم
                    </Link>
                  </li> */}  
                </ul>
              </nav>
            </div>
            <div className="col-12">
              {isBlocked !== true ? (
                <React.Fragment>
                  {/* {linkTo === undefined ? <AddProperty /> : null} */}
                  {linkTo === 'edit_property' ? (
                    <EditProperty
                      // property_id={this.props.dashboardPath.id}
                      locationSearch={this.state.locationSearch}
                      aIkar={true}
                      destination="dashBoard"
                    />
                  ) : null}
                  {linkTo === 'edit_user' ? <EditUser /> : null}
                  {linkTo === 'show_about_by_admin' ? <About /> : null}
                  {linkTo === 'show_privacy_by_admin' ? <Privacy /> : null}
                  {linkTo === 'edit_agency' ? <EditAgency /> : null}
                  {linkTo === 'add_property' ? <AddProperty /> : null}
                  {linkTo === 'properties_by_agency' ? (
                    <ShowPropertiesByAgency
                      locationSearch={this.state.locationSearch}
                      destination="dashBoard"
                      aIkar={false}
                      // isAdminTakingAgencyRole={isTakingAgencyRole}
                      // takenAgencyRoleEmail={takenAgencyRoleEmail}
                    />
                  ) : null}
                  {linkTo === 'properties_contacts' ? (
                    <ShowPropertiesWithContactsByAgency
                      locationSearch={this.state.locationSearch}
                    />
                  ) : null}
                  {linkTo === 'properties_by_user' ? (
                    <ShowPropertiesByUser
                    // isAdminTakingAgencyRole={isTakingAgencyRole}
                    // takenAgencyRoleEmail={takenAgencyRoleEmail}
                    />
                  ) : null}
                </React.Fragment>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
DashBoard.propTypes = {
  isTakingAgencyRole: PropTypes.bool.isRequired,
  // takenAgencyRoleEmail: PropTypes.string.isRequired,
  isBlocked: PropTypes.bool.isRequired,
};
const mapStateToProps = (state) => ({
  dashboardPath: state.setting.dashboardPath,
  isTakingAgencyRole: state.auth.isTakingAgencyRole,
  isBlocked: state.auth.isBlocked,
  takenAgencyRoleEmail: state.auth.takenAgencyRoleEmail,
});

export default connect(mapStateToProps)(DashBoard);
