import React, { useState, useEffect } from 'react';
import { linkDomain } from './../mainConf';
import { CategoryItem } from '../mainConf/categoryTemplate';

const CategoryCard = (props: Props) => {
  const { title, items } = props;
  return (
    <React.Fragment>
      <div className="card text-center">
        <div className="card-header display_8 display_gold p-1">{title}</div>
        <div className="card-body p-1">
          {items.map((item: CategoryItem, index: number) => {
            let displayedBusinessOffer ='';
            let businessOffer ='';
            if (item.business_offer === 'تأجير') {
              displayedBusinessOffer = 'للاجار';
            } else if (item.business_offer === 'بيع') {
              displayedBusinessOffer = 'للبيع';
            } else if (item.business_offer === 'مزادات') {
              displayedBusinessOffer = 'للمزادات';
            } else if (item.business_offer === 'رهن') {
              displayedBusinessOffer = 'للرهن';
            } else if (item.business_offer === 'استثمار') {
              displayedBusinessOffer = 'للاستثمار';
            }
            if (item.business_offer === 'غير محدد') {
              businessOffer = 'عروض';
            }else
            {
              businessOffer=item.business_offer;
            }
            if (item.province !== 'غير محدد') {
              if (item.property_type !== 'غير محدد') {
                return (
                  <p className="card-text display_8 m-0 pb-1" key={index}>
                    <a
                      href={`${linkDomain}/${item.province}/${item.property_type}/${businessOffer}`}
                    >
                      {`${item.property_type} ${displayedBusinessOffer} في ${item.province}`}
                    </a>
                  </p>
                );
              } else {
                return (
                  <p className="card-text display_8 m-0 pb-1" key={index}>
                    <a
                      href={`${linkDomain}/${item.province}/عقارات/${businessOffer}`}
                    >
                      {`عقارات ${displayedBusinessOffer} في ${item.province}`}
                    </a>
                  </p>
                );
              }
            } else {
              if (item.property_type !== 'غير محدد') {
                return (
                  <p className="card-text display_8 m-0 pb-1" key={index}>
                    <a
                      href={`${linkDomain}/${item.property_type}/${businessOffer}`}
                    >
                      {`${item.property_type} ${displayedBusinessOffer}`}
                    </a>
                  </p>
                );
              } else {
                return (
                  <p className="card-text display_8 m-0 pb-1" key={index}>
                    <a href={`${linkDomain}/عقارات/${businessOffer}`}>
                      {`عقارات ${displayedBusinessOffer}`}
                    </a>
                  </p>
                );
              }
            }
          })}
        </div>
        {/* <div className="card-footer text-muted">2 days ago</div> */}
      </div>
    </React.Fragment>
  );
};

type Props = {
  title: string;
  items: Array<CategoryItem>;
};

export default CategoryCard;
