import {
  FORGET_PASSWORD_PROCESS,
  RESET_PASSWORD,
  CHECK_CONFIRMATION
} from '../actions/types';

const initialState = {
  resMessage: '',
  resetPasswordMessage: '',
  checkConfirmationMessage: '',
  processResType: 'ready'
};

export default function(state = initialState, action) {
  switch (action.type) {
    case CHECK_CONFIRMATION:
      if (
        Object.keys(action.payload).length > 0 &&
        (action.payload.errorMessage === undefined ||
          action.payload.errorMessage === '')
      ) {
        return {
          checkConfirmationMessage: action.payload,
          processResType: action.payload.resType
        };
      } else {
        return {
          checkConfirmationMessage: action.payload,
          processResType: action.payload.resType
        };
      }
    case RESET_PASSWORD:
      if (
        Object.keys(action.payload).length > 0 &&
        (action.payload.errorMessage === undefined ||
          action.payload.errorMessage === '')
      ) {
        return {
          resetPasswordMessage: action.payload,
          processResType: action.payload.resType
        };
      } else {
        return {
          resetPasswordMessage: action.payload,
          processResType: action.payload.resType
        };
      }
    case FORGET_PASSWORD_PROCESS:
      if (
        Object.keys(action.payload).length > 0 &&
        (action.payload.errorMessage === undefined ||
          action.payload.errorMessage === '')
      ) {
        return {
          resMessage: action.payload,
          processResType: action.payload.resType
        };
      } else {
        return {
          resMessage: action.payload,
          processResType: action.payload.resType
        };
      }
    default:
      return state;
  }
}
