import {
  FORGET_PASSWORD_PROCESS,
  RESET_PASSWORD,
  CHECK_CONFIRMATION,
} from './types';
import axios from 'axios';
import { mainDomain } from './../mainConf/index';

export const checkConfirmation = (token) => async (dispatch) => {
  let res = {};
  let errorRes = { errorStatus: '', errorMessage: '' };
  await axios
    .get(
      `${mainDomain}/users/confirmation?confirmation_token=${token}`
      // , {
      //   withCredentials: true
      // }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response;
      } else res = {};
      res.resType = 'complete';
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = { errorMessage: error.request };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
      res.resType = 'not complete';
    });
  dispatch({ type: CHECK_CONFIRMATION, payload: res });
};
export const resetPassword = (passwordData) => async (dispatch) => {
  let res = {};
  let errorRes = { errorStatus: '', errorMessage: '' };

  await axios
    .post(
      `${mainDomain}/v1/users/pwd/reset`,
      passwordData
      // , {
      //   withCredentials: true
      // }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response;
      } else res = {};
      res.resType = 'complete';
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = { errorMessage: error.request };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
      res.resType = 'not complete';
    });
  dispatch({ type: RESET_PASSWORD, payload: res });
};

export const forgetPasswordProcess = (user) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  await axios
    .post(
      `${mainDomain}/v1/users/pwd/forgot`,
      user
      // , {
      //   withCredentials: true
      // }
    )
    .then(function (response) {
      if (
        response !== undefined &&
        response !== null &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
        res.resType = 'complete';
      } else res = {};
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
      res.resType = 'not complete';
    });
  dispatch({
    type: FORGET_PASSWORD_PROCESS,
    payload: res,
  });
};
