import { ADD_AGENCY, GET_AGENCY } from './types';
import axios from 'axios';
import { mainDomain } from './../mainConf';

export const addAgency = (agency) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  await axios
    .post(
      `${mainDomain}/v1/admin/agency`,
      agency,
      // ,
      // {
      //   withCredentials: true,
      // }
      {
        headers: {
          Accept: 'application/json',
          Authorization: localStorage.getItem('ikar_token'),
        },
      }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
        res.resType = 'complete';
      } else res = {};
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
      res.resType = 'not complete';
    });
  dispatch({
    type: ADD_AGENCY,
    payload: res,
  });
};

export const getAgency = (
  ikarAdmin,
  isTakingAgencyRole,
  agencyReferenceId,
  takenAgencyRoleToken
) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  var token = '';
  var agencyRefId = agencyReferenceId;
  if (isTakingAgencyRole) {
    token = takenAgencyRoleToken;
  } else {
    token = localStorage.getItem('ikar_token');
  }
  if (ikarAdmin) {
    await axios
      .get(
        `${mainDomain}/v1/admin/agency?reference_id=${agencyRefId}`,
        // ,
        // {
        //   withCredentials: true,
        // }
        {
          headers: {
            //'Content-Type': 'application/json',
            Accept: 'application/json',
            //Accept: 'application/json',
            Authorization: token,
          },
        }
      )
      .then(function (response) {
        if (
          response !== null &&
          response !== undefined &&
          Object.keys(response).length > 0
        ) {
          res = response.data;
          res.resType = 'complete';
        } else res = {};
      })
      .catch(function (error) {
        if (error.response) {
          errorRes = {
            errorStatus: error.response.status,
            errorMessage: error.response,
          };
        } else if (error.request) {
          errorRes = {
            errorMessage: error.request,
          };
        } else {
          errorRes = { errorMessage: error.message };
        }
        res = errorRes;
        res.resType = 'not complete';
      });
  } else {
    await axios
      .get(
        `${mainDomain}/v1/admin/agency?reference_id=${agencyRefId}`,
        // ,
        // {
        //   withCredentials: true,
        // }
        {
          headers: {
            //'Content-Type': 'application/json',
            Accept: 'application/json',
            //Accept: 'application/json',
            // Authorization: token,
          },
        }
      )
      .then(function (response) {
        if (
          response !== null &&
          response !== undefined &&
          Object.keys(response).length > 0
        ) {
          res = response.data;
          res.resType = 'complete';
        } else res = {};
      })
      .catch(function (error) {
        if (error.response) {
          errorRes = {
            errorStatus: error.response.status,
            errorMessage: error.response,
          };
        } else if (error.request) {
          errorRes = {
            errorMessage: error.request,
          };
        } else {
          errorRes = { errorMessage: error.message };
        }
        res = errorRes;
        res.resType = 'not complete';
      });
  }
  dispatch({
    type: GET_AGENCY,
    payload: res,
  });
};
