import { GET_CATEGORIES, GET_CATEGORIES2 } from "./types";
import axios from "axios";
import { mainDomain } from "./../mainConf";

// export const getProperties = () => async dispatch => {
//   ///const res=await axios.get('');
//   const res = {};
//   dispatch({
//     type: GET_PROPERTIES,
//     payload: res.data
//   });
// };

export const getCategories = (aggregationData) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: "",
    errorMessage: "",
  };
  if (aggregationData.aggs_on !== "none") {
    await axios
      .post(
        `${mainDomain}/v1/aggregations`,
        // aggregation_type
        {
          aggregation: aggregationData,
        }
        // , {
        //   withCredentials: true
        // }
      )
      .then(function (response) {
        if (
          response !== null &&
          response !== undefined &&
          Object.keys(response).length > 0
        ) {
          res = response.data;
          res.resType = "complete";
          res.requestData = aggregationData;
        } else res = {};
      })
      .catch(function (error) {
        if (error.response) {
          errorRes = {
            errorStatus: error.response.status,
            errorMessage: error.response,
          };
        } else if (error.request) {
          errorRes = {
            errorMessage: error.request,
          };
        } else {
          errorRes = { errorMessage: error.message };
        }
        res = errorRes;
        res.resType = "not complete";
        res.requestData = "";
      });
  } else {
    res = {};
    res.resType = "complete";
    res.requestData = aggregationData;
  }
  dispatch({
    type: GET_CATEGORIES,
    payload: res,
  });
};

export const getCategories2 = (aggregationData) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: "",
    errorMessage: "",
  };
  if (aggregationData.aggs_on !== "none") {
    await axios
      .post(
        `${mainDomain}/v1/aggregations`,
        // aggregation_type
        {
          aggregation: aggregationData,
        }
        // , {
        //   withCredentials: true
        // }
      )
      .then(function (response) {
        if (
          response !== null &&
          response !== undefined &&
          Object.keys(response).length > 0
        ) {
          res = response.data;
          res.resType = "complete";
          res.requestData = aggregationData;
        } else res = {};
      })
      .catch(function (error) {
        if (error.response) {
          errorRes = {
            errorStatus: error.response.status,
            errorMessage: error.response,
          };
        } else if (error.request) {
          errorRes = {
            errorMessage: error.request,
          };
        } else {
          errorRes = { errorMessage: error.message };
        }
        res = errorRes;
        res.resType = "not complete";
        res.requestData = "";
      });
  } else {
    res = {};
    res.resType = "complete";
    res.requestData = aggregationData;
  }
  dispatch({
    type: GET_CATEGORIES2,
    payload: res,
  });
};
