import {Get_Privacies , DELETE_PRIVACY , ADD_PRIVACY ,GET_PRIVACY} from './../actions/types';
import shortid from 'shortid';
const initialState = {
    privacys: {},
    privacy: {},
    requestFailed: false,
    requestFailedMessage: {},
    requestSuccessMessage: '',
    deleteSuccessMessage: '',
    requestMessage: '',
    resType: 'ready',
  };

  export default function (state = initialState, action) {
    
    switch (action.type) {

    case Get_Privacies:
       
        if (
          Object.keys(action.payload).length > 0 &&
          (action.payload.errorMessage === '' ||
            action.payload.errorMessage === undefined)
        ) {
         // action.payload.processStatus = 'get joinus';
          return {
          //...state,
          privacy: action.payload,
          //add state data
          privacys: action.payload,
          requestFailed: false,
          requestFailedMessage: {},
          requestSuccessMessage: '',
          resType: action.payload.resType,
          };
        } else {
          return {
          privacy: {},
          requestFailed: true,
          requestFailedMessage: action.payload,
          //add state data
          privacys: {},
          requestSuccessMessage: '',
          requestMessage: '',
          resType: action.payload.resType,
          //
          };


        }
        case DELETE_PRIVACY:
          if (
            Object.keys(action.payload).length > 0 &&
            (action.payload.errorMessage === '' ||
              action.payload.errorMessage === undefined)
          ) {
            return {
              privacys: {},
              privacy: {},
              requestFailed: false,
              requestFailedMessage: {},
              deleteSuccessMessage: action.payload.message,
              short_id: shortid.generate(),
            };
          } else {
            return {
              privacys: {},
              privacy: {},
              requestFailed: true,
              requestFailedMessage: action.payload,
              deleteSuccessMessage: '',
            };
          }


        case ADD_PRIVACY:
          if (
            Object.keys(action.payload).length > 0 &&
            (action.payload.errorMessage === '' ||
              action.payload.errorMessage === undefined)
          ) {
            if (action.payload.error === undefined) {
              return {
                //...state,
                privacy: action.payload,
                requestFailed: false,
                requestFailedMessage: { error: 'no' },
                //add state
                privacys: {},
                requestSuccessMessage: 'add is succeeded',
                resType: action.payload.resType,
                short_id: shortid.generate(),
              };
            } else {
              return {
                //...state,
                privacy: {},
                requestFailed: true,
                requestFailedMessage: action.payload,
                //add state
                privacys: {},
                //requestSuccessMessage: 'error in add question',
                resType: action.payload.resType,
                short_id: shortid.generate(),
              };
            }
          } else {
            return {
              //...state,
              privacy: {},
              requestFailed: true,
              requestFailedMessage: action.payload,
              //add state
              privacys: {},
              //requestSuccessMessage: 'error in add question',
              resType: action.payload.resType,
            };
          }

          case GET_PRIVACY:
            if (
              Object.keys(action.payload).length > 0 &&
              (action.payload.errorMessage === '' ||
                action.payload.errorMessage === undefined)
            ) {
              action.payload.processStatus = 'get privacy';
              return {
                //...state,
                privacy: action.payload,
                //add state data
                privacys: {},
                requestFailed: false,
                requestFailedMessage: {},
                requestMessage: 'get privacy ready',
                resType: action.payload.resType,
                //
              };
            } else {
              return {
                //...state,
                privacy: {},
                requestFailed: true,
                requestFailedMessage: action.payload,
                //add state data
                privacys: {},
                requestSuccessMessage: '',
                requestMessage: '',
                resType: action.payload.resType,
                //
              };
            }



            default:
              return state;

            }

        }