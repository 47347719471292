import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import jQuery from 'jquery';
// import { Link } from 'react-router-dom';
import AgencyRegistration from './../auth/agencyRegistration';
import UserRegistration from './../auth/userRegistration';
import Users from './../users/Users';
import Agencies from './../agencies/Agencies';
import Properties from './../properties/Properties';
import ShowPropertiesWithContacts from './../properties/ShowPropertiesWithContacts';
import ShowPropertiesByAgency from './../properties/ShowPropertiesByAgency';
import ShowFaqByAdmin from './../faqC/ShowFaqByAdmin';
import ShowJoinusByAdmin from './../joinUS/ShowJoinusByAdmin';
import About from './../../pages/About'
import Privacy from './../../pages/Privacy'
import EditProperty from './../properties/EditProperty';
// import ShowPropertiesWithBestOffer from './../properties/ShowPropertiesWithBestOffer';
import { connect } from 'react-redux';
import EditAgency from './../agencies/EditAgency';
import AddFAQ from './../faqC/AddFAQ';
import AddJoinus from './../joinUS/AddJoinus';
import AddAbout from './../about/AddAbout';
import EditAbout from './../about/EditAbout';
import AddPrivacy from './../privacy/AddPrivacy';
import EditPrivacy from './../privacy/EditPrivacy';
import EditFAQ from './../faqC/EditFAQ';
import EditJoinus from './../joinUS/EditJoinus';
import { Helmet } from 'react-helmet';
class AdminDashBoard extends Component {
  constructor() {
    // document.title = 'Ikar.sy';
    super();
    this.state = {
      pageTitle: 'صفحة مدير النظام | Ikar.sy',
      pageDescription: '',
      linkTo: '',
      locationSearch: '',
    };
  }
  handleLinkTo = (linkTo, e) => {
    //this.setState({ linkTo });
    //return <Redirect to="/adminDashBoard/properties_contacts" />;
    //this.props.history.push('/adminDashBoard/properties_contacts');
  };
  activateSideBarItems = () => {
    let sidebarLinks = document.querySelectorAll('.dashboard-sidebar-link');
    sidebarLinks.forEach(function (link) {
      link.addEventListener('click', function () {
        // Set clicked image as display image.
        // let newImageSrc = link.dataset.largeVersion;
        //mainImage.setAttribute('src', newImageSrc);
        // Change which image is current.
        document
          .querySelector('.dashboard-active')
          .classList.remove('dashboard-active');
        link.parentNode.classList.add('dashboard-active');
      });
    });
  };
  async componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.location.search !== this.state.locationSearch) {
      await this.setState({ locationSearch: nextProps.location.search });
    }
    // if (nextProps.match.params.to !== this.state.LinkTo) {
    //   this.setState({ linkTo: nextProps.match.params.to });
    // }
  }

  componentDidMount() {
    if (this.props.location.search !== this.state.locationSearch) {
      this.setState({ locationSearch: this.props.location.search });
    }
    const { to } = this.props.match.params;
    this.setState({ linkTo: to });
    this.activateSideBarItems();
    jQuery(function ($) {
      $(document).ready(function () {});
    });
  }
  render() {
    const { linkTo } = this.state;
    return (
      <div>
        <Helmet>
          <title>{this.state.pageTitle}</title>
          <meta name="description" content={this.state.pageDescription} />
          <meta name="robots" content="noindex" />
        </Helmet>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 dashboard-sidebar">
              <nav className="navbar navbar-expand-lg navbar-light ">
                <div className="bottom-border text-center text-warning ">
                  <FontAwesomeIcon
                    icon={['fas', 'user-cog']}
                    size="2x"
                    color="#F8C012"
                  />
                  <br />
                  مسؤول النظام
                </div>
                <ul className="navbar-nav ml-auto my-auto">
                  <li className="nav-item mx-1" style={{ display: 'none' }}>
                    <a
                      href="#!"
                      className="nav-link text-white  dashboard-sidebar-link dashboard-active"
                      //onClick={this.handleLinkTo.bind(this, 'edit_user')}
                    >
                      <FontAwesomeIcon
                        icon={['fas', 'plus']}
                        className="text-light mr-2"
                      />
                      لوحة التحكم
                    </a>
                  </li>
       
                  <li className="nav-item mx-1">
                    <a
                      href="/adminDashBoard/show_about_by_admin"
                      //className="nav-link text-white p-1 mx-1 dashboard-sidebar-link"
                      className={classnames(
                        'nav-link',
                        'text-white',
                       
                        'mx-1',
                        'dashboard-sidebar-link',
                        { 'dashboard-active': linkTo === 'show_about_by_admin' }
                      )}
                      // onClick={this.handleLinkTo.bind(
                      //   this,
                      //   'properties_contacts'
                      // )}
                    >
                      <FontAwesomeIcon
                        icon={['fas', 'search']}
                        className="text-light mr-2"
                      />
                       IKARحول
                       {` `}
                    </a>
                  </li>
            
                  <li className="nav-item mx-1">
                    <a
                      href="/adminDashBoard/show_faqs_by_admin"
                      //className="nav-link text-white p-1 mx-1 dashboard-sidebar-link"
                      className={classnames(
                        'nav-link',
                        'text-white',
                        
                        'mx-1',
                        'dashboard-sidebar-link',
                        { 'dashboard-active': linkTo === 'show_faqs_by_admin' }
                      )}
                      // onClick={this.handleLinkTo.bind(
                      //   this,
                      //   'properties_contacts'
                      // )}
                    >
                      <FontAwesomeIcon
                        icon={['fas', 'search']}
                        className="text-light mr-2"
                      />
                      الأسئلة الشائعة
                    </a>
                  </li>
                  <li className="nav-item mx-1">
                    <a
                      href="/adminDashBoard/show_joinus_by_admin"
                      //className="nav-link text-white p-1 mx-1 dashboard-sidebar-link"
                      className={classnames(
                        'nav-link',
                        'text-white',
                        
                        'mx-1',
                        'dashboard-sidebar-link',
                        {
                          'dashboard-active': linkTo === 'show_joinus_by_admin',
                        }
                      )}
                      //onClick={this.handleLinkTo.bind(this, 'properties')}
                    >
                      <FontAwesomeIcon
                        icon={['fas', 'search']}
                        className="text-light mr-2"
                      />
                      انضم إلينا
                    </a>
                  </li>
                  <li className="nav-item mx-1">
                    <a
                      href="/sendnotification"
                      //className="nav-link text-white p-1 mx-1 dashboard-sidebar-link"
                      className={classnames(
                        'nav-link',
                        'text-white',
            
                        'mx-1',
                        'dashboard-sidebar-link',
                        { 'dashboard-active': linkTo === 'sendnotification' }
                      )}
                      // onClick={this.handleLinkTo.bind(this, 'agencies')}
                    >
                      <FontAwesomeIcon
                      icon={['fas', 'bell']}
                        className="text-light mr-2"
                      />
                      الاشعارات
                    </a>
                  </li>
                  <li className="nav-item mx-1">
                    <a
                      href="/adminDashBoard/properties_contacts"
                      //className="nav-link text-white p-1 mx-1 dashboard-sidebar-link"
                      className={classnames(
                        'nav-link',
                        'text-white',
                  
                        'mx-1',
                        'dashboard-sidebar-link',
                        { 'dashboard-active': linkTo === 'properties_contacts' }
                      )}
                      // onClick={this.handleLinkTo.bind(
                      //   this,
                      //   'properties_contacts'
                      // )}
                    >
                      <FontAwesomeIcon
                        icon={['fas', 'search']}
                        className="text-light mr-2"
                      />
                      الرسائل
                    </a>
                  </li>
                  <li className="nav-item mx-1">
                    <a
                      href="/adminDashBoard/users"
                      //className="nav-link text-white p-1 mx-1 dashboard-sidebar-link"
                      className={classnames(
                        'nav-link',
                        'text-white',
                    
                        'mx-1',
                        'dashboard-sidebar-link',
                        { 'dashboard-active': linkTo === 'users' }
                      )}
                      //onClick={this.handleLinkTo.bind(this, 'users')}
                    >
                      <FontAwesomeIcon
                        icon={['fas', 'users']}
                        className="text-light mr-2"
                      />
                      المستخدمين
                    </a>
                  </li>
                  <li className="nav-item mx-1">
                    <a
                      href="/adminDashBoard/agency_registration"
                      //className="nav-link text-white p-1 mx-1 dashboard-sidebar-link"
                      className={classnames(
                        'nav-link',
                        'text-white',
                
                        'mx-1',
                        'dashboard-sidebar-link',
                        { 'dashboard-active': linkTo === 'agency_registration' }
                      )}
                      // onClick={this.handleLinkTo.bind(
                      //   this,
                      //   'agency_registration'
                      // )}
                    >
                      <FontAwesomeIcon
                        icon={['fas', 'plus']}
                        className="text-light mr-2"
                      />
                      اضافة مكتب
                    </a>
                  </li>
                  <li className="nav-item mx-1">
                    <a
                      href="/adminDashBoard/agencies"
                      //className="nav-link text-white p-1 mx-1 dashboard-sidebar-link"
                      className={classnames(
                        'nav-link',
                        'text-white',
                        'p-1',
                        'mx-1',
                        'dashboard-sidebar-link',
                        { 'dashboard-active': linkTo === 'agencies' }
                      )}
                      // onClick={this.handleLinkTo.bind(this, 'agencies')}
                    >
                      <FontAwesomeIcon
                        icon={['fas', 'binoculars']}
                        className="text-light mr-2"
                      />
                      المكاتب
                    </a>
                  </li>

                  {/* <li className="nav-item mx-1">
                    <Link
                      to="/adminDashBoard/properties_best_offer"
                      //className="nav-link text-white p-1 mx-1 dashboard-sidebar-link"
                      className={classnames(
                        'nav-link',
                        'text-white',
                        'p-1',
                        'mx-1',
                        'dashboard-sidebar-link',
                        {
                          'dashboard-active': linkTo === 'properties_best_offer'
                        }
                      )}
                      //onClick={this.handleLinkTo.bind(this, 'properties')}
                    >
                      <FontAwesomeIcon
                        icon={['fas', 'search']}
                        className="text-light mr-2"
                      />
                      العقارات اللقطة
                    </Link>
                  </li> */}

                  <li className="nav-item mx-1">
                    <a
                      href="/adminDashBoard/properties"
                      //className="nav-link text-white p-1 mx-1 dashboard-sidebar-link"
                      className={classnames(
                        'nav-link',
                        'text-white',
                        'p-1',
                        'mx-1',
                        'dashboard-sidebar-link',
                        { 'dashboard-active': linkTo === 'properties' }
                      )}
                      //onClick={this.handleLinkTo.bind(this, 'properties')}
                    >
                      <FontAwesomeIcon
                        icon={['fas', 'search']}
                        className="text-light mr-2"
                      />
                      العقارات
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col-12">
              {linkTo === 'agency_registration' ? <AgencyRegistration /> : null}
              {linkTo === 'agencies' ? <Agencies /> : null}
              {linkTo === 'user-registration' ? <UserRegistration /> : null}
              {linkTo === 'users' ? <Users /> : null}
              {linkTo === 'edit_agency' ? <EditAgency /> : null}
              {linkTo === 'show_faqs_by_admin' ? <ShowFaqByAdmin /> : null}
              {linkTo === 'show_about_by_admin' ? <About /> : null}
              {linkTo === 'show_privacy_by_admin' ? <Privacy /> : null}
              {linkTo === 'show_joinus_by_admin' ? <ShowJoinusByAdmin /> : null}
              {linkTo === 'add_faq' ? <AddFAQ /> : null}
              {linkTo === 'add_joinus' ? <AddJoinus /> : null}
              {linkTo === 'add_about' ? <AddAbout /> : null}
              {linkTo === 'edit_faq' ? (
                <EditFAQ locationSearch={this.state.locationSearch} />
              ) : null}
             {linkTo === 'edit_joinus' ? (
                <EditJoinus locationSearch={this.state.locationSearch} />
              ) : null}
             {linkTo === 'edit_about' ? (
                <EditAbout locationSearch={this.state.locationSearch} />
              ) : null}
             {linkTo === 'properties_by_agency' ? (
                <ShowPropertiesByAgency
                  locationSearch={this.state.locationSearch}
                  aIkar={true}
                  destination="adminDashBoard"
                  // isAdminTakingAgencyRole={isTakingAgencyRole}
                  // takenAgencyRoleEmail={takenAgencyRoleEmail}
                />
              ) : null}
              {linkTo === 'edit_property' ? (
                <EditProperty
                  locationSearch={this.state.locationSearch}
                  aIkar={true}
                  destination="adminDashBoard"
                  // isAdminTakingAgencyRole={isTakingAgencyRole}
                  // takenAgencyRoleEmail={takenAgencyRoleEmail}
                />
              ) : null}
              {linkTo === 'properties_contacts' ? (
                <ShowPropertiesWithContacts
                  locationSearch={this.state.locationSearch}
                ></ShowPropertiesWithContacts>
              ) : // <span className="display-3">srefsgsf</span>
              null}
              {/* {linkTo === 'properties_best_offer' ? (
                <ShowPropertiesWithBestOffer></ShowPropertiesWithBestOffer>
              ) :
              null} */}
              {/* {linkTo === 'add-property' ? <AddProperty /> : null} */}
              {linkTo === 'properties' ? (
                <Properties locationSearch={this.state.locationSearch} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  dashboardPath: state.setting.dashboardPath,
});
export default connect(mapStateToProps)(AdminDashBoard);
