import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import classnames from 'classnames';
import NotFound from '../../pages/NotFound';
import PropTypes from 'prop-types';
import { getProperty, callForProperty } from './../../actions/propertyActions';
import CurrencyFormat from 'react-currency-format';
import shortid from 'shortid';
import SearchBar from './../search/SearchBar.jsx';
import Lightbox from 'react-image-lightbox';
import { imageDomain, linkDomain } from './../../mainConf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContactAgency from '../contact/ContactAgency';


import { Growl } from 'primereact/growl';
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';
import { Dialog } from 'primereact/dialog';
import { Link } from 'react-router-dom';
import { noInternetMessage, mapsEnabled } from './../../mainConf';
import { ResReady, ResNotComplete } from './../layout/resTypes';
import logo from './../../assets/images/logo.png';
import SearchCategories from './../search/SearchCategories';
// import logo from './../../assets/images/logo.png';
import { createBrowserHistory } from 'history';
import { Helmet } from 'react-helmet';
import {
  buildShowPropertyTitle,
  buildShowPropertyDescription,
  buildAlt,
} from './../../mainConf/functions';

const history = createBrowserHistory();

class ShowProperty extends Component {
  constructor(props) {
    super();
    this.horizontalContainerRef = React.createRef();
    this.state = {
      pageNotFound: false,
      id: '',

      pageTitle: '',
      pageDescription: '',
      showCurrentLocation: false,
      lat: 34.890596,
      lng: 35.881085,
      zoom: 17,
      visible: true,
      largeScreen: false,
      mediumScreen: false,
      source: '',
      photoIndex: 0,
      isOpen: false,
      alt:"",
      noMessages: true,
      areas: [],
      IsMM: false,
      IsGarden: false,
      IsBuildDate: false,
      IsReconstructed: false,
      IsUsingGPS: false,
      showMap: false,
      maplays: null,
      propertyPosition: null,
      errors: {},
      title: '',
      sell_plan_id: 0,
      days: 0,
      temp_status: '',
      expiration_date: '',
      rented: true,
      rent_start: null,
      rent_end: null,
      pricing_type: 'ل.س',
      type: 'شقة',
      roof_building_area: 100,
      price_upon_review: false,
      rank: 100,
      icons: false,
      description: '',
      city: '',
      province: '',
      area: '',
      street: '',
      gps_location: '',
      bedrooms: 0,
      bathrooms: 0,
      toilets: 0,
      garage: true,
      parking: true,
      balcony: 0,
      view_direction: '',
      floor: 0,
      duplex: true,
      total_area: 0,
      living_area: 0,
      living_rooms: 0,
      ground_area: 0,
      lift: true,
      furnitured: true,
      type_of_ownership: 'طابو أخضر - 2400 سهم',
      price: 0,
      business_status: 'جديد',
      rent_plan_id: 1,
      // sell_plan_id: 2,
      built_at: null,
      reconstructed_at: null,
      status: '',
      price_per_day: 0,
      price_per_mm: 0,
      business_offer: 'بيع',
      views: 1,
      basement: true,
      created_at: null,
      reference_id: '',
      agency_ref_id: '',
      agent_ref_id: '',
      agency_rank: 100,
      agency_name: '',
      agency_mobile: '',
      agency_whatsapp: '',
      agency_telegram: '',
      agency_business_address: '',
      url2: [],
      thumbnails2: [],
      url: [],
      thumbnails: [],
      resType: 'ready',
      shortHeader: false,
      hideContact: false,
    };
  }
  
  onTypeChange2 = (type) => {
    if (type === 'سطح معد للبناء') {
        this.setState({
            // IsRoof: true,
            living_rooms: 0,
            balcony: 0,
            bedrooms: 0,
            bathrooms: 0,
            toilets: 0,
            floor: -100,
            view_direction: 'غير محدد',
            living_area: '',
            ground_area: '',
            furnitured: false,
            duplex: false,
            // garage: false,
            // basement: false,
            // parking: false
        });
    } else {
        this.setState({
            // IsRoof: false
        });
    }

    if (type === 'بناء كامل') {
        this.setState({
            // IsFullBuilding: true,
            living_rooms: 0,
            balcony: 0,
            bedrooms: 0,
            bathrooms: 0,
            toilets: 0,
            floor: -100,
            view_direction: 'غير محدد',
            duplex: false,
            roof_building_area: '',
        });
    } else {
        this.setState({
            // IsFullBuilding: false
        });
    }

    if (type === 'فندق') {
        this.setState({
            // IsHotel: true,
            roof_building_area: '',
            living_rooms: 0,
            balcony: 0,
            bathrooms: 0,
            toilets: 0,
            floor: -100,
        });
    } else {
        this.setState({
            // IsHotel: false
        });
    }

    if (type === 'فيلا') {
        this.setState({
            // IsVilla: true,
            floor: -100,
            roof_building_area: '',
        });
    } else {
        this.setState({
            // IsVilla: false
        });
    }
    if (type === 'مرآب' || type === 'صالة') {
        this.setState({
            // IsGarage: true,
            roof_building_area: '',
            living_rooms: 0,
            balcony: 0,
            bedrooms: 0,
            bathrooms: 0,
            toilets: 0,
            view_direction: 'غير محدد',
            garage: false,
            basement: false,
            parking: false,
            duplex: false,
            lift: false,
            furnitured: false,
            ground_area: '',
        });
    } else {
        this.setState({
            // IsGarage: false
        });
    }
    if (
        type === 'محلات تجارية' ||
        type === 'محلات اصلاح' ||
        type === 'محلات لتربية الحيوانات'
    ) {
        this.setState({
            // IsShop: true,
            roof_building_area: '',
            living_rooms: 0,
            balcony: 0,
            bedrooms: 0,
            bathrooms: 0,
            toilets: 0,
            floor: -100,
            view_direction: 'غير محدد',
            garage: false,
            basement: false,
            parking: false,
            duplex: false,
            lift: false,
            furnitured: false,
            ground_area: '',
        });
    } else {
        this.setState({
            // IsShop: false
        });
    }
    if (type === 'مستودع' || type === 'منشأة تصنيع') {
        this.setState({
            // IsStoreFactory: true,
            roof_building_area: '',
            living_rooms: 0,
            balcony: 0,
            bedrooms: 0,
            bathrooms: 0,
            toilets: 0,
            floor: -100,
            view_direction: 'غير محدد',
            furnitured: false,
            duplex: false,
            ground_area: '',
        });
    } else {
        this.setState({
            // IsStoreFactory: false
        });
    }
    if (type === 'مطعم' || type === 'مقهى') {
        this.setState({
            // IsRestaurant: true,
            roof_building_area: '',
            living_rooms: 0,
        });
    } else {
        this.setState({
            // IsRestaurant: false
        });
    }

    if (type === 'مكتب' || type === 'عيادة') {
        this.setState({
            // IsOffice: true,
            roof_building_area: '',
            ground_area: '',
            living_rooms: 0,
        });
    } else {
        this.setState({
            // IsOffice: false
        });
    }
    if (type === 'محطة وقود') {
        this.setState({
            // IsGasStation: true,
            roof_building_area: '',
            living_rooms: 0,
            balcony: 0,
            bedrooms: 0,
            bathrooms: 0,
            toilets: 0,
            floor: -100,
            view_direction: 'غير محدد',
            garage: false,
            basement: false,
            parking: false,
            duplex: false,
            lift: false,
            furnitured: false,
            ground_area: '',
        });
    } else {
        this.setState({
            // IsGasStation: false
        });
    }

    if (type === 'محلات لتربية الحيوانات') {
        this.setState({
            // IsForAnimal: true,
            roof_building_area: '',
            living_rooms: 0,
            balcony: 0,
            bedrooms: 0,
            bathrooms: 0,
            toilets: 0,
            floor: -100,
            view_direction: 'غير محدد',
            garage: false,
            basement: false,
            parking: false,
            duplex: false,
            lift: false,
            furnitured: false,
            ground_area: '',
        });
    } else {
        this.setState({
            // IsForAnimal: false
        });
    }
    if (type === 'قبو') {
        this.setState({
            // IsUnderGround: true,
            roof_building_area: '',
            ground_area: '',
            basement: false,
        });
    } else {
        this.setState({
            // IsUnderGround: false
        });
    }

    if (type === 'مزرعة') {
        this.setState({
            // IsFarm: true,
            roof_building_area: '',
            living_rooms: 0,
            balcony: 0,
            bedrooms: 0,
            bathrooms: 0,
            toilets: 0,
            floor: -100,
            view_direction: 'غير محدد',
            garage: false,
            basement: false,
            parking: false,
            duplex: false,
            lift: false,
            furnitured: false,
            ground_area: '',
            status: 'جاهز',
            built_at: null,
            reconstructed_at: null,
        });
    } else {
        this.setState({
            // IsFarm: false
        });
    }

    if (
        type === 'أراضي زراعية' ||
        type === 'أرض للبناء' ||
        type === 'أراضي ذات رخصة سياحية' ||
        type === 'أراضي ذات رخصة صناعية' ||
        type === 'أراضي ذات رخصة صناعية'
    ) {
        this.setState({
            // IsField: true,
            roof_building_area: '',
            living_rooms: 0,
            balcony: 0,
            bedrooms: 0,
            bathrooms: 0,
            toilets: 0,
            floor: -100,
            view_direction: 'غير محدد',
            garage: false,
            basement: false,
            parking: false,
            duplex: false,
            lift: false,
            furnitured: false,
            ground_area: '',
            status: 'جاهز',
            built_at: null,
            reconstructed_at: null,
        });
    } else {
        this.setState({
            // IsField: false
        });
    }
};

componentWillReceiveProps(nextProps, nextState) {
  var icons = false;
  if (
      nextProps.requestFailedMessage &&
      nextProps.requestFailedMessage !== undefined &&
      nextProps.requestFailedMessage.errorMessage !== undefined &&
      nextProps.requestFailedMessage.errorMessage !== null &&
      // !this.state.noMessages &&
      nextProps.requestFailedMessage.errorMessage.data !== undefined &&
      nextProps.requestFailedMessage.errorMessage.data.errors !== undefined &&
      nextProps.requestFailedMessage.errorMessage.data.errors[0] !==
      undefined &&
      nextProps.requestFailedMessage.errorMessage.data.errors[0].detail !==
      undefined &&
      nextProps.requestFailedMessage.errorMessage.data.errors[0].detail
          .detail === `can't show this property now, it is hidden or Archived`
  ) {
      var errors = {};
      errors.visible = 'هذه الصفحة غير متوفرة حالياً';
      this.setState({
          pageNotFound: true,
          errors,
      });
  } else if (
      nextProps.requestFailedMessage !== undefined &&
      nextProps.requestFailedMessage.errorMessage !== undefined &&
      nextProps.requestFailedMessage.errorMessage !== null &&
      // !this.state.noMessages &&
      nextProps.requestFailedMessage.errorMessage.data !== undefined &&
      nextProps.requestFailedMessage.errorMessage.data.errors !== undefined &&
      nextProps.requestFailedMessage.errorMessage.data.errors[0] !==
      undefined &&
      nextProps.requestFailedMessage.errorMessage.data.errors[0].detail !==
      undefined &&
      this.props.match !== undefined &&
      nextProps.requestFailedMessage.errorMessage.data.errors[0].detail ===
      `Property with Reference_id: ${this.props.match.params.id} does not exist`
  ) {
      this.setState({ pageNotFound: true });
  } else if (
      nextProps.property !== undefined &&
      nextProps.property !== [] &&
      nextProps.property !== {} &&
      nextProps.property.reference_id !== this.state.reference_id &&
      nextProps.resType !== 'not complete'
  ) {
      const {
          title,
          sell_plan_id,
          days,
          temp_status,
          expiration_date,
          rented,
          rent_start,
          rent_end,
          pricing_type,
          type,
          roof_building_area,
          price_upon_review,
          rank,
          description,
          city,
          province,
          area,
          street,
          gps_location,
          bedrooms,
          bathrooms,
          toilets,
          garage,
          parking,
          balcony,
          view_direction,
          floor,
          duplex,
          total_area,
          living_area,
          living_rooms,
          ground_area,
          lift,
          furnitured,
          type_of_ownership,
          price,
          business_status,
          rent_plan_id,
          visible,
          built_at,
          reconstructed_at,
          status,
          price_per_day,
          price_per_mm,
          business_offer,
          views,
          basement,
          created_at,
          reference_id,
          agency_ref_id,
          agent_ref_id,
          agency_rank,
          agency_name,
          agency_mobile,
          agency_whatsapp,
          agency_telegram,
          agency_business_address,
          url,
          thumbnails,
      } = nextProps.property;
      let flatType =
          type === 'استوديو' ||
          type === 'شقة' ||
          type === 'بيت' ||
          type === 'شاليه' ||
          type === 'فيلا'
              ? true
              : false;
      let officeType =
          type === 'مقهى' ||
          type === 'مكتب' ||
          type === 'عيادة' ||
          type === 'مطعم' ||
          type === 'منشأة تصنيع' ||
          type === 'محطة وقود' ||
          type === 'محلات تجارية' ||
          type === 'محلات اصلاح' ||
          type === 'محلات لتربية الحيوانات'
              ? true
              : false;
      let pageTitle =
          this.props.source === undefined
              ? buildShowPropertyTitle(
              title,
              type,
              bedrooms,
              flatType,
              officeType,
              furnitured,
              business_offer,
              province,
              area
              )
              : '';

      let pageDescription =
          this.props.source === undefined
              ? buildShowPropertyDescription(
              title,
              gps_location,
              description,
              agency_name
              )
              : '';
      if (
          (bedrooms !== null && bedrooms !== undefined && bedrooms !== 0) ||
          (living_rooms !== null &&
              living_rooms !== undefined &&
              living_rooms !== 0) ||
          (bathrooms !== null && bathrooms !== undefined && bathrooms !== 0) ||
          (balcony !== null && balcony !== undefined && balcony !== 0) ||
          (floor !== undefined && floor !== null && floor !== -100) ||
          (garage !== null && garage !== undefined && garage === true) ||
          (furnitured !== null &&
              furnitured !== undefined &&
              furnitured === true) ||
          (parking !== null && parking !== undefined && parking === true) ||
          (duplex !== null && duplex !== undefined && duplex === true) ||
          (lift !== null && lift !== undefined && lift === true) ||
          (basement !== null && basement !== undefined && basement === true)
      ) {
          icons = true;
      }
      if (
          (gps_location === null ||
              gps_location === undefined ||
              gps_location === '' ||
              !mapsEnabled) &&
          (url === null || url === undefined || url.length === 0)
      ) {
          this.setState({ shortHeader: true });
      } else {
          this.setState({ shortHeader: false });
      }

      this.setState({
          pageNotFound: false,
          pageTitle: pageTitle.replace(/\s\s+/g, ' '),
          pageDescription: pageDescription.replace(/\s\s+/g, ' '),
          title,
          visible,
          sell_plan_id,
          days,
          temp_status,
          expiration_date,
          rented,
          rent_start,
          rent_end,
          pricing_type,
          type,
          roof_building_area,
          price_upon_review,
          rank,
          description,
          city,
          province,
          area,
          street,
          gps_location,
          bedrooms,
          bathrooms,
          toilets,
          garage,
          parking,
          balcony,
          view_direction,
          floor,
          duplex,
          total_area,
          living_area,
          living_rooms,
          ground_area,
          lift,
          furnitured,
          type_of_ownership,
          price,
          business_status,
          rent_plan_id,
          built_at,
          reconstructed_at,
          status,
          icons,
          price_per_day,
          price_per_mm,
          business_offer,
          views,
          basement,
          created_at,
          reference_id,
          agency_ref_id,
          agent_ref_id,
          agency_rank,
          agency_name,
          agency_mobile,
          agency_whatsapp,
          agency_telegram,
          agency_business_address,
          url,
          thumbnails: thumbnails,
      });

      if (
          gps_location !== null &&
          gps_location !== undefined &&
          gps_location !== '' &&
          mapsEnabled
      ) {
          const lat = parseFloat(gps_location.split(',')[0]);
          const lng = parseFloat(gps_location.split(',')[1]);
          this.setState({
              lat,
              lng,
          });
      }
      this.onTypeChange2(type);
  } else {
  }

  if (
      nextProps.resType !== undefined &&
      nextProps.resType !== this.state.resType
  ) {
      const { resType } = nextProps;
      this.setState({ resType });
  }

  if (nextProps.propertyO !== undefined && nextProps.propertyO !== null) {
      // if (nextProps.propertyO.reference_id !== this.state.reference_id) {
      if (Object.keys(nextProps.propertyO).length > 0) {
          const {
              title,
              // sell_plan_id,
              days,
              temp_status,
              expiration_date,
              rented,
              rent_start,
              rent_end,
              pricing_type,
              type,
              roof_building_area,
              price_upon_review,
              rank,
              description,
              city,
              province,
              area,
              street,
              gps_location,
              bedrooms,
              bathrooms,
              // toilets,
              garage,
              parking,
              balcony,
              view_direction,
              floor,
              duplex,
              total_area,
              living_area,
              living_rooms,
              ground_area,
              lift,
              furnitured,
              type_of_ownership,
              price,
              business_status,
              rent_plan_id,
              sell_plan_id,
              visible,
              built_at,
              reconstructed_at,
              status,
              price_per_day,
              price_per_mm,
              business_offer,
              views,
              basement,
              // created_at,
              // reference_id,
              // agency_ref_id,
              // agent_ref_id,
              // agency_rank,
              // agency_name,
              // agency_mobile,
              // agency_whatsapp,
              // agency_telegram,
              // agency_business_address,
              photo_data,
              url,
              thumbnails,
          } = nextProps.propertyO;
          icons = false;
          if (
              (bedrooms !== null && bedrooms !== undefined && bedrooms !== 0) ||
              (living_rooms !== null &&
                  living_rooms !== undefined &&
                  living_rooms !== 0) ||
              (bathrooms !== null && bathrooms !== undefined && bathrooms !== 0) ||
              (balcony !== null && balcony !== undefined && balcony !== 0) ||
              (floor !== undefined && floor !== null && floor !== -100) ||
              (garage !== null && garage !== undefined && garage === true) ||
              (furnitured !== null &&
                  furnitured !== undefined &&
                  furnitured === true) ||
              (parking !== null && parking !== undefined && parking === true) ||
              (duplex !== null && duplex !== undefined && duplex === true) ||
              (lift !== null && lift !== undefined && lift === true) ||
              (basement !== null && basement !== undefined && basement === true)
          ) {
              icons = true;
          }
          if (
              (gps_location === null ||
                  gps_location === undefined ||
                  gps_location === '' ||
                  !mapsEnabled) &&
              (photo_data === null ||
                  photo_data === undefined ||
                  photo_data.length === 0) &&
              (this.state.url === null ||
                  this.state.url === undefined ||
                  this.state.url.length === 0)
          ) {
              this.setState({ shortHeader: true });
          } else {
              this.setState({ shortHeader: false });
          }

          this.setState({
              title,
              days,
              temp_status,
              expiration_date,
              rented,
              rent_start,
              rent_end,
              pricing_type,
              type,
              roof_building_area,
              price_upon_review,
              rank,
              icons,
              description,
              city,
              province,
              area,
              street,
              gps_location,
              bedrooms,
              bathrooms,
              //toilets,
              garage,
              parking,
              balcony,
              view_direction,
              floor,
              duplex,
              total_area,
              living_area,
              living_rooms,
              ground_area,
              lift,
              furnitured,
              type_of_ownership,
              price,
              business_status,
              rent_plan_id,
              sell_plan_id,
              visible,
              built_at,
              reconstructed_at,
              status,
              price_per_day,
              price_per_mm,
              business_offer,
              views,
              basement,
              // created_at,
              // reference_id,
              // agency_ref_id,
              // agent_ref_id,
              // agency_rank,
              // agency_name,
              // agency_mobile,
              // agency_whatsapp,
              // agency_telegram,
              // agency_business_address,
              url2: url,
              thumbnails2: thumbnails,
              url: photo_data,
              thumbnails: photo_data,
              hideContact: true,
          });

          if (
              gps_location !== null &&
              gps_location !== undefined &&
              gps_location !== '' &&
              mapsEnabled
          ) {
              const lat = parseFloat(gps_location.split(',')[0]);
              const lng = parseFloat(gps_location.split(',')[1]);
              this.setState({
                  lat,
                  lng,
              });
          }
      }
  }
}
isNumeric = (num) => {
    // return !isNaN(num);
    // return /^-{0,1}\d+$/.test(num);
    return /^\d+$/.test(num);
};

  componentDidMount() {
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
    if (
      this.props.match !== undefined &&
      this.props.match.params !== undefined
    ) {
      const { id } = this.props.match.params;
      if (id !== undefined && id !== null) {
        const ikarAdmin = this.props.ikarAdmin;
        const isTakingAgencyRole = this.props.isTakingAgencyRole;
        const takenAgencyRoleToken = this.props.takenAgencyRoleToken;
        this.setState({ id });
        this.props.getProperty(
          ikarAdmin,
          isTakingAgencyRole,
          takenAgencyRoleToken,
          id
        );
      }
    }
  }
  showMessage = () => {
    this.growl.show({
      severity: 'success',
      summary: 'تم ارسال الرسالة بنجاح',
    });
    if (document.getElementById('whatsappButton') !== null) {
      if (!window.confirm('هل تريد التواصل مع المكتب عبر ال واتسأب ')) {
        return;
      }

      document.getElementById('whatsappButton').click();
    }
  };

  onMapShowClick = () => {
    // e.preventDefault()
    this.setState({ showCurrentLocation: true });
  };
  rightButtonClick = () => {
    this.horizontalContainerRef.current.scrollBy({
      behavior: 'smooth',
      left: 500,
      top: 0,
    });
  };
  leftButtonClick = () => {
    this.horizontalContainerRef.current.scrollBy({
      behavior: 'smooth',
      left: -500,
      top: 0,
    });
  };
  onTelClick = async (id, e) => {
    // e.preventDefault();
    await ReactGA.event({
      category: 'Tel click',
      action: `Property ID: ${id}`,
    });
    this.props.callForProperty(id);
  };
  resize() {
    let currentWidth = window.innerWidth;
    if (currentWidth >= 992) {
      this.setState({ largeScreen: true });
    } else {
      this.setState({ largeScreen: false });
    }
    if (currentWidth >= 576) {
      this.setState({ mediumScreen: true });
    } else {
      this.setState({ mediumScreen: false });
    }
  }
  onGoBack = () => {
    history.goBack();
  };
  onAgencyClick = () => {
    ReactGA.event({
      category: 'Agency Page',
      action: `Agency ID: ${this.state.agency_ref_id}`,
    });
  };
  onEditProperty = async (reference_id, visibleStatus, e) => {
    if (!window.confirm('تعديل العقار المحدد ؟')) {
      e.preventDefault();
      return;
    }
  };
  openFacebookDialog = () => {
    //   window.open(
    //     `https://www.facebook.com/v2.10/dialog/share?
    //  app_id=4355048651203271
    // &href=${window.location.href}&quote=${
    //       this.state.created_at !== undefined && this.state.created_at !== null
    //         ? this.state.created_at.slice(0, 10)
    //         : ''
    //     }
    // ${this.state.agency_name}
    // ${this.state.title}
    // ${
    //   this.state.province !== this.state.area
    //     ? `${this.state.province} - ${this.state.area}`
    //     : this.state.province
    // }
    // ${this.state.type_of_ownership}
    // ${this.state.status}
    // ${price1}
    // ${holetext2}

    // ${this.state.description}&hashtag:${this.state.type}`,
    //     'facebook-share-dialog',
    //     'width=626,height=436'
    //   );
    // let agname = this.state.agency_name;

    // var agnamecode = encodeURI(agname);
    ReactGA.event({
        category: 'Facebook Sharing Button',
        action: `Property URL: ${window.location.href}`,
    });

    let price1 = '';
    this.state.price_upon_review === true
        ? (price1 = 'السعر عند المراجعة')
        : (price1 = `${this.state.price} ${this.state.pricing_type} `);
    let areaT = '';

    this.state.total_area !== null &&
    this.state.total_area !== undefined &&
    parseInt(this.state.total_area) !== 0
        ? (areaT = `المساحة الكلية ${this.state.total_area}م2`)
        : (areaT = '');

    let groundA = '';

    this.state.ground_area !== '' &&
    this.state.ground_area !== undefined &&
    parseInt(this.state.ground_area) >= 0
        ? (groundA = `تتضمن حديقة: ${this.state.ground_area}م2`)
        : (groundA = '');

    let broom = '';

    this.state.bedrooms !== null &&
    this.state.bedrooms !== undefined &&
    this.state.bedrooms !== 0
        ? this.state.type === 'مقهى' ||
        this.state.type === 'مكتب' ||
        this.state.type === 'عيادة' ||
        this.state.type === 'مطعم' ||
        this.state.type === 'منشأة تصنيع' ||
        this.state.type === 'محطة وقود' ||
        this.state.type === 'محلات تجارية' ||
        this.state.type === 'محلات اصلاح' ||
        this.state.type === 'محلات لتربية الحيوانات'
        ? (broom = `عدد الغرف: ${this.state.bedrooms} `)
        : (broom = `غرف النوم: ${this.state.bedrooms} `)
        : (broom = '');

    let liroom = '';

    this.state.living_rooms !== null &&
    this.state.living_rooms !== undefined &&
    parseInt(this.state.living_rooms) !== 0
        ? (liroom = `غرف الصالون: ${this.state.living_rooms} `)
        : (liroom = '');

    let baroom = '';

    this.state.bathrooms !== null &&
    this.state.bathrooms !== undefined &&
    parseInt(this.state.bathrooms) !== 0
        ? (baroom = `غرف الاستحمام: ${this.state.bathrooms} `)
        : (baroom = '');

    let bal = '';

    this.state.balcony !== null &&
    this.state.balcony !== undefined &&
    parseInt(this.state.balcony) !== 0
        ? (bal = `عدد البلكونات: ${this.state.balcony} `)
        : (bal = '');

    let flnum = '';

    this.state.floor !== undefined &&
    this.state.floor !== null &&
    parseInt(this.state.floor) !== -100
        ? this.state.floor === 0
        ? (flnum = ' الطابق أرضي ')
        : this.state.floor === -24
            ? (flnum = 'الطابق أرضي منخفض ')
            : this.state.floor === -25
                ? (flnum = 'الطابق نص قبو (قبو فايش) ')
                : this.state.floor === -1 ||
                this.state.floor === -2 ||
                this.state.floor === -3
                    ? (flnum = `الطابق قبو ${this.state.floor} `)
                    : (flnum = `رقم الطابق: ${this.state.floor} `)
        : (flnum = '');

    let gar = '';

    this.state.garage !== null &&
    this.state.garage !== undefined &&
    this.state.garage === true
        ? (gar = 'له كراج ')
        : (gar = '');

    let fur = '';

    this.state.furnitured !== null &&
    this.state.furnitured !== undefined &&
    this.state.furnitured === true
        ? (fur = 'مفروش ')
        : (fur = '');

    let par = '';

    this.state.parking !== null &&
    this.state.parking !== undefined &&
    this.state.parking === true
        ? (par = 'له موقف ')
        : (par = '');

    let dup = '';

    this.state.duplex !== null &&
    this.state.duplex !== undefined &&
    this.state.duplex === true
        ? (dup = 'العقار دوبلكس ')
        : (dup = '');

    let li = '';

    this.state.lift !== null &&
    this.state.lift !== undefined &&
    this.state.lift === true
        ? (li = 'له مصعد ')
        : (li = '');

    let bas = '';

    this.state.basement !== null &&
    this.state.basement !== undefined &&
    this.state.basement === true
        ? (bas = 'له ملجأ ')
        : (bas = '');

    let holetext = `${areaT} ${groundA}
${broom}
${liroom}
${baroom}
${bal}
${flnum}
${gar}
${fur}
${par}
${dup}
${li}
${bas}`;
    let holetext2 = holetext.replace(/(^[ \t]*\n)/gm, '');
    // {`${linkDomain}/properties/${this.state.id}`}
    // let image = '';

    // let br = <br></br>;
    // let m = <h1>hiiiiiii</h1>;

    this.state.price_upon_review === true
        ? (price1 = 'السعر عند المراجعة')
        : (price1 = `السعر:${this.state.price}   ${this.state.pricing_type}`);

    // if (
    //   this.state.url !== null &&
    //   this.state.url !== undefined &&
    //   this.state.url.length > 0
    // ) {
    //   image = `${imageDomain}/${this.state.url[0]}`;
    // }

    window.open(
        `https://www.facebook.com/v2.10/dialog/share?
   app_id=1499497363745951
  &href=${window.location.href}&quote=${
            this.state.created_at !== undefined && this.state.created_at !== null
                ? this.state.created_at.slice(0, 10)
                : ''
        }
- ${this.state.agency_name}
- ${this.state.title}
- ${
            this.state.province !== this.state.area
                ? `${this.state.province} - ${this.state.area}`
                : this.state.province
        }
- ${this.state.type_of_ownership}
- ${this.state.status}
- ${holetext2}
- ${price1}
  
- ${this.state.description}&hashtag:${this.state.type}`,
        'facebook-share-dialog',
        'width=626,height=436'
    );

    //     window.FB.ui({
    //       method: 'share',
    //       href: `${window.location.href}`,
    //       quote: `${
    //         this.state.created_at !== undefined && this.state.created_at !== null
    //           ? this.state.created_at.slice(0, 10)
    //           : ''
    //       }
    // ${this.state.agency_name}
    // ${this.state.title}
    // ${
    //   this.state.province !== this.state.area
    //     ? `${this.state.province} - ${this.state.area}`
    //     : this.state.province
    // }
    // ${this.state.type_of_ownership}
    // ${this.state.status}
    // ${holetext2}
    // ${price1}

    // ${this.state.description}`,
    //       hashtag: `${this.state.type}`,
    //     });
};
onSearchClick = () => {
    var search_bar_section = document.getElementById('search_bar_section_id');
    search_bar_section.scrollIntoView({ behavior: 'smooth' });
};


  render() {
    // let imageUrl = `${imageDomain}/${this.state.thumbnails[0]}`;
    const position = [this.state.lat, this.state.lng];
    const {
      largeScreen,
      mediumScreen,
      showCurrentLocation,
      photoIndex,
      isOpen,
      alt,
      title,
      icons,
      // sell_plan_id,
      temp_status,
      // temp_status,
      // expiration_date,
      rented,
      // rent_start,
      rent_end,
      pricing_type,
      type,
      roof_building_area,
      price_upon_review,
      // rank,
      description,
      city,
      province,
      area,
      street,
      gps_location,
      bedrooms,
      bathrooms,
      // toilets,
      garage,
      parking,
      balcony,
      view_direction,
      floor,
      duplex,
      total_area,
      // living_area,
      living_rooms,
      ground_area,
      lift,
      furnitured,
      type_of_ownership,
      price,
      // business_status,
      // rent_plan_id,
      // sell_plan_id,
      // visible,
      built_at,
      reconstructed_at,
      status,
      // price_per_day,
      // price_per_mm,
      business_offer,
      // views,
      basement,
      created_at,
      reference_id,
      agency_ref_id,
      // agent_ref_id,
      // agency_rank,
      agency_name,
      agency_mobile,
      agency_whatsapp,
      agency_telegram,
      agency_business_address,
      url,
      thumbnails,
      resType,
      shortHeader,
      hideContact,
      errors,
    } = this.state;
    return (
      <React.Fragment>
        {this.state.pageNotFound ? (
          <NotFound />
        ) : (
          <React.Fragment>
            <Helmet>
              {this.state.title !== '' ? (
                <link
                  rel="canonical"
                  href={`${linkDomain}/properties/${this.state.id}`}
                />
              ) : null}

              <title>{this.state.pageTitle}</title>
              <meta name="description" content={this.state.pageDescription} />
            </Helmet>
            <div className="contact_main_container">
              {(resType === 'complete' || hideContact) &&
              errors.visible !== 'هذه الصفحة غير متوفرة حالياً' ? (
                <React.Fragment>
                  <div className="text-center my-1">
                    <button
                      className="btn btn-warning my-1"
                      onClick={this.onSearchClick}
                    >
                      <span>ابحث عن عقارك المفضل{` `}</span>
                      <span>
                        <FontAwesomeIcon icon={['fas', 'search']} />
                      </span>
                    </button>
                    <a
                      href={`${linkDomain}/`}
                      className="btn btn-warning mr-2 my-1"
                    >
                      <span>الصفحة الرئيسية{` `}</span>
                      <span>
                        <FontAwesomeIcon icon={['fas', 'home']} />
                      </span>
                    </a>
                  </div>
                  <Dialog
                    header="موقع العقار المحدد على الخريطة"
                    //footer={mapFooter}
                    // iconsTemplate={mapIcon}
                    visible={showCurrentLocation}
                    style={{ width: '90vw', minHeight: '70vh' }}
                    modal={true}
                    onHide={() => this.setState({ showCurrentLocation: false })}
                  >
                    <div>
                      {showCurrentLocation ? (
                        mapsEnabled ? (
                          <Map
                            center={position}
                            zoom={this.state.zoom}
                            style={{ width: '85vw', minHeight: '65vh' }}
                          >
                            <TileLayer
                              attribution='<a href="http://ikar.sy">Ikar.sy</a> &amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                            <Marker position={position}>
                              <Popup>
                                <span className="display_8">{title}</span>
                              </Popup>
                            </Marker>
                          </Map>
                        ) : (
                          noInternetMessage
                        )
                      ) : null}
                    </div>
                  </Dialog>
                  {/* <div className="text-center main_title">استعراض عقار</div> */}
                  <Growl ref={(propertyM) => (this.growl = propertyM)} />

                  <table className="show-property-table-contact">
                    <thead></thead>
                    <colgroup>
                      <col style={{ width: '50%' }} />
                      <col style={{ width: 'auto' }} />
                    </colgroup>
                    <tbody>
                      <tr className="show-property-tr pl-sm-2 pl-md-5 pl-lg-2">
                        <td className=" text-center">
                          <span className="show-title">المكتب العقاري</span>
                        </td>
                        <td style={{ textAlign: 'right' }}>
                          <div>
                            {this.state.hideContact &&
                            this.props.source === 'add' ? (
                              <span
                                className="display_6 "
                                style={{ color: '#fcb814' }}
                              >
                                {' اسم المكتب العقاري '}
                              </span>
                            ) : null}
                            {!this.state.hideContact ? (
                              <a
                                href={`${linkDomain}/agency_profile/${agency_ref_id}`}
                                className="mx-auto"
                                onClick={this.state.onAgencyClick}
                                style={{ color: '#f18805' }}
                              >
                                {/* <Link
                            to={`/agency_profile/${agency_ref_id}`}
                            className="mx-auto"
                            onClick={this.state.onAgencyClick}
                            style={{ color: '#f18805' }}
                          > */}
                                <span
                                  className="show-title show-property-link"
                                  // style={{ color: '#f18805' }}
                                >
                                  {' '}
                                  {agency_name}{' '}
                                </span>
                                {/* </Link> */}
                              </a>
                            ) : (
                              <span>{agency_name} </span>
                            )}
                          </div>
                        </td>
                      </tr>
                      {agency_mobile !== '' &&
                      agency_mobile !== undefined &&
                      agency_mobile !== null ? (
                        <tr className="show-property-tr pl-sm-2 pl-md-5 pl-lg-2">
                          <td>
                            <span className="padding-telegram">
                              {' '}
                              {this.state.hideContact &&
                              this.props.source === 'add' ? (
                                <span
                                  className="display_6"
                                  style={{ color: '#fcb814' }}
                                >
                                  {' رقم الموبايل '}
                                </span>
                              ) : null}
                              <React.Fragment>
                                <FontAwesomeIcon
                                  icon={['fas', 'mobile-alt']}
                                  color="gray"
                                />
                                <span className="show-title">{` موبايل `}</span>
                              </React.Fragment>
                            </span>
                          </td>
                          <td style={{ textAlign: 'right' }}>
                            {' '}
                            {agency_mobile !== '' &&
                            agency_mobile !== undefined &&
                            agency_mobile !== null ? (
                              <React.Fragment>
                                {` `}
                                {/* موبايل: */}
                                <a
                                  href={`tel:${agency_mobile}`}
                                  // onClick="ga('send', 'event', { eventCategory: 'Contact', eventAction: 'Call', eventLabel: 'Mobile Button'});"
                                  style={{ color: '#f18805' }}
                                  onClick={this.onTelClick.bind(
                                    this,
                                    reference_id
                                  )}
                                >
                                  <span className="show-property-link">
                                    {agency_mobile.replace('00963', '0')}
                                  </span>

                                  {` `}
                                  {mediumScreen ? (
                                    <span className="show-property-whatsapp">
                                      {` انقر واتصل `}
                                    </span>
                                  ) : (
                                    <span className="show-property-whatsapp">
                                      {` اتصل `}
                                    </span>
                                  )}
                                </a>
                              </React.Fragment>
                            ) : null}
                          </td>
                        </tr>
                      ) : null}
                      {agency_whatsapp !== '' &&
                      agency_whatsapp !== undefined &&
                      agency_whatsapp !== null ? (
                        <tr className="show-property-tr-whatsapp  pl-sm-2 pl-md-5 pl-lg-2">
                          <td>
                            <span className="padding-table">
                              {' '}
                              <React.Fragment>
                                <FontAwesomeIcon
                                  icon={['fab', 'whatsapp']}
                                  color="#4ac959"
                                />
                                <span className="show-title">{` واتس أب `}</span>
                                {/* واتس أب: */}
                              </React.Fragment>
                            </span>
                          </td>
                          <td style={{ textAlign: 'right' }}>
                            {' '}
                            {agency_whatsapp !== '' &&
                            agency_whatsapp !== undefined &&
                            agency_whatsapp !== null ? (
                              <React.Fragment>
                                {` `}
                                {/* واتس أب: */}
                                <a
                                  id="whatsappButton"
                                  href={`https://wa.me/${agency_whatsapp.replace(
                                    '00',
                                    ''
                                  )}?text=${encodeURI(
                                    `مرحباً, أرجو التواصل معي بخصوص العقار: ${title} - ${business_offer}- Ikar.sy:<${linkDomain}/properties/${reference_id}>`
                                  )}`}
                                  // onClick="ga('send', 'event', { eventCategory: 'Contact', eventAction: 'Call', eventLabel: 'Mobile Button'});"
                                  style={{ color: '#f18805' }}
                                  onClick={this.onTelClick.bind(
                                    this,
                                    reference_id
                                  )}
                                >
                                  <span className="show-property-link">
                                    {agency_whatsapp.replace('00963', '0')}
                                  </span>

                                  {` `}
                                  {mediumScreen ? (
                                    <span className="show-property-whatsapp">
                                      {` انقر ودردش `}
                                    </span>
                                  ) : (
                                    <span className="show-property-whatsapp">
                                      {` دردش `}
                                    </span>
                                  )}
                                </a>
                              </React.Fragment>
                            ) : null}
                          </td>
                        </tr>
                      ) : null}
                      {agency_telegram !== '' &&
                      agency_telegram !== undefined &&
                      agency_telegram !== null ? (
                        <tr className="show-property-tr pl-sm-2 pl-md-5 pl-lg-2">
                          <td>
                            <span className="padding-telegram ">
                              {' '}
                              <React.Fragment>
                                <FontAwesomeIcon
                                  icon={['fab', 'telegram']}
                                  color="#0088CC"
                                />
                                <span className="show-title">{` تلغرام `}</span>
                              </React.Fragment>
                            </span>
                          </td>
                          <td style={{ textAlign: 'right' }}>
                            {' '}
                            {agency_telegram !== '' &&
                            agency_telegram !== undefined &&
                            agency_telegram !== null ? (
                              <React.Fragment>
                                {` `}
                                {/* تيلغرام: */}
                                <a
                                  href={`tel:${agency_telegram}`}
                                  // href={`https://telegram.me/share/url?url=${``}text=${encodeURI(
                                  //   `مرحباً, أرجو التواصل معي بخصوص العقار: ${title} - ${business_offer}- Ikar.sy:<http://ikar.sy/properties/${reference_id}>`
                                  // )}&t=${agency_telegram}`}
                                  // {`https://t.me/share/${agency_whatsapp.replace(
                                  //   '00',
                                  //   ''
                                  // )}?text='ssss'`}
                                  // href={`https://wa.me/${agency_whatsapp.replace(
                                  //   '00',
                                  //   ''
                                  // )}?text=${encodeURI(
                                  //   `مرحباً, أرجو التواصل معي بخصوص العقار: ${title} - ${business_offer}- Ikar.sy:<http://ikar.sy/properties/${reference_id}>`
                                  // )}`}
                                  // onClick="ga('send', 'event', { eventCategory: 'Contact', eventAction: 'Call', eventLabel: 'Mobile Button'});"
                                  style={{ color: '#f18805' }}
                                  onClick={this.onTelClick.bind(
                                    this,
                                    reference_id
                                  )}
                                >
                                  <span className="show-property-link">
                                    {agency_telegram.replace('00963', '0')}
                                  </span>
                                  {/* {` `}
                              {mediumScreen ? (
                                <span className="show-property-whatsapp">
                                  {` انقر ودردش `}
                                </span>
                              ) : (
                                <span className="show-property-whatsapp">
                                  {` دردش `}
                                </span>
                              )} */}
                                  {/* {` `}
                              <span className="show-property-whatsapp">
                                {` دردش تلغرام `}
                              </span> */}
                                </a>
                              </React.Fragment>
                            ) : null}
                          </td>
                        </tr>
                      ) : null}
                    </tbody>
                  </table>
                  <div className="text-right">
                    <button
                      className="btn btn-link ml-2 tooltipicon2"
                      onClick={this.onGoBack}
                      disabled={this.state.hideContact}
                    >
                      <span>
                        <FontAwesomeIcon
                          // className=" "
                          icon={['fas', 'share']}
                        ></FontAwesomeIcon>
                        <span className="tooltiptext">
                          <div style={{ fontSize: 12 }}>
                            العودة للصفحة السابقة
                          </div>
                        </span>
                      </span>
                    </button>
                    <SearchCategories
                      type={type}
                      business_offer={business_offer}
                      province={province}
                      area={area}
                      disabled={this.state.hideContact}
                    />

                    <button
                      class="btn btn-social btn-facebook"
                      onClick={this.openFacebookDialog}
                      disabled={this.state.hideContact}
                    >
                      <FontAwesomeIcon
                        icon={['fab', 'facebook']}
                        size="2x"
                        style={{ color: '#3b5998' }}
                      />
                    </button>
                  </div>

                  <div className="row mb-3">
                    <div className="col-md-12">
                      <div className="text-right mx-1">
                        <div>
                          <span className="property-title-3x">
                            {business_offer} ،
                          </span>

                          <span className="property-title-3x">{type}</span>
                          {(this.props.isLoggedIn &&
                            !this.props.ikarAdmin &&
                            this.props.agencyRefId ===
                              this.state.agency_ref_id) ||
                          (this.props.isLoggedIn &&
                            this.props.ikarAdmin &&
                            this.props.isTakingAgencyRole &&
                            this.props.takenAgencyRoleAgencyRefId ===
                              this.state.agency_ref_id) ? (
                            <Link
                              to={`/dashBoard/edit_property?property_id=${this.state.reference_id}`}
                            >
                              <button
                                // icon="fa fa-edit"
                                //iconPos="right"
                                className="btn btn-warning py-0 my-0 mx-2"
                                onClick={this.onEditProperty.bind(
                                  this,
                                  this.state.reference_id,
                                  'edit'
                                )}
                                disabled={this.state.hideContact}
                              >
                                تعديل العقار
                                <FontAwesomeIcon
                                  icon={['fas', 'edit']}
                                ></FontAwesomeIcon>
                              </button>
                            </Link>
                          ) : this.props.isLoggedIn &&
                            this.props.ikarAdmin &&
                            !this.props.isTakingAgencyRole ? (
                            <Link
                              to={`/adminDashBoard/edit_property?property_id=${this.state.reference_id}`}
                            >
                              <button
                                // icon="fa fa-edit"
                                //iconPos="right"
                                className="btn btn-primary py-0 my-0 mx-2"
                                onClick={this.onEditProperty.bind(
                                  this,
                                  this.state.reference_id,
                                  'edit'
                                )}
                              >
                                تعديل العقار
                                <FontAwesomeIcon
                                  icon={['fas', 'edit']}
                                ></FontAwesomeIcon>
                              </button>
                            </Link>
                          ) : null}
                          {temp_status !== null &&
                          temp_status !== undefined &&
                          temp_status === 'سعر لقطة' ? (
                            <div className="business-offer-div-outer">
                              <div className="business-offer-div-11  m-1 mr-0 py-1 no_select mx-auto">
                                <span>
                                  عقار لقطة
                                  {/* {` ${temp_status} `} */}
                                </span>
                              </div>
                            </div>
                          ) : null}
                        </div>

                        <h1 className="property-title-2x">{title}</h1>
                        <div>
                          {price_upon_review ||
                          price === '' ||
                          Number(price) === 0 ? (
                            <span>السعر عند الإتصال</span>
                          ) : price > 0 ? (
                            <React.Fragment>
                              السعر:
                              <CurrencyFormat
                                value={price}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={' '}
                                className="display_6"
                              />
                              <span className="display_8">{` ${pricing_type}`}</span>
                            </React.Fragment>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      {this.state.hideContact ? null : created_at !==
                          undefined && created_at !== null ? (
                        // me
                        <React.Fragment>
                          {/* {sell_plan_id !== undefined && sell_plan_id !== null ? (
                        <div className="text-right mx-1">
                          <span className="display_7">رقم العرض:</span>
                          {` `}
                          <div
                            className="display_7"
                            style={{ display: 'inline-block' }}
                          >
                            {sell_plan_id}
                          </div>
                        </div>
                      ) : null} */}
                          {/* <div className="text-right mx-1">
                        <span className="display_7">تاريخ العرض:</span>
                        {` `}
                        <div
                          className="display_7"
                          style={{ display: 'inline-block' }}
                        >
                          {created_at.slice(0, 10)}
                        </div>
                      </div> */}
                        </React.Fragment>
                      ) : null}
                    </div>
                  </div>

                  {this.state.hideContact &&
                  this.props.source !== 'add' &&
                  this.state.thumbnails2 !== undefined &&
                  this.state.thumbnails2.length > 0 ? (
                    <div className="text-right ">
                      <span
                        style={{
                          color: '#fcb814',
                          weight: '400',
                          size: '1rem',
                          padding: '0px 5px',
                        }}
                      >
                        {
                          ' لا تظهر الصور الحالية للعقار في هذه الصفحة ولكنها ستظهر عند نشر العقار '
                        }
                      </span>
                    </div>
                  ) : null}
                  {/* <div className="text-right no_select">
                <span className="property-title-2x">{title}</span>
              </div> */}
                  {gps_location !== null &&
                  gps_location !== undefined &&
                  gps_location !== '' &&
                  mapsEnabled ? (
                    <div className="row">
                      {url !== null && url !== undefined && url.length > 0 ? (
                        <div className="horizontal-container-outer mx-auto mb-4">
                          {url !== null &&
                          url !== undefined &&
                          url.length > 3 ? (
                            <React.Fragment>
                              <button
                                className="horizontal-container-right-button"
                                onClick={this.rightButtonClick}
                              >
                                <FontAwesomeIcon
                                  icon={['fas', 'angle-double-right']}
                                  size="2x"
                                  color="#fcb814"
                                ></FontAwesomeIcon>
                              </button>
                              <button
                                className="horizontal-container-left-button"
                                onClick={this.leftButtonClick}
                              >
                                <FontAwesomeIcon
                                  icon={['fas', 'angle-double-left']}
                                  size="2x"
                                  color="#fcb814"
                                ></FontAwesomeIcon>
                              </button>
                            </React.Fragment>
                          ) : null}
                          <div className="row px-2 mx-2">
                            {/* <div className="col-6 text-right h-c-title">
                          <span style={{ color: "#581f18" }}>صور العقار</span>
                        </div> */}
                          </div>
                          <div
                            className="show-property-horizontal-container"
                            ref={this.horizontalContainerRef}
                          >
                            {/* style={{ overflowX: 'auto', width: '100%' }} */}
                            <table
                              style={{
                                overflowX: 'auto',
                                width: '100%',
                                tableLayout: 'fixed',
                              }}
                            >
                              <tbody>
                                <tr className="show-property-tr pl-sm-2 pl-md-5 pl-lg-2">
                                  {(thumbnails || []).map(
                                    (thumbnail, index) => (
                                      <React.Fragment key={shortid.generate()}>
                                        {index === 0 ? (
                                          <React.Fragment>
                                            <td
                                              className="text-right"
                                              rowSpan="2"
                                              style={{ width: '350px' }}
                                            >
                                              {this.state.hideContact ? (
                                                <img
                                                  src={`${thumbnail}`}
                                                  alt={buildAlt(this.state.title,this.state.type,this.state.business_offer,this.state.province,
                                                    this.state.area, this.state.furnitured,this.state.duplex,this.state.basement,this.state.lift,
                                                   this.state.parking,this.state.garage,thumbnail )}
                                                  className="img-show-property-thumbnail-first"
                                                  // onClick={() =>
                                                  //   this.setState({
                                                  //     photoIndex: index,
                                                  //     isOpen: true
                                                  //   })
                                                  // }
                                                />
                                              ) : (
                                                <img
                                                  key={shortid.generate()}
                                                  src={`${imageDomain}/${thumbnail}`}
                                                  alt={buildAlt(this.state.title,this.state.type,this.state.business_offer,this.state.province,
                                                    this.state.area, this.state.furnitured,this.state.duplex,this.state.basement,this.state.lift,
                                                   this.state.parking,this.state.garage,thumbnail )}
                                                  className="img-show-property-thumbnail-first"
                                                  onClick={() =>
                                                    this.setState({
                                                      photoIndex: index,
                                                      isOpen: true,
                                                      alt:buildAlt(this.state.title,this.state.type,this.state.business_offer,this.state.province,
                                                        this.state.area, this.state.furnitured,this.state.duplex,this.state.basement,this.state.lift,
                                                       this.state.parking,this.state.garage,thumbnail )
                                                    })
                                                  }
                                                />
                                              )}
                                            </td>
                                            <td
                                              className="text-right"
                                              rowSpan="2"
                                              style={{ width: '250px' }}
                                            >
                                              <div className="col-3 show-property-map">
                                                {mapsEnabled &&
                                                !showCurrentLocation ? (
                                                  <Map
                                                    center={position}
                                                    zoom={this.state.zoom}
                                                    style={{
                                                      width: '100%',
                                                      minHeight: '100%',
                                                    }}
                                                    onClick={
                                                      this.onMapShowClick
                                                    }
                                                  >
                                                    <TileLayer
                                                      attribution='<a href="http://ikar.sy">Ikar.sy</a> &amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                    />
                                                    <Marker position={position}>
                                                      <Popup>
                                                        <span className="display_8">
                                                          {title}
                                                        </span>
                                                      </Popup>
                                                    </Marker>
                                                  </Map>
                                                ) : (
                                                  noInternetMessage
                                                )}
                                              </div>
                                            </td>
                                          </React.Fragment>
                                        ) : index <=
                                          Math.ceil(thumbnails.length / 2) -
                                            1 ? (
                                          <td
                                            className="text-right"
                                            style={{ width: '200px' }}
                                          >
                                            {this.state.hideContact ? (
                                              <img
                                                key={shortid.generate()}
                                                src={`${thumbnail}`}
                                                alt={buildAlt(this.state.title,this.state.type,this.state.business_offer,this.state.province,
                                                  this.state.area, this.state.furnitured,this.state.duplex,this.state.basement,this.state.lift,
                                                 this.state.parking,this.state.garage,thumbnail )}
                                                className="img-show-property-thumbnail"
                                                // onClick={() =>
                                                //   this.setState({
                                                //     photoIndex: index,
                                                //     isOpen: true
                                                //   })
                                                // }
                                              />
                                            ) : (
                                              <img
                                                key={shortid.generate()}
                                                src={`${imageDomain}/${thumbnail}`}
                                                alt={buildAlt(this.state.title,this.state.type,this.state.business_offer,this.state.province,
                                                  this.state.area, this.state.furnitured,this.state.duplex,this.state.basement,this.state.lift,
                                                 this.state.parking,this.state.garage ,thumbnail)}
                                                className="img-show-property-thumbnail"
                                                onClick={() =>
                                                  this.setState({
                                                    photoIndex: index,
                                                    isOpen: true,
                                                    alt:buildAlt(this.state.title,this.state.type,this.state.business_offer,this.state.province,
                                                      this.state.area, this.state.furnitured,this.state.duplex,this.state.basement,this.state.lift,
                                                     this.state.parking,this.state.garage,thumbnail)
                                                  })
                                                }
                                              />
                                            )}
                                          </td>
                                        ) : null}
                                        {/* index === Math.ceil(thumbnails.length / 2)-1 ? (
                                     <td></td>
                                   ) : null} */}
                                      </React.Fragment>
                                    )
                                  )}
                                  {thumbnails.length % 2 === 0 ? (
                                    <td
                                      className="text-right"
                                      style={{ width: '200px' }}
                                    ></td>
                                  ) : null}
                                </tr>
                                <tr className="show-property-tr pl-sm-2 pl-md-5 pl-lg-2">
                                  {(thumbnails || []).map(
                                    (thumbnail, index) => (
                                      <React.Fragment key={shortid.generate()}>
                                        {index >
                                        Math.ceil(thumbnails.length / 2) - 1 ? (
                                          <td
                                            className="text-right"
                                            style={{ width: '200px' }}
                                          >
                                            {this.state.hideContact ? (
                                              <img
                                                key={shortid.generate()}
                                                src={`${thumbnail}`}
                                                alt={buildAlt(this.state.title,this.state.type,this.state.business_offer,this.state.province,
                                                  this.state.area, this.state.furnitured,this.state.duplex,this.state.basement,this.state.lift,
                                                 this.state.parking,this.state.garage,thumbnail )}
                                                className="img-show-property-thumbnail"
                                                // onClick={() =>
                                                //   this.setState({
                                                //     photoIndex: index,
                                                //     isOpen: true
                                                //   })
                                                // }
                                              />
                                            ) : (
                                              <img
                                                key={shortid.generate()}
                                                src={`${imageDomain}/${thumbnail}`}
                                                alt={buildAlt(this.state.title,this.state.type,this.state.business_offer,this.state.province,
                                                  this.state.area, this.state.furnitured,this.state.duplex,this.state.basement,this.state.lift,
                                                 this.state.parking,this.state.garage,thumbnail )}
                                                className="img-show-property-thumbnail"
                                                onClick={() =>
                                                  this.setState({
                                                    photoIndex: index,
                                                    isOpen: true,
                                                    alt:buildAlt(this.state.title,this.state.type,this.state.business_offer,this.state.province,
                                                      this.state.area, this.state.furnitured,this.state.duplex,this.state.basement,this.state.lift,
                                                     this.state.parking,this.state.garage,thumbnail ) 
                                                  })
                                                }
                                              />
                                            )}
                                          </td>
                                        ) : null}
                                      </React.Fragment>
                                    )
                                  )}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="col-12 mb-4"
                          style={{ height: '350px' }}
                        >
                          {mapsEnabled && !showCurrentLocation ? (
                            <Map
                              center={position}
                              zoom={this.state.zoom}
                              style={{
                                width: '100%',
                                minHeight: '100%',
                              }}
                              onClick={this.onMapShowClick}
                            >
                              <TileLayer
                                attribution='<a href="http://ikar.sy">Ikar.sy</a> &amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                              />
                              <Marker position={position}>
                                <Popup>
                                  <span className="display_8">{title}</span>
                                </Popup>
                              </Marker>
                            </Map>
                          ) : (
                            noInternetMessage
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="row">
                      {url !== null && url !== undefined && url.length > 0 ? (
                        <div className="horizontal-container-outer mx-auto mb-4">
                          {url !== null &&
                          url !== undefined &&
                          url.length > 3 ? (
                            <React.Fragment>
                              <button
                                className="horizontal-container-right-button"
                                onClick={this.rightButtonClick}
                              >
                                <FontAwesomeIcon
                                  icon={['fas', 'angle-double-right']}
                                  size="2x"
                                  color="#fcb814"
                                ></FontAwesomeIcon>
                              </button>
                              <button
                                className="horizontal-container-left-button"
                                onClick={this.leftButtonClick}
                              >
                                <FontAwesomeIcon
                                  icon={['fas', 'angle-double-left']}
                                  size="2x"
                                  color="#fcb814"
                                ></FontAwesomeIcon>
                              </button>
                            </React.Fragment>
                          ) : null}
                          <div className="row px-2 mx-2">
                            {/* <div className="col-6 text-right h-c-title">
                          <span style={{ color: "#581f18" }}>صور العقار</span>
                        </div> */}
                          </div>
                          <div
                            className="show-property-horizontal-container"
                            ref={this.horizontalContainerRef}
                          >
                            {/* style={{ overflowX: 'auto', width: '100%' }} */}
                            <table
                              style={{
                                overflowX: 'auto',
                                width: '100%',
                                tableLayout: 'fixed',
                              }}
                            >
                              <tbody>
                                <tr className="show-property-tr pl-sm-2 pl-md-5 pl-lg-2">
                                  {(thumbnails || []).map(
                                    (thumbnail, index) => (
                                      <React.Fragment key={shortid.generate()}>
                                        {index === 0 ? (
                                          <React.Fragment>
                                            <td
                                              className="text-right"
                                              rowSpan="2"
                                              style={{ width: '350px' }}
                                            >
                                              {this.state.hideContact ? (
                                                <img
                                                  src={`${thumbnail}`}
                                                  alt={buildAlt(this.state.title,this.state.type,this.state.business_offer,this.state.province,
                                                    this.state.area, this.state.furnitured,this.state.duplex,this.state.basement,this.state.lift,
                                                   this.state.parking,this.state.garage ,thumbnail)}
                                                  className="img-show-property-thumbnail-first"
                                                  // onClick={() =>
                                                  //   this.setState({
                                                  //     photoIndex: index,
                                                  //     isOpen: true
                                                  //   })
                                                  // }
                                                />
                                              ) : (
                                                <img
                                                  src={`${imageDomain}/${thumbnail}`}
                                                  alt={buildAlt(this.state.title,this.state.type,this.state.business_offer,this.state.province,
                                                    this.state.area, this.state.furnitured,this.state.duplex,this.state.basement,this.state.lift,
                                                   this.state.parking,this.state.garage,thumbnail )}
                                                  className="img-show-property-thumbnail-first"
                                                  onClick={() =>
                                                    this.setState({
                                                      photoIndex: index,
                                                      isOpen: true,
                                                      alt:buildAlt(this.state.title,this.state.type,this.state.business_offer,this.state.province,
                                                        this.state.area, this.state.furnitured,this.state.duplex,this.state.basement,this.state.lift,
                                                       this.state.parking,this.state.garage,thumbnail )
                                                    })
                                                  }
                                                />
                                              )}
                                            </td>
                                          </React.Fragment>
                                        ) : index <=
                                          Math.ceil(thumbnails.length / 2) -
                                            1 ? (
                                          <td
                                            className="text-right"
                                            style={{ width: '200px' }}
                                          >
                                            {this.state.hideContact ? (
                                              <img
                                                key={shortid.generate()}
                                                src={`${thumbnail}`}
                                                alt={buildAlt(this.state.title,this.state.type,this.state.business_offer,this.state.province,
                                                  this.state.area, this.state.furnitured,this.state.duplex,this.state.basement,this.state.lift,
                                                 this.state.parking,this.state.garage,thumbnail )}
                                                className="img-show-property-thumbnail"
                                                // onClick={() =>
                                                //   this.setState({
                                                //     photoIndex: index,
                                                //     isOpen: true
                                                //   })
                                                // }
                                              />
                                            ) : (
                                              <img
                                                key={shortid.generate()}
                                                src={`${imageDomain}/${thumbnail}`}
                                                alt={buildAlt(this.state.title,this.state.type,this.state.business_offer,this.state.province,
                                                  this.state.area, this.state.furnitured,this.state.duplex,this.state.basement,this.state.lift,
                                                 this.state.parking,this.state.garage ,thumbnail)}
                                                className="img-show-property-thumbnail"
                                                onClick={() =>
                                                  this.setState({
                                                    photoIndex: index,
                                                    isOpen: true,
                                                    alt:buildAlt(this.state.title,this.state.type,this.state.business_offer,this.state.province,
                                                      this.state.area, this.state.furnitured,this.state.duplex,this.state.basement,this.state.lift,
                                                     this.state.parking,this.state.garage,thumbnail )
                                                  })
                                                }
                                              />
                                            )}
                                          </td>
                                        ) : null}
                                      </React.Fragment>
                                    )
                                  )}
                                  {thumbnails.length % 2 === 0 ? (
                                    <td
                                      className="text-right"
                                      style={{ width: '200px' }}
                                    ></td>
                                  ) : null}
                                </tr>
                                <tr className="show-property-tr pl-sm-2 pl-md-5 pl-lg-2">
                                  {(thumbnails || []).map(
                                    (thumbnail, index) => (
                                      <React.Fragment key={shortid.generate()}>
                                        {index >
                                        Math.ceil(thumbnails.length / 2) - 1 ? (
                                          <td
                                            className="text-right"
                                            style={{ width: '200px' }}
                                          >
                                            {this.state.hideContact ? (
                                              <img
                                                key={shortid.generate()}
                                                src={`${thumbnail}`}
                                                alt={buildAlt(this.state.title,this.state.type,this.state.business_offer,this.state.province,
                                                  this.state.area, this.state.furnitured,this.state.duplex,this.state.basement,this.state.lift,
                                                 this.state.parking,this.state.garage, thumbnail)}
                                                className="img-show-property-thumbnail"
                                                // onClick={() =>
                                                //   this.setState({
                                                //     photoIndex: index,
                                                //     isOpen: true
                                                //   })
                                                // }
                                              />
                                            ) : (
                                              <img
                                                key={shortid.generate()}
                                                src={`${imageDomain}/${thumbnail}`}
                                                alt={buildAlt(this.state.title,this.state.type,this.state.business_offer,this.state.province,
                                                  this.state.area, this.state.furnitured,this.state.duplex,this.state.basement,this.state.lift,
                                                 this.state.parking,this.state.garage ,thumbnail)}
                                                className="img-show-property-thumbnail"
                                                onClick={() =>
                                                  this.setState({
                                                    photoIndex: index,
                                                    isOpen: true,
                                                    alt:buildAlt(this.state.title,this.state.type,this.state.business_offer,this.state.province,
                                                      this.state.area, this.state.furnitured,this.state.duplex,this.state.basement,this.state.lift,
                                                     this.state.parking,this.state.garage ,thumbnail)
                                                  })
                                                }
                                              />
                                            )}
                                          </td>
                                        ) : null}
                                      </React.Fragment>
                                    )
                                  )}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  )}
                  <div className="row">
                    <div className="col-lg-10 col-sm-12">
                      <div className="row">
                        {isOpen && (
                          <Lightbox
                            mainSrc={`${imageDomain}/${url[photoIndex]}`}
                            nextSrc={`${imageDomain}/${
                              url[(photoIndex + 1) % url.length]
                            }`}
                            prevSrc={`${imageDomain}/${
                              url[(photoIndex + url.length - 1) % url.length]
                            }`}
                            onCloseRequest={() =>
                              this.setState({ isOpen: false })
                            }
                            onMovePrevRequest={() =>
                              this.setState({
                                photoIndex:
                                  (photoIndex + url.length - 1) % url.length,
                              })
                            }
                            onMoveNextRequest={() =>
                              this.setState({
                                photoIndex: (photoIndex + 1) % url.length,
                              })
                            }
                            imageTitle={alt}
                          />
                        )}
                      </div>
                   
                      <div className="text-right mb-2">
                      {url !== undefined && url !== null &&url.length>0 ?(
                      <a href={`${linkDomain}/properties/${reference_id}/gallery`}> عرض الصور </a>):null}
                      </div>
                      <div className="show-property-details mb-2">
                        <h2 className="text-center mb-2 show-title">
                          تفاصيل العرض
                        </h2>
                        <div className=" col-12 px-sm-2 px-md-5 px-lg-2 ">
                          <div
                            style={{ paddingRight: '10px', textAlign: 'right' }}
                          >
                            {description}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6 mt-3">
                            <table className="show-property-table">
                              <thead></thead>
                              <colgroup>
                                <col style={{ width: '160px' }} />
                                <col style={{ width: 'auto' }} />
                              </colgroup>
                              <tbody>
                                {/* <tr>
                              <td className="text-right text-md-center">
                                <span className="sub-title">تفاصيل العرض</span>
                              </td>
                            </tr> */}
                                {/* <tr className=" pl-sm-2 pl-md-5 pl-lg-2">
                            <td
                              colSpan="2"
                              className="text-center show-property-td"
                            >
                              <span className="display_6">تفاصيل العقار</span>
                            </td>
                          </tr>
                          <tr className="show-property-tr pl-sm-2 pl-md-5 pl-lg-2">
                            <td
                              colSpan="2"
                              className="show-property-td"
                              style={{ paddingRight: '10px' }}
                            >
                              {description}
                            </td>
                          </tr>{' '}
                          */}
   
                                {rented !== undefined &&
                                rented !== null &&
                                rented === true &&
                                rent_end !== undefined ? (
                                  <tr className="show-property-tr pl-sm-2 pl-md-5 pl-lg-2">
                                    <td className="show-property-td">
                                      <span className="display_7 text-danger">
                                        العقار مؤجر:
                                      </span>{' '}
                                    </td>
                                    <td>
                                      حتى تاريخ{' '}
                                      <div
                                        className="text-right"
                                        style={{ display: 'inline-block' }}
                                      >
                                        {rent_end}
                                      </div>
                                    </td>
                                  </tr>
                                ) : null}
                                {/* //me */}
                                <tr className="show-property-tr pl-sm-2 pl-md-5 pl-lg-2">
                                  <td className="show-property-td">
                                    <span className="display_7">
                                      تاريخ العرض:
                                    </span>
                                  </td>

                                  <td colSpan="2">
                                    {created_at !== undefined &&
                                    created_at !== null
                                      ? created_at.slice(0, 10)
                                      : null}
                                  </td>
                                </tr>
                                <tr className="show-property-tr pl-sm-2 pl-md-5 pl-lg-2">
                                  <td className="show-property-td">
                                    <span className="display_7">المحافظة:</span>
                                  </td>
                                  <td colSpan="2">{province}</td>
                                </tr>
                                {(area!==null && area!==undefined && area!=='غير محدد')?
                                <tr className="show-property-tr pl-sm-2 pl-md-5 pl-lg-2">
                                  <td className="show-property-td">
                                    <span className="display_7">المنطقة:</span>
                                  </td>
                                  <td colSpan="2">{area}</td>
                                </tr>:null}
                                {/* <tr className="show-property-tr pl-sm-2 pl-md-5 pl-lg-2">
                                  <td className="show-property-td">
                                    <span className="display_7">المدينة:</span>
                                  </td>
                                  <td colSpan="2">{city}</td>
                                </tr> */}
                                {street !== null &&
                                street !== undefined &&
                                street !== '' ? (
                                  <tr className="show-property-tr pl-sm-2 pl-md-5 pl-lg-2">
                                    <td className="show-property-td">
                                      <span className="display_7">
                                        العنوان العام:
                                      </span>{' '}
                                    </td>
                                    <td colSpan="2">{street}</td>
                                  </tr>
                                ) : null}
                                <tr className="show-property-tr pl-sm-2 pl-md-5 pl-lg-2">
                                  <td className="show-property-td">
                                    <span className="display_7">
                                      نوع الملكية:
                                    </span>
                                  </td>
                                  <td colSpan="2">{type_of_ownership}</td>
                                </tr>
                                {status !== null &&
                                status !== undefined &&
                                status !== '' &&
                                type !== 'أراضي زراعية' &&
                                type !== 'أرض للبناء' &&
                                type !== 'أراضي ذات رخصة سياحية' &&
                                type !== 'أراضي ذات رخصة صناعية' ? (
                                  <tr className="show-property-tr pl-sm-2 pl-md-5 pl-lg-2">
                                    <td className="show-property-td">
                                      <span className="display_7">
                                        حالة البناء:
                                      </span>
                                    </td>
                                    <td colSpan="2">{status}</td>
                                  </tr>
                                ) : null}
                                {view_direction !== undefined &&
                                view_direction !== null &&
                                view_direction !== '' &&
                                view_direction !== 'غير محدد' ? (
                                  <tr className="show-property-tr pl-sm-2 pl-md-5 pl-lg-2">
                                    <td className="show-property-td">
                                      <span className="display_7">
                                        اتجاه العقار:
                                      </span>{' '}
                                    </td>
                                    <td colSpan="2">{view_direction}</td>
                                  </tr>
                                ) : null}
                                {total_area !== null &&
                                total_area !== undefined &&
                                total_area !== 0 &&
                                parseInt(total_area) > 0 &&
                                (parseInt(this.state.living_area) > 0 ||
                                  parseInt(this.state.roof_building_area) >
                                    0) ? (
                                  <tr className="show-property-tr pl-sm-2 pl-md-5 pl-lg-2">
                                    <td className="show-property-td">
                                      <span className="display_7">
                                        المساحة الكلية:
                                      </span>{' '}
                                    </td>
                                    <td colSpan="2">
                                      {total_area !== '' &&
                                      parseInt(total_area) > 0
                                        ? `${total_area} م2`
                                        : ''}
                                      {ground_area !== '' &&
                                      ground_area >= 0 ? (
                                        <React.Fragment>
                                          {ground_area > 0 ? (
                                            <span
                                              className={classnames(
                                                'display_7',
                                                {
                                                  'mr-4':
                                                    ground_area !== '' &&
                                                    parseInt(ground_area) > 0,
                                                }
                                              )}
                                            >
                                              {` تتضمن حديقة  ${ground_area} م2 `}
                                            </span>
                                          ) : (
                                            ''
                                          )}
                                        </React.Fragment>
                                      ) : null}
                                    </td>
                                  </tr>
                                ) : ground_area !== '' &&
                                  parseInt(ground_area) > 0 ? (
                                  <tr className="show-property-tr pl-sm-2 pl-md-5 pl-lg-2">
                                    <td className="show-property-td">
                                      <span className="display_7">
                                        المساحة الكلية:
                                      </span>{' '}
                                    </td>
                                    <td colSpan="2">
                                      {/* {`${total_area} م2`} */}

                                      <React.Fragment>
                                        {ground_area > 0 ? (
                                          <span
                                            className={classnames('display_7', {
                                              'mr-4':
                                                ground_area !== '' &&
                                                parseInt(ground_area) > 0,
                                            })}
                                          >
                                            {` تتضمن حديقة  ${ground_area} م2 `}
                                          </span>
                                        ) : (
                                          ''
                                        )}
                                      </React.Fragment>
                                    </td>
                                  </tr>
                                ) : null}
                                {roof_building_area !== null &&
                                roof_building_area !== undefined &&
                                roof_building_area !== 0 &&
                                roof_building_area !== '' ? (
                                  <tr className="show-property-tr pl-sm-2 pl-md-5 pl-lg-2">
                                    <td className="show-property-td">
                                      <span className="display_7">
                                        المساحة الصالحة للبناء:
                                      </span>{' '}
                                    </td>
                                    <td colSpan="2">{`${roof_building_area} م2`}</td>
                                  </tr>
                                ) : null}
                                {built_at !== null &&
                                built_at !== undefined &&
                                built_at !== '' ? (
                                  <tr className="show-property-tr pl-sm-2 pl-md-5 pl-lg-2">
                                    <td className="show-property-td">
                                      <span className="display_7">
                                        تاريخ البناء:
                                      </span>{' '}
                                    </td>
                                    <td colSpan="2">
                                      <div
                                        className="text-right"
                                        style={{ display: 'inline-block' }}
                                      >
                                        {built_at}
                                      </div>
                                    </td>
                                  </tr>
                                ) : null}
                                {reconstructed_at !== null &&
                                reconstructed_at !== undefined &&
                                reconstructed_at !== '' ? (
                                  <tr className="show-property-tr pl-sm-2 pl-md-5 pl-lg-2">
                                    <td className="show-property-td">
                                      <span className="display_7">
                                        تاريخ اعادة البناء:
                                      </span>{' '}
                                    </td>
                                    <td colSpan="2">
                                      <div
                                        className="text-right"
                                        style={{ display: 'inline-block' }}
                                      >
                                        {reconstructed_at}
                                      </div>
                                    </td>


                                  </tr>
                                ) : null}
                              </tbody>
                            </table>
                          </div>
                          <div className="col-md-6 mt-3">
                            {icons ? (
                              <React.Fragment>
                                <table className="show-property-table">
                                  <thead></thead>
                                  <colgroup>
                                    <col style={{ width: '160px' }} />
                                    <col style={{ width: 'auto' }} />
                                  </colgroup>
                                  <tbody>
                                    {/* <tr>
                                  <td className="text-right text-md-center">
                                    <span className="sub-title">
                                      ميزات العرض
                                    </span>
                                  </td>
                                </tr> */}

                                    {bedrooms !== null &&
                                    bedrooms !== undefined &&
                                    bedrooms !== 0 ? (
                                      <tr className="show-property-tr pl-sm-2 pl-md-5 pl-lg-2">
                                        <td className="show-property-td text-right">
                                          {type === 'مقهى' ||
                                          type === 'مكتب' ||
                                          type === 'عيادة' ||
                                          type === 'مطعم' ||
                                          type === 'منشأة تصنيع' ||
                                          type === 'محطة وقود' ||
                                          type === 'محلات تجارية' ||
                                          type === 'محلات اصلاح' ||
                                          type === 'محلات لتربية الحيوانات' ? (
                                            <FontAwesomeIcon
                                              className=" ml-2"
                                              icon={['fas', 'door-open']}
                                              color="#fcb814"
                                            />
                                          ) : (
                                            <FontAwesomeIcon
                                              className=" ml-2"
                                              icon={['fas', 'bed']}
                                              color="#fcb814"
                                            />
                                          )}

                                          <span className="display_7">
                                            {type === 'مقهى' ||
                                            type === 'مكتب' ||
                                            type === 'عيادة' ||
                                            type === 'مطعم' ||
                                            type === 'منشأة تصنيع' ||
                                            type === 'محطة وقود' ||
                                            type === 'محلات تجارية' ||
                                            type === 'محلات اصلاح' ||
                                            type ===
                                              'محلات لتربية الحيوانات' ? (
                                              <span>عدد الغرف:</span>
                                            ) : (
                                              <span>غرف النوم:</span>
                                            )}
                                          </span>
                                        </td>
                                        <td>
                                          <span
                                            className="text-right"
                                            style={{ display: 'inline-block' }}
                                          >
                                            {` ${bedrooms} `}
                                          </span>
                                        </td>
                                      </tr>
                                    ) : null}
                                    {living_rooms !== null &&
                                    living_rooms !== undefined &&
                                    living_rooms !== 0 ? (
                                      <tr className="show-property-tr pl-sm-2 pl-md-5 pl-lg-2 ">
                                        <td className="show-property-td text-right">
                                          <FontAwesomeIcon
                                            className=" ml-2"
                                            icon={['fas', 'chair']}
                                            color="#fcb814"
                                          />

                                          <span className="display_7">
                                            غرف الصالون:
                                          </span>
                                        </td>
                                        <td>
                                          <span
                                            className="text-right"
                                            style={{ display: 'inline-block' }}
                                          >
                                            {` ${living_rooms} `}
                                          </span>
                                        </td>
                                      </tr>
                                    ) : null}
                                    {bathrooms !== null &&
                                    bathrooms !== undefined &&
                                    bathrooms !== 0 ? (
                                      <tr className="show-property-tr pl-sm-2 pl-md-5 pl-lg-2">
                                        <td className="show-property-td text-right">
                                          <FontAwesomeIcon
                                            className=" ml-2"
                                            icon={['fas', 'bath']}
                                            color="#fcb814"
                                          />
                                          <span className="display_7">
                                            غرف الاستحمام:
                                          </span>
                                        </td>
                                        <td className=" text-right">
                                          <span
                                            className="text-right"
                                            style={{ display: 'inline-block' }}
                                          >
                                            {` ${bathrooms} `}
                                          </span>
                                        </td>
                                      </tr>
                                    ) : null}

                                    {balcony !== null &&
                                    balcony !== undefined &&
                                    balcony !== 0 ? (
                                      <tr className="show-property-tr pl-sm-2 pl-md-5 pl-lg-2 ">
                                        <td className="show-property-td text-right">
                                          <FontAwesomeIcon
                                            className=" ml-2"
                                            icon={['fas', 'city']}
                                            color="#fcb814"
                                          />

                                          <span className="display_7">
                                            عدد البلكونات:
                                          </span>
                                        </td>

                                        <td className=" text-right">
                                          <span
                                            className="text-right"
                                            style={{ display: 'inline-block' }}
                                          >
                                            {` ${balcony} `}
                                          </span>
                                        </td>
                                      </tr>
                                    ) : null}

                                    {floor !== undefined &&
                                    floor !== null &&
                                    floor !== -100 ? (
                                      <tr className="show-property-tr pl-sm-2 pl-md-5 pl-lg-2 ">
                                        <td className="show-property-td text-right">
                                          <FontAwesomeIcon
                                            className=" ml-2"
                                            icon={['fas', 'building']}
                                            color="#fcb814"
                                          />

                                          <span className="display_7">
                                            {floor === 0 ||
                                            floor === -24 ||
                                            floor === -25 ||
                                            floor === -1 ||
                                            floor === -2 ||
                                            floor === -3
                                              ? `الطابق:`
                                              : `رقم الطابق:`}
                                          </span>
                                        </td>
                                        <td className=" text-right">
                                          <span
                                            className="text-right"
                                            style={{ display: 'inline-block' }}
                                          >
                                            {floor === 0
                                              ? ' أرضي '
                                              : floor === -24
                                              ? ' أرضي منخفض '
                                              : floor === -25
                                              ? ' نص قبو (قبو فايش) '
                                              : floor === -1 ||
                                                floor === -2 ||
                                                floor === -3
                                              ? `قبو ${floor} `
                                              : ` ${floor} `}
                                          </span>
                                        </td>
                                      </tr>
                                    ) : null}
                                  </tbody>
                                </table>

                                <div className="row">
                                  {garage !== null &&
                                  garage !== undefined &&
                                  garage === true ? (
                                    <div className="col-6 text-right py-2">
                                      <FontAwesomeIcon
                                        className=" ml-2 mr-1"
                                        icon={['fas', 'warehouse']}
                                        color="#fcb814"
                                      />

                                      <span className="display_7">له كراج</span>
                                    </div>
                                  ) : null}

                                  {furnitured !== null &&
                                  furnitured !== undefined &&
                                  furnitured === true ? (
                                    <div className="col-6  text-right py-2">
                                      <FontAwesomeIcon
                                        className=" ml-2 mr-1"
                                        icon={['fas', 'couch']}
                                        color="#fcb814"
                                      />

                                      <span className="display_7">مفروش</span>
                                    </div>
                                  ) : null}

                                  {parking !== null &&
                                  parking !== undefined &&
                                  parking === true ? (
                                    <div className="col-6  text-right py-2">
                                      <FontAwesomeIcon
                                        className=" ml-2 mr-1"
                                        icon={['fas', 'parking']}
                                        color="#fcb814"
                                      />

                                      <span className="display_7">له موقف</span>
                                    </div>
                                  ) : null}

                                  {duplex !== null &&
                                  duplex !== undefined &&
                                  duplex === true ? (
                                    <div className="col-6  text-right py-2">
                                      <FontAwesomeIcon
                                        className=" ml-2 mr-1"
                                        icon={['fas', 'clone']}
                                        color="#fcb814"
                                      />

                                      <span className="display_7">
                                        العقار دوبلكس
                                      </span>
                                    </div>
                                  ) : null}

                                  {lift !== null &&
                                  lift !== undefined &&
                                  lift === true ? (
                                    <div className="col-6 text-right py-2">
                                      <FontAwesomeIcon
                                        className=" ml-2 mr-1"
                                        icon={['fas', 'level-up-alt']}
                                        color="#fcb814"
                                      />

                                      <span className="display_7">له مصعد</span>
                                    </div>
                                  ) : null}

                                  {basement !== null &&
                                  basement !== undefined &&
                                  basement === true ? (
                                    <div className="col-6 text-right py-2">
                                      <FontAwesomeIcon
                                        className=" ml-2 mr-1"
                                        icon={['fas', 'archway']}
                                        color="#fcb814"
                                      />

                                      <span className="display_7">له ملجأ</span>
                                    </div>
                                  ) : null}

                        
                                </div>
                              </React.Fragment>
                            ) : (
                              <div className="text-center pt-5 mt-2">
                                <img
                                  src={logo}
                                  className="logo2"
                                  alt=""
                                  onClick={this.onMenuBtnClick}
                                />
                              </div>
                            )}


                          </div>
                        </div>

                        {this.state.hideContact &&
                        this.props.source ===
                          'add' ? null : agency_business_address !== '' &&
                          agency_business_address !== null &&
                          agency_business_address !== undefined ? (
                          largeScreen ? (
                            <div
                              className="row mb-3 round-div p-1 my-2   rounddiv"
                              style={{ direction: 'rtl', width: '100%' }}
                            >
                              <div
                                className="col-12 text-right"
                                style={{ direction: 'rtl' }}
                              >
                                <div className="row">
                                  <div className="col-md-5 text-right">
                                    <a
                                      href={`${linkDomain}/agency_profile/${agency_ref_id}`}
                                      className="mx-auto"
                                      style={{ color: '#f18805' }}
                                    >
                                      {/* <Link

                                  to={`/agency_profile/${agency_ref_id}`}
                                  className="mx-auto"
                                  style={{ color: '#f18805' }}
                                > */}
                                      <span
                                        className="show-title show-property-link"
                                        // style={{ color: '#f18805' }}
                                      >
                                        {' '}
                                        {agency_name}{' '}
                                      </span>
                                      {/* </Link> */}
                                    </a>
                                  </div>

                                  <div className="col-md-7 text-right">
                                    <span className="display_7">
                                      {' '}
                                      {agency_business_address}{' '}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-10 col-sm-12"></div>
                    {this.state.hideContact ? null : (
                      <ContactAgency
                        agency_ref_id={agency_ref_id}
                        property_ref_id={reference_id}
                        agency_name={agency_name}
                        agency_mobile={agency_mobile}
                        agency_business_address={agency_business_address}
                        showMessage={this.showMessage}
                        title={title}
                        business_offer={business_offer}
                        shortHeader={shortHeader}
                        hideContact={this.state.hideContact}
                      ></ContactAgency>
                    )}
                    <div
                      id="search_bar_section_id"
                      className="text-center col-lg-10 col-xl-8 col-12 m-auto"
                    >
                      <SearchBar
                        searchBarFullShow={this.state.searchBarFullShow}
                        searchType="main_search"
                        destination="/search"
                        urlFilters="false"
                        searchMode="off"
                      ></SearchBar>
                    </div>
                  </div>
                </React.Fragment>
              ) : resType === 'not complete' ? (
                errors.visible === 'هذه الصفحة غير متوفرة حالياً' ? (
                  <div className="text-center">
                    <span className="text-warning">
                      هذه الصفحة غير متوفرة حالياً
                    </span>
                  </div>
                ) : (
                  <ResNotComplete></ResNotComplete>
                )
              ) : resType === 'ready' ? (
                <ResReady></ResReady>
              ) : (
                <div>
                  <br />
                  <br />
                  <br />
                  <br />
                </div>
              )}
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

ShowProperty.propTypes = {
  property: PropTypes.object.isRequired,
  getProperty: PropTypes.func.isRequired,
  // resType: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  property: state.property.property,
  resType: state.property.resType,
  requestFailedMessage: state.property.requestFailedMessage,
  ikarAdmin: state.auth.ikarAdmin,
  isLoggedIn: state.auth.isLoggedIn,
  isTakingAgencyRole: state.auth.isTakingAgencyRole,
  takenAgencyRoleToken: state.auth.takenAgencyRoleToken,
  takenAgencyRoleAgencyRefId: state.auth.takenAgencyRoleAgencyRefId,
  takenAgencyRoleId: state.auth.takenAgencyRoleId,
  ikarId: state.auth.ikarId,
  agencyRefId: state.auth.agencyRefId,
});

export default connect(mapStateToProps, { getProperty, callForProperty })(
  ShowProperty
);