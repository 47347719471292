import React, { Component } from "react";
import Property from "./../properties/Property";
import { connect } from "react-redux";
import { PropTypes } from "prop-types";
import shortid from "shortid";
import { doSearch } from "./../../actions/searchActions";
import { isNumeric } from "./../../mainConf/functions";
import PaginationForProperties from "./../layout/Pagination/PaginationForProperties";
// import { ResReady, ResNotComplete } from './../layout/resTypes';
import { recordsPerPage } from "./../../mainConf/index";
import { removeParam } from "./../../mainConf/functions.jsx";
import queryString from "query-string";
import { Link } from "react-router-dom";
import classnames from "classnames";
import SearchBar from "./../search/SearchBar.jsx";
import { Helmet } from "react-helmet";
import { linkDomain } from "./../../mainConf";
class MainPageMostViewed extends Component {
  constructor(props) {
    super();
  }
  state = {
    is_page_param: false,
    pageTitle: "العقارات الأكثر مشاهدة | Ikar.sy",
    pageDescription:
      "أكثر عروض العقارات زيارة ضمن موقعنا في السوق السورية مع تفاصيل شاملة لكل عرض | Ikar.sy",
    properties: [],
    total_properties: -1,
    searchBarFullShow: false,
    requestSuccessMessage: {},
    errors: {},
    pageNumber: 1,
    searchParameters: {},
    main_type_param: "",
    business_offer_param: "",
    resType: "ready",
    locationSearch: "",
    normalUrl: "",
    largeScreen: "",
  };
  resize() {
    let currentWidth = window.innerWidth;
    if (currentWidth >= 768) {
      this.setState({ largeScreen: "t" });
    } else {
      this.setState({ largeScreen: "f" });
    }
  }
  async componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    let searchURLParams = await queryString.parse(this.props.location.search);
    let lowerSearchURLParams = {};
    await Object.keys(searchURLParams).forEach(function (key, value) {
      lowerSearchURLParams[key.toLowerCase()] = searchURLParams[key];
    });
    searchURLParams = lowerSearchURLParams;
    var main_type_param =
      searchURLParams.main_type !== undefined &&
      searchURLParams.main_type !== null
        ? searchURLParams.main_type
        : "";

    var business_offer_param =
      searchURLParams.business_offer !== undefined &&
      searchURLParams.business_offer !== null
        ? searchURLParams.business_offer
        : "";
    const is_page_param =
      searchURLParams.page === undefined ||
      searchURLParams.page === null ||
      !isNumeric(searchURLParams.page)
        ? false
        : true;
    this.setState({ is_page_param });
    const page =
      searchURLParams.page !== undefined &&
      searchURLParams.page !== null &&
      isNumeric(searchURLParams.page)
        ? searchURLParams.page
        : "1";
    if (main_type_param !== null) {
      await this.setState({ main_type_param });
    }

    if (business_offer_param !== null) {
      await this.setState({ business_offer_param });
    }
    await this.setState({ locationSearch: this.props.location.search });
    this.onSearchSubmit(
      recordsPerPage,
      page,
      business_offer_param,
      main_type_param
    );
  }

  onSearchSubmit = (
    recordsPerPage,
    pageNumber,
    business_offer_param,
    main_type_param
  ) => {
    var mainTypeParam = main_type_param;

    var businessOfferParam = business_offer_param;
    const property = {};
    if (mainTypeParam !== undefined && businessOfferParam !== undefined) {
      if (mainTypeParam !== "" && mainTypeParam !== null) {
        property.main_type = mainTypeParam;
      }
      if (businessOfferParam !== "" && businessOfferParam !== null) {
        property.business_offer = businessOfferParam;
      }
    }
    property.visible = true;
    property.order = {};
    property.order.views = "desc";
    property.temp_status = "عادي";
    // property.ranges = {};
    // property.ranges.days = {};
    // property.ranges.days.min = -999999;
    // property.ranges.days.max = -1;

    this.setState({ pageNumber });
    this.props.doSearch(property, recordsPerPage, pageNumber);
  };
  removeSearchQuery = async () => {
    localStorage.removeItem("ikar_main_type_param");
    localStorage.removeItem("ikar_business_offer_param");
    await this.setState({ main_type_param: "", business_offer_param: "" });
    this.onSearchSubmit(recordsPerPage, 1, null, null);
  };
  async componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.location.search !== this.state.locationSearch) {
      var normalUrl = await removeParam("main_type", nextProps.location.search);
      normalUrl = await removeParam("business_offer", normalUrl);
      await this.setState({
        normalUrl,
        locationSearch: nextProps.location.search,
      });
      const searchURLParams = await queryString.parse(
        nextProps.location.search
      );
      const main_type_param =
        searchURLParams.main_type !== undefined &&
        searchURLParams.main_type !== null
          ? searchURLParams.main_type
          : "";

      const business_offer_param =
        searchURLParams.business_offer !== undefined &&
        searchURLParams.business_offer !== null
          ? searchURLParams.business_offer
          : "";
      const page =
        searchURLParams.page !== undefined &&
        searchURLParams.page !== null &&
        isNumeric(searchURLParams.page)
          ? searchURLParams.page
          : "1";
      if (main_type_param !== null) {
        await this.setState({ main_type_param });
      }
      if (business_offer_param !== null) {
        await this.setState({ business_offer_param });
      }
      this.onSearchSubmit(
        recordsPerPage,
        page,
        business_offer_param,
        main_type_param
      );
    }
    if (
      nextProps.resType !== undefined &&
      nextProps.resType !== this.state.resType
    ) {
      const { resType } = nextProps;
      this.setState({ resType });
    }

    if (
      nextProps.properties !== this.state.properties &&
      nextProps.properties[0] !== undefined
    ) {
      const { properties } = nextProps;
      this.setState({
        properties,
        total_properties: properties[0].total_properties,
      });
      if (nextProps.pageNumber === 1) {
        this.setState({
          pageNumber: nextProps.pageNumber,
        });
      }
      if (nextProps.location.search !== this.state.locationSearch) {
        normalUrl = await removeParam("main_type", nextProps.location.search);
        normalUrl = await removeParam("business_offer", normalUrl);
        await this.setState({
          normalUrl,
          locationSearch: nextProps.location.search,
        });
        const searchURLParams = await queryString.parse(
          nextProps.location.search
        );
        const main_type_param =
          searchURLParams.main_type !== undefined &&
          searchURLParams.main_type !== null
            ? searchURLParams.main_type
            : "";

        const business_offer_param =
          searchURLParams.business_offer !== undefined &&
          searchURLParams.business_offer !== null
            ? searchURLParams.business_offer
            : "";
        if (main_type_param !== null) {
          await this.setState({ main_type_param });
        }
        if (business_offer_param !== null) {
          await this.setState({ business_offer_param });
        }
      }
    } else if (
      nextProps.properties[0] === undefined ||
      nextProps.properties.length === 0
    ) {
      this.setState({
        properties: [],
        total_properties: 0,
      });
    }
  }

  render() {
    const {
      properties,
      total_properties,
      // pageNumber,
      main_type_param,
      business_offer_param,
      largeScreen,
    } = this.state;

    if (properties !== undefined && properties.length > 0) {
    }

    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.pageTitle}</title>
          <meta name="description" content={this.state.pageDescription} />
          <link
            rel="canonical"
            href={`${linkDomain}/العروض-الأكثر-مشاهدة${
              this.state.pageNumber > 0 && this.state.is_page_param
                ? `?page=${this.state.pageNumber}`
                : ""
            }`}
          />
        </Helmet>
        {largeScreen === "t" ? (
          <SearchBar
            searchBarFullShow={this.state.searchBarFullShow}
            searchType="main_search"
            destination="/search"
            urlFilters="false"
            searchMode="off"
          ></SearchBar>
        ) : null}
        <div className="mb-3 text-center">
          <h1 className="main_title">العقارات الأكثر مشاهدة</h1>
        </div>
        <div className="property-container">
          <div className="container-fluid">
            {main_type_param !== "" || business_offer_param !== "" ? (
              <div className="text-right">
                <div className="mb-1">
                  <span className="display_7">
                    {`يتم فلترة النتائج تبعا للقيم(`}
                  </span>
                  <span className="display_7">
                    {business_offer_param !== "" ? (
                      <React.Fragment>
                        <span className="display_7">نوع العرض: </span>
                        <span className="display_gold">
                          {business_offer_param}
                        </span>{" "}
                      </React.Fragment>
                    ) : null}
                    {business_offer_param !== "" && main_type_param !== ""
                      ? " - "
                      : ""}
                    {main_type_param !== "" ? (
                      <React.Fragment>
                        <span className="display_7">نوع العقار: </span>
                        <span className="display_gold">
                          {main_type_param.split(" OR ").join(",")}
                        </span>
                      </React.Fragment>
                    ) : null}

                    <span className="display_7">{")"}</span>
                  </span>
                  <Link
                    to={{
                      pathname: this.props.location.pathname,
                      // query: { search_mode: 'min' }
                      search: this.state.normalUrl,
                    }}
                    className={classnames(
                      "btn",
                      "px-0",
                      "mb-2",
                      "mx-2",
                      "submit-button"
                    )}
                    style={{ width: "200px" }}
                    // onClick={this.onSearchButtonSubmit}
                  >
                    حذف الفلترة الحالية للبحث
                  </Link>
                </div>
              </div>
            ) : null}
            <div className="row justify-content-around align-items-center">
              {properties !== [] &&
                properties !== undefined &&
                (properties || []).map((property) => (
                  <Property
                    key={shortid.generate()}
                    property={property}
                    isLoggedIn={false}
                    ikarAdmin={false}
                    requestSuccessMessage={this.state.requestSuccessMessage}
                  />
                ))}
            </div>
            <PaginationForProperties
              resultLabel="عقار"
              recordsNumber={total_properties}
              // pageNumber={pageNumber}
              // onPageChange={this.onSearchButtonSubmit}
              showMessage={true}
              destination="/العروض-الأكثر-مشاهدة"
              locationSearch={this.state.locationSearch}
            ></PaginationForProperties>
          </div>
        </div>
        {largeScreen === "f" ? (
          <SearchBar
            searchBarFullShow={this.state.searchBarFullShow}
            searchType="main_search"
            destination="/search"
            urlFilters="false"
            searchMode="off"
          ></SearchBar>
        ) : null}
      </React.Fragment>
    );
  }
}

MainPageMostViewed.propTypes = {
  properties: PropTypes.array.isRequired,
  doSearch: PropTypes.func.isRequired,
  // pageNumber: PropTypes.string.isRequired
};

const mapStateToProps = (state) => ({
  properties: state.search.properties,
  pageNumber: state.search.pageNumber,
  resType: state.search.resType,
});

export default connect(mapStateToProps, { doSearch })(MainPageMostViewed);
