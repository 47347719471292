import { ADD_FAQ, GET_FAQ, GET_FAQS, DELETE_FAQ } from './types';
import axios from 'axios';
import { mainDomain } from './../mainConf';

export const addFaq = (faq) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  await axios
    .post(
      `${mainDomain}/v1/faq`,
      faq,
      // ,
      // {
      //   withCredentials: true,
      // }
      {
        headers: {
          Accept: 'application/json',
          Authorization: localStorage.getItem('ikar_token'),
        },
      }
    )
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
        res.resType = 'complete';
      } else res = {};
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
      res.resType = 'not complete';
    });
  dispatch({
    type: ADD_FAQ,
    payload: res,
  });
};

export const getFaq = (ikarAdmin, faqReferenceId) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  var token = '';
  var faqRefId = faqReferenceId;
  if (ikarAdmin) {
    token = localStorage.getItem('ikar_token');
    await axios
      .get(`${mainDomain}/v1/faq?reference_id=${faqRefId}`, {
        headers: {
          //'Content-Type': 'application/json',
          Accept: 'application/json',
          //Accept: 'application/json',
          Authorization: token,
        },
      })
      .then(function (response) {
        if (
          response !== null &&
          response !== undefined &&
          Object.keys(response).length > 0
        ) {
          res = response.data;
          res.resType = 'complete';
        } else res = {};
      })
      .catch(function (error) {
        if (error.response) {
          errorRes = {
            errorStatus: error.response.status,
            errorMessage: error.response,
          };
        } else if (error.request) {
          errorRes = {
            errorMessage: error.request,
          };
        } else {
          errorRes = { errorMessage: error.message };
        }
        res = errorRes;
        res.resType = 'not complete';
      });
  } else {
    await axios
      .get(
        `${mainDomain}/v1/faq?reference_id=${faqRefId}`,
        // ,
        // {
        //   withCredentials: true,
        // }
        {
          headers: {
            //'Content-Type': 'application/json',
            Accept: 'application/json',
            //Accept: 'application/json',
            // Authorization: token,
          },
        }
      )
      .then(function (response) {
        if (
          response !== null &&
          response !== undefined &&
          Object.keys(response).length > 0
        ) {
          res = response.data;
          res.resType = 'complete';
        } else res = {};
      })
      .catch(function (error) {
        if (error.response) {
          errorRes = {
            errorStatus: error.response.status,
            errorMessage: error.response,
          };
        } else if (error.request) {
          errorRes = {
            errorMessage: error.request,
          };
        } else {
          errorRes = { errorMessage: error.message };
        }
        res = errorRes;
        res.resType = 'not complete';
      });
  }
  dispatch({
    type: GET_FAQ,
    payload: res,
  });
};

export const getFaqs = (ikarAdmin) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  var token = '';
  if (ikarAdmin) {
    token = localStorage.getItem('ikar_token');
    await axios
      .get(
        `${mainDomain}/v1/faqs`,
        // ,
        // {
        //   withCredentials: true,
        // }
        {
          headers: {
            //'Content-Type': 'application/json',
            Accept: 'application/json',
            //Accept: 'application/json',
            Authorization: token,
          },
        }
      )
      .then(function (response) {
        if (
          response !== null &&
          response !== undefined &&
          Object.keys(response).length > 0
        ) {
          res = response.data;
          res.resType = 'complete';
        } else res = {};
      })
      .catch(function (error) {
        if (error.response) {
          errorRes = {
            errorStatus: error.response.status,
            errorMessage: error.response,
          };
        } else if (error.request) {
          errorRes = {
            errorMessage: error.request,
          };
        } else {
          errorRes = { errorMessage: error.message };
        }
        res = errorRes;
        res.resType = 'not complete';
      });
  } else {
    await axios
      .get(
        `${mainDomain}/v1/faqs`,
        // ,
        // {
        //   withCredentials: true,
        // }
        {
          headers: {
            //'Content-Type': 'application/json',
            Accept: 'application/json',
            //Accept: 'application/json',
            // Authorization: token,
          },
        }
      )
      .then(function (response) {
        if (
          response !== null &&
          response !== undefined &&
          Object.keys(response).length > 0
        ) {
          res = response.data;
          res.resType = 'complete';
        } else res = {};
      })
      .catch(function (error) {
        if (error.response) {
          errorRes = {
            errorStatus: error.response.status,
            errorMessage: error.response,
          };
        } else if (error.request) {
          errorRes = {
            errorMessage: error.request,
          };
        } else {
          errorRes = { errorMessage: error.message };
        }
        res = errorRes;
        res.resType = 'not complete';
      });
  }

  dispatch({
    type: GET_FAQS,
    payload: res,
  });
};

export const deleteFaq = (reference_id, processType) => async (dispatch) => {
  let res = {};
  let errorRes = {
    errorStatus: '',
    errorMessage: '',
  };
  await axios
    .delete(`${mainDomain}/v1/faq?reference_id=${reference_id}`, {
      headers: {
        Accept: 'application/json',
        Authorization: localStorage.getItem('ikar_token'),
      },
    })
    .then(function (response) {
      if (
        response !== null &&
        response !== undefined &&
        Object.keys(response).length > 0
      ) {
        res = response.data;
        res.message = processType;
      } else {
        res = {};
      }
    })
    .catch(function (error) {
      if (error.response) {
        errorRes = {
          errorStatus: error.response.status,
          errorMessage: error.response,
        };
      } else if (error.request) {
        errorRes = {
          errorMessage: error.request,
        };
      } else {
        errorRes = { errorMessage: error.message };
      }
      res = errorRes;
    });
  dispatch({
    type: DELETE_FAQ,
    payload: res,
  });
};
