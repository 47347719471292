import React, { Component } from 'react';
import classnames from 'classnames';
import {
  setMainResponse,
  getMainResponse,
} from './../../../actions/mainAction.jsx';
import ReactGA from 'react-ga4';
import carouselImage1 from './../../../assets/images/home1-banner1.jpg';
import carouselImage2 from './../../../assets/images/home1-banner2.jpg';
import carouselImage3 from './../../../assets/images/home1-banner3.jpg';
import carouselImage4 from './../../../assets/images/home1-banner4.jpg';
import carouselImage5 from './../../../assets/images/home1-banner5.jpg';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { doSearch } from './../../../actions/searchActions';
import jQuery from 'jquery';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { compose } from 'redux';
import { Link } from 'react-router-dom';
// import { recordsPerPage } from './../../../mainConf/index';
import headerBackground from './../../../assets/images/headerBackground.jpg';
import { formatMoney } from './../../../mainConf/functions';
import {
  syriaAreas,
  generalBusinessOffer,
  generalTypes,
  generalProvinces,
  generalTypesItems,
  generalAreas,
  generalSellPrices,
  generalRentPrices,
} from './../../../mainConf';
import shortid from 'shortid';
import logo from './../../../assets/images/logo.png';
class Header extends Component {
  state = {
    searchNewBarShow: true,
    searchNewBarShow2: true,
    largeScreen: false,
    expanded_search: false,
    smallScreen: false,
    searchBarShow: false,
    SearchSummaryShow: false,
    areas: [],
    propertyPosition: null,
    title: '',
    type: 'نوع العقار',
    //type: '',
    main_type: '',
    main_type_flat: false,
    main_type_cabin: false,
    main_type_building: false,
    main_type_trade: false,
    main_type_land: false,
    business_offer_rent: false,
    business_offer_sale: false,
    init1: true,
    init2: true,
    forRent: false,
    forSell: true,
    rank: 1,
    description: '',
    reference_id: '',
    city: '',
    province: 'المحافظة',
    area: 'المنطقة',
    street: '',
    gps_location: '',
    bedrooms: 'الغرف',
    bathrooms: 'عدد الحمامات',
    toilets: 0,
    garage: false,
    parking: false,
    random: '',
    balcony: 0,
    view_direction: 'الاتجاه',
    floor: 'الطابق',
    duplex: false,
    total_area: 0,
    living_area: 0,
    ground_area: 0,
    lift: false,
    furnitured: false,
    type_of_ownership: 'غير محدد',
    price: 0,
    business_status: 'جديد',
    rent_plan_id: 1,
    sell_plan_id: 1,
    visible: true,
    built_at: null,
    reconstructed_at: null,
    status: 'غير محدد',
    price_per_day: 0,
    price_per_mm: 0,
    business_offer: 'نوع العرض',
    //business_offer: '',
    views: 0,
    created_at: null,
    photo_data: [],
    min_price: 'أدنى سعر',
    max_price: 'أعلى سعر',
    min_area: 'أدنى مساحة',
    max_area: 'أعلى مساحة',
    errors: {},
    isMobile: false,
  };
  initSearch = () => {
    this.setState({
      //searchNewBarShow: true,
      largeScreen: false,
      expanded_search: false,
      smallScreen: false,
      searchBarShow: false,
      SearchSummaryShow: false,
      areas: [],
      propertyPosition: null,
      title: '',
      type: 'نوع العقار',
      main_type: '',
      main_type_flat: false,
      main_type_cabin: false,
      main_type_building: false,
      main_type_trade: false,
      main_type_land: false,
      business_offer_rent: false,
      business_offer_sale: false,
      init1: true,
      init2: true,
      forRent: false,
      forSell: true,
      rank: 1,
      description: '',
      reference_id: '',
      city: '',
      province: 'المحافظة',
      area: 'المنطقة',
      street: '',
      gps_location: '',
      bedrooms: 'الغرف',
      bathrooms: 'عدد الحمامات',
      toilets: 0,
      garage: false,
      parking: false,
      random: '',
      balcony: 0,
      view_direction: 'الاتجاه',
      floor: 'الطابق',
      duplex: false,
      total_area: 0,
      living_area: 0,
      ground_area: 0,
      lift: false,
      furnitured: false,
      type_of_ownership: 'غير محدد',
      price: 0,
      business_status: 'جديد',
      rent_plan_id: 1,
      sell_plan_id: 1,
      visible: true,
      built_at: null,
      reconstructed_at: null,
      status: 'غير محدد',
      price_per_day: 0,
      price_per_mm: 0,
      business_offer: 'نوع العرض',
      views: 0,
      created_at: null,
      photo_data: [],
      min_price: 'أدنى سعر',
      max_price: 'أعلى سعر',
      min_area: 'أدنى مساحة',
      max_area: 'أعلى مساحة',
      errors: {},
      bgNum: 0,
      searchUrl: '',
    });
  };
  componentDidMount() {
    window.addEventListener(
      'resize',
      () => {
        this.setState({
          isMobile: window.innerWidth < 768,
        });
        this.resize();
      },
      false
    );
    window.addEventListener('resize', this.resize.bind(this));
    const bgNum = Math.floor(Math.random() * 5);
    this.setState({ bgNum, searchNewBarShow: this.props.searchNewBarShow });
    this.resize();
  }
  componentWillReceiveProps(nextProps, nextState) {
    if (nextProps.searchNewBarShow !== this.state.searchNewBarShow) {
      this.setState({ searchNewBarShow: nextProps.searchNewBarShow });
      this.initSearch();
      this.resize();
    }
    if (nextProps.location.pathname !== this.state.pathname) {
      this.setState({ pathname: nextProps.location.pathname });
      this.props.onPathChange(nextProps.location.pathname);
    }
  }

  resize() {
    let currentWidth = window.innerWidth;
    if (currentWidth >= 576) {
      this.setState({ largeScreen: true });
    } else {
      this.setState({ largeScreen: false });
    }
    if (currentWidth <= 320) {
      this.setState({ smallScreen: true });
    } else {
      this.setState({ smallScreen: false });
    }
    if (currentWidth < 768) {
      this.setState({ isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  }
  buildSearchUrl = async () => {
    const {
      type,
      main_type_flat,
      main_type_cabin,
      main_type_building,
      main_type_trade,
      main_type_land,
      business_offer_rent,
      business_offer_sale,
      random,
      province,
      area,
      business_offer,
      // min_price,
      // max_price,
      // min_area,
      // max_area
    } = this.state;
    const property = {};
    var min_price =
      this.state.min_price === 'أدنى سعر' ? '' : this.state.min_price;
    var max_price =
      this.state.max_price === 'أعلى سعر' ? '' : this.state.max_price;

    var min_area =
      this.state.min_area === 'أدنى مساحة' ? '' : this.state.min_area;
    var max_area =
      this.state.max_area === 'أعلى مساحة' ? '' : this.state.max_area;
    if (this.state.expanded_search === true) {
      var Province = '';
      var Area = '';
      var Type = '';
      var BusinessOffer = '';
      if (province !== 'المحافظة') {
        Province = province;
      }
      if (area !== 'المنطقة') {
        Area = area;
      }
      if (type !== 'نوع العقار') {
        Type = type;
      }
      if (business_offer !== 'نوع العرض') {
        BusinessOffer = business_offer;
      }
      if (Type !== '') {
        property.type = Type;
      }
      if (BusinessOffer !== '') {
        property.business_offer = BusinessOffer;
      }
      if (Province !== '') {
        property.province = Province;
      }
      if (Area !== '') {
        property.city = Area;
      }
      if (Area !== '') {
        property.area = Area;
      }
      property.ranges = {};
      if (
        parseInt(min_price) !== 0 &&
        min_price !== '' &&
        min_price !== 'أدنى سعر' &&
        parseInt(max_price) !== 0 &&
        max_price !== '' &&
        max_price !== 'أعلى سعر' &&
        parseInt(min_price) <= parseInt(max_price)
      ) {
        property.ranges.price = {};
        property.ranges.price.min = min_price === '' ? 0 : min_price;
        property.ranges.price.max = max_price === '' ? 0 : max_price;
      }

      //////add
      else if (
        parseInt(min_price) !== 0 &&
        min_price !== '' &&
        min_price !== 'أدنى سعر' &&
        (parseInt(max_price) === 0 || max_price === '') &&
        parseInt(min_price) > 0
      ) {
        property.ranges.price = {};
        property.ranges.price.min = min_price;
        property.ranges.price.max = null;
      } else if (
        parseInt(max_price) !== 0 &&
        max_price !== '' &&
        max_price !== 'أعلى سعر' &&
        (parseInt(min_price) === 0 || min_price === '') &&
        parseInt(max_price) > 0
      ) {
        property.ranges.price = {};
        property.ranges.price.min = null;
        property.ranges.price.max = max_price;
      }
      //////
      if (
        parseInt(min_area) !== 0 &&
        min_area !== '' &&
        min_area !== 'أدنى مساحة' &&
        parseInt(max_area) !== 0 &&
        max_area !== '' &&
        max_area !== 'أعلى مساحة' &&
        parseInt(min_area) <= parseInt(max_area)
      ) {
        property.ranges.total_area = {};
        property.ranges.total_area.min = min_area === '' ? 0 : min_area;
        property.ranges.total_area.max = max_area === '' ? 0 : max_area;
      }

      //////add
      else if (
        parseInt(min_area) !== 0 &&
        min_area !== '' &&
        min_area !== 'أدنى مساحة' &&
        (parseInt(max_area) === 0 || max_area === '') &&
        parseInt(min_area) > 0
      ) {
        property.ranges.total_area = {};
        property.ranges.total_area.min = min_area;
        property.ranges.total_area.max = null;
      } else if (
        parseInt(max_area) !== 0 &&
        max_area !== '' &&
        max_area !== 'أعلى مساحة' &&
        (parseInt(min_area) === 0 || min_area === '') &&
        parseInt(max_area) > 0
      ) {
        property.ranges.total_area = {};
        property.ranges.total_area.min = null;
        property.ranges.total_area.max = max_area;
      }
    } else {
      //if (Type !== '') {
      var mainTypes = [];
      //}
      //if (BusinessOffer !== '') {
      if (main_type_flat === true) {
        mainTypes.push('شقق');
      }
      if (main_type_cabin === true) {
        mainTypes.push('شاليهات');
      }
      if (main_type_building === true) {
        mainTypes.push('بنايات');
      }
      if (main_type_trade === true) {
        mainTypes.push('تجاري');
      }
      if (main_type_land === true) {
        mainTypes.push('أراضي');
      }

      var mainTypesStr = '';
      mainTypesStr = mainTypes.join(' OR ');

      // if (
      //   business_offer !== null &&
      //   business_offer !== '' &&
      //   business_offer !== 'نوع العرض'
      // ) {
      //   property.business_offer = business_offer;
      // }
      if (!business_offer_rent || !business_offer_sale) {
        if (business_offer_rent) {
          property.business_offer = 'تأجير';
        }
        if (business_offer_sale) {
          property.business_offer = 'بيع';
        }
      }
      if (mainTypesStr !== '') {
        property.main_type = mainTypesStr;
      }
    }

    if (random !== '') {
      property.random = random;
    }

    var searchUrl = [];

    if (property.business_offer !== undefined) {
      searchUrl.push(
        `business_offer_id=${generalBusinessOffer.findIndex(
          (x) => x === property.business_offer
        )}`
      );
    }
    if (property.random !== undefined) {
      searchUrl.push(`random=${property.random}`);
    }
    if (property.province !== undefined) {
      searchUrl.push(
        `province_id=${generalProvinces.findIndex(
          (x) => x === property.province
        )}`
      );
    }
    if (property.area !== undefined) {
      if (property.province === 'دمشق') {
        searchUrl.push(
          `district_id=${syriaAreas.damascusAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === 'ريف دمشق') {
        searchUrl.push(
          `district_id=${syriaAreas.rifDamascusAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === 'حلب') {
        searchUrl.push(
          `district_id=${syriaAreas.aleppoAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === 'طرطوس') {
        searchUrl.push(
          `district_id=${syriaAreas.tartousAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === 'اللاذقية') {
        searchUrl.push(
          `district_id=${syriaAreas.latakiaAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === 'حمص') {
        searchUrl.push(
          `district_id=${syriaAreas.homsAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === 'حماة') {
        searchUrl.push(
          `district_id=${syriaAreas.hamaAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === 'السويداء') {
        searchUrl.push(
          `district_id=${syriaAreas.asuwaydaAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === 'الحسكة') {
        searchUrl.push(
          `district_id=${syriaAreas.hasakaAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === 'درعا') {
        searchUrl.push(
          `district_id=${syriaAreas.daraaAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === 'ادلب') {
        searchUrl.push(
          `district_id=${syriaAreas.idlibAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === 'دير الزور') {
        searchUrl.push(
          `district_id=${syriaAreas.deirezorAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === 'القنيطرة') {
        searchUrl.push(
          `district_id=${syriaAreas.quneitraAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      } else if (property.province === 'الرقة') {
        searchUrl.push(
          `district_id=${syriaAreas.raqqaAreas.findIndex(
            (x) => x === property.area
          )}`
        );
      }
    }

    if (property.type !== undefined) {
      searchUrl.push(
        `type_id=${generalTypesItems.findIndex((x) => x === property.type)}`
      );
    }
    if (property.ranges !== undefined) {
      if (
        property.ranges.price !== undefined &&
        property.ranges.price.min !== undefined
      ) {
        searchUrl.push(`min_price=${property.ranges.price.min}`);
      }
      if (
        property.ranges.price !== undefined &&
        property.ranges.price.max !== undefined
      ) {
        searchUrl.push(`max_price=${property.ranges.price.max}`);
      }

      if (
        property.ranges.total_area !== undefined &&
        property.ranges.total_area.min !== undefined
      ) {
        searchUrl.push(`min_area=${property.ranges.total_area.min}`);
      }
      if (
        property.ranges.total_area !== undefined &&
        property.ranges.total_area.max !== undefined
      ) {
        searchUrl.push(`max_area=${property.ranges.total_area.max}`);
      }
    }

    // if (property.main_type !== undefined) {
    //   searchUrl.push(
    //     `main_type=${generalMainTypes.findIndex(x => x === property.main_type)}`
    //   );
    // }

    if (property.main_type !== undefined) {
      searchUrl.push(`main_type=${property.main_type}`);
    }
    this.setState({ searchUrl: searchUrl.join('&') });
  };
  onSearchButtonSubmit = async (e) => {
    await this.buildSearchUrl();
    var str = this.state.searchUrl !== '' ? '?' : '';

    var searchStr = '';
    if (!this.state.expanded_search) {
      searchStr = searchStr + ` Main Search: `;
      if (this.state.main_type_flat === true) {
        searchStr = searchStr + ` شقق: `;
      }
      if (this.state.main_type_cabin === true) {
        searchStr = searchStr + ` شاليهات: `;
      }
      if (this.state.main_type_building === true) {
        searchStr = searchStr + ` بنايات: `;
      }
      if (this.state.main_type_trade === true) {
        searchStr = searchStr + ` تجاري: `;
      }
      if (this.state.main_type_land === true) {
        searchStr = searchStr + ` أراضي: `;
      }

      // if (
      //   business_offer !== null &&
      //   business_offer !== '' &&
      //   business_offer !== 'نوع العرض'
      // ) {
      //   property.business_offer = business_offer;
      // }
      if (this.state.business_offer_rent) {
        searchStr = searchStr + ` تأجير: `;
      }
      if (this.state.business_offer_sale) {
        searchStr = searchStr + ` بيع: `;
      }
    } else if (this.state.expanded_search) {
      // searchStr = searchStr + ` T=${this.state.type}, `;
      searchStr = searchStr + ` ExpandedSearch: `;

      if (this.state.type !== 'نوع العقار') {
        // searchStr = searchStr + ` T=${this.state.type}, `;
        searchStr = searchStr + ` Type, `;
      }

      if (this.state.business_offer !== 'نوع العرض') {
        // searchStr = searchStr + ` BO=${this.state.business_offer}, `;
        searchStr = searchStr + ` BusinessOffer, `;
      }
      if (this.state.province !== 'المحافظة') {
        // searchStr = searchStr + ` P=${this.state.province}, `;
        searchStr = searchStr + ` Province, `;
      }
      if (this.state.area !== 'المنطقة') {
        // searchStr = searchStr + ` A=${this.state.area}, `;
        searchStr = searchStr + ` Area, `;
      }
      if (this.state.min_price !== '') {
        // searchStr = searchStr + ` min_p=${this.state.min_price}, `;
        searchStr = searchStr + ` MinPrice, `;
      }
      if (this.state.max_price !== '') {
        // searchStr = searchStr + ` max_p=${this.state.max_price}, `;
        searchStr = searchStr + ` MaxPrice, `;
      }

      if (this.state.min_area !== '') {
        // searchStr = searchStr + ` min_a=${this.state.min_area}, `;
        searchStr = searchStr + ` MinArea, `;
      }
      if (this.state.max_area !== '') {
        // searchStr = searchStr + ` max_a=${this.state.max_area}, `;
        searchStr = searchStr + ` MaxArea, `;
      }
    }
    await ReactGA.event({
      category: 'Home page search',
      action: `Search parameters: ${searchStr}`,
    });

    window.location = `/search${str}${this.state.searchUrl}`;
    this.setState({ errors: {} });
    this.props.handleScroll();
    this.setState({ searchNewBarShow: false });
    this.setState({ searchBarShow: false });
    this.setState({ SearchSummaryShow: true });
    // this.props.history.push('/search');
  };
  onFullSearchClick = async () => {
    await this.setState({ expanded_search: !this.state.expanded_search });
    // await this.buildSearchUrl();
    jQuery(function ($) {
      var $searchFormBody = $('.search-form-body');
      $searchFormBody.toggleClass('search-form-body-toggle');

      $searchFormBody.toggleClass('search-form-body-toggle2');
    });
  };
  buildSearchQuery = async () => {
    const {
      main_type_flat,
      main_type_cabin,
      main_type_building,
      main_type_trade,
      main_type_land,
      // business_offer,
      business_offer_rent,
      business_offer_sale,
    } = this.state;
    var mainTypes = [];
    var property = {};
    //}
    //if (BusinessOffer !== '') {
    if (main_type_flat === true) {
      mainTypes.push('شقق');
    }
    if (main_type_cabin === true) {
      mainTypes.push('شاليهات');
    }
    if (main_type_building === true) {
      mainTypes.push('بنايات');
    }
    if (main_type_trade === true) {
      mainTypes.push('تجاري');
    }
    if (main_type_land === true) {
      mainTypes.push('أراضي');
    }

    var mainTypesStr = '';
    mainTypesStr = mainTypes.join(' OR ');

    // if (business_offer !== '' && business_offer !== 'نوع العرض') {
    //   property.business_offer = business_offer;
    // } else {
    //   property.business_offer = null;
    // }
    if (business_offer_rent) {
      property.business_offer = 'تأجير';
    }
    if (business_offer_sale) {
      property.business_offer = 'بيع';
    }
    if (
      (!business_offer_rent && !business_offer_sale) ||
      (business_offer_rent && business_offer_sale)
    ) {
      property.business_offer = null;
    }
    if (mainTypesStr !== '') {
      property.main_type = mainTypesStr;
    } else {
      property.main_type = null;
    }

    await this.props.setMainResponse(property);
    var requestData = { property };
    await this.props.getMainResponse(requestData);
  };
  onChange = async (e) => {
    await this.setState({ [e.target.name]: e.target.value });
    // this.buildSearchUrl();
  };
  onBusinessOfferChange = async (e) => {
    // e.preventDefault();
    await this.setState({ [e.target.name]: e.target.value });
    // await this.buildSearchUrl();
    await this.buildSearchQuery();
  };
  onBusinessOfferChangeList = async (e) => {
    // await this.setState({ [e.target.name]: e.target.value });
    // this.buildSearchUrl();

    if (e.target.value === 'أبحث للإيجار') {
      await this.setState({
        [e.target.name]: e.target.value,
        forRent: true,
        forSell: false,
      });
    } else if (e.target.value === 'تأجير') {
      await this.setState({
        [e.target.name]: e.target.value,
        forRent: true,
        forSell: false,
      });
    } else if (e.target.value === 'أبحث للشراء') {
      await this.setState({
        [e.target.name]: e.target.value,
        forRent: false,
        forSell: true,
      });
    } else if (e.target.value === 'رهن') {
      await this.setState({
        [e.target.name]: e.target.value,
        forRent: false,
        forSell: false,
      });
    } else {
      await this.setState({
        [e.target.name]: e.target.value,
        forRent: false,
        forSell: true,
      });
    }
  };

  onCheckBoxChange1 = async (e) => {
    if (this.state.init1) {
      await this.setState({ init1: false, [e.target.name]: true });
    } else {
      await this.setState({ [e.target.name]: e.target.checked });
    }
    var searchStr = '';
    if (this.state.main_type_flat === true) {
      searchStr = searchStr + ` شقق: `;
    }
    if (this.state.main_type_cabin === true) {
      searchStr = searchStr + ` شاليهات: `;
    }
    if (this.state.main_type_building === true) {
      searchStr = searchStr + ` بنايات: `;
    }
    if (this.state.main_type_trade === true) {
      searchStr = searchStr + ` تجاري: `;
    }
    if (this.state.main_type_land === true) {
      searchStr = searchStr + ` أراضي: `;
    }

    if (!this.state.business_offer_rent || !this.state.business_offer_sale) {
      if (this.state.business_offer_rent) {
        await this.setState({
          business_offer: 'تأجير',
          forRent: true,
          forSell: false,
        });
      }
      if (this.state.business_offer_sale) {
        await this.setState({
          business_offer: 'بيع',
          forRent: false,
          forSell: true,
        });
      }
    }
    if (this.state.business_offer_rent && this.state.business_offer_sale) {
      await this.setState({
        business_offer: 'نوع العرض',
        forRent: false,
        forSell: false,
      });
    }
    if (!this.state.business_offer_rent && !this.state.business_offer_sale) {
      await this.setState({
        business_offer: 'نوع العرض',
        forRent: false,
        forSell: false,
      });
    }
    if (this.state.business_offer_rent && !this.state.business_offer_sale) {
      searchStr = searchStr + ` تأجير: `;
    }
    if (this.state.business_offer_sale && !this.state.business_offer_rent) {
      searchStr = searchStr + ` بيع: `;
    }
    await ReactGA.event({
      category: 'Main Filters - business offer',
      action: `Used filters: ${searchStr}`,
    });
    await this.buildSearchQuery();
  };
  onCheckBoxChange = async (e) => {
    if (this.state.init2) {
      await this.setState({ init2: false, [e.target.name]: true });
    } else {
      await this.setState({ [e.target.name]: e.target.checked });
    }

    var searchStr = '';
    if (this.state.main_type_flat === true) {
      searchStr = searchStr + ` شقق, `;
    }
    if (this.state.main_type_cabin === true) {
      searchStr = searchStr + ` شاليهات, `;
    }
    if (this.state.main_type_building === true) {
      searchStr = searchStr + ` بنايات, `;
    }
    if (this.state.main_type_trade === true) {
      searchStr = searchStr + ` تجاري, `;
    }
    if (this.state.main_type_land === true) {
      searchStr = searchStr + ` أراضي, `;
    }

    if (this.state.business_offer_rent) {
      searchStr = searchStr + ` تأجير, `;
    }
    if (this.state.business_offer_sale) {
      searchStr = searchStr + ` بيع, `;
    }
    await ReactGA.event({
      category: 'Main Filters - main property types',
      action: `Used filters: ${searchStr}`,
    });

    await this.buildSearchQuery();
  };

  onProvinceChange = async (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.value === 'دمشق') {
      //دمشق
      await this.setState({
        areas: syriaAreas.damascusAreas,
        area: 'المنطقة',
        city: '',
      });
    } else if (e.target.value === 'ريف دمشق') {
      //ريف دمشق
      await this.setState({
        areas: syriaAreas.rifDamascusAreas,
        area: 'المنطقة',
        city: '',
      });
    } else if (e.target.value === 'حلب') {
      //حلب
      await this.setState({
        areas: syriaAreas.aleppoAreas,
        area: 'المنطقة',
        city: '',
      });
    } else if (e.target.value === 'طرطوس') {
      //طرطوس
      await this.setState({
        areas: syriaAreas.tartousAreas,
        area: 'المنطقة',
        city: '',
      });
    } else if (e.target.value === 'اللاذقية') {
      //اللاذقية
      await this.setState({
        areas: syriaAreas.latakiaAreas,
        area: 'المنطقة',
        city: '',
      });
    } else if (e.target.value === 'حمص') {
      //حمص
      await this.setState({
        areas: syriaAreas.homsAreas,
        area: 'المنطقة',
        city: '',
      });
    } else if (e.target.value === 'حماة') {
      //حماة
      await this.setState({
        areas: syriaAreas.hamaAreas,
        area: 'المنطقة',
        city: '',
      });
    } else if (e.target.value === 'السويداء') {
      //السويداء
      await this.setState({
        areas: syriaAreas.asuwaydaAreas,
        area: 'المنطقة',
        city: '',
      });
    } else if (e.target.value === 'الحسكة') {
      //الحسكة
      await this.setState({
        areas: syriaAreas.hasakaAreas,
        area: 'المنطقة',
        city: '',
      });
    } else if (e.target.value === 'درعا') {
      //درعا
      await this.setState({
        areas: syriaAreas.daraaAreas,
        area: 'المنطقة',
        city: '',
      });
    } else if (e.target.value === 'ادلب') {
      //ادلب
      await this.setState({
        areas: syriaAreas.idlibAreas,
        area: 'المنطقة',
        city: '',
      });
    } else if (e.target.value === 'دير الزور') {
      //دير الزور
      await this.setState({
        areas: syriaAreas.deirezorAreas,
        area: 'المنطقة',
        city: '',
      });
    } else if (e.target.value === 'القنيطرة') {
      //القنيطرة
      await this.setState({
        areas: syriaAreas.quneitraAreas,
        area: 'المنطقة',
        city: '',
      });
    } else if (e.target.value === 'الرقة') {
      //الرقة
      await this.setState({
        areas: syriaAreas.raqqaAreas,
        area: 'المنطقة',
        city: '',
      });
    } else {
      await this.setState({ areas: [], area: 'المنطقة', city: '' });
    }

    // this.buildSearchUrl();
  };

  render() {
    const {
      searchNewBarShow,
      expanded_search,
      areas,
      forSell,
      forRent,
      // IsGarden,
      // IsMM,
      // IsUsingGPS,
      // forRent,
      // forSell,
      // IsBuildDate,
      // IsReconstructed,
      // showMap,
      // maplays,
      // propertyPosition,
      // title,
      type,
      // main_type,
      main_type_flat,
      main_type_cabin,
      main_type_building,
      main_type_trade,
      main_type_land,
      business_offer_rent,
      business_offer_sale,
      init1,
      init2,
      // rank,
      // description,
      // reference_id,
      // city,
      province,
      area,
      random,
      // street,
      // gps_location,
      // bedrooms,
      // bathrooms,
      // toilets,
      // garage,
      // parking,
      // balcony,
      // view_direction,
      // floor,
      // duplex,
      // total_area,
      // living_area,
      // ground_area,
      // lift,
      // furnitured,
      // type_of_ownership,
      // price,
      // business_status,
      // rent_plan_id,
      // sell_plan_id,
      // visible,
      // built_at,
      // reconstructed_at,
      // status,
      // price_per_day,
      // price_per_mm,
      business_offer,
      // views,
      // created_at,
      // photo_data,
      min_price,
      max_price,
      min_area,
      max_area,
      errors,
    } = this.state;

    const bgImages = [
      carouselImage1,
      carouselImage2,
      carouselImage3,
      carouselImage4,
      carouselImage5,
    ];
    const className1 =
      this.state.isMobile === true ? 'min-header1' : 'min-header';
    return (
      <div className="container-fluid m-0 p-0">
        {searchNewBarShow ? (
          <div
            className={classnames(
              { 'main-header-expanded': expanded_search },
              { 'main-header': !expanded_search }
            )}
            style={{
              background: `url(${bgImages[this.state.bgNum]})center /cover`,
            }}
          >
            {this.state.isMobile ? (
              <Link
                to="/"
                className="navbar-brand mx-2 logopos"
                title="الصفحة الرئيسية"
                color="red"
                //onClick={this.onNavClick.bind(this, '/')}
              >
                <img
                  src={logo}
                  className="logo"
                  alt=""
                  // onClick={this.onMenuBtnClick}
                />
              </Link>
            ) : null}
          </div>
        ) : (
          <div
            className={className1}
            style={{
              backgroundImage: `url(${headerBackground})`,
            }}
          >
            {this.state.isMobile ? (
              <Link
                to="/"
                className="navbar-brand mx-2 logopos"
                title="الصفحة الرئيسية"
                color="red"
                //onClick={this.onNavClick.bind(this, '/')}
              >
                <img
                  src={logo}
                  className="logo"
                  alt=""
                  // onClick={this.onMenuBtnClick}
                />
              </Link>
            ) : null}
          </div>
        )}
        {searchNewBarShow ? (
          <React.Fragment>
            <div
              className={classnames(
                { 'search-min-div-container-expanded': expanded_search },
                { 'search-min-div-container': !expanded_search }
              )}
            ></div>
            <div className="search_min_div">
              <form>
                {!expanded_search ? (
                  // this.state.largeScreen ? (
                  <div className="input-group text-right mb-1 p-1">
                    <input
                      id="business_offer_sale_id"
                      type="checkbox"
                      name="business_offer_sale"
                      value="بيع"
                      checked={business_offer_sale === true || init1}
                      onChange={this.onCheckBoxChange1}
                      className={classnames('pl-1', 'mt-2', {
                        shining: init1,
                      })}
                    />
                    {/* <input
                      type="radio"
                      name="business_offer"
                      value="بيع"
                      checked={business_offer === 'بيع'}
                      onChange={this.onBusinessOfferChange}
                      className="pl-1 mt-2"
                    /> */}
                    <label
                      htmlFor="business_offer_sale_id"
                      className="no_select label_narrow label_white_with_shadow pr-1 pl-3"
                    >
                      للبيع
                    </label>
                    <input
                      id="business_offer_rent_id"
                      type="checkbox"
                      name="business_offer_rent"
                      value="تأجير"
                      checked={business_offer_rent === true || init1}
                      onChange={this.onCheckBoxChange1}
                      className={classnames('pl-1', 'mt-2', {
                        shining: init1,
                      })}
                    />

                    <label
                      htmlFor="business_offer_rent_id"
                      className="no_select label_narrow label_white_with_shadow pr-1 pl-3"
                    >
                      للإيجار
                    </label>
                  </div>
                ) : null}

                <div className="search-form-body search-form-body-toggle">
                  <div className="input-group justify-content-start">
                    {!this.state.largeScreen ? (
                      <div
                        className="form-group text-right px-1 mt-1 mb-2"
                        style={{ width: '270px' }}
                      >
                        <span className="p-float-label text-right">
                          <input
                            id="search-input"
                            type="text"
                            name="random"
                            className={classnames('form-control', {
                              'is-invalid': errors.random,
                            })}
                            onChange={this.onChange}
                            value={random}
                            // placeholder="أدخل نص للبحث: دمشق شقة مفروشة"
                            style={{ transition: 'all 0.4s' }}
                          />
                          {random === '' ? (
                            <label
                              htmlFor="search-input"
                              className="no_select text-right"
                            >
                              {this.state.smallScreen ? (
                                <h1 style={{ fontSize: 11 }}>
                                  {' '}
                                  ابحث عن(مثال): تأجير شاليه الرمال الذهبيه
                                </h1>
                              ) : (
                                'ابحث عن(مثال): تأجير شاليه الرمال الذهبيه'
                              )}
                            </label>
                          ) : null}
                        </span>
                      </div>
                    ) : (
                      <div
                        className="form-group text-right px-1 mt-1 mb-2"
                        style={{ width: '470px' }}
                      >
                        {business_offer === 'بيع' ? (
                          <span className="p-float-label text-right">
                            <input
                              id="search-input"
                              type="text"
                              name="random"
                              className={classnames('form-control', {
                                'is-invalid': errors.random,
                              })}
                              onChange={this.onChange}
                              // onFocus={e =>
                              //   (e.placeholder = `this.placeholder=''`)
                              // }
                              // onblur={e =>
                              //   (e.placeholder = `this.placeholder='مثال: طرطوس صافيتا شقة'`)
                              // }
                              value={random}
                              // placeholder="أدخل نص للبحث: دمشق شقة مفروشة"
                              style={{ transition: 'all 0.4s' }}
                            />
                            {random === '' ? (
                              <label
                                htmlFor="search-input"
                                className="no_select text-right"
                              >
                                ابحث عن(مثال): تأجير شاليه الرمال الذهبيه
                              </label>
                            ) : null}
                          </span>
                        ) : (
                          <span className="p-float-label text-right">
                            <input
                              id="search-input"
                              type="text"
                              name="random"
                              className={classnames('form-control', {
                                'is-invalid': errors.random,
                              })}
                              onChange={this.onChange}
                              value={random}
                              // placeholder="أدخل نص للبحث: دمشق شقة مفروشة"
                              style={{ transition: 'all 0.4s' }}
                            />
                            {random === '' ? (
                              <label
                                htmlFor="search-input"
                                className="no_select text-right"
                              >
                                ابحث عن(مثال): تأجير شاليه الرمال الذهبيه
                              </label>
                            ) : null}
                          </span>
                        )}
                      </div>
                    )}

                    <div
                      className={classnames(
                        'form-group',
                        'text-right',
                        'my-1',
                        'ml-1'
                      )}
                    >
                      {!this.state.smallScreen ? (
                        <button
                          // to={{
                          //   pathname: '/search',
                          //   // query: { search_mode: 'min' }
                          //   search: this.state.searchUrl
                          // }}
                          className={classnames(
                            'btn',
                            'px-0',
                            { 'submit-search-button': this.state.largeScreen },
                            {
                              'header-submit-search-button-min': !this.state
                                .largeScreen,
                            }
                          )}
                          onClick={this.onSearchButtonSubmit}
                        >
                          <span>بحث{` `}</span>
                          {this.state.largeScreen ? (
                            <span className="btn_full_search_icon">
                              <FontAwesomeIcon icon={['fas', 'search']} />
                            </span>
                          ) : null}
                        </button>
                      ) : null}
                    </div>

                    {expanded_search ? (
                      <React.Fragment>
                        <div className="input-group justify-content-start">
                          <div className="form-group text-right px-1  pb-0 mb-2">
                            {/* <label htmlFor="province" className="label_narrow">
                              المحافظة:
                            </label> */}
                            <select
                              name="province"
                              className={classnames(
                                'form-control',
                                'search-province',
                                {
                                  'is-invalid': errors.province,
                                }
                              )}
                              onChange={this.onProvinceChange}
                              value={province}
                              // style={{ width: '125px' }}
                            >
                              <option value="المحافظة">المحافظة</option>
                              <option value="دمشق"> دمشق</option>
                              <option value="ريف دمشق"> ريف دمشق</option>
                              <option value="حلب"> حلب</option>
                              <option value="طرطوس"> طرطوس</option>
                              <option value="اللاذقية"> اللاذقية</option>
                              <option value="حمص"> حمص</option>
                              <option value="حماة"> حماة</option>
                              <option value="السويداء"> السويداء</option>
                              <option value="الحسكة"> الحسكة</option>
                              <option value="درعا"> درعا</option>
                              <option value="ادلب"> ادلب</option>
                              <option value="دير الزور"> دير الزور</option>
                              <option value="القنيطرة"> القنيطرة</option>
                              <option value="الرقة"> الرقة</option>
                            </select>
                          </div>
                          <div className="form-group text-right pl-sm-1 pr-sm-0 px-1  pb-0 mb-2">
                            {/* <label htmlFor="area" className="label_narrow">
                              المنطقة:
                            </label> */}
                            <select
                              name="area"
                              className={classnames(
                                'form-control',
                                'search-area',
                                {
                                  'is-invalid': errors.area,
                                }
                              )}
                              onChange={this.onChange}
                              value={area}
                              // style={{ width: '162px' }}
                            >
                              <option value="المنطقة">المنطقة</option>
                              {areas.map((area, index) => (
                                <option value={area} key={index}>
                                  {area}
                                </option>
                              ))}
                            </select>
                          </div>

                          <div className="form-group text-right  px-1  pb-0 mb-2">
                            {/* <label
                              htmlFor="business_offer mx-1"
                              className="label_narrow"
                            >
                              نوع العرض:
                            </label> */}
                            <select
                              name="business_offer"
                              className={classnames(
                                'form-control',
                                'search-business-offer',
                                {
                                  'is-invalid': errors.business_offer,
                                }
                              )}
                              onChange={this.onBusinessOfferChangeList}
                              value={business_offer}
                              // style={{ width: '120px' }}
                            >
                              <option value="نوع العرض">نوع العرض</option>
                              {generalBusinessOffer.map((businessOffer) => (
                                <option
                                  value={businessOffer}
                                  key={shortid.generate()}
                                >
                                  {businessOffer}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="form-group text-right pl-sm-1 pr-sm-0 px-1  pb-0 mb-2">
                            {/* <label htmlFor="type" className="label_narrow">
                              نوع العقار:
                            </label> */}
                            <select
                              name="type"
                              className={classnames(
                                'form-control',
                                'search-type',
                                {
                                  'is-invalid': errors.type,
                                }
                              )}
                              onChange={this.onChange}
                              value={type}
                              // style={{ width: '115px' }}
                            >
                              <option value="نوع العقار">نوع العقار</option>
                              {generalTypes}
                            </select>
                          </div>
                        </div>
                        <div className="input-group justify-content-start">
                          <div className="form-group text-right px-1  pb-0 mb-2">
                            {/* text-right */}

                            <select
                              name="min_price"
                              id="minPrice"
                              className={classnames(
                                'search-min-price',
                                'form-control',
                                {
                                  'is-invalid': errors.min_price,
                                }
                              )}
                              onChange={this.onChange}
                              value={min_price}
                            >
                              <option value="أدنى سعر">أدنى سعر</option>
                              {forSell
                                ? generalSellPrices.map((price) => (
                                    <option
                                      value={price}
                                      key={shortid.generate()}
                                    >
                                      {`${formatMoney(price)} ل.س`}
                                    </option>
                                  ))
                                : forRent
                                ? generalRentPrices.map((price) => (
                                    <option
                                      value={price}
                                      key={shortid.generate()}
                                    >
                                      {`${formatMoney(price)} ل.س`}
                                    </option>
                                  ))
                                : null}
                            </select>
                            {/* {errors.min_price && (
                            <div className="invalid-feedback">
                              {errors.min_price}
                            </div>
                          )} */}
                          </div>
                          <div className="form-group text-right pl-sm-1 pr-sm-0 px-1  pb-0 mb-2">
                            <select
                              name="max_price"
                              id="maxPrice"
                              className={classnames(
                                'search-max-price',
                                'form-control',
                                {
                                  'is-invalid': errors.max_price,
                                }
                              )}
                              onChange={this.onChange}
                              value={max_price}
                            >
                              <option value="أعلى سعر">أعلى سعر</option>
                              {forSell ? (
                                <React.Fragment>
                                  {generalSellPrices.map((price) => (
                                    <option
                                      value={price}
                                      key={shortid.generate()}
                                    >
                                      {`${formatMoney(price)} ل.س`}
                                    </option>
                                  ))}
                                  <option value="900000000000">
                                    {`أكبر من ${formatMoney(9000000000)} ل.س`}
                                  </option>{' '}
                                </React.Fragment>
                              ) : forRent ? (
                                <React.Fragment>
                                  {generalRentPrices.map((price) => (
                                    <option
                                      value={price}
                                      key={shortid.generate()}
                                    >
                                      {`${formatMoney(price)} ل.س`}
                                    </option>
                                  ))}
                                  <option value="100000000000">
                                    {`أكبر من ${formatMoney(100000000)} ل.س`}
                                  </option>{' '}
                                </React.Fragment>
                              ) : null}
                            </select>

                            {/* {errors.max_price && (
                            <div className="invalid-feedback">
                              {errors.max_price}
                            </div>
                          )} */}
                          </div>
                          <div className="form-group text-right px-1  pb-0 mb-2">
                            {/* text-right */}

                            <select
                              name="min_area"
                              id="minArea"
                              className={classnames(
                                'search-min-area',
                                'form-control',
                                {
                                  'is-invalid': errors.min_area,
                                }
                              )}
                              onChange={this.onChange}
                              value={min_area}
                            >
                              <option value="أدنى مساحة">أدنى مساحة</option>
                              {generalAreas.map((area) => (
                                <option value={area} key={shortid.generate()}>
                                  {`${formatMoney(area)} متر مربع`}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="form-group text-right pl-sm-1 pr-sm-0 px-1  pb-0 mb-2">
                            {/* {errors.min_area && (
                            <div className="invalid-feedback">
                              {errors.min_area}
                            </div>
                          )} */}

                            <select
                              name="max_area"
                              id="maxArea"
                              className={classnames(
                                'search-max-area',
                                'form-control',
                                {
                                  'is-invalid': errors.max_area,
                                }
                              )}
                              onChange={this.onChange}
                              value={max_area}
                            >
                              <option value="أعلى مساحة">أعلى مساحة</option>
                              {generalAreas.map((area) => (
                                <option value={area} key={shortid.generate()}>
                                  {`${formatMoney(area)} متر مربع`}
                                </option>
                              ))}
                              <option value={90000000}>
                                {`أكبر من ${formatMoney(9000)} متر مربع`}
                              </option>
                            </select>

                            {/* {errors.max_area && (
                            <div className="invalid-feedback">
                              {errors.max_area}
                            </div>
                          )} */}
                          </div>
                        </div>
                        {(errors.min_price || errors.max_price) && (
                          <div className="row">
                            <div className="col-6">
                              <div
                                className="text-right alert alert-danger mt-0 mb-3  py-0"
                                role="alert"
                              >
                                الرجاء التأكد من القيم المدخلة للسعر
                              </div>
                            </div>
                            <div className="col-6"></div>
                          </div>
                        )}
                        {(errors.min_area || errors.max_area) && (
                          <div className="row">
                            <div className="col-6">
                              <div
                                className="text-right alert alert-danger mt-0 mb-3 py-0"
                                role="alert"
                              >
                                الرجاء التاكد من القيم المدخلة للمساحة
                              </div>
                            </div>
                            <div className="col-6"></div>
                          </div>
                        )}
                      </React.Fragment>
                    ) : null}
                  </div>
                  {!expanded_search ? (
                    this.state.largeScreen ? (
                      // <div className="input-group">
                      <div className="form-group text-right px-1">
                        <input
                          id="main_type_flat_id"
                          type="checkbox"
                          name="main_type_flat"
                          value="شقق"
                          checked={main_type_flat === true || init2}
                          onChange={this.onCheckBoxChange}
                          className={classnames('pl-1', { shining: init2 })}
                        />
                        <label
                          htmlFor="main_type_flat_id"
                          className="no_select label_narrow label_white_with_shadow pr-1 pl-3"
                        >
                          شقق
                        </label>
                        <input
                          id="main_type_cabin_id"
                          type="checkbox"
                          name="main_type_cabin"
                          value="شاليهات"
                          onChange={this.onCheckBoxChange}
                          className={classnames('px-1', { shining: init2 })}
                          checked={main_type_cabin === true || init2}
                        />

                        <label
                          htmlFor="main_type_cabin_id"
                          className="no_select label_narrow label_white_with_shadow pr-1 pl-3"
                        >
                          شاليهات
                        </label>
                        <input
                          id="main_type_building_id"
                          type="checkbox"
                          name="main_type_building"
                          value="بنايات"
                          onChange={this.onCheckBoxChange}
                          className={classnames('pl-1', { shining: init2 })}
                          checked={main_type_building === true || init2}
                        />
                        <label
                          htmlFor="main_type_building_id"
                          className="no_select label_narrow label_white_with_shadow pr-1 pl-3"
                        >
                          بنايات
                        </label>

                        <input
                          id="main_type_trade_id"
                          type="checkbox"
                          name="main_type_trade"
                          value="تجاري"
                          onChange={this.onCheckBoxChange}
                          className={classnames('pl-1', { shining: init2 })}
                          checked={main_type_trade === true || init2}
                        />

                        <label
                          htmlFor="main_type_trade_id"
                          className="no_select label_narrow label_white_with_shadow pr-1 pl-3"
                        >
                          تجاري
                        </label>
                        {/* {business_offer === 'بيع' ? ( */}
                        <React.Fragment>
                          <input
                            id="main_type_land_id"
                            type="checkbox"
                            name="main_type_land"
                            value="أراضي"
                            onChange={this.onCheckBoxChange}
                            className={classnames('pl-1', { shining: init2 })}
                            checked={main_type_land === true || init2}
                          />

                          <label
                            htmlFor="main_type_land_id"
                            className="no_select label_narrow label_white_with_shadow pr-1 pl-3"
                          >
                            أراضي
                          </label>
                        </React.Fragment>
                        {/* ) : null} */}
                      </div>
                    ) : (
                      // </div>
                      <div className="form-group text-right px-1 row">
                        <div className="col-6">
                          <input
                            id="main_type_flat_id"
                            type="checkbox"
                            name="main_type_flat"
                            value="شقق"
                            checked={main_type_flat === true || init2}
                            onChange={this.onCheckBoxChange}
                            className={classnames('pl-1', { shining: init2 })}
                          />
                          <label
                            htmlFor="main_type_flat_id"
                            className="no_select label_narrow label_white_with_shadow pr-1 pl-3"
                          >
                            شقق
                          </label>
                          <br />
                          <input
                            id="main_type_cabin_id"
                            type="checkbox"
                            name="main_type_cabin"
                            value="شاليهات"
                            onChange={this.onCheckBoxChange}
                            className={classnames('px-1', { shining: init2 })}
                            checked={main_type_cabin === true || init2}
                          />

                          <label
                            htmlFor="main_type_cabin_id"
                            className="no_select label_narrow label_white_with_shadow pr-1 pl-3"
                          >
                            شاليهات
                          </label>
                          <br />
                          <input
                            id="main_type_building_id"
                            type="checkbox"
                            name="main_type_building"
                            value="بنايات"
                            onChange={this.onCheckBoxChange}
                            className={classnames('pl-1', { shining: init2 })}
                            checked={main_type_building === true || init2}
                          />
                          <label
                            htmlFor="main_type_building_id"
                            className="no_select label_narrow label_white_with_shadow pr-1 pl-3"
                          >
                            بنايات
                          </label>
                        </div>
                        <div className="col-6">
                          <input
                            id="main_type_trade_id"
                            type="checkbox"
                            name="main_type_trade"
                            value="تجاري"
                            onChange={this.onCheckBoxChange}
                            className={classnames('pl-1', { shining: init2 })}
                            checked={main_type_trade === true || init2}
                          />

                          <label
                            htmlFor="main_type_trade_id"
                            className="no_select label_narrow label_white_with_shadow pr-1 pl-3"
                          >
                            تجاري
                          </label>
                          <br />
                          {/* {business_offer === 'بيع' ? ( */}
                          <React.Fragment>
                            <input
                              id="main_type_land_id"
                              type="checkbox"
                              name="main_type_land"
                              value="أراضي"
                              onChange={this.onCheckBoxChange}
                              className={classnames('pl-1', { shining: init2 })}
                              checked={main_type_land === true || init2}
                            />

                            <label
                              htmlFor="main_type_land_id"
                              className="no_select label_narrow label_white_with_shadow pr-1 pl-3"
                            >
                              أراضي
                            </label>
                          </React.Fragment>
                          {/* ) : null} */}
                        </div>
                      </div>
                    )
                  ) : null}

                  {this.state.smallScreen ? (
                    <div className="form-group text-center mt-2">
                      <button
                        // to={{
                        //   pathname: '/search',
                        //   // query: { search_mode: 'min' }
                        //   search: this.state.searchUrl
                        // }}
                        className={classnames(
                          'btn',
                          'px-0',
                          { 'submit-search-button': this.state.largeScreen },
                          {
                            'header-submit-search-button-min': !this.state
                              .largeScreen,
                          }
                        )}
                        onClick={this.onSearchButtonSubmit}
                      >
                        <span>بحث{` `}</span>
                        {this.state.largeScreen ? (
                          <span className="btn_full_search_icon">
                            <FontAwesomeIcon icon={['fas', 'search']} />
                          </span>
                        ) : null}
                      </button>
                    </div>
                  ) : null}

                  <div
                    className={classnames(
                      'form-group',
                      'text-center',
                      { 'mt-4': !expanded_search },
                      { 'mt-0': expanded_search }
                    )}
                  >
                    <Link
                      to="#"
                      // to={{
                      //   pathname: '/search',
                      //   query: { search_mode: 'full' }
                      // }}
                      className="label_narrow label_white_with_shadow link-search"
                      onClick={this.onFullSearchClick}
                    >
                      <span className="display_gold">
                        بحث موسع{` `}
                        {expanded_search ? (
                          <FontAwesomeIcon
                            icon={['fas', 'angle-up']}
                            size="sm"
                            // className="pt-1"
                          ></FontAwesomeIcon>
                        ) : (
                          <FontAwesomeIcon
                            icon={['fas', 'angle-down']}
                            size="sm"
                            // className="pt-1"
                          ></FontAwesomeIcon>
                        )}
                      </span>
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}
Header.propTypes = {
  doSearch: PropTypes.func.isRequired,
  isTakingAgencyRole: PropTypes.bool.isRequired,
  //takenAgencyRoleToken: PropTypes.string.isRequired,
  setMainResponse: PropTypes.func.isRequired,
  getMainResponse: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isTakingAgencyRole: state.auth.isTakingAgencyRole,
  takenAgencyRoleToken: state.auth.takenAgencyRoleToken,
});

export default compose(
  withRouter,
  connect(mapStateToProps, { doSearch, setMainResponse, getMainResponse })
)(Header);
