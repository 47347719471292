import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { doSearch } from './../../actions/searchActions';
import HContainer from './../layout/hContainer/HContainer';
class MainPageMostViewedH extends Component {
  constructor(props) {
    super();
  }
  state = {
    properties: [],
    total_properties: 0,
    searchBarFullShow: false,
    requestSuccessMessage: {},
    errors: {},
    pageNumber: 1,
    searchParameters: {},
    resType: '',
  };

  // onSearchSubmit = (recordsPerPage, pageNumber) => {
  //   const property = {};
  //   property.visible = true;
  //   this.setState({ pageNumber });
  //   this.props.doSearch(property, recordsPerPage, pageNumber);
  // };

  componentDidMount() {
    if (
      this.props.properties !== this.state.properties &&
      this.props.properties !== undefined &&
      this.props.properties[0] !== undefined
    ) {
      const { properties } = this.props;
      this.setState({
        properties,
        total_properties: properties[0].total_properties,
      });
    } else {
      this.setState({
        properties: [],
        total_properties: 0,
      });
    }
  }
  componentWillReceiveProps(nextProps, nextState) {
    if (
      nextProps.properties !== this.state.properties &&
      nextProps.properties !== undefined &&
      nextProps.properties[0] !== undefined
    ) {
      const { properties } = nextProps;
      this.setState({
        properties,
        total_properties: properties[0].total_properties,
      });
    } else {
      this.setState({
        properties: [],
        total_properties: 0,
      });
    }

    if (
      nextProps.resType !== this.state.resType &&
      nextProps.resType !== undefined
    ) {
      const { resType } = nextProps;
      this.setState({
        resType,
      });
    } else {
      this.setState({
        resType: '',
      });
    }
  }

  render() {
    const {
      properties,
      // total_properties, pageNumber,
      resType,
    } = this.state;

    return (
      <React.Fragment>
        <HContainer
          rows="10"
          cardsByRow="1"
          propertiesData={properties}
          title1="الأكثر مشاهدة"
          title2=""
          link="/العروض-الأكثر-مشاهدة"
          resType={resType}
          main_type={this.props.main_type}
          business_offer={this.props.business_offer}
        ></HContainer>
      </React.Fragment>
    );
  }
}

MainPageMostViewedH.propTypes = {
  //properties: PropTypes.array.isRequired
  //doSearch: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  //properties: state.search.properties
});

export default connect(mapStateToProps, {})(MainPageMostViewedH);
